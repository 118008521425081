import { ZoneMaj }                                                                                               from "../../types/components/ville/Zone.type";
import { AffichageCarte, FiltreRetour, MajBatRetour, MajCaseRetour, PopupMajManuel, RefreshRetour, ScrutRetour } from "../../types/api/RetourApiCarte";
import { FetchGH }                                                                                               from "../../components/generality/FetchGH";


export class CarteApi {
	
	private fetch: FetchGH;
	
	constructor(mapId?: number) {
		this.fetch = new FetchGH("carte", mapId);
	}
	
	public general(mapId?: string): Promise<AffichageCarte> {
		return this.fetch.get("/" + ((mapId) ? mapId : ""));
	}
	
	public popUp(data: { x: number, y: number, mapId: number }): Promise<PopupMajManuel> {
		return this.fetch.post({ path: "/popUpInfo", data: data });
	}
	
	public majBat(data: { x: number, y: number, mapId: number, camped: number, empty: number }): Promise<MajBatRetour> {
		return this.fetch.post({ path: "/majBat", data: data });
	}
	
	public majBatHypo(data: { x: number, y: number, mapId: number, hypo: number }): Promise<MajBatRetour> {
		return this.fetch.post({ path: "/majHypo", data: data });
	}
	
	public majScrut(data: { mapId: number }): Promise<ScrutRetour> {
		return this.fetch.post({ path: "/majScrut", data: data });
	}
	
	public majCase(data: { mapid: number, userid: number, zone_maj: ZoneMaj }): Promise<MajCaseRetour> {
		return this.fetch.post({ path: "/majCase", data: data });
	}
	
	public refresh(data: { mapId: number, userId: number, outils: boolean }): Promise<RefreshRetour> {
		return this.fetch.post({ path: "/refresh", data: data });
	}
	
	public filtre_objet(data: { mapId: number, userId: number, typeFiltre: number, min: number, max: number }): Promise<FiltreRetour> {
		return this.fetch.post({ path: "/filtre", data: data });
	}
	
}
import React                                         from "react";
import { components, OptionProps, SingleValueProps } from "react-select";
import { useTranslation }                            from "react-i18next";
import SvgIcone                                      from "../../../components/generality/SvgIcone";
import { CitoyensDTO }                               from "../../../types/models/citoyens.dto";
import { JobPrototypeDTO }                           from "../../../types/models/jobPrototype.dto";

interface CommonSingleValueProps {
	icon: string;
	label: string;
	isCampeurPro: boolean;
}

const CommonSingleValue = ({
	icon,
	label,
	isCampeurPro,
}: CommonSingleValueProps) => {
	const className = isCampeurPro ? "joueurCp" : "joueurNonCp";
	
	return (
		<div className="d-flex align-items-center">
			<SvgIcone icone={`h_${icon}`} />
			<span className={className}>{label}</span>
		</div>
	);
};

export const CitoyenSingleValue = ({ data, ...props }: SingleValueProps<CitoyensDTO>) => {
	const isCampeurPro = (data?.nb_camping_pro_noob ?? (data?.job?.id === 2 ? 0 : 6)) >= (data?.nb_camping ?? 0);
	
	return (
		<components.SingleValue data={data} {...props}>
			<CommonSingleValue
				icon={data.job.icon}
				label={data.citoyen.pseudo}
				isCampeurPro={isCampeurPro}
			/>
		</components.SingleValue>
	);
};

export const JobSingleValue = ({ data, ...props }: SingleValueProps<JobPrototypeDTO>) => {
	const { t } = useTranslation();
	
	return (
		<components.SingleValue data={data} {...props}>
			<CommonSingleValue
				icon={data.icon}
				label={t(data.nom, { ns: "game" })}
				isCampeurPro={!(data.id === 1 || data.id === 2)}
			/>
		</components.SingleValue>
	);
};

export const CitoyenOption = ({ data, ...props }: OptionProps<CitoyensDTO>) => {
	const isCampeurPro = (data?.nb_camping_pro_noob ?? (data?.job?.id === 2 ? 0 : 6)) >= (data?.nb_camping ?? 0);
	
	return (
		<components.Option data={data} {...props}>
			<CommonSingleValue
				icon={data.job.icon}
				label={data.citoyen.pseudo}
				isCampeurPro={isCampeurPro}
			/>
		</components.Option>
	);
};

export const JobOption = ({ data, ...props }: OptionProps<JobPrototypeDTO>) => {
	const { t } = useTranslation();
	
	return (
		<components.Option data={data} {...props}>
			<CommonSingleValue
				icon={data.icon}
				label={t(data.nom, { ns: "game" })}
				isCampeurPro={!(data.id === 1 || data.id === 2)}
			/>
		</components.Option>
	);
};
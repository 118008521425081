import React, { useEffect, useRef, useState } from "react";
import { CKEditor }                           from "@ckeditor/ckeditor5-react";

import { AccessibilityHelp, Alignment, Autoformat, AutoLink, Autosave, BlockQuote, Bold, ClassicEditor, Code, EditorConfig, Essentials, FontBackgroundColor, FontColor, FontFamily, FontSize, GeneralHtmlSupport, Heading, HorizontalLine, Indent, IndentBlock, Italic, Link, List, ListProperties, MediaEmbed, Paragraph, RemoveFormat, SelectAll, SpecialCharacters, SpecialCharactersArrows, SpecialCharactersCurrency, SpecialCharactersEssentials, SpecialCharactersLatin, SpecialCharactersMathematical, SpecialCharactersText, Strikethrough, Style, Subscript, Superscript, TodoList, Underline, Undo } from "ckeditor5";
import { useTranslation }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       from "react-i18next";
import "ckeditor5/ckeditor5.css";

import "./editor.css";

export default function EditorComponent({ data, onTextChange, width = 500, lang = "en", disabled = false }: { width?: number, data: string, onTextChange: (data: string) => void, lang: string, disabled?: boolean }) {
	const { t } = useTranslation();
	const editorContainerRef = useRef(null);
	const editorRef = useRef(null);
	const [isLayoutReady, setIsLayoutReady] = useState(false);
	
	useEffect(() => {
		setIsLayoutReady(true);
		
		return () => setIsLayoutReady(false);
	}, []);
	
	const editorConfig: EditorConfig = {
		licenseKey : "GPL",
		toolbar    : {
			items                 : [
				"undo",
				"redo",
				"|",
				//"selectAll",
				// "style",
				//"|",
				"heading",
				"|",
				"bold",
				"italic",
				"underline",
				"strikethrough",
				"fontColor",
				"fontSize",
				"fontFamily",
				"fontBackgroundColor",
				"removeFormat",
				"|",
				"alignment",
				"indent",
				"outdent",
				"|",
				"horizontalLine",
				"bulletedList",
				"numberedList",
				"|",
				"link",
				//"mediaEmbed",
				"blockQuote",
				"|",
				"specialCharacters",
				"subscript",
				"superscript",
				/*"|",
				 "code",
				 "todoList",*/
			
			],
			shouldNotGroupWhenFull: false,
		},
		plugins    : [
			AccessibilityHelp,
			Alignment,
			Autoformat,
			AutoLink,
			Autosave,
			BlockQuote,
			Bold,
			Code,
			Essentials,
			FontBackgroundColor,
			FontColor,
			FontFamily,
			FontSize,
			GeneralHtmlSupport,
			Heading,
			HorizontalLine,
			Indent,
			IndentBlock,
			Italic,
			Link,
			List,
			ListProperties,
			//Markdown,
			MediaEmbed,
			Paragraph,
			RemoveFormat,
			SelectAll,
			SpecialCharacters,
			SpecialCharactersArrows,
			SpecialCharactersCurrency,
			SpecialCharactersEssentials,
			SpecialCharactersLatin,
			SpecialCharactersMathematical,
			SpecialCharactersText,
			Strikethrough,
			Style,
			Subscript,
			Superscript,
			TodoList,
			Underline,
			Undo,
		],
		fontFamily : {
			supportAllValues: true,
		},
		fontSize   : {
			options         : [10, 12, 14, "default", 18, 20, 22],
			supportAllValues: true,
		},
		heading    : {
			options: [
				{
					model: "paragraph",
					title: "Paragraph",
					class: "ck-heading_paragraph",
				},
				{
					model: "heading1",
					view : "h1",
					title: "Heading 1",
					class: "ck-heading_heading1",
				},
				{
					model: "heading2",
					view : "h2",
					title: "Heading 2",
					class: "ck-heading_heading2",
				},
				{
					model: "heading3",
					view : "h3",
					title: "Heading 3",
					class: "ck-heading_heading3",
				},
				{
					model: "heading4",
					view : "h4",
					title: "Heading 4",
					class: "ck-heading_heading4",
				},
				{
					model: "heading5",
					view : "h5",
					title: "Heading 5",
					class: "ck-heading_heading5",
				},
				{
					model: "heading6",
					view : "h6",
					title: "Heading 6",
					class: "ck-heading_heading6",
				},
			],
		},
		htmlSupport: {
			allow: [
				{
					name      : /^.*$/,
					styles    : true,
					attributes: true,
					classes   : true,
				},
			],
		},
		initialData: "",
		link       : {
			addTargetToExternalLinks: true,
			defaultProtocol         : "https://",
			decorators              : {
				toggleDownloadable: {
					mode      : "manual",
					label     : "Downloadable",
					attributes: {
						download: "file",
					},
				},
			},
		},
		list       : {
			properties: {
				styles    : true,
				startIndex: true,
				reversed  : true,
			},
		},
		placeholder: t("Ecrivez votre texte ici", { ns: "app" }),
		style      : {
			definitions: [
				{
					name   : "Article category",
					element: "h3",
					classes: ["category"],
				},
				{
					name   : "Title",
					element: "h2",
					classes: ["document-title"],
				},
				{
					name   : "Subtitle",
					element: "h3",
					classes: ["document-subtitle"],
				},
				{
					name   : "Info box",
					element: "p",
					classes: ["info-box"],
				},
				{
					name   : "Side quote",
					element: "blockquote",
					classes: ["side-quote"],
				},
				{
					name   : "Marker",
					element: "span",
					classes: ["marker"],
				},
				{
					name   : "Spoiler",
					element: "span",
					classes: ["spoiler"],
				},
				{
					name   : "Code (dark)",
					element: "pre",
					classes: ["fancy-code", "fancy-code-dark"],
				},
				{
					name   : "Code (bright)",
					element: "pre",
					classes: ["fancy-code", "fancy-code-bright"],
				},
			],
		},
		language   : {
			// The UI will be English.
			ui: lang,
			// But the content will be edited in Arabic.
			content: lang,
		},
	};
	
	return (
		<div style={{ width: `${width}px` }}>
			<div className="main-container">
				<div className="editor-container editor-container_classic-editor editor-container_include-style" ref={editorContainerRef}>
					<div className="editor-container__editor">
						<div ref={editorRef}>{isLayoutReady && <CKEditor editor={ClassicEditor} config={editorConfig} data={data} onChange={(_event, editor) => {
							onTextChange(editor.getData());
						}} />}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

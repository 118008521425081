import React                                     from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import TooltipGH                                 from "../../../components/utils/TooltipGH";
import SvgIcone                                  from "../../../components/generality/SvgIcone";
import CustomSwitchToggle                        from "../../../components/generality/CustomSwitchToggle";
import { ExpeditionDTO }                         from "../../../types/models/expedition.dto";

const DraggableExpeditionList = ({
	expeditions,
	onReorder,
	jourActuel,
	general,
	verrouExpes,
	onViewExpedition,
	onEditExpedition,
	onDuplicateExpedition,
	onDeleteExpedition,
	onSwapExpedition,
	onDuplicateAnotherDay,
	onStatusChange,
	t,
}) => {
	const handleDragEnd = (result) => {
		if (!result.destination) {
			return;
		}
		
		const items: ExpeditionDTO[] = Array.from(expeditions);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		
		// Update priorities
		items.forEach((item, index) => {
			item.priorite = index + 1;
		});
		
		onReorder(items);
	};
	
	const recuperationPseudoPresinscrit = (expedition: ExpeditionDTO) => {
		return expedition.expedition_parts[0].expeditionnaires
			.filter((exped) => exped.preinscrit)
			.sort((a, b) => a.citoyen.citoyen.pseudo.localeCompare(b.citoyen.citoyen.pseudo))
			?.map((exped) => exped.citoyen);
	};
	
	const numberOfExpedition = expeditions.length;
	
	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable droppableId="expeditions">
				{(provided) => (
					<tbody {...provided.droppableProps} ref={provided.innerRef}>
					{expeditions.sort((a: ExpeditionDTO, b: ExpeditionDTO) => a.priorite - b.priorite).map((expedition: ExpeditionDTO, index: number) => (
						<Draggable
							key={expedition.id}
							draggableId={expedition.id}
							index={index}
							isDragDisabled={jourActuel < general.ville.jour || numberOfExpedition === 1}
						>
							{(provided, snapshot) => (
								<tr
									ref={provided.innerRef}
									{...provided.draggableProps}
									className={`${snapshot.isDragging ? "bg-gray-100" : ""}`}
								>
									<td {...provided.dragHandleProps} className="cursor-grab">
										<div className={"d-flex gap-2 justify-content-start align-items-center ps-2"}>
											{jourActuel >= general.ville.jour && numberOfExpedition !== 1 && <i className="fa-solid fa-grip-vertical mr-2 text-gray-400" />}
											<div>{expedition.nom}</div>
										</div>
									</td>
									<td className="sm_cols">
										<TooltipGH>
                                            <span className="infoBulle d-flex gap-1 align-items-center justify-content-center">
                                                <SvgIcone icone={expedition.type_expe.icon} />
                                            </span>
											<span className="info">{t(expedition.type_expe.nom, { ns: "outils" })}</span>
										</TooltipGH>
									</td>
									<td className="sm_cols">{expedition.nbr_partie}</td>
									<td className="sm_cols">
										{expedition.expedition_parts.reduce((acc, part) => acc + part.pa, 0)}
									</td>
									<td className="sm_cols">
										{expedition.expedition_parts[0].expeditionnaires.length}
									</td>
									<td className="small_cols">
										<div className="preinscrit_liste_exped">
											{recuperationPseudoPresinscrit(expedition).map((citoyen, idx) => (
												<span key={`preinscrit_${idx}`} className="flex gap-1 items-center justify-center">
                                                    <SvgIcone icone={`h_${citoyen?.job?.icon}`} />
                                                    <span>{citoyen?.citoyen?.pseudo}</span>
                                                </span>
											))}
										</div>
									</td>
									<td className="small_cols">{expedition.created_by?.pseudo ?? ""}</td>
									<td className="small_cols">{expedition.modify_by?.pseudo ?? ""}</td>
									<td className="small_cols">
										<CustomSwitchToggle
											checked={expedition.expedition_parts[0].ouverte}
											size="sm"
											onstyle="success"
											offstyle="danger"
											onlabel={t("Ouvert", { ns: "ville" })}
											offlabel={t("Stop", { ns: "ville" })}
											disabled={jourActuel < general.ville.jour}
											onChange={() => onStatusChange(expedition)}
										/>
									</td>
									<td className="medium_cols">
										<div className="group_bouton_outils_expedition">
											<button
												className="btn btn-primary btn-xs"
												onClick={() => onViewExpedition(expedition)}
											>
												<i className="fa-solid fa-eye"></i>
											</button>
											{jourActuel >= general.ville.jour && (
												<>
													{verrouExpes.includes(expedition.id) ? (
														<button className="btn btn-success btn-xs" disabled>
															<i className="fa-solid fa-lock"></i>
														</button>
													) : (
														<button
															className="btn btn-success btn-xs"
															onClick={() => onEditExpedition(expedition)}
														>
															<i className="fa-solid fa-pen-to-square"></i>
														</button>
													)}
													<TooltipGH>
														<button
															className="btn btn-warning btn-xs"
															onClick={() => onDuplicateExpedition(expedition)}
														>
															<i className="fa-regular fa-clone"></i>
														</button>
														<span className="info">{t("Dupliquer", { ns: "ville" })}</span>
													</TooltipGH>
													<button
														className="btn btn-danger btn-xs"
														onClick={() => onDeleteExpedition(expedition.id)}
													>
														<i className="fa-solid fa-trash"></i>
													</button>
													{expeditions.length > 1 && (
														<>
															<TooltipGH>
																<button
																	className="btn btn-secondary btn-xs"
																	onClick={() => onSwapExpedition(index, index - 1)}
																	disabled={index === 0}
																>
																	<i className="fa-solid fa-circle-chevron-up"></i>
																</button>
																<span className="info">{t("Monter la priorité", { ns: "ville" })}</span>
															</TooltipGH>
															<TooltipGH>
																<button
																	className="btn btn-secondary btn-xs"
																	onClick={() => onSwapExpedition(index, index + 1)}
																	disabled={index === expeditions.length - 1}
																>
																	<i className="fa-solid fa-circle-chevron-down"></i>
																</button>
																<span className="info">{t("Descendre la priorité", { ns: "ville" })}</span>
															</TooltipGH>
														</>
													)}
												</>
											)}
											{jourActuel <= general.ville.jour && (
												<TooltipGH>
													<button
														className="btn btn-warning btn-xs"
														onClick={() => onDuplicateAnotherDay(expedition)}
													>
														<i className="fa-regular fa-copy"></i>
													</button>
													<span className="info">{t("Copier l'expédition pour un autre jour", { ns: "ville" })}</span>
												</TooltipGH>
											)}
										</div>
									</td>
								</tr>
							)}
						</Draggable>
					))}
					{provided.placeholder}
					</tbody>
				)}
			</Droppable>
		</DragDropContext>
	);
};

export default DraggableExpeditionList;
import * as React                 from "react";
import { useEffect, useState }    from "react";
import chargement                 from "../../../../img/chargement.svg";
import { EncyclopedieApi }        from "../../../services/api/EncyclopedieApi";
import { Status_error, usePopUp } from "../../../types/Context/PopUpContext";
import SvgBatiment                from "../../../components/generality/SvgBatiment";
import HTMLParser                 from "html-react-parser";
import SvgIcone                   from "../../../components/generality/SvgIcone";
import { BatPrototypeDTO }        from "../../../types/models/batPrototype.dto";
import { ItemBatimentDTO }        from "../../../types/models/itemBatiment.dto";
import { useTranslation }         from "react-i18next";
import TooltipGH                  from "../../../components/utils/TooltipGH";


export default function InformationBatiment({ id, onClose }: { id: number, onClose: () => void }) {
	const { t } = useTranslation();
	const [bat, setBat] = useState<BatPrototypeDTO>(null);
	const [loading, setLoading] = useState(true);
	const { setMessagePopUp, setShowPop, setStatus } = usePopUp();
	
	useEffect(() => {
		if (id !== null) {
			const encyclopedieApi = new EncyclopedieApi();
			encyclopedieApi.info_bat(id).then((response) => {
				setBat(response.data.bat);
				setLoading(false);
			}).catch((error) => {
				setMessagePopUp(error?.data?.error ?? error?.message);
				setStatus(Status_error);
				setShowPop(true);
			});
		}
	}, [id]);
	
	
	if (loading) {
		return <div id={"zone_information_batiment"}>
			<div className={"chargement_page"}>
				<img src={chargement} alt="Drapeau" />
				<span>{t("Chargement...", { ns: "app" })}</span>
			</div>
		</div>;
	}
	
	return <div id="zone_information_batiment">
		<div className="info_titre_bat">
			<h2><span>{t("Nom :", { ns: "ency" })}</span><span>{t(bat.nom, { ns: "bats" })}</span></h2>
		</div>
		<div className="info_contenu_bat">
			<div className="info_image_bat">
				<SvgBatiment icone={bat.icon} />
			</div>
			<div className="info_texte_bat">
				<h3>{t("Description :", { ns: "ency" })}</h3>
				<p>{HTMLParser(t(bat.description, { ns: "bats" }))}</p>
			</div>
		</div>
		<hr />
		<div className="info_texte_bat">
			<h3>{t("Objets obtenables dans le bâtiment :", { ns: "ency" })}</h3>
		</div>
		<div className="info_items_bat">
			{Object.values(bat.items).sort((a: ItemBatimentDTO, b: ItemBatimentDTO) => {
				return a.probabily > b.probabily ? -1 : 1;
			}).map((item: ItemBatimentDTO) => {
				return <span key={"bat_" + bat.id + "_item_" + item.item.id}>
                    <TooltipGH>
                        <span className="infoBulle">
                            <SvgIcone icone={item.item.icon} />
                        </span>
                        <span className="info">{t(item.item.nom, { ns: "items" })}</span>
                    </TooltipGH>
                    <span className={"ency_space"}><em>({Math.round((item.probabily * 100) / (bat.proba_item_globale * 100) * 10000) / 100} %)</em></span>
                </span>;
			})}
		</div>
		<div className="info_button_bat">
			<button onClick={onClose} className={"btn btn-primary btn-xs"}>{t("Fermer", { ns: "ency" })}</button>
		</div>
	</div>;
}

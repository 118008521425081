import React, { useEffect, useState } from "react";
import chargement                     from "../../../img/chargement.svg";
import { EncyclopedieApi }            from "../../services/api/EncyclopedieApi";
import EncyChantiers                  from "../../containers/Encyclopedie/EncyChantiers";
import { useNavigate }                from "react-router";
import { useGHContext }               from "../../types/Context/GHContext";
import { useGeneralContext }          from "../../types/Context/GeneralContext";
import { useTranslation }             from "react-i18next";

export function EncyChantiersGeneral() {
	const { general, setGeneral } = useGeneralContext();
	const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [encyChantierData, setEncyChantierData] = useState(null);
	
	// Fonction pour recharger les données
	const reloadData = async () => {
		const encyclopedieBatApi = new EncyclopedieApi(parseInt(sessionStorage.getItem("mapId") ?? "0", 10));
		encyclopedieBatApi.main_chantiers().then((response) => {
			setIsOnRefresh(false);
			setIsOnUpdate(false);
			if (response.codeRetour === 0) {
				if (response.zoneRetour.general !== undefined) {
					setGeneral(response.zoneRetour.general);
					sessionStorage.setItem("mapId", response.zoneRetour.general.ville ? response.zoneRetour.general.ville.map_id.toString() : null);
				}
				if (response.zoneRetour.chantiers === undefined) {
					console.error("Erreur de chargement des données chantiers", response);
				} else {
					setEncyChantierData(response.zoneRetour.chantiers);
				}
			}
		});
	};
	
	useEffect(() => {
		if (checkHabilitation("encyclopedie", "chantiers")) {
			reloadData().then(r => r);
		} else {
			navigate("/news");
		}
	}, [refreshKey]);
	
	return (
		<>
			{encyChantierData === null ? (
				<div className={"chargement_page"}>
					<img src={chargement} alt="Drapeau" />
					<span>{t("Chargement...", { ns: "app" })}</span>
				</div>
			) : (
				<EncyChantiers chantiers={encyChantierData} />
			)}
		
		</>
	);
}
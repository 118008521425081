import React, { useCallback, useState } from "react";
import Modal                            from "react-bootstrap/Modal";
import Button                           from "react-bootstrap/Button";
import { ListeJumpEvent }               from "../../../types/components/Jump/ListJumpEvent.type";
import HTMLParser                       from "html-react-parser";
import { InscriptionJumpApi }           from "../../../services/api/InscriptionJumpApi";
import { formatInTimeZone }             from "date-fns-tz";
import SvgDrapeau                       from "../../../components/generality/SvgDrapeau";
import { Link }                         from "react-router";
import SvgIcone                         from "../../../components/generality/SvgIcone";
import { useGeneralContext }            from "../../../types/Context/GeneralContext";
import { Status_error, usePopUp }       from "../../../types/Context/PopUpContext";
import { UserDTO }                      from "../../../types/models/user.dto";
import { JumpDTO }                      from "../../../types/models/jump.dto";
import { EventDTO }                     from "../../../types/models/event.dto";
import { ObjectifVillePrototypeDTO }    from "../../../types/models/objectifVillePrototype.dto";
import { InscriptionJumpDTO }           from "../../../types/models/inscriptionJump.dto";
import { useTranslation }               from "react-i18next";
import { Tab, Tabs }                    from "react-bootstrap";
import TooltipGH                        from "../../../components/utils/TooltipGH";

interface ListJumpEventProps {
	listJumpEvent: ListeJumpEvent,
}

export default function ListJumpEvent({ listJumpEvent }: ListJumpEventProps) {
	const { t } = useTranslation();
	const [isMasque, setIsMasque] = useState(false);
	const [showPopDesc, setShowPopDesc] = useState(false);
	const [description, setDescription] = useState("");
	const [masqueJump, setMasqueJump] = useState(listJumpEvent.masqueJump);
	const [masqueEvent, setMasqueEvent] = useState(listJumpEvent.masqueEvent);
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const [activeTab, setActiveTab] = React.useState("actif");
	
	const handleClose = useCallback(() => {
		setShowPopDesc(false);
		setDescription("");
	}, []);
	
	const handleOpenDescription = useCallback(description => {
		setDescription(description);
		setShowPopDesc(true);
	}, []);
	
	const handleChangeMasque = useCallback((id, click, type) => {
		const user = listJumpEvent.user;
		// Crée une copie des états masqueJump et masqueEvent
		const updatedMasqueJump = [...masqueJump];
		const updatedMasqueEvent = [...masqueEvent];
		const inscriptionApi = new InscriptionJumpApi();
		// Vérifie le type
		if (type === 0) {
			
			inscriptionApi.masque_jump({ userId: user.id, idJump: id, sens: click })
				.then((response) => {
					if (response.codeRetour === 0) {
						if (click) {
							// Si c'est cliqué, ajoute l'ID s'il n'est pas déjà présent
							if (!updatedMasqueJump.includes(id)) {
								updatedMasqueJump.push(id);
							}
						} else {
							// Si ce n'est pas cliqué, retire l'ID s'il est présent
							const index = updatedMasqueJump.indexOf(id);
							if (index !== -1) {
								updatedMasqueJump.splice(index, 1);
							}
						}
						// Met à jour les états avec les nouvelles valeurs
						setMasqueJump(updatedMasqueJump);
					} else {
						setShowPop(true);
						setStatus(Status_error);
						setMessagePopUp(response.libRetour);
					}
				})
				.catch((error) => {
					console.error(error);
				});
		} else if (type === 1) {
			inscriptionApi.masque_event({ userId: user.id, idEvent: id, sens: click })
				.then((response) => {
					if (response.codeRetour === 0) {
						// Pour masqueEvent
						if (click) {
							// Si c'est cliqué, ajoute l'ID s'il n'est pas déjà présent
							if (!updatedMasqueEvent.includes(id)) {
								updatedMasqueEvent.push(id);
							}
						} else {
							// Si ce n'est pas cliqué, retire l'ID s'il est présent
							const index = updatedMasqueEvent.indexOf(id);
							if (index !== -1) {
								updatedMasqueEvent.splice(index, 1);
							}
						}
						// Met à jour les états avec les nouvelles valeurs
						setMasqueEvent(updatedMasqueEvent);
					} else {
						setShowPop(true);
						setStatus(Status_error);
						setMessagePopUp(response.libRetour);
					}
				})
				.catch((error) => {
					// Gère les erreurs ici si nécessaire
					console.error(error);
				});
			
		}
	}, [listJumpEvent.user, masqueJump, masqueEvent]);
	
	const toggleMasque = () => {
		setIsMasque(!isMasque);
	};
	
	let { listJump, listEvent, listEventArch } = listJumpEvent;
	if (!isMasque) {
		listJump = listJump.filter(value => !masqueJump.includes(value.id));
		listEvent = listEvent.filter(value => !masqueEvent.includes(value.id));
	}
	
	const handleTabChange = (key: string) => {
		setActiveTab(key);
	};
	
	
	return (
		<div className="jumpCorps">
			<Tabs
				activeKey={activeTab}
				onSelect={handleTabChange}
			>
				<Tab eventKey="actif" title={t("Jump/Event actif", { ns: "jumpEvent" })}>
					<div id="active_jump">
						<div className={"container-tabs p-1"}>
							<div id={"zone-btn-inscription"}>
								<button className={"btn btn-primary"} onClick={toggleMasque}>
									{isMasque ? t("Remasquer les jumps et events masqués", { ns: "jumpEvent" }) : t("Réafficher les jumps et events masqué", { ns: "jumpEvent" })}
								</button>
							</div>
							<TableJumpEvent
								list={listJump}
								type={0}
								onClickDescription={handleOpenDescription}
								listInscription={listJumpEvent.listInscription}
								masqueList={masqueJump}
								onChangeMasque={handleChangeMasque}
							/>
							<br />
							<TableJumpEvent
								list={listEvent}
								type={1}
								onClickDescription={handleOpenDescription}
								masqueList={masqueEvent}
								onChangeMasque={handleChangeMasque}
							/>
							<Modal show={showPopDesc} onHide={handleClose} centered={true} dialogClassName={"modal_description"} scrollable={true}
								   contentClassName={"modal_description_content"}>
								<Modal.Body>{HTMLParser(description)}</Modal.Body>
								<Modal.Footer>
									<Button variant="secondary" onClick={handleClose}>{t("Fermer", { ns: "app" })}</Button>
								</Modal.Footer>
							</Modal>
						</div>
					</div>
				</Tab>
				<Tab eventKey="archive" title={t("Inscriptions jump/event archivées", { ns: "jumpEvent" })}>
					<div id="active_jump">
						<div className={"container-tabs p-1"}>
							<TableJumpEvent
								list={listJumpEvent.listJumpArch}
								type={0}
								onClickDescription={handleOpenDescription}
								listInscription={listJumpEvent.listInscription}
								masqueList={masqueJump}
								onChangeMasque={handleChangeMasque}
								isArchMode={true}
							/>
							<br />
							<TableJumpEvent
								list={listJumpEvent.listEventArch}
								type={1}
								onClickDescription={handleOpenDescription}
								masqueList={masqueEvent}
								onChangeMasque={handleChangeMasque}
								isArchMode={true}
							/>
						</div>
					</div>
				</Tab>
			</Tabs>
		</div>
	);
}


export function TableJumpEvent({ list, type, onClickDescription, listInscription, masqueList, onChangeMasque, isArchMode = false }: {
	list: EventDTO[] | JumpDTO[],
	type: number,
	onClickDescription: (description: string) => void,
	listInscription?: InscriptionJumpDTO[],
	masqueList: string[],
	onChangeMasque: (id: string, click: boolean, type: number) => void,
	isArchMode?: boolean,
}) {
	const { t } = useTranslation();
	const { general } = useGeneralContext();
	/** type : 0 - Jump, 1 - Event */
	const isJump: boolean = type === 0;
	
	let statutJump;
	if (isJump) {
		statutJump = listInscription ?? [];
	}
	
	return <table>
		<thead>
		<tr>
			<th className="th_nomJump">{(isJump) ? t("Nom du jump", { ns: "jumpEvent" }) : t("Nom de l'event", { ns: "jumpEvent" })}</th>
			<th className="th_commu">
				<TooltipGH>
					<span className={"infoBulle"}>🌍</span>
					<span className={"info"}>{t("Communauté", { ns: "jumpEvent" })}</span>
				</TooltipGH>
			</th>
			<th className="td_orgaJump">{t("Type de ville", { ns: "jumpEvent" })}</th>
			{isJump && <th className="td_orgaJump">{t("Objectif(s)", { ns: "jumpEvent" })}</th>}
			<th className="td_orgaJump">{t("Organisateur(s)", { ns: "jumpEvent" })}</th>
			<th className="td_dateJump">{HTMLParser(t("Date de fin <br/> inscription", { ns: "jumpEvent" }))}</th>
			<th className="td_dateJump">{HTMLParser(t("Date de jump <br/> approximative", { ns: "jumpEvent" }))}</th>
			<th className="td_lienInscipJump">{HTMLParser(t("Lien vers <br/> la fiche", { ns: "jumpEvent" }))}</th>
			{isJump && <th className="td_lienInscipJump">{HTMLParser(t("Statut <br/> de mon inscription", { ns: "jumpEvent" }))}</th>}
			{!isArchMode && <th className="td_masquageJump">{HTMLParser((isJump) ? t("Masquer <br/> jump", { ns: "jumpEvent" }) : t("Masquer <br/> event", { ns: "jumpEvent" }))}</th>}
		</tr>
		</thead>
		<tbody>
		{Object.values(list).map((value) => {
			let listOrga = [];
			if (isJump) {
				if ("gestionnaires" in value) {
					listOrga = Object.values(value.gestionnaires).map((user: UserDTO) => user.pseudo).sort((a: string, b: string) => a.localeCompare(b));
				}
			} else {
				if ("organisateurs" in value) {
					listOrga = Object.values(value.organisateurs).map((user: UserDTO) => user.pseudo).sort((a: string, b: string) => a.localeCompare(b));
				}
			}
			const orga = listOrga.length === 0 ? " " : (listOrga.length === 1
				? listOrga[0]
				: listOrga.slice(0, -1).join(", ") + " " + t("et", { ns: "app" }) + " " + listOrga[listOrga.length - 1]);
			return <tr id={"jump_" + value.id} key={((isJump) ? "jump_" : "event_") + value.id}>
				<td className={"td_nomJump"}>{value.nom} {value.description !== null && <span onClick={() => onClickDescription(value.description)}><i className="fa fa-info-circle"></i></span>}</td>
				<td className="th_commu"><SvgDrapeau drapeau={value["community"]} /></td>
				<td className="td_typeJump">
					<TooltipGH>
                        <span className={"infoBulle d-flex justify-content-center gap-1"}>
                            <SvgIcone icone={value.type_ville?.icon} />
                        </span>
						<span className={"info"}>{t(value.type_ville?.nom, { ns: "jump" })}</span>
					</TooltipGH>
				</td>
				{isJump && <td className="td_objectifgaJump">
                    <span className={"d-flex gap-2"}>{Object.values(value.objectif).map((objectif: ObjectifVillePrototypeDTO) => {
						return <TooltipGH key={objectif.id}>
                        <span className={"infoBulle"}>
                            <SvgIcone icone={objectif.icon} />
                        </span>
							<span className={"info"}>{t(objectif.nom, { ns: "jump" })}</span>
						</TooltipGH>;
					})}
                </span></td>}
				<td className="td_orgaJump">{orga}</td>
				<td className="td_dateJump">{formatInTimeZone(new Date(Date.parse((isJump) ? value["date_fin_inscription"] : value["fin_inscription_date_at"])), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
				<td className="td_dateJump">{formatInTimeZone(new Date(Date.parse((isJump) ? value["date_approx_jump"] : value["event_begin_at"])), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
				<td className="td_lienInscipJump">
					{isArchMode ? (isJump ? (<Link to={"/jump/inscription/archive/" + value.id} style={{ textDecoration: "none", color: "inherit" }}>
						<button type={"button"}>{t("Voir", { ns: "jumpEvent" })}</button>
					</Link>) : (<Link to={"/event/archive/" + value.id} style={{ textDecoration: "none", color: "inherit" }}>
						<button type={"button"}>{t("Voir", { ns: "jumpEvent" })}</button>
					</Link>)) : (
						(isJump) ? (
							// Condition pour le bouton "Jump"
							statutJump[value.id] === undefined ? (
								<Link to={"/jump/inscription/" + value.id} style={{ textDecoration: "none", color: "inherit" }}>
									<button type={"button"}>{t("S'inscrire", { ns: "jumpEvent" })}</button>
								</Link>
							) : (statutJump[value.id]?.statut.id ?? 99) === 99 ? (
								<Link to={"/jump/inscription/" + value.id} style={{ textDecoration: "none", color: "inherit" }}>
									<button type={"button"}>{t("Se réinscrire", { ns: "jumpEvent" })}</button>
								</Link>
							) : (
								<Link to={"/jump/inscription/" + value.id} style={{ textDecoration: "none", color: "inherit" }}>
									<button type={"button"}>{t("Modifier", { ns: "jumpEvent" })}</button>
								</Link>
							)
						) : (
							<Link to={"/event/" + value.id} style={{ textDecoration: "none", color: "inherit" }}>
								<button type={"button"}>{t("Vers Event", { ns: "jumpEvent" })}</button>
							</Link>
						)
					)}
				</td>
				{isJump && <td className={"td_lienInscipJump"}>{t(statutJump[value.id]?.statut.nom ?? "", { ns: "jump" })}</td>}
				{!isArchMode && <td className="td_masquageJump">
					<input type="checkbox" checked={masqueList.includes(value.id)} onChange={(event) => onChangeMasque(value.id, event.target.checked, type)} />
				</td>}
			</tr>;
		})}
		</tbody>
	</table>;
	
	
}

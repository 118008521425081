import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { BsCheck2, BsPencilSquare, BsPlusCircle, BsTrash3Fill }        from "react-icons/bs";
import OverlayTrigger                                                  from "react-bootstrap/OverlayTrigger";
import Tooltip                                                         from "react-bootstrap/Tooltip";
import { EstimationTdgDTO }                                            from "../../../types/models/estimationTdg.dto";
import { useTranslation }                                              from "react-i18next";

interface EstimationsAttaqueProps {
	jour: number,
	type_estim: number,
	estimations: EstimationTdgDTO[],
	vueDetail: "textarea" | "inputGroup";
	onAddEstimation: (estimation: EstimationTdgDTO) => void;
	onChangeEstimation: (estimations: EstimationTdgDTO[]) => void;
}

const EstimationsAttaque = forwardRef((props: EstimationsAttaqueProps, ref) => {
	const { t } = useTranslation();
	const [day, setDay] = useState(props.jour);
	const [newEstim, setNewEstim] = useState<EstimationTdgDTO>({
		day            : props.jour,
		type_estim     : props.type_estim,
		val_pourcentage: 0,
		min_estim      : 0,
		max_estim      : 0,
	});
	const [estimations, setEstimations] = useState(props.estimations);
	const [estimEdit, setEstimEdit] = useState([]);
	
	useEffect(() => {
		if (estimations !== props.estimations) {
			setEstimations(props.estimations);
		}
	}, [props.estimations]);
	
	useEffect(() => {
		if (day !== props.jour) {
			setDay(props.jour);
		}
	}, [props.jour]);
	
	const handleInputChange = (index: number | null, field: string, value: number) => {
		if (index === null) {
			setNewEstim({ ...newEstim, ...{ [field]: isNaN(value) ? undefined : value } });
		} else {
			const updatedEstimations = [...estimations];
			updatedEstimations[index] = {
				...updatedEstimations[index],
				[field]: value,
			};
			setEstimations(updatedEstimations);
		}
		
	};
	
	const handlePercentageChange = (index: number | null, event: React.ChangeEvent<HTMLInputElement>) => {
		let newPercentage = parseInt(event.target.value, 10);
		
		if (!isNaN(newPercentage)) {
			if (newPercentage < 0) {
				newPercentage = 0;
			} else if (newPercentage > 100) {
				newPercentage = 100;
			}
		}
		handleInputChange(index, "val_pourcentage", newPercentage);
	};
	
	const handleMinChange = (index: number | null, event: React.ChangeEvent<HTMLInputElement>) => {
		let newMin = parseInt(event.target.value);
		
		if (!isNaN(newMin)) {
			if (newMin < 0) {
				newMin = 0;
			}
		}
		handleInputChange(index, "min_estim", newMin);
	};
	
	const handleMaxChange = (index: number | null, event: React.ChangeEvent<HTMLInputElement>) => {
		let newMax = parseInt(event.target.value);
		
		if (!isNaN(newMax)) {
			if (newMax < 0) {
				newMax = 0;
			}
		}
		handleInputChange(index, "max_estim", newMax);
	};
	
	const handleEditEstimation = (index: number) => {
		// Ajoute l'index à estim_edit s'il n'est pas déjà présent
		const updatedEstimEdit = estimEdit.includes(index)
			? estimEdit
			: [...estimEdit, index];
		
		setEstimEdit(updatedEstimEdit);
	};
	
	const handleRemoveEstimation = (index: number) => {
		const updatedEstimations = [...props.estimations];
		updatedEstimations.splice(index, 1);
		
		if (updatedEstimations.length === 0) {
			props.onChangeEstimation([]);
		} else {
			props.onChangeEstimation(updatedEstimations);
		}
	};
	
	const handleSaveEdit = (index: number) => {
		
		const updatedEstimations = [...props.estimations];
		setEstimEdit(estimEdit.filter(i => i !== index));
		props.onChangeEstimation(updatedEstimations);
		
	};
	
	const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		const { type_estim } = props;
		const textareaContent = event.target.value;
		const estimationLines = textareaContent.split("\n");
		const estimations: EstimationTdgDTO[] = estimationLines.map(line => {
			const regexResult = line.match(/(\d+)% : (\d+) - (\d+)/);
			if (regexResult) {
				const [, percentage, min, max] = regexResult;
				if (percentage === undefined || min === undefined || max === undefined) {
					return null;
				}
				return {
					day            : day,
					type_estim     : type_estim,
					val_pourcentage: parseInt(percentage),
					min_estim      : parseInt(min),
					max_estim      : parseInt(max),
					isEditing      : false,
				};
			}
			return null;
		}).filter(estimation => estimation !== null);
		
		props.onChangeEstimation(estimations);
		
	};
	
	const isDuplicatePercentage = (percentage: number, currentIndex: number | null) => {
		return estimations.some((estimation, index) => {
			if (currentIndex !== null && index === currentIndex) {
				return false; // On ignore l'index pour l'édition pour pouvoir sauvegarder
			}
			return estimation.val_pourcentage === percentage;
		});
	};
	
	const resetFormFields = () => {
		setNewEstim({
			day            : day,
			type_estim     : props.type_estim,
			val_pourcentage: 0,
			min_estim      : 0,
			max_estim      : 0,
		});
		
	};
	
	const resetEstimDay = () => {
		setNewEstim({
			day            : day,
			type_estim     : props.type_estim,
			val_pourcentage: 0,
			min_estim      : 0,
			max_estim      : 0,
		});
	};
	
	const renderInputGroup = () => {
		const { onAddEstimation } = props;
		const disbled_add = newEstim.min_estim === 0 || newEstim.max_estim === 0 || isDuplicatePercentage(newEstim.val_pourcentage, null);
		return (
			<div className={"zone_saisie_input_tdg"}>
				{estimations.map((estimation, index) => (
					<div key={index} className={"group_input_tdg"}>
						{estimEdit.includes(index) ? (
							<div>
								<input
									className={"input_tdg_pct"}
									type="number"
									value={estimation.val_pourcentage}
									placeholder={t("qualité", { ns: "hotel" })}
									onChange={(event) => handlePercentageChange(index, event)}
								/>
								<span>% :</span>
								<input
									className={"input_tdg_min"}
									placeholder={t("min", { ns: "hotel" })}
									type="number"
									value={estimation.min_estim}
									onChange={(event) => handleMinChange(index, event)}
								/>
								<span>-</span>
								<input
									className={"input_tdg_max"}
									placeholder={t("max", { ns: "hotel" })}
									type="number"
									value={estimation.max_estim}
									onChange={(event) => handleMaxChange(index, event)}
								/>
								<button onClick={() => handleSaveEdit(index)} className="btn btn-link p-0"
										disabled={isDuplicatePercentage(estimation.val_pourcentage, index)}>
									<OverlayTrigger
										placement="top"
										overlay={<Tooltip>{t("Enregistrer l'estimation", { ns: "hotel" })}</Tooltip>}
										delay={{ show: 250, hide: 400 }}>
											<span className={`icon-green icon_tdg ${isDuplicatePercentage(estimation.val_pourcentage, index) ? "icon-disabled" : ""}`}>
												<BsCheck2 />
											</span>
									</OverlayTrigger>
								</button>
							</div>
						) : (
							<div>
								<span>{estimation.val_pourcentage}% : {estimation.min_estim} - {estimation.max_estim}</span>
								<button onClick={() => handleEditEstimation(index)} className="btn btn-link p-0">
									<OverlayTrigger
										placement="top"
										overlay={<Tooltip>{t("Editer l'estimation", { ns: "hotel" })}</Tooltip>}
										delay={{ show: 250, hide: 400 }}>
											<span className="icon-green">
												<BsPencilSquare />
											</span>
									</OverlayTrigger>
								</button>
								<button onClick={() => handleRemoveEstimation(index)} className="btn btn-link p-0">
									<OverlayTrigger
										placement="top"
										overlay={<Tooltip>{t("Supprimer l'estimation", { ns: "hotel" })}</Tooltip>}
										delay={{ show: 250, hide: 400 }}>
											<span className="icon-red">
												<BsTrash3Fill />
											</span>
									</OverlayTrigger>
								</button>
							</div>
						)}
					</div>
				))}
				<div className={"group_add_estim_tdg"}>
					<input
						className={"input_tdg_pct"}
						type="number"
						placeholder={t("qualité", { ns: "hotel" })}
						value={newEstim.val_pourcentage === 0 ? "" : newEstim.val_pourcentage}
						onChange={(event) => handlePercentageChange(null, event)}
					/>
					<span>% :</span>
					<input
						className={"input_tdg_min"}
						type="number"
						placeholder={t("min", { ns: "hotel" })}
						value={newEstim.min_estim === 0 ? "" : newEstim.min_estim}
						onChange={(event) => handleMinChange(null, event)}
					/>
					<span>-</span>
					<input
						className={"input_tdg_max"}
						type="number"
						placeholder={t("max", { ns: "hotel" })}
						value={newEstim.max_estim === 0 ? "" : newEstim.max_estim}
						onChange={(event) => handleMaxChange(null, event)}
					/>
					<button onClick={() => onAddEstimation(newEstim)} className={"btn btn-link p-0"} disabled={disbled_add}>
						<OverlayTrigger
							placement="top"
							overlay={<Tooltip>{t("Ajouter une estimation", { ns: "hotel" })}</Tooltip>}
							delay={{ show: 250, hide: 400 }}>
							<span className={`icon-green icon_tdg ${disbled_add ? "icon-disabled" : ""}`}>
								<BsPlusCircle />
							</span>
						</OverlayTrigger>
					</button>
				</div>
			
			</div>
		);
	};
	
	const renderTextarea = () => {
		const textareaContent = estimations.map(estimation => {
			if (estimation.val_pourcentage === undefined || estimation.min_estim === undefined || estimation.max_estim === undefined) {
				return null;
			}
			return `${estimation.val_pourcentage}% : ${estimation.min_estim} - ${estimation.max_estim}`;
		}).join("\n");
		
		return (
			<textarea
				value={textareaContent}
				placeholder={t("Saisir sous le format 99% : 99999 - 99999", { ns: "hotel" })}
				onChange={handleTextareaChange} // Add this line
			/>
		);
	};
	
	const { vueDetail } = props;
	
	useImperativeHandle(ref, () => ({
		resetEstimDay, resetFormFields,
	}));
	
	return <div ref={ref}>
		{vueDetail === "textarea" ? renderTextarea() : renderInputGroup()}
	</div>;
});

export default EstimationsAttaque;
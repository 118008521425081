import React                                                                  from "react";
import SvgIcone                                                               from "../../../components/generality/SvgIcone";
import { useGeneralContext }                                                  from "../../../types/Context/GeneralContext";
import HTMLParser                                                             from "html-react-parser";
import { AffNbrIcon }                                                         from "../../../components/generality/ComposantGeneral";
import { useExpeditionContext }                                               from "../../../types/Context/Outils/Expedition/ExpeditionContext";
import { calculPdc, calculPdcExpe, recuperationCitoyensPreinscritExpedition } from "./ExpeditionUtils";
import { ConsigneExpeditionDTO }                                              from "../../../types/models/consigneExpedition.dto";
import { useTranslation }                                                     from "react-i18next";

const ID_RAMASSAGE = 6;

export default function AppercuExpeditions({ id_exp, onClose, onViewExpedition }: { id_exp: string, onClose: () => void, onViewExpedition: (idTrace: string) => void }) {
	const { t } = useTranslation();
	const { expeditions } = useExpeditionContext();
	const { general } = useGeneralContext();
	
	const expedition = expeditions.find((exp) => exp.id === id_exp);
	
	
	return <div className="appercu-expeditions">
		{expedition.expedition_parts.sort((exp_a, exp_b) => exp_a.number < exp_b.number ? -1 : 1).map((expedition_part, index_part) => {
			const preinscrit = recuperationCitoyensPreinscritExpedition(expedition);
			
			return <div key={expedition_part.id} className={"zone_expedition_expe_part"}>
				<table className={"inscription_expedition"}>
					<thead>
					<tr className={"ligne_inscription_expedition"}>
						<th rowSpan={2} className={"cols40_expedition"}><SvgIcone icone={expedition.type_expe.icon} classIcone={"itemExpe"} /></th>
						<th colSpan={7} style={{ fontSize: "18px" }}>
							<div className={"expedition_titre"}>
								{expedition_part.trace !== null && <span onClick={() => onViewExpedition(expedition_part.trace.id)} style={{ cursor: "pointer" }}><SvgIcone icone={"r_explor"} /></span>}
								{expedition.type_expe.id === ID_RAMASSAGE ? (<span>{expedition.nom} - {index_part + 1}</span>) : (<span>{expedition.nom}</span>)}
								<span className={"expedition_titre"}><span>-</span><span className={"d-flex gap-1 align-items-center"}><span>{expedition_part.pa}</span><SvgIcone icone={"h_pa"} /></span></span>
							</div>
						</th>
						<th rowSpan={2} className={"pdc_zone_tab"}>
							<div className={(calculPdcExpe(expedition, general) >= (expedition?.min_pdc ?? 0)) ? "color-green" : "color-red"}>
								<span>{expedition.min_pdc}</span>
								<span>{t("PDC", { ns: "hotel" })}</span>
							</div>
						</th>
					</tr>
					<tr className={"ligne_inscription_expedition"}>
						<th colSpan={7}>
							{preinscrit.length > 0 && <>
								<span>{t("Préinscrits sur cette expédition :", { ns: "hotel" })}</span>
								<span className={"preinscrit_expeditions"}>{preinscrit.map((citoyen) => {
									return <span key={citoyen.citoyen.id} className={"preinscrit"}>
                                        <SvgIcone icone={"h_" + citoyen.job?.icon} />
                                        <span>
                                            <span className={citoyen.ban ? "color-red" : ""}>{citoyen.citoyen.pseudo}</span>
											{citoyen.ban && <SvgIcone icone={"h_ban"} />}
                                        </span>
                                    </span>;
								})}</span>
							</>}
						</th>
					</tr>
					<tr className={"ligne_inscription_expedition"}>
						<th className={"cols40_expedition"}></th>
						<th className={"pseudo_expedition"}>{t("Pseudo", { ns: "hotel" })}</th>
						<th className={"cols40_expedition"}><span className={"d-flex justify-content-center"}><SvgIcone icone={"h_ban"} /></span></th>
						<th className={"cols40_expedition"}><span className={"d-flex justify-content-center"}><SvgIcone icone={"r_dwater"} /></span></th>
						<th className={"cols20_expedition"}><span className={"d-flex justify-content-center"}><SvgIcone icone={"h_pa"} /></span></th>
						<th className={"cols20_expedition"}><span className={"d-flex justify-content-center"}><SvgIcone icone={"h_pe"} /></span></th>
						<th className={"cols20_expedition"}><span className={"d-flex justify-content-center"}><SvgIcone icone={"h_guard"} /></span></th>
						<th className={"sac_expedition"}>{t("Sac", { ns: "hotel" })}</th>
						<th className={"ah_expedition"}>{t("Action héroïque", { ns: "hotel" })}</th>
					</tr>
					</thead>
					<tbody>
					{expedition_part.expeditionnaires.sort((exp_a, exp_b) => exp_a.position < exp_b.position ? -1 : 1).map((exped) => {
						return <tr key={exped.id} className={"ligne_inscription_expedition ligne_citoyen_user"}>
							<td className={"cols40_expedition"}>
								<div className={"zone_td_display"}>
									{exped.job && <SvgIcone icone={"h_" + exped.job?.icon} />}
								</div>
							</td>
							<td className={"pseudo_expedition"}>
								<div className={"zone_td_display"}>
                                    <span className={"pseudo_expedition_liste"}>
                                        <span className={exped.citoyen?.ban ? "color-red" : ""}>{exped.citoyen?.citoyen.pseudo}</span>
										{exped.citoyen?.ban && <SvgIcone icone={"h_ban"} />}
                                    </span>
								</div>
							</td>
							<td className={"cols40_expedition"}>
								<div className={"zone_td_display"}>
									{exped.for_banni ? <SvgIcone icone={"h_ban"} /> : <i className="fa-solid fa-xmark color-red"></i>}
								</div>
							</td>
							<td className={"cols40_expedition"}>
								<div className={"zone_td_display"}>
									{exped.soif ? (<SvgIcone icone={"r_dwater"} />) : (<SvgIcone icone={"status_clean"} />)}
								</div>
							</td>
							<td className={"cols20_expedition"}>
								<div className={"zone_td_display"}>{exped.pa_base ?? 6}</div>
							</td>
							<td className={"cols20_expedition"}>
								<div className={"zone_td_display"}>{exped.pe_base ?? 6}</div>
							</td>
							<td className={"cols20_expedition"}>
								<div className={"zone_td_display"}>{calculPdc(exped?.citoyen ?? null, expedition_part, general)}</div>
							</td>
							<td className={"sac_expedition"}>
								<div className={"zone_sac_expeditionnaire"}>
									<div className={"sac_expeditionnaire"}>{exped.sac.map((sacItems) => {
										return <div key={`obj_${sacItems.item.id}_${sacItems.broken ? 1 : 0}`}>
											<AffNbrIcon
												item={sacItems.item}
												broken={sacItems.broken}
												nbr={sacItems.nbr}
												classSpecifique={"itemSacOutilsExpedition"}
											/>
										</div>;
									})}</div>
								</div>
							</td>
							<td className={"ah_expedition"}>
								<div className={"zone_td_display"}>
									<SvgIcone icone={exped.action_heroic?.icon} />
									<span>{exped.action_heroic?.nom}</span>
								</div>
							</td>
						</tr>;
					})}
					{expedition_part.description !== null && <>
						<tr style={{ height: "5px" }}></tr>
						<tr className={"ligne_inscription_expedition"}>
							<th colSpan={2}>{t("Description", { ns: "hotel" })}</th>
							<td colSpan={6} className={"description_expedition_part"}>{HTMLParser(expedition_part.description)}</td>
						</tr>
					</>}
					{expedition_part.consignes.length > 0 && <>
						<tr className={"ligne_inscription_expedition"}>
							<th colSpan={8}>{t("Consignes", { ns: "hotel" })}</th>
						</tr>
						{expedition_part.consignes.sort((consigne_a: ConsigneExpeditionDTO, consigne_b: ConsigneExpeditionDTO) => {
							return consigne_a.ordre_consigne < consigne_b.ordre_consigne ? -1 : 1;
						}).map((consigne, indexConsigne) => {
							return <tr key={indexConsigne} className={"ligne_inscription_expedition"}>
								<td colSpan={1}>
									<div>
										{consigne?.fait ? <i className="fa-solid fa-check color-green"></i> : <i className="fa-solid fa-xmark color-red"></i>}
									</div>
								</td>
								<td colSpan={7} className={"consigne_zone_checkbox"}>
									<div>
										<div>{consigne?.zone?.x_rel}/{consigne?.zone?.y_rel}</div>
										<div>:</div>
										<div className={"text_consigne_zone_consigne"}>{HTMLParser(consigne.text)}</div>
									</div>
								</td>
							</tr>;
						})}
					</>}
					</tbody>
				</table>
			</div>;
		})}
		<div>
			<button onClick={onClose} className={"btn btn-xs btn-primary"}>{t("Fermer", { ns: "ville" })}</button>
		</div>
	</div>;
	
	
}


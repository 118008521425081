import React, { createContext, useContext, useEffect, useState } from "react";
import { CaseRuine, RuinesGeneralType }                          from "../../../types/components/ville/Ruines.type";
import HTMLParser                                                from "html-react-parser";
import SvgIcone                                                  from "../../../components/generality/SvgIcone";
import RuineZoom, { calculCase, directionMap }                   from "./RuineZoom";
import RuineListing                                              from "./RuineListing";
import RuineMap                                                  from "./RuineMap";
import { RuineApi }                                              from "../../../services/api/RuineApi";
import RangeSlider                                               from "react-bootstrap-range-slider";
import PopUpMajCaseRuine                                         from "./PopUpMajCaseRuine";
import { AffNbrIcon }                                            from "../../../components/generality/ComposantGeneral";
import { Helmet }                                                from "react-helmet-async";
import { formatInTimeZone }                                      from "date-fns-tz";
import { Status_error, Status_success, usePopUp }                from "../../../types/Context/PopUpContext";
import { useGeneralContext }                                     from "../../../types/Context/GeneralContext";
import { useGHContext }                                          from "../../../types/Context/GHContext";
import { RuinesObjetsDTO }                                       from "../../../types/models/ruinesObjets.dto";
import { useTranslation }                                        from "react-i18next";
import { UserPersoCouleurDTO }                                   from "../../../types/models/userPersoCouleur.dto";
import TooltipGH                                                 from "../../../components/utils/TooltipGH";

interface RuineContextType {
	plan: CaseRuine[][][];
	setPlan: (plan: CaseRuine[][][]) => void;
	filAriane: (number | null)[][][][];
	setFilAriane: (ariane: (number | null)[][][][]) => void;
	x: number;
	setX: (x: number) => void;
	y: number;
	setY: (y: number) => void;
	z: number;
	setZ: (z: number) => void;
	selObjet?: number[];
}

const RuineContext = createContext<RuineContextType>({
	plan        : [],
	setPlan     : () => {
	},
	filAriane   : [],
	setFilAriane: () => {
	},
	x           : 7,
	setX        : () => {
	},
	y           : 0,
	setY        : () => {
	},
	z           : 0,
	setZ        : () => {
	},
	selObjet    : [],
});

export function useRuineContext() {
	return useContext(RuineContext);
}

const generateCSSUser = (user: UserPersoCouleurDTO) => {
	return `
		.ruine_escalier_up {color:${user.color_stair_up};}
		.ruine_escalier_down {color: ${user.color_stair_down}; }
		.selCaseRuineObjet{color:black;background-color:${user.color_select_objet_ruine};}
		.objetRuineSelected{border:3px solid ${user.color_select_objet_ruine};}
		`;
};

export default function Ruine(props: { ruine: RuinesGeneralType }) {
	const { t } = useTranslation();
	const trad_porte = props.ruine.trad_porte;
	const ruine = props.ruine.ruine;
	const ruineCases = props.ruine.ruineCases;
	
	const [allObjet, setAllObjet] = useState(props.ruine.allObjet);
	const [coord, setCoord] = useState(props.ruine.coord);
	const [filAriane, setFilAriane] = useState(props.ruine.filAriane);
	const [listRuines, setListRuines] = useState(props.ruine.listRuines);
	const [modAriane, setModAriane] = useState(false);
	const [modMapping, setModMapping] = useState(false);
	const [modCorrectif, setModCorrectif] = useState(false);
	const [plan, setPlan] = useState(ruineCases);
	const [showAriane, setShowAriane] = useState(false);
	const [showGestionPorte, setShowGestionPorte] = useState(false);
	const [showPopUpObjet, setShowPopUpObjet] = useState(false);
	const [x, setX] = useState(7);
	const [y, setY] = useState(0);
	const [z, setZ] = useState(0);
	const [selObjet, setSelObjet] = useState<number[]>([]);
	const [zombie, setZombie] = useState(0);
	const [maxEtage, setMaxEtage] = useState(props.ruine.maxEtage);
	const [retourMajPopUp, setRetourMajPopUp] = useState(null);
	const [typeRetourMajPopup, setTypeRetourMajPopup] = useState(null);
	const { setStatus, setMessagePopUp, setShowPop, handleClose } = usePopUp();
	const { general } = useGeneralContext();
	const { triggerRefresh } = useGHContext();
	
	const reinitialisationRuine = () => {
		triggerRefresh();
	};
	
	useEffect(() => {
		// Mettez à jour le nombre de zombies à la coordonnée x, y
		setPlan((plan) => updatePlan(x, y, z, plan, { nbr_zombie: zombie }));
	}, [zombie]);
	
	useEffect(() => {
		const caseRuine = plan[z][y][x];
		if (caseRuine.type_porte === null) {
			setShowGestionPorte(false);
		} else {
			setShowGestionPorte(true);
		}
		
		setZombie(caseRuine.nbr_zombie);
	}, [x, y, z]);
	
	useEffect(() => {
		const planReinit = [...ruineCases];
		setCoord(props.ruine.coord);
		setFilAriane(props.ruine.filAriane);
		setListRuines(props.ruine.listRuines);
		setPlan(planReinit);
		setX(7);
		setY(0);
		setZ(0);
		setZombie(0);
		setAllObjet(props.ruine.allObjet);
		setMaxEtage(props.ruine.maxEtage);
	}, [props.ruine]);
	
	const handleAddCoordFilAriane = (xCoord: number[], yCoord: number[], zCoord: number[]) => {
		const coordMod = [...coord];
		
		if (coordMod.length === 0) {
			coordMod.push([xCoord, yCoord, zCoord]);
		} else {
			const dernierOccurs = coordMod[coordMod.length - 1];
			if (
				xCoord === dernierOccurs[0] &&
				yCoord === dernierOccurs[1] &&
				zCoord === dernierOccurs[2]
			) {
				setMessagePopUp(t("Vous venez de sélectionner la même case, ce n'est pas possible !", { ns: "ruine" }));
				setStatus(Status_error);
				setShowPop(true);
			} else if (xCoord !== dernierOccurs[0] && yCoord !== dernierOccurs[1]) {
				setMessagePopUp(t("Veuillez sélectionner une case alignée avec le dernier point de votre fil d'Ariane.", { ns: "ruine" }));
				setStatus(Status_error);
				setShowPop(true);
			} else {
				if (
					((xCoord !== dernierOccurs[0] && yCoord === dernierOccurs[1]) || (xCoord === dernierOccurs[0] && yCoord !== dernierOccurs[1])) &&
					zCoord !== dernierOccurs[2]
				) {
					coordMod.push([dernierOccurs[0], dernierOccurs[1], zCoord]);
				}
				coordMod.push([xCoord, yCoord, zCoord]);
			}
		}
		
		setCoord(coordMod);
	};
	
	const onUpdatePlanRuine = (ruineId: string, mapId: number, plan: CaseRuine[][][]) => {
		const ruineApi = new RuineApi();
		
		ruineApi
			.majPlan({ ruineId: ruineId, mapId: mapId, plan: plan.flat().flat() })
			.then((response) => {
				if (response.codeRetour === 0) {
					setStatus(Status_success);
					setShowPop(true);
					setMessagePopUp(response.libRetour);
					setListRuines(response.zoneRetour.listRuines);
					triggerRefresh();
					
					setTimeout(() => {
						handleClose();
					}, 1000);
				} else {
					setStatus(Status_error);
					setShowPop(true);
					setMessagePopUp(response.libRetour);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};
	
	const onUpdateFilAriane = (ruineId: string, mapId: number, coord: number[][][]) => {
		const ruineApi = new RuineApi();
		
		ruineApi
			.majAriane({ ruineId: ruineId, mapId: mapId, coord: coord })
			.then((response) => {
				if (response.codeRetour === 0) {
					setStatus(Status_success);
					setShowPop(true);
					setMessagePopUp(response.libRetour);
					
					setTimeout(() => {
						handleClose();
					}, 1000);
				} else {
					setStatus(Status_error);
					setShowPop(true);
					setMessagePopUp(response.libRetour);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};
	
	const onUpdateObjetCaseRuine = (ruineId: string, mapId: number, caseRuine: CaseRuine, plan: CaseRuine[][][]) => {
		const ruineApi = new RuineApi();
		// On met à jour le plan dans un premier temps puis on met à jour les objets
		ruineApi.majPlan({ ruineId: ruineId, mapId: mapId, plan: plan.flat().flat() }).then((response) => {
			if (response.codeRetour === 0) {
				setListRuines(response.zoneRetour.listRuines);
				ruineApi.majCaseObjet(ruineId, mapId, caseRuine)
					.then((response) => {
						const planMod = [...plan];
						planMod[z][y][x] = response.data.ruineCase;
						setPlan(planMod);
						setAllObjet(response.data.allRuineObjets);
						setRetourMajPopUp(t("Mise à jour OK.", { ns: "app" }));
						setTypeRetourMajPopup(0);
					})
					.catch((error) => {
						setRetourMajPopUp(error.data.error);
						setTypeRetourMajPopup(1);
					});
			} else {
				setStatus(Status_error);
				setShowPop(true);
				setMessagePopUp(response.libRetour);
			}
		}).catch((error) => {
			console.error(error);
		});
		
		
	};
	
	const updatePorteRuine = (typePorte: null | string) => {
		setPlan((plan) => updatePlan(x, y, z, plan, { type_porte: typePorte }));
	};
	
	const addUpperFloorWithStaircase = (type_escalier: string) => {
		// verification si l'étage supérieur n'existe pas déjà
		const verif_etage_supp = plan[z + 1];
		const planMod = [...plan];
		if (verif_etage_supp === undefined && type_escalier === "up") {
			planMod[z + 1] = [];
			for (let yInit = 0; yInit < 14; yInit++) {
				planMod[z + 1][yInit] = [];
				for (let xInit = 0; xInit < 15; xInit++) {
					planMod[z + 1][yInit][xInit] = {
						x            : xInit,
						y            : yInit,
						z            : z + 1,
						type_case    : 0,
						nbr_zombie   : null,
						type_escalier: null,
						type_porte   : null,
						items        : [],
						update_by    : null,
						update_at    : null,
					};
				}
			}
			planMod[z + 1][y][x].type_escalier = "down";
			planMod[z][y][x].type_escalier = "up";
			planMod[z + 1][y][x].type_case = 17;
			planMod[z + 1][y][x].nbr_zombie = 0;
			
			setMaxEtage(planMod.length);
		} else if (
			planMod[z][y][x].type_escalier === null &&
			type_escalier === "up"
		) {
			if (
				planMod[z + 1][y][x].type_case === null ||
				planMod[z + 1][y][x].type_case === 0
			) {
				planMod[z + 1][y][x].type_case = 17;
			}
			planMod[z + 1][y][x].type_escalier = "down";
			planMod[z][y][x].type_escalier = "up";
		} else if (
			planMod[z][y][x].type_escalier === null &&
			type_escalier === "down"
		) {
			if (
				planMod[z - 1][y][x].type_case === null ||
				planMod[z - 1][y][x].type_case === 19
			) {
				planMod[z - 1][y][x].type_case = 16;
			}
			planMod[z - 1][y][x].type_escalier = "up";
			planMod[z][y][x].type_escalier = "down";
		}
		
		setPlan(planMod);
	};
	
	const removeFloor = () => {
		// Créer une copie de la structure de données
		const planMod = [...plan];
		
		if (plan[z][y][x].type_escalier !== null) {
			if (plan[z][y][x].type_escalier === "down") {
				// Vérifier si l'étage existe, on supprime seulement si et uniquement si, on n'a pas développé l'étage de la ruine
				if (plan[z][y][x].type_case === 16) {
					// Supprimer l'étage spécifié
					planMod.splice(z, 1);
					
					planMod[z - 1][y][x].type_escalier = null;
					setMaxEtage(planMod.length);
					setZ(z - 1);
				} else {
					planMod[z - 1][y][x].type_escalier = null;
					planMod[z][y][x].type_escalier = null;
				}
			} else if (plan[z][y][x].type_escalier === "up") {
				// Vérifier si l'étage existe, on supprime seulement si et uniquement si, on n'a pas développé l'étage de la ruine
				if (plan[z + 1] !== undefined && plan[z + 1][y][x].type_case === 16) {
					// Supprimer l'étage spécifié
					planMod.splice(z + 1, 1);
					
					planMod[z][y][x].type_escalier = null;
					
					setMaxEtage(planMod.length);
				} else {
					planMod[z + 1][y][x].type_escalier = null;
					planMod[z][y][x].type_escalier = null;
				}
			}
		}
		
		// Mettre à jour l'état avec le nouveau plan
		setPlan(planMod);
	};
	
	const handleChangeFloor = (isForUp: boolean) => {
		const zMod = isForUp ? z + 1 : z - 1;
		if (plan[zMod][y][x].nbr_zombie === null) {
			const planMod = [...plan];
			planMod[zMod][y][x].nbr_zombie = 0;
			
			setPlan(planMod);
		}
		setZ(zMod);
	};
	
	const reinitZombieOnRuine = () => {
		// Copie profonde si nécessaire (si les objets internes sont complexes)
		const newPlan = plan.map((layer) => layer.map((row) => row.map((caseRuine) => ({ ...caseRuine, nbr_zombie: null }))));
		setPlan(newPlan); // Mettre à jour l'état avec la nouvelle structure
	};
	const reinitZombieOnlyZeroOnRuine = () => {
		// Copie profonde si nécessaire (si les objets internes sont complexes)
		const newPlan = plan.map((layer) => layer.map((row) => row.map((caseRuine) => ({ ...caseRuine, nbr_zombie: caseRuine.nbr_zombie === 0 ? null : caseRuine.nbr_zombie }))));
		setPlan(newPlan); // Mettre à jour l'état avec la nouvelle structure
	};
	
	const handleValiderCorrection = () => {
		let planMod = [...plan];
		// On balaye le plan pour récupérer les corrections à effectuer
		planMod.forEach((etage) => {
			etage.forEach((ligne) => {
				ligne.forEach((caseRuine) => {
					if (caseRuine.correction && caseRuine.type_case !== 0) {
						let affGauche = "";
						let affDroite = "";
						let affHaut = "";
						let affBas = "";
						// On récupère l'affichage de la case pour déterminer la correction à effectuer
						const typeCase = caseRuine.type_case > 17 ? caseRuine.type_case - 17 : caseRuine.type_case;
						[affGauche, affDroite, affHaut, affBas] = directionMap[typeCase];
						
						// On corrige l'affichage si on est sur le bord
						if (x === 0) {
							affGauche = "";
						}
						if (x === 14) {
							affDroite = "";
						}
						if (y === 0) {
							affHaut = "";
						}
						if (y === 13) {
							affBas = "";
						}
						let retour;
						// On va faire les 4 directions une à une, et si on a "direction", on effectue la suppression
						if (affGauche === "direction") {
							retour = calculCase("supp", "gauche", planMod, typeCase, caseRuine.x, caseRuine.y, caseRuine.z);
							planMod = retour.updatedPlan;
						}
						if (affDroite === "direction") {
							retour = calculCase("supp", "droite", planMod, typeCase, caseRuine.x, caseRuine.y, caseRuine.z);
							planMod = retour.updatedPlan;
						}
						if (affHaut === "direction") {
							retour = calculCase("supp", "haut", planMod, typeCase, caseRuine.x, caseRuine.y, caseRuine.z);
							planMod = retour.updatedPlan;
						}
						if (affBas === "direction") {
							retour = calculCase("supp", "bas", planMod, typeCase, caseRuine.x, caseRuine.y, caseRuine.z);
							planMod = retour.updatedPlan;
						}
						
						planMod[caseRuine.z][caseRuine.y][caseRuine.x].correction = false;
						planMod[caseRuine.z][caseRuine.y][caseRuine.x].type_case = 0;
						planMod[caseRuine.z][caseRuine.y][caseRuine.x].type_porte = null;
						planMod[caseRuine.z][caseRuine.y][caseRuine.x].nbr_zombie = null;
						// Si on a un escalier, on le supprime, et on supprime l'escalier à l'étage supérieur
						if (planMod[caseRuine.z][caseRuine.y][caseRuine.x].type_escalier !== null) {
							planMod[caseRuine.z][caseRuine.y][caseRuine.x].type_escalier = null;
							planMod[caseRuine.z + 1][caseRuine.y][caseRuine.x].type_escalier = null;
						}
						
					} else if (caseRuine.correction && caseRuine.type_case === 0) {
						planMod[caseRuine.z][caseRuine.y][caseRuine.x].correction = false;
					}
				});
			});
		});
		
		setPlan(planMod);
	};
	
	const reinitCorrectionPlan = () => {
		const planMod = [...plan];
		// On parcourt le plan pour réinitialiser les corrections
		planMod.forEach((etage) => {
			etage.forEach((ligne) => {
				ligne.forEach((caseRuine) => {
					caseRuine.correction = false;
				});
			});
		});
		setPlan(planMod);
	};
	
	useEffect(() => {
		setFilAriane(recalculFilAriane(coord, maxEtage));
	}, [coord]);
	
	return (<>
			<Helmet>
				<style>{generateCSSUser(general.themeUser.user_perso_couleur)}</style>
			</Helmet>
			<RuineListing ruine={{ listRuines: listRuines }} />
			<RuineContext.Provider value={{ plan, setPlan, filAriane, setFilAriane, x, setX, y, setY, z, setZ, selObjet }}>
				<div id="planRuine">
					<div id="zoneRuineCarte">
						<div id="descMappingRuine" className="fondWhite02">
							<p>
								{HTMLParser(t("Vous êtes sur la carte de la ruine <strong>{ruineName}</strong> en <em>{coord}</em> ", { ns: "ville" }).replace("{ruineName}", ruine.bat.nom).replace("{coord}", "(" + (ruine.x - ruine.ville.pos_x) + "/" + (ruine.ville.pos_y - ruine.y) + ")"))}
							</p>
						</div>
						<div id="blocMappingRuine" className="fondWhite02">
							<div id="zoneRuineZoom">
								<div className="caseRuineZoom">
									<RuineZoom />
								</div>
								<div id="bloc_opt_RuineZoom">
									<fieldset className="optionCarteRuine">
										<legend className="optionCarteRuineText">{t("Gestion des portes", { ns: "ville" })}</legend>
										{showGestionPorte ? (
											<div id="groupGestionPorte">
												<button className="addPorteBout" onClick={(event) => {
													event.preventDefault();
													updatePorteRuine("p");
												}}><SvgIcone icone={"small_enter"} /></button>
												<button className="addPorteBout" onClick={(event) => {
													event.preventDefault();
													updatePorteRuine("pC");
												}}><SvgIcone icone={"item_lock"} /></button>
												<button className="addPorteBout" onClick={(event) => {
													event.preventDefault();
													updatePorteRuine("pD");
												}}><SvgIcone icone={"item_classicKey"} /></button>
												<button className="addPorteBout" onClick={(event) => {
													event.preventDefault();
													updatePorteRuine("pP");
												}}><SvgIcone icone={"item_bumpKey"} /></button>
												<button className="addPorteBout" onClick={(event) => {
													event.preventDefault();
													updatePorteRuine("pM");
												}}><SvgIcone icone={"item_magneticKey"} /></button>
												<button className="suppPorteBout" onClick={(event) => {
													event.preventDefault();
													updatePorteRuine(null);
													setShowGestionPorte(!showGestionPorte);
												}}><i className="fas fa-times fa-2x color-red" style={{ width: "16px", height: "16px" }}></i></button>
											</div>
										) : (<div id="addPorteBoutonGeneral">
											<button id="boutAddPorte" disabled={(x === 7 && y === 0 && z === 0) || plan[z][y][x].type_escalier !== null} onClick={(event) => {
												event.preventDefault();
												setShowGestionPorte(!showGestionPorte);
											}}>{t("Ajouter Porte", { ns: "ville" })}</button>
										</div>)}
									</fieldset>
									<fieldset className="optionCarteRuine">
										<legend className="optionCarteRuineText">{t("Nombre de zombie", { ns: "ville" })}</legend>
										<RangeSlider value={zombie} onChange={(e) => setZombie(parseInt(e.target.value, 10))} min={0} max={4} step={1} />
									</fieldset>
									{!modAriane && (
										<fieldset className="optionCarteRuine" id={"btn-gest-etage"}>
											<legend className="optionCarteRuineText">{t("Gestion étage", { ns: "ville" })}</legend>
											{plan[z][y][x].type_escalier === null && (<button className={"btn btn-sm btn-success"} type={"button"} disabled={(x === 7 && y === 0 && z === 0) || plan[z][y][x].type_porte !== null} onClick={() => addUpperFloorWithStaircase("up")}>{t("Ajouter un escalier montant", { ns: "ville" })}</button>)}
											{z > 0 && plan[z][y][x].type_escalier === null && (
												<button className={"btn btn-sm btn-success"} type={"button"} disabled={(x === 7 && y === 0 && z === 0) || plan[z][y][x].type_porte !== null} onClick={() => addUpperFloorWithStaircase("down")}>{t("Ajouter un escalier descendant", { ns: "ville" })}</button>)}
											{z + 1 < maxEtage && plan[z][y][x].type_escalier !== null && (<button className={"btn btn-sm btn-warning"} type={"button"} onClick={removeFloor}>{t("Supprimer un escalier", { ns: "ville" })}</button>)}
											{plan[z][y][x].type_escalier === "up" && (<button className={"btn btn-sm btn-primary"} type={"button"} onClick={() => handleChangeFloor(true)}>{t("Monter l'escalier", { ns: "ville" })}</button>)}
											{plan[z][y][x].type_escalier === "down" && (<button className={"btn btn-sm btn-primary"} type={"button"} onClick={() => handleChangeFloor(false)}>{t("Descendre l'escalier", { ns: "ville" })}</button>)}
										</fieldset>
									)}
									{!modAriane && (
										<fieldset id={"ruine_mode_mapping"}>
											<div>
												<button type={"button"} className={"btn btn-sm btn-secondary"} onClick={() => {
													setModMapping(!modMapping);
													setModAriane(false);
												}}>{modMapping ? t("Desactiver mode mapping", { ns: "ville" }) : t("Activer mode mapping", { ns: "ville" })}</button>
												<TooltipGH>
                                                    <span className="infoBulle">
                                                        <i className="fa-solid fa-circle-info"></i>
                                                    </span>
													<span className="info">{t("Activer le mode mapping vous permettra de n'avoir que l'étage en cours de mapping, il ne vous sera pas possible d'activer le fil d'Ariane dans ce mode.", { ns: "ville" })}</span>
												</TooltipGH>
											</div>
										</fieldset>
									)}
									{!modAriane && (
										<fieldset id={"ruine_mode_item"}>
											<legend>{t("Objets au sol", { ns: "ville" })}</legend>
											<div>
												<div id={"listingObjetsInCaseRuine"}>
													{Object.values(plan[z][y][x].items).length === 0 ? t("Pas d'objet au sol ici.", { ns: "ville" }) : (
														Object.values(plan[z][y][x].items).sort((a: RuinesObjetsDTO, b: RuinesObjetsDTO) => {
															const aName = t(a.item.nom, { ns: "items" });
															const bName = t(b.item.nom, { ns: "items" });
															if (aName === bName) {
																return a.broken ? 1 : -1;
															} else {
																return aName.localeCompare(bName);
															}
														}).map((itemCase) => {
															return (<AffNbrIcon item={itemCase.item} broken={itemCase.broken}
																				nbr={itemCase.nombre}
																				key={"obj_" + itemCase.item.id + "_" + (itemCase.broken ? 1 : 0)}
															/>);
														})
													)}
												</div>
												<div id={"zone_bouton_maj_objetCaseRuine"}>
													{(plan[z][y][x].id !== undefined && plan[z][y][x].id !== null) ? (
														<button type={"button"} className={"btn btn-sm btn-primary"} onClick={() => {
															setModAriane(false);
															setShowPopUpObjet(!showPopUpObjet);
														}}>{t("Modifier les objets", { ns: "ville" })}</button>) : (
														<span>{t("Merci de sauvegarder votre plan avant de rajouter des objets.", { ns: "ville" })}</span>
													)}
													{plan[z][y][x].update_by !== null &&
														<em>{t("Dernière mise à jour par {pseudo} le {date}", { ns: "ville" }).replace("{pseudo}", plan[z][y][x].update_by.pseudo).replace("{date}", formatInTimeZone(new Date(Date.parse(plan[z][y][x].update_at)), general.fuseau, t("dd/MM/yyyy à H:mm", { ns: "app" })))}</em>}
												</div>
											
											</div>
										</fieldset>
									)}
								</div>
							</div>
							<RuineMap modAriane={modAriane} modMapping={modMapping} showAriane={showAriane} trad_porte={trad_porte} onAddCoordFilAriane={handleAddCoordFilAriane} modCorrectif={modCorrectif} />
							<div id="menuRuine">
								{general.myVille && (
									<fieldset>
										<legend>{t("Menu", { ns: "ville" })}</legend>
										<div>
											<button id="saveRuine" className={"btn btn-sm btn-success"} onClick={() => onUpdatePlanRuine(ruine.id, ruine.ville.map_id, plan)}>{t("Sauver", { ns: "ville" })}</button>
											<button id="annulRuine" className={"btn btn-sm btn-danger"} onClick={() => reinitialisationRuine()}>{t("Annuler", { ns: "ville" })}</button>
											{!showAriane && !modMapping && (<button id="affFilAriane" className={"btn btn-sm btn-secondary"} onClick={() => {
												setShowAriane(true);
												setModMapping(false);
											}}>{t("Afficher fil d'Ariane", { ns: "ville" })}</button>)}
											{!showAriane && !modMapping && !modCorrectif && (<button id="affCorrection" className={"btn btn-sm btn-primary"} onClick={() => {
												setShowAriane(false);
												setModMapping(false);
												setModCorrectif(true);
											}}>{t("Activer le mode correction", { ns: "ville" })}</button>)}
											{!showAriane && !modMapping && modCorrectif && (<button id="affCorrection" className={"btn btn-sm btn-warning"} onClick={() => {
												setShowAriane(false);
												setModMapping(false);
												setModCorrectif(false);
												reinitCorrectionPlan();
											}}>{t("Désactiver le mode correction", { ns: "ville" })}</button>)}
											{!showAriane && !modMapping && modCorrectif && (<button id="affCorrection" className={"btn btn-sm btn-danger"} onClick={() => {
												handleValiderCorrection();
											}}>{t("Effacer les cases sélectionnées", { ns: "ville" })}</button>)}
											{showAriane && !modMapping && (<>
												<button id="hiddenFilAriane" className={"btn btn-sm btn-secondary"} onClick={() => {
													setShowAriane(false);
													setModAriane(false);
												}}>{t("Masquer fil d'Ariane", { ns: "ville" })}</button>
												<button id="modFilAriane" className={"btn btn-sm btn-secondary"} onClick={() => {
													setModAriane(true);
												}}>{t("Modifier fil d'Ariane", { ns: "ville" })}</button>
											</>)}
											{modAriane && !modMapping && (<>
												<button className={"btn btn-sm btn-warning"} id="canFilAriane" onClick={() => {
													const coordMod = [...coord];
													if (coordMod.length !== 1) {
														coordMod.splice(-1, 1);
														setCoord(coordMod);
													}
												}}>{t("Annuler dernier point", { ns: "ville" })}</button>
												<button className={"btn btn-sm btn-primary"} id="enrFilAriane" onClick={() => onUpdateFilAriane(ruine.id, ruine.ville.map_id, coord)}>{t("Enregistrer fil d'Ariane", { ns: "ville" })}</button>
												<button className={"btn btn-sm btn-danger"} id="annulFilAriane" onClick={() => {
													setModAriane(false);
													setCoord(props.ruine.coord);
												}}>{t("Annuler fil d'Ariance", { ns: "ville" })}</button>
											</>)}
											<button className={"btn btn-sm btn-warning"} onClick={() => reinitZombieOnRuine()}>{t("Réinitialiser les zombies", { ns: "ville" })}</button>
											<button className={"btn btn-sm btn-danger"} onClick={() => reinitZombieOnlyZeroOnRuine()}>{t("Effacer les zombies à 0", { ns: "ville" })}</button>
										</div>
									</fieldset>
								)}
								<fieldset>
									<legend>{t("Objets au sol dans la ruine", { ns: "ville" })}</legend>
									<div id={"listingObjetsInRuine"}>
										{Object.values(allObjet).sort((a, b) => {
											if (a.count === b.count) {
												const aName = t(a.item.nom, { ns: "items" });
												const bName = t(b.item.nom, { ns: "items" });
												if (aName === bName) {
													return a.broken ? 1 : -1;
												} else {
													return aName.localeCompare(bName);
												}
											} else {
												return b.count - a.count;
											}
										}).map((objet) => {
											const idObjet = objet.item.id * 10 + (objet.broken ? 1 : 0);
											const isSelected = selObjet.includes(idObjet);
											const toggleSelection = () => {
												const selObjetMod = isSelected
													? selObjet.filter((selectedId) => selectedId !== idObjet)
													: [...selObjet, idObjet];
												setSelObjet(selObjetMod);
											};
											
											return (
												<div key={`obj_${objet.item.id}_${objet.broken ? 1 : 0}`} onClick={toggleSelection} className={(selObjet.includes(idObjet)) ? "selCaseRuineObjet" : ""}>
													<AffNbrIcon
														item={objet.item}
														broken={objet.broken}
														nbr={objet.count}
													/>
												</div>
											);
										})}
									</div>
								
								</fieldset>
							</div>
						</div>
					</div>
				</div>
				{showPopUpObjet && (
					<div id="popUpMajCaseRuine">
						<PopUpMajCaseRuine
							caseRuine={plan[z][y][x]}
							onCancel={() => {
								setShowPopUpObjet(false);
								setRetourMajPopUp(null);
								setTypeRetourMajPopup(null);
							}}
							popUpMaj={props.ruine.popUpMaj}
							onSaveCase={(caseRuine) =>
								onUpdateObjetCaseRuine(ruine.id, ruine.ville.map_id, caseRuine, plan)
							}
							retourMajPopUp={retourMajPopUp}
							typeRetourMajPopup={typeRetourMajPopup}
						/>
					</div>
				)}
			</RuineContext.Provider>
		</>
	);
}

export function updatePlan(x: number, y: number, z: number, plan: CaseRuine[][][], updatedProperties: Partial<CaseRuine>): CaseRuine[][][] {
	// On clone le tableau plan
	const updatedPlan = [...plan];
	
	// on récupère la case de coordonnée x, y
	const targetCase = updatedPlan[z][y][x];
	
	// On met à jour les propriétés spécifiées
	// On remplace la case d'origine par la nouvelle version dans le tableau cloné
	updatedPlan[z][y][x] = { ...targetCase, ...updatedProperties };
	
	return updatedPlan;
}

export function recalculFilAriane(coord: number[][][], maxEtage: number): number[][][][] {
	const filAriane: (number | null)[][][][] = [];
	// Initialisation du tableau filAriane
	for (let z = 0; z < maxEtage + 1; z++) {
		filAriane[z] = [];
		for (let y = 0; y < 14; y++) {
			filAriane[z][y] = []; // Créez le sous-tableau pour la ligne y
			for (let x = 0; x < 15; x++) {
				filAriane[z][y][x] = [null]; // Créez le sous-tableau pour la colonne x
			}
		}
	}
	
	if (coord.length === 1) {
		const x = parseInt(coord[0][0].toString(), 10);
		const y = parseInt(coord[0][1].toString(), 10);
		const z = parseInt(coord[0][2].toString(), 10);
		filAriane[z][y][x][0] = 20;
	} else {
		for (let c = 0; c < coord.length - 1; c++) {
			const xd = parseInt(coord[c][0].toString(), 10);
			const yd = parseInt(coord[c][1].toString(), 10);
			const zd = parseInt(coord[c][2].toString(), 10);
			const xa = parseInt(coord[c + 1][0].toString(), 10);
			const ya = parseInt(coord[c + 1][1].toString(), 10);
			const za = parseInt(coord[c + 1][2].toString(), 10);
			
			if (zd === za) {
				if (xd === xa) {
					if (ya < yd) {
						const key = filAriane[za][yd][xd].length - 1;
						filAriane[za][yd][xd][key] = (() => {
							switch (filAriane[za][yd][xd][key]) {
								case 16:
									return 14;
								case 17:
									return 1;
								case 18:
									return 5;
								case 19:
									return 8;
								case null:
									return 22;
								default:
									return filAriane[za][yd][xd][key];
							}
						})();
						
						for (let o = ya + 1; o < yd; o++) {
							filAriane[za][o][xd].push(1);
						}
						
						filAriane[za][ya][xa].push(17);
					}
					
					if (ya > yd) {
						const key = filAriane[za][yd][xd].length - 1;
						filAriane[za][yd][xd][key] = (() => {
							switch (filAriane[za][yd][xd][key]) {
								case 16:
									return 0;
								case 17:
									return 15;
								case 18:
									return 11;
								case 19:
									return 7;
								case 20:
								case null:
									return 20;
								default:
									return filAriane[za][yd][xd][key];
							}
						})();
						
						for (let o = yd + 1; o < ya; o++) {
							filAriane[za][o][xd].push(0);
						}
						
						filAriane[za][ya][xa].push(16);
					}
				}
				
				if (yd === ya) {
					if (xa < xd) {
						const key = filAriane[za][yd][xd].length - 1;
						filAriane[za][yd][xd][key] = (() => {
							switch (filAriane[za][yd][xd][key]) {
								case 16:
									return 4;
								case 17:
									return 10;
								case 18:
									return 2;
								case 19:
									return 12;
								case null:
									return 21;
								default:
									return filAriane[za][yd][xd][key];
							}
						})();
						
						for (let o = xa + 1; o < xd; o++) {
							filAriane[za][yd][o].push(2);
						}
						
						filAriane[za][ya][xa].push(18);
					}
					
					if (xa > xd) {
						const key = filAriane[za][yd][xd].length - 1;
						filAriane[za][yd][xd][key] = (() => {
							switch (filAriane[za][yd][xd][key]) {
								case 16:
									return 9;
								case 17:
									return 6;
								case 18:
									return 13;
								case 19:
									return 3;
								case null:
									return 23;
								default:
									return filAriane[za][yd][xd][key];
							}
						})();
						
						for (let o = xd + 1; o < xa; o++) {
							filAriane[za][yd][o].push(3);
						}
						
						filAriane[za][ya][xa].push(19);
					}
				}
			}
		}
	}
	
	return filAriane;
}

import { ItemProbabilityDTO } from "../../../types/models/itemProbability.dto";
import { ItemPrototypeDTO }   from "../../../types/models/itemPrototype.dto";
import { useTranslation }     from "react-i18next";
import React                  from "react";
import ItemAssemblageList     from "./ItemAssemblageList";

export default function ItemObtains({ listItem, itemPp, listObjet, inEncyclo = true }: { listItem: ItemProbabilityDTO[], listObjet: ItemPrototypeDTO[], itemPp?: ItemPrototypeDTO, inEncyclo?: boolean }) {
	
	const { t } = useTranslation();
	
	if (Object.values(listItem).length === 1) {
		
		const valeurs = Object.values(listItem);
		const item = valeurs[0];
		
		return <ItemAssemblageList id={item.item.id}
								   icon={listObjet[item.item.id].icon}
								   nom={listObjet[item.item.id].nom}
								   classProps={"groupAssemblage"}
								   classAdd={""}
								   classComplement={""}
								   complement={""}
								   inEncyclo={inEncyclo}
		/>;
	} else {
		
		let valTotal = 0;
		Object.values(listItem).map((item) => {
			valTotal += item.taux;
		});
		
		const libItem = Object.values(listItem).map((item) => {
			
			let complement = "";
			let classComplement = "";
			let classGroup = "groupAssemblage";
			let proba = 0;
			
			if (valTotal !== 0) {
				proba = Math.round((item.taux * 100) / (valTotal * 100) * 10000) / 100;
				
				if (proba > 0) {
					complement = `(${proba}%)`;
					classComplement = "item_proba_nombre";
					classGroup = "groupAssemblageProba";
				}
			}
			
			return <ItemAssemblageList id={item.item.id}
									   icon={listObjet[item.item.id].icon}
									   nom={listObjet[item.item.id].nom}
									   classProps={classGroup}
									   complement={complement}
									   classComplement={classComplement}
									   classAdd={""}
									   key={"assemblage_" + item.id}
									   inEncyclo={inEncyclo}
			/>;
		});
		
		if (listObjet[itemPp.id].list_assemblage !== null &&
			(listObjet[itemPp.id].list_assemblage.id === 97 ||
				listObjet[itemPp.id].list_assemblage.id === 98)
		) {
			
			const libUnique = [];
			const libProba = [];
			Object.values(listItem).map((item, index) => {
				if (item.taux === 0) {
					libUnique.push(libItem[index]);
				} else {
					libProba.push(libItem[index]);
				}
			});
			
			return <React.Fragment><span className="item_unique">{libUnique.map((lib, index) => {
				return <React.Fragment key={"assemblage_item_unique_" + index}>{index > 0 && <span className={"ency_space"}>+</span>}{lib}</React.Fragment>;
			})}</span>
				<span className={"ency_space"}>{t("et", { ns: "app" })}</span>
				<span className="result_assemblage">{libProba.map((lib, index) => {
					return <React.Fragment key={"assemblage_item_assemblage_" + index}>{index > 0 && ""}{lib}</React.Fragment>;
				})}</span>
			</React.Fragment>;
			
			
		} else {
			return <span className={"result_assmblage"}>{Object.values(libItem).map(itemHtml => {
				return itemHtml;
			})}</span>;
		}
	}
	
	
}

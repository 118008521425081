import React                                                 from "react";
import { SoifType }                                          from "../../../types/components/Outils/OutilsExpedition.type";
import Select, { components, OptionProps, SingleValueProps } from "react-select";
import SvgIcone                                              from "../../../components/generality/SvgIcone";
import { useGeneralContext }                                 from "../../../types/Context/GeneralContext";
import { ExpeditionApi }                                     from "../../../services/api/ExpeditionApi";
import { Status_error, Status_success, usePopUp }            from "../../../types/Context/PopUpContext";
import { DirectionScrutateur }                               from "../../../types/components/Generality/Game.type";
import HTMLParser                                            from "html-react-parser";
import PopUpMajSacOuvrier                                    from "./PopUpMajSacOuvrier";
import { useCarteContext }                                   from "../../../types/Context/CarteContext";
import { AffNbrIcon }                                        from "../../../components/generality/ComposantGeneral";
import { DirectionScrutateurArrow }                          from "../../../components/generality/ComposantGeneral/DirectionScrutateurArrow";
import PopUpConsigne                                         from "./PopUpConsigne";
import Modal                                                 from "react-bootstrap/Modal";
import { customStyle }                                       from "../../Hotel/Inscription/InscriptionExpedition";
import CustomSwitchToggle                                    from "../../../components/generality/CustomSwitchToggle";
import { useExpeditionContext }                              from "../../../types/Context/Outils/Expedition/ExpeditionContext";
import ConfirmationDialog                                    from "../../../components/generality/ComposantGeneral/ConfirmationDialog";
import { customStyleOutils }                                 from "./ModuleOutilsExpeditions";
import { ExpeditionDTO }                                     from "../../../types/models/expedition.dto";
import { ExpeditionnaireDTO }                                from "../../../types/models/expeditionnaire.dto";
import { OuvriersDTO }                                       from "../../../types/models/ouvriers.dto";
import { CitoyensDTO }                                       from "../../../types/models/citoyens.dto";
import { JobPrototypeDTO }                                   from "../../../types/models/jobPrototype.dto";
import { useTranslation }                                    from "react-i18next";
import Button                                                from "../../../components/utils/Button";

const { SingleValue, Option } = components;

export const styleJob = {
	widthContainer          : 140,
	widthMenu               : 140,
	height                  : 24,
	minHeight               : 24,
	widthClearIndicator     : 20,
	widthControl            : 140,
	widthDropdownIndicator  : 20,
	widthIndicatorsContainer: 40,
	widthInput              : 20,
	widthSingleValue        : 100,
	widthValueContainer     : 100,
};
export const stylePseudo = {
	widthContainer          : 150,
	widthMenu               : 200,
	height                  : 24,
	minHeight               : 24,
	widthClearIndicator     : 20,
	widthControl            : 150,
	widthDropdownIndicator  : 0,
	widthIndicatorsContainer: 20,
	widthInput              : 20,
	widthSingleValue        : 150,
	widthValueContainer     : 150,
};

const JobSingleValue = (props: SingleValueProps<JobPrototypeDTO>) => {
	const { t } = useTranslation();
	return <SingleValue {...props}>
		<SvgIcone icone={"h_" + props.data.icon} />
		<span>{t(props.data.nom, { ns: "game" })}</span>
	</SingleValue>;
};

const JobOption = (props: OptionProps<JobPrototypeDTO>) => {
	const { t } = useTranslation();
	return <Option {...props}>
		<SvgIcone icone={"h_" + props.data.icon} />
		<span>{t(props.data.nom, { ns: "game" })}</span>
	</Option>;
};

const PseudoSingleValue = (props: SingleValueProps<CitoyensDTO>) => {
	return <SingleValue {...props}>
        <span className={" d-flex gap-1 align-items-center justify-content-center"}>
            <SvgIcone icone={"h_" + props.data.job.icon} />
            <span className={props.data.ban ? "color-red" : ""}>{props.data.citoyen.pseudo}</span>
			{props.data.ban && <SvgIcone icone={"h_ban"} />}
        </span>
	</SingleValue>;
};

const PseudoOption = (props: OptionProps<CitoyensDTO>) => {
	return <Option {...props}>
        <span className={"d-flex gap-1 align-items-center justify-content-center"}>
            <SvgIcone icone={"h_" + props.data.job.icon} />
            <span className={props.data.ban ? "color-red" : ""}>{props.data.citoyen.pseudo}</span>
			{props.data.ban && <SvgIcone icone={"h_ban"} />}
        </span>
	</Option>;
};

const DirectionSingleValue = (props: SingleValueProps<DirectionScrutateur>) => {
	const { t } = useTranslation();
	return <SingleValue {...props}>
		<span className={"choix_direction_fao"}>{DirectionScrutateurArrow(props.data.id)}{t(props.data.nom, { ns: "game" })}</span>
	</SingleValue>;
};

const DirectionOption = (props: OptionProps<DirectionScrutateur>) => {
	const { t } = useTranslation();
	return <Option {...props}>
		<span className={"choix_direction_fao"}>{DirectionScrutateurArrow(props.data.id)}{t(props.data.nom, { ns: "game" })}</span>
	</Option>;
};

export const SoifSingleValue = (props: SingleValueProps<{ value: number, label: string, icon: string }>) => {
	return <SingleValue {...props}>
        <span className={" d-flex gap-1 align-items-center justify-content-center"}>
            <SvgIcone icone={props.data.icon} />
            <span>{props.data.label}</span>
        </span>
	</SingleValue>;
};

export const SoifOption = (props: OptionProps<{ value: number, label: string, icon: string }>) => {
	return <Option {...props}>
        <span className={" d-flex gap-1 align-items-center justify-content-center"}>
            <SvgIcone icone={props.data.icon} />
            <span>{props.data.label}</span>
        </span>
	</Option>;
};

export default function ListeOuvriers() {
	const { t } = useTranslation();
	const { general } = useGeneralContext();
	const { carte, jourActuel, setJourActuel } = useCarteContext();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const { ouvriers, setOuvriers, refreshOutils, citoyensUser, expeditions, calculStockRestant, expedition } = useExpeditionContext();
	const [modification, setModification] = React.useState<boolean>(false);
	const [directionChoix, setDirectionChoix] = React.useState<number>(carte.outilsExpe.outilsExpeditions?.fao_direction);
	const [ouvertureOuvrier, setOuvertureOuvrier] = React.useState<boolean>(carte.outilsExpe.outilsExpeditions?.ouvert_ouvrier);
	const [showPopUpObjet, setShowPopUpObjet] = React.useState<boolean>(false);
	const [indexOuvrierConsigne, setIndexOuvrierConsigne] = React.useState<number>(null);
	const [indexSac, setIndexSac] = React.useState<number>(null);
	const [showConfirmation, setShowConfirmation] = React.useState(false);
	const [selectionPseudo, setSelectionPseudo] = React.useState<{ indexOuvrier: number, optionSelected: CitoyensDTO }>(null);
	const [showDayChoice, setShowDayChoice] = React.useState<boolean>(false);
	const [withPreInscrit, setWithPreInscrit] = React.useState<boolean>(false);
	
	
	const expeditionApi = new ExpeditionApi(general.ville.map_id);
	const optionsSoif = [
		{ value: 0, label: t("Non", { ns: "app" }), icon: "status_clean" },
		{ value: 1, label: t("Oui", { ns: "app" }), icon: "r_dwater" },
	];
	
	const directionsFao = carte.outilsExpe.liste.direction;
	const listJob = carte.outilsExpe.liste.job;
	
	// Add ouvrier
	const handleAddOuvrier = () => {
		setModification(true);
		const ouvriersMod = [...ouvriers];
		ouvriersMod.push({
			job     : null,
			citoyen : null,
			soif    : null,
			sac     : [],
			consigne: "",
			position: ouvriers.length + 1,
		});
		setOuvriers(ouvriersMod);
	};
	
	// Cancel ouvrier
	const handleCancelOuvrier = () => {
		setModification(false);
		setOuvriers(carte.outilsExpe.outilsExpeditions.ouvriers);
	};
	
	// Save ouvrier
	const handleSaveOuvrier = () => {
		
		expeditionApi.sauvegardeOuvrier({
			map_id       : general.ville.map_id,
			id_user      : general.user.id,
			jour         : jourActuel,
			ouvriers     : ouvriers,
			direction_fao: directionChoix,
		}).then(() => {
			setMessagePopUp(t("Mise à jour ok !", { ns: "ville" }));
			setShowPop(true);
			setStatus(Status_success);
			setTimeout(() => {
				setMessagePopUp("");
				setShowPop(false);
			}, 2000);
			refreshOutils(jourActuel);
			setModification(false);
		}).catch((error) => {
			console.error(error);
			setMessagePopUp(error?.data?.error ?? error?.message);
			setShowPop(true);
			setStatus(Status_error);
		});
	};
	
	// Change ouvrier
	const handleOuvrierChange = (index: number, updatedProperties: Partial<OuvriersDTO>) => {
		const ouvriersMod = [...ouvriers];
		ouvriersMod[index] = { ...ouvriersMod[index], ...updatedProperties };
		setOuvriers(ouvriersMod);
		setModification(true);
	};
	const handleFaoChange = (optionSelected: DirectionScrutateur) => {
		setDirectionChoix(optionSelected?.id);
		setModification(true);
	};
	
	// Delete ouvrier
	const handleDeleteOuvrier = (index: number) => {
		setModification(true);
		const ouvriersMod = [...ouvriers];
		ouvriersMod.splice(index, 1);
		
		// On réajuste les positions
		ouvriersMod.forEach((ouvrier, indexOuv) => {
			ouvrier.position = indexOuv + 1;
		});
		
		setOuvriers(ouvriersMod);
	};
	
	// Gestion des pseudos avec confirmation
	const handlePseudoClick = (index: number, citoyen: CitoyensDTO) => {
		if (citoyen === null) {
			handleOuvrierChange(index, { citoyen: null, preinscrit: false });
		} else {
			if (isOptionDisabled(citoyen)) {
				// On sauvegarde l'info
				setSelectionPseudo({ indexOuvrier: index, optionSelected: citoyen });
				setShowConfirmation(true);
			} else {
				handleOuvrierChange(index, { citoyen: citoyen, preinscrit: true });
			}
		}
	};
	
	const handleConfirmationExpedition = () => {
		if (selectionPseudo !== null) {
			handleOuvrierChange(selectionPseudo.indexOuvrier, { citoyen: selectionPseudo.optionSelected, preinscrit: true });
			setSelectionPseudo(null);
		}
		setShowConfirmation(false);
	};
	
	const handleAnnulationExpedition = () => {
		// On remet à null la sélection, y compris dans le select
		const ouvrierMod = [...ouvriers];
		ouvrierMod[selectionPseudo.indexOuvrier].citoyen = null;
		setOuvriers(ouvrierMod);
		setSelectionPseudo(null);
		setShowConfirmation(false);
	};
	
	// Mise à jour du statut ouverture des ouvriers
	// Mise à jour du statut de l'expedition
	const handleStatutOuvrier = () => {
		if (jourActuel >= general.ville.jour) {
			expeditionApi.changeStatutOuvrier({
				map_id : carte.ville.map_id,
				id_user: general.user.id,
				jour   : jourActuel,
				statut : !ouvertureOuvrier,
			}).then(() => {
				// on met à jour visuellement le temps de faire le refresh
				setOuvertureOuvrier(!ouvertureOuvrier);
			}).catch((error) => {
				console.error(error);
				setMessagePopUp(error?.data?.error ?? error?.message);
				setShowPop(true);
				setStatus(Status_error);
			});
		}
	};
	
	const handleDuplicateOuvrier = (fromDay: number, toDay: number) => {
		
		// Appel API pour réaliser la duplication
		expeditionApi.duplicateOuvrier({ map_id: general.ville.map_id, id_user: general.user.id, with_preinscrit: withPreInscrit, from_day: fromDay, to_day: toDay }).then(() => {
			setMessagePopUp(t("Duplication ok !", { ns: "ville" }));
			setShowPop(true);
			setStatus(Status_success);
			setTimeout(() => {
				setMessagePopUp("");
				setShowPop(false);
				setJourActuel(toDay);
			}, 2000);
			refreshOutils(toDay);
		}).catch((error) => {
			console.error(error);
			setMessagePopUp(error?.data?.error ?? error?.message);
			setShowPop(true);
			setStatus(Status_error);
		});
		
	};
	
	React.useEffect(() => {
		setDirectionChoix(carte.outilsExpe.outilsExpeditions.fao_direction);
	}, [carte.outilsExpe.outilsExpeditions.fao_direction]);
	React.useEffect(() => {
		setOuvertureOuvrier(carte.outilsExpe.outilsExpeditions.ouvert_ouvrier);
	}, [carte.outilsExpe.outilsExpeditions.ouvert_ouvrier]);
	
	const isOptionDisabled = (option: CitoyensDTO): boolean => {
		// On récupère la liste des Citoyens pré-inscrits en expédition, l'expéditionnaire doit avoir la valeur preinscrit à true pour être dans le
		// tableau (on ne prend que la partie 0 de l'expédition, les expeditionnaires sont identiques d'une partie à une autre).
		const selectedExpeditionnaire: CitoyensDTO[] = [];
		
		expeditions.map((expeditionOutils: ExpeditionDTO) => {
			if (expedition !== undefined && expedition?.id !== null && expedition.id !== expeditionOutils.id || expedition === undefined ||
				expedition?.id === null) {
				expeditionOutils.expedition_parts[0].expeditionnaires.map((expeditionnaire: ExpeditionnaireDTO) => {
					if (expeditionnaire.citoyen !== null) {
						selectedExpeditionnaire.push(expeditionnaire.citoyen);
					}
				});
			}
		});
		// On fait la même chose pour les ouvriers pré-inscrit où on récupère les citoyens
		const selectedOuvriers: CitoyensDTO[] = [];
		
		ouvriers.map((ouvrierOutils: OuvriersDTO) => {
			selectedOuvriers.push(ouvrierOutils.citoyen);
		});
		
		let selectedOptions;
		if (expedition) {
			// On récupère les expeditionnaires de l'expédition en cours de création/modification
			const selectedExpeditionnaireExpe = expedition.expedition_parts[0].expeditionnaires.filter(expeditionnaire => expeditionnaire.citoyen !== null)
				.flatMap(expeditionnaire => expeditionnaire.citoyen);
			// On fusionne les 3 tableaux en un
			selectedOptions = [...selectedExpeditionnaire, ...selectedExpeditionnaireExpe, ...selectedOuvriers];
		} else {
			selectedOptions = [...selectedExpeditionnaire, ...selectedOuvriers];
		}
		
		return selectedOptions.some(selectedOption => selectedOption?.citoyen.id === option.citoyen.id);
	};
	
	return <fieldset>
		<legend>{t("Ouvriers", { ns: "ville" })} - {ouvriers.length}</legend>
		<div id={"bouton_ouvrier_gestion"}>
			{jourActuel <= general.ville.jour && ouvriers.length > 0 && <Button taille={"sm"} couleur={"primary"} onClick={() => {
				setWithPreInscrit(false);
				setShowDayChoice(true);
			}}>{t("Dupliquer les ouvriers", { ns: "outils" })}</Button>}
			{jourActuel <= general.ville.jour && ouvriers.length > 0 && <Button taille={"sm"} couleur={"primary"} onClick={() => {
				setWithPreInscrit(true);
				setShowDayChoice(true);
			}}>{t("Dupliquer les ouvriers (avec les préinscrits)", { ns: "outils" })}</Button>}
			{jourActuel >= general.ville.jour && <button onClick={handleAddOuvrier} className={"btn btn-primary btn-sm"}>{t("Ajouter un ouvrier", { ns: "ville" })}</button>}
			{jourActuel >= general.ville.jour && modification && <>
				<button onClick={handleSaveOuvrier} className={"btn btn-success btn-sm"}>{t("Sauvegarder les ouvriers", { ns: "ville" })}</button>
				<button onClick={handleCancelOuvrier} className={"btn btn-warning btn-sm"}>{t("Annuler modification", { ns: "ville" })}</button>
			</>}
			<div id={"zone_choix_fao_ouvrier"}>
				<label>{t("Direction FAO :", { ns: "ville" })}</label>
				{jourActuel >= general.ville.jour ? (
					<Select value={directionsFao.find(direction => {
						if (directionChoix === undefined || directionChoix === null) {
							return null;
						}
						return direction?.id === directionChoix;
					})}
							id={"direction_fao"}
							name={"direction_fao"}
							options={directionsFao}
							isClearable={true}
							components={{ SingleValue: DirectionSingleValue, Option: DirectionOption }}
							getOptionLabel={(direction: DirectionScrutateur) => direction.nom}
							getOptionValue={(direction: DirectionScrutateur) => direction.id.toString()}
							onChange={(optionSelected: DirectionScrutateur) => handleFaoChange(optionSelected)}
							styles={customStyle(general.themeUser, stylePseudo, false)} />
				) : (directionChoix && <span className={"choix_direction_fao"}>{DirectionScrutateurArrow(directionChoix)}{directionsFao.find(direction => {
					return direction?.id === directionChoix;
				}).nom}</span>)}
			</div>
			{jourActuel >= general.ville.jour && <div id={"statut_ouverture_ouvrier"}>
				<div>{t("Statut des places ouvrières :", { ns: "ville" })}</div>
				<CustomSwitchToggle checked={ouvertureOuvrier} size={"sm"} onstyle="success" offstyle={"danger"}
									onlabel={t("Ouvert", { ns: "ville" })} offlabel={t("Stop", { ns: "ville" })} disabled={jourActuel < general.ville.jour}
									onChange={handleStatutOuvrier} />
			</div>}
		</div>
		{ouvriers.length > 0 && <table id={"ouvriers"}>
			<thead>
			<tr style={{ height: "20px" }}>
				<th className={"small_cols_ouvrier"}>{t("Métier", { ns: "ville" })}</th>
				<th className={"small_cols_ouvrier"}>{t("Pseudo", { ns: "ville" })}</th>
				<th className={"small_cols_ouvrier"}>{t("Soif", { ns: "ville" })}</th>
				<th className={"small_cols_ouvrier"}>{t("Banni ?", { ns: "ville" })}</th>
				<th className={"big_cols"}>{t("Sac", { ns: "ville" })}</th>
				<th className={"big_cols"}>{t("Consigne", { ns: "ville" })}</th>
				{jourActuel >= general.ville.jour && <th className={"sm_cols"}>{t("Action", { ns: "ville" })}</th>}
			</tr>
			</thead>
			<tbody>
			{ouvriers.sort((ouv_a, ouv_b) => {
				return ouv_a.position - ouv_b.position;
			}).map((ouvrier: OuvriersDTO, index) => {
				return <tr key={"ouvriers_" + index} style={{ height: "20px" }}>
					<td className={"small_cols_ouvrier"}>
						<div>
							{jourActuel >= general.ville.jour ? (
								<Select value={ouvrier.job}
										id={"job_expeditionnaire_" + index}
										name={"job_expeditionnaire_" + index}
										options={listJob}
										isClearable={true}
										components={{ SingleValue: JobSingleValue, Option: JobOption }}
										getOptionLabel={(job: JobPrototypeDTO) => job.nom}
										getOptionValue={(job: JobPrototypeDTO) => job?.id.toString()}
										onChange={(optionSelected: JobPrototypeDTO) => {
											if (optionSelected === null) {
												handleOuvrierChange(index, { job_fige: false, job: null });
											} else {
												handleOuvrierChange(index, { job_fige: true, job: optionSelected });
											}
										}}
										styles={customStyle(general.themeUser, styleJob, false)} />
							) : (ouvrier.job && <span className={"consultation_expedition"}><SvgIcone icone={"h_" + ouvrier.job.icon} /><span>{ouvrier.job.nom}</span></span>)}
						</div>
					</td>
					<td className={"small_cols_ouvrier"}>
						<div>
							{jourActuel >= general.ville.jour ? (
								<Select value={ouvrier.citoyen}
										id={"citoyen_expeditionnaire_" + index}
										name={"citoyen_expeditionnaire_" + index}
										options={citoyensUser}
										isClearable={true}
										components={{ SingleValue: PseudoSingleValue, Option: PseudoOption }}
										getOptionLabel={(citoyen: CitoyensDTO) => {
											return citoyen.citoyen.pseudo;
										}}
										getOptionValue={(citoyen: CitoyensDTO) => {
											return citoyen.citoyen.id.toString();
										}}
										onChange={(optionSelected: CitoyensDTO) => {
											handlePseudoClick(index, optionSelected);
										}}
										styles={customStyleOutils(general.themeUser, stylePseudo, (citoyen) => isOptionDisabled(citoyen))} />
							) : (ouvrier.citoyen && <span className={"consultation_expedition"}><SvgIcone icone={"h_" + ouvrier.citoyen.job.icon} /><span>{ouvrier.citoyen.citoyen.pseudo}</span></span>)}
						</div>
					</td>
					<td className={"small_cols_ouvrier"}>
						<div>
							{jourActuel >= general.ville.jour ? (
								<Select value={optionsSoif.find(option => {
									if (ouvrier?.soif === undefined || ouvrier?.soif === null) {
										return option.value === -1;
									} else {
										return option.value === (ouvrier.soif ? 1 : 0);
									}
								})}
										id={"soif_expeditionnaire_" + index}
										name={"soif_expeditionnaire_" + index}
										options={optionsSoif}
										getOptionLabel={(option: SoifType) => option.label}
										getOptionValue={(option: SoifType) => option.value.toString()}
										isClearable={true}
										components={{ SingleValue: SoifSingleValue, Option: SoifOption }}
										onChange={(optionSelected: SoifType) => {
											if (optionSelected === null || optionSelected.value === -1) {
												handleOuvrierChange(index, { soif: null, soif_fige: false });
											} else {
												handleOuvrierChange(index, { soif: optionSelected.value === 1, soif_fige: true });
											}
											
										}}
										styles={customStyle(general.themeUser, styleJob, false)} />
							) : (ouvrier.soif !== null && <span className={"consultation_expedition"}>{ouvrier.soif ? t("Non", { ns: "app" }) : t("Oui", { ns: "app" })}</span>)}
						</div>
					</td>
					<td className={"xsmall_cols_ouvrier"}>
						<div>
							{jourActuel >= general.ville.jour ? (
								<CustomSwitchToggle checked={ouvrier.for_banni} size={"sm"} onstyle="danger" offstyle={"success"}
													onlabel={t("Oui", { ns: "app" })} offlabel={t("Non", { ns: "app" })} disabled={jourActuel < general.ville.jour}
													onChange={(toggle) => handleOuvrierChange(index, { for_banni: toggle })} />
							) : (ouvrier.for_banni !== null && <span className={"consultation_expedition"}>{ouvrier.for_banni ? t("Non", { ns: "app" }) : t("Oui", { ns: "app" })}</span>)}
						</div>
					</td>
					<td className={"big_cols"}>
						<div className={"zone_sac_ouvriers"}>
							<div className={"sac_ouvriers"}>{ouvrier.sac.map((sacItems) => {
								return <div key={`obj_${sacItems.item.id}_${sacItems.broken ? 1 : 0}`}>
									<AffNbrIcon
										item={sacItems.item}
										broken={sacItems.broken}
										nbr={sacItems.nbr}
										classSpecifique={"itemSacOutilsExpedition"}
									/>
								</div>;
							})}</div>
							{jourActuel >= general.ville.jour && <button className={"btn btn-primary btn-xs"} onClick={() => {
								setIndexSac(index);
								setShowPopUpObjet(true);
							}}>{t("Modifier sac", { ns: "ville" })}</button>}
						</div>
					</td>
					<td className={"big_cols"}>
						<div id={"zone_consigne_ouvrier"}>
							<div className={"consultation_expedition"}>{HTMLParser(ouvrier.consigne ?? "")}</div>
							{jourActuel >= general.ville.jour && <button className={"btn btn-primary btn-xs"} onClick={() => setIndexOuvrierConsigne(index)}>{t("Modifier consigne", { ns: "ville" })}</button>}
						</div>
					</td>
					{jourActuel >= general.ville.jour && <td className={"sm_cols"}>
						<button className={"btn btn-danger btn-sm"} onClick={() => handleDeleteOuvrier(index)}><i
							className="fa-solid fa-trash"></i></button>
					</td>}
				</tr>;
			})}
			</tbody>
		</table>}
		
		
		<Modal show={showPopUpObjet} onHide={() => setShowPopUpObjet(false)} centered key={"modal_sac_ouvrier"}>
			{showPopUpObjet && <div id="popUpSacOuvrier">
				<PopUpMajSacOuvrier ouvrier={ouvriers[indexSac]}
									ouvrierInitial={JSON.parse(JSON.stringify(ouvriers[indexSac]))}
									popUpMaj={carte.outilsExpe.popUpMaj}
									onCancel={(ouvrierMod) => {
										setShowPopUpObjet(false);
										setIndexSac(null);
										const ouvriersMod = [...ouvriers];
										ouvriersMod[indexSac] = ouvrierMod;
										setOuvriers(ouvriersMod);
									}}
									onSaveCase={(ouvrierModPopUp) => {
										const ouvriersMod = [...ouvriers];
										ouvriersMod[indexSac] = ouvrierModPopUp;
										setOuvriers(ouvriersMod);
										setModification(true);
										setShowPopUpObjet(false);
									}}
									sacRapide={carte.outilsExpe.sacRapide}
									stock={calculStockRestant(carte.listItemsBank)}
				/>
			</div>}
		</Modal>
		<Modal show={indexOuvrierConsigne !== null} onHide={() => setIndexOuvrierConsigne(null)} centered key={"modal_consigne_ouvrier"}>
			{indexOuvrierConsigne !== null && <div id="popUpConsigne">
				<PopUpConsigne translate={{
					valider: t("Valider", { ns: "ville" }),
					annuler: t("Annuler", { ns: "ville" }),
					phrase : t("Phrase pré-faite :", { ns: "ville" }),
					choix  : t("Choisir une phrase", { ns: "ville" }),
				}}
							   texte={ouvriers[indexOuvrierConsigne].consigne}
							   onChange={(texte) => {
								   handleOuvrierChange(indexOuvrierConsigne, { consigne: texte });
								   setIndexOuvrierConsigne(null);
							   }}
							   onCancel={() => setIndexOuvrierConsigne(null)}
							   lang={general.user.lang}
							   listeConsignes={carte.outilsExpe.liste.phrase}
				/>
			</div>}
		</Modal>
		<Modal show={showDayChoice} onHide={() => {
			setShowDayChoice(false);
		}} centered key={"modal_choice_day"}>{showDayChoice && <div id={"modal_ducplicate_day"}>
			<label htmlFor={"day_choice"}>{t("Dupliquer les ouvriers pour :", { ns: "ville" })}</label>
			<select id={"day_choice"} onChange={(e) => {
				if (parseInt(e.target.value) === 0) {
					return;
				}
				const day = parseInt(e.target.value);
				handleDuplicateOuvrier(jourActuel, day);
				setShowDayChoice(false);
			}}>
				<option value={0}>{t("choix jour", { ns: "ville" })}</option>
				{Array.from({ length: general.ville.jour - jourActuel + 3 }, (_, i) => i + jourActuel + 1).map((jour) => {
					return <option key={"jour_" + jour} value={jour}>{t("jour", { ns: "ville" }) + jour}</option>;
				})}
			</select>
		</div>}</Modal>
		<ConfirmationDialog
			message={t("Êtes-vous sur de pré-inscrire ce citoyen à nouveau ?", { ns: "hotel" })}
			titre={t("Attention !", { ns: "hotel" })}
			btnAnnuler={t("Annuler", { ns: "ville" })}
			btnConfirmation={t("Confirmer", { ns: "ville" })}
			show={showConfirmation}
			onConfirm={handleConfirmationExpedition}
			onCancel={handleAnnulationExpedition}
		/>
	</fieldset>;
	
}


import React, { useEffect, useState } from "react";
import chargement                     from "../../../img/chargement.svg";
import { OutilsChantiersApi }         from "../../services/api/OutilsChantiersApi";
import OutilsChantier                 from "../../containers/Outils/Chantiers/OutilsChantier";
import { useNavigate, useParams }     from "react-router";
import { useGeneralContext }          from "../../types/Context/GeneralContext";
import { useGHContext }               from "../../types/Context/GHContext";
import { Status_error, usePopUp }     from "../../types/Context/PopUpContext";
import { useTranslation }             from "react-i18next";

export function OutilsChantiersGeneral() {
	const { general, setGeneral } = useGeneralContext();
	const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const navigate = useNavigate();
	const params = useParams();
	const { t } = useTranslation();
	const [outilsChantiersData, setOutilsChantiersData] = useState(null);
	
	// Fonction pour recharger les données
	const reloadOutilsChantiersData = async () => {
		const outilsChantiersApi = new OutilsChantiersApi(parseInt(params.id, 10));
		outilsChantiersApi.main().then((response) => {
			setIsOnRefresh(false);
			setIsOnUpdate(false);
			if (response.data.general !== undefined) {
				setGeneral(response.data.general);
				sessionStorage.setItem("mapId", response.data.general.ville ? response.data.general.ville.map_id.toString() : null);
			}
			if (response.data.outilsChantier === undefined) {
				console.error("Erreur de chargement des données outils chantiers", response);
			} else {
				setOutilsChantiersData(response.data.outilsChantier);
			}
		}).catch((error) => {
			setStatus(Status_error);
			setShowPop(true);
			setMessagePopUp(error?.data?.error);
			navigate("/");
		});
	};
	
	useEffect(() => {
		if (checkHabilitation("outils", "chantiers")) {
			reloadOutilsChantiersData().then(r => r);
		} else {
			navigate("/news");
		}
	}, [refreshKey]);
	
	return (
		<>
			{outilsChantiersData === null ? (
				<div className={"chargement_page"}>
					<img src={chargement} alt="Drapeau" />
					<span>{t("Chargement...", { ns: "app" })}</span>
				</div>
			) : (
				<OutilsChantier outilsChantier={outilsChantiersData} />
			)}
		
		</>
	);
}
import { useCallback } from "react";

// Hook personnalisé pour mettre la première lettre en majuscule
export const useCapitalized = () => {
	const capitalizeFirstLetter = useCallback((str: string): string => {
		if (str.length === 0) {
			return str;
		}
		return str.charAt(0).toUpperCase() + str.slice(1);
	}, []);
	
	return { capitalizeFirstLetter };
};
import { useGeneralContext }        from "../../types/Context/GeneralContext";
import { useLocation, useNavigate } from "react-router";
import { useEffect }                from "react";

export function RedirectionGeneral() {
	const { general } = useGeneralContext();
	const navigate = useNavigate();
	const location = useLocation();
	
	useEffect(() => {
		// On va venir rediriger les utilisateurs vers les pages contenant l'id de la ville
		if (general.ville?.map_id === null) {
			navigate("/news");
		} else {
			// On récupère le path sur lequel l'utilisateur se trouve
			const path = location.pathname;
			const userMapId = general.user?.map_id;
			const villeMapId = general.ville?.map_id;
			
			const navigationPaths = [
				{ condition: general.user === null, path: "/news" },
				{ condition: path === "/" && userMapId !== null, path: `/carte/${userMapId}` },
				{ condition: path === "/" && userMapId === null, path: "/news" },
				{ condition: true, path: `${path.endsWith("/") ? path.slice(0, -1) : path}/${villeMapId}` },
			];
			
			for (let nav of navigationPaths) {
				if (nav.condition) {
					navigate(nav.path);
					break;
				}
			}
			
		}
	}, []);
	
	
	return null;
}
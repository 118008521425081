import React, { useCallback, useEffect }                   from "react";
import { GroupedChantierOption, OutilsChantiersTypeProps } from "../../../../types/components/Outils/OutilsChantiers.type";
import SvgIcone                                            from "../../../../components/generality/SvgIcone";
import Select, { components }                              from "react-select";
import { v4 as uuid }                                      from "uuid";
import { useOutilsChantiers }                              from "../TabsOutilsChantiers";
import { ChantierPrototypeDTO }                            from "../../../../types/models/chantierPrototype.dto";
import { ProgrammeChantierDTO }                            from "../../../../types/models/programmeChantier.dto";
import { useTranslation }                                  from "react-i18next";
import TooltipGH                                           from "../../../../components/utils/TooltipGH";

const { SingleValue, Option, GroupHeading } = components;

export default function RecapChantiers(props: { outils: OutilsChantiersTypeProps }) {
	const { t } = useTranslation();
	const { outilsChantiers, setOutilsChantiers } = useOutilsChantiers();
	const outils: OutilsChantiersTypeProps = props.outils;
	
	const aplatirListeChantiers = useCallback((chantiers, result) => {
		for (const chantier of chantiers) {
			result.push(chantier);
			if (chantier.children.length > 0) {
				aplatirListeChantiers(chantier.children, result);
			}
		}
	}, []);
	
	// Définissez les composants personnalisés ici en utilisant `useCallback` si nécessaire.
	const CustomOption = useCallback((props) => (
		<components.Option {...props}>
			<SvgIcone icone={props.data.icon} />
			<span>{t(props.data.nom, { ns: "chantiers" })}</span>
		</components.Option>
	), []);
	
	const CustomSingleValue = useCallback((props) => (
		<components.SingleValue {...props}>
			<SvgIcone icone={props.data.icon} />
			<span>{t(props.data.nom, { ns: "chantiers" })}</span>
		</components.SingleValue>
	), []);
	
	const CustomGroupHeading = useCallback((props) => (
		<components.GroupHeading {...props}>
			<span>{t(props.data.label, { ns: "chantiers" })}</span>
			<span>{props.data.options.length}</span>
		</components.GroupHeading>
	), []);
	
	const customStyles = {
		option           : (provided, state) => ({
			...provided,
			display        : "flex",
			flexDirection  : "row",
			alignItems     : "center",
			backgroundColor: state.isDisabled ? "lightgrey" : "white",
			color          : state.isDisabled ? "grey" : "black",
			":active"      : {
				...provided[":active"],
				backgroundColor: !state.isDisabled && (state.isSelected ? "data.color" : "white"),
			},
		}),
		singleValue      : (provided) => ({
			...provided,
			display        : "flex",
			flexDirection  : "row",
			alignItems     : "center",
			backgroundColor: "white",
			color          : "black",
		}),
		control          : (provided) => ({
			...provided,
			minHeight: "24px",
			height   : "24px",
		}),
		input            : (provided) => ({
			...provided,
			gridTemplateColumns: "none",
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			padding: "0",
		}),
		valueContainer   : (provided) => ({
			...provided,
			padding: "0px 8px",
		}),
		menu             : (provided) => ({
			...provided,
			backgroundColor: "white",
			color          : "black",
		}),
		menuList         : (provided) => ({
			...provided,
			backgroundColor: "white",
			color          : "black",
		}),
		menuPortal       : (provided) => ({
			...provided,
			backgroundColor: "white",
			color          : "black",
		}),
	};
	
	// Applatir les chantiers dans un seul tableau
	const listChantiersAplatit: ChantierPrototypeDTO[] = [];
	aplatirListeChantiers(outils.listChantier, listChantiersAplatit);
	
	// Liste des chantiers non construits
	const listChantiersAConstruire: ChantierPrototypeDTO[] = listChantiersAplatit.filter(
		(chantier) => !Object.keys(outils.listChantiersConstruit).map(Number).includes(chantier.id));
	
	// Recréation du tableau multidimensionnel en regroupant par catégorie "chantier" :
	const tabChantierByCat = listChantiersAConstruire.reduce((acc, element) => {
		const categorie = element.cat_chantier as number;
		if (!acc[categorie]) {
			acc[categorie] = [];
		}
		acc[categorie].push(element);
		return acc;
	}, {});
	
	const groupedChantierOptions: GroupedChantierOption[] = Object.values(outils.listChantier.reduce((acc, element) => {
		const categorie = element.cat_chantier as number;
		if (!acc[categorie]) {
			acc[categorie] = [];
		}
		acc[categorie] = element;
		return acc;
	}, {})).map((value: ChantierPrototypeDTO) => {
		if (tabChantierByCat[value.cat_chantier as number] === undefined) {
			return {
				label  : value.nom,
				options: [],
			};
		} else {
			return {
				label  : value.nom,
				options: tabChantierByCat[value.cat_chantier as number],
			};
		}
	});
	
	// Fonction pour ajouter un nouveau chantier
	const ajouterChantier = () => {
		
		// Récupération de la dernière priorité et incrémentation
		const dernierePriorite = outilsChantiers.chantiers_programmes.length > 0
			? outilsChantiers.chantiers_programmes[outilsChantiers.chantiers_programmes.length - 1].priority + 1
			: 1; // On commence la priorité à 1 si la liste est vide
		
		// Vous devez définir la structure de l'objet chantier par défaut qui sera ajouté
		const nouveauChantier = {
			uuid      : uuid(),
			chantier  : null,
			bde       : false,
			finir     : false,
			pa_laisser: 0,
			priority  : dernierePriorite,
		};
		
		// Mettre à jour l'état avec le nouveau chantier
		setOutilsChantiers({
			...outilsChantiers,
			chantiers_programmes: [...outilsChantiers.chantiers_programmes, nouveauChantier],
		});
	};
	
	// Fonction pour mettre à jour la ligne spécifique dans chantiers_programmes
	const handleChantierChange = (uuid: string, updateProgramme: Partial<ProgrammeChantierDTO>) => {
		
		const chantiersUpdated = outilsChantiers.chantiers_programmes.map(ch => {
			if (ch.uuid === uuid) {
				return {
					...ch,
					...updateProgramme,
				};
			}
			return ch;
		});
		
		// Mettre à jour l'état d'outilsChantiers avec les nouvelles informations
		setOutilsChantiers({ ...outilsChantiers, chantiers_programmes: chantiersUpdated });
	};
	
	// Fonction pour trouver la valeur actuelle du Select basée sur item. Chantier
	const findChantierValue = (chantierId) => {
		for (const group of groupedChantierOptions) {
			const foundOption = group.options.find(option => option.id === chantierId);
			if (foundOption) {
				return foundOption;
			}
		}
		return null;
	};
	
	const isOptionDisabled = (option, selectedOptions) => {
		return selectedOptions.some(selectedOption => selectedOption?.id === option.id);
	};
	
	const deleteChantierProgramme = (uuid: string) => {
		const chantiersProgramme = outilsChantiers.chantiers_programmes.filter(ch => ch.uuid !== uuid);
		setOutilsChantiers({ ...outilsChantiers, chantiers_programmes: chantiersProgramme });
	};
	
	const swapChantiers = (index1: number, index2: number) => {
		const chantiersProgramme = [...outilsChantiers.chantiers_programmes];
		
		// Clone des chantiers à échanger
		const chantier1 = { ...chantiersProgramme[index1] };
		const chantier2 = { ...chantiersProgramme[index2] };
		
		// Echanger les priorités
		const priorityTemp = chantier1.priority;
		chantier1.priority = chantier2.priority;
		chantier2.priority = priorityTemp;
		
		// Echanger les chantiers dans le tableau
		chantiersProgramme[index1] = chantier1;
		chantiersProgramme[index2] = chantier2;
		
		// Mise à jour de l'état avec le nouveau tableau de chantiers
		setOutilsChantiers({ ...outilsChantiers, chantiers_programmes: chantiersProgramme });
	};
	
	useEffect(() => {
		let coutPa = 0;
		let defTot = 0;
		
		outilsChantiers.chantiers_programmes.map((chantier_prog) => {
			if (chantier_prog.chantier !== null) {
				const paRestant = (outils.listAvancement[chantier_prog.chantier.id]?.pa ?? chantier_prog.chantier.pa) -
					Math.round(chantier_prog.chantier.pa * outils.option.reductionPA / 100);
				const paDepense = (paRestant - chantier_prog.pa_a_laisser);
				if (chantier_prog.bde) {
					
					const restePa = paDepense % 3;
					const coutBde = (paDepense - restePa) / 3 * 2;
					coutPa += coutBde;
					coutPa += restePa;
				} else {
					coutPa += paDepense;
				}
				
				if (chantier_prog.pa_a_laisser === 0) {
					defTot += chantier_prog.chantier.def || 0;
				}
			}
		});
		
		setOutilsChantiers({ ...outilsChantiers, pa_chantier: coutPa, def_chantier: defTot });
		
	}, [outilsChantiers.chantiers_programmes]);
	
	
	return <div id={"recapChantier"}>
		<table className={"tab_recapchantier"}>
			<thead>
			<tr className={"fondBlack02"}>
				<th className={"nom"}>{t("Chantier", { ns: "outils" })}</th>
				<th className={"bde"}>
					<TooltipGH>
                        <span className="infoBulle">
                            <SvgIcone icone={"h_ban"} />
                        </span>
						<span className="info">{t("Pour les bannis", { ns: "outils" })}</span>
					</TooltipGH>
				</th>
				<th className={"pa_restant"}>
					<TooltipGH>
                        <span className={"infoBulle"}>
                            <SvgIcone icone={"h_pa"} /><sub>R</sub>
                        </span>
						<span className="info">{t("PA restant au chantier", { ns: "outils" })}</span>
					</TooltipGH>
				</th>
				<th className={"def"}>
					<TooltipGH>
                        <span className={"infoBulle"}>
                            <SvgIcone icone={"r_buildr"} />
                        </span>
						<span className="info">{t("Def chantier", { ns: "outils" })}</span>
					</TooltipGH>
				</th>
				<th className={"pa_laisser"}>
					<TooltipGH>
                        <span className="infoBulle">
                            <SvgIcone icone={"h_pa"} /><sub>L</sub>
                        </span>
						<span className="info">{t("PA à laisser au chantier", { ns: "outils" })}</span>
					</TooltipGH>
				</th>
				<th className={"priority"}>{t("Priorité", { ns: "outils" })}</th>
			</tr>
			</thead>
			<tbody>
			{outilsChantiers.chantiers_programmes.sort((ch1: ProgrammeChantierDTO, ch2: ProgrammeChantierDTO) => ch1.priority > ch2.priority ? 1 : -1)
				.map((item, index) => {
					return <tr key={item.uuid}>
						<td className={"nom"}>
							<Select
								onChange={(selectedOption: ChantierPrototypeDTO) => handleChantierChange(item.uuid, { chantier: selectedOption })}
								options={groupedChantierOptions}
								components={{
									Option      : CustomOption,
									SingleValue : CustomSingleValue,
									GroupHeading: CustomGroupHeading,
								}}
								isOptionDisabled={(option) => isOptionDisabled(option, outilsChantiers.chantiers_programmes.map(ch => ch.chantier))}
								styles={customStyles}
								value={findChantierValue(item.chantier?.id)}
							/>
						</td>
						<td className={"bde"}>
							<div className={"centrage_info"}>
								<input type={"checkbox"} checked={item.bde}
									   onChange={(event) => handleChantierChange(item.uuid, { bde: event.target.checked })} />
							</div>
						</td>
						<td className={"pa_restant"}>
							<div className="centrage_info">
								{item.chantier !== null && ((outils.listAvancement[item.chantier.id]?.pa ?? item.chantier.pa) -
									Math.round(item.chantier.pa * outils.option.reductionPA / 100))}
							</div>
						</td>
						<td className={"def"}>
							<div className="centrage_info">
								{item.chantier !== null && item.chantier.def}
							</div>
						</td>
						<td className={"pa_laisser"}>
							<div className="centrage_info">
								<input type={"number"} value={item.pa_a_laisser}
									   onChange={(event) => handleChantierChange(item.uuid, { pa_a_laisser: parseInt(event.target.value, 10) })} />
							</div>
						</td>
						<td className={"priority"}>
							<div className="centrage_info">
								{outilsChantiers.chantiers_programmes.length > 0 && (
									<button onClick={() => deleteChantierProgramme(item.uuid)}><i className="fa-solid fa-trash color-red"></i></button>
								)}
								{outilsChantiers.chantiers_programmes.length > 1 && (
									<button onClick={() => swapChantiers(index, index - 1)} disabled={index === 0}>
										<TooltipGH>
                                            <span className="infoBulle">
                                                <i className="fa-solid fa-circle-chevron-up"></i>
                                            </span>
											<span className="info">{t("Monter le chantier", { ns: "outils" })}</span>
										</TooltipGH>
									</button>
								)}
								{outilsChantiers.chantiers_programmes.length > 1 && (
									<button onClick={() => swapChantiers(index, index + 1)}
											disabled={index === outilsChantiers.chantiers_programmes.length - 1}>
										<TooltipGH>
                                            <span className="infoBulle">
                                                <i className="fa-solid fa-circle-chevron-down"></i>
                                            </span>
											<span className="info">{t("Descendre le chantier", { ns: "outils" })}</span>
										</TooltipGH>
									</button>
								)}
							</div>
						</td>
					</tr>;
				})}
			
			</tbody>
		</table>
		<div id={"recap_general"}>
			<div>
				<div className="centrage_info">
					<button onClick={ajouterChantier} className={"btn btn-primary btn-sm"}>{t("Ajouter un chantier", { ns: "outils" })}</button>
				</div>
			</div>
			<div id={"group_recap_pa_def"}>
				<div>
					<div className="centrage_info">
						<TooltipGH>
                            <span className="infoBulle">
                                <SvgIcone icone={"h_pa"} /><sub>{t("total", { ns: "outils" })}</sub>:
                            </span>
							<span className="info">{t("Cout PA total chantier", { ns: "outils" })}</span>
						</TooltipGH>
					</div>
					
					<div className="centrage_info">
						{outilsChantiers.pa_chantier}
					</div>
				</div>
				<div>
					<div className="centrage_info">
						<TooltipGH>
                            <span className="infoBulle">
                                <SvgIcone icone={"r_buildr"} /><sub>{t("total", { ns: "outils" })}</sub>:
                            </span>
							<span className="info">{t("Cout PA total chantier", { ns: "outils" })}</span>
						</TooltipGH>
					</div>
					<div className="centrage_info">
						{outilsChantiers.def_chantier}
					</div>
				</div>
			</div>
		</div>
	</div>;
}


import React, { useContext, useEffect, useState }       from "react";
import { Nav, Navbar, NavDropdown, useAccordionButton } from "react-bootstrap";
import { Avatar }                                       from "../../components/generality/ComposantGeneral";
import HTMLParser                                       from "html-react-parser";
import Button                                           from "react-bootstrap/Button";
import Accordion                                        from "react-bootstrap/Accordion";
import ListGroup                                        from "react-bootstrap/ListGroup";
import Card                                             from "react-bootstrap/Card";
import AccordionContext                                 from "react-bootstrap/AccordionContext";
import { Link }                                         from "react-router";
import { GeneralType }                                  from "../../types/components/Generality/General.type";
import { LanguageSelector }                             from "./LangueSelector";
import { BarLoader, ClipLoader }                        from "react-spinners";
import { formatInTimeZone }                             from "date-fns-tz";
import Cookies                                          from "js-cookie";
import { useGHContext }                                 from "../../types/Context/GHContext";
import { useGeneralContext }                            from "../../types/Context/GeneralContext";
import { useTranslation }                               from "react-i18next";
import SvgIcone                                         from "../../components/generality/SvgIcone";

export interface HeaderProps {
	onActualisation: () => void;
	onUpdate: () => void;
	onReset: () => void;
	isOnUpdate: boolean;
	isOnRefresh: boolean;
}

export interface NavigateurPropsProps {
	general: GeneralType;
	toggleMenu?: () => void;
}

function ContextAwareToggle({ children, eventKey, callback }: { children: any, eventKey: string, callback: () => void }) {
	const { activeEventKey } = useContext(AccordionContext);
	
	const decoratedOnClick = useAccordionButton(
		eventKey,
		() => callback && callback(),
	);
	
	const isCurrentEventKey = activeEventKey === eventKey;
	
	return (
		<div
			style={{
				cursor: "pointer", // Add this to show the element is clickable
			}}
			onClick={decoratedOnClick}
		>
			<ListGroup>
				<ListGroup.Item className="custom-menu-button">
					<div className={"groupNameChevron"}>
						<div className={"card-name-header-menu"}>{children}</div>
						<i className={(isCurrentEventKey) ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></i>
					</div>
				</ListGroup.Item>
			</ListGroup>
		</div>
	);
}

function Navigation({ general, toggleMenu }: NavigateurPropsProps) {
	const mapIdVille = general.ville?.map_id ?? null;
	const isCo = general.isCo;
	const { t } = useTranslation();
	
	const keepMenuOpen = general.user?.user_personnalisation.keep_menu_open ?? false;
	
	const closeMenuOnClick = () => {
		// Vérifiez si l'option keepMenuOpen est à false pour fermer le menu
		if (!keepMenuOpen) {
			toggleMenu();
		}
		// Si keepMenuOpen est true, ne faites rien (le menu restera ouvert)
	};
	
	return (
		<Accordion>
			{general.menuUser.items.sort((item_a, item_b) => item_a.ordre < item_b.ordre ? -1 : 1).map((item, index) => {
				if (item.type_menu === "group") {
					return <Card key={"menu_" + index}>
						<Card.Header>
							<ContextAwareToggle eventKey={"subMenu" + index} callback={() => {
							}}>{t(item.name, { ns: "app" })}</ContextAwareToggle>
						</Card.Header>
						<Accordion.Collapse eventKey={"subMenu" + index}>
							<ListGroup>
								{item.items.map((subItem, subIndex) => {
									return <Link to={`${subItem.menu.route.replaceAll("-", "/")}${subItem.menu.my_ville ? mapIdVille : (subItem.menu.for_user ? general.user.id_my_hordes : "")}`} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick} key={"subMenu_" + subIndex}>
										<ListGroup.Item className="custom-menu-button">{t(subItem.menu.label, { ns: "app" })}</ListGroup.Item>
									</Link>;
								})}
							</ListGroup>
						</Accordion.Collapse>
					</Card>;
				} else {
					return <Card key={"menu_" + index}>
						<ListGroup>
							<Link to={`${item.menu.route.replaceAll("-", "/")}${item.menu.my_ville ? mapIdVille : (item.menu.for_user ? general.user.id_my_hordes : "")}`} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}>
								<ListGroup.Item className="custom-menu-button">{t(item.menu.label, { ns: "app" })}</ListGroup.Item>
							</Link>
						</ListGroup>
					</Card>;
				}
			})}
			{isCo && general.miseEnAvant.map((eventEnAvant, index) => {
				return <Card key={"menu_" + index}>
					<ListGroup>
						<Link to={"event/" + eventEnAvant.id} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
							className="custom-menu-button-event"><span className={"d-flex gap-1 justify-content-center align-items-center"}><SvgIcone icone={eventEnAvant?.icone ?? "r_eventpart"} />{eventEnAvant.nom}</span></ListGroup.Item></Link>
					</ListGroup>
				</Card>;
			})}
		</Accordion>
	);
}

function NavigationBandeau({ general }: NavigateurPropsProps) {
	const { checkHabilitation } = useGHContext();
	const mapIdVille = general.ville?.map_id ?? null;
	const myVille = general.myVille;
	const isCo = general.isCo;
	const visitVille = mapIdVille !== null;
	const { t } = useTranslation();
	
	
	return (
		<Navbar expand={true} id={"menuSite-hor"}>
			<Nav className="me-auto d-flex w-100">
				{general.menuUser.items.sort((item_a, item_b) => item_a.ordre < item_b.ordre ? -1 : 1).map((item, index) => {
					if (item.type_menu === "group") {
						return <NavDropdown title={<span className={"m-2"}>{t(item.name, { ns: "app" })}</span>} className={"custom-dropdown-item"} key={"subMenu" + index}>
							{item.items.map((subItem, subIndex) => {
								return <NavDropdown.Item to={`${subItem.menu.route.replaceAll("-", "/")}${subItem.menu.my_ville ? mapIdVille : (subItem.menu.for_user ? general.user.id_my_hordes : "")}`} as={Link} style={{ textDecoration: "none", color: "inherit" }} key={"subMenu" + index + "_" + subIndex}>
									<ListGroup.Item className="custom-menu-button">{t(subItem.menu.label, { ns: "app" })}</ListGroup.Item>
								</NavDropdown.Item>;
							})}
						</NavDropdown>;
					} else {
						return <Nav.Link to={`${item.menu.route.replaceAll("-", "/")}${item.menu.my_ville ? mapIdVille : (item.menu.for_user ? general.user.id_my_hordes : "")}`} as={Link} style={{ textDecoration: "none", color: "inherit" }} key={"subMenu" + index}>
							<ListGroup.Item className="custom-menu-button">{<span className={"m-2"}>{t(item.menu.label, { ns: "app" })}</span>}</ListGroup.Item>
						</Nav.Link>;
					}
				})}
				{isCo && general.miseEnAvant.map((eventEnAvant, index) => {
					return <Nav.Link to={"event/" + eventEnAvant.id} as={Link} style={{ textDecoration: "none", color: "inherit" }} key={"menu_" + index}>
						<ListGroup.Item className="custom-menu-button-event"><span className={"d-flex gap-1 justify-content-center align-items-center m-2"}><SvgIcone icone={"r_eventpart"} />{eventEnAvant.nom}</span></ListGroup.Item>
					</Nav.Link>;
				})}
			</Nav>
		</Navbar>
	);
}

export function Header({ onActualisation, onUpdate, onReset, isOnUpdate, isOnRefresh }: HeaderProps) {
	const { checkHabilitation } = useGHContext();
	const { general, setGeneral } = useGeneralContext();
	const menuCookie = Cookies.get("menu");
	const [menuOpen, setMenuOpen] = useState(menuCookie ? JSON.parse(menuCookie) : false);
	const [localGeneral, setLocalGeneral] = useState(general);
	const { t } = useTranslation();
	
	useEffect(() => {
		setLocalGeneral(general);
	}, [general]);
	
	const toggleMenu = () => {
		if (general.user !== null && general.user.user_personnalisation.keep_menu_open) {
			const expirationDate = new Date();
			expirationDate.setDate(expirationDate.getDate() + 365);
			Cookies.set("menu", JSON.stringify(!menuOpen), { expires: expirationDate, path: "/", secure: true });
		}
		setMenuOpen(!menuOpen);
	};
	
	const ville = localGeneral.ville;
	const user = localGeneral.user;
	const isCo = localGeneral.isCo;
	const ptsSaison = localGeneral.ptsSaison.toString();
	const jourVille = ville ? ville.jour.toString() : "";
	
	let couleurChargement = "#ffffff";
	
	if (user?.theme === "light" || user?.theme === "vintage") {
		couleurChargement = "#000000";
	}
	
	const mapIdVille = ville?.map_id ?? null;
	const mapIdJoueur = user?.map_id ?? null;
	
	const buttonConnexion = () => {
		const isLoggedIn = !isCo;
		const isUserMapIdMatchesCity = user?.map_id === ville?.map_id || mapIdJoueur === mapIdVille || (mapIdJoueur !== null && ville === null);
		const isUserMapIdNotNull = mapIdJoueur !== null;
		
		if (isLoggedIn) {
			return <Button href={"/loginMH"} variant={"primary"} size={"sm"}>{t("Connexion", { ns: "app" })}</Button>;
		} else {
			const buttonOptions = [
				{ check: isUserMapIdMatchesCity, variant: "success", onClick: onUpdate, text: isOnUpdate ? <BarLoader color={couleurChargement} height={12} width={80} /> : t("Mise à jour", { ns: "app" }) },
				{ check: isUserMapIdNotNull, variant: "primary", onClick: onReset, text: t("Ma Ville", { ns: "app" }) },
				{ check: true, variant: "primary", onClick: onReset, text: t("Quitter la ville", { ns: "app" }) },
			];
			
			for (const option of buttonOptions) {
				if (option.check) {
					return (
						<Button variant={option.variant} size={"sm"} onClick={option.onClick} className={"bouton_chargement_maj"}>
							{option.text}
						</Button>
					);
				}
			}
		}
	};
	
	return (
		<header id={"general"}>
			<div id={"entete"}>
				<div id="titre_principale">
					<div id="groupImage">
						<div id={"groupMenuMaj"} className={"gap-4"}>
							{!(user?.user_personnalisation?.menu_bandeau ?? false) && <div className="header-content">
								<div className="burger-icon" onClick={toggleMenu}>
									<span></span>
									<span></span>
									<span></span>
								</div>
								{!(user?.user_personnalisation?.menu_bandeau ?? false) && menuOpen && <nav id="menuSite" className={(menuOpen ? "menu-open " : "") + "flex-column"}><Navigation general={general} toggleMenu={toggleMenu} /></nav>}
							</div>}
							<div className={((user?.user_personnalisation?.menu_bandeau ?? false)) ? "ms-4" : null}>
								{buttonConnexion()}
							</div>
							<div>
								<Button variant={"warning"} size={"sm"} onClick={onActualisation} id={"#zoneRefresh"}
										className={"bouton_chargement_maj"}>{isOnRefresh ? (<ClipLoader color={couleurChargement} size={10} />) :
									(<i className="fa-solid fa-rotate-right"></i>)}</Button>
							</div>
						</div>
						<div id={"entete_link"}>
							<div id={"groupImgText"}>
								<Link to={mapIdVille === null ? "/news" : ("/carte/" + mapIdVille)} style={{ textDecoration: "none", color: "inherit" }}>
									<div id="imageBanniere"></div>
								</Link>
								<Link to={mapIdVille === null ? "/news" : ("/carte/" + mapIdVille)} style={{ textDecoration: "none", color: "inherit" }}>
									<div id="textCentrale">
										<p id="entente_devise"> {t("Le site pour gérer ta ville MyHordes !", { ns: "app" })} </p>
										{ville !== null && (<>{mapIdVille === mapIdJoueur ? (
												<p id="entete_nomV">{HTMLParser(t("Vous êtes actuellement dans la ville {ville} jour <span id=\"jourVille\">{jour}</span>", { ns: "app" }).replace("{ville}", ville.nom).replace("{jour}", jourVille))} - {t("Points de saison : {point}", { ns: "app" }).replace("{point}", ptsSaison)}</p>) :
											(<p id="entete_nomV">{HTMLParser(t("Vous visitez actuellement la ville {ville} jour <span id=\"jourVille\">{jour}</span>", { ns: "app" }).replace("{ville}", ville.nom).replace("{jour}", jourVille))} - {t("Points de saison : {point}", { ns: "app" }).replace("{point}", ptsSaison)}</p>)}</>)}
									</div>
								</Link>
							</div>
						</div>
					</div>
					<div id="textDerMaj">
						{ville !== null && <p id="entete_majV">{t("Dernière mise à jour le {date} par {user}", { ns: "app" }).replace("{date}",
							(formatInTimeZone(ville.date_time, general.fuseau, t("dd/MM/yyyy à H:mm", { ns: "app" })))).replace("{user}",
							((ville.update_by === null) ? t("inconnu", { ns: "app" }) : ville.update_by.pseudo))}</p>}
					</div>
				</div>
				<div id="Espace_perso">
					<div id="avatar_pseudo">
						<div id="pseuCo">
							{user !== null && <p>{user?.pseudo}<br />
								{user?.map_id !== null && <span>{t("Ville n°", { ns: "app" })}<span id="idMapPerso">{user.map_id}</span></span>}
								{user?.map_id === null && <span>{t("Pas de ville", { ns: "app" })}</span>}
							</p>}
						</div>
						<div id="avatar"><Avatar url={user?.avatar ?? null} classAvatar={"avatar_banniere"} /></div>
					</div>
					<div id="perso_admin">
						<LanguageSelector />
						{isCo && <div><Link to={"espace_perso"} style={{ textDecoration: "none", color: "inherit" }}>
							<div id="menuEspacePerso" className="imgPanel"><i className="fa fa-2x fa-cogs"></i></div>
						</Link></div>}
						{checkHabilitation("admin") && <div><Link to={"admin"} style={{ textDecoration: "none", color: "inherit" }}>
							<div id="admin" className="imgPanel"><i className="fa-solid fa-2x fa-screwdriver-wrench"></i></div>
						</Link></div>}
						{isCo && <div className={"deconnexion"}><Button href={"/logout"} variant={"danger"} size={"sm"} onClick={() => {
							setGeneral(null);
							sessionStorage.setItem("mapId", null);
						}}>{t("Déconnexion", { ns: "app" })}</Button></div>}
					</div>
				</div>
			</div>
			{(user?.user_personnalisation?.menu_bandeau ?? false) && <div className="header-content-horizontal"><NavigationBandeau general={general} /></div>}
		</header>
	);
	
}

import { ItemPrototypeDTO }        from "../../../types/models/itemPrototype.dto";
import { ListAssemblageDTO }       from "../../../types/models/listAssemblage.dto";
import { TypeActionAssemblageDTO } from "../../../types/models/typeActionAssemblage.dto";
import { useTranslation }          from "react-i18next";
import React                       from "react";
import ItemNeeds                   from "./ItemNeeds";
import ItemObtains                 from "./ItemObtains";

export default function ListeAssemblage({ item, listNeed, listItems, listObtains, listAction, inEncyclo = true }: { item: ItemPrototypeDTO, listItems: ItemPrototypeDTO[], listNeed: ListAssemblageDTO[], listObtains: ListAssemblageDTO[], listAction: TypeActionAssemblageDTO[], inEncyclo?: boolean }) {
	const { t } = useTranslation();
	
	
	return <React.Fragment>
		{listNeed[item.id ?? 0] !== undefined &&
			<React.Fragment>{Object.entries(listNeed[item.id ?? 0]).map(([index, assemblage]: [string, ListAssemblageDTO[]]) => {
				
				return <React.Fragment key={"assemblage_need_" + item.id + "_" + index}>
					<p>{listAction[parseInt(index, 10) - 1].nom_item_need}</p>
					{Object.values(assemblage).map((itemAssemblage) => {
						return <div className="ligneAssemblage" key={"assemblage_" + itemAssemblage.id}>
							<ItemNeeds listItem={itemAssemblage.item_needs} listObjet={listItems} itemPp={itemAssemblage.item_principal} inEncyclo={inEncyclo} />
							<span className={"ency_space"}><i className="fa-solid fa-arrow-right"></i></span>
							<ItemObtains listItem={itemAssemblage.item_obtains} listObjet={listItems}
										 itemPp={itemAssemblage.item_principal} inEncyclo={inEncyclo} />
						</div>;
					})}
				</React.Fragment>;
			})}</React.Fragment>}
		{listObtains[item.id] !== undefined &&
			<React.Fragment>{Object.entries(listObtains[item.id]).map(([index, assemblage]: [string, ListAssemblageDTO[]]) => {
				return <React.Fragment key={"assemblage_obtain_" + item.id + "_" + index}>
					<p>{t(listAction[parseInt(index, 10) - 1].nom_item_obtain, { ns: "items" })}</p>
					{Object.values(assemblage).map((itemAssemblage) => {
						return <div className="ligneAssemblage" key={"assemblage_" + itemAssemblage.id}>
							<ItemNeeds listItem={itemAssemblage.item_needs} listObjet={listItems} itemPp={itemAssemblage.item_principal} inEncyclo={inEncyclo} />
							<span className={"ency_space"}><i className="fa-solid fa-arrow-right"></i></span>
							<ItemObtains listItem={itemAssemblage.item_obtains} listObjet={listItems}
										 itemPp={itemAssemblage.item_principal} inEncyclo={inEncyclo} />
						</div>;
					})}
				</React.Fragment>;
			})}</React.Fragment>}
	</React.Fragment>;
}
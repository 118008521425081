import React                       from "react";
import { ItemPrototypeDTO }        from "../../../types/models/itemPrototype.dto";
import { useTranslation }          from "react-i18next";
import SvgIcone                    from "../../../components/generality/SvgIcone";
import BadgeCaracteristiqueItem    from "../../../components/encyclopedie/BadgeCaracteristiqueItem";
import { CaracteristiquesItemDTO } from "../../../types/models/caracteristiquesItem.dto";
import { TypeActionAssemblageDTO } from "../../../types/models/typeActionAssemblage.dto";
import { ListAssemblageDTO }       from "../../../types/models/listAssemblage.dto";
import ListeAssemblage             from "../../Encyclopedie/Objets/ListeAssemblage";

export default function DetailItem({ item, listNeeds, listObtains, listObjets, listAction }: { item: ItemPrototypeDTO, listObjets: ItemPrototypeDTO[], listNeeds: ListAssemblageDTO[], listObtains: ListAssemblageDTO[], listAction: TypeActionAssemblageDTO[] }) {
	const { t } = useTranslation();
	
	return (
		<div id={"popUDetailItem"} className={"d-flex flex-column justify-content-center align-content-center gap-2 p-3"}>
			<div>
				<h3 className={"d-flex justify-content-center align-content-center gap-2 mt-0 mb-0"}><span>{t("Détail de l'objet :", { ns: "ville" })}</span><span className={"d-flex gap-2"}><SvgIcone icone={item.icon} /><span>{t(item.nom, { ns: "items" })}</span></span></h3>
				<fieldset>
					<legend>{t("Informations de l'objet", { ns: "ville" })}</legend>
					<div className={"d-flex flex-column justify-content-center align-content-center gap-2"}>
						<div className={"d-flex justify-content-start align-content-center gap-2"}>
							<span style={{ textDecoration: "underline" }}>{t("Categorie :", { ns: "ville" })}</span>
							<span>{t(item.category_objet.nom, { ns: "items" })}</span>
						</div>
						<div className={"d-flex flex-column justify-content-start align-content-center gap-1"}>
							<span style={{ textDecoration: "underline" }}>{t("Description :", { ns: "ville" })}</span>
							<span>{t(item.description, { ns: "items" })}</span>
						</div>
						{(item?.caracteristiques ?? []).length !== 0 && <div className={"d-flex flex-column justify-content-start align-content-center gap-1"}>
							<span style={{ textDecoration: "underline" }}>{t("Caractéristiques :", { ns: "ville" })}</span>
							<span className={"d-flex flex-wrap justify-content-start align-content-center gap-1"}>{item.caracteristiques.sort((carac_a: CaracteristiquesItemDTO, carac_b: CaracteristiquesItemDTO) => {
								return t(carac_a.type_carac.nom, { ns: "items" }).localeCompare(t(carac_b.type_carac.nom, { ns: "items" }));
							}).map((carac: CaracteristiquesItemDTO) => {
								return <BadgeCaracteristiqueItem caracteristique={carac} key={carac.id} />;
							})}</span>
						</div>}
						{item.deco > 0 && <div className={"d-flex justify-content-start align-content-center gap-2"}>
							<span style={{ textDecoration: "underline" }}>{t("Points de déco :", { ns: "ville" })}</span>
							<span>{item.deco}</span>
						</div>}
						{item.def_base > 0 && <div className={"d-flex justify-content-start align-content-center gap-2"}>
							<span style={{ textDecoration: "underline" }}>{t("Points de défense :", { ns: "ville" })}</span>
							<span>{item.def_base}</span>
						</div>}
					</div>
				</fieldset>
				{(listNeeds[item.id ?? 0] !== undefined || listObtains[item.id] !== undefined) && <fieldset>
					<legend>{t("Actions sur l'objet", { ns: "ville" })}</legend>
					<div className={"d-flex flex-column justify-content-center align-content-center gap-2"}>
						<ListeAssemblage item={item} listItems={listObjets} listNeed={listNeeds} listObtains={listObtains} listAction={listAction} />
					</div>
				</fieldset>}
				{(item?.item_batiments ?? []).length !== 0 && <fieldset>
					<legend>{t("Bâtiments où l'objet peut être obtenu (probabilité)", { ns: "ville" })}</legend>
					<div className={"d-flex flex-column justify-content-center align-content-center gap-2"}>
						{item.item_batiments.sort((a, b) => t(a.bat_prototype.nom, { ns: "bats" }).localeCompare(t(b.bat_prototype.nom, { ns: "bats" }))).map(itemBatiment => (
							<span className={"itemBat"} key={"item_bat_" + itemBatiment.bat_prototype.id}>
                            {t(itemBatiment.bat_prototype.nom, { ns: "bats" })} : {Math.round(itemBatiment.probabily / itemBatiment.bat_prototype.proba_item_globale * 10000) / 100} %
                        </span>
						))}
					</div>
				</fieldset>
				}
			</div>
		</div>);
}
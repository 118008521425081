import React, { useEffect, useState } from "react";
import SvgIcone                       from "../generality/SvgIcone";
import { ItemPrototypeDTO }           from "../../types/models/itemPrototype.dto";
import { CategoryObjetDTO }           from "../../types/models/categoryObjet.dto";
import { useTranslation }             from "react-i18next";
import { useNormalizedSearch }        from "../../services/hook/useNormalizedSearch";
import TooltipGH                      from "../utils/TooltipGH";

interface ListingStockItemsProps {
	listCategory: CategoryObjetDTO[];
	listItems: ItemPrototypeDTO[];
	itemSelected: (item: ItemPrototypeDTO, broken: boolean) => void;
	stock: number[];
}

export const ListingStockItems = (props: ListingStockItemsProps) => {
	const { t } = useTranslation();
	const [listItems, setListItems] = useState<ItemPrototypeDTO[]>(props.listItems);
	const [itemSearch, setItemSearch] = useState<string>("");
	const { normalizeSearch } = useNormalizedSearch();
	
	const stock = props.stock;
	
	useEffect(() => {
		setListItems(props.listItems);
	}, [props.listItems]);
	
	const affIcon = (item: ItemPrototypeDTO, nbr, nbrCasse) => {
		const listIcon = [];
		
		if (item.expedition) {
			listIcon.push({
				classImg: "videImg5",
				typeName: t("(expédition)", { ns: "items" }),
				type    : 5,
			});
		} else {
			if (item.type_objet?.id === 3) {
				listIcon.push({
					classImg: "videImg4",
					typeName: t("(Marqueur)", { ns: "items" }),
					type    : 4,
				});
			} else {
				if (item.id >= 2000 && item.id < 3000) {
					listIcon.push({
						classImg: "videImg3",
						typeName: t("empoisonné(e)", { ns: "items" }),
						type    : 3,
					});
				} else {
					listIcon.push({ classImg: "videImg", typeName: "", type: 1 });
					if (item.type_objet?.id === 1) {
						listIcon.push({
							classImg: "videImg2",
							typeName: t("cassé(e)", { ns: "items" }),
							type    : 2,
						});
					}
				}
			}
		}
		
		const iconsList = [];
		listIcon.map(
			(value: { classImg: string; typeName: string; type: number }) => {
				if ((value.type === 2 ? nbrCasse ?? 0 : nbr ?? 0) !== 0) {
					iconsList.push(
						<div
							className="videListingStockItem"
							key={"item_" + item.id + "_" + value.typeName}
							onClick={() => props.itemSelected(item, value.type === 2)}
						>
							{!item.expedition && <span className="nbrItemListing">
                                <span className={"nbrItemListingText" + ((value.type === 2 ? nbrCasse : nbr) < 0 ? " color-red" : "")}>
                                    {value.type === 2 ? nbrCasse : nbr}
                                </span>
                            </span>}
							<TooltipGH>
                                <span className={value.classImg}>
                                    <span className="infoBulle">
                                        <SvgIcone icone={item.icon} />
                                    </span>
                                </span>
								<span className="info">{item.nom} {value.typeName} (id : {item.id})</span>
							</TooltipGH>
						</div>,
					);
				}
				
			},
		);
		
		return iconsList;
	};
	
	return (
		<React.Fragment>
			<div id="searchItemsListing">
				<label htmlFor="searchItemListing">
					{t("Rechercher un objet :", { ns: "ville" })}
				</label>
				<input
					type="search"
					name="searchItemListing"
					id="searchItemListing"
					value={itemSearch}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						const searchItem = event.currentTarget.value;
						setItemSearch(searchItem);
						setListItems(
							props.listItems.filter((item) =>
								normalizeSearch(searchItem, t(item.nom, { ns: "items" })),
							),
						);
					}}
					onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
						const searchItem = event.currentTarget.value;
						setItemSearch(searchItem);
						setListItems(
							props.listItems.filter((item) =>
								normalizeSearch(searchItem, t(item.nom, { ns: "items" })),
							),
						);
					}}
					onClick={() => {
						const searchItem = "";
						setItemSearch(searchItem);
						setListItems(props.listItems);
					}}
				/>
			</div>
			<table id="tabItemListing">
				<tbody>
				{props.listCategory.map((categorie) => {
					return (
						<tr
							className="ensCatItemListing"
							key={"categorie_" + categorie.id}
						>
							<td className="catItemListing fondWhite02">{categorie.nom}</td>
							<td className="tdItemListing fondWhite02">
								<div className="lignItemListing ">
									{listItems
										.filter((item: ItemPrototypeDTO) => {
											return item.category_objet.id === categorie.id && ((stock[item.id * 10] ?? 0) !== 0 || (stock[item.id * 10 + 1] ?? 0) !== 0);
										})
										.sort(compareItemsNames)
										.map((item) => {
											return affIcon(item, stock[item.id * 10], stock[item.id * 10 + 1]);
										})}
								</div>
							</td>
						</tr>
					);
				})}
				</tbody>
			</table>
		</React.Fragment>
	);
};

export function compareItemsNames(a: ItemPrototypeDTO, b: ItemPrototypeDTO) {
	return a.nom.localeCompare(b.nom);
}

import React, { useEffect, useState }             from "react";
import Modal                                      from "react-bootstrap/Modal";
import Button                                     from "react-bootstrap/Button";
import ConfirmationDialog                         from "../../../components/generality/ComposantGeneral/ConfirmationDialog";
import { InscriptionJumpApi }                     from "../../../services/api/InscriptionJumpApi";
import { InscriptionEventPropsType }              from "../../../types/components/Jump/InscriptionEvent.type";
import { FieldControlType }                       from "../../../types/components/Jump/InscriptionJump.type";
import SvgIcone                                   from "../../../components/generality/SvgIcone";
import { formatInTimeZone }                       from "date-fns-tz";
import InscriptionForm                            from "./InscriptionForm";
import HistoriqueModification                     from "./HistoriqueModification";
import { Link }                                   from "react-router";
import { useGeneralContext }                      from "../../../types/Context/GeneralContext";
import { Status_error, Status_success, usePopUp } from "../../../types/Context/PopUpContext";
import { UserDTO }                                from "../../../types/models/user.dto";
import { useTranslation }                         from "react-i18next";


export default function InscriptionEvent({ inscriptionProps, isArchMode = false }: { inscriptionProps: InscriptionEventPropsType, isArchMode?: boolean }) {
	const { t } = useTranslation();
	const { general } = useGeneralContext();
	const options = inscriptionProps.options;
	const jump = inscriptionProps.jump;
	const user = inscriptionProps.user;
	const inscriptionApi = new InscriptionJumpApi();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	
	
	const [inscription, setInscription] = useState(inscriptionProps.inscription);
	
	
	const [messagePopUpListErreur, setMessagePopUpListErreur] = useState(null);
	const [showModListErreur, setShowModListErreur] = useState(false);
	const [confirmShow, setConfirmShow] = useState(false);
	const [fieldErrors] = useState<FieldControlType>({});
	
	useEffect(() => {
		setInscription(inscriptionProps.inscription);
	}, [inscriptionProps]);
	
	const onRetour = (isErrorGestion: boolean, showModGestion: boolean, messagePopGestion: string) => {
		setStatus(isErrorGestion ? Status_error : Status_success);
		setShowPop(showModGestion);
		setMessagePopUp(messagePopGestion);
		if (!isErrorGestion) {
			setTimeout(() => {
				setShowPop(false);
				setMessagePopUp("");
			}, 1500);
		}
	};
	
	const handleClose = () => {
		setMessagePopUpListErreur(null);
		setShowModListErreur(false);
	};
	
	const handleSaveJump = () => {
		inscriptionApi.inscription_jump({ userId: user.id, jumpId: jump.id, inscription: inscription }).then((response) => {
			if (response.codeRetour === 0) {
				onRetour(false, true, response.libRetour);
				setTimeout(() => {
					onRetour(false, false, "");
				}, 2000);
				setInscription(response.zoneRetour.inscription);
			} else if (response.codeRetour === 2) {
				const retourErreur = <>
					<span>{response.libRetour}</span>
					<ul>{Object.values(response.zoneRetour.libErreur).map((erreur, index) => {
						return <li key={"erreur_" + index}>{erreur}</li>;
					})}</ul>
				</>;
				setShowModListErreur(true);
				setMessagePopUpListErreur(retourErreur);
			} else {
				onRetour(true, true, response.libRetour);
			}
		});
	};
	
	const handleModifJump = () => {
		inscriptionApi.modif_inscription_jump({ userId: user.id, jumpId: jump.id, inscription: inscription }).then((response) => {
			if (response.codeRetour === 0) {
				onRetour(false, true, response.libRetour);
				setTimeout(() => {
					onRetour(false, false, "");
				}, 2000);
				setInscription(response.zoneRetour.inscription);
			} else if (response.codeRetour === 2) {
				const retourErreur = <>
					<span>{response.libRetour}</span>
					<ul>{Object.values(response.zoneRetour.libErreur).map((erreur, index) => {
						return <li key={"erreur_" + index}>{erreur}</li>;
					})}</ul>
				</>;
				setShowModListErreur(true);
				setMessagePopUpListErreur(retourErreur);
			} else {
				onRetour(true, true, response.libRetour);
			}
		});
	};
	
	const handleDesitJump = () => {
		inscriptionApi.desist_inscription_jump({ userId: user.id, jumpId: jump.id, inscription: inscription }).then((response) => {
			if (response.codeRetour === 0) {
				onRetour(false, true, response.libRetour);
				setTimeout(() => {
					onRetour(false, false, "");
				}, 2000);
				setInscription(response.zoneRetour.inscription);
			} else if (response.codeRetour === 2) {
				const retourErreur = <>
					<span>{response.libRetour}</span>
					<ul>{Object.values(response.zoneRetour.libErreur).map((erreur, index) => {
						return <li key={"erreur_" + index}>{erreur}</li>;
					})}</ul>
				</>;
				setShowModListErreur(true);
				setMessagePopUpListErreur(retourErreur);
			} else {
				onRetour(true, true, response.libRetour);
			}
		});
	};
	
	const handleReinsJump = () => {
		inscriptionApi.reinscription_jump({ userId: user.id, jumpId: jump.id, inscription: inscription }).then((response) => {
			if (response.codeRetour === 0) {
				onRetour(false, true, response.libRetour);
				setTimeout(() => {
					onRetour(false, false, "");
				}, 2000);
				setInscription(response.zoneRetour.inscription);
			} else if (response.codeRetour === 2) {
				const retourErreur = <>
					<span>{response.libRetour}</span>
					<ul>{Object.values(response.zoneRetour.libErreur).map((erreur, index) => {
						return <li key={"erreur_" + index}>{erreur}</li>;
					})}</ul>
				</>;
				setShowModListErreur(true);
				setMessagePopUpListErreur(retourErreur);
			} else {
				onRetour(true, true, response.libRetour);
			}
		});
	};
	
	
	const listOrga = Object.values(jump.event.organisateurs).map((user: UserDTO) => user.pseudo).sort((a: string, b: string) => a.localeCompare(b));
	const orga = listOrga.length === 0 ? " " :
		(listOrga.length === 1 ? listOrga[0] : listOrga.slice(0, -1).join(", ") + " " + t("et", { ns: "app" }) + " " + listOrga[listOrga.length - 1]);
	
	
	return <div className="jumpCorps">
		<div id="feuilleInscription" className="fondWhite02">
			<div id="recapJumpInscription">
				<div id="banniereJump">{jump.banniere !== null && <img src={"/uploads/banniere/" + jump.banniere} alt="" />}</div>
				<span id="nomJumpInscription">{jump.nom}</span>
				<span><strong>{t("Type de ville :", { ns: "jumpEvent" })}</strong>&nbsp;<span className={"d-flex gap-1 align-items-center"}><SvgIcone icone={jump.type_ville.icon} /><span>{t(jump.type_ville.nom, { ns: "game" })}</span></span></span>
				<span><strong>{t("Organisé par", { ns: "jumpEvent" })}</strong>&nbsp;{orga}</span>
				<span><strong>{t("Date de fin d'inscription :", { ns: "jumpEvent" })}</strong>&nbsp;{formatInTimeZone(new Date(jump.date_fin_inscription), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</span>
				<span><strong>{t("Date début de l'event :", { ns: "jumpEvent" })}</strong>&nbsp;{formatInTimeZone(new Date(jump.date_approx_jump), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</span>
				<span><strong>{t("Statut de votre inscription :", { ns: "jumpEvent" })}</strong>&nbsp;{t(inscription.statut.nom, { ns: "jump" })}</span>
			</div>
			<div id="formInscriptionJump">
				<InscriptionForm inscriptionProps={inscription}
								 options={options}
								 jump={jump}
								 user={user}
								 fieldControl={fieldErrors}
								 isOrga={false}
								 onRetour={setInscription}
								 isArchMode={isArchMode}
				/>
				<div id="boutonInscription">
					{!isArchMode && inscription.statut.id === 0 &&
						<button type={"button"} className={"btn btn-primary btn-sm"} onClick={handleSaveJump}>{t("S'enregistrer", { ns: "jumpEvent" })}</button>}
					{!isArchMode && inscription.statut.id >= 1 && inscription.statut.id < 99 &&
						<button type={"button"} className={"btn btn-success btn-sm"} onClick={handleModifJump}>{t("Modifier", { ns: "jumpEvent" })}</button>}
					{!isArchMode && inscription.statut.id >= 1 && inscription.statut.id < 99 &&
						<button type={"button"} className={"btn btn-danger btn-sm"} onClick={handleDesitJump}>{t("Se désister", { ns: "jumpEvent" })}</button>}
					{!isArchMode && <Link to={"/event/" + jump.event.id} style={{ textDecoration: "none", color: "inherit" }}>
						<button type={"button"} className={"btn btn-secondary btn-sm"}>{t("Retour", { ns: "jumpEvent" })}</button>
					</Link>}
					{isArchMode && <Link to={"/event/archive/" + jump.event.id} style={{ textDecoration: "none", color: "inherit" }}>
						<button type={"button"} className={"btn btn-secondary btn-sm"}>{t("Retour", { ns: "jumpEvent" })}</button>
					</Link>}
					{!isArchMode && inscription.statut.id === 99 &&
						<button type={"button"} className={"btn btn-primary btn-sm"} onClick={handleReinsJump}>{t("Se réinscrire", { ns: "jumpEvent" })}</button>}
				</div>
			</div>
			<HistoriqueModification inscription={inscription} options={options} user={user} inGestion={false} />
		</div>
		<Modal show={showModListErreur} onHide={handleClose} centered={true}>
			<Modal.Header className={"modal-header-error"}>
				<Modal.Title>{t("Erreur !", { ns: "app" })}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{messagePopUpListErreur}</Modal.Body>
			{<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>{t("Fermer", { ns: "app" })}</Button>
			</Modal.Footer>}
		</Modal>
		<ConfirmationDialog
			message={t("Êtes-vous sur de vous désister ? Il sera possible de se réinscrire.", { ns: "jumpEvent" })}
			titre={t("Confimation", { ns: "jumpEvent" })}
			btnAnnuler={t("Annuler", { ns: "jumpEvent" })}
			btnConfirmation={t("Oui", { ns: "jumpEvent" })}
			show={confirmShow}
			onConfirm={() => {
				setConfirmShow(false);
			}}
			onCancel={() => setConfirmShow(false)}
		/>
	</div>;
}

import React, { useEffect, useState } from "react";

export type Colors =
	| "primary"
	| "secondary"
	| "success"
	| "danger"
	| "warning"
	| "info"
	| "light"
	| "dark";

export type ColorsOutline =
	| "outline-primary"
	| "outline-secondary"
	| "outline-success"
	| "outline-danger"
	| "outline-warning"
	| "outline-info"
	| "outline-light"
	| "outline-dark";

interface CustomSwitchToggleProps {
	onChange?: (checked: boolean) => void;
	checked?: boolean;
	disabled?: boolean;
	onlabel?: string;
	offlabel?: string;
	onstyle?: Colors | ColorsOutline;
	offstyle?: Colors | ColorsOutline;
	size?: "xs" | "sm" | "lg";
	style?: string;
	width?: number;
	height?: number;
}

const CustomSwitchToggle = (props: CustomSwitchToggleProps) => {
	const [checked, setChecked] = useState(typeof props.checked === "boolean" ? props.checked : false);
	const [disabled, setDisabled] = useState(typeof props.disabled === "boolean" ? props.disabled : false);
	const onlabel = props.onlabel || "On";
	const offlabel = props.offlabel || "Off";
	const onstyle = props.onstyle || "primary";
	const offstyle = props.offstyle || "light";
	const size = props.size || "";
	const style = props.style || "";
	const width = props.width || null;
	const height = props.height || null;
	
	useEffect(() => {
		if (typeof props.checked === "boolean") {
			setChecked(props.checked);
		}
	}, [props.checked]);
	
	const toggle = () => {
		if (checked) {
			off();
		} else {
			on();
		}
	};
	
	const off = () => {
		if (!disabled) {
			setChecked(false);
			if (props.onChange) {
				props.onChange(false);
			}
		}
	};
	
	const on = () => {
		if (!disabled) {
			setChecked(true);
			if (props.onChange) {
				props.onChange(true);
			}
		}
	};
	
	let switchStyle: { width?: string; height?: string } = {};
	if (width) {
		switchStyle.width = width + "px";
	} else {
		switchStyle.width = undefined;
	}
	if (height) {
		switchStyle.height = height + "px";
	}
	
	let labelStyle: { lineHeight?: string } = {};
	if (height) {
		labelStyle.lineHeight = "calc(" + height + "px * 0.8)";
	}
	
	return (
		<div
			className={
				"switch btn " +
				(checked ? "on btn-" + onstyle : "off btn-" + offstyle) +
				(size ? " btn-" + size : "") +
				(style ? " " + style : "")
			}
			style={switchStyle}
			onClick={toggle}
		>
			<div className="switch-group">
				<span className={"switch-on btn btn-" + onstyle + (size ? " btn-" + size : "")} style={labelStyle}>{onlabel}</span>
				<span className={"switch-off btn btn-" + offstyle + (size ? " btn-" + size : "")} style={labelStyle}>{offlabel}</span>
				<span className={"switch-handle btn btn-light" + (size ? "btn-" + size : "")} />
			</div>
		</div>
	);
};

export default CustomSwitchToggle;
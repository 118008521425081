import SvgIcone                       from "../../../components/generality/SvgIcone";
import React, { useEffect, useState } from "react";
import { CitoyensDTO }                from "../../../types/models/citoyens.dto";
import { UserDTO }                    from "../../../types/models/user.dto";
import { VilleDTO }                   from "../../../types/models/ville.dto";
import { useTranslation }             from "react-i18next";
import TooltipGH                      from "../../../components/utils/TooltipGH";

export function LibCoordonneCitoyen(props: { citoyen: CitoyensDTO, ville: VilleDTO }) {
	const { t } = useTranslation();
	const citoyen = props.citoyen;
	const ville = props.ville;
	
	let x_rel: number | null = null;
	let y_rel: number | null = null;
	if (citoyen.x !== null && citoyen.y !== null) {
		x_rel = citoyen.x - ville.pos_x;
		y_rel = ville.pos_y - citoyen.y;
	}
	
	const conditions = [
		{ check: ville.chaos || ville.devast || x_rel === null || y_rel === null, result: "(??/??)", icon: "r_explor" },
		{ check: !citoyen.dehors, result: t("Aux portes", { ns: "ville" }), icon: "h_home" },
		{ check: citoyen.dehors && x_rel === 0 && y_rel === 0, result: t("En ville", { ns: "ville" }), icon: "h_home" },
		{ check: true, result: `${x_rel}/${y_rel}`, icon: "r_explor" },
	];
	
	for (const condition of conditions) {
		if (condition.check) {
			return (
				<TooltipGH>
                    <span className="home infoBulle">
                        <SvgIcone icone={condition.icon} />
                    </span>
					<span className="info">{condition.result}</span>
				</TooltipGH>
			
			);
		}
	}
	
	
}

export function InputFormCitoyen(props: {
	type: string, name: string, user: UserDTO, citoyen: CitoyensDTO, choices?: any[], index: number, disabled?: boolean,
	onJoueurChange: (updatedJoueur: CitoyensDTO, index: number, nameForm: string) => void
}) {
	
	const [citoyen, setCitoyen] = useState(props.citoyen);
	
	useEffect(() => {
		setCitoyen(props.citoyen);
	}, [props.citoyen]);
	
	const type: string = props.type;
	const name: string = props.name;
	const id_joueur: number = props.citoyen.citoyen.id;
	const choices: any[] = props.choices ?? [];
	const disabled = props.disabled ?? false;
	
	let blocMaj = false;
	
	if ((props.citoyen.citoyen.user_personnalisation.bloc_maj_citoyens || props.user.user_personnalisation.bloc_maj_citoyen) && id_joueur !== props.user.id || disabled) {
		blocMaj = true;
	}
	
	switch (type) {
		case "select":
			if (blocMaj) {
				return <span>{props.citoyen[name]}</span>;
			} else {
				return <select name={name + "_" + id_joueur}
							   id={name + "_" + id_joueur}
							   key={name + "_" + id_joueur}
							   value={props.citoyen[name]}
							   onChange={(event) => {
								   const valueChoice = parseInt(event.target.value, 10);
								   props.onJoueurChange({ ...props.citoyen, [name]: valueChoice }, props.index, name);
							   }}>
					{choices.map(choice => {
						return <option value={choice} key={id_joueur + "_" + name + "_" + choice}>{choice}</option>;
					})}
				</select>;
			}
		case "coche":
			if (blocMaj) {
				if (props.citoyen[name]) {
					return <span className="icone_citoyen_bloc"><i className="fa-solid fa-check color-green icone_citoyen_centre"></i></span>;
				} else {
					return <span className="icone_citoyen_bloc"><i className="fa-solid fa-xmark color-red icone_citoyen_centre fa-lg"></i></span>;
				}
			} else {
				return <input type="checkbox"
							  name={name + "_" + id_joueur}
							  id={name + "_" + id_joueur}
							  key={name + "_" + id_joueur}
							  checked={props.citoyen[name]}
							  onChange={(event) => {
								  const { checked } = event.target;
								  props.onJoueurChange({ ...props.citoyen, [name]: checked }, props.index, name);
							  }} />;
			}
		case "input":
			if (blocMaj) {
				return <span>{props.citoyen[name]}</span>;
			} else {
				return <input type="number"
							  name={name + "_" + id_joueur}
							  id={name + "_" + id_joueur}
							  key={name + "_" + id_joueur}
							  value={citoyen[name]}
							  onChange={(event) => {
								  const valueChoice = parseInt(event.target.value, 10);
								  setCitoyen({ ...citoyen, [name]: (isNaN(valueChoice) ? "" : valueChoice) });
								  
							  }}
							  onBlur={(event) => {
								  const valueChoice = parseInt(event.target.value, 10);
								  props.onJoueurChange({ ...props.citoyen, [name]: (isNaN(valueChoice) ? 0 : valueChoice) }, props.index, name);
							  }}
				/>;
			}
	}
}
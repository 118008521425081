import { ErmiteId, HabitantId }                        from "../../../types/components/Generality/Job.const";
import { Campeur, InfoCase }                           from "../../../types/components/Outils/Camping.type";
import { calculMalusZombie, recupMaluseNombreCampeur } from "../../../containers/Ville/Carte/calculCamping";

const getMalusDistance = (infoCase: InfoCase): number => {
	const km = infoCase.km;
	
	const malus_distance = {
		0 : -100, 1: -75, 2: -50, 3: -25, 4: -10,
		5 : 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0,
		12: 5, 13: 7, 14: 10, 15: 15, 16: 20,
	};
	
	if (km > 16) {
		return 20;
	}
	return malus_distance[km as keyof typeof malus_distance];
};

const getMalusCampingSuccessif = (typeVille: string, pouvoir: string, nbr: number): number => {
	const malus_camping = {
		normal: {
			noob: {
				0: 80, 1: 60, 2: 35, 3: 15, 4: 0,
				5: -50, 6: -100, 7: -200, 8: -400,
				9: -1000, 10: -2000, 11: -5000, 12: -5000, 13: -5000,
			},
			pro : {
				0 : 80, 1: 70, 2: 60, 3: 40, 4: 30,
				5 : 20, 6: 0, 7: -50, 8: -100, 9: -200,
				10: -400, 11: -1000, 12: -2000, 13: -5000,
			},
		},
		pande : {
			noob: {
				0: 50, 1: 30, 2: 20, 3: 10, 4: 0,
				5: -50, 6: -100, 7: -200, 8: -400,
				9: -1000, 10: -2000, 11: -5000, 12: -5000, 13: -5000,
			},
			pro : {
				0 : 50, 1: 45, 2: 40, 3: 30, 4: 20,
				5 : 10, 6: 0, 7: -50, 8: -100, 9: -200,
				10: -400, 11: -1000, 12: -2000, 13: -5000,
			},
		},
	};
	
	if (nbr > 13) {
		nbr = 13;
	}
	
	return malus_camping[typeVille][pouvoir][nbr];
};

export const isProCalculAnonyme = (campeur: Campeur): boolean => {
	// Deux cas de figure :
	// si le campeur est anonyme, on va regarder lvl_reclus pour déterminer si il est pro ou non en fonction du nombre de camping,
	// si le campeur est identifié, on va regarder son nombre de campeur pro qu'il a le droit et le nombre de camping qu'il a déjà fait
	
	if (campeur.type === "anonyme") {
		switch (campeur.lvl_reclus) {
			case 0:
				return false;
			case 1:
				return campeur.nb_camping <= 6;
			case 2:
			case 4:
				return campeur.nb_camping <= 8;
			default:
				return false;
			
		}
	} else {
		return (campeur?.citoyen?.nb_camping_pro_noob ?? (campeur?.citoyen?.job?.id === 2 ? 0 : 6)) >= campeur.nb_camping;
	}
};

export const calculateChances = (
	infoCase: InfoCase,
	campeur: Campeur,
	position: number,
): number => {
	const typeVille = infoCase.hard ? "pande" : "normal";
	const malusVille = infoCase.hard ? -30 : 0;
	
	let bonusBat = -25;
	if (position <= infoCase.nbrCampMax || infoCase.nbrCampMax === 0) {
		if (infoCase.mode === "identifie" && infoCase.bat) {
			bonusBat = infoCase.bat.bonus_camping;
		} else if (infoCase.mode === "anonyme") {
			bonusBat = infoCase.bonus_camping;
		}
	}
	
	// Déterminer si le campeur est pro
	const isPro = campeur.type === "anonyme" ? isProCalculAnonyme(campeur) : ((campeur?.citoyen?.nb_camping_pro_noob ?? (campeur?.citoyen?.job?.id === 2 ? 0 : 6)) >= campeur.nb_camping);
	
	const jobId = campeur.type === "anonyme"
		? campeur.job.id
		: campeur.citoyen?.job?.id ?? 0;
	
	const pouvoir = (isPro && jobId !== HabitantId) ? "pro" : "noob";
	
	// Calcul des différents bonus/malus
	const malusDistance = getMalusDistance(infoCase);
	const malusCampingSucc = getMalusCampingSuccessif(typeVille, pouvoir, campeur.nb_camping);
	const malusCampeur = recupMaluseNombreCampeur(position);
	const malusZombie = calculMalusZombie(infoCase.zombie, jobId, campeur.capuche);
	const bonusNuit = infoCase.nuit ? 10 : 0;
	const bonusPhare = infoCase.phare ? 25 : 0;
	const bonusTombe = campeur.tombe ? 8 : 0;
	const bonusOdc = campeur.objet_camping * 5;
	const malusDevast = infoCase.devast ? -50 : 0;
	const amelioration = (infoCase.nbrAmelioOD === "" ? 0 : Number(infoCase.nbrAmelioOD)) * 5;
	
	const sommeBonusMalus =
			  malusDistance +
			  malusCampingSucc +
			  malusCampeur +
			  malusZombie +
			  bonusNuit +
			  bonusPhare +
			  bonusTombe +
			  bonusOdc +
			  malusDevast +
			  amelioration +
			  malusVille +
			  bonusBat;
	
	const maxChance = campeur.type === "anonyme" ? (jobId === ErmiteId ? 100 : campeur?.lvl_reclus === 4 ? 99 : 90) : (campeur?.citoyen?.chance_camping_max ?? 90);
	return Math.min(maxChance, sommeBonusMalus);
};

export const campingCalculator = {
	calculateChances,
	getMalusDistance,
	getMalusCampingSuccessif,
};
import React  from "react";
import svgBat from "../../../img/sprite_bat.svg";

export default function SvgBatiment(props: { icone: string, classBat?: string }) {
	
	let classBat = "icon_bat";
	if (props.classBat !== undefined) {
		classBat = props.classBat;
	}
	
	return <svg className={classBat}>
		<use xlinkHref={svgBat + "#" + props.icone} />
	</svg>;
}
import React, { useEffect, useRef }         from "react";
import { Chart, registerables }             from "chart.js";
import { BoxAndWiskers, BoxPlotController } from "@sgratzl/chartjs-chart-boxplot";
import { UserDTO }                          from "../types/models/user.dto";
import { ThemeUserDTO }                     from "../types/models/themeUser.dto";

interface ChartProps {
	data: {
		legend: string[],
		nbr: number[],
	},
	id: string,
	refChart: string,
	user?: UserDTO
	width?: number,
	height?: number
	backgroundColor: string[],
	translate?: {
		titre: string,
		table: {
			legend: string,
			nbr: string,
			pct: string,
		},
	},
	displayLegend?: boolean,
	themeUser?: ThemeUserDTO
}

const ChartBoxPlotComponant = ({ data, id, refChart, user = null, width = 500, height = 500, backgroundColor, translate = { titre: "", table: { legend: "", nbr: "", pct: "" } }, displayLegend = false, themeUser = null }: ChartProps) => {
	
	const chartPie = useRef<HTMLCanvasElement>(null);
	
	let colorFont = themeUser?.stats_font_color ?? "#ffffff";
	let colorGrid = themeUser?.stats_border_color ?? "rgba(255,255,255,0.15)";
	
	const buildChartTheme = () => {
		Chart.register(BoxPlotController, BoxAndWiskers, ...registerables);
		const myChartRef = chartPie.current?.getContext("2d");
		const myChart = Chart.getChart(refChart);
		if (myChart) {
			myChart.destroy();
		}
		
		new Chart(myChartRef!, {
			type   : "boxplot",
			data   : {
				labels  : Object.values(data.legend),
				datasets: [{
					backgroundColor       : "rgba(255, 206, 86, 0.4)",
					borderColor           : "rgb(255, 206, 86)",
					borderWidth           : 2,
					outlierBackgroundColor: "#908768",
					outlierRadius         : 2,
					data                  : Object.values(data.nbr),
				}],
			},
			options: {
				scales : {
					y: {
						ticks: {
							color: colorFont,
						},
						grid : {
							color: colorGrid,
						},
					},
					x: {
						ticks: {
							color: colorFont,
						},
						grid : {
							color: colorGrid,
						},
					},
					
				},
				plugins: {
					legend: {
						display : displayLegend,
						position: "top",
						labels  : {
							color: colorFont,
						},
					},
					title : {
						display: true,
						text   : translate.titre,
						color  : colorFont,
					},
				},
			},
		});
	};
	
	useEffect(() => {
		buildChartTheme();
	}, [data]);
	
	// calcul total data pour le pourcentage
	const totalData = Object.values(data.nbr).reduce((a, b) => a + b, 0);
	
	return (
		<div id={id + "-groupe-stats-graph-table"}>
			<div id={id + "-graph-theme"}>
				<canvas ref={chartPie} id={refChart} width={width} height={height}></canvas>
			</div>
		</div>
	);
};

export default ChartBoxPlotComponant;
import React, { useEffect, useState }                    from "react";
import SvgIcone                                          from "../../components/generality/SvgIcone";
import { AmeType }                                       from "../../types/components/Ame/Ame.type";
import { Avatar, getSortIndicatorClass, handleSortData } from "../../components/generality/ComposantGeneral";
import { Link, useNavigate }                             from "react-router";
import { ClassementAmeApi }                              from "../../services/api/ClassementAmeApi";
import Tabs                                              from "react-bootstrap/Tabs";
import Tab                                               from "react-bootstrap/Tab";
import TriangleSort                                      from "../../components/generality/ComposantGeneral/TriangleSort";
import { useGeneralContext }                             from "../../types/Context/GeneralContext";
import { Status_error, usePopUp }                        from "../../types/Context/PopUpContext";
import { Helmet }                                        from "react-helmet-async";
import { HistoriqueVilleDTO }                            from "../../types/models/historiqueVille.dto";
import { PictosDTO }                                     from "../../types/models/pictos.dto";
import { useTranslation }                                from "react-i18next";
import Form                                              from "react-bootstrap/Form";
import StatsAmes                                         from "./StatsAmes";
import Button                                            from "../../components/utils/Button";
import { faChevronDown, faUserCheck, faUserXmark }       from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon }                               from "@fortawesome/react-fontawesome";
import { useGHContext }                                  from "../../types/Context/GHContext";
import TooltipGH                                         from "../../components/utils/TooltipGH";

interface AmeProps {
	ame: AmeType;
}

interface SaisonAmeType {
	type: number;
	nom: string;
}

const generateCSSUser = (color: string) => {
	return `.listHistoVille.ville_commune{background-color:${color};}`;
};

export default function Ame({ ame }: AmeProps) {
	const { t } = useTranslation();
	const { setStatus, setShowPop, setMessagePopUp } = usePopUp();
	const [activeTab, setActiveTab] = useState<string>("histo");
	const [activeTabPicto, setActiveTabPicto] = useState<string>("picto");
	const [avatar, setAvatar] = useState(ame.userVisu.avatar);
	const [listVilles, setListVilles] = useState(ame.listVilles);
	const [afficherSuite, setAfficherSuite] = useState(false);
	const [filtre_saison, setFiltreSaison] = useState("-1");
	const [filtre_saison_voisin, setFiltreSaisonVoisin] = useState("-1");
	const [filtre_saison_ame, setFiltreSaisonAme] = useState("-1");
	const [listPicto, setListPicto] = useState(ame.listPicto);
	const [listVoisin, setListVoisin] = useState(ame.listVoisin);
	const [nbrVilleVoisin, setNbrVilleVoisin] = useState(ame.nbrVille);
	const [sortedColumnVoisin, setSortedColumnVoisin] = useState("");
	const [sortOrderVoisin, setSortOrderVoisin] = useState("");
	useNavigate();
	const { general } = useGeneralContext();
	const { setIsOnRefresh, triggerRefresh } = useGHContext();
	const [showCommune, setShowCommune] = useState(false);
	const [isOpenPanel, setIsOpenPanel] = useState(false);
	
	const userVisu = ame.userVisu;
	const histoPicto = ame.histoPicto;
	const ville = ame.ville;
	
	useEffect(() => {
		setListVoisin(ame.listVoisin);
		setListPicto(ame.listPicto);
		setListVilles(ame.listVilles);
		setNbrVilleVoisin(ame.nbrVille);
		setAvatar(ame.userVisu.avatar);
		
		if (ame.userVisu.id !== general.user.id) {
			setActiveTab("histo");
		}
		
	}, [ame]);
	
	
	let villeFiltre = listVilles;
	if (filtre_saison !== "-1") {
		villeFiltre = listVilles.filter((ville) => {
			let phase = ville.ville_histo.phase;
			phase = phase === "native" || phase === "import" ? "" : "-" + phase;
			return ville.ville_histo.saison + phase === filtre_saison;
		});
	}
	
	const listVillesFiltre = afficherSuite ? villeFiltre : villeFiltre.slice(0, 6);
	
	const nomPouvoir = userVisu.der_pouv.nom;
	const iconePouvoir = <SvgIcone icone={userVisu.der_pouv.icon} />;
	let date_maj_ame_string: string;
	if (userVisu.date_maj_ame !== null) {
		const date_maj_ame = new Date(Date.parse(userVisu.date_maj_ame));
		date_maj_ame_string = date_maj_ame.toLocaleDateString() + " - " + date_maj_ame.toLocaleTimeString().replace(/:\d\d$/, "");
	}
	
	const saisonsUniques: SaisonAmeType[] = ame.listVilles.reduce((unique: SaisonAmeType[], ville) => {
		let phase = ville.ville_histo.phase;
		phase = phase === "native" || phase === "import" ? "" : "-" + phase;
		const numphase = phase === "native" || phase === "import" ? 0 : (phase === "beta" ? 2 : 1);
		
		const saison: SaisonAmeType = {
			type: (numphase === 0) ? ville.ville_histo.saison * 10 : ((ville.ville_histo.saison - 1) * 10 + numphase),
			nom : ville.ville_histo.saison + phase,
		};
		
		// Vérifie si la saison existe déjà dans le tableau unique
		const index = unique.findIndex(item => item.type === saison.type && item.nom === saison.nom);
		
		// Si la saison n'existe pas déjà, ajoutez-la au tableau
		if (index === -1) {
			unique.push(saison);
		}
		
		return unique;
	}, []);
	
	const listSaison = [];
	listSaison.push(<option key={"saison_null"} value={-1}>
		{t("Toutes les saisons", { ns: "ame" })}
	</option>);
	saisonsUniques.sort((saison_a: SaisonAmeType, saison_b: SaisonAmeType) => saison_a.type > saison_b.type ? -1 : 1).map((saison, index) => {
		listSaison.push(
			<option key={"saison_" + index} value={saison.nom}>
				{t("Saison", { ns: "ame" }) + " " + saison.nom}
			</option>,
		);
	});
	
	const handleFiltreSaisonAme = (saison: string) => {
		setFiltreSaisonAme(saison);
		if (saison === "-1") {
			setListPicto(ame.listPicto);
		} else {
			const ameApi = new ClassementAmeApi();
			ameApi.cumulPictoUser(parseInt(saison), userVisu.id).then((response) => {
				setListPicto(Object.values(response.data.pictos).sort((a: PictosDTO, b: PictosDTO) => {
					if (a.picto.rare === b.picto.rare) {
						return b.nombre - a.nombre;
					} else {
						return a.picto.rare ? -1 : 1;
					}
				}));
			}).catch((error) => {
				setShowPop(true);
				setMessagePopUp(error.data.error);
				setStatus(Status_error);
			});
		}
	};
	const handleFiltreSaisonVoisin = (saison: string) => {
		setFiltreSaisonVoisin(saison);
		if (saison === "-1") {
			setListVoisin(ame.listVoisin);
			setNbrVilleVoisin(ame.nbrVille);
		} else {
			const ameApi = new ClassementAmeApi();
			ameApi.voisinUser(saison, userVisu.id).then((response) => {
				setListVoisin(Object.values(response.data.listVoisin));
				setNbrVilleVoisin(response.data.nbrVille);
			}).catch((error) => {
				setShowPop(true);
				setMessagePopUp(error.data.error);
				setStatus(Status_error);
			});
		}
	};
	
	const handleMajAme = () => {
		const ameApi = new ClassementAmeApi();
		setIsOnRefresh(true);
		ameApi.updateSoul().then(() => {
			triggerRefresh();
		}).catch((error) => {
			setShowPop(true);
			setMessagePopUp(error?.data?.error ?? error?.message ?? t("Erreur lors de la mise à jour de l'âme", { ns: "ame" }));
			setStatus(Status_error);
		});
	};
	
	const sortedLists = (list: { pseudo: string, id: number, nbVille: number }[], sortedColumn: string, orderColumn: string) => {
		if (sortedColumn === "nbr") {
			return Object.values(list).sort((a, b) => {
				if (a.nbVille === b.nbVille) {
					return a.pseudo.localeCompare(b.pseudo);
				} else if (orderColumn === "asc") {
					return a.nbVille > b.nbVille ? 1 : -1;
				} else {
					return a.nbVille < b.nbVille ? 1 : -1;
				}
			});
		} else if (sortedColumn === "pseudo") {
			return Object.values(list).sort((a, b) => {
				if (a.pseudo === b.pseudo) {
					return a.nbVille > b.nbVille ? 1 : -1;
				} else if (orderColumn === "asc") {
					return a.pseudo.localeCompare(b.pseudo);
				} else {
					return b.pseudo.localeCompare(a.pseudo);
				}
			});
		} else {
			return Object.values(list).sort((a, b) => {
				if (a.nbVille === b.nbVille) {
					return a.pseudo.localeCompare(b.pseudo);
				} else if (orderColumn === "asc") {
					return a.nbVille > b.nbVille ? 1 : -1;
				} else {
					return a.nbVille < b.nbVille ? 1 : -1;
				}
			});
		}
	};
	
	const villeCommune = ame.commune.map((id) => parseInt(id, 10));
	
	return (
		<div id="corpsAme">
			<Helmet>
				<style>{generateCSSUser(general.themeUser.user_perso_couleur.color_ville_commune)}</style>
			</Helmet>
			<div id="zone_groupe_avatar_listing">
				<div id="info_perso_ame">
					<div className="avatar_ame">
						<Avatar url={avatar} />
					</div>
					<div id={"container_infoAme_general"}>
						<div id={"container_infoAme"}>
							<div className={`panel_ame d-flex flex-column info_complementaire_ame left-panel ${isOpenPanel ? "hidden" : ""}`}>
								<span>{t("Pseudo : {pseudo}", { ns: "ame" }).replace("{pseudo}", userVisu.pseudo)}</span>
								<span className={"d-flex gap-1 justify-content-start align-items-center"}>
                                    <span>{t("Dernier pouvoir : {pouvoir}", { ns: "ame" }).replace("{pouvoir}", "")}</span>
                                    <span>{iconePouvoir}</span>
                                    <span>{t(nomPouvoir, { ns: "game" })}</span>
                                </span>
								<span className="nomVille_ame d-flex flex-column">{userVisu.map_id === null && t("Actuellement pas en ville", { ns: "ame" })}{userVisu.map_id !== null && (<React.Fragment>
									<span>{t("Ville en cours : {ville}", { ns: "ame" }).replace("{ville}", "")}</span>
									<span className={"lien_carte_ame"}><Link to={"/carte/" + ville.mapId} className={"d-flex gap-1 justify-content-start"}><span>{ville.nom + " " + ville.mapId}</span><i className="fa-solid fa-arrow-up-right-from-square"></i></Link></span>
								</React.Fragment>)}
                            </span>
							</div>
							<div className={`panel_ame d-flex align-items-center justify-content-start info_complementaire_stat right-panel${isOpenPanel ? "open" : ""}`}>
								<div className={`toggle-button_ame ${isOpenPanel ? "open" : ""}`}>
									<Button couleur={"primary"} taille={"xs"} onClick={() => setIsOpenPanel(!isOpenPanel)} className={"d-flex gap-2 justify-content-center align-items-center"}>
										<span>{t("Statistiques", { ns: "ame" })}</span>
										{isOpenPanel ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronDown} />}
									</Button>
								</div>
								<div>
									<StatsAmes ameStats={ame.stats} />
								</div>
							</div>
						</div>
						<div className={"mt-1 mb-1 d-flex justify-content-center align-self-center align-items-center gap-1"}>
							<div>
								{userVisu.date_maj_ame === null && (<span>{t("Dernière mise à jour de l'âme : inconnue", { ns: "ame" })}</span>)}
								{userVisu.date_maj_ame !== null && (<span style={{ fontSize: "10px" }}>{t("Dernière mise à jour de l'âme : {date}", { ns: "ame" }).replace("{date}", date_maj_ame_string)}</span>)}
							</div>
							<div>
								{userVisu.id === general.user.id && <Button couleur={"primary"} taille={"xs"} onClick={() => handleMajAme()}>{t("Mettre à jour mon âme", { ns: "ame" })}</Button>}
							</div>
						</div>
					</div>
				</div>
				<div id={"zoneListingPictosTitre"}>
					<Tabs activeKey={activeTabPicto} onSelect={setActiveTabPicto}>
						<Tab eventKey={"picto"} title={t("Pictos", { ns: "ame" })}>
							<div id={"zone_tabs_picto"}>
								<div>
									<span>{t("Total des pictos :", { ns: "ame" })}</span>
									<select
										value={filtre_saison_ame}
										onChange={(event) => {
											handleFiltreSaisonAme(event.target.value);
										}}
									>
										{Object.values(ame.listeSaison).map((saison) => {
											return <option key={saison.id} value={saison.id}>{saison.nom}</option>;
										})}
									</select>
								</div>
								<div id="zoneListingPictos">
									{listPicto.map((picto: PictosDTO) => {
										return (<div className={(picto.picto.rare ? "pictoRareAme" : "pictoCommunAme") + " tablePictoAme"} key={"picto_" + picto.picto.id}>
											<TooltipGH>
                                                <span className="infoBulle">
                                                    <span className="ensemblePicto_Nombre">
                                                        <span className="picto_ame_nombre">{picto.nombre}</span>
                                                        <span className="picto_ame_img">{<SvgIcone icone={picto.picto.img} />}</span>
                                                    </span>
                                                </span>
												<span className="info">{t(picto.picto.name, { ns: "game" })}</span>
											</TooltipGH>
										
										</div>);
									})}
								</div>
							</div>
						</Tab>
						<Tab eventKey={"titre"} title={t("Titres", { ns: "ame" })}>
							<div id={"zone_tabs_picto"}>
								<div id="zoneListingTitres">
									{listPicto.filter((picto) => picto.picto.titre.length > 0).sort((picto_a, picto_b) => t(picto_a.picto.name, { ns: "game" }).localeCompare(t(picto_b.picto.name, { ns: "game" }))).map((picto: PictosDTO) => {
										return (<div className={"tableTitreAme"} key={"picto_" + picto.picto.id}>
											<div className={"p-1"}>
												<div className={`d-flex gap-1 align-items-center align-self-center ${picto.picto.rare ? "titreRareAme" : "titreCommunAme"}`}>
													<SvgIcone icone={picto.picto.img} />
													<span>{t(picto.picto.name, { ns: "game" })}</span>
													<span>({t("Valeur :", { ns: "game" })} {picto.nombre}) :</span>
												</div>
												<div className={"d-flex gap-1 align-items-start flex-column p-1 pt-2 ps-2"}>
													{picto.picto.titre.sort((titre_a, titre_b) => titre_a.nbr - titre_b.nbr).map((titre, index) => {
														const obtenu = (titre?.nbr ?? 99999999) <= picto.nombre;
														return <div key={"titre_" + index} className={"d-flex gap-1 align-items-center"}>
															<span className={obtenu ? "color-green" : "color-red"}>{obtenu ? <FontAwesomeIcon icon={faUserCheck} /> : <FontAwesomeIcon icon={faUserXmark} />}</span>
															<span>{t(titre.titre, { ns: "game" })}</span>
															<span>(x{titre?.nbr ?? "???"})</span>
														</div>;
													})}
												</div>
											</div>
										</div>);
									})}
								</div>
							</div>
						</Tab>
					</Tabs>
				</div>
			
			</div>
			
			<div id="zoneListingVille">
				<Tabs activeKey={activeTab} onSelect={setActiveTab}>
					<Tab eventKey={"histo"} title={t("Historiques des incarnations", { ns: "ame" })}>
						<div id={"zone_tabs_histo"}>
							<div className={"menu_ame_ville"}>
								<div className={"filtre_saison_ville"}>
									<div>
										<label htmlFor={"filtre_saison"}>{t("Voir les villes de la saison :", { ns: "ame" })}</label>
										<select
											name="filtre_saison"
											id="filtre_saison"
											value={filtre_saison ?? "-1"}
											onChange={(event) => {
												setFiltreSaison(event.target.value);
											}}
										>
											{listSaison}
										</select>
									</div>
									{userVisu.id !== general.user.id && <div className={"d-flex justify-content-center align-content-center gap-2"}>
										<label htmlFor={"filtre_commun"}>{t("Voir uniquement les villes communes :", { ns: "ame" })}</label>
										<Form.Check
											type="switch"
											checked={showCommune}
											onChange={() => setShowCommune(!showCommune)}
										/>
									</div>}
								</div>
								<div className={"bouton_moins_ville"}>
									{afficherSuite &&
										(<button className={"ame_chargement_ville btn btn-primary btn-xs"} onClick={() => setAfficherSuite(false)}>{t("Voir moins de villes", { ns: "ame" })}</button>)}
								</div>
								<div className={"legend-color-commune"}>
									{general.user?.id_my_hordes !== userVisu.id_my_hordes && <><span style={{ backgroundColor: general.themeUser.user_perso_couleur.color_ville_commune, width: "20px", height: "20px", display: "inline-block" }}></span><span>{t("Villes communes", { ns: "ame" })}</span></>}
								</div>
							</div>
							
							{listVillesFiltre.filter((histoVille: HistoriqueVilleDTO) => {
								return !showCommune ? true : villeCommune.includes(histoVille?.ville_histo?.id ?? 0);
							}).map((histoVille: HistoriqueVilleDTO, index: number) => {
								return (
									<div className={"listHistoVille" + ((villeCommune.includes(histoVille?.ville_histo?.id ?? 0)) ? " ville_commune" : " ville_standard")} key={"ville_" + index}>
										<div className="nomVille_listing_ame d-flex justify-content-center gap-1 align-items-center">
											<span>{histoVille.ville_histo.nom}</span>
											<span>-</span>
											{histoVille.ville !== null && (<TooltipGH>
                                                <span className="infoBulle lien_carte_ame">
                                                    <Link to={"/carte/" + histoVille.ville.map_id}>
                                                        <span className={"d-flex justify-content-center gap-1 align-items-center"}>
                                                            <span>{histoVille.ville.map_id}</span>
                                                            <i className="fa-solid fa-arrow-up-right-from-square"></i>
                                                        </span>
                                                    </Link>
                                                </span>
												<span className="info">{t("Voir la carte", { ns: "ame" })}</span>
											</TooltipGH>)}
											{histoVille.ville === null && <span>{histoVille.ville_histo.mapid}</span>}
											<span>-</span>
											<span className={"d-flex justify-content-center gap-1 align-items-center"}>
                                                <SvgIcone icone={"r_heroac"} />
                                                <span>{t("Saison", { ns: "ame" })}</span>
                                                <span>{histoVille.ville_histo.saison}</span>
												{histoVille.ville_histo.phase !== "native" && histoVille.ville_histo.phase !== "import" ? <span>{histoVille.ville_histo.phase}</span> : null}
												<SvgIcone icone={"r_heroac"} />
                                            </span>
										</div>
										<div className={"d-flex justify-content-center gap-1 align-items-center"}>
											<span>{t("Mort J", { ns: "ame" })}{histoVille.day_of_death}</span>
											<TooltipGH>
                                                <span className="infoBulle">
                                                    <SvgIcone icone={"h_death"} />
                                                </span>
												<span className="info">{t("Cause :", { ns: "ame" })} {histoVille.type_mort.label}{histoVille.clean_type_histo !== null && (
													<React.Fragment>
														<br />
														<span>{histoVille.clean_type_histo.label}{" "}{t("par", { ns: "ame" })}{" "}<span className={"nom_cleaneur"}>{histoVille.clean_name === null ? t("inconnu", { ns: "ame" }) : histoVille.clean_name}</span></span>
													</React.Fragment>)}
                                                </span>
											</TooltipGH>
											
											{histoVille.msg !== null && histoVille.msg !== "" && (<React.Fragment>
												<span className="message_mort_ame">&nbsp;-&nbsp; {t("Message de mort", { ns: "ame" })}</span>
												<TooltipGH>
													<span className="infoBulle">{" "}<SvgIcone icone={"r_forum"} /></span>
													<span className="info">{histoVille.msg}</span>
												</TooltipGH>
											
											</React.Fragment>)}
										</div>
										{(general.user !== null && ((userVisu.show_histo_picto && general.user?.id_my_hordes !== userVisu.id_my_hordes) || general.user?.id_my_hordes === userVisu.id_my_hordes)) && (
											<div className="zoneHistoPictos">{histoPicto[histoVille.ville_histo.mapid * 10 + 2] && histoPicto[histoVille.ville_histo.mapid * 10 + 2].map((picto, index) => {
												return <div key={histoVille.ville_histo.mapid * 10 + 2 + "_" + index} className={`${picto.picto.rare ? "pictoRareAme" : "pictoCommunAme"} tablePictoAme`}>
													<TooltipGH>
                                                        <span className="infoBulle">
                                                            <span className="ensemblePicto_Nombre">
                                                                <span className="picto_ame_nombre">{picto.nombre}</span>
                                                                <span className="picto_ame_img"><SvgIcone icone={picto.picto.img} /></span>
                                                            </span>
                                                        </span>
														<span className="info">{t(picto.picto.name, { ns: "game" })}</span>
													</TooltipGH>
												</div>;
											})}</div>)}
									</div>
								);
							})}
							{!afficherSuite && <button className={"ame_chargement_ville btn btn-primary btn-xs"} onClick={() => setAfficherSuite(true)}>{t("Charger plus de villes", { ns: "ame" })}</button>}
						</div>
					</Tab>
					{general.user?.id_my_hordes === userVisu.id_my_hordes && <Tab eventKey={"voisin"} title={t("Liste des voisins", { ns: "ame" })}>
						<div id={"zone_tabs_voisin"}>
							<div className={"filtre_saison_ville"}>
								<span>{t("Nombre de ville totale :", { ns: "ame" }) + " " + nbrVilleVoisin}</span>
							</div>
							<div className={"filtre_saison_ville"}>
								<label htmlFor={"filtre_saison_voisin"}>{t("Voir mes voisins de la saison :", { ns: "ame" })}</label>
								<select
									name="filtre_saison_voisin"
									id="filtre_saison_voisin"
									value={filtre_saison_voisin ?? "-1"}
									onChange={(event) => {
										handleFiltreSaisonVoisin(event.target.value);
									}}
								>
									{listSaison}
								</select>
							</div>
							<table id={"tabs_voisin_ame"}>
								<thead>
								<tr>
									<th>
										<div className={"entete_tri"}
											 onClick={() => handleSortData("pseudo", sortedColumnVoisin, sortOrderVoisin, setSortedColumnVoisin, setSortOrderVoisin)}>
											<div id={"entete_ame_voisin_name"}>{t("Pseudo", { ns: "ame" })}</div>
											<TriangleSort direction={getSortIndicatorClass("pseudo", sortedColumnVoisin, sortOrderVoisin)} />
										</div>
									</th>
									<th>
										<div className={"entete_tri"}
											 onClick={() => handleSortData("nbr", sortedColumnVoisin, sortOrderVoisin, setSortedColumnVoisin, setSortOrderVoisin)}>
											<div id={"entete_ame_voisin_nbr"}>{t("Nombre", { ns: "ame" })}</div>
											<TriangleSort direction={getSortIndicatorClass("nbr", sortedColumnVoisin, sortOrderVoisin)} />
										</div>
									</th>
									<th>
										<div id={"entete_ame_voisin_pct"}>{t("% ville commune", { ns: "ame" })}</div>
									</th>
								</tr>
								</thead>
								<tbody>
								{sortedLists(listVoisin, sortedColumnVoisin, sortOrderVoisin).map((voisin, index) => {
									return <tr key={"voisin_" + index}>
										<td><span className={"lien_to_ame"}><Link to={"/ame/" + voisin.id}>{voisin.pseudo}<i
											className="fa-solid fa-arrow-up-right-from-square"></i></Link></span></td>
										<td>{voisin.nbVille}</td>
										<td>{Math.round(voisin.nbVille / nbrVilleVoisin * 10000) / 100}</td>
									</tr>;
								})}
								</tbody>
							</table>
						</div>
					</Tab>}
				</Tabs>
			</div>
		</div>
	);
}
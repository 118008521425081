import React                                                                     from "react";
import simpleRestProvider                                                        from "ra-data-simple-rest";
import { Admin, AppBar, CustomRoutes, defaultDarkTheme, Layout, Menu, Resource } from "react-admin";
import { UserEdit, UserList }                                                    from "./User";
import { DashBoard }                                                             from "./DashBoard";
import { VilleEdit, VilleList }                                                  from "./Ville";
import { ExpeditionEdit, ExpeditionList }                                        from "./Ville/Expedition";
import { Route }                                                                 from "react-router";
import { LogList }                                                               from "./Log";
import { BatimentEdit, BatimentList }                                            from "./Ville/Batiment";
import { RuineEdit, RuineList }                                                  from "./Ville/Ruine";
import { ChantierEdit, ChantierList }                                            from "./Chantiers";
import { ItemCreate, ItemEdit, ItemList }                                        from "./Items";
import { CategorieCreate, CategorieEdit, CategorieList }                         from "./Items/Categorie";
import { DechargeCreate, DechargeEdit, DechargeList }                            from "./Chantier/Decharge";
import { HerosCreate, HerosEdit, HerosList }                                     from "./Users/Heros";
import { BatimentsEdit, BatimentsList }                                          from "./Batiments";
import { ArbreChantierList }                                                     from "./Chantier/ArbreChantier";
import ParamGeneral                                                              from "./DashBoard/ParamGeneral";
import { BsFileText, BsTools }                                                   from "react-icons/bs";
import { ImPower }                                                               from "react-icons/im";
import { MdEmojiEvents }                                                         from "react-icons/md";
import { SiBandsintown }                                                         from "react-icons/si";

import { RuineCasesEdit, RuineCasesList }                               from "./Ville/RuineCases";
import { JumpEdit, JumpList }                                           from "./Jump";
import { MenuCreate, MenuEdit, MenuList }                               from "./DashBoard/MenuPrototype";
import MenuDesigner                                                     from "./DashBoard/MenuDesigner";
import { AssemblageCreate, AssemblageEdit, AssemblageList }             from "./Items/Assemblage";
import { PictoEdit, PictoList }                                         from "./Pictos";
import { HerosSkillCreate, HerosSkillEdit, HerosSkillList }             from "./HerosSkill";
import { VersionManagerCreate, VersionManagerEdit, VersionManagerList } from "./DashBoard/VersionDesigner";

export default function AdminPanel() {
	
	const MyMenu = () => (
		<Menu position="absolute">
			<Menu.DashboardItem />
			<Menu.Item to={"/admin/param"} primaryText={"Paramètres"} leftIcon={<BsTools />} />
			<Menu.Item to={"/admin/menu"} primaryText={"Menu"} leftIcon={<BsTools />} />
			<Menu.ResourceItem name="batiment" />
			<Menu.ResourceItem name="chantier" />
			<Menu.ResourceItem name="logs" />
			<Menu.ResourceItem name="items" />
			<Menu.ResourceItem name="pictos" />
			<Menu.ResourceItem name="user" />
			<Menu.Item to={"/admin/ville"} primaryText={"Villes"} leftIcon={<SiBandsintown />} />
			<Menu.ResourceItem name="event" />
			<Menu.Item to={"/admin/jump"} primaryText={"Jumps"} leftIcon={<MdEmojiEvents />} />
			<Menu.Item to={"/admin/herosskill"} primaryText={"Heros Skill"} leftIcon={<ImPower />} />
			<Menu.Item to={"/admin/versions"} primaryText={"Versions"} leftIcon={<BsFileText />} />
		</Menu>
	);
	
	const MyAppBar = () => <AppBar color="primary" position="absolute" />;
	
	const MyLayout = props => <Layout {...props} menu={MyMenu} appBar={MyAppBar} />;
	
	const dataProvider = simpleRestProvider("/rest/v1/admin");
	
	const theme = {
		...defaultDarkTheme,
		components: {
			...defaultDarkTheme.components,
			Admin: {
				maxWidth: "1220px",
			},
		},
	};
	
	return <div id={"body_admin"}>
		<Admin layout={MyLayout} basename="/admin" theme={theme} dataProvider={dataProvider} dashboard={DashBoard} disableTelemetry={true}>
			<CustomRoutes>
				<Route path="/" element={<DashBoard />} />
				<Route path="/param" element={<ParamGeneral />} />
			</CustomRoutes>
			<Resource name="menu" list={MenuList} edit={MenuEdit} create={MenuCreate}>
				<Route path="/design" element={<MenuDesigner />} />
			</Resource>
			<Resource name="logs" list={LogList} />
			<Resource name="batiment" list={BatimentsList} edit={BatimentsEdit}>
			</Resource>
			<Resource name="chantier" list={ChantierList} edit={ChantierEdit}>
				<Route path="/arbre" element={<ArbreChantierList />} />
				<Route path="/decharge" element={<DechargeList />} />
				<Route path="/decharge/:id" element={<DechargeEdit />} />
				<Route path="/decharge/create" element={<DechargeCreate />} />
			</Resource>
			<Resource name="items" list={ItemList} edit={ItemEdit}>
				<Route path="/create" element={<ItemCreate />} />
				<Route path="/categorie" element={<CategorieList />} />
				<Route path="/categorie/:id" element={<CategorieEdit />} />
				<Route path="/categorie/create" element={<CategorieCreate />} />
			</Resource>
			<Resource name="pictos" list={PictoList} edit={PictoEdit}></Resource>
			<Resource name="user" list={UserList} edit={UserEdit}>
				<Route path="/heros" element={<HerosList />} />
				<Route path="/heros/:id" element={<HerosEdit />} />
				<Route path="/heros/create" element={<HerosCreate />} />
			</Resource>
			<Resource name="ville" list={VilleList} edit={VilleEdit}>
				<Route path=":id/expedition" element={<ExpeditionList />} />
				<Route path=":id/expedition/:expe_id" element={<ExpeditionEdit />} />
				<Route path=":id/batiment" element={<BatimentList />} />
				<Route path=":id/batiment/:bat_id" element={<BatimentEdit />} />
				<Route path=":id/ruine" element={<RuineList />} />
				<Route path=":id/ruine/:ruine_id" element={<RuineEdit />} />
				<Route path=":id/ruine/:ruine_id/cases" element={<RuineCasesList />} />
				<Route path=":id/ruine/:ruine_id/cases/:case_id" element={<RuineCasesEdit />} />
			</Resource>
			<Resource name="jump" list={JumpList} edit={JumpEdit}></Resource>
			<Resource name="items_assemblage" list={AssemblageList} edit={AssemblageEdit} create={AssemblageCreate}></Resource>
			<Resource name="herosskill" list={HerosSkillList} edit={HerosSkillEdit} create={HerosSkillCreate}></Resource>
			<Resource name="versions" list={VersionManagerList} edit={VersionManagerEdit} create={VersionManagerCreate}></Resource>
		</Admin>
	</div>;
	
}
import { Fetch }                              from "../../components/generality/Fetch";
import { FetchGH }                            from "../../components/generality/FetchGH";
import { EventEnAvantType, HabilitationMenu } from "../../types/components/Generality/General.type";
import { ChantierPrototypeDTO }               from "../../types/models/chantierPrototype.dto";
import { MenuDTO }                            from "../../types/models/menu.dto";

export class AdminApi {
	private fetch: Fetch;
	private fetchGH: FetchGH;
	
	constructor() {
		this.fetch = new Fetch("admin");
		this.fetchGH = new FetchGH("admin");
	}
	
	public stats(): Promise<RetourStats> {
		return this.fetch.from("/stats").request().get();
	}
	
	public generaux(): Promise<RetourGeneraux> {
		return this.fetch.from("/general").request().get();
	}
	
	public maj_generaux(data: GenerauxApp): Promise<RetourGeneraux> {
		return this.fetch.from("/general").request().put(data);
	}
	
	public maj_objet(): Promise<RetourMaj> {
		return this.fetch.from("/majPrototype_items").request().post();
	}
	
	public maj_bats(): Promise<RetourMaj> {
		return this.fetch.from("/majPrototype_bats").request().post();
	}
	
	public maj_pictos(): Promise<RetourMaj> {
		return this.fetch.from("/majPrototype_pictos").request().post();
	}
	
	public maj_chantiers(): Promise<RetourMaj> {
		return this.fetch.from("/majPrototype_chantier").request().post();
	}
	
	public maj_Parentchantiers(): Promise<RetourMaj> {
		return this.fetch.from("/majParent_chantier").request().post();
	}
	
	public maj_OrderBy(): Promise<RetourMajOrder> {
		return this.fetch.from("/chantier_arbre/majOrder").request().post();
	}
	
	public update_manual_orderBy(data: {
		categories: ChantierPrototypeDTO[];
	}): Promise<RetourMaj> {
		return this.fetch.from("/chantier_arbre/maj_arbre").request().post(data);
	}
	
	public maj_chantiers_json(): Promise<RetourMaj> {
		return this.fetch.from("/chantier_arbre/maj_json").request().post();
	}
	
	public maj_bats_json(): Promise<RetourMaj> {
		return this.fetch.from("/batiment/maj_json").request().post();
	}
	
	public maj_items_json(): Promise<RetourMaj> {
		return this.fetch.from("/items/maj_json").request().post();
	}
	
	public maj_menus_json(): Promise<RetourMajGH> {
		return this.fetchGH.post({ path: "/menu/maj_json" });
	}
	
	public maj_pictos_json(): Promise<RetourMajGH> {
		return this.fetchGH.post({ path: "/pictos/maj_json" });
	}
	
	public maj_versions_json(): Promise<RetourMajGH> {
		return this.fetchGH.post({ path: "/versions/maj_json" });
	}
	
	public maj_heros_json(): Promise<RetourMajGH> {
		return this.fetchGH.post({ path: "/herosskill/maj_json" });
	}
	
	public maj_menu({ data }: { data: MenuDTO }): Promise<RetourMenuGH> {
		return this.fetchGH.post({ path: "/menu/save-menu/", data: data });
	}
	
	public cache_clear(): Promise<CacheClear> {
		return this.fetchGH.post({ path: "/cache" });
	}
}

export type RetourStats = {
	stat: {
		theme: string[];
		nbr: number[];
		stats_count: number[];
	};
};

export type RetourMaj = {
	codeRetour: number;
	libRetour: string;
};
export type RetourMajGH = {
	data: {
		libRetour: string;
		error?: string,
	}
	status?: number,
	message?: string,
};
export type RetourMenuGH = {
	data: {
		menu: MenuDTO;
		error?: string,
	}
	status?: number,
	message?: string,
};
export type CacheClear = {
	data: {
		output: string;
		error?: string,
	}
	status?: number,
	message?: string,
};

export type RetourGeneraux = {
	codeRetour: number;
	libRetour: string;
	generaux?: GenerauxApp;
};

export type GenerauxApp = {
	lienMHE: string;
	lienMHO: string;
	lienGit: string;
	lienCrowdin: string;
	versionJeu: string;
	lienDiscord: string;
	habilitation: HabilitationMenu;
	miseEnAvant: EventEnAvantType[],
};

export type RetourMajOrder = {
	codeRetour: number;
	libRetour: string;
	zoneRetour: {
		categorie: ChantierPrototypeDTO[];
	};
};

import { OptionsPersoType }           from "../../types/components/OptionsPerso/OptionsPerso.type";
import { GeneralType }                from "../../types/components/Generality/General.type";
import { FetchGH }                    from "../../components/generality/FetchGH";
import { UserDTO }                    from "../../types/models/user.dto";
import { DispoUserTypeExpeditionDTO } from "../../types/models/dispoUserTypeExpedition.dto";
import { ThemeUserDTO }               from "../../types/models/themeUser.dto";
import { MenuDTO }                    from "../../types/models/menu.dto";
import { RetourMenuGH }               from "./AdminApi";


export class OptionPersoApi {
	
	private fetchGH: FetchGH;
	
	constructor(mapId?: number) {
		this.fetchGH = new FetchGH("perso", mapId ?? 0);
	}
	
	public main(): Promise<AffichageOptionsPerso> {
		return this.fetchGH.get("/");
	}
	
	public majUser(data: { user: UserDTO, themeId: number }): Promise<RetourOptionPersoBase> {
		return this.fetchGH.post({ path: "/maj_options", data: data });
	}
	
	public majThemeUser(data: { userId: number, themeUser: ThemeUserDTO, themeToSelect: boolean }): Promise<RetourOptionPersoBase> {
		return this.fetchGH.post({ path: "/maj_theme_user", data: data });
	}
	
	public majDispoUser(data: { user_id: number, dispo_type: DispoUserTypeExpeditionDTO }): Promise<RetourDispoUserExpe> {
		return this.fetchGH.post({ path: "/maj_dispo_user", data: data });
	}
	
	public suppDispoUser(dispo_type_id: number): Promise<RetourSuppDispoUserExpe> {
		return this.fetchGH.delete("/suppDispoUser/" + dispo_type_id);
	}
	
	public suppThemeUser(data: { userId: number, themeUserId: number }): Promise<RetourOptionPersoBase> {
		return this.fetchGH.post({ path: "/supp_theme_user", data: data });
	}
	
	public maj_menu({ data }: { data: MenuDTO }): Promise<RetourMenuGH> {
		return this.fetchGH.post({ path: "/save-menu", data: data });
	}
	
}

export interface RetourOptionPersoBase {
	data: {
		user?: UserDTO,
		general?: GeneralType,
		libRetour?: string,
		error?: string,
	}
	status?: number,
	message?: string,
}

export interface RetourDispoUserExpe {
	data: {
		dispo?: DispoUserTypeExpeditionDTO,
		error?: string,
	}
	status?: number,
	message?: string,
}

export interface RetourSuppDispoUserExpe {
	data: {
		error?: string,
	}
	status?: number,
	message?: string,
}

export interface AffichageOptionsPerso {
	data: {
		options?: OptionsPersoType,
		general?: GeneralType,
		libRetour?: string,
		error?: string,
	}
	status?: number,
	message?: string,
}
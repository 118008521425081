import React, { useEffect, useState } from "react";
import chargement                     from "../../../img/chargement.svg";
import { Contact }                    from "../../containers/General/Contact";
import { ContactApi, RetourContacts } from "../../services/api/ContactApi";
import { useGeneralContext }          from "../../types/Context/GeneralContext";
import { useGHContext }               from "../../types/Context/GHContext";
import { Status_error, usePopUp }     from "../../types/Context/PopUpContext";
import { useNavigate }                from "react-router";
import { useTranslation }             from "react-i18next";

export function ContactGeneral() {
	const { general, setGeneral } = useGeneralContext();
	const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const navigate = useNavigate();
	const [contactData, setContactData] = useState(null);
	const { t } = useTranslation();
	
	// Fonction pour recharger les données
	const reloadContactData = async () => {
		const contactApi = new ContactApi();
		
		contactApi.contact().then((response: RetourContacts) => {
			setIsOnRefresh(false);
			setIsOnUpdate(false);
			if (response.data.general !== undefined) {
				setGeneral(response.data.general);
				sessionStorage.setItem("mapId", response.data.general.ville ? response.data.general.ville.map_id.toString() : null);
			}
			if (response.data.contact === undefined) {
				console.error("Erreur de chargement des données contact", response);
			} else {
				setContactData(response.data.contact);
			}
		}).catch((error) => {
			setStatus(Status_error);
			setShowPop(true);
			setMessagePopUp(error.message);
			navigate("/news");
		});
	};
	
	useEffect(() => {
		if (checkHabilitation("contact")) {
			reloadContactData().then(r => r);
		} else {
			navigate("/news");
		}
	}, [refreshKey]);
	
	return (
		<>
			{contactData === null ? (
				<div className={"chargement_page"}>
					<img src={chargement} alt="Drapeau" />
					<span>{t("Chargement...", { ns: "app" })}</span>
				</div>
			) : (<Contact contact={contactData} />)}
		</>
	);
}
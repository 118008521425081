import React                        from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { NewsType }                 from "../../types/components/General/News.type";
import ChangelogList                from "../General/ChangelogList";
import { formatInTimeZone }         from "date-fns-tz";
import { GeneralType }              from "../../types/components/Generality/General.type";
import HTMLParser                   from "html-react-parser";
import { useTranslation }           from "react-i18next";
import { ContentsVersionDTO }       from "../../types/models/contentsVersion.dto";

interface NewsProps {
	news: NewsType;
	general: GeneralType;
}

export function News({ news, general }: NewsProps) {
	const { newsData } = news;
	const { t } = useTranslation();
	
	const renderNewsEntries = () => {
		return Object.values(newsData).map((news) => {
			
			const contents = (news.version === null) ? [] : Object.values(news.version.contents).sort((a: ContentsVersionDTO, b: ContentsVersionDTO) => {
				
				if (a.type_content === b.type_content) {
					return a.id_relatif > b.id_relatif ? 1 : -1;
				}
				
				return a.type_content > b.type_content ? 1 : -1;
			});
			
			return <ListGroupItem key={news.id}>
				<div className="blocNews fondWhite02">
					<h2>{t(news.titre, { ns: "version" })}</h2>
					<div className="contenuNews">
						{news.version !== null && <React.Fragment>
							{t("Bonjour à tous,", { ns: "version" })}<br />
							{t("Le site a été mis à jour avec les modifications suivantes :", { ns: "version" })}<br />
							<ChangelogList contents={contents} />
							{t("À très bientôt et bon jeu !", { ns: "version" })}
						</React.Fragment>}
						{news.version === null && <React.Fragment>{HTMLParser(t(news.content, { ns: "version" }))}</React.Fragment>}
					</div>
					<div className="lignAutheurDateNews">
						<p className="authorNews">{HTMLParser(t("Par <em>{auteur}</em>, le {dateAjout}", { ns: "version" }).replace("{auteur}", news.auteur.pseudo).replace("{dateAjout}",
							formatInTimeZone(new Date(Date.parse(news.date_ajout)), general.fuseau, t("dd/MM/yyyy à H:mm", { ns: "app" }))))}</p>
						{news.date_modif !== null && <p className="dateModifNews"><small><em>{t("Modifiée le {dateModif}", { ns: "version" }).replace("{dateModif}",
							formatInTimeZone(new Date(Date.parse(news.date_modif)), general.fuseau, t("dd/MM/yyyy à H:mm", { ns: "app" })))}</em></small></p>}
					</div>
				</div>
			</ListGroupItem>;
		});
	};
	
	return <div>
		<ListGroup>{renderNewsEntries()}</ListGroup>
	</div>;
}
import React      from "react";
import svg_divers from "../../../img/sprite_divers.svg";

export default function SvgDivers(props: { icone: string, classDivers?: string }) {
	
	let classDivers = "icon_divers";
	if (props.classDivers !== undefined) {
		classDivers = props.classDivers;
	}
	
	return <svg className={classDivers}>
		<use xlinkHref={svg_divers + "#" + props.icone} />
	</svg>;
}
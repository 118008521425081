import i18n                 from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi              from "i18next-http-backend";

i18n.use(HttpApi) // Charger les traductions à partir de fichiers JSON
	//.use(LanguageDetector) // Détecter la langue du navigateur
	//.use(i18nextPlugin)
	.use(initReactI18next) // Passer instance à react-i18next.
	.init({
		supportedLngs: ["en", "fr", "de", "es"], // Langues supportées
		fallbackLng  : "en", // Langue par défaut si aucune langue n'est détectée
		debug        : false,
		load         : "all",
		preload      : ["en", "fr", "de", "es"],
		interpolation: {
			escapeValue: false, // React se charge de l'échappement des valeurs par défaut
		},
		backend      : {
			loadPath: "/translations/{{lng}}/{{ns}}.json", // Chemin vers les fichiers de traduction
		},
		ns           : ["ame", "app", "bats", "changelog", "chantiers", "ency", "game", "hotel", "items", "jump", "jumpEvent", "outils", "perso", "ruine", "ruineGame", "security", "service", "stats", "validators", "version", "ville", "villes"], // Liste des namespaces
		defaultNS    : "app", // Namespace par défaut
	});

export const changeLanguage = (lng) => {
	i18n.changeLanguage(lng);
};

export default i18n;
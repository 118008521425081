import * as React                                                                                                                  from "react";
import { useEffect, useState }                                                                                                     from "react";
import { Create, Datagrid, Edit, List, NumberField, NumberInput, SelectInput, SimpleForm, TextField, useDelete, useRecordContext } from "react-admin";
import { Link, useParams }                                                                                                         from "react-router";
import { Button }                                                                                                                  from "@mui/material";
import { BiEditAlt }                                                                                                               from "react-icons/bi";
import { BsDatabaseAdd, BsTrash }                                                                                                  from "react-icons/bs";
import axios                                                                                                                       from "axios";
import { ChantierPrototypeDTO }                                                                                                    from "../../../types/models/chantierPrototype.dto";

const EditDechargeButton = () => {
	const decharge = useRecordContext();
	return (
		<Button
			component={Link}
			to={`/admin/chantier/decharge/${decharge?.id}`}
			color="primary"
		>
			<BiEditAlt />
			Editer
		</Button>
	);
};

const DeletedechargeButton = () => {
	const record = useRecordContext();
	const [deleteOne, { isPending }] = useDelete(
		"chantier_decharge",
		{ id: record.id, previousData: record },
	);
	const handleClick = () => {
		deleteOne().then(r => r);
	};
	
	return (
		<Button
			onClick={handleClick}
			disabled={isPending}
			color="error"
		>
			<BsTrash />
			Supprimer
		</Button>
	);
};

const AddDechargeButton = () => (
	<Button
		component={Link}
		to="/admin/chantier/decharge/create"
		color="primary"
		startIcon={<BsDatabaseAdd />}
	>
		Ajouter une décharge
	</Button>
);


export const DechargeList = (props) => {
	
	
	const PostTitle = () => {
		return <span>Liste des décharges</span>;
	};
	
	return <List {...props} title={<PostTitle />} resource="chantier_decharge">
		<Datagrid>
			<TextField source="id" />
			<TextField source={"chantier.nom"} />
			<EditDechargeButton />
			<DeletedechargeButton />
		</Datagrid>
		<AddDechargeButton />
	</List>;
};


export const DechargeEdit = (props) => {
	
	const { id } = useParams();
	const [chantiers, setChantiers] = useState([]);
	const [load, setLoad] = useState(false);
	
	
	const PostTitle = () => {
		const record = useRecordContext();
		return <span>Edition décharge {record ? `"${record.chantier.nom}"` : ""}</span>;
	};
	
	const fetchChantiers = async () => {
		try {
			const response = await axios.get("/rest/v1/admin/chantier");
			const chantierList = response.data.sort((a: ChantierPrototypeDTO, b: ChantierPrototypeDTO) => a.nom.localeCompare(b.nom));
			setChantiers(Object.values(chantierList));
		} catch (error) {
			console.error("Error fetching heros:", error);
		}
	};
	useEffect(() => {
		fetchChantiers().then(r => r);
		setLoad(true);
	}, []);
	
	return (load && <Edit {...props} title={<PostTitle />} resource={"chantier_decharge"} id={id} redirect={"admin/chantier/decharge"}>
		<SimpleForm>
			<NumberField source={"id"} label={"ID"} />
			{chantiers.length > 0 && <SelectInput
				source="chantier.id"
				label="Chantier"
				choices={chantiers}
				optionText="nom"
				optionValue="id"
				name={"id"} />}
		</SimpleForm>
	</Edit>);
};

export const DechargeCreate = (props) => {
	const [chantiers, setChantiers] = useState([]);
	const [load, setLoad] = useState(false);
	
	const fetchChantiers = async () => {
		try {
			const response = await axios.get("/rest/v1/admin/chantier");
			const chantierList = response.data.sort((a: ChantierPrototypeDTO, b: ChantierPrototypeDTO) => a.nom.localeCompare(b.nom));
			setChantiers(Object.values(chantierList));
		} catch (error) {
			console.error("Error fetching heros:", error);
		}
	};
	useEffect(() => {
		fetchChantiers().then(r => r);
		setLoad(true);
	}, []);
	
	return (load && <Create {...props} title="Créer une nouvelle catégorie" resource={"chantier_decharge"} redirect={"/admin/chantier/decharge/"}>
		<SimpleForm>
			<NumberInput name={"id"} label={"ID"} source={"id"} />
			{chantiers.length > 0 && <SelectInput
				source="chantier.id"
				label="Chantier"
				choices={chantiers}
				optionText="nom"
				optionValue="id"
			/>}
		</SimpleForm>
	</Create>);
};
import React          from "react";
import { components } from "react-select";

const { SingleValue, Option } = components;


export default function OutilsVeille() {
	
	
	return <div></div>;
	
}
import React                  from "react";
import { HerosSkillLevelDTO } from "../../types/models/herosSkillLevel.dto";
import { useTranslation }     from "react-i18next";
import TooltipGH              from "../utils/TooltipGH";
import { ThemeUserDTO }       from "../../types/models/themeUser.dto";
import { useColor }           from "../../services/hook/useColor";

// Types des props du composant
interface CompetenceBadgeProps {
	skillLevel: HerosSkillLevelDTO;
	personnalisation: ThemeUserDTO;
}

const CompetenceBadge = ({ skillLevel, personnalisation }: CompetenceBadgeProps) => {
	const { t } = useTranslation();
	const { getColorContrast } = useColor();
	
	// Map des informations de chaque niveau
	const levels: Record<number, string> = {
		0: personnalisation.user_perso_couleur.couleur_non_heros,
		1: personnalisation.user_perso_couleur.couleur_debutant,
		2: personnalisation.user_perso_couleur.couleur_apprenti,
		3: personnalisation.user_perso_couleur.couleur_expert,
		4: personnalisation.user_perso_couleur.couleur_elite,
	};
	
	const couleur = levels[skillLevel.lvl];
	
	const tooltipText = () => {
		return <>
			<h4 style={{ margin: "0 0 5px" }}>{t(skillLevel.heros_skill_type.name, { ns: "game" })} - {t(skillLevel.name, { ns: "game" })}</h4>
			<ul style={{ paddingLeft: "20px", margin: "10px 0 0" }}>
				{skillLevel.pouvoir.map((power, index) => (
					<li key={index}>{t(power.text, { ns: "game", value: power.value, value2: power.value2 })}</li>
				))}
			</ul>
		</>;
	};
	
	
	return (
		<TooltipGH>
			<div
				className="competence-badge"
				style={{ backgroundColor: couleur }}
			>
				<span style={{ color: getColorContrast(couleur, personnalisation.primary_font_color) }}>{t(skillLevel.heros_skill_type.name, { ns: "game" }).charAt(0).toUpperCase()}<sub>{skillLevel.lvl}</sub></span>
			</div>
			{tooltipText()}
		</TooltipGH>
	);
};

export default CompetenceBadge;

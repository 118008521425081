import React from "react";
import svg   from "../../../img/sprite.svg";

export default function SvgIcone(props: { icone: string, classIcone?: string, style?: React.CSSProperties }) {
	
	let classIcone = "itemHordes";
	if (props.classIcone !== undefined) {
		classIcone = props.classIcone;
	}
	
	return <svg className={classIcone} style={props.style}>
		<use href={svg + "#" + props.icone} />
	</svg>;
}
import React, { useEffect, useState } from "react";
import TriangleSort                   from "../../../components/generality/ComposantGeneral/TriangleSort";
import { ItemPrototypeDTO }           from "../../../types/models/itemPrototype.dto";
import SvgIcone                       from "../../../components/generality/SvgIcone";
import { EncyclopedieObjetType }      from "../../../types/components/Encyclopedie/EncyclopedieObjet.type";
import { useTranslation }             from "react-i18next";
import CustomSwitchToggle             from "../../../components/generality/CustomSwitchToggle";
import { useNormalizedSearch }        from "../../../services/hook/useNormalizedSearch";
import TooltipGH                      from "../../../components/utils/TooltipGH";
import TranslationsDisplay            from "../../../components/utils/TranslationsDisplay";


const calculDefTotal = (items: ItemPrototypeDTO, dechHumi: boolean, lvlDech: number, val_def: number[]): number => {
	let def = 1;
	if (dechHumi) {
		def += 1;
	}
	if ((items.decharge?.id === 4 || items.decharge?.id === 5) && lvlDech >= 1) {
		def += val_def[(items.decharge?.id ?? 0).toString()] ?? 0;
	}
	if ((items.decharge?.id === 6 || items.decharge?.id === 3) && lvlDech >= 2) {
		def += val_def[(items.decharge?.id ?? 0).toString()] ?? 0;
	}
	if ((items.decharge?.id === 1 || items.decharge?.id === 2) && lvlDech >= 3) {
		def += val_def[(items.decharge?.id ?? 0).toString()] ?? 0;
	}
	return def;
};

export default function ListDecharges({ objets, search_objet, listObjetsPrototype, isTrad, lng }: { objets: EncyclopedieObjetType, search_objet: string, listObjetsPrototype: ItemPrototypeDTO[], isTrad: boolean, lng: string }) {
	const { t } = useTranslation();
	const [listDecharges, setListDecharges] = useState(objets.listDecharges);
	const [sortedColumnD, setSortedColumnD] = useState("");
	const [sortOrderD, setSortOrderD] = useState("");
	const [dechHumi, setDechHumi] = useState(false);
	const [lvlDech, setLvlDech] = useState(0);
	const { normalizeSearch } = useNormalizedSearch();
	
	useEffect(() => {
		setListDecharges(objets.listDecharges);
	}, [objets]);
	
	const filterLists = (listObjetsPrototype: ItemPrototypeDTO[]) => {
		const filteredDecharges = Object.values(objets.listDecharges).filter(
			(decharge) => normalizeSearch(search_objet.trim(), t(listObjetsPrototype[decharge.id].nom, { ns: "items" })),
		);
		setListDecharges(filteredDecharges);
	};
	
	
	useEffect(() => {
		filterLists(listObjetsPrototype);
	}, [search_objet]);
	
	const getSortIndicatorClassDecharges = (column: string) => {
		if (column === sortedColumnD) {
			return sortOrderD === "asc" ? "asc" : "desc";
		} else {
			return "default"; // Retourne une chaîne vide si la colonne n'est pas triée
		}
	};
	const handleSortDataDecharges = (column: string) => {
		// Si la colonne cliquée est la même que la colonne triée précédemment,
		// on inverse l'ordre de tri.
		const order = column === sortedColumnD && sortOrderD === "asc" ? "desc" : (sortOrderD === "desc" ? "default" : "asc");
		
		if (order === "default") {
			column = "";
		}
		
		// On met à jour l'état avec la colonne actuellement triée et l'ordre de tri.
		setSortedColumnD(column);
		setSortOrderD(order);
	};
	
	
	const sortedListsDecharges = (listObjetsPrototype: ItemPrototypeDTO[]) => {
		const sortFunctionsDecharges = {
			"armes"   : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => (a.decharge?.id ?? 0) === 4 ? 1 : -1,
			"appat"   : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => (a.decharge?.id ?? 0) === 5 ? 1 : -1,
			"def"     : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => (a.decharge?.id ?? 0) === 6 ? 1 : -1,
			"anim"    : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => (a.decharge?.id ?? 0) === 3 ? 1 : -1,
			"bois"    : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => (a.decharge?.id ?? 0) === 1 ? 1 : -1,
			"feraille": (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => (a.decharge?.id ?? 0) === 2 ? 1 : -1,
			"point"   : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => calculDefTotal(a, dechHumi, lvlDech, objets.val_def) - calculDefTotal(b, dechHumi, lvlDech, objets.val_def),
			"default" : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => t(listObjetsPrototype[a.id]?.nom ?? "", { ns: "items" }).localeCompare(t(listObjetsPrototype[b.id]?.nom ?? "", { ns: "items" })),
		};
		const sortFunction = sortFunctionsDecharges[sortedColumnD] || sortFunctionsDecharges["default"];
		return Object.values(listDecharges).sort((a, b) => {
			const result = sortFunction(a, b);
			return sortOrderD === "asc" ? -result : result;
		});
	};
	const dechargesTri: ItemPrototypeDTO[] = sortedListsDecharges(objets.listObjets);
	
	
	return (
		<div id="ency_decharges">
			<div className={"container-tabs"}>
				<div className={"d-flex justify-content-center align-items-center gap-2 mb-1"}>
					<div className={"d-flex justify-content-center align-items-center gap-2"}>
						<span>{t("Décharge humidifiée", { ns: "chantiers" })} {t("construite", { ns: "ency" })}</span>
						<CustomSwitchToggle checked={dechHumi} size={"sm"} onstyle="success" offstyle={"danger"}
											onlabel={t("Oui", { ns: "app" })} offlabel={t("Non", { ns: "app" })}
											onChange={() => setDechHumi(!dechHumi)} />
					</div>
					<div className={"d-flex justify-content-center align-items-center gap-2"}>
						<span>{t("Niveau de vote décharge publique :", { ns: "ency" })}</span>
						<select value={lvlDech} onChange={(e) => setLvlDech(parseInt(e.target.value))}>
							<option value={0}>{t("Aucun", { ns: "ency" })}</option>
							<option value={1}>{t("Niveau 1", { ns: "ency" })}</option>
							<option value={2}>{t("Niveau 2", { ns: "ency" })}</option>
							<option value={3}>{t("Niveau 3", { ns: "ency" })}</option>
						</select>
					</div>
				</div>
				<table>
					<thead>
					<tr>
						<th className="tab_ency_items_icone" rowSpan={3}>{t("Image", { ns: "ency" })}</th>
						<th className="tab_ency_decharges_nom" rowSpan={3}>{t("Nom", { ns: "ency" })}</th>
						<th className="tab_ency_decharges_def" rowSpan={3}>
							<div className={"entete_tri"} onClick={() => handleSortDataDecharges("point")}>
								<div id={"entete_ency_veilles_def"}>{t("Défense", { ns: "ency" })}</div>
								<TriangleSort direction={getSortIndicatorClassDecharges("point")} />
							</div>
						</th>
						<th colSpan={6}>{t("Modifié par le vote de la décharge", { ns: "ency" })}</th>
					</tr>
					<tr>
						<th colSpan={2}>{t("Niveau 1", { ns: "ency" })}</th>
						<th colSpan={2}>{t("Niveau 2", { ns: "ency" })}</th>
						<th colSpan={2}>{t("Niveau 3", { ns: "ency" })}</th>
					</tr>
					<tr>
						<th className="tab_ency_decharges_arme">
							<div className={"entete_tri"} onClick={() => handleSortDataDecharges("armes")}>
								<div id={"entete_ency_decharges_arme"} className={"d-flex justify-content-center align-items-center gap-1 mx-1"}><span>{t("Décharge piégée", { ns: "chantiers" })}</span>
									<TooltipGH>
                                        <span className={"infoBulle"}>
                                            <i className={"fa fa-circle-info"}></i>
                                        </span>
										<span className={"info"}>+5</span>
									</TooltipGH>
								</div>
								<TriangleSort direction={getSortIndicatorClassDecharges("armes")} />
							</div>
						</th>
						<th className="tab_ency_decharges_appat">
							<div className={"entete_tri"} onClick={() => handleSortDataDecharges("appat")}>
								<div id={"entete_ency_decharges_appat"} className={"d-flex justify-content-center align-items-center gap-1 mx-1"}>
									<span>{t("Appâts odorants", { ns: "chantiers" })}</span>
									<TooltipGH>
                                        <span className={"infoBulle"}>
                                            <i className={"fa fa-circle-info"}></i>
                                        </span>
										<span className={"info"}>+3</span>
									</TooltipGH>
								</div>
								<TriangleSort direction={getSortIndicatorClassDecharges("appat")} />
							</div>
						</th>
						<th className="tab_ency_decharges_od">
							<div className={"entete_tri"} onClick={() => handleSortDataDecharges("def")}>
								<div id={"entete_ency_decharges_od"} className={"d-flex justify-content-center align-items-center gap-1 mx-1"}>
									<span>{t("Décharge blindée", { ns: "chantiers" })}</span>
									<TooltipGH>
                                        <span className={"infoBulle"}>
                                            <i className={"fa fa-circle-info"}></i>
                                        </span>
										<span className={"info"}>+2</span>
									</TooltipGH>
								</div>
								<TriangleSort direction={getSortIndicatorClassDecharges("def")} />
							</div>
						</th>
						<th className="tab_ency_decharges_anim">
							<div className={"entete_tri"} onClick={() => handleSortDataDecharges("anim")}>
								<div id={"entete_ency_decharges_anim"} className={"d-flex justify-content-center align-items-center gap-1 mx-1"}>
									<span>{t("Enclos", { ns: "chantiers" })}</span>
									<TooltipGH>
                                        <span className={"infoBulle"}>
                                            <i className={"fa fa-circle-info"}></i>
                                        </span>
										<span className={"info"}>+6</span>
									</TooltipGH>
								</div>
								<TriangleSort direction={getSortIndicatorClassDecharges("anim")} />
							</div>
						</th>
						<th className="tab_ency_decharges_bois">
							<div className={"entete_tri"} onClick={() => handleSortDataDecharges("bois")}>
								<div id={"entete_ency_decharges_bois"} className={"d-flex justify-content-center align-items-center gap-1 mx-1"}>
									<span>{t("Déchardes de bois", { ns: "chantiers" })}</span>
									<TooltipGH>
                                        <span className={"infoBulle"}>
                                            <i className={"fa fa-circle-info"}></i>
                                        </span>
										<span className={"info"}>+1</span>
									</TooltipGH>
								</div>
								<TriangleSort direction={getSortIndicatorClassDecharges("bois")} />
							</div>
						</th>
						<th className="tab_ency_decharges_feraille">
							<div className={"entete_tri"} onClick={() => handleSortDataDecharges("feraille")}>
								<div id={"entete_ency_decharges_feraille"} className={"d-flex justify-content-center align-items-center gap-1 mx-1"}>
									<span>{t("Ferraillerie", { ns: "chantiers" })}</span>
									<TooltipGH>
                                        <span className={"infoBulle"}>
                                            <i className={"fa fa-circle-info"}></i>
                                        </span>
										<span className={"info"}>+1</span>
									</TooltipGH>
								</div>
								<TriangleSort direction={getSortIndicatorClassDecharges("feraille")} />
							</div>
						</th>
					</tr>
					</thead>
					<tbody>
					{dechargesTri.map((item) => {
						
						return <tr className={"ligne_ency_decharges"} key={"veilles_" + item.id}>
							<td className={"tab_ency_items_icone"}>
								<div><SvgIcone icone={listObjetsPrototype[item.id].icon} /></div>
							</td>
							<td className="tab_ency_decharges_nom">{isTrad ? <TranslationsDisplay translationKey={listObjetsPrototype[item.id].nom ?? ""} namespace={"items"} userLang={lng} /> : t(listObjetsPrototype[item.id].nom, { ns: "items" })}</td>
							<td className="tab_ency_decharges_def">{calculDefTotal(item, dechHumi, lvlDech, objets.val_def)}</td>
							<td className="tab_ency_decharges_arme">{(item.decharge?.id ?? 0) === 4 ? <i className="fas fa-check color-check"></i> : ""}</td>
							<td className="tab_ency_decharges_appat">{(item.decharge?.id ?? 0) === 5 ? <i className="fas fa-check color-check"></i> : ""}</td>
							<td className="tab_ency_decharges_od">{(item.decharge?.id ?? 0) === 6 ? <i className="fas fa-check color-check"></i> : ""}</td>
							<td className="tab_ency_decharges_anim">{(item.decharge?.id ?? 0) === 3 ? <i className="fas fa-check color-check"></i> : ""}</td>
							<td className="tab_ency_decharges_bois">{(item.decharge?.id ?? 0) === 1 ? <i className="fas fa-check color-check"></i> : ""}</td>
							<td className="tab_ency_decharges_feraille">{(item.decharge?.id ?? 0) === 2 ? <i className="fas fa-check color-check"></i> : ""}</td>
						</tr>;
					})}
					</tbody>
				</table>
			</div>
		</div>
	);
}





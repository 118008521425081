import React from "react";

const TriangleSort = ({ direction }: { direction: string }) => {
	return (
		<div className={`triangle ${direction}`}>
			<div className="triangle-up">
				<i className="fa-solid fa-caret-up fa-lg"></i>
			</div>
			<div className="triangle-down">
				<i className="fa-solid fa-caret-down fa-lg"></i>
			</div>
		</div>
	);
};

export default TriangleSort;

import React, { useEffect, useState } from "react";
import { GestionJumpApi }             from "../../services/api/GestionJumpApi";
import { useTranslation }             from "react-i18next";
import TooltipGH                      from "../../components/utils/TooltipGH";

export default function BanniereJump({ typeCreation, urlImage, onRetour, disabled = false }: {
	typeCreation: string, urlImage: string | null,
	onRetour: (urlBanniere: string, isError: boolean, showMod: boolean, messagePop: string) => void,
	disabled?: boolean
}) {
	const { t } = useTranslation();
	
	const [urlImageProps, setUrlImageProps] = useState(urlImage);
	
	useEffect(() => {
		setUrlImageProps(urlImage);
	}, [urlImage]);
	
	const handleImageChange = (e) => {
		if (e.target.files.length > 1) {
			onRetour(null, true, true, t("Veuillez sélectionner un seul fichier . ", { ns: "jumpEvent" }));
			return;
		}
		
		/** @var file File */
		const file = e.target.files[0];
		if (file.size >= 8192000) {
			onRetour(null, true, true, t("Ce fichier est trop volumineux . ", { ns: "jumpEvent" }));
			return;
		}
		
		const type_info = file.type.split("/", 2);
		if (type_info.length < 2 || type_info[0] !== "image") {
			onRetour(null, true, true, t("Ce format de fichier n'est pas pris en charge.", { ns: "jumpEvent" }));
			return;
		}
		
		const reader = new FileReader();
		
		reader.onload = function() {
			const banniereApi = new GestionJumpApi();
			banniereApi.creation_banniere({ image: reader.result, up: 1, mime: type_info[1], oldName: urlImageProps }).then((response) => {
				if (response.codeRetour === 0) {
					setUrlImageProps(response.zoneRetour.urlBanniere);
					onRetour(response.zoneRetour.urlBanniere, false, true, t("Image correctement ajouté.", { ns: "jumpEvent" }));
				} else {
					onRetour(null, true, true, response.libRetour);
				}
			});
		};
		reader.readAsDataURL(file);
	};
	const handleDelete = () => {
		const banniereApi = new GestionJumpApi();
		banniereApi.creation_banniere({ up: 0, oldName: urlImageProps }).then((response) => {
			if (response.codeRetour === 0) {
				setUrlImageProps(response.zoneRetour.urlBanniere);
				onRetour(response.zoneRetour.urlBanniere, false, true, t("Image correctement ajouté.", { ns: "jumpEvent" }));
			} else {
				onRetour(null, true, true, response.libRetour);
			}
		});
	};
	
	return <>
		<div className="banniere_jump_event">
			{(urlImageProps === null || urlImageProps === "") ? (<div id={"textRemplacant_" + typeCreation}>{t("Aucune bannière", { ns: "jumpEvent" })}</div>) :
				(<img id={"img_" + typeCreation} src={"/uploads/banniere/" + urlImageProps} alt="Banniere du jump" />)}
		</div>
		<div>
			{disabled ? null : (<>
				<input className="elementNonVisible banniere_file_select_creation" type="file" id={"banniere_file_select_" + typeCreation}
					   accept=".gif,.jpg,.jpeg,.jif,.jfif,.png,.webp,.bmp" onChange={(event) => handleImageChange(event)} disabled={disabled} />
				{(urlImageProps !== null && urlImageProps !== "") ? (
					<div className="bouton_banniere">
						<label className="btn btn-primary btn-sm" htmlFor={"banniere_file_select_" + typeCreation}>{t("Modifier la bannière", { ns: "jumpEvent" })}</label>
						<TooltipGH>
                            <span className="infoBulle">
                                <i className="fas fa-question-circle"></i>
                            </span>
							<span className="infoInscription">{t("Votre image ne doit pas faire plus de {taille}.", { ns: "jumpEvent" }).replace("{taille}", "1024Ko")}</span>
						</TooltipGH>
					</div>
				) : (
					<div className="bouton_banniere">
						<label className="btn btn-primary btn-sm" htmlFor={"banniere_file_select_" + typeCreation}>{t("Ajouter une bannière", { ns: "jumpEvent" })}</label>
						<TooltipGH>
                            <span className="infoBulle">
                                <i className="fas fa-question-circle"></i>
                            </span>
							<span className="infoInscription">{t("Votre image ne doit pas faire plus de {taille}.", { ns: "jumpEvent" }).replace("{taille}", "1024Ko")}</span>
						</TooltipGH>
					</div>
				)}
				{(urlImageProps !== null && urlImageProps !== "") && <div className="bouton_banniere">
					<button id={"banniere_del_" + typeCreation} className="banniere_del btn btn-danger btn-sm"
							onClick={() => handleDelete()}>{t("Supprimer la bannière", { ns: "jumpEvent" })}</button>
				</div>}
			</>)}
		</div>
	</>;
	
}
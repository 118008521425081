import React                    from "react";
import SvgIcone                 from "../../../components/generality/SvgIcone";
import parse                    from "html-react-parser";
import { ChantierPrototypeDTO } from "../../../types/models/chantierPrototype.dto";
import { useTranslation }       from "react-i18next";
import { UpChantierDTO }        from "../../../types/models/upChantier.dto";
import { ChantiersDTO }         from "../../../types/models/chantiers.dto";

interface ListChantiersProps {
	evoChantier: ChantierPrototypeDTO[],
	translation: string[]
	listEvosVille?: UpChantierDTO[],
	listChantiersVille?: ChantiersDTO[],
	colorEvo?: string,
}

const ListEvolutions = ({ evoChantier, translation, listEvosVille = [], listChantiersVille = [], colorEvo = null }: ListChantiersProps) => {
	const { t } = useTranslation();
	const translate: string[] = translation;
	const listEvo: ChantierPrototypeDTO[] = Object.values(evoChantier).sort((a: ChantierPrototypeDTO, b: ChantierPrototypeDTO) => {
		const aName = t(a.nom, { ns: "chantiers" }).toLowerCase();
		const bName = t(b.nom, { ns: "chantiers" }).toLowerCase();
		return aName.localeCompare(bName);
	});
	
	return <table>
		<thead>
		<tr>
			<th className="nomEvoChantier">{t("Nom Chantier", { ns: "ency" })}</th>
			<th className="effetEvoChantier">{t("Effet de base", { ns: "ency" })}</th>
			<th className="effetEvoChantier">{t("Niveau 1", { ns: "ency" })}</th>
			<th className="effetEvoChantier">{t("Niveau 2", { ns: "ency" })}</th>
			<th className="effetEvoChantier">{t("Niveau 3", { ns: "ency" })}</th>
			<th className="effetEvoChantier">{t("Niveau 4", { ns: "ency" })}</th>
			<th className="effetEvoChantier">{t("Niveau 5", { ns: "ency" })}</th>
		</tr>
		</thead>
		<tbody>
		{listEvo.map((evo: ChantierPrototypeDTO) => {
			const upVille = listEvosVille.find(up => up.chantier.id === evo.id);
			const chantierConstruit = Object.values(listChantiersVille).find(chantier => {
				return chantier.chantier.id === evo.id;
			});
			return <tr key={"ligne_chantier_" + evo.id}>
				<td className="nomEvoChantier" key={"ligne_evo_" + evo.id}>
                    <span className={"d-flex gap-1 align-items-center justify-content-center"}>
                        <span>{t(evo.nom, { ns: "chantiers" })}</span>
                        <SvgIcone icone={evo.icon} />
                    </span>
				</td>
				{Object.values(evo.level_ups).map(bonus => {
					return <td className={"effetEvoChantier"} key={"evo_chantier_" + evo.id + "_lvl_" + bonus.level} style={{ backgroundColor: ((upVille && (upVille?.lvl_actuel ?? 0) >= bonus.level) || (chantierConstruit && bonus.level === 0)) ? colorEvo : null }}>
						<div className={"d-flex gap-1 align-items-center justify-content-center"}>
							{Object.values(bonus.bonus_ups).map((up, index) => <span key={"evo_chantier_" + evo.id + "_lvl_" + bonus.level + "_bonus_" + index} className={"d-flex gap-1 align-items-center justify-content-center"}>{parse(translate[bonus.id][up.id])}</span>)}
						</div>
					</td>;
				})}
			</tr>;
		})}
		
		</tbody>
	</table>;
};

export default ListEvolutions;
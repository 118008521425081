import { Fetch }                     from "../../components/generality/Fetch";
import { EncyclopedieBatimentsType } from "../../types/components/Encyclopedie/EncyclopedieBatiments.type";
import { EncyclopedieChantiersType } from "../../types/components/Encyclopedie/EncyclopedieChantiers.type";
import { EncyclopedieCitoyensType }  from "../../types/components/Encyclopedie/EncyclopedieCitoyens.type";
import { EncyclopedieObjetType }     from "../../types/components/Encyclopedie/EncyclopedieObjet.type";
import { GeneralType }               from "../../types/components/Generality/General.type";
import { EncyclopedieVilleType }     from "../../types/components/Encyclopedie/EncyclopedieVille.type";
import { FetchGH }                   from "../../components/generality/FetchGH";
import { BatPrototypeDTO }           from "../../types/models/batPrototype.dto";


export class EncyclopedieApi {
	
	private fetch: Fetch;
	private fetchGH: FetchGH;
	
	constructor(mapId?: number) {
		this.fetch = new Fetch("encyclopedie", mapId ?? 0);
		this.fetchGH = new FetchGH("encyclopedie", mapId ?? 0);
	}
	
	public main_chantiers(): Promise<AffichageEncyChantiers> {
		return this.fetch.from("/chantiers").request().get();
	}
	
	public main_citoyens(): Promise<AffichageEncyCitoyens> {
		return this.fetch.from("/citoyens").request().get();
	}
	
	public main_bats(): Promise<AffichageEncyBatiments> {
		return this.fetch.from("/batiments").request().get();
	}
	
	public main_objets(): Promise<AffichageEncyObjets> {
		return this.fetch.from("/objets").request().get();
	}
	
	public main_villes(): Promise<AffichageEncyVilles> {
		return this.fetchGH.get("/villes");
	}
	
	public info_bat(id: number): Promise<AffichageEncyBatimentsInfo> {
		return this.fetchGH.get("/batiments/" + id);
	}
}

export type AffichageEncyChantiers = {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		chantiers: EncyclopedieChantiersType
		general: GeneralType,
	},
}

export type AffichageEncyCitoyens = {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		citoyens: EncyclopedieCitoyensType
		general: GeneralType,
	},
}

export type AffichageEncyBatiments = {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		batiments: EncyclopedieBatimentsType
		general: GeneralType,
	},
}

export type AffichageEncyObjets = {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		objets: EncyclopedieObjetType
		general: GeneralType,
	},
}

export type AffichageEncyVilles = {
	data: {
		villes?: EncyclopedieVilleType,
		general?: GeneralType,
		error?: string,
	},
	status?: number,
	message?: string,
}
export type AffichageEncyBatimentsInfo = {
	data: {
		bat?: BatPrototypeDTO,
		error?: string,
	},
	status?: number,
	message?: string,
}
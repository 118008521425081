import React, { useEffect, useState }                    from "react";
import { PlansChantierType }                             from "../../../types/components/Hotel/PlansChantier.type";
import SvgIcone                                          from "../../../components/generality/SvgIcone";
import { PlansChantierApi }                              from "../../../services/api/PlansChantierApi";
import Cookies                                           from "js-cookie";
import ListChantiers                                     from "../../Encyclopedie/Chantiers/ListChantiers";
import Tab                                               from "react-bootstrap/Tab";
import Tabs                                              from "react-bootstrap/Tabs";
import LignePlanChantier                                 from "./LignePlanChantier";
import { Status_error, Status_success, usePopUp }        from "../../../types/Context/PopUpContext";
import HTMLParser                                        from "html-react-parser";
import { ChantierPrototypeDTO }                          from "../../../types/models/chantierPrototype.dto";
import { useTranslation }                                from "react-i18next";
import { formatInTimeZone }                              from "date-fns-tz";
import { useGeneralContext }                             from "../../../types/Context/GeneralContext";
import { useDispatch, useSelector }                      from "react-redux";
import { AppDispatch, RootState }                        from "../../../store/store";
import { changePlanObtenu, resetPlansObtenus, setPlans } from "../../../store/hotel/plansChantierSlice";
import ListEvolutions                                    from "../../Encyclopedie/Chantiers/ListEvolutions";
import chroma                                            from "chroma-js";


interface PlansChantiersProps {
	plansChantier: PlansChantierType;
}

export default function PlansChantiers({ plansChantier }: PlansChantiersProps) {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const selectOngletCookie = Cookies.get("selectedOngletPlans");
	const { general } = useGeneralContext();
	const listPlansObtenu = useSelector((state: RootState) => state.plansChantier.plansObtenus);
	const compteurPlans = useSelector((state: RootState) => state.plansChantier.compteurPlans);
	const listePlans = useSelector((state: RootState) => state.plansChantier.listePlans);
	const [panneauRuineOuvert, setPanneauRuineOuvert] = useState(false);
	const [ville, setVille] = useState(plansChantier.ville);
	const [idxSelected, setIdxSelected] = useState(selectOngletCookie ?? "plans");
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const [modified, setModified] = useState(false);
	
	useEffect(() => {
		dispatch(resetPlansObtenus({ listChantier: Object.values(plansChantier.listPlansVille) }));
		dispatch(setPlans({ plans: Object.values(plansChantier.listPlans) }));
		setVille(plansChantier.ville);
	}, [plansChantier]);
	
	const handleChangePlansObtenu = (chantier: ChantierPrototypeDTO) => {
		dispatch(changePlanObtenu({ chantier: chantier }));
		setModified(true);
	};
	
	const handleBoutonAnnuler = () => {
		dispatch(resetPlansObtenus({ listChantier: Object.values(plansChantier.listPlansVille) }));
		setModified(false);
	};
	
	const handleBoutonSauver = () => {
		const planApi = new PlansChantierApi();
		
		planApi.sauvegarde({
			mapId    : plansChantier.ville.map_id,
			userId   : plansChantier.user.id,
			listPlans: listPlansObtenu,
		}).then((response) => {
			if (response.codeRetour === 0) {
				setVille(response.zoneRetour.ville);
				setStatus(Status_success);
				setShowPop(true);
				setMessagePopUp(response.libRetour);
				setTimeout(() => {
					setShowPop(false);
					setMessagePopUp("");
					setModified(false);
				}, 1500);
			} else {
				setStatus(Status_error);
				setShowPop(true);
				setMessagePopUp(response.libRetour);
			}
		});
	};
	
	const handleTabChange = (eventKey) => {
		setIdxSelected(eventKey);
		const expirationDate = new Date();
		expirationDate.setDate(expirationDate.getDate() + 365);
		Cookies.set("selectedOngletPlans", eventKey, {
			expires: expirationDate,
			path   : "/",
			secure : true,
		});
	};
	
	const planTypes = [
		{ key: "Commun", type: "c", plans: listePlans.plansCommun },
		{ key: "Inhab", type: "u", plans: listePlans.plansInhab },
		{ key: "Rare", type: "r", plans: listePlans.plansRare },
		{ key: "TresRare", type: "e", plans: listePlans.plansEpique },
	];
	const planTypesRuine = [
		{ key: "Inhab", type: "u" },
		{ key: "Rare", type: "r" },
		{ key: "TresRare", type: "e" },
	];
	
	const listRuines = [
		{ key: "bunker", nom: t("Bunker abandonné", { ns: "bats" }), icon: "b" },
		{ key: "hopital", nom: t("Hôpital abandonné", { ns: "bats" }), icon: "m" },
		{ key: "hotel", nom: t("Hôtel abandonné", { ns: "bats" }), icon: "h" },
	];
	
	const planIcons = [
		{ icon: "item_bplan_c", count: compteurPlans.commun, max: compteurPlans.max_commun },
		{ icon: "item_bplan_u", count: compteurPlans.inhab, max: compteurPlans.max_inhab },
		{ icon: "item_bplan_r", count: compteurPlans.rare, max: compteurPlans.max_rare },
		{ icon: "item_bplan_e", count: compteurPlans.epique, max: compteurPlans.max_epique },
	];
	
	const color = chroma(general.themeUser.succes_color).alpha(0.3).css();
	
	return <div id="corpsPlanChantier">
		<div id={"zonePlansChantierConteneur"}>
			<Tabs
				activeKey={idxSelected}
				onSelect={handleTabChange}
			>
				<Tab eventKey={"plans"} title={t("Plans de chantier", { ns: "hotel" })}>
					<div id="zonePlansChantiersPlans">
						{modified && <h2 className={"color-red"}>{t("Une modification a été effectué, pensez à sauvegarder", { ns: "hotel" })}</h2>}
						<div className="formPlansChantier fondWhite02">
							<div id="zonePlansChantier">
								{planTypes.map(({ key, type, plans }) => (
									<React.Fragment key={key}>
										<div className={`listPlans${key}`}>
											{plans.map((colChantier: ChantierPrototypeDTO[], index) => (
												<div key={`${key.toLowerCase()}_${index}`} className="d-flex flex-column">
													{colChantier.map((chantier: ChantierPrototypeDTO) => (
														<LignePlanChantier
															key={`chantier_${chantier.id}`}
															chantier={chantier}
															type={type}
															chantierObtenu={listPlansObtenu}
															onClickChantier={handleChangePlansObtenu}
														/>
													))}
												</div>
											))}
										</div>
										<hr className="hrBlack40" />
									</React.Fragment>
								))}
								<div id="formPlansFooter" className={"mb-1"}>
									<div id="boutonFormChantier">
										{modified && plansChantier.myVille && <>
											<button className={"btn btn-success btn-xs"} onClick={handleBoutonSauver}>{t("Sauvegarder", { ns: "hotel" })}</button>
											<button className={"btn btn-warning btn-xs"} onClick={handleBoutonAnnuler}>{t("Annuler modification", { ns: "hotel" })}</button>
										</>}
									</div>
									<div id="compteurPlans" className="fondWhite06 me-1">
										{ville.plans_chantier_date_maj !== null &&
											<span>{HTMLParser(t("Mis à jour par <strong>{userMaj}</strong> le <strong>{dateMaj}</strong>.", { ns: "hotel" }).replace("{userMaj}", ville.plans_chantier_update_by.pseudo).replace("{dateMaj}", formatInTimeZone(new Date(Date.parse(ville.plans_chantier_date_maj)), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))))}</span>}
										<React.Fragment>
											{planIcons.map(({ icon, count, max }, index) => (
												<span key={index} className={"d-flex gap-1"}>
                                                    <SvgIcone icone={icon} />
                                                    <span id={`nrbLvl_${index + 1}`}>{count}</span>/{max}
                                                </span>
											))}
										</React.Fragment>
									</div>
								</div>
							</div>
							<div id="zoneBoutonPlansRuine" onClick={() => setPanneauRuineOuvert(prevPanneau => !prevPanneau)}>
								<div>{t("Plans de chantier dans les ruines", { ns: "hotel" })}</div>
								<div id="declenchRuine">{panneauRuineOuvert ? HTMLParser(t("Cliquer pour <span>fermer</span>", { ns: "hotel" })) : HTMLParser(t("Cliquer pour <span>ouvrir</span>", { ns: "hotel" }))}</div>
							</div>
							<div id="zoneRuinePlan">
								<div id="zoneInviPlan"></div>
								{panneauRuineOuvert && <div id="listRuinePlans">
									<div id="ruineFlex">
										{Object.values(listRuines).map(({ key, nom, icon }) => {
											const nomRuine = key.charAt(0).toUpperCase() + key.slice(1);
											return <div key={nomRuine} id={`ruine${nomRuine}`}>
												<div className="titreRuine">{nom}</div>
												{Object.values(planTypesRuine).map((planType) => {
													return <div key={planType.key} className={`listPlans${planType.key}Ruine`}>
														{listePlans.plansRuine[key][`plans${planType.key}`].map((chantier: ChantierPrototypeDTO) => {
															return <LignePlanChantier
																key={`chantier_${key.charAt(0)}_${chantier.id}`}
																chantier={chantier}
																chantierObtenu={listPlansObtenu}
																type={planType.type}
																sous_type={icon}
															/>;
														})}
													</div>;
												})}
											</div>;
										})}
									</div>
								</div>}
							</div>
						</div>
					</div>
				</Tab>
				<Tab eventKey={"chantiers"} title={t("Chantiers", { ns: "hotel" })}>
					<div id="zonePlansChantiersPlans">
						<div className="listChantierPlans fondWhite02">
							<ListChantiers listChantier={plansChantier.listChantier}
										   banque={plansChantier.banque}
										   listChantiersVille={plansChantier.listChantiersConstruit}
										   listPlansVille={listPlansObtenu}
										   listAvancement={plansChantier.listAvancement}
										   reductionAtelier={plansChantier.reductionPA}
										   villePrivee={general.ville.prived}
							/>
						</div>
					</div>
				</Tab>
				<Tab eventKey={"evo"} title={t("Evolutions", { ns: "hotel" })}>
					<div id="listEvoChantierPlans">
						<div className={"d-flex gap-1 justify-content-center align-items-center mt-2"}>
							<div style={{ backgroundColor: color, width: "30px", height: "15px" }}></div>
							<div>{t("Evolutions actuelles", { ns: "hotel" })}</div>
						</div>
						<ListEvolutions evoChantier={plansChantier.listEvoChantiers} translation={plansChantier.translateEvo} listEvosVille={plansChantier.listEvoChantiersVille} listChantiersVille={plansChantier.listChantiersConstruit} colorEvo={color} />
					</div>
				</Tab>
			</Tabs>
		</div>
	</div>;
	
}


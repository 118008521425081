import React, { useEffect }   from "react";
import { JumpListType }       from "../../../types/components/Jump/JumpList.type";
import SvgIcone               from "../../../components/generality/SvgIcone";
import { Avatar }             from "../../../components/generality/ComposantGeneral";
import { Link }               from "react-router";
import { InscriptionJumpDTO } from "../../../types/models/inscriptionJump.dto";
import { useTranslation }     from "react-i18next";
import TooltipGH              from "../../../components/utils/TooltipGH";
import Button                 from "../../../components/utils/Button";

export default function JumpList({ event, isArchMode = false }: { event: JumpListType, isArchMode?: boolean }) {
	const { t } = useTranslation();
	const [showComparatif, setShowComparatif] = React.useState(false);
	const [lienComparatif, setLienComparatif] = React.useState<string | null>(null);
	const date_jour = event.date_jour;
	const eventJump = event.event;
	const listJump = event.listInscription;
	
	const dateFinInscription = new Date(eventJump.fin_inscription_date_at);
	const dateJour = new Date(date_jour);
	
	useEffect(() => {
		// On va récupérer les map_ids des villes de chaque event pour avoir un lien de comparaison
		const mapIds = [];
		eventJump.list_jump.sort((jump_a, jump_b) => jump_a.nom.localeCompare(jump_b.nom)).map((jump) => {
			if (jump.ville !== null) {
				mapIds.push(jump?.ville?.map_id);
			}
		});
		
		if (mapIds.length > 0) {
			setShowComparatif(true);
			setLienComparatif(`/villes/comparatif?cities=${mapIds.join(",")}`);
		}
	}, []);
	
	
	return <div className="jumpCorps">
		
		<div id="banniereEvent">
			{eventJump.banniere !== null && <img src={"/uploads/banniere/" + eventJump.banniere} alt="" />}
		</div>
		{showComparatif && <div className={"d-flex justify-content-center mb-2 mt-2"}>
			<Button taille={"xs"} couleur={"primary"}><Link to={lienComparatif} style={{ textDecoration: "none", color: "inherit" }}>{t("Voir le comparatif entre les villes", { ns: "villes" })}</Link></Button>
		</div>}
		{eventJump.list_jump.map((jump) => {
			
			// On va filtrer les inscriptions pour ne garder que celles qui ne sont pas abandonnées
			const jumpInscription = Object.values(jump.inscription_jumps).filter((inscription) => {
				return inscription.statut.id !== 99;
			});
			
			return <div className="groupJump" key={"ligne_jump" + jump.id}>
				<div className="recapJumpInscription">
                    <span className="nomJumpInscription d-flex gap-1 align-items-center">
                        <span>{jump.nom}</span>
						{jump.job_specific && <span className={"d-flex gap-1 align-items-center"}><SvgIcone icone={"h_" + (jump.job[0]?.icon ?? "")} /></span>}
						<span className={"d-flex gap-1"}>
                            <span>({t("Nombre d'inscrit :", { ns: "jumpEvent" })}</span>
                            <span>{jumpInscription.length}</span>
                            <span>)</span>
                        </span>
                    </span>
					{dateFinInscription < dateJour && !isArchMode ? (
						<span className={"color-red"}>{t("Les inscriptions sont finis, il n'est plus possible de s'y inscrire !", { ns: "jumpEvent" })}</span>
					) : (
						(isArchMode) ? (listJump.includes(jump.id) ? (
							<Link to={`/event/inscription/archive/${eventJump.id}/${jump.id}`}>
								<button type={"button"}>{t("Voir son inscription", { ns: "jumpEvent" })}</button>
							</Link>
						) : null) : (
							<Link to={`/event/inscription/${eventJump.id}/${jump.id}`}>
								<button type={"button"}>{listJump.includes(jump.id) ? t("Modifier", { ns: "jumpEvent" }) : t("S'inscrire", { ns: "jumpEvent" })}</button>
							</Link>
						)
					
					)}
				</div>
				<div className="listingInscription">
					{jumpInscription.sort((a: InscriptionJumpDTO, b: InscriptionJumpDTO) => {
						return a.user.pseudo.localeCompare(b.user.pseudo);
					}).map((inscription) => {
							return <LigneJump inscription={inscription} one_metier={eventJump.one_metier}
											  key={"inscription_jump_" + jump.id + "_" + inscription.user.id} />;
						},
					)}
				</div>
			</div>;
		})}
	</div>;
	
	
}

export function LigneJump({ inscription, one_metier }: { inscription: InscriptionJumpDTO, one_metier: boolean }) {
	const { t } = useTranslation();
	
	return <div className="candidatureEvent">
		<div className="imageCandidature">
			<Avatar url={inscription.user.avatar} />
		</div>
		<div className="zonePseudoMetierStatut">
			<div className="pseudoAndMetierCandidatureEvent">
				<div className="pseudoCandaditatureEvent">{inscription.user.pseudo}</div>
				<div className="listMetierCandidatureEvent d-flex gap-1 align-items-center">{one_metier ? (
					<TooltipGH>
                        <span className="infoBulle d-flex gap-1 align-items-center">
                            <SvgIcone icone={"h_" + inscription.voeux_metier1.icon} />
                        </span>
						<span className="info">{t(inscription.voeux_metier1.nom, { ns: "game" })}</span>
					</TooltipGH>
				) : (<>
					<TooltipGH>
                        <span className="infoBulle d-flex gap-1 align-items-center">
                            <SvgIcone icone={"h_" + inscription.voeux_metier1.icon} />
                        </span>
						<span className="info">{t(inscription.voeux_metier1.nom, { ns: "game" })}</span>
					</TooltipGH>
					
					{inscription.voeux_metier2 !== null && <>
						<span>/</span>
						<TooltipGH>
                            <span className="infoBulle d-flex gap-1 align-items-center">
                                <SvgIcone icone={"h_" + inscription.voeux_metier2.icon} />
                            </span>
							<span className="info">{t(inscription.voeux_metier2.nom, { ns: "game" })}</span>
						</TooltipGH>

					</>}
					{inscription.voeux_metier3 !== null && <>
						<span>/</span>
						<TooltipGH>
                            <span className="infoBulle d-flex gap-1 align-items-center">
                                <SvgIcone icone={"h_" + inscription.voeux_metier3.icon} />
                            </span>
							<span className="info">{t(inscription.voeux_metier3.nom, { ns: "game" })}</span>
						</TooltipGH>
					</>}
				</>)}
				</div>
			</div>
			<div className="statutCandidatureEvent">{t(inscription.statut.nom, { ns: "jump" })}</div>
		</div>
	</div>;
}
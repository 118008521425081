import { ActionReducerMapBuilder, createSlice, PayloadAction }                                                                                                       from "@reduxjs/toolkit";
import { Campeur, CampeurUpdate, CampingState, createCampeurAnonyme, createCampeurIdentifie, createInitialInfoCase, InfoCase, isCampeurAnonyme, isCampeurIdentifie } from "../../types/components/Outils/Camping.type";
import { BatPrototypeDTO }                                                                                                                                           from "../../types/models/batPrototype.dto";
import { JobPrototypeDTO }                                                                                                                                           from "../../types/models/jobPrototype.dto";
import { CitoyensDTO }                                                                                                                                               from "../../types/models/citoyens.dto";
import { v4 as uuid }                                                                                                                                                from "uuid";
import { campingCalculator }                                                                                                                                         from "../../services/services/outils/campingCalculator";

const initialState: CampingState = {
	mode    : "anonyme",
	infoCase: createInitialInfoCase("anonyme", [], [], []),
	campeurs: [],
	listBat : [],
};

// Définition des types spécifiques pour les payloads des actions
type InitializeCampingPayload = {
	mode: "anonyme" | "identifie";
	infoCase: InfoCase;
	listBat: BatPrototypeDTO[];
};

type AddCamperActionPayload = {
	type: "anonyme";
	job: JobPrototypeDTO;
} | {
	type: "identifie";
	citoyen: CitoyensDTO;
};

const calculateAllChances = (campeurs: Campeur[], infoCase: InfoCase): Campeur[] => {
	return campeurs.map((campeur, index) => ({
		...campeur,
		chance_camping: campingCalculator.calculateChances(infoCase, campeur, index + 1),
	}));
};

export const campingSlice = createSlice({
	name: "camping",
	initialState,
	extraReducers<State>(builder: ActionReducerMapBuilder<State>): void {
	},
	reducerPath: undefined,
	reducers   : {
		initializeCamping: (state, action: PayloadAction<InitializeCampingPayload>) => {
			state.listBat = action.payload.listBat;
			state.mode = action.payload.mode;
			state.infoCase = action.payload.infoCase;
			const newCamper = action.payload.mode === "anonyme" ? createCampeurAnonyme(uuid(), state.infoCase.listJob[0]) : createCampeurIdentifie(uuid(), state.infoCase.citoyens[0]);
			state.campeurs = calculateAllChances([...state.campeurs, newCamper], state.infoCase);
		},
		addCamper        : (state, action: PayloadAction<{ type: "anonyme" | "identifie", job?: JobPrototypeDTO, citoyen?: CitoyensDTO }>) => {
			const newCamper = action.payload.type === "anonyme" ? createCampeurAnonyme(uuid(), action.payload.job) : createCampeurIdentifie(uuid(), action.payload.citoyen);
			state.campeurs = calculateAllChances([...state.campeurs, newCamper], state.infoCase);
		},
		deleteCamper     : (state, action: PayloadAction<string>) => {
			state.campeurs = state.campeurs.filter(campeur => campeur.id !== action.payload);
		},
		
		updateCamper: (state, action: PayloadAction<{ id: string; updates: CampeurUpdate }>) => {
			const { id, updates } = action.payload;
			const index = state.campeurs.findIndex(c => c.id === id);
			
			if (index !== -1) {
				const campeur = state.campeurs[index];
				
				if (isCampeurAnonyme(campeur) && "job" in updates) {
					state.campeurs[index] = {
						...campeur,
						...updates,
					};
				} else if (isCampeurIdentifie(campeur) && "citoyen" in updates) {
					state.campeurs[index] = {
						...campeur,
						...updates,
					};
				} else {
					// Pour les mises à jour communes (tombe, capuche, etc.)
					state.campeurs[index] = {
						...campeur,
						...updates,
					};
				}
			}
			state.campeurs = calculateAllChances(state.campeurs, state.infoCase);
		},
		swapCampers : (state, action: PayloadAction<{
			index1: number;
			index2: number;
		}>) => {
			const { index1, index2 } = action.payload;
			if (
				index1 >= 0 && index1 < state.campeurs.length &&
				index2 >= 0 && index2 < state.campeurs.length
			) {
				[state.campeurs[index1], state.campeurs[index2]] =
					[state.campeurs[index2], state.campeurs[index1]];
				
				state.campeurs = calculateAllChances(state.campeurs, state.infoCase);
			}
		},
		
		updateBuilding: (state, action: PayloadAction<{
			bat: BatPrototypeDTO | null;
			nbTas?: number;
		}>) => {
			const { bat, nbTas } = action.payload;
			
			state.infoCase.bat = bat;
			
			if (bat === null) {
				state.infoCase.bonus_camping = -25;
				state.infoCase.nbrCampMax = 99;
			} else {
				state.infoCase.bonus_camping = bat.bonus_camping;
				state.infoCase.nbrCampMax = bat.max_campeur;
			}
			
			if (nbTas !== undefined) {
				state.infoCase.nbTas = nbTas;
				if (bat?.id === -1) {
					const maxCamp = Math.floor(nbTas / 3);
					state.infoCase.nbrCampMax = maxCamp > 3 ? 3 : maxCamp;
				}
			}
		},
		
		updateInfoCase: (state, action: PayloadAction<Partial<InfoCase>>) => {
			if (action.payload.mode && action.payload.mode !== state.infoCase.mode) {
				state.infoCase = createInitialInfoCase(action.payload.mode, state.infoCase.listBat, state.infoCase.listJob, state.infoCase.citoyens);
				state.campeurs = [];
			} else {
				state.infoCase = {
					...state.infoCase,
					...action.payload,
				} as InfoCase;
			}
		},
		
		updateAllChances: (state) => {
			state.campeurs = calculateAllChances(state.campeurs, state.infoCase);
		},
		reset           : (state) => {
			state.campeurs = [];
		},
		updateKm        : (state, action: PayloadAction<number>) => {
			// On filtre les batiments qui ont un km contenu dans leur borne
			const batiments = state.listBat.filter(bat => {
				if (bat.explorable) {
					if (state.infoCase.hard) {
						return bat.km_min <= action.payload && bat.km_max >= action.payload;
					} else {
						return bat.km_min <= action.payload && 10 >= action.payload;
					}
				} else {
					return bat.km_min <= action.payload && bat.km_max >= action.payload;
				}
			});
			
			state.infoCase = {
				...state.infoCase,
				km     : action.payload,
				listBat: batiments,
			};
		},
		updateHard      : (state, action: PayloadAction<boolean>) => {
			
			const batiments = state.listBat.filter(bat => {
				if (bat.explorable) {
					if (action.payload) {
						return bat.km_min <= state.infoCase.km && bat.km_max >= state.infoCase.km;
					} else {
						return bat.km_min <= state.infoCase.km && 10 >= state.infoCase.km;
					}
				} else {
					return bat.km_min <= state.infoCase.km && bat.km_max >= state.infoCase.km;
				}
			});
			
			state.infoCase = {
				...state.infoCase,
				hard   : action.payload,
				listBat: batiments,
			};
		},
	},
	selectors  : undefined,
});

export const {
				 initializeCamping,
				 addCamper,
				 deleteCamper,
				 updateCamper,
				 swapCampers,
				 updateBuilding,
				 updateInfoCase,
				 updateAllChances,
				 reset,
				 updateKm,
				 updateHard,
			 } = campingSlice.actions;

// Sélecteurs typés
export const selectInfoCase = (state: { camping: CampingState }): InfoCase => state.camping.infoCase;

export const selectCampeurs = (state: { camping: CampingState }): Campeur[] => state.camping.campeurs;

export const selectMode = (state: { camping: CampingState }): "anonyme" | "identifie" => state.camping.infoCase.mode;

export const selectBatiment = (state: { camping: CampingState }): BatPrototypeDTO | null => state.camping.infoCase.bat;

export const selectListBatiments = (state: { camping: CampingState }): BatPrototypeDTO[] => state.camping.infoCase.listBat;

export const selectBonusCamping = (state: { camping: CampingState }): number => state.camping.infoCase.bonus_camping;

export const selectNbrCampMax = (state: { camping: CampingState }): number => state.camping.infoCase.nbrCampMax;

export const selectCampeurById = (id: string) => (state: { camping: CampingState }): Campeur | undefined => state.camping.campeurs.find(campeur => campeur.id === id);

export default campingSlice.reducer;
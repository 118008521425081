import React, { useEffect, useRef }                                                                from "react";
import { useDispatch, useSelector }                                                                from "react-redux";
import { useTranslation }                                                                          from "react-i18next";
import { CampeurUpdate, InfoCase }                                                                 from "../../../types/components/Outils/Camping.type";
import CampingControls                                                                             from "./CampingControls";
import { CampersList }                                                                             from "./CampersList";
import { addCamper, deleteCamper, initializeCamping, swapCampers, updateAllChances, updateCamper } from "../../../store/outils/campingSlice";
import { customStyles }                                                                            from "./CustomStyle";
import { RootState }                                                                               from "../../../store/store";
import { BatPrototypeDTO }                                                                         from "../../../types/models/batPrototype.dto";

interface PropsCamping {
	initialInfoCase: InfoCase;
	listBat: BatPrototypeDTO[];
}

export const Camping = ({ initialInfoCase, listBat }: PropsCamping) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const infoCase = useSelector((state: RootState) => state.camping.infoCase);
	const campeurs = useSelector((state: RootState) => state.camping.campeurs);
	const isInitialized = useRef(false);
	
	const handleAddCampeur = () => {
		// Si mode anonyme, il faut fournir un job par défaut
		if (infoCase.mode === "anonyme") {
			dispatch(addCamper({
				type: "anonyme",
				job : infoCase?.listJob[0], // ou un job par défaut
			}));
		} else {
			dispatch(addCamper({
				type   : "identifie",
				citoyen: infoCase?.citoyens[0], // ou un citoyen par défaut
			}));
		}
	};
	
	useEffect(() => {
		// Ne faire l'initialisation qu'une seule fois
		if (!isInitialized.current) {
			dispatch(initializeCamping({
				mode    : initialInfoCase.mode,
				infoCase: initialInfoCase,
				listBat : listBat,
			}));
			isInitialized.current = true;
		}
	}, []); // Dépendances vides = une seule fois au montage
	
	const handleUpdateCampeur = (index: string, updates: CampeurUpdate) => {
		dispatch(updateCamper({ id: index, updates }));
	};
	
	const updateAllCampingChances = () => {
		dispatch(updateAllChances());
	};
	
	useEffect(() => {
		updateAllCampingChances();
	}, [infoCase]);
	
	return (
		<div className="outilsCalculCamping">
			<CampingControls />
			<CampersList
				campeurs={campeurs}
				infoCase={infoCase}
				onAddCampeur={handleAddCampeur}
				onUpdateCampeur={handleUpdateCampeur}
				onDeleteCampeur={(id) => dispatch(deleteCamper(id))}
				onSwapCampers={(index1, index2) => dispatch(swapCampers({ index1, index2 }))}
				customStyles={customStyles} />
		</div>
	);
};

export default Camping;
import React, { ReactNode, useState } from "react";
import { FaTimes }                    from "react-icons/fa";
import { FaCircleInfo }               from "react-icons/fa6";

const InfoBanner = ({ children }: { children: ReactNode }) => {
	const [isVisible, setIsVisible] = useState(true);
	
	return (
		isVisible && (
			<div style={{
				position : "relative",
				top      : 0,
				width    : "100%",
				padding  : "5px 10px",
				textAlign: "center",
				zIndex   : 1000,
			}}>
				<FaCircleInfo className={"me-2"} />
				<span>{children}</span>
				<FaTimes
					style={{ marginLeft: "10px", cursor: "pointer" }}
					onClick={() => setIsVisible(false)}
				/>
			</div>
		)
	);
};

export default InfoBanner;

import { RuinesDTO }        from "../../models/ruines.dto";
import { ItemPrototypeDTO } from "../../models/itemPrototype.dto";
import { RuinesPlansDTO }   from "../../models/ruinesPlans.dto";
import { RuinesObjetsDTO }  from "../../models/ruinesObjets.dto";
import { UserDTO }          from "../../models/user.dto";
import { CategoryObjetDTO } from "../../models/categoryObjet.dto";

export interface RuinesListingType {
	listRuines: RuinesDTO[];
}

export interface RuinesGeneralType {
	allObjet: { item: ItemPrototypeDTO; count: number; broken: boolean }[];
	coord: number[][][];
	filAriane: (number | null)[][][][];
	listRuines: RuinesDTO[];
	maxEtage: number;
	planRuine: RuinesPlansDTO;
	popUpMaj: PopUpMajCaseRuineProps;
	ruine: RuinesDTO;
	ruineCases: CaseRuine[][][];
	trad_porte: string[];
	userOption: UserDTO;
}

export interface CaseRuine {
	id?: number;
	x: number;
	y: number;
	z: number;
	type_case: number;
	type_porte?: string;
	type_escalier?: string;
	nbr_zombie?: number;
	items?: RuinesObjetsDTO[];
	update_by?: UserDTO;
	update_at?: string;
	porte_fouillee?: boolean;
	case_vue?: boolean;
	zombie_kill?: number;
	position_porte?: number;
	decoration?: number;
	decoration_variation?: number;
	correction?: boolean;
}

export interface PopUpMajCaseRuineProps {
	listCategorie: CategoryObjetDTO[];
	listItems: ItemPrototypeDTO[];
}


export const DIRECTION_O = 1;
export const DIRECTION_N = 2;
export const DIRECTION_E = 4;
export const DIRECTION_S = 8;

import React                 from "react";
import { useTranslation }    from "react-i18next";
import { TypeDeathDTO }      from "../../types/models/typeDeath.dto";
import { CleanUpCadaverDTO } from "../../types/models/cleanUpCadaver.dto";
import { UserDTO }           from "../../types/models/user.dto";
import SvgIcone              from "../generality/SvgIcone";
import TooltipGH             from "../utils/TooltipGH";

export default function TypeMort({ typeDeath, cleanUpType = null, cleanBy = null }: { typeDeath: TypeDeathDTO, cleanUpType?: CleanUpCadaverDTO, cleanBy?: UserDTO }) {
	const { t } = useTranslation();
	
	return <div className={"d-flex gap-1 justify-content-center align-items-center align-self-center"}>
		<TooltipGH>
            <span className="infoBulle cause_mort_citoyen">
                <SvgIcone icone={typeDeath.icon} />
            </span>
			<span className="info">{t("Cause :", { ns: "hotel" })} {t(typeDeath.label, { ns: "game" })}</span>
		</TooltipGH>
		{cleanUpType !== null && <TooltipGH>
            <span className="infoBulle cause_mort_citoyen">
                <SvgIcone icone={cleanUpType.icon} />
            </span>
			<span className="info">{t(cleanUpType.label, { ns: "game" })} {t("par", { ns: "hotel" })}{" "}<span className="nom_cleaneur_citoyen">{cleanBy === null ? t("inconnu", { ns: "hotel" }) : cleanBy.pseudo}</span></span>
		</TooltipGH>}
	</div>;
}
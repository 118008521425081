import React, { useState }                        from "react";
import Cookies                                    from "js-cookie";
import { CreationJumpType }                       from "../../../types/components/Jump/CreationJump.type";
import Tabs                                       from "react-bootstrap/Tabs";
import Tab                                        from "react-bootstrap/Tab";
import CreationJump                               from "./CreationJump";
import CreationEvent                              from "./CreationEvent";
import { Status_error, Status_success, usePopUp } from "../../../types/Context/PopUpContext";
import { useTranslation }                         from "react-i18next";

export default function Creation(props: { creation: CreationJumpType }) {
	const { t } = useTranslation();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const storedActiveTab = Cookies.get("activeTab_creation_jump");
	
	const [activeTab, setActiveTab] = useState(storedActiveTab || "jump");
	
	const handleTabChange = (eventkey) => {
		setActiveTab(eventkey);
		Cookies.set("activeTab_creation_jump", eventkey, { expires: 365 });
	};
	
	
	const onRetour = (isErrorCreation: boolean, showModCreation: boolean, messagePopCreation: string) => {
		setStatus(isErrorCreation ? Status_error : Status_success);
		setShowPop(showModCreation);
		setMessagePopUp(messagePopCreation);
		if (!isErrorCreation) {
			setTimeout(() => {
				setShowPop(false);
				setMessagePopUp("");
			}, 1500);
		}
	};
	
	return <div className="jumpCorps">
		<div id="infoCreation">
			<div id="zone_creation">
				<Tabs activeKey={activeTab} onSelect={handleTabChange}>
					<Tab eventKey={"jump"} title={t("Jump", { ns: "jumpEvent" })}>
						<CreationJump creation={props.creation} onRetour={onRetour} />
					</Tab>
					<Tab eventKey={"event"} title={t("Event", { ns: "jumpEvent" })}>
						<CreationEvent creation={props.creation} onRetour={onRetour} />
					</Tab>
				</Tabs>
			</div>
		</div>
	</div>;
	
}
import { Fetch }                                     from "../../components/generality/Fetch";
import { GeneralType }                               from "../../types/components/Generality/General.type";
import { FetchGH }                                   from "../../components/generality/FetchGH";
import { ContactPost, ContactType, ContactTypePost } from "../../types/components/General/Contact.type";


export class ContactApi {
	
	private fetch: Fetch;
	private fetchGH: FetchGH;
	
	constructor() {
		this.fetch = new Fetch("contact");
		this.fetchGH = new FetchGH("contact");
	}
	
	
	public contact(): Promise<RetourContacts> {
		return this.fetchGH.get("/contact");
	}
	
	public contactPost(data: ContactPost): Promise<RetourContactsPost> {
		return this.fetchGH.post({ path: "/contact", data: data });
	}
	
	public creation_image(data: { image?: any, up: number, mime?: any, oldName?: any }): Promise<RetourImage> {
		return this.fetchGH.post({ path: "/creation_image", data: data });
	}
	
	
}

export interface RetourContacts {
	data: {
		general?: GeneralType,
		contact?: ContactType,
		error?: string,
	}
	status?: number,
	message?: string,
}

export interface RetourContactsPost {
	data: {
		general?: GeneralType,
		contact?: ContactTypePost,
		error?: string,
	}
	status?: number,
	message?: string,
}

export interface RetourImage {
	data: {
		urlBanniere?: string,
		error?: string,
	}
	status?: number,
	message?: string,
}
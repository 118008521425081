import React   from "react";
import SvgCase from "./SvgCase";

export function Bordure(props: { classBordure: string; typeCarte: string; valeur: number; }) {
	return <td className={"fondNoir " + props.classBordure + "_" + props.typeCarte}>{props.valeur}</td>;
}

export function BordureCase(props: { classBordure: string; typeCarte: string; idBordure: string; display?: string; classOption?: string }) {
	let style = null;
	if (props.display !== null) {
		style = { "display": props.display };
	}
	
	const classOption = props.classOption ?? null;
	
	return <SvgCase classPrincipale={props.classBordure} typeCarte={props.typeCarte} svg={"bord"} idSvg={props.idBordure} style={style} classOption={classOption} />;
}
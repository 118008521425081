import React, { useEffect, useState }                                    from "react";
import { RuineGameType }                                                 from "../../types/components/RuineGame/RuineGame.type";
import RuineTraceMap                                                     from "../Ville/Ruine/RuineTraceMap";
import { useRuineGameContext }                                           from "./RuineGame";
import { JaugeOxygene }                                                  from "./JaugeOxygene";
import { RuineGameApi }                                                  from "../../services/api/RuineGameApi";
import { JaugeMana }                                                     from "./JaugeMana";
import SvgIcone                                                          from "../../components/generality/SvgIcone";
import { CaseRuine, DIRECTION_E, DIRECTION_N, DIRECTION_O, DIRECTION_S } from "../../types/components/ville/Ruines.type";
import { useTranslation }                                                from "react-i18next";

interface RuineGamePlayProps {
	game: RuineGameType;
	setEjected: (isEjected: boolean) => void;
	tradPorte: string[];
}

const random = (min: number, max: number): number => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

function generateRandomIndex(maxIndex: number): number {
	return Math.floor(Math.random() * maxIndex);
}

function checkDirection(direction: number, couloir: number) {
	if (direction === 0) {
		return false;
	}
	const check: Record<number, number> = {
		[DIRECTION_S]: 1,
		[DIRECTION_E]: 2,
		[DIRECTION_N]: 3,
		[DIRECTION_O]: 4,
	};
	if (!(direction in check)) {
		return false;
	}
	const bin = couloir.toString(2).padStart(5, "0");
	return bin[check[direction]] === "1";
}

function caseRuineAdjacente(caseRuine: CaseRuine, direction: string, position: string, typeRuine: string) {
	const porteOuverte = (caseRuine.type_porte === "p" || caseRuine.type_porte === null);
	// calcul du style selon la direction et la position si haut => translateY(100%), si bas => translateY(-100%), si
	// gauche => translateX(100%), si droite => translateX(-100%)
	const translationCase = (position === "haut" || position === "bas") ? "translateY" : "translateX";
	const directionCase = (position === "haut" || position === "gauche") ? "-100%" : "100%";
	
	return <div className={`zone_ruine_game zone_ruine_game_${position} zone_ruine_game_skin-${typeRuine} zone_ruine_game_type-${(caseRuine.x === 7 &&
		caseRuine.y === 0) ? "16" : caseRuine.type_case} zone_ruine_porte-${(porteOuverte ? "open" : "closed")}`}
				style={{ transform: direction === position ? `${translationCase}(0)` : `${translationCase}(${directionCase})` }}>
		<div className="decos">
			{caseRuine.position_porte && !caseRuine.type_escalier !== null &&
				<div className={`deco_porte deco_porte-${caseRuine.position_porte} deco_porte_lvl-0`}></div>}
			{caseRuine.position_porte && caseRuine.type_escalier !== null && <div
				className={`deco_porte deco_porte-${caseRuine.position_porte} deco_porte_lvl-${(caseRuine.type_escalier === "up") ? 1 : -1}`}></div>}
			{[...Array(16)].map((_, i) => (
				caseRuine.decoration & (2 ** i) && (
					<div key={i} className={`deco_ambiance decos_ambiance-${i + 1}${caseRuine.decoration_variation &
					(2 ** (16 + i)) ? "b" : "a"}`}></div>
				)
			))}
		</div>
	</div>;
}


export function RuineGamePlay({ game, setEjected, tradPorte }: RuineGamePlayProps) {
	const { t } = useTranslation();
	const {
			  plan, setPlan, x, setX, y, setY, z, setZ, oxygene, setOxygene, mana, setMana,
			  isCallApi, setIsCallApi, planMap, setPlanMap, setIsEndGame,
		  } = useRuineGameContext();
	const [sortis, setSortis] = useState(false);
	const [fuite, setFuite] = useState(false);
	const [isInPiece, setIsInPiece] = useState(false);
	const caseRuine = plan[z][y][x];
	const [positionListDead, setPositionListDead] = useState<number[][]>([]);
	const [positionListAlive, setPositionListAlive] = useState<number[][]>([]);
	const [direction, setDirection] = useState<string>("");
	const [deplacement, setDeplacement] = useState<boolean>(false);
	const [oxygeneLost, setOxygeneLost] = useState<number>(game.oxygen_lost ?? 0);
	const ruineGameApi = new RuineGameApi();
	
	const handleKillZombie = () => {
		if (!isCallApi) {
			setIsCallApi(true);
			const updatedPlan = [...plan];
			const caseActuelle = updatedPlan[z][y][x];
			ruineGameApi.killZombie({ idUser: game.user.id, case: caseRuine, idRuineGame: game.id }).then((response) => {
				if (response.data.zombie_kill !== undefined) {
					caseActuelle.zombie_kill = response.data.zombie_kill;
				}
				if (response.data.zombie_restant !== undefined) {
					caseActuelle.nbr_zombie = response.data.zombie_restant;
				}
				if (response.data.mana_kill !== undefined) {
					const newMana = response.data.mana_kill;
					setMana(newMana);
				}
				setPlan(updatedPlan);
			}).catch((error) => {
				console.error(error);
			}).finally(() => {
				setIsCallApi(false);
			});
		}
	};
	
	const handleFouillePiece = () => {
		if (!isCallApi) {
			setIsCallApi(true);
			const updatedPlan = [...plan];
			const caseActuelle = updatedPlan[z][y][x];
			ruineGameApi.fouillePiece({ idUser: game.user.id, case: caseRuine, idRuineGame: game.id }).then(() => {
				caseActuelle.porte_fouillee = true;
				setPlan(updatedPlan);
			}).catch((error) => {
				console.error(error);
			}).finally(() => {
				setIsCallApi(false);
			});
		}
	};
	
	const handleMoveInPiece = (etatMove: boolean) => {
		if (!isCallApi) {
			setIsCallApi(true);
			ruineGameApi.deplacementPiece(
				{ idUser: game.user.id, case: caseRuine, id_ruine_game: game.id, in_piece: etatMove }).then(() => {
				setIsInPiece(etatMove);
			}).catch((error) => {
				console.error(error);
			}).finally(() => {
				setIsCallApi(false);
			});
		}
	};
	
	const handleFuiteZombie = () => {
		if (!isCallApi) {
			setIsCallApi(true);
			ruineGameApi.fuiteRuine({ idUser: game.user.id, case: caseRuine, idRuineGame: game.id, caseFuite: true }).then((response) => {
				if (response.data.oxygen_lost_total !== undefined && response.data.oxygen_lost_total > 0) {
					setOxygeneLost(response.data.oxygen_lost_total);
				}
				if (response.data.perte_oxy !== undefined && response.data.perte_oxy > 0) {
					const newOxygene = oxygene - response.data.perte_oxy;
					setOxygene(newOxygene);
				}
				setFuite(true);
			}).catch((error) => {
				console.error(error);
			}).finally(() => {
				setIsCallApi(false);
			});
		}
	};
	
	const handleChangeFloor = (isForUp: boolean) => {
		if (!isCallApi) {
			setIsCallApi(true);
			const zMod = isForUp ? z + 1 : z - 1;
			ruineGameApi.deplacement({
				idUser       : game.user.id,
				case         : caseRuine,
				id_ruine_game: game.id,
				case_suivante: plan[zMod][y][x],
				case_fuite   : false,
			}).then((response) => {
				setZ(zMod);
				if (response.data.perte_oxy !== undefined && response.data.perte_oxy > 0) {
					const newOxygene = Math.max(oxygene - response.data.perte_oxy, 0);
					setOxygene(newOxygene);
				}
				if (response.data.oxygen_lost_total !== undefined && response.data.oxygen_lost_total > 0) {
					setOxygeneLost(response.data.oxygen_lost_total);
				}
			}).catch((error) => {
				console.error(error);
			}).finally(() => {
				if (game.type_plan === 1) {
					const updatedPlan = [...plan];
					const caseSuivante = updatedPlan[zMod][y][x];
					const updatedPlanMap = [...planMap];
					updatedPlanMap[zMod][y][x] = caseSuivante;
					setPlanMap(updatedPlanMap);
				}
				setIsCallApi(false);
			});
		}
	};
	
	const handleSortir = () => {
		setSortis(true);
		setEjected(false);
		setIsEndGame(true);
	};
	
	const calculDirection = async (direction: string) => {
		const directionMap = { bas: [0, 1], haut: [0, -1], gauche: [-1, 0], droite: [1, 0] };
		const [xMod, yMod] = directionMap[direction];
		
		try {
			setDeplacement(true);
			setIsCallApi(true);
			
			const updatedPlan = [...plan];
			const caseActuelle = updatedPlan[z][y][x];
			const caseSuivante = updatedPlan[z][y + yMod][x + xMod];
			
			const response = await ruineGameApi.deplacement({
				idUser       : game.user.id,
				case         : caseActuelle,
				id_ruine_game: game.id,
				case_suivante: caseSuivante,
				case_fuite   : fuite,
			});
			
			setDirection(direction);
			if (response.data.perte_oxy !== undefined && response.data.perte_oxy > 0) {
				setOxygeneLost(response.data.oxygen_lost_total);
			}
			//setOxygene(response.data.perte_oxy > 0 ? oxygene - response.data.perte_oxy : oxygene);
			caseActuelle.case_vue = true;
			setPlan(updatedPlan);
			
			setTimeout(() => {
				setDeplacement(false);
				setDirection("");
				setX(x + xMod);
				setY(y + yMod);
				setFuite(false);
				if (game.type_plan === 1) {
					const updatedPlanMap = [...planMap];
					updatedPlanMap[z][y + yMod][x + xMod] = caseSuivante;
					setPlanMap(updatedPlanMap);
				}
			}, 500);
		} catch (error) {
			console.error(error);
		} finally {
			setIsCallApi(false);
		}
	};
	
	useEffect(() => {
		if (oxygene <= 0) {
			setEjected(true);
			setIsEndGame(true);
		}
	}, [oxygene]);
	
	useEffect(() => {
		setIsInPiece(game.in_piece);
	}, [game.in_piece]);
	
	useEffect(() => {
		const generatePositions = (dead: boolean) => {
			const newPositions: number[][] = [];
			const count = dead ? caseRuine.zombie_kill : caseRuine.nbr_zombie;
			for (let n = 1; n <= count; n++) {
				const positions: number[][] = [];
				if (checkDirection(DIRECTION_E, caseRuine.type_case)) {
					positions.push([random(60, 80), random(dead ? 46 : 38, 54)]);
				}
				if (checkDirection(DIRECTION_O, caseRuine.type_case)) {
					positions.push([random(20, 40), random(dead ? 46 : 38, 54)]);
				}
				if (checkDirection(DIRECTION_N, caseRuine.type_case)) {
					positions.push([random(42, 58), random(dead ? 20 : 38, 40)]);
				}
				if (checkDirection(DIRECTION_S, caseRuine.type_case)) {
					positions.push([random(42, 58), random(dead ? 60 : 54, 80)]);
				}
				newPositions.push(positions[generateRandomIndex(positions.length)]);
			}
			newPositions.sort((a, b) => a[1] - b[1]);
			dead ? setPositionListDead(newPositions) : setPositionListAlive(newPositions);
		};
		
		if (caseRuine.zombie_kill > 0) {
			generatePositions(true);
		}
		
		if (caseRuine.nbr_zombie > 0) {
			generatePositions(false);
		}
	}, [caseRuine.zombie_kill, caseRuine.nbr_zombie]);
	
	let thereIsStair = false;
	let stair_up = false;
	if (caseRuine.position_porte) {
		thereIsStair = caseRuine.type_escalier !== null;
		stair_up = (caseRuine.type_escalier === "up");
	}
	
	let porteOuverte = false;
	if (caseRuine.type_porte === "p" || caseRuine.type_porte === null) {
		porteOuverte = true;
	}
	
	
	return <div id={"zone_play_game"}>
		<div id={"ruine_zoom_play"}>
			<div id={"ruine_exploration_jauge"}>
				<div id={"ruine_exploration"}>
					<div className="zone_ruine_map_game">
						<div className={`zone_jeu ${isInPiece ? "in_room" : ""}`}>
							<div
								className={`zone_ruine_game zone_ruine_game_skin-${game.type_ruine} zone_ruine_game_type-${isInPiece ?
									"room" : ((x === 7 && y === 0) ? "16" : caseRuine.type_case)} zone_ruine_porte-${(porteOuverte ? "open" : "closed")}`}
								style={{ transition: `transform ${direction === "" ? 0 : "0.5s"}` }}
							>
								<div className="decos">
									{!isInPiece && <>
										{caseRuine.position_porte && !thereIsStair &&
											<div className={`deco_porte deco_porte-${caseRuine.position_porte} deco_porte_lvl-0`}
												 onClick={() => handleMoveInPiece(true)}></div>}
										{caseRuine.position_porte && thereIsStair && <div
											className={`deco_porte deco_porte-${caseRuine.position_porte} deco_porte_lvl-${(stair_up) ? 1 :
												-1}`} onClick={() => handleChangeFloor(stair_up)}></div>}
										{[...Array(16)].map((_, i) => (
											caseRuine.decoration & (2 ** i) && (
												<div key={i} className={`deco_ambiance decos_ambiance-${i + 1}${caseRuine.decoration_variation &
												(2 ** (16 + i)) ? "b" : "a"}`}></div>
											)
										))}
									</>}
								</div>
								
								{!isInPiece && caseRuine.zombie_kill > 0 && positionListDead.map((position, index) => (
									<div key={`dead-${index}`}
										 className={`personnage ${Math.random() < 0.5 ? "zombie_deadlocal" : "zombie_dead"}`}
										 style={{ left: `${position[0]}%`, top: `${position[1]}%` }}></div>
								))}
								{!isInPiece && caseRuine.nbr_zombie > 0 && positionListAlive.map((position, index) => (
									<div key={`alive-${index}`} className={`personnage ${Math.random() < 0.5 ? "zombielocal" : "zombie"}`}
										 style={{ left: `${position[0]}%`, top: `${position[1]}%` }}></div>
								))}
							</div>
							{deplacement && plan[z] !== undefined && plan[z][y] !== undefined && plan[z][y][x + 1] !== undefined
								&& plan[z][y][x + 1]?.type_case !== 0 &&
								caseRuineAdjacente(plan[z][y][x + 1], direction, "droite", game.type_ruine)
							}
							{deplacement && plan[z] !== undefined && plan[z][y] !== undefined && plan[z][y][x - 1] !== undefined &&
								plan[z][y][x - 1]?.type_case !== 0 &&
								caseRuineAdjacente(plan[z][y][x - 1], direction, "gauche", game.type_ruine)
							}
							{deplacement && plan[z] !== undefined && plan[z][y - 1] !== undefined && plan[z][y - 1][x] !== undefined &&
								plan[z][y - 1][x]?.type_case !== 0 &&
								caseRuineAdjacente(plan[z][y - 1][x], direction, "haut", game.type_ruine)
							}
							{deplacement && plan[z] !== undefined && plan[z][y + 1] !== undefined && plan[z][y + 1][x] !== undefined &&
								plan[z][y + 1][x]?.type_case !== 0 &&
								caseRuineAdjacente(plan[z][y + 1][x], direction, "bas", game.type_ruine)
							}
							<div className="personnage citoyen" style={{ left: "50%", top: "50%", zIndex: "100" }}></div>
							{!deplacement && <div className="zone_ruine_game-controls">
								{!isInPiece && caseRuine.type_case === 16 &&
									<div className="action-move action-move-south" onClick={() => calculDirection("bas")}></div>}
								{((!fuite && caseRuine.nbr_zombie <= 0) || (fuite && caseRuine.nbr_zombie > 0)) && (<>
									{!isInPiece && checkDirection(DIRECTION_E, caseRuine.type_case) &&
										<div className="action-move action-move-east" onClick={() => calculDirection("droite")}></div>}
									{!isInPiece && checkDirection(DIRECTION_O, caseRuine.type_case) &&
										<div className="action-move action-move-west" onClick={() => calculDirection("gauche")}></div>}
									{!isInPiece && checkDirection(DIRECTION_S, caseRuine.type_case) &&
										<div className="action-move action-move-south" onClick={() => calculDirection("bas")}></div>}
									{!isInPiece && checkDirection(DIRECTION_N, caseRuine.type_case) &&
										<div className="action-move action-move-north" onClick={() => calculDirection("haut")}></div>}
									{isInPiece &&
										<div className="action-move action-move-unshift" onClick={() => handleMoveInPiece(false)}></div>}
								</>)}
							</div>}
						</div>
					</div>
				</div>
				<div>
					<div style={{ textAlign: "center" }} className={"legend_jauge_ruineGame"}>O<sub>2</sub></div>
					<JaugeOxygene endGame={sortis} oxygeneStart={game.qte_oxygene} gameBegin={game.begin_at} oxygen_lost={oxygeneLost ?? 0} />
				</div>
				<div>
					<div id={"icone_jauge_mana_ruine_game"} className={"legend_jauge_ruineGame"}><SvgIcone icone={"h_rage"} />
					</div>
					<JaugeMana manaStart={game.mana_initial} />
				</div>
			</div>
			<fieldset id={"ruine_action_exploration"}>
				<legend>{t("Liste des actions", { ns: "ruineGame" })}</legend>
				{!isInPiece && !fuite && caseRuine.nbr_zombie > 0 && mana > 0 &&
					<button className={"btn btn-danger"} onClick={() => handleKillZombie()}>{t("Tuer zombie", { ns: "ruineGame" })}</button>}
				{!isInPiece && !fuite && caseRuine.nbr_zombie > 0 &&
					<button className={"btn btn-danger"} onClick={() => handleFuiteZombie()}>{t("Fuir", { ns: "ruineGame" })}</button>}
				{plan[z][y][x].type_escalier === "up" && (
					<button className={"btn btn-sm btn-primary"} type={"button"} onClick={() => handleChangeFloor(true)}
							disabled={!fuite && caseRuine.nbr_zombie > 0}>{t("Monter l'escalier", { ns: "ruineGame" })}</button>)}
				{plan[z][y][x].type_escalier === "down" && (
					<button className={"btn btn-sm btn-primary"} type={"button"} onClick={() => handleChangeFloor(false)}
							disabled={!fuite && caseRuine.nbr_zombie > 0}>{t("Descendre l'escalier", { ns: "ruineGame" })}</button>)}
				{!isInPiece && plan[z][y][x].type_porte === "p" && (
					<button className={"btn btn-sm btn-primary"} type={"button"} onClick={() => handleMoveInPiece(true)}
							disabled={!fuite && caseRuine.nbr_zombie > 0}>{t("Entrer dans la piece", { ns: "ruineGame" })}</button>)}
				{isInPiece && !caseRuine.porte_fouillee && plan[z][y][x].type_porte === "p" && (
					<button className={"btn btn-sm btn-primary"} type={"button"}
							onClick={() => handleFouillePiece()}>{t("Fouiller piece", { ns: "ruineGame" })}</button>)}
				{isInPiece && plan[z][y][x].type_porte === "p" && (<button className={"btn btn-sm btn-primary"} type={"button"} onClick={() => handleMoveInPiece(
					false)}>{t("Sortir de la piece", { ns: "ruineGame" })}</button>)}
				{x === 7 && y === 0 && z === 0 && (<button className={"btn btn-sm btn-warning"} type={"button"} onClick={() => handleSortir()}>{t("Sortir", { ns: "ruineGame" })}</button>)}
			</fieldset>
		</div>
		<div id={"ruine_plan_play"}>
			{/*plan.map((_etage, z_mod) => {
			 return <RuineTraceMap plan={plan} z_aff={z_mod} z={z} x={x} y={y} translate={translate.trad_porte} key={"etage_" + z_mod} />;
			 })*/}
			{game.type_plan !== 2 && <RuineTraceMap plan={planMap} z_aff={z} z={z} x={x} y={y} trad_porte={tradPorte} key={"etage_" + z} />}
		</div>
	</div>;
}
import { StylesConfig }    from "react-select";
import { CitoyensDTO }     from "../../../types/models/citoyens.dto";
import { JobPrototypeDTO } from "../../../types/models/jobPrototype.dto";

const baseStyles = {
	display        : "flex",
	flexDirection  : "row" as const,
	alignItems     : "center",
	backgroundColor: "white",
	color          : "black",
};

export const customStyles: StylesConfig<CitoyensDTO | JobPrototypeDTO> = {
	option           : (provided) => ({
		...provided,
		...baseStyles,
	}),
	singleValue      : (provided) => ({
		...provided,
		...baseStyles,
	}),
	control          : (provided) => ({
		...provided,
		minHeight: "24px",
		height   : "24px",
	}),
	input            : (provided) => ({
		...provided,
		gridTemplateColumns: "none",
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		padding: "0",
	}),
	valueContainer   : (provided) => ({
		...provided,
		padding: "0px 8px",
	}),
	menu             : (provided) => ({
		...provided,
		backgroundColor: "white",
		color          : "black",
	}),
	menuList         : (provided) => ({
		...provided,
		backgroundColor: "white",
		color          : "black",
	}),
	menuPortal       : (provided) => ({
		...provided,
		backgroundColor: "white",
		color          : "black",
	}),
};

export const tableStyles = {
	table : "w-full border-collapse",
	th    : "p-2 border text-left bg-gray-100",
	td    : "p-2 border",
	button: {
		base     : "btn btn-xs",
		primary  : "btn-primary",
		danger   : "btn-danger",
		secondary: "btn-secondary",
	},
};
import React, { createContext, useContext, useEffect, useState } from "react";
import Tab                                                       from "react-bootstrap/Tab";
import Tabs                                                      from "react-bootstrap/Tabs";
import { OutilsChantiersTypeProps }                              from "../../../types/components/Outils/OutilsChantiers.type";
import RecapChantiers                                            from "./ComposantChantier/RecapChantiers";
import EnteteOutilsChantiers                                     from "./ComposantChantier/EnteteOutilsChantiers";
import RepaArme                                                  from "./ComposantChantier/RepaArme";
import UpHabitationAmelio                                        from "./ComposantChantier/UpHabitationAmelioration";
import UpChantiers                                               from "./ComposantChantier/UpChantiers";
import PaOuvrier                                                 from "./ComposantChantier/PaOuvrier";
import RessourcesOutils                                          from "./ComposantChantier/RessourcesOutils";
import { OutilsChantierDTO }                                     from "../../../types/models/outilsChantier.dto";
import { useTranslation }                                        from "react-i18next";

interface OutilsChantierContextType {
	outilsChantiers: OutilsChantierDTO,
	setOutilsChantiers: (outilsChantierMod: OutilsChantierDTO) => void;
}

export const OutilsChantiersContext = createContext<OutilsChantierContextType>({
	outilsChantiers   : null,
	setOutilsChantiers: () => {
	},
});

export function useOutilsChantiers() {
	return useContext(OutilsChantiersContext);
}

export default function TabsOutilsChantier(props: { outils: OutilsChantiersTypeProps }) {
	const { t } = useTranslation();
	const outils = props.outils;
	//const defense = outils.defense;
	
	const [outilsChantiers, setOutilsChantiers] = useState(outils.outilsChantier);
	
	useEffect(() => {
		setOutilsChantiers(outils.outilsChantier);
	}, [props.outils]);
	
	return (
		<OutilsChantiersContext.Provider value={{ outilsChantiers, setOutilsChantiers }}>
			<div id={"tabs_outils_chantiers"}>
				<EnteteOutilsChantiers outils={outils} />
				<div id={"group_tableur_outils_chantiers"}>
					<div id={"tabs_gestion_outils_chantiers"}>
						<Tabs defaultActiveKey="chantier" id="uncontrolled-tab-example" fill>
							<Tab eventKey="chantier" title={t("Chantiers", { ns: "outils" })}>
								<RecapChantiers outils={outils} />
							</Tab>
							<Tab eventKey="repa" title={t("Réparation Armes", { ns: "outils" })}>
								<RepaArme outils={outils} />
							</Tab>
							<Tab eventKey="habitation" title={t("Up Habitations", { ns: "outils" })}>
								<UpHabitationAmelio outils={outils} />
							</Tab>
							<Tab eventKey="evolution" title={t("Evolutions", { ns: "outils" })}>
								<UpChantiers outils={outils} />
							</Tab>
						</Tabs>
					</div>
					<div id={"tabs_recap_outils_chantiers"}>
						<Tabs
							defaultActiveKey="gain_pa"
							id="uncontrolled-tab-example"
							fill
						>
							<Tab eventKey="gain_pa" title={t("PA ouvriers", { ns: "outils" })}>
								<PaOuvrier outils={outils} />
							</Tab>
							<Tab eventKey="ressource" title={t("Ressources", { ns: "outils" })}>
								<RessourcesOutils outils={outils} />
							</Tab>
						</Tabs>
					</div>
				</div>
				<button type={"button"}>{t("Enregistrer", { ns: "outils" })}</button>
			</div>
		</OutilsChantiersContext.Provider>
	);
	
}

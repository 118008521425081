import React, { useEffect, useState }             from "react";
import { OutilsReparationPropsType }              from "../../../types/components/Outils/Reparation.type";
import HTMLParser                                 from "html-react-parser";
import SvgIcone                                   from "../../../components/generality/SvgIcone";
import Form                                       from "react-bootstrap/Form";
import ConfirmationDialog                         from "../../../components/generality/ComposantGeneral/ConfirmationDialog";
import { ReparationsApi }                         from "../../../services/api/ReparationsApi";
import { BoutonCopy }                             from "../../../components/generality/ComposantGeneral";
import { formatInTimeZone }                       from "date-fns-tz";
import { useGeneralContext }                      from "../../../types/Context/GeneralContext";
import { Status_error, Status_success, usePopUp } from "../../../types/Context/PopUpContext";
import { ChantierPrototypeDTO }                   from "../../../types/models/chantierPrototype.dto";
import { ReparationChantierDTO }                  from "../../../types/models/reparationChantier.dto";
import { useTranslation }                         from "react-i18next";

export default function Reparation(props: { reparation: OutilsReparationPropsType }) {
	const { t } = useTranslation();
	const { general } = useGeneralContext();
	const listCatChantier = props.reparation.listCatChantier;
	const pvByPa = props.reparation.pvByPa;
	const mapId = props.reparation.mapId;
	const userId = props.reparation.userId;
	
	const [outilsReparation, setOutilsReparation] = useState(props.reparation.outilRepa);
	const [textReparation, setTextReparation] = useState(props.reparation.textReparation);
	
	const [confirmShow, setConfirmShow] = useState(false);
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	
	useEffect(() => {
		setOutilsReparation(props.reparation.outilRepa);
		setTextReparation(props.reparation.textReparation);
	}, [props.reparation]);
	
	const handleClose = () => {
		setMessagePopUp("");
		setShowPop(false);
		setStatus(Status_success);
	};
	
	const upDateReparationChantiers = (updatedReparationChantiers: ReparationChantierDTO[]) => {
		// Calculez les nouvelles valeurs de gain_def et pa_tot en fonction de la sélection
		let newGainDef = 0; // Utilisez la valeur de def_base comme valeur par défaut
		let newPaTot = 0; // Utilisez la valeur actuelle de pa_tot
		
		updatedReparationChantiers.forEach((reparationItem) => {
			const itemPctRepa = reparationItem.pct_repa;
			
			switch (itemPctRepa) {
				case 0:
					break;
				case 70:
					newPaTot += reparationItem.pa_repa70;
					newGainDef += reparationItem.gain_def70;
					break;
				case 99:
					newPaTot += reparationItem.pa_repa99;
					newGainDef += reparationItem.gain_def99;
					break;
				case 100:
					newPaTot += reparationItem.pa_repa100;
					newGainDef += reparationItem.gain_def100;
					break;
				default:
					newPaTot += reparationItem.pa_repa_perso ?? 0;
					newGainDef += reparationItem.gain_def_perso ?? 0;
					break;
			}
		});
		
		const updatedOutilsReparation = {
			...outilsReparation,
			reparation_chantiers: updatedReparationChantiers,
			gain_def            : newGainDef,
			pa_tot              : newPaTot,
		};
		
		setOutilsReparation(updatedOutilsReparation);
	};
	
	const handleChoseChoix = (event: React.ChangeEvent<HTMLSelectElement>, reparation: ReparationChantierDTO) => {
		const selectedValue = parseInt(event.target.value, 10); // Conversion la valeur en nombre si nécessaire
		// Mise à jour de l'état outilsReparation en fonction de la sélection
		const updatedReparationChantiers = Object.values(outilsReparation.reparation_chantiers).map((item) => {
			if (item.chantier.id === reparation.chantier.id) {
				return { ...item, pct_repa: selectedValue };
			} else {
				return item;
			}
		});
		
		upDateReparationChantiers(updatedReparationChantiers);
	};
	
	const handleClickChange = (pctDef: number, pctConf: number) => {
		// Mise à jour de l'état outilsReparation en fonction du bouton cliqué
		const updatedReparationChantiers = Object.values(outilsReparation.reparation_chantiers).map((reparationItem) => {
			let pct = pctDef;
			if (reparationItem.chantier.def === 0) {
				pct = pctConf;
			}
			
			if (pct === 99 && reparationItem.pa_repa99 === reparationItem.pa_repa100) {
				pct = 100;
			}
			
			return { ...reparationItem, pct_repa: pct };
			
		});
		
		upDateReparationChantiers(updatedReparationChantiers);
	};
	
	const handleSauvegarde = () => {
		if (outilsReparation.created_by === null) {
			onSauvegardeOutils(true);
		} else {
			setConfirmShow(true);
		}
	};
	
	const onSauvegardeOutils = (withSauvegarde: boolean) => {
		const reparationsApi = new ReparationsApi(mapId);
		
		reparationsApi.sauvegarde({ outilsReparations: outilsReparation, sauvegarde: withSauvegarde, mapId: mapId, userId: userId })
			.then((response) => {
				if (response.codeRetour === 0) {
					setTextReparation(response.zoneRetour.textReparation);
					
					if (withSauvegarde) {
						setOutilsReparation(response.zoneRetour.outilsReparations);
						
						setStatus(Status_success);
						setShowPop(true);
						setMessagePopUp(response.libRetour);
						
						setTimeout(() => {
							handleClose();
						}, 1000);
					}
				} else {
					setStatus(Status_error);
					setShowPop(true);
					setMessagePopUp(response.libRetour);
				}
			});
	};
	
	const recupCoutPa = (reparation: ReparationChantierDTO) => {
		switch (reparation.pct_repa) {
			case 0:
				return 0;
			case 70:
				return reparation.pa_repa70;
			case 99:
				return reparation.pa_repa99;
			case 100:
				return reparation.pa_repa100;
			default:
				return reparation.pa_repa_perso ?? 0;
		}
	};
	
	const recupGainDef = (reparation: ReparationChantierDTO) => {
		switch (reparation.pct_repa) {
			case 0:
				return 0;
			case 70:
				return reparation.gain_def70;
			case 99:
				return reparation.gain_def99;
			case 100:
				return reparation.gain_def100;
			default:
				return reparation.gain_def_perso ?? 0;
		}
	};
	
	const handlePersonalisation = (event: React.ChangeEvent<HTMLInputElement>, reparation: ReparationChantierDTO) => {
		const value = parseInt(event.target.value, 10);
		
		// On va recalculer le gain de défense en fonction de la valeur personnalisée
		const chantier = reparation.chantier;
		const defBase = chantier.def;
		const pvBase = chantier.pv;
		const pvActuel = reparation.pv_actuel;
		
		// calcul du gain de pv en fonction de la valeur personnalisée
		let gainPv = value * pvByPa;
		
		// calcul du pct de réparation en fonction de la valeur personnalisée
		let pctRepa = Math.floor(((pvActuel + gainPv) / pvBase) * 100);
		
		let gainDef = 0;
		//min(floor(($pvActuel + $paPerso * $pvByPa) * $chantierProto->getDef() / $chantierProto->getPv()), $chantierProto->getDef())
		if (defBase !== 0) {
			gainDef = Math.max(Math.min(Math.floor((pvActuel + value * pvByPa) * defBase / pvBase), defBase) - reparation.def_actuelle, 0);
		}
		
		const updatedReparationChantiers = Object.values(outilsReparation.reparation_chantiers).map((item) => {
			if (item.chantier.id === reparation.chantier.id) {
				return { ...item, pa_repa_perso: value, pct_repa: pctRepa, gain_def_perso: gainDef };
			} else {
				return item;
			}
		});
		
		upDateReparationChantiers(updatedReparationChantiers);
	};
	
	const listDeroulante = (reparation: ReparationChantierDTO) => {
		return <select value={(reparation.pct_repa !== 0 && reparation.pct_repa !== 70 && reparation.pct_repa !== 99 && reparation.pct_repa !== 100) ? -1 : reparation.pct_repa} onChange={(event) => handleChoseChoix(event, reparation)}>
			<option value={0}>{t("Ne pas réparer", { ns: "outils" })}</option>
			<option value={70}>70%+1</option>
			<option value={99}>99%</option>
			<option value={100}>100%</option>
			<option value={-1}>{t("Repa Perso", { ns: "outils" })}</option>
		</select>;
	};
	
	return <div id="zoneReparation">
		<div id="formRepa">
			<table>
				{Object.values(listCatChantier).map((categorie) => {
					const listChantierReparation = Object.values(outilsReparation.reparation_chantiers).filter((reparation) => {
						if (typeof reparation.chantier.cat_chantier === "object") {
							const categorieReparation = reparation.chantier.cat_chantier as ChantierPrototypeDTO;
							return categorieReparation.id === categorie.id;
						} else {
							const categorieReparation = reparation.chantier.cat_chantier as number;
							return categorieReparation === categorie.id;
						}
						
					});
					
					if (listChantierReparation.length === 0) {
						return null;
					} else {
						return <React.Fragment key={"table_" + categorie.id}>
							<thead>
							<tr>
								<th className={"repa_name"}>{t(categorie.nom, { ns: "chantiers" })}</th>
								<th className="repa_def_actu">{HTMLParser(t("Def <br/> actuelle", { ns: "outils" }))}</th>
								<th className="repa_def_min">{HTMLParser(t("Def <br/> min", { ns: "outils" }))}</th>
								<th className="repa_def_max">{HTMLParser(t("Def <br/> max", { ns: "outils" }))}</th>
								<th className="repa_pv_actu">{HTMLParser(t("PV <br/> actuel", { ns: "outils" }))}</th>
								<th className="repa_pv_min">{HTMLParser(t("PV <br/> min", { ns: "outils" }))}</th>
								<th className="repa_pv_max">{HTMLParser(t("Pv <br/> max", { ns: "outils" }))}</th>
								<th className="repa_ratio">{HTMLParser(t("Ratio <br/> def/PA", { ns: "outils" }))}</th>
								<th className="repa_choix">{HTMLParser(t("Choix répa", { ns: "outils" }))}</th>
								<th className="rapa_cout">{HTMLParser(t("PA répa", { ns: "outils" }))}</th>
								<th className="repa_gain">{HTMLParser(t("Gain def", { ns: "outils" }))}</th>
							</tr>
							</thead>
							<tbody>
							{listChantierReparation.sort(
								(a: ReparationChantierDTO, b: ReparationChantierDTO) => a.chantier.order_by_general < b.chantier.order_by_general ? -1 : 1)
								.map((reparation) => {
									const chantier = reparation.chantier;
									
									return <tr id={"ligneRepa_" + chantier.id} key={"ligneRepa_" + chantier.id} className="ligneRepa">
										<td className="repa_name">
                                            <span className="iconNomChantierRepa d-flex gap-1 align-items-center justify-content-start ms-1">
                                                <SvgIcone icone={chantier.icon} />
                                                <span>{t(chantier.nom, { ns: "chantiers" })}</span>
                                            </span></td>
										<td className="repa_def_actu">{(chantier.def === 0) ? "/" : reparation.def_actuelle}</td>
										<td className="repa_def_min">{(chantier.def === 0) ? "/" : Math.floor(Math.ceil(chantier.def * (chantier.pv * 0.7 + 1)) / chantier.pv)}</td>
										<td className="repa_def_max">{(chantier.def === 0) ? "/" : chantier.def}</td>
										<td className="repa_pv_actu">{reparation.pv_actuel}</td>
										<td className="repa_pv_min">{Math.ceil(chantier.pv * 0.7) + 1}</td>
										<td className="repa_pv_max">{chantier.pv}</td>
										<td className="repa_ratio">{(chantier.def === 0) ? "/" : (Math.round(((chantier.def / (chantier.pv / pvByPa)) + Number.EPSILON) * 100) / 100)}</td>
										<td className="repa_choix">{listDeroulante(reparation)}</td>
										<td className="repa_cout paRepa" id={"paRepa_" + chantier.id}>
											{(reparation.pct_repa !== 0 && reparation.pct_repa !== 70 && reparation.pct_repa !== 99 && reparation.pct_repa !== 100) ? (<>
												<input type={"number"} value={recupCoutPa(reparation)} onChange={(event) => handlePersonalisation(event, reparation)} />
											</>) : (<>{recupCoutPa(reparation)}</>)}
										</td>
										<td className="repa_gain grainDef" id={"grainDef_" + chantier.id}>{recupGainDef(reparation)}</td>
									</tr>;
								})}
							</tbody>
						</React.Fragment>;
					}
				})}
				<tbody>
				<tr>
					<td id="zoneBoutonRepa" colSpan={8} rowSpan={2}>
						<div>
							<button type={"button"} onClick={() => handleClickChange(0, 0)}>{t("Tout 0%", { ns: "outils" })}</button>
							<button type={"button"} onClick={() => handleClickChange(70, 70)}>{t("Tout 70%+1", { ns: "outils" })}</button>
							<button type={"button"} onClick={() => handleClickChange(99, 70)}>{t("Confort 70%+1 Def 99%/100%", { ns: "outils" })}</button>
							<button type={"button"} onClick={() => handleClickChange(100, 70)}>{t("Confort 70%+1 Def 100%", { ns: "outils" })}</button>
							<button type={"button"} onClick={() => handleClickChange(99, 99)}>{t("Tout 99%/100%", { ns: "outils" })}</button>
							<button type={"button"} onClick={() => handleClickChange(100, 100)}>{t("Tout 100%", { ns: "outils" })}</button>
						</div>
					</td>
					<td>
                        <span className={"d-flex gap-1 align-items-center justify-content-start ms-1"}>
                            <span><strong>{t("Total", { ns: "outils" })}</strong></span>
                            <SvgIcone icone={"h_pa"} />
                        </span>
					</td>
					<td colSpan={2} id="paTotRepa">{outilsReparation.pa_tot}</td>
				</tr>
				<tr>
					<td>
                        <span className={"d-flex gap-1 align-items-center justify-content-start ms-1"}>
                            <span><strong>{t("Gain def", { ns: "outils" })} </strong></span>
                            <SvgIcone icone={"h_guard"} />
                        </span>
					</td>
					<td colSpan={2} id="gainDefTot">{outilsReparation.gain_def}</td>
				</tr>
				<tr>
					<td id="ligneChoixTwoStep" colSpan={8} rowSpan={2}>
						<div>
							<Form.Check
								name={"outils_reparation_twoStep"}
								id={"outils_reparation_twoStep"}
								type="switch"
								checked={outilsReparation.two_step}
								onChange={(event) => {
									const outilsMod = { ...outilsReparation, ...{ two_step: event.target.checked } };
									setOutilsReparation(outilsMod);
								}}
							/>
							<label htmlFor={"outils_reparation_twoStep"}>{t("Cochez pour générer un texte avec tous les chantiers à 70% (quelque soit le choix de réparation) puis en classant par ordre de rentabilité de réparation pour ceux à 99/100% lorsque les 70%+1 sont terminés", { ns: "outils" })}</label>
						</div>
					</td>
					<td>
                        <span className={"d-flex gap-1 align-items-center justify-content-start ms-1"}>
                            <span><strong>{t("Def avant", { ns: "outils" })}</strong></span>
                            <SvgIcone icone={"h_guard"} />
                        </span>
					
					</td>
					<td colSpan={2} id="defAvRepa">{outilsReparation.def_base}</td>
				</tr>
				<tr>
					<td>
                        <span className={"d-flex gap-1 align-items-center justify-content-start ms-1"}>
                            <span><strong>{t("Def après", { ns: "outils" })}</strong></span>
                            <SvgIcone icone={"h_guard"} />
                        </span>
					</td>
					<td colSpan={2} id="defApRepa">{outilsReparation.gain_def + outilsReparation.def_base}</td>
				</tr>
				</tbody>
			</table>
			<div id="boutFormRepa">
				<button type={"button"} onClick={handleSauvegarde}>{t("Sauvegarder et générer le texte", { ns: "outils" })}</button>
				{outilsReparation.created_by !== null &&
					<button type={"button"} onClick={() => onSauvegardeOutils(false)}>{t("Générer le texte", { ns: "outils" })}</button>}
				<button type={"button"} onClick={() => setOutilsReparation(props.reparation.outilRepa)}>{t("Annuler", { ns: "outils" })}</button>
			</div>
			<div id="phraseMajRepa">
				{outilsReparation.created_by !== null && <p className="fondWhite02">{HTMLParser(
					t("Plan de réparation créé par <strong>{createur}</strong > le {date}", { ns: "outils" }).replace("{createur}", outilsReparation.created_by.pseudo)
						.replace("{date}", formatInTimeZone(new Date(outilsReparation.created_at), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))) +
					((outilsReparation.modify_by === null) ? "" : (". " + t("Modifié par <strong>{modificateur}</strong > le {date}.", { ns: "outils" }).replace("{modificateur}", outilsReparation.modify_by.pseudo).replace("{date}",
						formatInTimeZone(new Date(outilsReparation.modify_at), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))))))}</p>}
			</div>
		</div>
		<div id="textRepa">
			<div id="contenuTextRepa">{HTMLParser(textReparation)}</div>
			<BoutonCopy textAcopier={textReparation} />
		</div>
		<ConfirmationDialog
			message={t("Attention, en enregistrant vous effacerez le choix qui a été fait auparavant, êtes - vous sûr de vouloir enregistrer les modifications ? ", { ns: "outils" }) + "\n" + t("Si vous souhaitez simplement regénérer un texte sans sauvegarder, utiliser le bouton \"Générer le texte\".", { ns: "outils" })}
			titre={""}
			btnAnnuler={t("Annuler", { ns: "outils" })}
			btnConfirmation={t("Confirmer", { ns: "outils" })}
			show={confirmShow}
			onConfirm={() => {
				setConfirmShow(false);
				onSauvegardeOutils(true);
			}}
			onCancel={() => setConfirmShow(false)}
		/>
	</div>;
	
}
import * as React                                                                                                                                                                                                  from "react";
import { useEffect, useState }                                                                                                                                                                                     from "react";
import { ArrayInput, BooleanField, BooleanInput, Datagrid, Edit, EditButton, List, NumberField, NumberInput, ReferenceInput, SelectInput, SimpleForm, SimpleFormIterator, TextField, TextInput, useRecordContext } from "react-admin";
import axios                                                                                                                                                                                                       from "axios";
import Button                                                                                                                                                                                                      from "@mui/material/Button";
import { Link }                                                                                                                                                                                                    from "react-router";
import { Status_error, Status_success, usePopUp }                                                                                                                                                                  from "../../types/Context/PopUpContext";
import { AdminApi }                                                                                                                                                                                                from "../../services/api/AdminApi";
import { ChantierPrototypeDTO }                                                                                                                                                                                    from "../../types/models/chantierPrototype.dto";
import { ItemPrototypeDTO }                                                                                                                                                                                        from "../../types/models/itemPrototype.dto";

const DechargeButton = () => {
	return (
		<Button component={Link} to={"/admin/chantier/decharge"} color="primary">
			Decharges
		</Button>
	);
};

const ArbreButton = () => {
	return (
		<Button component={Link} to={"/admin/chantier/arbre"} color="primary">
			Arbre des chantiers
		</Button>
	);
};
const MajJson = () => {
	const adminApi = new AdminApi();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const handleUpdate = () => {
		adminApi.maj_chantiers_json().then((response) => {
			if (response.codeRetour === 0) {
				setStatus(Status_success);
				setMessagePopUp(response.libRetour);
				setShowPop(true);
				setTimeout(() => {
					setShowPop(false);
					setMessagePopUp("");
				}, 1000);
			} else {
				setStatus(Status_error);
				setMessagePopUp(response.libRetour);
				setShowPop(true);
			}
		});
	};
	return (
		<button className={"btn btn-primary"} onClick={handleUpdate}>
			Mettre à jour le fichier JSON
		</button>
	);
};

export const ChantierList = (props) => {
	const postFilters = [
		<TextInput source="q" label="Search" alwaysOn key={"search_name_chantier"} />,
		<ReferenceInput
			source="id"
			label="Chantier"
			reference="chantier"
			key={"search_id_chantier"}
		/>,
	];
	
	return (
		<>
			<div>
				<DechargeButton />
				<ArbreButton />
				<MajJson />
			</div>
			<List {...props} filters={postFilters}>
				<Datagrid>
					<NumberField source="id" />
					<TextField source="nom" />
					<BooleanField source={"actif"} />
					<EditButton />
				</Datagrid>
			</List>
		</>
	);
};

export const ChantierEdit = (props) => {
	const [chantiers, setChantiers] = useState([]);
	const [items, setItems] = useState([]);
	const [load, setLoad] = useState(false);
	
	const PostTitle = () => {
		const record = useRecordContext();
		return <span>Edition de {record ? `"${record.nom}"` : ""}</span>;
	};
	
	const fetchChantiers = async () => {
		try {
			const response = await axios.get("/rest/v1/admin/chantier");
			const chantierList = response.data.sort(
				(a: ChantierPrototypeDTO, b: ChantierPrototypeDTO) =>
					a.nom.localeCompare(b.nom),
			);
			setChantiers(Object.values(chantierList));
		} catch (error) {
			console.error("Error fetching heros:", error);
		}
	};
	const fetchItems = async () => {
		try {
			const response = await axios.get("/rest/v1/admin/items");
			const itemsList = response.data.sort((a: ItemPrototypeDTO, b: ItemPrototypeDTO) =>
				a.nom.localeCompare(b.nom),
			);
			setItems(Object.values(itemsList));
		} catch (error) {
			console.error("Error fetching heros:", error);
		}
	};
	
	useEffect(() => {
		fetchChantiers().then((r) => r);
		fetchItems().then((r) => r);
		setLoad(true);
	}, []);
	
	const plan = [
		{ lvl: 0, name: "Aucun" },
		{ lvl: 1, name: "Commun" },
		{ lvl: 2, name: "Inhab" },
		{ lvl: 3, name: "Rare" },
		{ lvl: 4, name: "Très rare" },
	];
	const up_chantier = [
		{ type: 1, nom: "Ajoute de l'eau" },
		{ type: 2, nom: "Ajout de la def" },
		{ type: 3, nom: "Ajout % def" },
		{ type: 4, nom: "Ajout % régen" },
		{ type: 5, nom: "Reduction cout en PA" },
		{ type: 6, nom: "gain PV par PA" },
		{ type: 7, nom: "Valeur objet def" },
		{ type: 8, nom: "Valeur textuel" },
		{ type: 9, nom: "Consomme de l'eau" },
		{ type: 10, nom: "Ajout de la def/âme" },
		{ type: 11, nom: "Consomme des pamplemousses explosifs" },
		{ type: 12, nom: "Consomme des piles" },
	];
	
	return (
		load && (
			<Edit {...props} title={<PostTitle />}>
				<SimpleForm>
					<div id={"chantier_form_prototype"}>
						<TextInput source={"nom"} label={"Nom Chantier"} />
						<TextInput
							source={"description"}
							label={"Description Chantier"}
							multiline={true}
							fullWidth
						/>
						<TextInput source={"icon"} label={"Icon"} />
						<NumberInput source={"def"} label={"Def"} />
						<NumberInput source={"water"} label={"Eau donné"} />
						<NumberInput source={"pa"} label={"PA"} />
						<NumberInput source={"pv"} label={"PV"} />
						<NumberInput source={"niveau"} label={"Niveau"} />
						<NumberInput source={"plan"} label={"Plan"} />
						<SelectInput
							source="plan"
							label="Plan"
							choices={plan}
							optionText="name"
							optionValue="lvl"
						/>
						<BooleanInput name={"temp"} source={"Temporaire"} />
						<BooleanInput name={"indes"} source={"Indestructible"} />
						<BooleanInput name={"ruine_ho"} source={"Ruine Hotel"} />
						<BooleanInput name={"ruine_hs"} source={"Ruine Hopital"} />
						<BooleanInput name={"ruine_bu"} source={"Ruine Bunker"} />
						<BooleanInput name={"specifique_ville_prive"} source={"Specifique Ville Prive"} />
						<NumberInput name={"level_max"} source={"Level maximum"} />
						<NumberInput name={"id_mh"} source={"Identifiant MyHordes"} />
						<TextInput name={"uid"} source={"Uid MyHordes"} />
						{chantiers.length > 0 && (
							<SelectInput
								source="parent.id"
								label="Chantier parent"
								choices={chantiers}
								optionText="nom"
								optionValue="id"
							/>
						)}
						{chantiers.length > 0 && (
							<SelectInput
								source="cat_chantier.id"
								label="Categorie du chantier"
								choices={chantiers}
								optionText="nom"
								optionValue="id"
							/>
						)}
						<BooleanInput name={"actif"} source={"Actif"} />
					</div>
					<div id={"chantier_form_prototype_ressources"}>
						{items.length > 0 && (
							<ArrayInput name={"ressources"} source={"ressources"}>
								<SimpleFormIterator inline>
									<SelectInput
										source="item.id"
										label="Item"
										choices={items}
										optionText="nom"
										optionValue="id"
									/>
									<NumberInput source="nombre" label="Quantité" />
								</SimpleFormIterator>
							</ArrayInput>
						)}
						{
							<ArrayInput name={"level_ups"} source={"level_ups"}>
								<SimpleFormIterator inline>
									<NumberInput source="level" label="Level" />
									<ArrayInput name={"bonus_ups"} source={"bonus_ups"}>
										<SimpleFormIterator inline>
											<SelectInput
												source="type_bonus"
												label="Type Bonus"
												choices={up_chantier}
												optionText="nom"
												optionValue="type"
											/>
											<TextInput source="valeur_up" label="Valeur" />
										</SimpleFormIterator>
									</ArrayInput>
								</SimpleFormIterator>
							</ArrayInput>
						}
					</div>
				</SimpleForm>
			</Edit>
		)
	);
};

import { Fetch }                    from "../../components/generality/Fetch";
import { RetourInscription }        from "./InscriptionJumpApi";
import { CandidatureJumpPropsType } from "../../types/components/Jump/CandidatureJump.type";
import { GestionEventType }         from "../../types/components/Jump/GestionEvent.type";
import { GestionJumpType }          from "../../types/components/Jump/GestionJump.type";
import { CreationJumpType }         from "../../types/components/Jump/CreationJump.type";
import { ListGestionType }          from "../../types/components/Jump/ListGestion.type";
import { GeneralType }              from "../../types/components/Generality/General.type";
import { JumpDTO }                  from "../../types/models/jump.dto";
import { EventDTO }                 from "../../types/models/event.dto";
import { InscriptionJumpDTO }       from "../../types/models/inscriptionJump.dto";

export class GestionJumpApi {
	
	private fetch: Fetch;
	
	constructor(mapId?: number) {
		this.fetch = new Fetch("gestion", mapId ?? 0);
	}
	
	public creation_banniere(data: { image?: any, up: number, mime?: any, oldName: any }): Promise<RetourGestionBanniere> {
		return this.fetch.from("/creation_banniere").request().post(data);
	}
	
	public creation_jump(data: { jump: JumpDTO }): Promise<RetourCreationJump> {
		return this.fetch.from("/creation_jump").request().post(data);
	}
	
	public associate(data: { idJump: string, userId: number }): Promise<RetourAssociationJump> {
		return this.fetch.from("/associate").request().post(data);
	}
	
	public creation_event(data: { event: EventDTO }): Promise<RetourCreationEvent> {
		return this.fetch.from("/creation_event").request().post(data);
	}
	
	public suppression_event(idJump): Promise<RetourSuppEventJump> {
		return this.fetch.from("/supp_event/" + idJump).request().delete();
	}
	
	public suppression_jump(idJump): Promise<RetourSuppEventJump> {
		return this.fetch.from("/supp_jump/" + idJump).request().delete();
	}
	
	public modification_event(data: { event: EventDTO }): Promise<RetourModificationEvent> {
		return this.fetch.from("/gestion_event/" + data.event.id).request().put(data);
	}
	
	public modification_jump(data: { jump: JumpDTO }): Promise<RetourModificationJump> {
		return this.fetch.from("/gestion_jump/" + data.jump.id).request().put(data);
	}
	
	public modifBanniere(data: { userId: number, idJump: string, sens: boolean }): Promise<RetourGestionBanniere> {
		return this.fetch.from("/modif_banniere").request().post(data);
	}
	
	public majFigeMetier(data: { userId: number, idJump: string, fige: boolean }): Promise<RetourFigeMetier> {
		return this.fetch.from("/figeMetier").request().post(data);
	}
	
	public majFigeComp(data: { userId: number, idJump: string, fige: boolean }): Promise<RetourFigeComp> {
		return this.fetch.from("/figeComp").request().post(data);
	}
	
	public gestion_candidature_jump(data: { userId: number, jumpId: string, inscription: InscriptionJumpDTO }): Promise<RetourInscription> {
		return this.fetch.from("/gestion_candidature_jump").request().post(data);
	}
	
	public aff_gestionCandidature(idJump: string, userId: string): Promise<AffichageCandidature> {
		return this.fetch.from("/jump/" + idJump + "/" + userId).request().get();
	}
	
	public aff_gestionEvent(idEvent: string): Promise<AffichageGestionEvent> {
		return this.fetch.from("/event/" + idEvent).request().get();
	}
	
	public aff_gestionJump(idJump: string): Promise<AffichageGestionJump> {
		return this.fetch.from("/jump/" + idJump).request().get();
	}
	
	public main_creation(): Promise<AffichageCreation> {
		return this.fetch.from("/creation").request().get();
	}
	
	public main_gestion(): Promise<AffichageGestion> {
		return this.fetch.from("/jump").request().get();
	}
	
}

export interface RetourGestionBanniere {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		urlBanniere: string,
		fileName?: string,
	},
}

export interface RetourCreationJump {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		jump: string,
	},
}

export interface RetourCreationEvent {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		event: string,
	},
}

export interface RetourFigeMetier {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: object,
}

export interface RetourFigeComp {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: object,
}

export interface RetourSuppEventJump {
	codeRetour?: number,
	libRetour?: string,
}

export interface RetourModificationEvent {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		event?: EventDTO,
		libErreur?: string[],
	},
}

export interface RetourModificationJump {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		jump?: JumpDTO,
		libErreur?: string[],
	},
}

export interface RetourAssociationJump {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		jump: JumpDTO,
	},
}

export interface AffichageCandidature {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		inscription: CandidatureJumpPropsType,
		general: GeneralType,
	},
}

export interface AffichageGestionEvent {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		gestionEvent: GestionEventType,
		general: GeneralType,
	},
}

export interface AffichageGestionJump {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		gestionJump: GestionJumpType,
		general: GeneralType,
	},
}

export interface AffichageCreation {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		creation: CreationJumpType,
		general: GeneralType,
	},
}

export interface AffichageGestion {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		list: ListGestionType,
		general: GeneralType,
	},
}
import { CitoyensDTO }     from "../../models/citoyens.dto";
import { JobPrototypeDTO } from "../../models/jobPrototype.dto";
import { BatPrototypeDTO } from "../../models/batPrototype.dto";

// Types de base pour les informations de case
export interface InfoCase {
	phare: boolean;
	nuit: boolean;
	devast: boolean;
	nbrAmelioOD: number | string;
	zombie: number;
	nbrCampMax: number;
	hard: boolean;
	bat: BatPrototypeDTO | null;
	listBat: BatPrototypeDTO[];
	listJob?: JobPrototypeDTO[];
	bonus_camping: number;
	nbTas: number;
	mode: "anonyme" | "identifie";
	citoyens?: CitoyensDTO[];
	km: number;
}

// Types pour les campeurs
export interface Campeur {
	id: string;
	tombe: boolean;
	capuche: boolean;
	objet_camping: number;
	chance_camping: number;
	nb_camping: number;
	type: "anonyme" | "identifie";
	job?: JobPrototypeDTO;
	campeur_pro?: boolean;
	lvl_reclus?: number;
	citoyen?: CitoyensDTO;
}

// Type guards
export const isCampeurAnonyme = (campeur: Campeur) => {
	return campeur.type === "anonyme";
};

export const isCampeurIdentifie = (campeur: Campeur) => {
	return campeur.type === "identifie";
};

// État global du camping
export interface CampingState {
	mode: "anonyme" | "identifie";
	infoCase: InfoCase;
	campeurs: Campeur[];
	listBat: BatPrototypeDTO[];
}

// Types pour les mises à jour
export type CampeurUpdate = Partial<Campeur>;

// Helpers pour la création des campeurs
export const createInitialInfoCase = (mode: "anonyme" | "identifie", listBat: BatPrototypeDTO[], listJob: JobPrototypeDTO[], citoyens?: CitoyensDTO[]): InfoCase => {
	const baseInfo: InfoCase = {
		phare        : false,
		nuit         : true,
		devast       : false,
		nbrAmelioOD  : 0,
		zombie       : 0,
		nbrCampMax   : 99,
		hard         : false,
		bat          : null,
		listBat      : listBat,
		bonus_camping: -25,
		nbTas        : 0,
		km           : 0,
		mode         : mode,
	};
	
	if (mode === "identifie") {
		return {
			...baseInfo,
			citoyens: citoyens,
			listJob : [],
		};
	} else {
		return {
			...baseInfo,
			listJob : listJob,
			citoyens: [],
		};
	}
};

export const createCampeurAnonyme = (
	id: string,
	job?: JobPrototypeDTO,
): Campeur => ({
	id            : id,
	type          : "anonyme",
	job,
	campeur_pro   : false,
	tombe         : false,
	capuche       : true,
	objet_camping : 0,
	chance_camping: 0,
	nb_camping    : 0,
});

export const createCampeurIdentifie = (
	id: string,
	citoyen?: CitoyensDTO,
): Campeur => ({
	id            : id,
	type          : "identifie",
	citoyen,
	tombe         : false,
	capuche       : true,
	objet_camping : 0,
	chance_camping: 0,
	nb_camping    : citoyen?.nb_camping ?? 0,
});

// Types pour les actions du reducer/slice
export type CampingAction =
	| { type: "INITIALIZE"; payload: InfoCase }
	| { type: "ADD_CAMPER"; payload: Campeur }
	| { type: "DELETE_CAMPER"; payload: string }
	| { type: "UPDATE_CAMPER"; payload: { id: string; updates: CampeurUpdate } }
	| { type: "SWAP_CAMPERS"; payload: { index1: number; index2: number } }
	| { type: "UPDATE_INFO_CASE"; payload: Partial<InfoCase> }
	| { type: "UPDATE_BUILDING"; payload: { bat: BatPrototypeDTO | null } }
	| { type: "UPDATE_ALL_CHANCES"; payload: Campeur[] };
import React from "react";
import es    from "../../../img/es.svg";
import en    from "../../../img/en.svg";
import fr    from "../../../img/fr.svg";
import de    from "../../../img/de.svg";

export default function SvgDrapeau(props: { drapeau: string, classIcone?: string, classIconeInt?: string }) {
	
	let classDrapeau = "drapeau";
	let classDrapeauInt = "drapeau_int";
	if (props.classIcone !== undefined) {
		classDrapeau = props.classIcone;
	}
	if (props.classIconeInt !== undefined) {
		classDrapeauInt = props.classIconeInt;
	}
	
	if (props.drapeau === "int" || props.drapeau === "multi") {
		return <div className={classDrapeauInt}>🌍</div>;
	}
	
	const drapeaux = { es, en, de, fr };
	
	if (drapeaux[props.drapeau]) {
		return (
			<div className={classDrapeau}>
				<img src={drapeaux[props.drapeau]} alt={props.drapeau} />
			</div>
		);
	}
	
	
}
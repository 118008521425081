import React from "react";
import svg   from "../../../img/sprite_ruine.svg";

export default function SvgRuine(props: { icone: string, classRuine?: string }) {
	
	let classRuine = "ruineZoom";
	if (props.classRuine !== undefined) {
		classRuine = props.classRuine;
	}
	
	return <svg className={classRuine}>
		<use xlinkHref={svg + "#" + props.icone} />
	</svg>;
}
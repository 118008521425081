import React               from "react";
import { useRuineContext } from "./Ruine";
import RuineTraceMap       from "./RuineTraceMap";
import { useTranslation }  from "react-i18next";


export default function RuineMap(props: {
	modAriane: boolean,
	modMapping: boolean,
	onAddCoordFilAriane: (x: any, y: any, z: any) => void,
	showAriane: boolean,
	trad_porte: string[],
	modCorrectif: boolean,
}) {
	const { t } = useTranslation();
	const { plan, filAriane, x, setX, y, setY, z, setZ, selObjet, setPlan } = useRuineContext();
	
	
	const modAriane = props.modAriane;
	const modCorrectif = props.modCorrectif;
	const modMapping = props.modMapping;
	const showAriane = props.showAriane;
	const trad_porte = props.trad_porte;
	
	
	const handleClick = (xPos: number, yPos: number, zPos: number) => {
		if (modAriane) {
			props.onAddCoordFilAriane(xPos, yPos, zPos);
		} else if (modCorrectif) {
			// Si on est sur la porte d'entrée, on ne peut pas corriger
			if (zPos === 0 && xPos === 7 && yPos === 0) {
				return;
			}
			// Copie du plan
			const planMod = [...plan];
			// On applique la correction
			planMod[zPos][yPos][xPos].correction = !planMod[zPos][yPos][xPos].correction;
			// On met à jour le plan
			setPlan(planMod);
		} else {
			const typeCase = plan[zPos][yPos][xPos];
			if (typeCase.type_case <= 17 && typeCase.type_case !== 0) {
				setX(xPos);
				setY(yPos);
				setZ(zPos);
			}
		}
	};
	
	const nbEtage = plan.length;
	
	return <>
		<div id={"carteRuine"}>
			{(modMapping) ? (
				<React.Fragment>
					{nbEtage > 1 && <p className={"titre_etage_ruine"}>{t("Etage n°", { ns: "ville" }) + " " + z}</p>}
					<RuineTraceMap plan={plan} z_aff={z} z={z} x={x} y={y} handleClick={handleClick} trad_porte={trad_porte} />
				</React.Fragment>
			) : (
				Object.values(plan).map((niveau, z_tab) => {
					return <React.Fragment key={"etage_" + z_tab}>
						{z_tab > 0 && <hr style={{ width: "80%" }} />}
						{nbEtage > 1 && <p className={"titre_etage_ruine"}>{t("Etage n°", { ns: "ville" }) + " " + z_tab}</p>}
						<RuineTraceMap plan={plan} z_aff={z_tab} z={z} x={x} y={y} handleClick={handleClick} showAriane={showAriane} filAriane={filAriane}
									   trad_porte={trad_porte} selObjet={selObjet} />
					</React.Fragment>;
				})
			)}
		</div>
	</>;
	
}
import React                                                            from "react";
import { useTranslation }                                               from "react-i18next";
import Select                                                           from "react-select";
import TooltipGH                                                        from "../../../components/utils/TooltipGH";
import { Campeur, CampeurUpdate, InfoCase }                             from "../../../types/components/Outils/Camping.type";
import { CitoyenOption, CitoyenSingleValue, JobOption, JobSingleValue } from "./SelectCamperComponent";
import { JobPrototypeDTO }                                              from "../../../types/models/jobPrototype.dto";
import { CitoyensDTO }                                                  from "../../../types/models/citoyens.dto";

interface CampersListProps {
	campeurs: Campeur[];
	infoCase: InfoCase;
	onAddCampeur: () => void;
	onUpdateCampeur: (index: string, updates: CampeurUpdate) => void;
	onDeleteCampeur: (id: string) => void;
	onSwapCampers: (index1: number, index2: number) => void;
	customStyles: any;
}

export const CampersList = ({
	campeurs,
	infoCase,
	onAddCampeur,
	onUpdateCampeur,
	onDeleteCampeur,
	onSwapCampers,
	customStyles,
}: CampersListProps) => {
	const { t } = useTranslation();
	
	const renderSelectOptions = (index: number, campeur: Campeur) => {
		if (infoCase.mode === "identifie") {
			return (
				<Select
					value={campeur.type === "identifie" ? campeur.citoyen : null}
					options={Object.values(infoCase.citoyens)}
					components={{
						SingleValue: CitoyenSingleValue,
						Option     : CitoyenOption,
					}}
					onChange={(selected: CitoyensDTO) => {
						if (selected) {
							onUpdateCampeur(campeur.id, {
								type      : "identifie",
								citoyen   : selected,
								nb_camping: selected?.nb_camping ?? 0,
							});
						}
					}}
					styles={customStyles}
					getOptionLabel={(citoyen) => citoyen.citoyen.pseudo}
				/>
			);
		}
		
		return (
			<Select
				value={campeur.type === "anonyme" ? campeur.job : null}
				options={Object.values(infoCase.listJob)} // Add your job options here
				components={{
					SingleValue: JobSingleValue,
					Option     : JobOption,
				}}
				onChange={(selected: JobPrototypeDTO) => {
					if (selected) {
						onUpdateCampeur(campeur.id, {
							type      : "anonyme",
							job       : selected,
							lvl_reclus: (selected.id === 1 || selected.id === 2) ? 0 : 1,
						});
					}
				}}
				styles={customStyles}
				getOptionLabel={(job) => job.nom}
			/>
		);
	};
	
	return (
		<div id={`camping_campeur_${infoCase.mode}`}>
			<table>
				<thead>
				<tr>
					<th className="tbl_camping_ordre">{t("Ordre", { ns: "ville" })}</th>
					<th className="tbl_camping_campeur">
						<span className="d-flex gap-1 justify-content-center align-items-center">{t("Campeur", { ns: "ville" })}
							{infoCase.mode === "identifie" && <TooltipGH>
								<span className="infoBulle">
									<i className="fa-solid fa-circle-info" />
								</span>
								<span className="info">{t("Les campeurs en couleur rouge sont ceux qui ne sont pas considérés campeur pro", { ns: "ville" })}</span>
							</TooltipGH>}
						</span>
					</th>
					<th className="tbl_camping_nbr">{t("Nombre Camping", { ns: "ville" })}</th>
					{infoCase.mode === "anonyme" && <th className={"tbl_camping_cp"}>{t("Compétence Reclus", { ns: "ville" })}</th>}
					<th className={"tbl_camping_tombe"}>{t("Spécifique", { ns: "ville" })}</th>
					<th className="tbl_camping_tombe">{t("Tombe", { ns: "ville" })}</th>
					<th className="tbl_camping_odc">{t("Objet Camping", { ns: "ville" })}</th>
					<th className="tbl_camping_chance">{t("Chance de réussite", { ns: "ville" })}</th>
					<th className="tbl_camping_action" />
				</tr>
				</thead>
				<tbody>
				{campeurs.map((campeur, index) => (
					<CamperRow
						key={campeur.id}
						campeur={campeur}
						index={index}
						isLast={index === campeurs.length - 1}
						onUpdate={(updates) => onUpdateCampeur(campeur.id, updates)}
						onDelete={() => onDeleteCampeur(campeur.id)}
						onMoveUp={() => onSwapCampers(index, index - 1)}
						onMoveDown={() => onSwapCampers(index, index + 1)}
						renderSelect={() => renderSelectOptions(index, campeur)}
						mode={infoCase.mode}
					/>
				))}
				</tbody>
			</table>
			<button
				onClick={onAddCampeur}
				className="btn btn-sm btn-primary"
			>
				{t("Ajouter un campeur", { ns: "ville" })}
			</button>
		</div>
	);
};

interface CamperRowProps {
	campeur: Campeur;
	index: number;
	isLast: boolean;
	onUpdate: (updates: Partial<Campeur>) => void;
	onDelete: () => void;
	onMoveUp: () => void;
	onMoveDown: () => void;
	renderSelect: () => ReturnType<typeof Select>;
	mode: "anonyme" | "identifie";
}

const CamperRow = ({
	campeur,
	index,
	isLast,
	onUpdate,
	onDelete,
	onMoveUp,
	onMoveDown,
	renderSelect,
	mode,
}: CamperRowProps) => {
	const { t } = useTranslation();
	
	return (
		<tr>
			<td className="tbl_camping_ordre">{index + 1}</td>
			<td className="tbl_camping_campeur">{renderSelect()}</td>
			<td className="tbl_camping_nbr">
				<select
					value={campeur.nb_camping}
					onChange={(e) => onUpdate({ nb_camping: parseInt(e.target.value, 10) })}
				>
					{Array.from({ length: 11 }, (_, i) => (
						<option key={i} value={i}>{i}</option>
					))}
				</select>
			</td>
			{mode === "anonyme" && <td className={"tbl_camping_cp"}>
				<select value={campeur.lvl_reclus ?? ((campeur?.job?.id === 1 || campeur?.job?.id === 2) ? 0 : 1)} onChange={(e) => onUpdate({ lvl_reclus: parseInt(e.target.value, 10) })}>
					<option value={0}>{t("Non campeur pro", { ns: "outils" })}</option>
					<option value={1}>{t("Campeur pro (6 max)", { ns: "outils" })}</option>
					<option value={2}>{t("Campeur pro (8 max)", { ns: "outils" })}</option>
					<option value={4}>{t("Chance max 99%", { ns: "outils" })}</option>
				</select>
			</td>}
			<td className={"tbl_camping_tombe"}>
				{((campeur?.citoyen?.job?.id ?? null) === 4 || (campeur?.job?.id ?? null) === 4) && (<React.Fragment>
					<input
						type="checkbox"
						id={`capuche-${index}`}
						checked={campeur.capuche}
						onChange={(e) => onUpdate({ capuche: e.target.checked })}
					/>
					<TooltipGH>
						<span className={"infoBulle"}><i className="fa-solid fa-circle-info"></i></span>
						<span className={"info"}>{t("Capuche présente", { ns: "ville" })}</span>
					</TooltipGH>
				</React.Fragment>)}
			</td>
			<td className="tbl_camping_tombe">
				<input
					type="checkbox"
					checked={campeur.tombe}
					onChange={(e) => onUpdate({ tombe: e.target.checked })}
				/>
			</td>
			<td className="tbl_camping_odc">
				<select
					value={campeur.objet_camping}
					onChange={(e) => onUpdate({ objet_camping: parseInt(e.target.value, 10) })}
				>
					{Array.from({ length: 13 }, (_, i) => (
						<option key={i} value={i}>{i}</option>
					))}
				</select>
			</td>
			<td className="tbl_camping_chance">
				{campeur.chance_camping}%
			</td>
			<td className="tbl_camping_action">
				<div className="bouton_action_camping">
					<button onClick={onDelete} className="btn btn-xs btn-danger">
						<i className="fa-solid fa-trash" />
					</button>
					<button
						onClick={onMoveUp}
						disabled={index === 0}
						className="btn btn-xs btn-secondary"
					>
						<i className="fa-solid fa-circle-chevron-up" />
					</button>
					<button
						onClick={onMoveDown}
						disabled={isLast}
						className="btn btn-xs btn-secondary"
					>
						<i className="fa-solid fa-circle-chevron-down" />
					</button>
				</div>
			</td>
		</tr>
	);
};
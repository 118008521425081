import * as React                                 from "react";
import { useEffect, useState }                    from "react";
import axios                                      from "axios";
import LigneOrderChantier                         from "./LigneOrderChantier";
import { Status_error, Status_success, usePopUp } from "../../../types/Context/PopUpContext";
import { ChantierPrototypeDTO }                   from "../../../types/models/chantierPrototype.dto";
import { AdminApi }                               from "../../../services/api/AdminApi";

export const ArbreChantierList = () => {
	const [categories, setCategories] = useState([]);
	const [load, setLoad] = useState(false);
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	
	
	const fetchChantiers = async () => {
		try {
			const response = await axios.get("/rest/v1/admin/chantier_arbre");
			setCategories(Object.values(response.data));
		} catch (error) {
			console.error("Error fetching heros:", error);
		}
	};
	useEffect(() => {
		fetchChantiers().then(r => r);
		setLoad(true);
	}, []);
	
	const handleUpdateOrderBy = async () => {
		const adminApi = new AdminApi();
		adminApi.maj_OrderBy().then((response) => {
			if (response.codeRetour === 0) {
				setCategories(response.zoneRetour.categorie);
			}
		});
	};
	const handleUpdateArbreChantier = async () => {
		const adminApi = new AdminApi();
		adminApi.update_manual_orderBy({ categories: categories }).then((response) => {
			if (response.codeRetour === 0) {
				setStatus(Status_success);
				setMessagePopUp(response.libRetour);
				setShowPop(true);
				setTimeout(() => {
					setShowPop(false);
					setMessagePopUp("");
				}, 1000);
			} else {
				setStatus(Status_error);
				setMessagePopUp(response.libRetour);
				setShowPop(true);
			}
		});
	};
	
	const updateCategorie = (chantierMod: ChantierPrototypeDTO, index: number) => {
		const chantiers = [...categories];
		chantiers[index] = chantierMod;
		
		setCategories(chantiers);
	};
	
	return <div>
		{/* eslint-disable-next-line react/no-unescaped-entities */}
		<button onClick={handleUpdateArbreChantier} className={"btn btn-sm btn-primary"}>Sauvegarder l'arbre</button>
		<button onClick={handleUpdateOrderBy} className={"btn btn-sm btn-danger"}>Réinitialiser les ordres</button>
		<div>{load && categories.sort((a: ChantierPrototypeDTO, b: ChantierPrototypeDTO) => a.order_by_listing < b.order_by_listing ? -1 : 1).map((categorie, index) => <LigneOrderChantier chantier={categorie} onChantiers={(chantierMod) => updateCategorie(chantierMod, index)} niveau={0} key={"cate_" + index} index={index} />)}</div>
	</div>;
};
import React                                      from "react";
import SvgIcone                                   from "../../../components/generality/SvgIcone";
import ConfirmationDialog                         from "../../../components/generality/ComposantGeneral/ConfirmationDialog";
import { useCarteContext }                        from "../../../types/Context/CarteContext";
import { ExpeditionApi }                          from "../../../services/api/ExpeditionApi";
import { Status_error, Status_success, usePopUp } from "../../../types/Context/PopUpContext";
import { useGeneralContext }                      from "../../../types/Context/GeneralContext";
import { useExpeditionContext }                   from "../../../types/Context/Outils/Expedition/ExpeditionContext";
import Modal                                      from "react-bootstrap/Modal";
import AppercuExpeditions                         from "./AppercuExpeditions";
import AppercuCarte                               from "../../Hotel/Inscription/AppercuCarte";
import { InscriptionExpeditionApi }               from "../../../services/api/InscriptionExpeditionApi";
import { ExpeditionDTO }                          from "../../../types/models/expedition.dto";
import { ExpeditionnaireDTO }                     from "../../../types/models/expeditionnaire.dto";
import { ExpeditionPartDTO }                      from "../../../types/models/expeditionPart.dto";
import { UserDTO }                                from "../../../types/models/user.dto";
import { VilleDTO }                               from "../../../types/models/ville.dto";
import { ZoneMapDTO }                             from "../../../types/models/zoneMap.dto";
import { useTranslation }                         from "react-i18next";
import TooltipGH                                  from "../../../components/utils/TooltipGH";
import DraggableExpeditionList                    from "./DraggableExpeditionList";

export default function ListeExpeditions() {
	const { t } = useTranslation();
	const { expeditions, setExpeditions, setExpedition, setOngletActuel, refreshOutils, setOnDuplicate, verrouExpes } = useExpeditionContext();
	const { carte, jourActuel, setJourActuel } = useCarteContext();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const { general } = useGeneralContext();
	const [idExpSup, setIdExpSup] = React.useState<string>(""); // id de l'expédition à supprimer
	const [confirmationShow, setConfirmationShow] = React.useState<boolean>(false);
	const [modificationOrdre, setModificationOrdre] = React.useState<boolean>(false);
	const expeditionApi = new ExpeditionApi(carte.ville.map_id);
	const [showAppercu, setShowAppercu] = React.useState<boolean>(false);
	const [idExpView, setIdExpView] = React.useState<string>(null);
	const [showDayChoice, setShowDayChoice] = React.useState<boolean>(false);
	const [afficherAppercu, setAfficherAppercu] = React.useState<boolean>(false);
	const [zoneAppercu, setZoneAppercu] = React.useState<ZoneMapDTO[] | null>(null);
	const [traceAppercu, setTraceAppercu] = React.useState<number[]>([]);
	const [borne, setBorne] = React.useState<{ x_min: number, x_max: number, y_min: number, y_max: number }>({ x_min: null, x_max: null, y_min: null, y_max: null });
	const [ville, setVille] = React.useState<VilleDTO | null>(null);
	const [userOption, setUserOption] = React.useState<UserDTO | null>(null);
	const [idExpeAppercu, setIdExpeAppercu] = React.useState<string>("");
	// Swap entre les expéditions
	const swapExpedition = (index_expe_a: number, index_exp_b: number) => {
		const expeditionsMod = [...expeditions];
		const exp_a = expeditionsMod[index_expe_a];
		const exp_b = expeditionsMod[index_exp_b];
		// On échange les priorités
		const priorite = exp_a.priorite;
		exp_a.priorite = exp_b.priorite;
		exp_b.priorite = priorite;
		setModificationOrdre(true);
		setExpeditions(expeditionsMod);
	};
	
	// Suppresion de l'expédition ciblée
	const handleSuppression = () => {
		// On supprime l'expédition
		expeditionApi.deleteExpedition(
			{ map_id: carte.ville.map_id, id_user: general.user.id, jour: jourActuel, expedition_id: idExpSup }).then(() => {
			setMessagePopUp(t("Suppresion ok !", { ns: "ville" }));
			setShowPop(true);
			setStatus(Status_success);
			setExpeditions(expeditions.filter((expedition) => expedition.id !== idExpSup));
			setTimeout(() => {
				setMessagePopUp("");
				setShowPop(false);
			}, 2000);
			refreshOutils(jourActuel);
		}).catch((error) => {
			console.error(error);
			setMessagePopUp(error?.data?.error ?? error?.message);
			setShowPop(true);
			setStatus(Status_error);
		});
	};
	
	// Confirmation que l'expédidition est supprimé
	const handleConfirmSuppression = () => {
		setConfirmationShow(false);
		handleSuppression();
	};
	
	// Annulation de la suppression
	const handleCancelSuppression = () => {
		setConfirmationShow(false);
		setIdExpSup("");
	};
	
	// Mise à jour du statut de l'expedition
	const handleStatutExpedition = (expedition: ExpeditionDTO) => {
		if (jourActuel >= general.ville.jour) {
			expeditionApi.changeStatutExpedition({
				map_id       : carte.ville.map_id,
				id_user      : general.user.id,
				jour         : jourActuel,
				expedition_id: expedition.id,
				statut       : !expedition.expedition_parts[0].ouverte,
			}).then(() => {
				// on met à jour visuellement le temps de faire le refresh
				const expeditionsMod = [...expeditions];
				const index = expeditionsMod.findIndex((exp) => exp.id === expedition.id);
				expeditionsMod[index].expedition_parts[0].ouverte = !expedition.expedition_parts[0].ouverte;
				setExpeditions(expeditionsMod);
			}).catch((error) => {
				console.error(error);
				setMessagePopUp(error?.data?.error ?? error?.message);
				setShowPop(true);
				setStatus(Status_error);
			});
		}
	};
	
	// Mise à jour de l'ordre des expeditions
	const handleSauvegardeOrdre = () => {
		expeditionApi.changeOrderExpedition({
			map_id     : carte.ville.map_id,
			id_user    : general.user.id,
			jour       : jourActuel,
			expeditions: expeditions,
		}).then(() => {
			setMessagePopUp(t("Mise à jour ok !", { ns: "ville" }));
			setShowPop(true);
			setStatus(Status_success);
			setModificationOrdre(false);
			setTimeout(() => {
				setMessagePopUp("");
				setShowPop(false);
			}, 2000);
			refreshOutils(jourActuel);
		}).catch((error) => {
			console.error(error);
			setMessagePopUp(error?.data?.error ?? error?.message);
			setShowPop(true);
			setStatus(Status_error);
		});
	};
	
	// Fonction pour alimenter l'expédition à dupliquer en retirant les tracés d'expédition associée et en vidant les expéditionnaires uniquement les citoyens, il faut faire un clone de l'expédition
	const handleDuplicateExpedition = (expedition: ExpeditionDTO): void => {
		const expeditionDup = JSON.parse(JSON.stringify(expedition));
		expeditionDup.id = null;
		expeditionDup.expedition_parts.forEach((part: ExpeditionPartDTO) => {
			part.id = null;
			part.trace = null;
			part.expeditionnaires.forEach((exped: ExpeditionnaireDTO) => {
				exped.citoyen = null;
				exped.id = null;
				exped.sac.forEach((sac) => {
					sac.id = null;
					sac.expeditionnaire = null;
				});
				exped.dispo.forEach((dispo) => {
					dispo.id = null;
					dispo.dispo = null;
				});
			});
			part.consignes = [];
		});
		setExpedition(expeditionDup);
		setOngletActuel("gestion");
	};
	// Fonction pour alimenter l'expédition à dupliquer en retirant les tracés d'expédition associée et en vidant les expéditionnaires uniquement les citoyens, il faut faire un clone de l'expédition
	const handleDuplicateExpeditionAnotherDay = (expedition: ExpeditionDTO, day: number): void => {
		setOnDuplicate(true);
		const expeditionDup = JSON.parse(JSON.stringify(expedition));
		expeditionDup.id = null;
		expeditionDup.expedition_parts.forEach((part: ExpeditionPartDTO) => {
			part.id = null;
			part.trace = null;
			part.expeditionnaires.forEach((exped: ExpeditionnaireDTO) => {
				exped.citoyen = null;
				exped.id = null;
				exped.sac.forEach((sac) => {
					sac.id = null;
					sac.expeditionnaire = null;
				});
				exped.dispo.forEach((dispo) => {
					dispo.id = null;
					dispo.dispo = null;
				});
			});
			part.consignes = [];
		});
		setExpedition(expeditionDup);
		setJourActuel(day);
	};
	
	// Edition de l'expédition
	const handleEditExpedition = (expe: ExpeditionDTO) => {
		// On va accéder à l'expédition afin d'avoir une version mise à jour et de controler si elle n'est pas verrouillée.
		expeditionApi.getExpedition({ map_id: carte.ville.map_id, id_user: general.user.id, jour: jourActuel, expedition_id: expe.id }).then((response) => {
			if (response.data.expedition) {
				// On va maintenant procéder au vérouillage de l'expédition
				expeditionApi.verouillageExpedition({ map_id: carte.ville.map_id, id_user: general.user.id, jour: jourActuel, expedition_id: expe.id, verrouillage: true }).then(() => {
					setExpedition(response.data.expedition);
					setOngletActuel("gestion");
				}).catch((error) => {
					setMessagePopUp(error?.data?.error ?? error?.message);
					setShowPop(true);
					setStatus(Status_error);
				});
			}
		}).catch((error) => {
			setMessagePopUp(error?.data?.error ?? error?.message);
			setShowPop(true);
			setStatus(Status_error);
		});
	};
	// View expédition
	const handleViewExpedition = (expe: ExpeditionDTO) => {
		setShowAppercu(true);
		setIdExpView(expe.id);
	};
	
	const calculExpeditionnaire = () => {
		let total = 0;
		expeditions.forEach((expedition) => {
			total += expedition.expedition_parts[0].expeditionnaires.length;
		});
		return total;
	};
	
	const recuperationPseudoPresinscrit = (expedition: ExpeditionDTO) => {
		return expedition.expedition_parts[0].expeditionnaires.filter((exped) => exped.preinscrit).sort((expedi_a, expedi_b) => {
			return expedi_a.citoyen.citoyen.pseudo.localeCompare(expedi_b.citoyen.citoyen.pseudo);
		})?.map((exped: ExpeditionnaireDTO) => {
			if (exped.citoyen !== null) {
				return exped.citoyen;
			}
		});
	};
	
	const handleAppercuTrace = (id_trace: string) => {
		const inscriptionApi = new InscriptionExpeditionApi(general.ville.map_id ?? 0);
		
		inscriptionApi.appercuTrace(id_trace).then((response) => {
			setIdExpeAppercu(id_trace);
			setZoneAppercu(response.data.appercu.zones);
			setTraceAppercu(response.data.appercu.trace);
			setVille(response.data.appercu.ville);
			setUserOption(response.data.appercu.user);
			setBorne({ x_min: response.data.appercu.x_min, x_max: response.data.appercu.x_max, y_min: response.data.appercu.y_min, y_max: response.data.appercu.y_max });
			setAfficherAppercu(true);
		}).catch((error) => {
			setStatus(Status_error);
			setMessagePopUp(error?.data?.error ?? error.message);
			setShowPop(true);
		});
	};
	
	return <fieldset>
		<legend>{t("Expéditions", { ns: "ville" })} - {expeditions.length} - {t("{nombre} expéditionnaire(s)", { ns: "ville" }).replace("{nombre}", calculExpeditionnaire().toString())}</legend>
		{expeditions.length > 0 && <table id={"expeditions"}>
			<thead>
			<tr>
				<th>{t("Nom", { ns: "ville" })}</th>
				<th className={"sm_cols"}>{t("Type", { ns: "ville" })}</th>
				<th className={"sm_cols"}>{t("Nombre partie", { ns: "ville" })}</th>
				<th className={"sm_cols"}>
					<TooltipGH>
                        <span className={"infoBulle d-flex gap-1 align-items-center justify-content-center"}>
                            <SvgIcone icone={"h_pa"} />
                        </span>
						<span className={"info"}>{t("Total PA", { ns: "ville" })}</span>
					</TooltipGH>
				</th>
				<th className={"sm_cols"}>
					<TooltipGH>
                        <span className={"infoBulle d-flex gap-1 align-items-center justify-content-center"}>
                            <SvgIcone icone={"r_surgrp"} />
                        </span>
						<span className={"info"}>{t("Nombre expéditionnaire", { ns: "ville" })}</span>
					</TooltipGH>
				</th>
				<th className={"small_cols"}>{t("Pré-inscrit", { ns: "ville" })}</th>
				<th className={"small_cols"}>{t("Créateur", { ns: "ville" })}</th>
				<th className={"small_cols"}>{t("Modificateur", { ns: "ville" })}</th>
				<th className={"small_cols"}>{t("Statut", { ns: "ville" })}</th>
				<th className={"medium_cols"}>{t("Action", { ns: "ville" })}</th>
			</tr>
			</thead>
			<DraggableExpeditionList
				expeditions={expeditions}
				onReorder={(newOrder: ExpeditionDTO[]) => {
					setExpeditions(newOrder);
					setModificationOrdre(true);
				}}
				jourActuel={jourActuel}
				general={general}
				verrouExpes={verrouExpes}
				onViewExpedition={handleViewExpedition}
				onEditExpedition={handleEditExpedition}
				onDuplicateExpedition={handleDuplicateExpedition}
				onDeleteExpedition={(id) => {
					setIdExpSup(id);
					setConfirmationShow(true);
				}}
				onSwapExpedition={swapExpedition}
				onDuplicateAnotherDay={(expedition: ExpeditionDTO) => {
					setShowDayChoice(true);
					setIdExpView(expedition.id);
				}}
				onStatusChange={handleStatutExpedition}
				t={t}
			/>
		</table>}
		{modificationOrdre && <button className={"btn btn-primary"} onClick={handleSauvegardeOrdre}>{t("Sauvegarder l'ordre", { ns: "ville" })}</button>}
		<ConfirmationDialog
			message={t("Êtes-vous sûr de supprimer définitivement cette expédition ?", { ns: "ville" })}
			titre={t("Confirmation de suppression", { ns: "ville" })}
			btnAnnuler={t("Annuler", { ns: "ville" })}
			btnConfirmation={t("Confirmer", { ns: "ville" })}
			show={confirmationShow}
			onConfirm={handleConfirmSuppression}
			onCancel={handleCancelSuppression}
		/>
		<Modal show={showAppercu} onHide={() => {
			setShowAppercu(false);
			setIdExpView(null);
		}} centered key={"modal_appercu_expedition"}>
			{showAppercu && <AppercuExpeditions id_exp={idExpView} onClose={() => {
				setShowAppercu(false);
				setIdExpView(null);
			}} onViewExpedition={(idTrace) => handleAppercuTrace(idTrace)} />}
		</Modal>
		<Modal show={showDayChoice} onHide={() => {
			setShowDayChoice(false);
		}} centered key={"modal_choice_day"}>
			{showDayChoice && <div id={"modal_ducplicate_day"}>
				<label htmlFor={"day_choice"}>{t("Dupliquer l'expédition pour :", { ns: "ville" })}</label>
				<select id={"day_choice"} onChange={(e) => {
					if (parseInt(e.target.value) === 0) {
						return;
					}
					handleDuplicateExpeditionAnotherDay(expeditions.find((exp) => exp.id === idExpView), parseInt(e.target.value));
					setShowDayChoice(false);
					setIdExpView(null);
				}}>
					<option value={0}>{t("choix jour", { ns: "ville" })}</option>
					{Array.from({ length: general.ville.jour - jourActuel + 3 }, (_, i) => i + jourActuel + 1).map((jour) => {
						return <option key={"jour_" + jour} value={jour}>{t("jour", { ns: "ville" }) + jour}</option>;
					})}
				</select>
			</div>}
		</Modal>
		<Modal show={afficherAppercu} onHide={() => {
			setAfficherAppercu(false);
		}} centered key={"modal_appercu"}>
			{afficherAppercu && <div className={"modal-centralisation"}>
				<div id={"modal_appercu"} style={{ width: `${25 * (borne.x_max - borne.x_min + 1) + 50}px` }}>
					<h2>{t("Aperçu du tracé", { ns: "hotel" })}</h2>
					<AppercuCarte trace={traceAppercu} ville={ville} x_max={borne.x_max} y_max={borne.y_max} x_min={borne.x_min} y_min={borne.y_min} zones={zoneAppercu} user={userOption} idTrace={idExpeAppercu} />
					<button className={"btn btn-primary btn-xs"} onClick={() => {
						setAfficherAppercu(false);
						setIdExpeAppercu("");
						setZoneAppercu(null);
						setTraceAppercu([]);
						setBorne({ x_min: null, x_max: null, y_min: null, y_max: null });
						setVille(null);
						setUserOption(null);
					}}>{t("Fermer", { ns: "ville" })}</button>
				</div>
			</div>}
		</Modal>
	</fieldset>;
	
}


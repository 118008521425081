import React, { useEffect, useState }             from "react";
import Modal                                      from "react-bootstrap/Modal";
import Button                                     from "react-bootstrap/Button";
import { FieldControlType }                       from "../../../types/components/Jump/InscriptionJump.type";
import InscriptionForm                            from "../Inscription/InscriptionForm";
import { CandidatureJumpPropsType }               from "../../../types/components/Jump/CandidatureJump.type";
import { GestionJumpApi }                         from "../../../services/api/GestionJumpApi";
import { formatInTimeZone }                       from "date-fns-tz";
import HistoriqueModification                     from "../Inscription/HistoriqueModification";
import { Link }                                   from "react-router";
import { useGeneralContext }                      from "../../../types/Context/GeneralContext";
import { Status_error, Status_success, usePopUp } from "../../../types/Context/PopUpContext";
import { useTranslation }                         from "react-i18next";

export default function Candidature({ inscriptionProps, isArchMode = false }: { inscriptionProps: CandidatureJumpPropsType, isArchMode?: boolean }) {
	const { t } = useTranslation();
	const { general } = useGeneralContext();
	const options = inscriptionProps.options;
	const jump = inscriptionProps.jump;
	const user = inscriptionProps.user;
	const gestionApi = new GestionJumpApi();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	
	const [inscription, setInscription] = useState(inscriptionProps.inscription);
	
	const [messagePopUpListErreur, setMessagePopUpListErreur] = useState(null);
	const [showModListErreur, setShowModListErreur] = useState(false);
	const [fieldErrors] = useState<FieldControlType>({});
	
	useEffect(() => {
		setInscription(inscriptionProps.inscription);
	}, [inscriptionProps]);
	
	const onRetour = (isErrorGestion: boolean, showModGestion: boolean, messagePopGestion: string) => {
		setStatus(isErrorGestion ? Status_error : Status_success);
		setShowPop(showModGestion);
		setMessagePopUp(messagePopGestion);
		if (!isErrorGestion) {
			setTimeout(() => {
				setShowPop(false);
				setMessagePopUp("");
			}, 1500);
		}
	};
	
	const handleClose = () => {
		setMessagePopUpListErreur(null);
		setShowModListErreur(false);
	};
	
	const handleModifJump = () => {
		gestionApi.gestion_candidature_jump({ userId: user.id, jumpId: jump.id, inscription: inscription }).then((response) => {
			if (response.codeRetour === 0) {
				onRetour(false, true, response.libRetour);
				setTimeout(() => {
					onRetour(false, false, "");
				}, 2000);
				setInscription(response.zoneRetour.inscription);
			} else if (response.codeRetour === 2) {
				const retourErreur = <>
					<span>{response.libRetour}</span>
					<ul>{Object.values(response.zoneRetour.libErreur).map((erreur, index) => {
						return <li key={"erreur_" + index}>{erreur}</li>;
					})}</ul>
				</>;
				setShowModListErreur(true);
				setMessagePopUpListErreur(retourErreur);
			} else {
				onRetour(true, true, response.libRetour);
			}
		});
	};
	
	const handleChangeStatut = (e) => {
		const statutId = parseInt(e.target.value, 10);
		const statut = options.listStatut.find((statut) => statut.id === statutId);
		const inscriptionMod = { ...inscription, ...{ statut: statut } };
		setInscription(inscriptionMod);
	};
	
	
	return <div className="jumpCorps">
		<div id="feuilleInscription" className="fondWhite02">
			<div id="recapJumpInscription">
				<span id="titre_candidature"><strong>{t("Candidature de {inscripuser}", { ns: "jumpEvent" }).replace("{inscripuser}", inscription.user.pseudo)}</strong></span>
				<span><strong>{t("Date d'inscription :", { ns: "jumpEvent" })}</strong>&nbsp;{formatInTimeZone(new Date(inscription.date_inscription), general.fuseau,
					t("dd / MM / yyyy à H:mm", { ns: "app" }))}</span>
				{inscription.date_modification !== null &&
					<span><strong>{t("Date derniere modification :", { ns: "jumpEvent" })}</strong>&nbsp;{formatInTimeZone(new Date(inscription.date_modification), general.fuseau,
						t("dd / MM / yyyy à H:mm", { ns: "app" }))}</span>}
				<span><strong>{t("Statut de la candidature :", { ns: "jumpEvent" })}</strong> {inscription.statut.nom_gestion}</span>
			</div>
			<div id="formInscriptionJump">
				<InscriptionForm inscriptionProps={inscription}
								 options={options}
								 jump={jump}
								 user={user}
								 fieldControl={fieldErrors}
								 isOrga={true}
								 onRetour={setInscription}
								 isArchMode={isArchMode}
				/>
				<fieldset>
					{!isArchMode && <>
						<legend>Gestion de la candidature</legend>
						<div>
							<label>Modifier le statut de la candidature : </label>
							<select value={inscription.statut.id}
									onChange={(event) => handleChangeStatut(event)}
							>
								{Object.values(options.listStatut).map((statut) => {
									return <option value={statut.id} key={"option_statut_" + statut.id}>{statut.nom_gestion_court}</option>;
								})}
							</select>
						</div>
					</>}
					<div id="boutonInscription">
						{!isArchMode && <button type={"button"} className={"btn btn-success btn-sm"} onClick={handleModifJump}>{t("Enregistrer", { ns: "jumpEvent" })}</button>}
						{!isArchMode && <Link to={"/jump/gestion/" + jump.id} style={{ textDecoration: "none", color: "inherit" }}>
							<button type={"button"} className={"btn btn-secondary btn-lg"}>{t("Retour à la gestion du jump", { ns: "jumpEvent" })}</button>
						</Link>}
						{isArchMode && <Link to={"/jump/gestion/archive/" + jump.id} style={{ textDecoration: "none", color: "inherit" }}>
							<button type={"button"} className={"btn btn-secondary btn-lg"}>{t("Retour à l'archive du jump", { ns: "jumpEvent" })}</button>
						</Link>}
					</div>
				</fieldset>
			</div>
			<HistoriqueModification inscription={inscription} options={options} user={user} inGestion={true} />
		</div>
		<Modal show={showModListErreur} onHide={handleClose} centered={true}>
			<Modal.Header className={"modal-header-error"}>
				<Modal.Title>{t("Erreur !", { ns: "app" })}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{messagePopUpListErreur}</Modal.Body>
			{<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>{t("Fermer", { ns: "app" })}</Button>
			</Modal.Footer>}
		</Modal>
	</div>;
}

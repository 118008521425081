import React, { useEffect, useState } from "react";
import TriangleSort                   from "../../../components/generality/ComposantGeneral/TriangleSort";
import { ItemPrototypeDTO }           from "../../../types/models/itemPrototype.dto";
import SvgIcone                       from "../../../components/generality/SvgIcone";
import { EncyclopedieObjetType }      from "../../../types/components/Encyclopedie/EncyclopedieObjet.type";
import { useTranslation }             from "react-i18next";
import { useNormalizedSearch }        from "../../../services/hook/useNormalizedSearch";
import TooltipGH                      from "../../../components/utils/TooltipGH";
import TranslationsDisplay            from "../../../components/utils/TranslationsDisplay";

export default function ListWeapons({ objets, search_objet, listObjetsPrototype, isTrad, lng }: { objets: EncyclopedieObjetType, search_objet: string, listObjetsPrototype: ItemPrototypeDTO[], isTrad: boolean, lng: string }) {
	const { t } = useTranslation();
	const [listWeapons, setListWeapons] = useState(objets.listWeapons);
	const [sortedColumnW, setSortedColumnW] = useState("");
	const [sortOrderW, setSortOrderW] = useState("");
	const { normalizeSearch } = useNormalizedSearch();
	
	useEffect(() => {
		setListWeapons(objets.listWeapons);
	}, [objets]);
	
	const filterLists = (listObjetsPrototype: ItemPrototypeDTO[]) => {
		const filteredWeapons = Object.values(objets.listWeapons).filter(
			(weapon) => normalizeSearch(search_objet.trim(), t(listObjetsPrototype[weapon.id ?? 0]?.nom ?? "", { ns: "items" })),
		);
		setListWeapons(filteredWeapons);
	};
	
	useEffect(() => {
		filterLists(listObjetsPrototype);
	}, [search_objet]);
	
	const getSortIndicatorClassWeapons = (column: string) => {
		if (column === sortedColumnW) {
			return sortOrderW === "asc" ? "asc" : "desc";
		} else {
			return "default"; // Retourne une chaîne vide si la colonne n'est pas triée
		}
	};
	const handleSortDataWeapons = (column: string) => {
		// Si la colonne cliquée est la même que la colonne triée précédemment,
		// on inverse l'ordre de tri.
		const order = column === sortedColumnW && sortOrderW === "asc" ? "desc" : (sortOrderW === "desc" ? "default" : "asc");
		
		if (order === "default") {
			column = "";
		}
		
		// On met à jour l'état avec la colonne actuellement triée et l'ordre de tri.
		setSortOrderW(order);
		setSortedColumnW(column);
	};
	const sortedListsWeapons = (listObjetsPrototype: ItemPrototypeDTO[]) => {
		const sortFunctions = {
			"kill"    : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => (a.kill_max ?? a.kill_min ?? 0) - (b.kill_max ?? b.kill_min ?? 0),
			"reussite": (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => (a.chance_kill ?? 0) - (b.chance_kill ?? 0),
			"casse"   : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => (a.chance ?? 0) - (b.chance ?? 0),
			"avg"     : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => (a.avg_kill ?? 0) - (b.avg_kill ?? 0),
			"default" : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => t(listObjetsPrototype[a.id ?? 0]?.nom ?? "", { ns: "items" }).localeCompare(t(listObjetsPrototype[b.id ?? 0]?.nom ?? "", { ns: "items" })),
		};
		const sortFunction = sortFunctions[sortedColumnW] || sortFunctions["default"];
		return Object.values(listWeapons).sort((a, b) => {
			const result = sortFunction(a, b);
			return sortOrderW === "asc" ? -result : result;
		});
	};
	const weaponsTri: ItemPrototypeDTO[] = sortedListsWeapons(objets.listObjets);
	
	
	return (
		<div id="ency_weapons">
			<table>
				<thead>
				<tr>
					<th className="tab_ency_weapons_icone">{t("Image", { ns: "ency" })}</th>
					<th className="tab_ency_weapons_nom">{t("Nom", { ns: "ency" })}</th>
					<th className="tab_ency_weapons_kill">
						<div className={"entete_tri"} onClick={() => handleSortDataWeapons("kill")}>
							<div id={"entete_ency_weapons_kill"}>{t("Nombre de kill", { ns: "ency" })}</div>
							<TriangleSort direction={getSortIndicatorClassWeapons("kill")} />
						</div>
					</th>
					<th className="tab_ency_weapons_pct_kill">
						<div className={"entete_tri"} onClick={() => handleSortDataWeapons("reussite")}>
							<div id={"entete_ency_weapons_reussite"}>{t("% de réussite des kills", { ns: "ency" })}</div>
							<TriangleSort direction={getSortIndicatorClassWeapons("reussite")} />
						</div>
					</th>
					<th className="tab_ency_weapons_pct_cass">
						<div className={"entete_tri"} onClick={() => handleSortDataWeapons("casse")}>
							<div id={"entete_ency_weapons_casse"}>{t("% de casse ou épuisement", { ns: "ency" })}</div>
							<TriangleSort direction={getSortIndicatorClassWeapons("casse")} />
						</div>
					</th>
					<th className="tab_ency_weapons_kill_avg">
						<div className={"entete_tri"} onClick={() => handleSortDataWeapons("avg")}>
							<div id={"entete_ency_weapons_kill_avg"}>{t("Nombre de kill moyen", { ns: "ency" })}</div>
							<TriangleSort direction={getSortIndicatorClassWeapons("avg")} />
						</div>
					</th>
					<th className="tab_ency_weapons_recup">{t("devient", { ns: "ency" })}</th>
				</tr>
				</thead>
				<tbody>
				{weaponsTri.map((item) => {
					
					return <tr className={"ligne_ency_weapons"} key={"weapons_" + item.id}>
						<td className={"tab_ency_weapons_icone"}>
							<div className={"d-flex gap-1 align-items-center justify-content-center"}><SvgIcone icone={listObjetsPrototype[item.id ?? 0]?.icon ?? ""} /></div>
						</td>
						<td className="tab_ency_weapons_nom">{isTrad ? <TranslationsDisplay translationKey={listObjetsPrototype[item.id ?? 0]?.nom ?? ""} namespace={"items"} userLang={lng} /> : t(listObjetsPrototype[item.id ?? 0]?.nom ?? "", { ns: "items" })}</td>
						{item.kill_max !== undefined && item.kill_max !== null && <td className="tab_ency_weapons_kill">
							<div>
								<span>{t("{killMin} à {killMax}", { ns: "ency" }).replace("{killMin}", (item.kill_min ?? 0).toString()).replace("{killMax}", item.kill_max.toString())}</span>
								<SvgIcone icone={"h_zombie"} />
							</div>
						</td>}
						{item.kill_max === null && <td className="tab_ency_weapons_kill">
							<div>
								<span>{item.kill_min}</span>
								<SvgIcone icone={"h_zombie"} />
							</div>
						</td>}
						<td className="tab_ency_weapons_pct_kill">{item.chance_kill} %</td>
						<td className="tab_ency_weapons_pct_cass">{item.chance} %</td>
						<td className="tab_ency_weapons_pct_cass">{Math.round((item.avg_kill ?? 0) * 1000) / 1000}</td>
						<td className="tab_ency_weapons_recup">
                                    <span className={"weapons_recup"}>
                                        {(item.item_result ?? []).length > 0 && item.type === 1 && Object.values(item.item_result ?? []).map((item_res, index) => {
											return <React.Fragment key={"item_result_" + index + "_" + item.id}>{index > 0 &&
												<span className={"ency_space"}>{t("ou", { ns: "app" })}</span>}
												<TooltipGH>
                                                    <span className="infoBulle">
                                                        <SvgIcone icone={listObjetsPrototype[item_res.id ?? 0]?.icon ?? ""} />
                                                    </span>
													<span className="info">{t(listObjetsPrototype[item_res.id ?? 0]?.nom ?? "", { ns: "items" })}</span>
												</TooltipGH>
											</React.Fragment>;
										})}
										{item.type === 2 && <span className="videImg2">
                                            <TooltipGH>
                                                <span className="infoBulle">
                                                    <SvgIcone icone={listObjetsPrototype[item.id ?? 0]?.icon ?? ""} />
                                                </span>
                                                <span className="info">{t(listObjetsPrototype[item.id ?? 0]?.nom ?? "", { ns: "items" })} {t("cassé(e)", { ns: "items" })}</span>
                                            </TooltipGH>
                                        </span>}
										{(item.type !== 2 && item.type !== 1 && (item.item_result ?? []).length === 0) && <TooltipGH>
                                            <span className="infoBulle">
                                                <i className="fa-solid fa-xmark color-red"></i>
                                            </span>
											<span className="info">{t(listObjetsPrototype[item.id ?? 0]?.nom ?? "", { ns: "items" })} {t("disparu(e)", { ns: "items" })}</span>
										</TooltipGH>}
                                    </span>
						</td>
					</tr>;
				})}
				</tbody>
			</table>
		</div>
	);
}





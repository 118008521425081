import React from "react";

interface ScrollLinkProps {
	targetId: string;
	offset?: number;
	children: React.ReactNode;
}

export default function ScrollLink({ targetId, offset = -60, children }: ScrollLinkProps) {
	
	function handleClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
		e.preventDefault();
		const element = document.getElementById(targetId);
		if (element) {
			const yOffset = offset; // Offset configurable, par défaut -60px
			const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
			window.scrollTo({ top: y, behavior: "smooth" });
		}
	}
	
	return (
		<a href={`#${targetId}`} onClick={handleClick}>
			{children}
		</a>
	);
}
import React, { useEffect, useState } from "react";
import chargement                     from "../../../img/chargement.svg";
import { EncyclopedieApi }            from "../../services/api/EncyclopedieApi";
import EncyCitoyens                   from "../../containers/Encyclopedie/EncyCitoyens";
import { useNavigate }                from "react-router";
import { useGeneralContext }          from "../../types/Context/GeneralContext";
import { useGHContext }               from "../../types/Context/GHContext";
import { useTranslation }             from "react-i18next";

export function EncyCitoyensGeneral() {
	const { general, setGeneral } = useGeneralContext();
	const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [encyCitoyensData, setEncyCitoyensData] = useState(null);
	
	// Fonction pour recharger les données
	const reloadData = async () => {
		const encyclopedieBatApi = new EncyclopedieApi(parseInt(sessionStorage.getItem("mapId") ?? "0", 10));
		
		encyclopedieBatApi.main_citoyens().then((response) => {
			setIsOnRefresh(false);
			setIsOnUpdate(false);
			if (response.codeRetour === 0) {
				if (response.zoneRetour.general !== undefined) {
					setGeneral(response.zoneRetour.general);
					sessionStorage.setItem("mapId", response.zoneRetour.general.ville ? response.zoneRetour.general.ville.map_id.toString() : null);
				}
				if (response.zoneRetour.citoyens === undefined) {
					console.error("Erreur de chargement des données citoyens", response);
				} else {
					setEncyCitoyensData(response.zoneRetour.citoyens);
				}
			}
		});
	};
	
	useEffect(() => {
		if (checkHabilitation("encyclopedie", "citoyens")) {
			reloadData().then(r => r);
		} else {
			navigate("/news");
		}
	}, [refreshKey]);
	
	return (
		<>
			{encyCitoyensData === null ? (
				<div className={"chargement_page"}>
					<img src={chargement} alt="Drapeau" />
					<span>{t("Chargement...", { ns: "app" })}</span>
				</div>
			) : (
				<EncyCitoyens citoyens={encyCitoyensData} />
			)}
		
		</>
	);
}
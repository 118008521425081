import * as React                                                                                                                                                                                     from "react";
import { ArrayInput, BooleanField, BooleanInput, Datagrid, Edit, EditButton, List, NumberField, NumberInput, ReferenceInput, SimpleForm, SimpleFormIterator, TextField, TextInput, useRecordContext } from "react-admin";
import { Status_error, Status_success, usePopUp }                                                                                                                                                     from "../../types/Context/PopUpContext";
import { AdminApi }                                                                                                                                                                                   from "../../services/api/AdminApi";


const MajJson = () => {
	const adminApi = new AdminApi();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const handleUpdate = () => {
		adminApi.maj_pictos_json().then((response) => {
			setStatus(Status_success);
			setMessagePopUp(response.data.libRetour);
			setShowPop(true);
			setTimeout(() => {
				setShowPop(false);
				setMessagePopUp("");
			}, 1000);
		}).catch((error) => {
			setStatus(Status_error);
			setMessagePopUp(error?.data?.error ?? error?.message ?? "Erreur inconnue");
			setShowPop(true);
		});
	};
	return (
		<button className={"btn btn-primary"} onClick={handleUpdate}>
			Mettre à jour le fichier JSON
		</button>
	);
};

export const PictoList = (props) => {
	const postFilters = [
		<TextInput source="q" label="Search" alwaysOn key={"search_items"} />,
		<ReferenceInput source="id" label="Item" reference="items" key={"search_items_id"} />,
	];
	
	return <>
		<MajJson />
		<List {...props} filters={postFilters}>
			<Datagrid>
				<NumberField source="id" />
				<TextField source="name" />
				<BooleanField source={"actif"} />
				<EditButton />
			</Datagrid>
		</List>
	
	</>;
};

export const PictoEdit = (props) => {
	
	const PostTitle = () => {
		const record = useRecordContext();
		return <span>Edition de {record ? `"${record.name}"` : ""}</span>;
	};
	
	return (<Edit {...props} title={<PostTitle />}>
		<SimpleForm>
			<div id={"chantier_form_prototype"}>
				<NumberInput source={"id"} label={"Id"} disabled={true} />
				<TextInput source={"name"} label={"Name"} />
				<TextInput source={"description"} label={"Description"} multiline fullWidth />
				<BooleanInput source={"rare"} label={"Rare"} />
				<BooleanInput source={"community"} label={"Community"} />
				<TextInput source={"img"} label={"Image"} />
				<TextInput source={"uuid"} label={"Uuid"} />
				<NumberInput source={"id_mh"} label={"Id MH"} />
				<BooleanInput source={"actif"} label={"Actif"} />
			</div>
			<div id={"chantier_form_prototype_ressources"}>
				<ArrayInput name={"titre"} source={"titre"}>
					<SimpleFormIterator inline>
						<NumberInput source={"id"} label={"Id"} />
						<TextInput source={"titre"} label={"Titre"} />
						<NumberInput source={"nbr"} label={"Nbr"} />
					</SimpleFormIterator>
				</ArrayInput>
			</div>
		</SimpleForm>
	</Edit>);
};
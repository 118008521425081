import React, { useEffect, useState } from "react";
import chargement                     from "../../../img/chargement.svg";
import { InscriptionJumpApi }         from "../../services/api/InscriptionJumpApi";
import ListJumpEvent                  from "../../containers/Jump/Inscription/ListJumpEvent";
import { useNavigate }                from "react-router";
import { useGeneralContext }          from "../../types/Context/GeneralContext";
import { useGHContext }               from "../../types/Context/GHContext";
import { Status_error, usePopUp }     from "../../types/Context/PopUpContext";
import { useTranslation }             from "react-i18next";

export function ListJumpEventGeneral() {
	const { general, setGeneral } = useGeneralContext();
	const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const navigate = useNavigate();
	const { t } = useTranslation();
	
	const [data, setData] = useState(null);
	
	// Fonction pour recharger les données
	const reloadData = async () => {
		const inscriptionApi = new InscriptionJumpApi(parseInt(sessionStorage.getItem("mapId") ?? "0", 10));
		
		inscriptionApi.main().then((response) => {
			setIsOnRefresh(false);
			setIsOnUpdate(false);
			if (response.codeRetour === 0) {
				if (response.zoneRetour.general !== undefined) {
					setGeneral(response.zoneRetour.general);
					sessionStorage.setItem("mapId", response.zoneRetour.general.ville ? response.zoneRetour.general.ville.map_id.toString() : null);
				}
				if (response.zoneRetour.listJumpEvent === undefined) {
					console.error("Erreur de chargement des données inscription list jump in event", response);
				} else {
					setData(response.zoneRetour.listJumpEvent);
				}
			} else if (response.codeRetour === 1) {
				setStatus(Status_error);
				setShowPop(true);
				setMessagePopUp(response.libRetour);
				navigate("/news");
			}
		});
	};
	
	useEffect(() => {
		if (checkHabilitation("jump", "inscription")) {
			reloadData().then(r => r);
		} else {
			navigate("/news");
		}
	}, [refreshKey]);
	
	return (
		<>
			{data === null ? (
				<div className={"chargement_page"}>
					<img src={chargement} alt="Drapeau" />
					<span>{t("Chargement...", { ns: "app" })}</span>
				</div>
			) : (
				<ListJumpEvent listJumpEvent={data} />
			)}
		
		</>
	);
}
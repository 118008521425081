import { FormCitoyens, ListIdPouvoir }           from "../../../types/components/Hotel/Citoyens.type";
import React                                     from "react";
import SvgIcone                                  from "../../../components/generality/SvgIcone";
import { AffNbrIcon, Avatar }                    from "../../../components/generality/ComposantGeneral";
import { InputFormCitoyen, LibCoordonneCitoyen } from "./CitoyensUtils";
import HTMLParser                                from "html-react-parser";
import { CitoyensDTO }                           from "../../../types/models/citoyens.dto";
import { UserDTO }                               from "../../../types/models/user.dto";
import { VilleDTO }                              from "../../../types/models/ville.dto";
import { useTranslation }                        from "react-i18next";
import { CoffreCitoyenDTO }                      from "../../../types/models/coffreCitoyen.dto";
import TooltipGH                                 from "../../../components/utils/TooltipGH";
import CompetencesBadge                          from "../../../components/Citoyens/CompetencesBadge";
import { HerosSkillLevelDTO }                    from "../../../types/models/herosSkillLevel.dto";
import { useGeneralContext }                     from "../../../types/Context/GeneralContext";
import { FontAwesomeIcon }                       from "@fortawesome/react-fontawesome";
import { faBoxesPacking }                        from "@fortawesome/free-solid-svg-icons";
import Button                                    from "../../../components/utils/Button";

export function LigneCitoyenVivantExpend(props: {
	citIdUpdate: number | null
	citoyen: CitoyensDTO,
	formCitoyens: FormCitoyens,
	index: number,
	listIdPouvoir: ListIdPouvoir,
	maLigne: boolean,
	moyenContact: string[],
	nameFormIsUpdate: string | null,
	onJoueurChange: (updatedJoueur: CitoyensDTO, index: number, nameForm: string) => void,
	onMajCoffre: (citoyen: CitoyensDTO, index: number) => void,
	user: UserDTO,
	ville: VilleDTO,
	listSkillLevel: HerosSkillLevelDTO[],
	choiceCheck: boolean;
	listCitoyenCheck: number[];
	handleCheckCitoyen: (citoyenId: number) => void;
}) {
	const { t } = useTranslation();
	const { general } = useGeneralContext();
	const citIdUpdate = props.citIdUpdate;
	const citoyen = props.citoyen;
	const formCitoyens = props.formCitoyens;
	const index = props.index;
	const listIdPouvoir = props.listIdPouvoir;
	const maLigne = props.maLigne;
	const moyenContact = props.moyenContact;
	const nameFormIsUpdate = props.nameFormIsUpdate;
	const user = props.user;
	const ville = props.ville;
	
	let blocMaj = false;
	if ((props.citoyen.citoyen.user_personnalisation.bloc_maj_citoyens || props.user.user_personnalisation.bloc_maj_citoyen) && citoyen.citoyen.id !== props.user.id) {
		blocMaj = true;
	}
	
	let date_maj_string;
	if (citoyen.date_maj !== null) {
		const date_maj = new Date(citoyen.date_maj);
		date_maj_string = date_maj.toLocaleDateString() + " - " + date_maj.toLocaleTimeString().replace(/:\d\d$/, "");
	}
	
	return <tr className={"lignCitoyenV " + ((maLigne) ? "maLigne" : ((index % 2 === 0) ? "fondWhite02" : "fondBlack02"))}
			   id={"id_" + citoyen.citoyen.id}
			   key={"id_" + citoyen.citoyen.id}>
		<td className="zoneAvatarCitoyenV">
			<div className="zonePseudoCitoyenV gap-1">
				{citoyen.ban && <span><SvgIcone icone={"r_ban"} /></span>}
				<span className={"pseudoCitoyenHV" + ((citoyen.ban) ? " citoyenBanni" : "")}>
                    <a href={"/ame/" + citoyen.citoyen.id_my_hordes}>{citoyen.citoyen.pseudo}</a>&thinsp;
                </span>
				<TooltipGH>
                    <span className="infoBulle icone_citoyen_centre">
                        <SvgIcone icone={"h_" + citoyen.job.icon} />
                    </span>
					<span className="info">{citoyen.job.nom}</span>
				</TooltipGH>
				{citoyen.message !== null && citoyen.message !== "" && <React.Fragment>
					<span>-</span>
					<TooltipGH>
                        <span className="infoBulle">
                            <SvgIcone icone={"small_chat"} />
                        </span>
						<span className="info infoBulleMessage">{citoyen.message}</span>
					</TooltipGH>
				</React.Fragment>}
			</div>
			<div className="zoneAvatIconeCitoyenV">
				<div className="avatarPouvCitoyenV">
					<div className="avatarCitoyenV">
						<Avatar url={citoyen.citoyen.avatar} classAvatar={null} />
					</div>
					<div className="zonePouvHeros">
						{props.listSkillLevel.map((skillLevel) => {
							const skill = citoyen.skill.length > 0 ? citoyen.skill.find((skill) => skill.heros_skill_type.id === skillLevel.heros_skill_type.id) ?? citoyen.citoyen.skill_type.find((skill) => skill.heros_skill_type.id === skillLevel.heros_skill_type.id) : citoyen.citoyen.skill_type.find((skill) => skill.heros_skill_type.id === skillLevel.heros_skill_type.id);
							return <span key={skillLevel.id} className="citoyen_tab_reduit_icone">
                                {citoyen.job?.id !== 2 && <CompetencesBadge skillLevel={skill ?? skillLevel} personnalisation={general.themeUser} />}
                            </span>;
						})}
					</div>
				</div>
				<div className="zoneIconCitoyenV">
					<div className="positionCitoyenV">
						<span><LibCoordonneCitoyen citoyen={citoyen} ville={ville} /></span>
					</div>
					{citoyen.citoyen.tem_arma && <div className="temArmaCitoyenV">
						<TooltipGH>
                            <span className="infoBulle">
                                <SvgIcone icone={"h_arma"} />
                            </span>
							<span className="info">{t("Témoin de l'Arma", { ns: "hotel" })}</span>
						</TooltipGH>

					</div>}
					{/*                    {citoyen.citoyen.legend &&
					 <div className="legendaireCitoyenV">
					 <span className="infoBulle">
					 <SvgIcone icone={"item_reveil"} />
					 <span className="info">{t("Réveil légendaire", { ns: "hotel" })}</span>
					 </span>
					 </div>}*/}
					{moyenContact[citoyen.citoyen.id] && <div className="moyenContactCitoyenV">
						<TooltipGH>
                            <span className="infoBulle">
                                <i className="fa-solid fa-phone"></i>
                            </span>
							<span className="info">{HTMLParser(moyenContact[citoyen.citoyen.id])}</span>
						</TooltipGH>

					</div>}
					{props.choiceCheck && <div className={"d-flex justify-content-center"}><input type="checkbox" className="citoyenCheck" checked={props.listCitoyenCheck.includes(citoyen.citoyen.id)} onChange={() => props.handleCheckCitoyen(citoyen.citoyen.id)} /></div>}
				</div>
			</div>
		
		</td>
		<td className="zoneInfoComplCitoyenV">
			<div id="divers">
				{citoyen.citoyen.der_pouv.ordre_recup >= listIdPouvoir.poss_apag && <div>
					<label htmlFor={"lvl_ruine"} className={(nameFormIsUpdate === "charge_apag" && citIdUpdate === citoyen.citoyen.id) ? "updated_cit_form_exp" : null}>{t(formCitoyens.divers.chargeApag.label, { ns: "hotel" })}</label>
					<InputFormCitoyen type={formCitoyens.divers.chargeApag.type}
									  name={"charge_apag"} citoyen={citoyen}
									  choices={formCitoyens.divers.chargeApag.choices}
									  index={index} user={user}
									  onJoueurChange={props.onJoueurChange}
					/>
				</div>}
				<div>
					<label htmlFor={"nb_camping"} className={(nameFormIsUpdate === "nb_camping" && citIdUpdate === citoyen.citoyen.id) ? "updated_cit_form_exp" : null}>{t(formCitoyens.divers.nbCamping.label, { ns: "hotel" })}</label>
					<InputFormCitoyen type={formCitoyens.divers.nbCamping.type}
									  name={"nb_camping"} citoyen={citoyen}
									  choices={formCitoyens.divers.nbCamping.choices}
									  index={index} user={user}
									  onJoueurChange={props.onJoueurChange}
					/>
				</div>
				<div>
					<label htmlFor={"immuniser"} className={(nameFormIsUpdate === "immuniser" && citIdUpdate === citoyen.citoyen.id) ? "updated_cit_form_exp" : null}>{t(formCitoyens.divers.immuniser.label, { ns: "hotel" })}</label>
					<InputFormCitoyen type={formCitoyens.divers.immuniser.type}
									  name={"immuniser"} citoyen={citoyen} index={index} user={user}
									  onJoueurChange={props.onJoueurChange}
					/>
				</div>
				<div>
					<label htmlFor={"lvl_ruine"} className={(nameFormIsUpdate === "lvl_ruine" && citIdUpdate === citoyen.citoyen.id) ? "updated_cit_form_exp" : null}>{t(formCitoyens.divers.lvlRuine.label, { ns: "hotel" })}</label>
					<InputFormCitoyen type={formCitoyens.divers.lvlRuine.type}
									  name={"lvl_ruine"} citoyen={citoyen}
									  choices={formCitoyens.divers.lvlRuine.choices}
									  index={index} user={user}
									  onJoueurChange={props.onJoueurChange}
					/>
				</div>
				<div>
					<label htmlFor={"shoes"} className={(nameFormIsUpdate === "shoes" && citIdUpdate === citoyen.citoyen.id) ? "updated_cit_form_exp" : null}>{t(formCitoyens.divers.shoes.label, { ns: "hotel" })}</label>
					<InputFormCitoyen type={formCitoyens.divers.shoes.type}
									  name={"shoes"} citoyen={citoyen} index={index} user={user}
									  onJoueurChange={props.onJoueurChange}
					/>
				</div>
			</div>
		</td>
		<td className="zonePouvHerosCitoyenV">
			<div id="pouvoirs_extend_citoyens">
				<div className={formCitoyens.pouv.sauvetage.class}>
					<label className={(nameFormIsUpdate === "sauvetage" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>
						<InputFormCitoyen type={formCitoyens.pouv.sauvetage.type}
										  name={"sauvetage"} citoyen={citoyen} index={index} user={user}
										  onJoueurChange={props.onJoueurChange}
						/>{t(formCitoyens.pouv.sauvetage.label, { ns: "hotel" })}</label>
				</div>
				<div className={formCitoyens.pouv.rdh.class}>
					<label className={(nameFormIsUpdate === "rdh" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>
						<InputFormCitoyen type={formCitoyens.pouv.rdh.type}
										  name={"rdh"} citoyen={citoyen} index={index} user={user}
										  onJoueurChange={props.onJoueurChange}
						/>{t(formCitoyens.pouv.rdh.label, { ns: "hotel" })}</label>
				</div>
				<div className={formCitoyens.pouv.uppercut.class}>
					<label className={(nameFormIsUpdate === "uppercut" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>
						<InputFormCitoyen type={formCitoyens.pouv.uppercut.type}
										  name={"uppercut"} citoyen={citoyen} index={index} user={user}
										  onJoueurChange={props.onJoueurChange}
						/>{t(formCitoyens.pouv.uppercut.label, { ns: "hotel" })}</label>
				</div>
				{citoyen.citoyen.der_pouv.ordre_recup >= listIdPouvoir.camaraderie && citoyen.citoyen.nb_charge_camaraderie > 0 &&
					<div className={formCitoyens.pouv.donJh.class}>
						<label className={(nameFormIsUpdate === "don_jh" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>
							<InputFormCitoyen type={formCitoyens.pouv.donJh.type}
											  name={"don_jh"} citoyen={citoyen} index={index} user={user}
											  onJoueurChange={props.onJoueurChange}
							/>{t(formCitoyens.pouv.donJh.label, { ns: "hotel" })}</label>
					</div>}
				{citoyen.citoyen.der_pouv.ordre_recup >= listIdPouvoir.corps_sain && <div className={formCitoyens.pouv.corpsSain.class}>
					<label className={(nameFormIsUpdate === "corps_sain" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>
						<InputFormCitoyen type={formCitoyens.pouv.corpsSain.type}
										  name={"corps_sain"} citoyen={citoyen} index={index} user={user}
										  onJoueurChange={props.onJoueurChange}
						/>{t(formCitoyens.pouv.corpsSain.label, { ns: "hotel" })}</label>
				</div>}
				{citoyen.citoyen.der_pouv.ordre_recup >= listIdPouvoir.second_souffle && <div className={formCitoyens.pouv.secondSouffle.class}>
					<label className={(nameFormIsUpdate === "second_souffle" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>
						<InputFormCitoyen type={formCitoyens.pouv.secondSouffle.type}
										  name={"second_souffle"} citoyen={citoyen} index={index} user={user}
										  onJoueurChange={props.onJoueurChange}
						/>{t(formCitoyens.pouv.secondSouffle.label, { ns: "hotel" })}</label>
				</div>}
				{citoyen.citoyen.der_pouv.ordre_recup < listIdPouvoir.trouvaille_ame && <div className={formCitoyens.pouv.trouvaille.class}>
					<label className={(nameFormIsUpdate === "trouvaille" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>
						<InputFormCitoyen type={formCitoyens.pouv.trouvaille.type}
										  name={"trouvaille"} citoyen={citoyen} index={index} user={user}
										  onJoueurChange={props.onJoueurChange}
						/>{t(formCitoyens.pouv.trouvaille.label, { ns: "hotel" })}</label>
				</div>}
				{citoyen.citoyen.der_pouv.ordre_recup >= listIdPouvoir.trouvaille_ame && <div className={formCitoyens.pouv.trouvaille_a.class}>
					<label className={(nameFormIsUpdate === "trouvaille" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>
						<InputFormCitoyen type={formCitoyens.pouv.trouvaille_a.type}
										  name={"trouvaille"} citoyen={citoyen} index={index} user={user}
										  onJoueurChange={props.onJoueurChange}
						/>{t(formCitoyens.pouv.trouvaille_a.label, { ns: "hotel" })}</label>
				</div>}
				{citoyen.citoyen.der_pouv.ordre_recup >= listIdPouvoir.vlm && <div className={formCitoyens.pouv.vlm.class}>
					<label className={(nameFormIsUpdate === "vlm" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>
						<InputFormCitoyen type={formCitoyens.pouv.vlm.type}
										  name={"vlm"} citoyen={citoyen} index={index} user={user}
										  onJoueurChange={props.onJoueurChange}
						/>{t(formCitoyens.pouv.vlm.label, { ns: "hotel" })}</label>
				</div>}
				{citoyen.citoyen.tem_arma && <div className={formCitoyens.pouv.pef.class}>
					<label className={(nameFormIsUpdate === "pef" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>
						<InputFormCitoyen type={formCitoyens.pouv.pef.type}
										  name={"pef"} citoyen={citoyen} index={index} user={user}
										  onJoueurChange={props.onJoueurChange}
						/>{t(formCitoyens.pouv.pef.label, { ns: "hotel" })}</label>
				</div>}
				{<div className={formCitoyens.pouv.camaraderie_recu.class}>
					<label className={(nameFormIsUpdate === "charge_camaraderie" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>
						<InputFormCitoyen type={formCitoyens.pouv.camaraderie_recu.type}
										  name={"charge_camaraderie"} citoyen={citoyen} index={index} user={user}
										  onJoueurChange={props.onJoueurChange}
						/>{t(formCitoyens.pouv.camaraderie_recu.label, { ns: "hotel" })}</label>
				</div>}
			</div>
		</td>
		<td className="zoneAmeMaisonCitoyenV">
			<div className={"simpleChoixCit"}>
				<span>{t(formCitoyens.hab.lvlMaison, { ns: "hotel" })}</span>
				<span className="info">{t(citoyen.lvl_maison.nom, { ns: "hotel" })}&thinsp;</span>
				<SvgIcone icone={"home_" + citoyen.lvl_maison.icon} />
			</div>
			{(citoyen.lvl_maison.id > 1 || ville.devast) && <React.Fragment>
				<div className={formCitoyens.hab.lvlCoinSieste.class}>
					<label className={(nameFormIsUpdate === "lvl_coin_sieste" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>{t(formCitoyens.hab.lvlCoinSieste.label, { ns: "hotel" })}</label>
					<InputFormCitoyen type={formCitoyens.hab.lvlCoinSieste.type}
									  name={"lvl_coin_sieste"} citoyen={citoyen} index={index} user={user}
									  onJoueurChange={props.onJoueurChange}
									  choices={formCitoyens.hab.lvlCoinSieste.choices}
									  disabled={ville.devast}
					/>
				</div>
				<div className={formCitoyens.hab.lvlCuisine.class}>
					<label className={(nameFormIsUpdate === "lvl_cuisine" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>{t(formCitoyens.hab.lvlCuisine.label, { ns: "hotel" })}</label>
					<InputFormCitoyen type={formCitoyens.hab.lvlCuisine.type}
									  name={"lvl_cuisine"} citoyen={citoyen} index={index} user={user}
									  onJoueurChange={props.onJoueurChange}
									  choices={formCitoyens.hab.lvlCuisine.choices}
									  disabled={ville.devast}
					/>
				</div>
				<div className={formCitoyens.hab.lvlLabo.class}>
					<label className={(nameFormIsUpdate === "lvl_labo" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>{t(formCitoyens.hab.lvlLabo.label, { ns: "hotel" })}</label>
					<InputFormCitoyen type={formCitoyens.hab.lvlLabo.type}
									  name={"lvl_labo"} citoyen={citoyen} index={index} user={user}
									  onJoueurChange={props.onJoueurChange}
									  choices={formCitoyens.hab.lvlLabo.choices}
									  disabled={ville.devast}
					/>
				</div>
				<div className={formCitoyens.hab.lvlRangement.class}>
					<label className={(nameFormIsUpdate === "lvl_rangement" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>{t(formCitoyens.hab.lvlRangement.label, { ns: "hotel" })}</label>
					<InputFormCitoyen type={formCitoyens.hab.lvlRangement.type}
									  name={"lvl_rangement"} citoyen={citoyen} index={index} user={user}
									  onJoueurChange={props.onJoueurChange}
									  choices={formCitoyens.hab.lvlRangement.choices}
									  disabled={ville.devast}
					/>
				</div>
				<div className={formCitoyens.hab.lvlRenfort.class}>
					<label className={(nameFormIsUpdate === "lvl_renfort" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>{t(formCitoyens.hab.lvlRenfort.label, { ns: "hotel" })}</label>
					<InputFormCitoyen type={formCitoyens.hab.lvlRenfort.type}
									  name={"lvl_renfort"} citoyen={citoyen} index={index} user={user}
									  onJoueurChange={props.onJoueurChange}
									  choices={formCitoyens.hab.lvlRenfort.choices}
									  disabled={ville.devast}
					/>
				</div>
				<div className={formCitoyens.hab.cloture.class}>
					<label className={(nameFormIsUpdate === "cloture" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>{t(formCitoyens.hab.cloture.label, { ns: "hotel" })}</label>
					<InputFormCitoyen type={formCitoyens.hab.cloture.type}
									  name={"cloture"} citoyen={citoyen} index={index} user={user}
									  onJoueurChange={props.onJoueurChange}
									  disabled={ville.devast}
					/>
				</div>
				<div className={formCitoyens.hab.nbCarton.class}>
					<label className={(nameFormIsUpdate === "nb_carton" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>{t(formCitoyens.hab.nbCarton.label, { ns: "hotel" })}</label>
					<InputFormCitoyen type={formCitoyens.hab.nbCarton.type}
									  name={"nb_carton"} citoyen={citoyen} index={index} user={user}
									  onJoueurChange={props.onJoueurChange}
					/>
				</div>
				<div className={formCitoyens.hab.nbBarricade.class}>
					<label className={(nameFormIsUpdate === "nb_barricade" && citIdUpdate === citoyen.citoyen.id) ? " updated_cit_form_exp" : null}>{t(formCitoyens.hab.nbBarricade.label, { ns: "hotel" })}</label>
					<InputFormCitoyen type={formCitoyens.hab.nbBarricade.type}
									  name={"nb_barricade"} citoyen={citoyen} index={index} user={user}
									  onJoueurChange={props.onJoueurChange}
					/>
				</div>
			</React.Fragment>}
		</td>
		<td className="zoneCoffreDispoCitoyenV">
			<div className="regroupementCoffre">
				<div className="zoneCoffre" id={"coffre_" + citoyen.citoyen.id}>
					{citoyen.coffres.sort((a: CoffreCitoyenDTO, b: CoffreCitoyenDTO) => {
						const aName = t(a.item.nom, { ns: "game" }).toLowerCase();
						const bName = t(b.item.nom, { ns: "game" }).toLowerCase();
						return aName.localeCompare(bName);
					}).map(item =>
						<AffNbrIcon item={item.item}
									broken={item.broken}
									nbr={item.nombre}
									key={"obj_" + item.item.id + "_" + (item.broken ? 1 : 0)} />,
					)}
				</div>
				{!blocMaj && <TooltipGH>
					<Button taille={"xs"} couleur={"success"} onClick={() => props.onMajCoffre(citoyen, index)}>
						<FontAwesomeIcon icon={faBoxesPacking} />
					</Button>
					<span className="info">{t("Ouvrir le coffre", { ns: "hotel" })}</span>
				</TooltipGH>}
			</div>
			<div className={"infoMaj"}>
				<i className={"fa-solid fa-clock-rotate-left " + ((citoyen.date_maj !== null && citoyen.diff_date_maj === 0) ? "maj_0" :
					((citoyen.date_maj !== null && citoyen.diff_date_maj === 1) ? "maj_24" : "maj_48"))}></i>
				<span className="info">
                        {citoyen.date_maj === null && <em>{t("Dernière mise à jour inconnue", { ns: "hotel" })}</em>}
					{citoyen.date_maj !== null &&
						<em>{t("Dernière mise à jour le {dateMaj} par {user}", { ns: "hotel" })
							.replace("{user}", citoyen.update_by.pseudo)
							.replace("{dateMaj}", date_maj_string)}</em>}
                    </span>
			</div>
		</td>
	</tr>
		;
}
import React, { useEffect, useState } from "react";
import chargement                     from "../../../img/chargement.svg";
import { CoalitionsJumpApi }          from "../../services/api/CoalitionsJumpApi";
import { useNavigate }                from "react-router";
import ListCoalition                  from "../../containers/Jump/Coalition/ListCoalition";
import { useGeneralContext }          from "../../types/Context/GeneralContext";
import { useGHContext }               from "../../types/Context/GHContext";
import { Status_error, usePopUp }     from "../../types/Context/PopUpContext";
import { useTranslation }             from "react-i18next";

export function ListCoalitionGeneral() {
	const { general, setGeneral } = useGeneralContext();
	const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const navigate = useNavigate();
	const { t } = useTranslation();
	
	const [coaData, setCoaData] = useState(null);
	
	
	// Fonction pour recharger les données
	const reloadData = async () => {
		const coaApi = new CoalitionsJumpApi(parseInt(sessionStorage.getItem("mapId") ?? "0", 10));
		
		coaApi.main().then((response) => {
			setIsOnRefresh(false);
			setIsOnUpdate(false);
			if (response.data.general !== undefined) {
				setGeneral(response.data.general);
				sessionStorage.setItem("mapId", response.data.general.ville ? response.data.general.ville.map_id.toString() : null);
			}
			if (response.data.list === undefined) {
				console.error("Erreur de chargement des données coalition list", response);
			} else {
				setCoaData(response.data.list);
			}
		}).catch((error) => {
			setStatus(Status_error);
			setShowPop(true);
			setMessagePopUp(error.data.error);
			navigate("/news");
		});
	};
	
	useEffect(() => {
		if (checkHabilitation("jump", "coalitions")) {
			reloadData().then(r => r);
		} else {
			navigate("/news");
		}
	}, [refreshKey]);
	
	return (
		<>
			{coaData === null ? (
				<div className={"chargement_page"}>
					<img src={chargement} alt="Drapeau" />
					<span>{t("Chargement...", { ns: "app" })}</span>
				</div>
			) : (
				<ListCoalition list={coaData} />
			)}
		
		</>
	);
}
import React                       from "react";
import { ExpeditionPartDTO }       from "../../../types/models/expeditionPart.dto";
import { calculPdc, ID_RAMASSAGE } from "../../Outils/Expedition/ExpeditionUtils";
import SvgIcone                    from "../../../components/generality/SvgIcone";
import { AffNbrIcon }              from "../../../components/generality/ComposantGeneral";
import HTMLParser                  from "html-react-parser";
import { ConsigneExpeditionDTO }   from "../../../types/models/consigneExpedition.dto";
import { useTranslation }          from "react-i18next";
import { useGeneralContext }       from "../../../types/Context/GeneralContext";
import { CreneauHorraireDTO }      from "../../../types/models/creneauHorraire.dto";
import chroma                      from "chroma-js";
import { calculColorDispo }        from "./InscriptionExpedition";
import { UserDTO }                 from "../../../types/models/user.dto";
import Button                      from "../../../components/utils/Button";
import Modal                       from "react-bootstrap/Modal";
import Tooltips                    from "../../../components/utils/Tooltips";
import { FontAwesomeIcon }         from "@fortawesome/react-fontawesome";
import { faComment }               from "@fortawesome/free-solid-svg-icons";

export function TabExpedition({ expedition, creneaux, userOption }: { expedition: ExpeditionPartDTO, creneaux: CreneauHorraireDTO[], userOption: UserDTO }) {
	const { t } = useTranslation();
	const { general } = useGeneralContext();
	const [openCommentaire, setOpenCommentaire] = React.useState<boolean>(false);
	const [commentaireExpeditionnaire, setCommentaireExpeditionnaire] = React.useState<{ expeditionnaireIndex: number } | null>(null);
	
	return <div key={expedition.id} className={"zone_expedition_expe_part"}>
		<table className={"inscription_expedition"}>
			<thead>
			<tr className={"ligne_inscription_expedition"}>
				<th rowSpan={1} className={"cols40_expedition"}><SvgIcone icone={expedition.expedition.type_expe.icon ?? ""} classIcone={"itemExpe"} /></th>
				<th colSpan={17} style={{ fontSize: "18px" }}>
					<div className={"expedition_titre"}>
						{expedition.expedition.type_expe.id === ID_RAMASSAGE ? (<span>{expedition.expedition.nom} - {expedition.number}</span>) : (<span>{expedition.expedition.nom}</span>)}
						<span className={"expedition_titre"}>
                            <span>-</span>
                            <span className={"d-flex gap-1 align-items-center"}>
                                <span>{expedition.pa}</span>
                                <SvgIcone icone={"h_pa"} />
                            </span>
                        </span>
					</div>
				</th>
				<th rowSpan={1} colSpan={3} className={"pdc_zone_tab"}>
					<div>
						<span>{expedition.expedition.min_pdc}</span>
						<span>{t("PDC", { ns: "hotel" })}</span>
					</div>
				</th>
			</tr>
			<tr className={"ligne_inscription_expedition"}>
				<th rowSpan={2} className={"cols40_expedition"}></th>
				<th rowSpan={2} className={"pseudo_expedition"}>{t("Pseudo", { ns: "hotel" })}</th>
				<th rowSpan={2} className={"cols40_expedition"}><SvgIcone icone={"h_ban"} /></th>
				<th rowSpan={2} className={"cols40_expedition"}><SvgIcone icone={"r_dwater"} /></th>
				<th rowSpan={2} className={"cols20_expedition"}><SvgIcone icone={"h_pa"} /></th>
				<th rowSpan={2} className={"cols20_expedition"}><SvgIcone icone={"h_pe"} /></th>
				<th rowSpan={2} className={"cols20_expedition"}><SvgIcone icone={"h_guard"} /></th>
				<th rowSpan={2} className={"cols20_expedition"}><Tooltips label={t("Commentaires joueurs", { ns: "hotel" })}><FontAwesomeIcon icon={faComment} /></Tooltips></th>
				<th rowSpan={2} className={"sac_expedition"}>{t("Sac", { ns: "hotel" })}</th>
				<th rowSpan={2} className={"ah_expedition"}>{t("Action héroïque", { ns: "hotel" })}</th>
				<th colSpan={creneaux.length}>
                                            <span className={"dispo_rapide_inscription"}>
                                                <span>{t("Disponibilités", { ns: "hotel" })}</span>
                                            </span>
				</th>
			</tr>
			<tr className={"ligne_inscription_expedition"}>
				{creneaux.map((creneau) => {
					return <th key={creneau.id} className={"cols40_expedition"}>{t(creneau.libelle, { ns: "jump" })}</th>;
				})}
			</tr>
			</thead>
			<tbody>
			{expedition.expeditionnaires.sort((exped_a, exped_b) => exped_a.position < exped_b.position ? -1 : 1).map((exped, index) => {
				return <tr key={exped.id} className={"ligne_inscription_expedition ligne_citoyen_user" + ((exped.citoyen?.citoyen?.id === general.user.id) ? " my_line_expedition" : "")}>
					<td className={"cols40_expedition"}>
						<div className={"zone_td_display"}>
							{exped.job && <SvgIcone icone={"h_" + exped.job?.icon} />}
						</div>
					</td>
					<td className={"pseudo_expedition"}>
						<div className={"zone_td_display"}>
                            <span className={"pseudo_expedition_liste"}>
                                                    <span className={exped.citoyen?.ban ? "color-red" : ""}>{exped.citoyen?.citoyen.pseudo}</span>
								{exped.citoyen?.ban && <SvgIcone icone={"h_ban"} />}
                                                </span>
						</div>
					</td>
					<td className={"cols40_expedition"}>
						<div className={"zone_td_display"}>
							{exped.for_banni ? <SvgIcone icone={"h_ban"} /> : <i className="fa-solid fa-xmark color-red"></i>}
						</div>
					</td>
					<td className={"cols40_expedition"}>
						<div className={"zone_td_display"}>
							{exped.soif ? <SvgIcone icone={"r_dwater"} /> : <SvgIcone icone={"status_clean"} />}
						</div>
					</td>
					<td className={"cols20_expedition"}>
						<div className={"zone_td_display"}>{exped.pa_base ?? 6}</div>
					</td>
					<td className={"cols20_expedition"}>
						<div className={"zone_td_display"}>{exped.pe_base ?? 0}</div>
					</td>
					<td className={"cols20_expedition"}>
						<div className={"zone_td_display"}>{calculPdc(exped?.citoyen ?? null, expedition, general)}</div>
					</td>
					<td className={"cols20_expedition"}>
						<div className={"zone_td_display gap-1"}>
							{exped.commentaire && <FontAwesomeIcon icon={faComment} style={{ cursor: "pointer" }} onClick={() => {
								setCommentaireExpeditionnaire({ expeditionnaireIndex: index });
								setOpenCommentaire(true);
							}} />}
						</div>
					</td>
					<td className={"sac_expedition"}>
						<div className={"zone_sac_expeditionnaire"}>
							<div className={"sac_expeditionnaire"}>{exped.sac.map((sacItems) => {
								return <div key={`obj_${sacItems.item.id}_${sacItems.broken ? 1 : 0}`}>
									<AffNbrIcon
										item={sacItems.item}
										broken={sacItems.broken}
										nbr={sacItems.nbr}
										classSpecifique={`itemSacOutilsExpedition ${sacItems.nbr > 1 ? "color-red" : ""}`}
									/>
								</div>;
							})}</div>
						</div>
					</td>
					<td className={"ah_expedition"}>
						<div className={"zone_td_display"}>
							<SvgIcone icone={exped.action_heroic?.icon} />
							<span>{exped.action_heroic?.nom}</span>
						</div>
					</td>
					{
						creneaux.map((creneau, indexCreneau) => {
							if (exped.dispo[indexCreneau].dispo) {
								const colorCalc = calculColorDispo(exped.dispo[indexCreneau].dispo, general.themeUser.user_perso_couleur);
								const color = chroma(colorCalc);
								return <td key={creneau.id} className={"cols40_expedition"} style={{ backgroundColor: color.css(), color: chroma.contrast(colorCalc, "white") > 2 ? "white" : "black" }}>
									<div className={"zone_td_display"}>
										<span>{t(exped.dispo[indexCreneau].dispo.nom, { ns: "jump" })}</span>
									</div>
								</td>;
							} else {
								return <td key={creneau.id} className={"cols40_expedition"}>
									<div className={"zone_td_display"}>
										<span></span>
									</div>
								</td>;
							}
							
						})
					}
				</tr>;
			})}
			{expedition.description !== null && <>
				<tr style={{ height: "5px" }}></tr>
				<tr className={"ligne_inscription_expedition"}>
					<th colSpan={2}>{t("Description", { ns: "hotel" })}</th>
					<td colSpan={6 + creneaux.length} className={"description_expedition_part"}>{HTMLParser(expedition.description)}</td>
				</tr>
			</>}
			{expedition.consignes.length > 0 && <>
				<tr className={"ligne_inscription_expedition"}>
					<th colSpan={8 + creneaux.length}>{t("Consignes", { ns: "hotel" })}</th>
				</tr>
				{expedition.consignes.sort((consigne_a: ConsigneExpeditionDTO, consigne_b: ConsigneExpeditionDTO) => {
					return consigne_a.ordre_consigne < consigne_b.ordre_consigne ? -1 : 1;
				}).map((consigne, indexConsigne) => {
					return <tr key={indexConsigne} className={"ligne_inscription_expedition"}>
						<td colSpan={1}>
							<div>
								{consigne?.fait ? <i className="fa-solid fa-check color-green"></i> : <i className="fa-solid fa-xmark color-red"></i>}
							</div>
						</td>
						<td colSpan={8 + creneaux.length} className={"consigne_zone_checkbox"}>
							<div>
								<div>{consigne?.zone?.x_rel}/{consigne?.zone?.y_rel}</div>
								<div>:</div>
								<div className={"text_consigne_zone_consigne"}>{HTMLParser(consigne.text)}</div>
							</div>
						</td>
					</tr>;
				})}
			</>}
			</tbody>
		</table>
		<Modal show={openCommentaire} onHide={() => setOpenCommentaire(false)} centered key={"modal_commentaire_expeditionnaire_view"}>
			{commentaireExpeditionnaire !== null && <div id={"popUpConsigne"}>
				<div>{HTMLParser(expedition.expeditionnaires[commentaireExpeditionnaire.expeditionnaireIndex]?.commentaire ?? "")}</div>
				<div>
					<Button taille={"xs"} couleur={"primary"} onClick={() => setOpenCommentaire(false)}>{t("Fermer", { ns: "app" })}</Button>
				</div>
			</div>}
		</Modal>
	</div>;
}
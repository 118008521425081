import * as React                                from "react";
import { SaveButton, Toolbar, useRecordContext } from "react-admin";

const EditToolbar = props => {
	useRecordContext();
	
	return <Toolbar {...props}>
		<SaveButton />
	</Toolbar>;
};

export default EditToolbar;
import React, { PropsWithChildren } from "react";

type ButtonProps = {
	taille: "xs" | "sm" | "md" | "lg" | "xl";
	couleur: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" | "link";
	onClick?: () => void;
	className?: string;
}

export default function Button({ taille, couleur, onClick, className, children }: PropsWithChildren<ButtonProps>) {
	return (
		<button
			className={`btn btn-${taille} btn-${couleur} ${className ?? ""}`}
			onClick={onClick}
		>
			{children}
		</button>
	);
}
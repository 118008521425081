import React, { useCallback, useEffect, useState }                                           from "react";
import { createBrowserRouter, Outlet, RouteObject, useLocation, useNavigate, useNavigation } from "react-router";
import { RouterProvider }                                                                    from "react-router/dom";
import chargement                                                                            from "./../img/chargement.svg";
import { GeneralApi }                                                                        from "./services/api/GeneralApi";
import ReactDOM                                                                              from "react-dom/client";
import { GHContext }                                                                         from "./types/Context/GHContext";
import { Helmet, HelmetProvider }                                                            from "react-helmet-async";
import Modal                                                                                 from "react-bootstrap/Modal";
import { GeneralType }                                                                       from "./types/components/Generality/General.type";
import { GeneralContext, useGeneralContext }                                                 from "./types/Context/GeneralContext";
import HTMLParser                                                                            from "html-react-parser";
import { Header }                                                                            from "./containers/General/Header";
import { CarteGeneral }                                                                      from "./views/Ville/CarteGeneral";
import { AdminGeneral }                                                                      from "./views/Admin/AdminGeneral";
import { AmeGeneral }                                                                        from "./views/Ame/AmeGeneral";
import { ClassementGeneral }                                                                 from "./views/Ame/ClassementGeneral";
import { EncyBatimentsGeneral }                                                              from "./views/Encyclopedie/EncyBatimentsGeneral";
import { EncyChantiersGeneral }                                                              from "./views/Encyclopedie/EncyChantiersGeneral";
import { EncyCitoyensGeneral }                                                               from "./views/Encyclopedie/EncyCitoyensGeneral";
import { EncyObjetsGeneral }                                                                 from "./views/Encyclopedie/EncyObjetsGeneral";
import { EncyVillesGeneral }                                                                 from "./views/Encyclopedie/EncyVillesGeneral";
import { ChangelogGeneral }                                                                  from "./views/Generaux/ChangelogGeneral";
import { ContactGeneral }                                                                    from "./views/Generaux/ContactGeneral";
import { OptionsPersoGeneral }                                                               from "./views/OptionPerso/OptionsPersoGeneral";
import { NewsGeneral }                                                                       from "./views/Generaux/NewsGeneral";
import { RuineGameGeneral }                                                                  from "./views/Generaux/RuineGameGeneral";
import { StatsGeneral }                                                                      from "./views/Generaux/StatsGeneral";
import { CitoyensGeneral }                                                                   from "./views/Hotel/CitoyensGeneral";
import { JournalGeneral }                                                                    from "./views/Hotel/JournalGeneral";
import { PlansChantiersGeneral }                                                             from "./views/Hotel/PlansChantiersGeneral";
import { TourDeGuetGeneral }                                                                 from "./views/Hotel/TourDeGuetGeneral";
import { StatistiquesGeneral }                                                               from "./views/Hotel/StatistiquesGeneral";
import { ListCoalitionGeneral }                                                              from "./views/Jump/ListCoalitionGeneral";
import { CoalitionGeneral }                                                                  from "./views/Jump/CoalitionGeneral";
import { JumpListGeneral }                                                                   from "./views/Jump/JumpListGeneral";
import { InscriptionEventGeneral }                                                           from "./views/Jump/InscriptionEventGeneral";
import { CreationGeneral }                                                                   from "./views/Jump/CreationGeneral";
import { GestionListGeneral }                                                                from "./views/Jump/GestionListGeneral";
import { GestionJumpGeneral }                                                                from "./views/Jump/GestionJumpGeneral";
import { CandidatureGeneral }                                                                from "./views/Jump/CandidatureGeneral";
import { GestionEventGeneral }                                                               from "./views/Jump/GestionEventGeneral";
import { ListJumpEventGeneral }                                                              from "./views/Jump/ListJumpEventGeneral";
import { OutilsChantiersGeneral }                                                            from "./views/Outils/OutilsChantiersGeneral";
import { OutilsDechargesGeneral }                                                            from "./views/Outils/OutilsDechargesGeneral";
import { OutilsReparationsGeneral }                                                          from "./views/Outils/OutilsReparationsGeneral";
import { OutilsVeillesGeneral }                                                              from "./views/Outils/OutilsVeillesGeneral";
import { BanqueGeneral }                                                                     from "./views/Ville/BanqueGeneral";
import { RuineListingGeneral }                                                               from "./views/Ville/RuineListingGeneral";
import { RuineGeneral }                                                                      from "./views/Ville/RuineGeneral";
import { VillesGeneral }                                                                     from "./views/Villes/VillesGeneral";
import { RedirectionGeneral }                                                                from "./views/Generaux/RedirectionGeneral";
import { InscriptionGeneral }                                                                from "./views/Jump/InscriptionGeneral";
import { PopUpContext, Status_error, Status_info, Status_success, Status_warning }           from "./types/Context/PopUpContext";
import { OutilsExpeditionsGeneral }                                                          from "./views/Outils/OutilsExpeditionsGeneral";
import { InscriptionExpeditionGeneral }                                                      from "./views/Hotel/InscriptionExpeditionGeneral";
import { I18nextProvider, useTranslation }                                                   from "react-i18next";
import "./config/i18n"; // Charger la configuration i18n
import i18n                                                                                  from "./config/i18n";
import { ThemeUserDTO }                                                                      from "./types/models/themeUser.dto";
import Button                                                                                from "react-bootstrap/Button";
import Footer                                                                                from "./containers/General/Footer";
import { UserPersonnalisationDTO }                                                           from "./types/models/userPersonnalisation.dto";
import { Provider }                                                                          from "react-redux";
import { store }                                                                             from "./store/store";
import { VillesComparatifGeneral }                                                           from "./views/Villes/VillesComparatifGeneral";

export const VERSION: string = "3.36.0";

const RoutesSite = () => {
	const indexRoute: RouteObject = { index: true, element: <RedirectionGeneral /> };
	const adminRoutes: RouteObject = { path: "/admin/*", element: <AdminGeneral /> };
	const ameRoutes: RouteObject[] = [
		{ path: "ame/:idUser", element: <AmeGeneral /> },
		{ path: "ame/classement", element: <ClassementGeneral /> },
	];
	const defaultRoutes: RouteObject[] = [
		{ path: "banque", element: <RedirectionGeneral /> },
		{ path: "carte", element: <RedirectionGeneral /> },
		{ path: "chantiers", element: <RedirectionGeneral /> },
		{ path: "citoyens", element: <RedirectionGeneral /> },
		{ path: "decharges", element: <RedirectionGeneral /> },
		{ path: "journal", element: <RedirectionGeneral /> },
		{ path: "plansDeChantier", element: <RedirectionGeneral /> },
		{ path: "reparations", element: <RedirectionGeneral /> },
		{ path: "ruine", element: <RedirectionGeneral /> },
		{ path: "stats", element: <RedirectionGeneral /> },
		{ path: "tourDeGuet", element: <RedirectionGeneral /> },
		{ path: "veilles", element: <RedirectionGeneral /> },
	];
	const encyRoutes: RouteObject[] = [
		{ path: "encyclopedie/batiments", element: <EncyBatimentsGeneral /> },
		{ path: "encyclopedie/chantiers", element: <EncyChantiersGeneral /> },
		{ path: "encyclopedie/citoyens", element: <EncyCitoyensGeneral /> },
		{ path: "encyclopedie/objets", element: <EncyObjetsGeneral /> },
		{ path: "encyclopedie/villes", element: <EncyVillesGeneral /> },
	];
	const generalRoutes: RouteObject[] = [
		{ path: "changelog", element: <ChangelogGeneral /> },
		{ path: "contact", element: <ContactGeneral /> },
		{ path: "espace_perso", element: <OptionsPersoGeneral /> },
		{ path: "news", element: <NewsGeneral /> },
		{ path: "ruineGame", element: <RuineGameGeneral /> },
		{ path: "statistiques", element: <StatsGeneral /> },
	];
	const hotelRoutes: RouteObject[] = [
		{ path: "citoyens/:mapId", element: <CitoyensGeneral /> },
		{ path: "journal/:mapId", element: <JournalGeneral /> },
		{ path: "plansDeChantier/:mapId", element: <PlansChantiersGeneral /> },
		{ path: "tourDeGuet/:mapId", element: <TourDeGuetGeneral /> },
		{ path: "stats/:mapId", element: <StatistiquesGeneral /> },
		{ path: "inscription_expe/:mapId", element: <InscriptionExpeditionGeneral /> },
	];
	const jumpRoutes: RouteObject[] = [
		{ path: "coalition", element: <ListCoalitionGeneral /> },
		{ path: "coalition/:idJump", element: <CoalitionGeneral /> },
		{ path: "event/archive/:idEvent", element: <JumpListGeneral isArchMode={true} /> },
		{ path: "event/:idEvent", element: <JumpListGeneral /> },
		{ path: "event/inscription/archive/:idEvent/:idJump", element: <InscriptionEventGeneral isArchMode={true} /> },
		{ path: "event/inscription/:idEvent/:idJump", element: <InscriptionEventGeneral /> },
		{ path: "jump/creation", element: <CreationGeneral /> },
		{ path: "jump/gestion", element: <GestionListGeneral /> },
		{ path: "jump/gestion/archive/:idJump", element: <GestionJumpGeneral isArchMode={true} /> },
		{ path: "jump/gestion/archive/:idJump/:idUser", element: <CandidatureGeneral isArchMode={true} /> },
		{ path: "jump/gestion/event/archive/:idEvent", element: <GestionEventGeneral isArchMode={true} /> },
		{ path: "jump/gestion/:idJump", element: <GestionJumpGeneral /> },
		{ path: "jump/gestion/:idJump/:idUser", element: <CandidatureGeneral /> },
		{ path: "jump/gestion/event/:idEvent", element: <GestionEventGeneral /> },
		{ path: "jump/inscription", element: <ListJumpEventGeneral /> },
		{ path: "jump/inscription/archive/:idJump", element: <InscriptionGeneral isArchMode={true} /> },
		{ path: "jump/inscription/:idJump", element: <InscriptionGeneral /> },
	];
	const outilsRoutes: RouteObject[] = [
		{ path: "chantiers/:mapId", element: <OutilsChantiersGeneral /> },
		{ path: "decharges/:mapId", element: <OutilsDechargesGeneral /> },
		{ path: "expeditions/:mapId", element: <OutilsExpeditionsGeneral /> },
		{ path: "reparations/:mapId", element: <OutilsReparationsGeneral /> },
		{ path: "veilles/:mapId", element: <OutilsVeillesGeneral /> },
	];
	const villeRoutes: RouteObject[] = [
		{ path: "banque/:mapId", element: <BanqueGeneral /> },
		{ path: "carte/:mapId", element: <CarteGeneral /> },
		{ path: "ruine/:mapId", element: <RuineListingGeneral /> },
		{ path: "ruine/:id/:mapId", element: <RuineGeneral /> },
	];
	const villesRoutes: RouteObject[] = [
		{ path: "villes", element: <VillesGeneral /> },
		{ path: "villes/comparatif", element: <VillesComparatifGeneral /> },
	];
	
	const allRoutes: RouteObject[] = [
		indexRoute,
		adminRoutes,
		...ameRoutes,
		...defaultRoutes,
		...encyRoutes,
		...generalRoutes,
		...hotelRoutes,
		...jumpRoutes,
		...outilsRoutes,
		...villeRoutes,
		...villesRoutes,
	];
	
	const routes = createBrowserRouter([
		{
			path                  : "/",
			element               : <Layout />,
			hydrateFallbackElement: <Fallback />,
			children              : allRoutes,
		},
	], {
		future: {
			v7_relativeSplatPath          : true,
			v7_startTransition            : true,
			v7_fetcherPersist             : true,
			v7_normalizeFormMethod        : true,
			v7_partialHydration           : true,
			v7_skipActionErrorRevalidation: true,
		},
	});
	
	return <RouterProvider router={routes} />;
};

const generateCSSUser = (themeUser: ThemeUserDTO, perso?: UserPersonnalisationDTO) => {
	// On vérifie que l'utilisateur a bien plusieurs themes associés avant de continuer
	
	if (themeUser === null) {
		return "";
	}
	
	// On récupère les valeurs du theme selectionné pour modifier les variables css
	if (themeUser.stats_bg_color !== null) {
		document.documentElement.style.setProperty("--stats-bg-color", themeUser.stats_bg_color);
	}
	if (themeUser.primary_border_color !== null) {
		document.documentElement.style.setProperty("--primary-border-color", themeUser.primary_border_color);
	}
	if (themeUser.background_color !== null) {
		document.documentElement.style.setProperty("--background-color", themeUser.background_color);
	}
	if (themeUser.my_line_color !== null) {
		document.documentElement.style.setProperty("--myLine-color", themeUser.my_line_color);
	}
	if (themeUser.primary_color !== null) {
		document.documentElement.style.setProperty("--primary-color", themeUser.primary_color);
	}
	if (themeUser.secondary_color !== null) {
		document.documentElement.style.setProperty("--secondary-color", themeUser.secondary_color);
	}
	if (themeUser.tertiary_color !== null) {
		document.documentElement.style.setProperty("--tertiary-color", themeUser.tertiary_color);
	}
	if (themeUser.hover_font_color !== null) {
		document.documentElement.style.setProperty("--hover-font-color", themeUser.hover_font_color);
	}
	if (themeUser.primary_font_color !== null) {
		document.documentElement.style.setProperty("--primary-font-color", themeUser.primary_font_color);
	}
	if (themeUser.tertiary_font_color !== null) {
		document.documentElement.style.setProperty("--tertiary-font-color", themeUser.tertiary_font_color);
	}
	if (themeUser.primary_row_color !== null) {
		document.documentElement.style.setProperty("--primary-row-color", themeUser.primary_row_color);
	}
	if (themeUser.secondary_row_color !== null) {
		document.documentElement.style.setProperty("--secondary-row-color", themeUser.secondary_row_color);
	}
	if (themeUser.bg_hover_color !== null) {
		document.documentElement.style.setProperty("--special-color", themeUser.bg_hover_color);
	}
	if (themeUser.succes_color !== null) {
		document.documentElement.style.setProperty("--succes-color", themeUser.succes_color);
	}
	if (themeUser.error_color !== null) {
		document.documentElement.style.setProperty("--error-color", themeUser.error_color);
	}
	if (themeUser.specifique_color !== null) {
		document.documentElement.style.setProperty("--specifique-color", themeUser.specifique_color);
	}
	if (perso?.menu_bandeau ?? false) {
		document.documentElement.style.setProperty("--top-decalage", "106px");
	} else {
		document.documentElement.style.setProperty("--top-decalage", "64px");
	}
	
	return "";
};

export default function App() {
	
	const [general, setGeneral] = useState<GeneralType>(null);
	const { t } = useTranslation();
	
	useEffect(() => {
		const generalApi = new GeneralApi(parseInt(sessionStorage.getItem("mapId") ?? "0"));
		
		const intervalId = setInterval(() => {
			generalApi.ping({ versionClient: VERSION }).then((response) => {
				if (response.data.reload === undefined || response.data.reload) {
					window.location.reload();
				}
			});
		}, 600000); // 600000 milliseconds = 10 minutes
		
		generalApi.general().then((response) => {
			if (response.data.general?.ville === null) {
				sessionStorage.setItem("mapId", null);
			} else {
				sessionStorage.setItem("mapId", response.data.general.ville.map_id.toString());
			}
			i18n.changeLanguage(response.data.general.user?.lang ?? "en");
			setGeneral(response.data.general);
		});
		
		// Clear interval on component unmount
		return () => clearInterval(intervalId);
	}, []);
	
	const getNumberOfReloads = () => parseInt(sessionStorage.getItem("numberOfReloads") ?? "0");
	
	if (general) {
		if (general.version.version === VERSION) {
			sessionStorage.setItem("numberOfReloads", "0");
		} else {
			const numberOfReloads = getNumberOfReloads();
			if (numberOfReloads < 2) {
				sessionStorage.setItem("numberOfReloads", (numberOfReloads + 1).toString());
				window.location.reload();
			} else {
				return (
					<div style={{ width: "800px", margin: "auto", textAlign: "center", backgroundColor: "#1d2536" }}>
						<p className="text en">Application not up to date, please clear the cache and try again.</p>
						<p className="text fr">Application non à jour, merci de vider le cache et de réessayer.</p>
						<p className="text de">Anwendung nicht auf dem neuesten Stand, bitte leeren Sie den Cache und versuchen Sie es erneut.</p>
						<p className="text es">La aplicación no está actualizada, borre la caché e inténtelo de nuevo.</p>
					</div>
				);
			}
		}
	}
	
	return <>
		{general === null ? (
			<div className={"chargement_page"}>
				<img src={chargement} alt="Drapeau" />
				{t("Chargement...", { ns: "app" })}
			</div>
		) : (
			<GeneralContext.Provider value={{ general: general, setGeneral: setGeneral }}>
				<RoutesSite />
			</GeneralContext.Provider>
		)}
	</>;
}

export function Fallback() {
	return <div className={"chargement_page"}>
		<img src={chargement} alt="Drapeau" />
	</div>;
}

export const getTitrePopUpStatus = (status: string, t: (key: string, options?: { ns: string }) => string): string => {
	switch (status) {
		case Status_success:
			return t("Succès !", { ns: "app" });
		case Status_error:
			return t("Erreur !", { ns: "app" });
		case Status_warning:
			return t("Attention !", { ns: "app" });
		case Status_info:
			return t("Information", { ns: "app" });
		default:
			return "";
	}
};

function Layout() {
	const navigation = useNavigation();
	const location = useLocation();
	const navigate = useNavigate();
	const [isOnUpdate, setIsOnUpdate] = useState(false);
	const [isOnRefresh, setIsOnRefresh] = useState(false);
	const [status, setStatus] = useState<string>(Status_success);
	const [isWithButtonClose, setIsWithButtonClose] = useState(false);
	const [showPop, setShowPop] = useState(false);
	const [messagePopUp, setMessagePopUp] = useState("");
	const [titlePopUp, setTitlePopUp] = useState("");
	const [refreshKey, setRefreshKey] = useState(0);
	const { t } = useTranslation();
	
	const { general, setGeneral } = useGeneralContext();
	
	const triggerRefresh = () => {
		if (!isOnRefresh) {
			setRefreshKey(prev => prev + 1);
			setIsOnRefresh(true);
		}
	};
	
	const handleClose = () => {
		setMessagePopUp("");
		setTitlePopUp("");
		setShowPop(false);
		setStatus(Status_success);
		setIsWithButtonClose(false);
	};
	
	const checkHabilitation = useCallback((niveau1: string, niveau2?: string): boolean => {
		return niveau2 === undefined ? general.habilitation[niveau1][general.niveauHabilitation] :
			general.habilitation[niveau1][niveau2][general.niveauHabilitation];
	}, [general.habilitation, general.niveauHabilitation]);
	
	const urlRedirection = () => {
		const currentPath = location.pathname;
		const urlsToAvoid = ["/ame", "/admin", "/coalition", "/changelog", "/encyclopedie/", "espace_perso", "/event",
			"/jump", "/villes", "/chantiers", "/decharges", "/reparations", "/veilles", "/statistiques", "/contact",
			"/ruineGame"];
		
		if (urlsToAvoid.some(urlToAvoid => currentPath.includes(urlToAvoid))) {
			return null;
		}
		
		return currentPath.includes("/ruine") ? currentPath.replace(/(\/ruine)\/.*/, "$1") :
			currentPath.replace(/[0-9]+$/, sessionStorage.getItem("mapId") ?? "");
	};
	
	const resetMap = () => {
		const generalApi = new GeneralApi(0);
		generalApi.general().then((response) => {
			setGeneral(response.data.general);
			if (response.data.general.ville === null) {
				sessionStorage.setItem("mapId", null);
				// navigation vers news car il n'y a pas de ville
				navigate("/news");
			} else {
				sessionStorage.setItem("mapId",
					response.data.general.ville ? response.data.general.ville.map_id.toString() : null);
				const newPath = urlRedirection();
				if (newPath) {
					navigate(newPath);
				}
			}
			triggerRefresh();
		});
	};
	
	const updateInformation = () => {
		if (!isOnUpdate) {
			setIsOnUpdate(true);
			const generalApi = new GeneralApi(0);
			generalApi.update().then((response) => {
				if (response.data.general !== undefined) {
					setGeneral(response.data.general);
					sessionStorage.setItem("mapId",
						response.data.general.ville ? response.data.general.ville.map_id.toString() : null);
					const newPath = urlRedirection();
					if (newPath) {
						navigate(newPath);
					}
					triggerRefresh();
				}
				setIsOnUpdate(false);
			}).catch((error) => {
				setStatus(Status_error);
				setShowPop(true);
				setMessagePopUp(error.data?.lib ?? error.message ?? "Une erreur est survenue.");
				setIsOnUpdate(false);
			});
			
		}
	};
	
	//const triggerUpdate = () => updateInformation();
	
	return (
		<PopUpContext.Provider value={{
			status,
			setStatus,
			showPop,
			messagePopUp,
			setMessagePopUp,
			setShowPop,
			handleClose,
			titlePopUp,
			setTitlePopUp,
			isWithButtonClose,
			setIsWithButtonClose,
		}}>
			<GHContext.Provider value={{
				checkHabilitation: checkHabilitation,
				handleClose      : handleClose,
				isOnRefresh      : isOnRefresh,
				isOnUpdate       : isOnUpdate,
				setIsOnRefresh   : setIsOnRefresh,
				setIsOnUpdate    : setIsOnUpdate,
				triggerRefresh   : triggerRefresh,
				triggerUpdate    : triggerRefresh,
				refreshKey       : refreshKey,
			}}>
				<Helmet>
					<style>{generateCSSUser(general.themeUser, general.user?.user_personnalisation)}</style>
				</Helmet>
				<Header onActualisation={triggerRefresh} onUpdate={updateInformation} onReset={resetMap} isOnUpdate={isOnUpdate}
						isOnRefresh={isOnRefresh} />
				<div id={"content"}>
					<div id={"bloc_page"}>
						<div id="entetecorps"></div>
						<div id="corps">{(navigation.state === "loading") ? (
							<div className={"chargement_page"}><img src={chargement} alt="Drapeau" /><span>{t("Chargement...", { ns: "app" })}</span>
							</div>) : (<Outlet />)}</div>
						<div id="piedcorps"></div>
					</div>
					<Footer general={general} />
					<Modal show={showPop} onHide={handleClose} centered={true}>
						<Modal.Header className={"modal-header-" + status}>
							<Modal.Title>{titlePopUp === "" ? getTitrePopUpStatus(status, t) : titlePopUp}</Modal.Title>
						</Modal.Header>
						<Modal.Body>{HTMLParser(messagePopUp ?? "")}</Modal.Body>
						{(status === Status_error || isWithButtonClose) && <Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>{t("Fermer", { ns: "app" })}</Button>
						</Modal.Footer>}
					</Modal>
				</div>
			</GHContext.Provider>
		</PopUpContext.Provider>
	);
}

// const rootElement = document.getElementById("app");
// createRoot(rootElement).render(<App />);
//<React.StrictMode></React.StrictMode>

const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(
	<Provider store={store}>
		<I18nextProvider i18n={i18n}>
			<HelmetProvider>
				<App />
			</HelmetProvider>
		</I18nextProvider>
	</Provider>);
import React, { useEffect, useState } from "react";
import chargement                     from "../../../img/chargement.svg";
import { OptionPersoApi }             from "../../services/api/OptionPersoApi";
import OptionsPerso                   from "../../containers/OptionPerso/OptionsPerso";
import { useNavigate }                from "react-router";
import { useGeneralContext }          from "../../types/Context/GeneralContext";
import { useGHContext }               from "../../types/Context/GHContext";
import { Status_error, usePopUp }     from "../../types/Context/PopUpContext";
import { useTranslation }             from "react-i18next";

export function OptionsPersoGeneral() {
	const [optionsData, setOptionsData] = useState(null);
	const { general, setGeneral } = useGeneralContext();
	const { refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const navigate = useNavigate();
	const { t } = useTranslation();
	// Fonction pour recharger les données
	const reloadOptionsPersoData = async () => {
		const optionApi = new OptionPersoApi(parseInt(sessionStorage.getItem("mapId") ?? "0", 10));
		
		optionApi.main().then((response) => {
			setIsOnRefresh(false);
			setIsOnUpdate(false);
			if (response.data.general !== undefined) {
				setGeneral(response.data.general);
				sessionStorage.setItem("mapId", response.data.general.ville ? response.data.general.ville.map_id.toString() : null);
			}
			if (response.data.options === undefined) {
				console.error("Erreur de chargement des données options perso", response);
			} else {
				setOptionsData(response.data.options);
			}
		}).catch((error) => {
			setStatus(Status_error);
			setShowPop(true);
			setMessagePopUp(error?.data?.error);
			navigate("/news");
		});
	};
	
	useEffect(() => {
		if (general !== undefined && general.isCo) {
			reloadOptionsPersoData().then(r => r);
		} else {
			navigate("/news");
		}
	}, [refreshKey]);
	
	return (
		<>
			{optionsData === null ? (
				<div className={"chargement_page"}>
					<img src={chargement} alt="Drapeau" />
					<span>{t("Chargement...", { ns: "app" })}</span>
				</div>
			) : (
				<OptionsPerso options={optionsData} />
			)}
		
		</>
	);
}
import HTMLParser                            from "html-react-parser";
import React, { useEffect, useState }        from "react";
import SvgIcone                              from "../../../components/generality/SvgIcone";
import { CarteType }                         from "../../../types/components/ville/Carte.type";
import { useCalculKm }                       from "./calculCarte";
import { CarteApi }                          from "../../../services/api/CarteApi";
import { useNavigate }                       from "react-router";
import { InscriptionConsigneSauvegardeType } from "../../../types/components/Hotel/InscriptionExpedition.type";
import { InscriptionExpeditionApi }          from "../../../services/api/InscriptionExpeditionApi";
import { Status_error, usePopUp }            from "../../../types/Context/PopUpContext";
import { useGHContext }                      from "../../../types/Context/GHContext";
import { BatPrototypeDTO }                   from "../../../types/models/batPrototype.dto";
import { CitoyensDTO }                       from "../../../types/models/citoyens.dto";
import { ConsigneExpeditionDTO }             from "../../../types/models/consigneExpedition.dto";
import { ZoneMapDTO }                        from "../../../types/models/zoneMap.dto";
import { MapItemDTO }                        from "../../../types/models/mapItem.dto";
import { useTranslation }                    from "react-i18next";
import TooltipGH                             from "../../../components/utils/TooltipGH";
import TooltipsInfo                          from "../../../components/utils/TooltipsInfo";
import { InfoCase }                          from "../../../types/components/Outils/Camping.type";

interface DetailProps {
	zone: ZoneMapDTO;
	carte: CarteType;
	typeCarte: string;
	onSendInfoCamping: (info: InfoCase) => void;
	closePopUp: () => void;
	onSendBatiment: (zone: ZoneMapDTO) => void;
	onUpdateZone: (zone: ZoneMapDTO) => void;
	onRefresh: () => void;
}

export default function DetailCase(props: DetailProps) {
	const { t } = useTranslation();
	const zone = props.zone;
	const carte = props.carte;
	const ville = props.carte.ville;
	
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const { triggerRefresh } = useGHContext();
	const [bat_camped, setBat_camped] = useState(zone.bat === null ? null : (zone.camped ? 1 : 0));
	const [bat_empty, setBat_empty] = useState(zone.bat === null ? null : (zone.empty ? 1 : 0));
	const [bat_hypothese, setBat_hypothese] = useState(zone.bat === null ? null : zone.dig > 0 ? zone.bat_hypothese?.id ?? zone.bat?.id ?? -1 : null);
	const [bat_retour_ajax, setBat_retour_ajax] = useState("");
	const [bat_class_ajax, setBat_class_ajax] = useState("");
	const [consignes, setConsignes] = useState<ConsigneExpeditionDTO[]>([]);
	const [majOK, setMajOK] = useState(false);
	const navigate = useNavigate();
	const apiRef = new CarteApi(0);
	
	useEffect(() => {
		setBat_camped(zone.bat === null ? null : (zone.camped ? 1 : 0));
		setBat_empty(zone.bat === null ? null : (zone.empty ? 1 : 0));
		setBat_hypothese(zone.bat === null ? null : zone.dig > 0 ? zone.bat_hypothese?.id ?? zone.bat?.id ?? -1 : null);
		let consigne_case: ConsigneExpeditionDTO[] = null;
		if (zone.consigne_expeditions !== null) {
			consigne_case = zone.consigne_expeditions.filter((consigne) => carte.listExpInscrit.includes(consigne.expedition_part.trace?.id));
		}
		setConsignes(consigne_case);
	}, [zone]);
	
	const sendInfoCamping = () => {
		const km = zone.km;
		const infoCamping: InfoCase = {
			mode         : "identifie",
			citoyens     : Object.values(carte.ville.list_citoyen_ville).filter((citoyen: CitoyensDTO) => !citoyen.mort).sort((a: CitoyensDTO, b: CitoyensDTO) => a.citoyen.pseudo.localeCompare(b.citoyen.pseudo)),
			phare        : carte.constructionChantier.phare,
			devast       : carte.ville.devast,
			nuit         : true,
			nbrAmelioOD  : 0,
			zombie       : zone.zombie,
			nbrCampMax   : zone?.bat?.max_campeur ?? 99,
			hard         : carte.ville.hard,
			bat          : zone.bat,
			listBat      : carte.listBat.filter(
				(bat) => bat.km_min <= km && (
					(bat.explorable && !carte.ville.hard && km <= 10) ||  // Pour les ruines
					((!bat.explorable || carte.ville.hard) && bat.km_max >= km)                 // Pour les non-ruines ou ruine en pandé
				),
			),
			bonus_camping: zone.bat?.bonus_camping ?? -25,
			nbTas        : zone.dig ?? 0,
			km           : zone.km,
		};
		props.onSendInfoCamping(infoCamping);
	};
	
	const sendBatiment = (zone: ZoneMapDTO, camped?: number, empty?: number) => {
		zone.camped = camped === 1;
		zone.empty = empty === 1;
		
		if (zone.empty) {
			zone.status_bat = "bat-e";
		} else {
			zone.status_bat = "bat";
		}
		
		props.onSendBatiment(zone);
	};
	
	const sendInfoClose = () => {
		props.closePopUp();
	};
	
	const majBat = (camped: number, empty: number) => {
		const x = props.zone.x;
		const y = props.zone.y;
		const mapId = props.carte.ville.map_id;
		
		apiRef.majBat({ x: x, y: y, mapId: mapId, camped: camped, empty: empty }).then((result) => {
			setBat_class_ajax("successForm");
			setBat_retour_ajax(result.data.zoneRetour);
			sendBatiment(props.zone, camped, empty);
			setTimeout(() => {
				setBat_class_ajax("");
				setBat_retour_ajax("");
			}, 1500);
		}).catch((error) => {
			setBat_class_ajax("erreurForm");
			setBat_retour_ajax(error.data.error);
		});
	};
	
	const majBatHypo = (batiment_hypothese: number) => {
		const x = props.zone.x;
		const y = props.zone.y;
		const mapId = props.carte.ville.map_id;
		
		apiRef.majBatHypo({ x: x, y: y, mapId: mapId, hypo: batiment_hypothese }).then((result) => {
			setBat_class_ajax("successForm");
			setBat_retour_ajax(result.data.zoneRetour);
			setTimeout(() => {
				setBat_class_ajax("");
				setBat_retour_ajax("");
				props.onRefresh();
			}, 500);
		}).catch((error) => {
			setBat_class_ajax("erreurForm");
			setBat_retour_ajax(error.data.error);
		});
	};
	
	const listingCitoyensCase = (citoyensCase: CitoyensDTO[]) => {
		citoyensCase.sort((a: CitoyensDTO, b: CitoyensDTO) =>
			a.citoyen.pseudo
				.toLocaleUpperCase()
				.localeCompare(b.citoyen.pseudo.toLocaleUpperCase()),
		);
		return <span className={"d-flex gap-1 align-items-center justify-content-start flex-wrap"}> {citoyensCase.map((citoyen: CitoyensDTO, index: number) => (
			<span key={"citoyen_" + citoyen.citoyen.id} className={"d-flex align-items-center justify-content-center"}>
                {citoyen.ban && (<span style={{ fontWeight: "bold" }} className={"color-red d-flex gap-1 align-items-center justify-content-center"}><span>{citoyen.citoyen.pseudo}</span><SvgIcone icone={"h_" + citoyen.job.icon} /></span>)}
				{!citoyen.ban && (<span className={"d-flex gap-1 align-items-center justify-content-center"}><span>{citoyen.citoyen.pseudo}</span><SvgIcone icone={"h_" + citoyen.job.icon} /></span>)}
				{index < citoyensCase.length - 1 && <span>,</span>}
            </span>))}
        </span>;
	};
	
	const listingItemsSol = (items: MapItemDTO[]) => {
		items.sort((a: MapItemDTO, b: MapItemDTO) => {
			const aStartsWithQuote = a.item.nom.startsWith("«");
			const bStartsWithQuote = b.item.nom.startsWith("«");
			
			// Si les deux éléments commencent par ", utilisez une comparaison normale
			if (aStartsWithQuote && bStartsWithQuote) {
				return a.item.nom.localeCompare(b.item.nom);
			}
			
			// Si 'a' commence par " et pas 'b', alors 'a' doit venir après 'b'
			if (aStartsWithQuote && !bStartsWithQuote) {
				return 1;
			}
			
			// Si 'b' commence par " et pas 'a', alors 'b' doit venir après 'a'
			if (!aStartsWithQuote && bStartsWithQuote) {
				return -1;
			}
			
			// Dans tous les autres cas, utilisez une comparaison normale
			return a.item.nom.localeCompare(b.item.nom);
		});
		
		return items.map((item: MapItemDTO) => (
			<AffNbrIcon
				item={item}
				key={"obj_" + item.item.id + "_" + (item.broked ? 1 : 0)}
			/>
		));
	};
	
	const phraseMaj = (carte: CarteType, zone: ZoneMapDTO, pseudo = null, heure = null) => {
		
		if (zone.x_rel === 0 && zone.y_rel === 0) {
			return;
		}
		
		if (zone.vue === -1) {
			
			if (pseudo === null) {
				return "";
			}
			const phrase = t("Une partie du désert que vous n'avez pas encore tout à fait exploré", { ns: "ville" }) + ", ";
			
			const diffDay = carte.ville.jour - zone.marqueur_maj_day;
			
			switch (diffDay) {
				case 0:
					return (phrase + t("marqueur de la zone mise à jour aujourd'hui par {pseudo} à {heureMaj}", { ns: "ville" }).replace("{pseudo}", pseudo).replace("{heureMaj}", heure));
				case 1:
					return (phrase + t("marqueur de la zone mise à jour hier par {pseudo} à {heureMaj}", { ns: "ville" }).replace("{pseudo}", pseudo).replace("{heureMaj}", heure));
				default:
					return (phrase + t("marqueur de la zone mise à jour il y a {jour} jours par {pseudo} à {heureMaj}", { ns: "ville" }).replace("{pseudo}", pseudo).replace("{heureMaj}", heure).replace("{jour}", diffDay.toString()));
			}
		} else {
			if (zone.citoyen === null && zone.marqueur_maj_by === null) {
				return t("Pas de dernière mise à jour connue", { ns: "ville" });
			} else {
				const diffDay = carte.ville.jour - zone.day;
				
				let phrase: string;
				
				if (zone.citoyen === null) {
					phrase = "";
				} else {
					switch (diffDay) {
						case 0:
							phrase = t("Zone mise à jour aujourd'hui par {pseudo} à {heureMaj}", { ns: "ville" }).replace("{pseudo}", pseudo).replace("{heureMaj}", heure);
							break;
						case 1:
							phrase = t("Zone mise à jour hier par {pseudo} à {heureMaj}", { ns: "ville" }).replace("{pseudo}", pseudo).replace("{heureMaj}", heure);
							break;
						default:
							phrase = t("Zone mise à jour il y a {jour} jours par {pseudo} à {heureMaj}", { ns: "ville" }).replace("{pseudo}", pseudo).replace("{heureMaj}", heure).replace("{jour}", diffDay.toString());
							break;
					}
				}
				
				if (zone.day < zone.marqueur_maj_day) {
					const diffMarqueurDay = carte.ville.jour - zone.marqueur_maj_day;
					if (zone.citoyen !== null) {
						phrase += " " + t("et", { ns: "app" }) + " ";
					}
					switch (diffMarqueurDay) {
						case 0:
							phrase += t("marqueur de la zone mise à jour aujourd'hui par {pseudo} à {heureMaj}", { ns: "ville" }).replace("{pseudo}", zone.marqueur_maj_by?.pseudo ?? "").replace("{heureMaj}", zone.marqueur_maj_heure ?? "");
							break;
						case 1:
							phrase += t("marqueur de la zone mise à jour hier par {pseudo} à {heureMaj}", { ns: "ville" }).replace("{pseudo}", zone.marqueur_maj_by?.pseudo ?? "").replace("{heureMaj}", zone.marqueur_maj_heure ?? "");
							break;
						default:
							phrase += t("marqueur de la zone mise à jour il y a {jour} jours par {pseudo} à {heureMaj}", { ns: "ville" }).replace("{pseudo}", zone.marqueur_maj_by?.pseudo ?? "").replace("{heureMaj}", zone.marqueur_maj_heure ?? "").replace("{jour}", diffMarqueurDay.toString());
							break;
					}
					
					if (zone.citoyen === null) {
						// S'il n'y a pas de citoyen, on met la première lettre en majuscule
						phrase = phrase.charAt(0).toUpperCase() + phrase.slice(1);
					}
				}
				
				return phrase;
			}
		}
	};
	
	const phraseObjet = (items: MapItemDTO[]) => {
		if (items.length > 1 || (items.length === 1 && items[0].nombre > 1)) {
			return <p>{t("Objets au sol :", { ns: "ville" })}</p>;
		} else {
			return <p>{t("Objet au sol :", { ns: "ville" })}</p>;
		}
	};
	
	const zoneBatiment = (carte: CarteType, zone: ZoneMapDTO) => {
		if (carte.myVille) {
			if (zone.dig <= 0) {
				return (
					<div className="infoBatCaseCarte">
                        <span>
                            <input type="hidden" name={"val_camped_" + zone.x + "_" + zone.y} value={zone.camped ? 1 : 0} />
                            <label htmlFor={"bat_camped_" + zone.x + "_" + zone.y}>{t("Bâtiment campé :", { ns: "ville" })}</label>
                            <select id={"bat_camped_" + zone.x + "_" + zone.y} name={"bat[camped]"} value={bat_camped} onChange={(event) => {
								setBat_camped(parseInt(event.target.value));
								majBat(parseInt(event.target.value), bat_empty);
							}}>
                                <option value="1">{t("Oui", { ns: "app" })}</option>
                                <option value="0">{t("Non", { ns: "app" })}</option>
                            </select>
                        </span>
						<span>
                            <input type="hidden" name={"val_empty_" + zone.x + "_" + zone.y} value={zone.empty ? 1 : 0} />
                            <label htmlFor={"bat_empty_" + zone.x + "_" + zone.y}>{t("Bâtiment vide :", { ns: "ville" })}</label>
                            <select id={"bat_empty_" + zone.x + "_" + zone.y} name={"bat[empty]"} value={bat_empty} onChange={(event) => {
								setBat_empty(parseInt(event.target.value));
								majBat(bat_camped, parseInt(event.target.value));
							}}>
                                <option value="1">{t("Oui", { ns: "app" })}</option>
                                <option value="0">{t("Non", { ns: "app" })}</option>
                            </select>
                        </span>
					</div>
				);
			} else {
				const kmBat = useCalculKm(zone.x, zone.y, carte.ville.pos_x, carte.ville.pos_y);
				const listBatFiltrer = carte.listBat.filter((bat: BatPrototypeDTO) => bat.km_min <= kmBat && bat.km_max >= kmBat && !bat.explorable)
					.sort((a: BatPrototypeDTO, b: BatPrototypeDTO) => {
						const aBat = t(a.nom, { ns: "bats" });
						const bBat = t(b.nom, { ns: "bats" });
						return aBat.localeCompare(bBat);
					})
					.map((bat: BatPrototypeDTO) => (
						<option key={"bat_" + zone.id + "_" + bat.id} value={bat.id}>{t(bat.nom, { ns: "bats" })}</option>
					));
				
				return (
					<div className={"infoBatCaseCarte"}>
                        <span className="hypothese_bat">
                            <label htmlFor={"bat_hypo_" + zone.x + "_" + zone.y}>{t("Bâtiment hypothétique :", { ns: "ville" })}</label>
                            <select id={"bat_hypo_" + zone.x + "_" + zone.y} name={"bat[hypo]"} value={bat_hypothese ?? -1} onChange={(event) => {
								setBat_hypothese(parseInt(event.target.value));
								majBatHypo(parseInt(event.target.value));
							}}>
                                {listBatFiltrer}
                            </select>
                        </span>
					</div>
				);
			}
		} else {
			if (zone.dig <= 0) {
				return (
					<div className="infoBatCaseCarte">
						<span>{t("Bâtiment campé :", { ns: "ville" })}{" "}{zone.camped ? t("Oui", { ns: "app" }) : t("Non", { ns: "app" })}</span>
						<span>{t("Bâtiment vide :", { ns: "ville" })}{" "}{zone.empty ? t("Oui", { ns: "app" }) : t("Non", { ns: "app" })}</span>
					</div>
				);
			}
		}
	};
	
	const zoneMaj = (carte: CarteType, zone: ZoneMapDTO) => {
		return (
			<div className="infoMiseAJour">
                <span className="phaseMaj">
                    {zone.vue !== -1 && phraseMaj(carte, zone, zone.citoyen?.pseudo, zone.heure_maj)}
					{zone.vue === -1 && phraseMaj(carte, zone, zone.marqueur_maj_by?.pseudo, zone.marqueur_maj_heure)}
                </span>
				{carte.myVille && <span className="btnMajCase">
                    <button className="majCaseCarte btn btn-success btn-xs" onClick={() => props.onUpdateZone(zone)} id={"maj-" + zone.x + "_" + zone.y}>{t("Mise jour", { ns: "ville" })}</button>
                </span>}
			</div>
		);
	};
	
	const handleRedirectionRuine = (idRuine) => {
		navigate(`/ruine/${idRuine}/${carte.ville.map_id}`);
	};
	
	const handleMajConsigne = (valid: boolean, consigne: ConsigneExpeditionDTO) => {
		const consigneMaj: InscriptionConsigneSauvegardeType = {
			consigne_id       : consigne.id,
			valide            : valid,
			expedition_part_id: consigne.expedition_part.id,
		};
		const expeditionApi = new InscriptionExpeditionApi(carte.ville.map_id ?? 0);
		expeditionApi.sauvegarderConsigneExpedition(consigneMaj).then((result) => {
			setMajOK(true);
			setTimeout(() => {
				setMajOK(false);
				triggerRefresh();
			}, 1500);
		}).catch((error) => {
			setStatus(Status_error);
			setMessagePopUp(error.data?.error ?? error.message ?? error);
			setShowPop(true);
		});
	};
	
	const xNorm = zone.x - ville.pos_x;
	const yNorm = ville.pos_y - zone.y;
	
	const paAller = Math.abs(xNorm) + Math.abs(yNorm);
	let paRetour: number;
	let reducPa = 0;
	if (carte.lvlTdG === 4) {
		reducPa = 2;
	}
	if (carte.lvlTdG === 5) {
		reducPa = 3;
	}
	
	if (reducPa !== 0 && (xNorm === 0 || yNorm === 0)) {
		reducPa--;
	}
	
	if (paAller < reducPa) {
		paRetour = paAller;
	} else {
		paRetour = 2 * paAller - reducPa;
	}
	
	const citoyensCase = ville.list_citoyen_vie[zone.y * 100 + zone.x];
	const itemsCase = zone.items;
	
	
	return (
		<div className={"detailCase"} id={"case_" + zone.x + "_" + zone.y} key={"case_" + zone.x + "_" + zone.y}>
			<div className="infoCase">
				<div className="enteteInfoCase">
                    <span className="coordPopUp">{t("Coordonnées", { ns: "ville" })} :&nbsp;{" "}
						<span className="coordInfoCase">{xNorm}/{yNorm}</span>
                    </span>
					<span className="distancePopup">
                        {t("Distance", { ns: "ville" })} :&nbsp;{" "}
						<span className="distanceInfoCase d-flex gap-1 align-items-center">
                            <span className={"d-flex align-items-center"}>
                                <span>{useCalculKm(zone.x, zone.y, ville.pos_x, ville.pos_y)}</span>
                                <span> km (</span>
                                <span>{paAller}</span>
                                <span>/</span>
                                <span>{paRetour}</span>
                                <SvgIcone icone={"h_pa"} />
                                <span>)</span>
                            </span>
							{paRetour !== paAller * 2 && <span><TooltipsInfo label={t("Il s'agit des PA aller et PA aller-retour en prenant en compte le rapatriement de la Plateforme d'Observation", { ns: "ville" })} /></span>}
                        </span>
                    </span>
					<span className="quitPopUp fondWhite02" onClick={sendInfoClose}>&#10007;</span>
				</div>
				{zone.vue === -1 && !(zone.x_rel === 0 && zone.y_rel === 0) && zone.item_sol.length === 0 && t("Une partie du désert que vous n'avez pas encore exploré.", { ns: "ville" })}
				{zone.vue === -1 && carte.myVille && zoneMaj(carte, zone)}
				{zone.vue === -2 && citoyensCase !== undefined && (
					<div className="citoyensPresentCaseVille">
						<p>{t("Citoyen en ville ou aux portes :", { ns: "ville" })} </p>
						{listingCitoyensCase(citoyensCase)}
					</div>
				)}
				{zone.vue >= 0 && (<div className="infoCaseDetail">
                        <span className={"d-flex gap-1 align-items-center justify-content-start"}>
                            <span>{t("Contrôle de la case", { ns: "ville" })} :{" "}</span>
                            <span className="zombInfoCase d-flex gap-1 align-items-center justify-content-center">
                                <span>{zone.pdc}</span>
                                <SvgIcone icone={"h_guard"} /><span>/</span>
                                <span>{zone.zombie}</span>
                                <SvgIcone icone={"h_zombie"} />
                            </span>
                        </span>
						<span className="statutCaseDetailCaseVille">{t("Statut de la case :", { ns: "ville" })}&nbsp;<span className="statutInfoCase">{zone.dried ? 1 : 0}</span>{zone.dried ? t("Epuisée", { ns: "ville" }) : t("Non-épuisée", { ns: "ville" })}</span>
						<span className="statutCaseDetailCaseVille">
                            {t("Balisage de la case :", { ns: "ville" })}&nbsp;
							<span className="zombInfoCase">{zone.lvl_balisage === null ? 0 : zone.lvl_balisage}</span>
                        </span>
					</div>
				)}
				{zone.vue >= 0 && citoyensCase !== undefined && (<div className="citoyensPresentCaseVille">
						<p>{t("Citoyens présents :", { ns: "ville" })} </p>
						{listingCitoyensCase(citoyensCase)}
					</div>
				)}
				{zone.item_sol.length > 0 && itemsCase !== null && (<div className="objetSolCaseVille">
						{phraseObjet(itemsCase)}
						<div className="listObjetSolCaseVille">
							{listingItemsSol(itemsCase)}
						</div>
					</div>
				)}
				{zone.vue >= 0 && zoneMaj(carte, zone)}
				{zone.bat !== null && (<div className="batimentCaseVille">
						<div className="seperateurHorizontal"></div>
						<div className="nomBatCaseCarte d-flex gap-1 justify-content-center mt-1 mb-1">
							<span>{t(zone.bat.nom, { ns: "bats" })}{" "}</span>
							{zone.dig > 0 && <span className={"d-flex gap-1"}>
                                <span>(</span>
                                <span>{zone.dig}</span>
                                <SvgIcone icone={"small_dig"} />
                                <span>)</span>
                            </span>}
						</div>
						<div className="infoDiverseBatCaseCarte">
							<div className="descBatCaseCarte">
								{HTMLParser(t(zone.bat.description, { ns: "bats" }))}
							</div>
							{!zone.bat.explorable && zoneBatiment(carte, zone)}
							<div className={"retourAjaxMaj " + bat_class_ajax}>
								{bat_retour_ajax}
							</div>
						</div>
					</div>
				)}
				<div className={"zone_bouton_detail_carte"}>
					{props.carte.campingActif && !(xNorm === 0 && yNorm === 0) && (
						<button className={"majCaseCarte btn btn-warning btn-xs"} onClick={sendInfoCamping}>{t("Calculer camping", { ns: "ville" })}</button>
					)}
					{zone.bat !== null && props.carte.listRuine[zone.bat.id] !== undefined && !(xNorm === 0 && yNorm === 0) && (
						<button className={"majCaseCarte btn btn-secondary btn-xs"} onClick={() => handleRedirectionRuine(props.carte.listRuine[zone.bat.id])}>{t("Plan de la ruine", { ns: "ville" })}</button>
					)}
				</div>
				{consignes !== null && consignes.length > 0 && <div className="consigneCaseVille">
					<div className="seperateurHorizontal"></div>
					<div className={"zone_consigne_exepedition"}>
						<div id={"text_consigne_titre"}>{t("Consigne(s)", { ns: "ville" })}</div>
						{consignes.map((consigne, index) => {
							return <div key={"consigne_" + index} className={"ligne_consigne_instruction"}>
                                <span><input type={"checkbox"} checked={consigne.fait} disabled={carte.carteOptionPerso} onChange={(event) => {
									event.stopPropagation();
									const newConsignes = [...consignes];
									newConsignes[index].fait = event.target.checked;
									setConsignes(newConsignes);
									handleMajConsigne(event.target.checked, consigne);
								}} /></span>
								<span>{HTMLParser(consigne.text ?? "")}</span>
							</div>;
						})}
						{majOK && <div id={"text_retour_maj"} className={"color-green"}>{t("Mise à jour ok", { ns: "ville" })}</div>}
					</div>
				</div>}
			</div>
		</div>
	);
}

export function AffNbrIcon(props: {
	item: MapItemDTO;
}) {
	let typeName = "";
	let classImage: string;
	
	const item = props.item;
	
	if (item.item.type_objet?.id === 3) {
		classImage = "videImg4";
		typeName = "";
	} else {
		if (item.item.id >= 2000 && item.item.id < 3000) {
			classImage = "videImg3";
			typeName = "";
		} else {
			if (item.broked) {
				classImage = "videImg2";
				typeName = "";
			} else {
				classImage = "videImg";
			}
		}
	}
	
	const infoBulle = (
		<span className={"info"}>
      {item.item.nom} {typeName} (id : {item.item.id})
    </span>
	);
	
	return (
		<div className={"videItem"}>
			<TooltipGH>
                <span className={classImage}>
                    <SvgIcone icone={item.item.icon} />
                </span>
				{infoBulle}
			</TooltipGH>
			<span className={"nbrItems"}>{item.nombre}</span>
		</div>
	);
}

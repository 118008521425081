import { RefObject, useCallback, useState } from "react";

interface PlacementState {
	vertical: "top" | "bottom";
	horizontal: "left" | "right";
}

interface ArrowPosition {
	top?: number;
	bottom?: number;
	left?: number;
	right?: number;
}

interface TooltipPosition {
	container: {
		top: number;
		left: number;
	};
	arrow: ArrowPosition;
}

interface TooltipPositionConfig {
	width: number;
	spacingTop?: number;
	spacingLeft?: number;
	arrowSize?: number;
}

export const useTooltipPosition = (
	tooltipRef: RefObject<HTMLElement>,
	{ width, spacingTop = 10, spacingLeft = 0, arrowSize = 7 }: TooltipPositionConfig,
) => {
	const [position, setPosition] = useState<TooltipPosition>({
		container: { top: 0, left: 0 },
		arrow    : {},
	});
	const [placement, setPlacement] = useState<PlacementState>({
		vertical  : "bottom",
		horizontal: "right",
	});
	const [visiblePosition, setVisiblePosition] = useState(false);
	
	const calculatePosition = useCallback((element: HTMLElement) => {
		const rect = element.getBoundingClientRect();
		const viewportWidth = window.innerWidth;
		const viewportHeight = window.innerHeight;
		// Position horizontale
		let x = rect.width + spacingLeft;
		let horizontalPlacement: "left" | "right" = "right";
		if (rect.right + spacingLeft + width > viewportWidth) {
			const leftPosition = rect.right - spacingLeft - width;
			if (leftPosition >= 0) {
				x = -spacingLeft - width;
				horizontalPlacement = "left";
			}
		}
		
		// Position verticale
		let y = rect.height + spacingTop;
		let verticalPlacement: "top" | "bottom" = "bottom";
		
		if (tooltipRef.current) {
			const tooltipHeight = tooltipRef.current.offsetHeight;
			const spaceBelow = viewportHeight - rect.bottom;
			
			if (spaceBelow < tooltipHeight + spacingTop) {
				if (rect.top > tooltipHeight + spacingTop) {
					y = -tooltipHeight - spacingTop;
					verticalPlacement = "top";
				} else {
					y = rect.height + spacingTop;
					verticalPlacement = "bottom";
				}
			} else {
				y = rect.height + spacingTop;
				verticalPlacement = "bottom";
			}
			setVisiblePosition(true);
		}
		
		// Calculer la position de la flèche
		const arrowPosition: ArrowPosition = {};
		
		// Position verticale de la flèche
		if (verticalPlacement === "bottom") {
			arrowPosition.top = -arrowSize;
		} else {
			arrowPosition.bottom = -arrowSize;
		}
		
		// Position horizontale de la flèche
		if (horizontalPlacement === "right") {
			arrowPosition.left = 0;
		} else {
			arrowPosition.right = 0;
		}
		
		setPosition({
			container: { top: y, left: x },
			arrow    : arrowPosition,
		});
		setPlacement({ vertical: verticalPlacement, horizontal: horizontalPlacement });
		
	}, [tooltipRef, width, spacingTop, arrowSize]);
	
	const updatePositionAfterRender = useCallback((element: HTMLElement) => {
		requestAnimationFrame(() => {
			calculatePosition(element);
		});
	}, [calculatePosition]);
	
	return {
		containerPosition: position.container,
		arrowPosition    : position.arrow,
		placement,
		updatePosition   : calculatePosition,
		updatePositionAfterRender,
		visiblePosition,
	};
};
import { Fetch }                              from "../../components/generality/Fetch";
import { ListPlansObtenu, PlansChantierType } from "../../types/components/Hotel/PlansChantier.type";
import { GeneralType }                        from "../../types/components/Generality/General.type";
import { VilleDTO }                           from "../../types/models/ville.dto";


export class PlansChantierApi {
	
	private fetch: Fetch;
	
	constructor(mapId?: number) {
		this.fetch = new Fetch("plans_chantier", mapId ?? 0);
	}
	
	public sauvegarde(data: {
		mapId: number,
		userId: number,
		listPlans: ListPlansObtenu[]
	}): Promise<RetourSauvegardePlans> {
		return this.fetch.from("/majPlans").request().post(data);
	}
	
	public main(mapId?: string): Promise<AffichageTourDeGuet> {
		return this.fetch.from("/" + ((mapId) ? mapId : "")).request().get();
	}
	
}

export type RetourSauvegardePlans = {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		ville: VilleDTO,
		phrase: string,
	},
}

export type AffichageTourDeGuet = {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		plansChantier: PlansChantierType
		general: GeneralType,
	},
}
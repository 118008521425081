import React              from "react";
import { useTranslation } from "react-i18next";

export default function AppercuCarteTaille({ tailleCase }: { tailleCase: number }) {
	const { t } = useTranslation();
	
	const grandeVille = tailleCase * 27 > 1144 ? 1144 : tailleCase * 27;
	const petiteVille = tailleCase * 14 * 1.35 > 780 ? 780 : tailleCase * 14 * 1.35;
	
	return (
		<div id={"option_apercu_taille_carte"}>
			<div id={"option_apercu_taille_carte_horizontal"}>
				<div>{t("Horizontalement", { ns: "perso" })}</div>
				<div style={{ width: `${grandeVille + 50}px`, height: `25px`, backgroundColor: "blue" }}></div>
				<div style={{ width: `${petiteVille + 50}px`, height: `25px`, backgroundColor: "red" }}></div>
			</div>
			<div>
				<div>{t("Verticalement", { ns: "perso" })}</div>
				<div id={"option_apercu_taille_carte_vertical"}>
					<div style={{ height: `${grandeVille + 50}px`, width: `25px`, backgroundColor: "blue" }}></div>
					<div style={{ height: `${petiteVille + 50}px`, width: `25px`, backgroundColor: "red" }}></div>
				</div>
			</div>
		</div>
	);
}
import * as React                                                                                                                from "react";
import { Create, Datagrid, Edit, List, NumberField, NumberInput, SimpleForm, TextField, TextInput, useDelete, useRecordContext } from "react-admin";
import { Link, useParams }                                                                                                       from "react-router";
import { Button }                                                                                                                from "@mui/material";
import { BiEditAlt }                                                                                                             from "react-icons/bi";
import { BsDatabaseAdd, BsTrash }                                                                                                from "react-icons/bs";

const EditCategorieButton = () => {
	const categorie = useRecordContext();
	return (
		<Button
			component={Link}
			to={`/admin/items/categorie/${categorie?.id}`}
			color="primary"
		>
			<BiEditAlt />
			Editer
		</Button>
	);
};

const DeleteCategorieButton = () => {
	const record = useRecordContext();
	const [deleteOne, { isPending }] = useDelete(
		"items_categorie",
		{ id: record.id, previousData: record },
	);
	const handleClick = () => {
		deleteOne().then(r => r);
	};
	
	return (
		<Button
			onClick={handleClick}
			disabled={isPending}
			color="error"
		>
			<BsTrash />
			Supprimer
		</Button>
	);
};

const AddCategorieButton = () => (
	<Button
		component={Link}
		to="/admin/items/categorie/create"
		color="primary"
		startIcon={<BsDatabaseAdd />}
	>
		Ajouter une catégorie
	</Button>
);


export const CategorieList = (props) => {
	
	
	const PostTitle = () => {
		return <span>Liste des categories objets</span>;
	};
	
	return <List {...props} title={<PostTitle />} resource="items_categorie">
		<Datagrid>
			<TextField source="id" />
			<TextField source={"nom"} />
			<EditCategorieButton />
			<DeleteCategorieButton />
		</Datagrid>
		<AddCategorieButton />
	</List>;
};


export const CategorieEdit = (props) => {
	
	const { id } = useParams();
	
	const PostTitle = () => {
		const record = useRecordContext();
		return <span>Edition categorie {record ? `"${record.nom}"` : ""}</span>;
	};
	
	return (<Edit {...props} title={<PostTitle />} resource={"items_categorie"} id={id} redirect={"admin/items/categorie"}>
		<SimpleForm>
			<NumberField source={"id"} label={"ID"} />
			<TextInput source={"nom"} label={"Nom"} />
		</SimpleForm>
	</Edit>);
};

export const CategorieCreate = (props) => (
	<Create {...props} title="Créer une nouvelle catégorie" resource={"items_categorie"}>
		<SimpleForm>
			<NumberInput name={"id"} label={"ID"} source={"id"} />
			<TextInput name={"nom"} label="Nom" source={"nom"} />
		</SimpleForm>
	</Create>
);
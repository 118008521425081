import { FormCitoyens, ListIdPouvoir } from "../../../types/components/Hotel/Citoyens.type";
import { AffNbrIcon, Avatar }          from "../../../components/generality/ComposantGeneral";
import SvgIcone                        from "../../../components/generality/SvgIcone";
import React                           from "react";

import { InputFormCitoyen, LibCoordonneCitoyen } from "./CitoyensUtils";
import HTMLParser                                from "html-react-parser";
import { CitoyensDTO }                           from "../../../types/models/citoyens.dto";
import { UserDTO }                               from "../../../types/models/user.dto";
import { VilleDTO }                              from "../../../types/models/ville.dto";
import { useTranslation }                        from "react-i18next";
import { CoffreCitoyenDTO }                      from "../../../types/models/coffreCitoyen.dto";
import { HerosSkillLevelDTO }                    from "../../../types/models/herosSkillLevel.dto";
import { useGeneralContext }                     from "../../../types/Context/GeneralContext";
import CompetencesBadge                          from "../../../components/Citoyens/CompetencesBadge";
import TooltipGH                                 from "../../../components/utils/TooltipGH";
import { FontAwesomeIcon }                       from "@fortawesome/react-fontawesome";
import { faBoxesPacking }                        from "@fortawesome/free-solid-svg-icons";
import Button                                    from "../../../components/utils/Button";

export function LigneCitoyenVivantCompact(props: {
	citIdUpdate: number | null;
	citoyen: CitoyensDTO;
	formCitoyens: FormCitoyens;
	index: number;
	listIdPouvoir: ListIdPouvoir;
	maLigne: boolean;
	moyenContact: string[];
	nameFormIsUpdate: string | null;
	onJoueurChange: (
		updatedJoueur: CitoyensDTO,
		index: number,
		nameForm: string,
	) => void;
	onMajCoffre: (citoyen: CitoyensDTO, index: number) => void;
	user: UserDTO;
	ville: VilleDTO;
	listSkillLevel: HerosSkillLevelDTO[];
	choiceCheck: boolean;
	listCitoyenCheck: number[];
	handleCheckCitoyen: (citoyenId: number) => void;
}) {
	const { t } = useTranslation();
	const { general } = useGeneralContext();
	const citIdUpdate = props.citIdUpdate;
	const citoyen = props.citoyen;
	const formCitoyens = props.formCitoyens;
	const index = props.index;
	const listIdPouvoir = props.listIdPouvoir;
	const maLigne = props.maLigne;
	const moyenContact = props.moyenContact;
	const nameFormIsUpdate = props.nameFormIsUpdate;
	const user = props.user;
	const ville = props.ville;
	
	let date_maj_string;
	if (citoyen.date_maj !== null) {
		const date_maj = new Date(citoyen.date_maj);
		date_maj_string =
			date_maj.toLocaleDateString() +
			" - " +
			date_maj.toLocaleTimeString().replace(/:\d\d$/, "");
	}
	
	let blocMaj = false;
	
	if (
		(props.citoyen.citoyen.user_personnalisation.bloc_maj_citoyens || props.user.user_personnalisation.bloc_maj_citoyen) &&
		citoyen.citoyen.id !== props.user.id
	) {
		blocMaj = true;
	}
	
	return (
		<tr className={"lignCitoyenVReduit " + (maLigne ? "maLigne" : index % 2 === 0 ? "fondWhite02" : "fondBlack02")}
			id={"id_" + citoyen.citoyen.id}
			key={"id_" + citoyen.citoyen.id}
		>
			<td className="citoyen_tab_reduit_avatar">
				<TooltipGH>
                    <span className="infoBulle">
                        <Avatar url={citoyen.citoyen.avatar} classAvatar={"img_cit_reduit"} />
                    </span>
					<span className="infoImg"><Avatar url={citoyen.citoyen.avatar} classAvatar={"img_cit_zoom"} /></span>
				</TooltipGH>
			
			</td>
			<td className="citoyen_tab_reduit_pseudo">
                <span className={"d-flex gap-1 align-items-center justify-content-start ms-1"}>
                    {citoyen.ban && (<span><SvgIcone icone={"r_ban"} /></span>)}
					<span className={"pseudoCitoyenHV" + (citoyen.ban ? " citoyenBanni" : "")}>
                        <a href={"/ame/" + citoyen.citoyen.id_my_hordes}>{citoyen.citoyen.pseudo}</a>
                    </span>
                    <TooltipGH>
                        <span className="infoBulle icone_citoyen_centre">
                            <SvgIcone icone={"h_" + citoyen.job.icon} />
                        </span>
                        <span className="info">{citoyen.job.nom}</span>
                    </TooltipGH>
					
					{citoyen.message !== null && citoyen.message !== "" && (<TooltipGH>
                        <span className="infoBulle">
                            <SvgIcone icone={"small_chat"} />
                        </span>
						<span className="info infoBulleMessage">{citoyen.message}</span>
					</TooltipGH>)}
                </span>
			
			</td>
			{props.listSkillLevel.map((skillLevel) => {
				const skill = citoyen.skill.length > 0 ? citoyen.skill.find((skill) => skill.heros_skill_type.id === skillLevel.heros_skill_type.id) ?? citoyen.citoyen.skill_type.find((skill) => skill.heros_skill_type.id === skillLevel.heros_skill_type.id) : citoyen.citoyen.skill_type.find((skill) => skill.heros_skill_type.id === skillLevel.heros_skill_type.id);
				return <td key={skillLevel.id} className="citoyen_tab_reduit_icone">
					{citoyen.job.id !== 2 && <CompetencesBadge skillLevel={skill ?? skillLevel} personnalisation={general.themeUser} />}
				</td>;
			})}
			<td className="citoyen_tab_reduit_pos">
				<span><LibCoordonneCitoyen citoyen={citoyen} ville={ville} /></span>
			</td>
			<td className="citoyen_tab_reduit_icone">
				{citoyen.citoyen.tem_arma && (<i className="fa-solid fa-check color-green icone_citoyen_centre"></i>)}
			</td>
			{/*<td className="citoyen_tab_reduit_icone">{citoyen.citoyen.legend && (<i className="fa-solid fa-check color-green icone_citoyen_centre"></i>)}</td>*/}
			<td className="citoyen_tab_reduit_icone">{moyenContact[citoyen.citoyen.id] && (<div className="moyenContactCitoyenV icone_citoyen_centre">
				<TooltipGH>
                    <span className="infoBulle">
                        <i className="fa-solid fa-phone"></i>
                    </span>
					<span className="info">{HTMLParser(moyenContact[citoyen.citoyen.id])}</span>
				</TooltipGH>
			</div>)}
			</td>
			<td className={"citoyen_tab_reduit_select"}>
				<div className={nameFormIsUpdate === "lvl_ruine" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					<InputFormCitoyen
						type={formCitoyens.divers.lvlRuine.type}
						user={user}
						name={"lvl_ruine"}
						citoyen={citoyen}
						choices={formCitoyens.divers.lvlRuine.choices}
						index={index}
						onJoueurChange={props.onJoueurChange}
					/>
				</div>
			</td>
			<td className={"citoyen_tab_reduit_select"}>
				<div className={nameFormIsUpdate === "nb_camping" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					<InputFormCitoyen
						type={formCitoyens.divers.nbCamping.type}
						name={"nb_camping"}
						citoyen={citoyen}
						user={user}
						choices={formCitoyens.divers.nbCamping.choices}
						index={index}
						onJoueurChange={props.onJoueurChange}
					/>
				</div>
			</td>
			<td className={"citoyen_tab_reduit_coche"}>
				<div className={nameFormIsUpdate === "immuniser" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					<InputFormCitoyen
						type={formCitoyens.divers.immuniser.type}
						name={"immuniser"}
						citoyen={citoyen}
						index={index}
						user={user}
						onJoueurChange={props.onJoueurChange}
					/>
				</div>
			</td>
			<td className={"citoyen_tab_reduit_coche"}>
				<div className={nameFormIsUpdate === "shoes" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					<InputFormCitoyen
						type={formCitoyens.divers.shoes.type}
						name={"shoes"}
						citoyen={citoyen}
						index={index}
						user={user}
						onJoueurChange={props.onJoueurChange}
					/>
				</div>
			</td>
			<td className={"citoyen_tab_reduit_select"}>
				{citoyen.citoyen.der_pouv.ordre_recup >= listIdPouvoir.poss_apag && (
					<div className={nameFormIsUpdate === "charge_apag" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
						<InputFormCitoyen
							type={formCitoyens.divers.chargeApag.type}
							name={"charge_apag"}
							citoyen={citoyen}
							user={user}
							choices={formCitoyens.divers.chargeApag.choices}
							index={index}
							onJoueurChange={props.onJoueurChange}
						/>
					</div>
				)}
			</td>
			<td className={"citoyen_tab_reduit_coche"}>
				<div className={nameFormIsUpdate === "rdh" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					<InputFormCitoyen
						type={formCitoyens.pouv.rdh.type}
						name={"rdh"}
						citoyen={citoyen}
						index={index}
						user={user}
						onJoueurChange={props.onJoueurChange}
					/>
				</div>
			</td>
			<td className={"citoyen_tab_reduit_coche"}>
				<div className={nameFormIsUpdate === "uppercut" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					<InputFormCitoyen
						type={formCitoyens.pouv.uppercut.type}
						user={user}
						name={"uppercut"}
						citoyen={citoyen}
						index={index}
						onJoueurChange={props.onJoueurChange}
					/>
				</div>
			</td>
			<td className={"citoyen_tab_reduit_coche"}>
				<div className={nameFormIsUpdate === "sauvetage" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					<InputFormCitoyen
						type={formCitoyens.pouv.sauvetage.type}
						user={user}
						name={"sauvetage"}
						citoyen={citoyen}
						index={index}
						onJoueurChange={props.onJoueurChange}
					/>
				</div>
			</td>
			<td className={"citoyen_tab_reduit_coche"}>
				<div className={nameFormIsUpdate === "don_jh" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					{citoyen.citoyen.der_pouv.ordre_recup >= listIdPouvoir.camaraderie &&
						citoyen.citoyen.nb_charge_camaraderie > 0 && (
							<InputFormCitoyen
								type={formCitoyens.pouv.donJh.type}
								user={user}
								name={"don_jh"}
								citoyen={citoyen}
								index={index}
								onJoueurChange={props.onJoueurChange}
							/>
						)}
				</div>
			</td>
			<td className={"citoyen_tab_reduit_coche"}>
				<div className={nameFormIsUpdate === "charge_camaraderie" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					<InputFormCitoyen
						type={formCitoyens.pouv.camaraderie_recu.type}
						user={user}
						name={"charge_camaraderie"}
						citoyen={citoyen}
						index={index}
						onJoueurChange={props.onJoueurChange}
					/>
				</div>
			</td>
			<td className={"citoyen_tab_reduit_coche"}>
				<div className={nameFormIsUpdate === "corps_sain" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					{citoyen.citoyen.der_pouv.ordre_recup >= listIdPouvoir.corps_sain && (
						<InputFormCitoyen
							type={formCitoyens.pouv.corpsSain.type}
							user={user}
							name={"corps_sain"}
							citoyen={citoyen}
							index={index}
							onJoueurChange={props.onJoueurChange}
						/>
					)}
				</div>
			</td>
			<td className={"citoyen_tab_reduit_coche"}>
				<div className={nameFormIsUpdate === "second_souffle" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					{citoyen.citoyen.der_pouv.ordre_recup >=
						listIdPouvoir.second_souffle && (
							<InputFormCitoyen
								type={formCitoyens.pouv.secondSouffle.type}
								user={user}
								name={"second_souffle"}
								citoyen={citoyen}
								index={index}
								onJoueurChange={props.onJoueurChange}
							/>
						)}
				</div>
			</td>
			<td className={"citoyen_tab_reduit_coche"}>
				<div className={nameFormIsUpdate === "pef" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					{citoyen.citoyen.tem_arma && (
						<InputFormCitoyen
							type={formCitoyens.pouv.pef.type}
							name={"pef"}
							user={user}
							citoyen={citoyen}
							index={index}
							onJoueurChange={props.onJoueurChange}
						/>
					)}
				</div>
			</td>
			<td className={"citoyen_tab_reduit_coche"}>
				<div className={nameFormIsUpdate === "vlm" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					{citoyen.citoyen.der_pouv.ordre_recup >= listIdPouvoir.vlm && (
						<InputFormCitoyen
							type={formCitoyens.pouv.vlm.type}
							name={"vlm"}
							user={user}
							citoyen={citoyen}
							index={index}
							onJoueurChange={props.onJoueurChange}
						/>
					)}
				</div>
			</td>
			<td className={"citoyen_tab_reduit_coche"}>
				<div className={nameFormIsUpdate === "trouvaille" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					{
						<React.Fragment>
							{citoyen.citoyen.der_pouv.ordre_recup <
								listIdPouvoir.trouvaille_ame && (
									<InputFormCitoyen
										type={formCitoyens.pouv.trouvaille.type}
										user={user}
										name={"trouvaille"}
										citoyen={citoyen}
										index={index}
										onJoueurChange={props.onJoueurChange}
									/>
								)}
							{citoyen.citoyen.der_pouv.ordre_recup >=
								listIdPouvoir.trouvaille_ame && (
									<InputFormCitoyen
										type={formCitoyens.pouv.trouvaille_a.type}
										user={user}
										name={"trouvaille"}
										citoyen={citoyen}
										index={index}
										onJoueurChange={props.onJoueurChange}
									/>
								)}
						</React.Fragment>
					}
				</div>
			</td>
			<td className="citoyen_tab_reduit_selectHab">
				<TooltipGH>
                    <span className="infoBulle habitation_icone">
                        <SvgIcone icone={"home_" + citoyen.lvl_maison.icon} />
                    </span>
					<span className="info">{citoyen.lvl_maison.nom}</span>
				</TooltipGH>
			</td>
			<td className={"citoyen_tab_reduit_select"}>
				<div className={nameFormIsUpdate === "lvl_coin_sieste" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					{(citoyen.lvl_maison.id > 1 || ville.devast) && (
						<InputFormCitoyen
							type={formCitoyens.hab.lvlCoinSieste.type}
							name={"lvl_coin_sieste"}
							citoyen={citoyen}
							user={user}
							choices={formCitoyens.hab.lvlCoinSieste.choices}
							index={index}
							onJoueurChange={props.onJoueurChange}
							disabled={ville.devast}
						/>
					)}
				</div>
			</td>
			<td className={"citoyen_tab_reduit_select"}>
				<div className={nameFormIsUpdate === "lvl_cuisine" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					{(citoyen.lvl_maison.id > 1 || ville.devast) && (
						<InputFormCitoyen
							type={formCitoyens.hab.lvlCuisine.type}
							name={"lvl_cuisine"}
							citoyen={citoyen}
							choices={formCitoyens.hab.lvlCuisine.choices}
							index={index}
							user={user}
							onJoueurChange={props.onJoueurChange}
							disabled={ville.devast}
						/>
					)}
				</div>
			</td>
			<td className={"citoyen_tab_reduit_select"}>
				<div className={nameFormIsUpdate === "lvl_labo" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					{(citoyen.lvl_maison.id > 1 || ville.devast) && (
						<InputFormCitoyen
							type={formCitoyens.hab.lvlLabo.type}
							name={"lvl_labo"}
							citoyen={citoyen}
							choices={formCitoyens.hab.lvlLabo.choices}
							index={index}
							user={user}
							onJoueurChange={props.onJoueurChange}
							disabled={ville.devast}
						/>
					)}
				</div>
			</td>
			<td className={"citoyen_tab_reduit_select"}>
				<div className={nameFormIsUpdate === "lvl_rangement" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					{(citoyen.lvl_maison.id > 1 || ville.devast) && (
						<InputFormCitoyen
							type={formCitoyens.hab.lvlRangement.type}
							name={"lvl_rangement"}
							citoyen={citoyen}
							choices={formCitoyens.hab.lvlRangement.choices}
							index={index}
							user={user}
							onJoueurChange={props.onJoueurChange}
							disabled={ville.devast}
						/>
					)}
				</div>
			</td>
			<td className={"citoyen_tab_reduit_select"}>
				<div className={nameFormIsUpdate === "lvl_renfort" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					{(citoyen.lvl_maison.id > 1 || ville.devast) && (
						<InputFormCitoyen
							type={formCitoyens.hab.lvlRenfort.type}
							name={"lvl_renfort"}
							citoyen={citoyen}
							choices={formCitoyens.hab.lvlRenfort.choices}
							index={index}
							user={user}
							onJoueurChange={props.onJoueurChange}
							disabled={ville.devast}
						/>
					)}
				</div>
			</td>
			<td className={"citoyen_tab_reduit_coche"}>
				<div className={nameFormIsUpdate === "cloture" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					{(citoyen.lvl_maison.id > 1 || ville.devast) && (
						<InputFormCitoyen
							type={formCitoyens.hab.cloture.type}
							name={"cloture"}
							citoyen={citoyen}
							index={index}
							user={user}
							onJoueurChange={props.onJoueurChange}
							disabled={ville.devast}
						/>
					)}
				</div>
			</td>
			<td className={"citoyen_tab_reduit_input"}>
				<div className={nameFormIsUpdate === "nb_carton" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					<InputFormCitoyen
						type={formCitoyens.hab.nbCarton.type}
						name={"nb_carton"}
						citoyen={citoyen}
						index={index}
						onJoueurChange={props.onJoueurChange}
						user={user}
					/>
				</div>
			</td>
			<td className={"citoyen_tab_reduit_input"}>
				<div className={nameFormIsUpdate === "nb_barricade" && citIdUpdate === citoyen.citoyen.id ? " updated_cit_form" : null}>
					<InputFormCitoyen
						type={formCitoyens.hab.nbBarricade.type}
						name={"nb_barricade"}
						citoyen={citoyen}
						index={index}
						onJoueurChange={props.onJoueurChange}
						user={user}
					/>
				</div>
			</td>
			<td className="citoyen_tab_reduit_coffre">
				<div className="regroupementCoffre">
					<div className="zoneCoffre" id={"coffre_" + citoyen.citoyen.id}>
						{citoyen.coffres.sort((a: CoffreCitoyenDTO, b: CoffreCitoyenDTO) => {
							const aName = t(a.item.nom, { ns: "game" }).toLowerCase();
							const bName = t(b.item.nom, { ns: "game" }).toLowerCase();
							return aName.localeCompare(bName);
						}).map((item) => (
							<AffNbrIcon
								item={item.item}
								broken={item.broken}
								nbr={item.nombre}
								key={"obj_" + item.item.id + "_" + (item.broken ? 1 : 0)}
							/>
						))}
					</div>
					{!blocMaj && <TooltipGH>
						<Button taille={"xs"} couleur={"success"} onClick={() => props.onMajCoffre(citoyen, index)}>
							<FontAwesomeIcon icon={faBoxesPacking} />
						</Button>
						<span className="info">{t("Ouvrir le coffre", { ns: "hotel" })}</span>
					</TooltipGH>}
				</div>
			</td>
			{/* <td class="citoyen_tab_reduit_dispo">d</td> */}
			<td className="citoyen_tab_reduit_icone">
				<TooltipGH>
                    <span className="infoBulle icone_citoyen_centre">
                        <i className={"fa-solid fa-clock-rotate-left " + (citoyen.date_maj !== null && citoyen.diff_date_maj === 0 ? "maj_0" : citoyen.date_maj !== null && citoyen.diff_date_maj === 1 ? "maj_24" : "maj_48")}></i>
                    </span>
					<span className="info">
                            {citoyen.date_maj === null && (<em>{t("Dernière mise à jour inconnue", { ns: "hotel" })}</em>)}
						{citoyen.date_maj !== null && (<em>{t("Dernière mise à jour le {dateMaj} par {user}", { ns: "hotel" })
							.replace("{user}", citoyen.update_by.pseudo)
							.replace("{dateMaj}", date_maj_string)}</em>)}
                        </span>
				</TooltipGH>
			
			</td>
			{props.choiceCheck && <td className="citoyen_tab_reduit_icone">
				<input type="checkbox" checked={props.listCitoyenCheck.includes(citoyen.citoyen.id)} onChange={() => props.handleCheckCitoyen(citoyen.citoyen.id)} />
			</td>}
		</tr>
	);
}

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MenuPrototypeDTO }                             from "../../types/models/menuPrototype.dto";
import { MenuDTO }                                      from "../../types/models/menu.dto";
import { MenuElementDTO }                               from "../../types/models/menuElement.dto";

interface MenuState {
	availableMenuPrototypes: MenuPrototypeDTO[];  // Menu dispo à l'utilisation
	remainingAvailableMenus: MenuPrototypeDTO[];  // Menu restant à disposition
	currentMenu: MenuDTO;                         // Menu courant
	showModal: boolean;
	groupName: string;
}

const initialState: MenuState = {
	availableMenuPrototypes: [], // Menus sur la gauche
	remainingAvailableMenus: [], // Menus restants après ajout dans currentMenu
	currentMenu            : { items: [] },  // Menu que l'on construit
	showModal              : false,
	groupName              : "",
};


export const fetchMenuPrototypes = createAsyncThunk(
	"menu/fetchMenuPrototypes",
	async () => {
		const response = await fetch("/rest/v1/admin/menu/");  // Ton endpoint API ici
		const data: MenuPrototypeDTO[] = await response.json();
		return data;
	},
);

export const fetchCurrentMenu = createAsyncThunk(
	"menu/fetchCurrentMenu",
	async () => {
		const response = await fetch("/rest/v1/admin/menu/current-menu/");  // Endpoint API pour le menu courant
		const data: MenuDTO = await response.json();
		return data;
	},
);

const menuAdminSlice = createSlice({
	name         : "menuAdmin",
	initialState,
	reducers     : {
		setAvailableMenus  : (state, action: PayloadAction<MenuPrototypeDTO[]>) => {
			state.availableMenuPrototypes = action.payload.sort((a, b) => a.id - b.id);
		},
		setCurrentMenuItems: (state, action: PayloadAction<MenuDTO>) => {
			state.currentMenu.items = action.payload.items; // Met à jour les items du menu actuel
			
			// Calculer les menus restants après ajout dans le menu courant
			state.remainingAvailableMenus = state.availableMenuPrototypes.filter(
				(menuPrototype) => !(state.currentMenu.items ?? []).some(
					(menuElement) => {
						if (menuElement.type_menu === "menu") {
							return menuElement.menu?.id === menuPrototype.id;
						} else {
							return (menuElement.items ?? []).some((item) => item.menu?.id === menuPrototype.id);
						}
					},
				),
			);
		},
		addItemToMenu      : (state, action: PayloadAction<{ item: MenuElementDTO; order: number; }>) => {
			const newMenuItem: MenuElementDTO = {
				id       : action.payload.item.id,
				type_menu: action.payload.item.type_menu,
				ordre    : action.payload.order,
				name     : action.payload.item.name,
				menu     : action.payload.item.menu,
				items    : action.payload.item.items,
			};
			state.currentMenu.items?.push(newMenuItem);
			
			// Supprimer l'item de availableMenuPrototypes si menu n'est pas null
			if (newMenuItem.type_menu === "menu" && newMenuItem.menu) {
				// Mettre à jour remainingAvailableMenus
				state.remainingAvailableMenus = state.availableMenuPrototypes.filter((menuPrototype) => {
					// Vérifiez si le menuPrototype n'est pas utilisé dans currentMenu
					return !(state.currentMenu.items ?? []).some((menuElement) => {
						if (menuElement.type_menu === "menu") {
							// Si c'est un menu, vérifiez directement l'ID
							return menuElement.menu?.id === menuPrototype.id;
						} else if (menuElement.type_menu === "group") {
							// Si c'est un groupe, vérifiez chaque item du groupe
							return (menuElement.items ?? []).some(item => item.menu?.id === menuPrototype.id);
						}
						return false; // Gérer d'autres types si nécessaire
					});
				});
			}
		},
		addItemToGroup     : (state, action: PayloadAction<{ item: MenuElementDTO; groupCibleId: number; order: number; }>) => {
			// On récupère le groupe cible
			const groupCible = state.currentMenu.items.find(item => item.id === action.payload.groupCibleId);
			// On ajoute l'item au groupe cible
			const newMenuItem: MenuElementDTO = {
				id       : action.payload.item.id,
				type_menu: action.payload.item.type_menu,
				ordre    : action.payload.order,
				name     : action.payload.item.name,
				menu     : action.payload.item.menu,
				items    : action.payload.item.items,
			};
			groupCible.items?.push(newMenuItem);
			
			// Supprimer l'item de availableMenuPrototypes si menu n'est pas null
			if (newMenuItem.type_menu === "menu" && newMenuItem.menu) {
				// Mettre à jour remainingAvailableMenus
				state.remainingAvailableMenus = state.availableMenuPrototypes.filter((menuPrototype) => {
					// Vérifiez si le menuPrototype n'est pas utilisé dans currentMenu
					return !(state.currentMenu.items ?? []).some((menuElement) => {
						if (menuElement.type_menu === "menu") {
							// Si c'est un menu, vérifiez directement l'ID
							return menuElement.menu?.id === menuPrototype.id;
						} else if (menuElement.type_menu === "group") {
							// Si c'est un groupe, vérifiez chaque item du groupe
							return (menuElement.items ?? []).some(item => item.menu?.id === menuPrototype.id);
						}
						return false; // Gérer d'autres types si nécessaire
					});
				});
			}
			
			// On met à jour les ordres des items du groupe
			groupCible.items?.sort((a, b) => a.ordre - b.ordre);
		},
		openModal          : (state) => {
			state.showModal = true;
		},
		closeModal         : (state) => {
			state.showModal = false;
			state.groupName = ""; // Réinitialiser le nom du groupe
		},
		setGroupName       : (state, action: PayloadAction<string>) => {
			state.groupName = action.payload;
		},
		removeItemFromMenu : (state, action: PayloadAction<number>) => {
			const itemIdToRemove = action.payload;
			const itemToRemove = state.currentMenu.items.find(item => item.id === itemIdToRemove);
			
			if (itemToRemove) {
				state.currentMenu.items = state.currentMenu.items.filter(item => item.id !== itemIdToRemove);
				
				// On remet les différents menus dans le menu disponible
				state.remainingAvailableMenus = state.availableMenuPrototypes.filter((menuPrototype) => {
					// Vérifiez si le menuPrototype n'est pas utilisé dans currentMenu
					return !(state.currentMenu.items ?? []).some((menuElement) => {
						if (menuElement.type_menu === "menu") {
							// Si c'est un menu, vérifiez directement l'ID
							return menuElement.menu?.id === menuPrototype.id;
						} else if (menuElement.type_menu === "group") {
							// Si c'est un groupe, vérifiez chaque item du groupe
							return (menuElement.items ?? []).some(item => item.menu?.id === menuPrototype.id);
						}
						return false; // Gérer d'autres types si nécessaire
					});
				});
			}
		},
		removeItemFromGroup: (state, action: PayloadAction<{ groupId: number, menuId: number }>) => {
			const groupIdToRemove = action.payload.groupId;
			const menuIdToRemove = action.payload.menuId;
			
			// On récupère le groupe
			const groupToRemove = state.currentMenu.items.find(item => item.id === groupIdToRemove);
			
			// On supprime le menu du groupe
			groupToRemove.items = groupToRemove.items.filter(item => item.id !== menuIdToRemove);
			
			// On met à jour le menu courant
			state.currentMenu.items = state.currentMenu.items.map(item => {
				if (item.id === groupIdToRemove) {
					return groupToRemove;
				}
				return item;
			});
			
			// On remet les différents menus dans le menu disponible
			state.remainingAvailableMenus = state.availableMenuPrototypes.filter((menuPrototype) => {
				// Vérifiez si le menuPrototype n'est pas utilisé dans currentMenu
				return !(state.currentMenu.items ?? []).some((menuElement) => {
					if (menuElement.type_menu === "menu") {
						// Si c'est un menu, vérifiez directement l'ID
						return menuElement.menu?.id === menuPrototype.id;
					} else if (menuElement.type_menu === "group") {
						// Si c'est un groupe, vérifiez chaque item du groupe
						return (menuElement.items ?? []).some(item => item.menu?.id === menuPrototype.id);
					}
					return false; // Gérer d'autres types si nécessaire
				});
			});
		},
		resetMenu          : (state) => {
			state.availableMenuPrototypes = []; // Réinitialiser les menus disponibles
			state.currentMenu = { items: [] }; // Réinitialiser le menu actuel
		},
		moveMenuElement    : (state, action: PayloadAction<{ index: number; direction: "up" | "down"; groupId?: number; }>) => {
			const { index, direction, groupId } = action.payload;
			
			const swapElements = ({ items, i1, i2 }: { items: MenuElementDTO[], i1: number, i2: number }) => {
				const temp = items[i1];
				items[i1] = items[i2];
				items[i2] = temp;
			};
			
			if (groupId) {
				// Déplacer un élément à l'intérieur d'un groupe
				const group = state.currentMenu.items.find(item => item.id === groupId && item.type_menu === "group");
				if (group && group.items) {
					if (direction === "up" && index > 0) {
						swapElements({ items: group.items, i1: index, i2: index - 1 });
					} else if (direction === "down" && index < group.items.length - 1) {
						swapElements({ items: group.items, i1: index, i2: index + 1 });
					}
					
					// Mettre à jour l'ordre dans le groupe
					group.items.forEach((item, i) => {
						item.ordre = i;
					});
				}
			} else {
				// Déplacer un élément au niveau du menu principal
				if (direction === "up" && index > 0) {
					swapElements({ items: state.currentMenu.items, i1: index, i2: index - 1 });
				} else if (direction === "down" && index < state.currentMenu.items.length - 1) {
					swapElements({ items: state.currentMenu.items, i1: index, i2: index + 1 });
				}
				
				// Mettre à jour l'ordre au niveau du menu principal
				state.currentMenu.items.forEach((item, i) => {
					item.ordre = i;
				});
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchMenuPrototypes.fulfilled, (state, action: PayloadAction<MenuPrototypeDTO[]>) => {
			state.availableMenuPrototypes = action.payload;
			state.remainingAvailableMenus = action.payload;
		});
		builder.addCase(fetchCurrentMenu.fulfilled, (state, action: PayloadAction<MenuDTO>) => {
			state.currentMenu = action.payload;
			// Mettre à jour remainingAvailableMenus après récupération du menu courant
			state.remainingAvailableMenus = state.availableMenuPrototypes.filter((menuPrototype) => {
				// Vérifiez si le menuPrototype n'est pas utilisé dans currentMenu
				return !(state.currentMenu.items ?? []).some((menuElement) => {
					if (menuElement.type_menu === "menu") {
						// Si c'est un menu, vérifiez directement l'ID
						return menuElement.menu?.id === menuPrototype.id;
					} else if (menuElement.type_menu === "group") {
						// Si c'est un groupe, vérifiez chaque item du groupe
						return (menuElement.items ?? []).some(item => item.menu?.id === menuPrototype.id);
					}
					return false; // Gérer d'autres types si nécessaire
				});
			});
		});
	},
});

export const { setAvailableMenus, addItemToMenu, removeItemFromMenu, openModal, closeModal, setGroupName, setCurrentMenuItems, resetMenu, addItemToGroup, removeItemFromGroup, moveMenuElement } = menuAdminSlice.actions;
export default menuAdminSlice.reducer;

import React                                                                 from "react";
import ColorPicker                                                           from "../../components/generality/ColorPickerGH";
import { OptionPersoApi }                                                    from "../../services/api/OptionPersoApi";
import { Status_error, Status_success, usePopUp }                            from "../../types/Context/PopUpContext";
import Select                                                                from "react-select";
import { calculColorDispo, customStyleDispo, DispoOption, DispoSingleValue } from "../Hotel/Inscription/InscriptionExpedition";
import { useGeneralContext }                                                 from "../../types/Context/GeneralContext";
import SvgIcone                                                              from "../../components/generality/SvgIcone";
import { Helmet }                                                            from "react-helmet-async";
import Form                                                                  from "react-bootstrap/Form";
import { useTranslation }                                                    from "react-i18next";
import { UserDTO }                                                           from "../../types/models/user.dto";
import { TypeDispoDTO }                                                      from "../../types/models/typeDispo.dto";
import { DispoUserTypeExpeditionDTO }                                        from "../../types/models/dispoUserTypeExpedition.dto";
import { CreneauHorraireDTO }                                                from "../../types/models/creneauHorraire.dto";
import { UserPersonnalisationDTO }                                           from "../../types/models/userPersonnalisation.dto";
import { UserPersoCouleurDTO }                                               from "../../types/models/userPersoCouleur.dto";
import TooltipGH                                                             from "../../components/utils/TooltipGH";

export const styleDispoOption = {
	widthContainer          : 55,
	widthMenu               : 70,
	height                  : 24,
	minHeight               : 24,
	widthClearIndicator     : 0,
	widthControl            : 55,
	widthDropdownIndicator  : 0,
	widthIndicatorsContainer: 0,
	widthInput              : 20,
	widthSingleValue        : 55,
	widthValueContainer     : 55,
};

const generateCSSUser = (user: UserPersoCouleurDTO) => {
	return `
		.my_line_expedition {background-color:${user.my_color_expe} !important;}
		`;
};

export default function GestionDispo({ user, onUserChange, onUserPersoChange, onUserPersoCouleurChange, creneau, onUser, dispo }: { user: UserDTO, onUserChange: (updatedProperties: Partial<UserDTO>) => void, onUserPersoChange: (updatedProperties: Partial<UserPersonnalisationDTO>) => void, onUserPersoCouleurChange: (updatedProperties: Partial<UserPersoCouleurDTO>) => void, creneau: CreneauHorraireDTO[], dispo: TypeDispoDTO[], onUser: (user: UserDTO) => void }) {
	const { general } = useGeneralContext();
	const [dispoType, setDispoType] = React.useState <DispoUserTypeExpeditionDTO>(null);
	const [showDispoForm, setShowDispoForm] = React.useState<boolean>(false);
	const { setMessagePopUp, setShowPop, setStatus } = usePopUp();
	const { t } = useTranslation();
	const optionApi = new OptionPersoApi();
	
	const handleChangeDispotype = (dispo: DispoUserTypeExpeditionDTO) => {
		optionApi.majDispoUser({ user_id: user.id, dispo_type: dispo }).then((res) => {
			if (res.data.dispo) {
				const userMod = { ...user };
				if (dispo.id === null) {
					userMod.dispo_user_type_expeditions.push(res.data.dispo);
					setMessagePopUp(t("Ajout de la disponibilité réussi", { ns: "perso" }));
				} else {
					// on met à jour la disponibilité
					const index = userMod.dispo_user_type_expeditions.findIndex((d) => d.id === dispo.id);
					userMod.dispo_user_type_expeditions[index] = res.data.dispo;
					setMessagePopUp(t("Modification de la disponibilité réussie", { ns: "perso" }));
				}
				onUser(userMod);
				setShowPop(true);
				setStatus(Status_success);
				setTimeout(() => {
					setShowDispoForm(false);
					setDispoType(null);
					setShowPop(false);
					setStatus(null);
					setMessagePopUp("");
				}, 1500);
			}
		}).catch((err) => {
			setMessagePopUp(err.data?.error ?? err.message);
			setShowPop(true);
			setStatus(Status_error);
		});
	};
	
	const handleSuppDispotype = (dispo: DispoUserTypeExpeditionDTO) => {
		optionApi.suppDispoUser(dispo.id).then((res) => {
			setMessagePopUp(t("Suppression réalisée avec succès", { ns: "perso" }));
			const userMod = { ...user };
			const index = userMod.dispo_user_type_expeditions.findIndex((d) => d.id === dispo.id);
			userMod.dispo_user_type_expeditions.splice(index, 1);
			onUser(userMod);
			setShowPop(true);
			setStatus(Status_success);
			setTimeout(() => {
				setShowDispoForm(false);
				setDispoType(null);
				setShowPop(false);
				setStatus(null);
				setMessagePopUp("");
			}, 1500);
		}).catch((err) => {
			setMessagePopUp(err.data?.error ?? err.message);
			setShowPop(true);
			setStatus(Status_error);
		});
	};
	
	const handleChangeDispoToCreneau = (indexCreneau: number, updatedProperties: TypeDispoDTO) => {
		const dispoTypeMod = { ...dispoType };
		// On balaye toutes les parties de l'expedition pour metre à jour l'expediteur
		dispoTypeMod.creneau[indexCreneau] = {
			...dispoTypeMod.creneau[indexCreneau],
			dispo: updatedProperties,
		};
		setDispoType(dispoTypeMod);
	};
	
	const handleCreateDispotype = () => {
		// création d'un nouveau type de disponibilité selon tous les créneaux fournis en props
		const dispoTypeNew: DispoUserTypeExpeditionDTO = {
			id     : null,
			nom    : "",
			creneau: creneau.map((c) => ({ id: null, dispo: null, creneau: c })),
		};
		setDispoType(dispoTypeNew);
		setShowDispoForm(true);
	};
	const handleDuplicateDispotype = (dispo: DispoUserTypeExpeditionDTO) => {
		// Création d'une copie de la disponibilité en ne prenant pas en compte l'id et le nom
		const dispoTypeNew: DispoUserTypeExpeditionDTO = {
			id     : null,
			nom    : "",
			creneau: dispo.creneau.map((c) => ({ id: null, dispo: c.dispo, creneau: c.creneau })),
		};
		setDispoType(dispoTypeNew);
		setShowDispoForm(true);
	};
	
	const swapDispo = (indexA: number, indexB: number) => {
		// Clone the array to avoid mutating the original user object directly
		const updatedDispo = [...user.dispo_user_type_expeditions];
		
		// Destructure the properties for clarity
		const { priorite: prioriteA } = updatedDispo[indexA];
		const { priorite: prioriteB } = updatedDispo[indexB];
		
		// Swap the priorities
		updatedDispo[indexA].priorite = prioriteB;
		updatedDispo[indexB].priorite = prioriteA;
		
		// Update the user state with the modified array
		onUserChange({ ...user, dispo_user_type_expeditions: updatedDispo });
	};
	
	const controlDispo = (): boolean => {
		// si il y a pas un nom, on ne peut pas valider
		if (!dispoType.nom) {
			return false;
		}
		// on vérifie que tous les créneaux sont remplis
		if (dispoType) {
			for (const creneau of dispoType.creneau) {
				if (!creneau.dispo) {
					return false;
				}
			}
		}
		return true;
	};
	
	return <>
		<Helmet>
			<style>{generateCSSUser(general.themeUser.user_perso_couleur)}</style>
		</Helmet>
		<div id="zone_opt_expe">
			<div id="zoneoOptJump">
				<h2>{t("Gestion des options de la page d'inscription expédition", { ns: "perso" })}</h2>
				<fieldset>
					<legend><h2>{t("Gestion diverses", { ns: "perso" })}</h2></legend>
					<div id={"gestion_option_exped_gen"}>
						<div className={"choix_color_expe_option"}>
							<span>{t("Couleur de ma ligne :", { ns: "perso" })}</span>
							<ColorPicker color={general.themeUser.user_perso_couleur.my_color_expe} onChangeColor={(color) => onUserPersoCouleurChange({ my_color_expe: color })} />
						</div>
						<div className={"choix_color_expe_option"}>
							<label htmlFor={"option_expe_on_top"}>{t("Positionner son expédition en première position", { ns: "perso" })}</label>
							<Form.Check name={"option_expe_on_top"} id={"option_expe_on_top"} type="switch" checked={user.user_personnalisation.expe_on_the_top}
										onChange={(event) => onUserPersoChange({ expe_on_the_top: event.target.checked })} />
						</div>
						<div className={"choix_color_expe_option"}>
							<label htmlFor={"option_expe_dispo_fix"}>{t("Bloquer les dispos des autres joueurs afin de ne pas interférer", { ns: "perso" })}</label>
							<Form.Check name={"option_expe_dispo_fix"} id={"option_expe_dispo_fix"} type="switch" checked={user.user_personnalisation.fix_inscription_expe}
										onChange={(event) => onUserPersoChange({ fix_inscription_expe: event.target.checked })} />
						</div>
					</div>
					<fieldset>
						<legend><h2>{t("Aperçu", { ns: "perso" })}</h2></legend>
						<div className={"option_expedition_expe_part"}>
							<table className="inscription_expedition">
								<tbody>
								<tr className="ligne_inscription_expedition ligne_citoyen_user my_line_expedition">
									<td className="cols40_expedition">
										<div className="zone_td_display">
										</div>
									</td>
									<td className="pseudo_expedition">
										<div className="zone_td_display">
											{general.user?.pseudo}
										</div>
									</td>
									<td className="cols40_expedition">
										<div className="zone_td_display"><i className="fa-solid fa-xmark color-red"></i></div>
									</td>
									<td className="sac_expedition">
										<div className="zone_sac_expeditionnaire">
											<div className="sac_expeditionnaire">
												<div>
													<div className="itemSacOutilsExpedition"><span className="nbrItems">1</span><span className="videImg"><SvgIcone icone={"item_food_bag"} /></span></div>
												</div>
												<div>
													<div className="itemSacOutilsExpedition"><span className="nbrItems">1</span><span className="videImg"><SvgIcone icone={"item_water"} /></span></div>
												</div>
											</div>
										</div>
									</td>
								</tr>
								<tr className="ligne_inscription_expedition ligne_citoyen_user">
									<td className="cols40_expedition">
										<div className="zone_td_display">
										</div>
									</td>
									<td className="pseudo_expedition">
										<div className="zone_td_display">Pala</div>
									</td>
									<td className="cols40_expedition">
										<div className="zone_td_display"><i className="fa-solid fa-xmark color-red"></i></div>
									</td>
									<td className="sac_expedition">
										<div className="zone_sac_expeditionnaire">
											<div className="sac_expeditionnaire">
												<div>
													<div className="itemSacOutilsExpedition"><span className="nbrItems">1</span><span className="videImg"><SvgIcone icone={"item_food_bag"} /></span></div>
												</div>
												<div>
													<div className="itemSacOutilsExpedition"><span className="nbrItems">1</span><span className="videImg"><SvgIcone icone={"item_water"} /></span></div>
												</div>
											</div>
										</div>
									</td>
								</tr>
								</tbody>
							</table>
						</div>
					</fieldset>
				</fieldset>
				<fieldset>
					<legend><h2>{t("Gestion des couleurs des disponibilités", { ns: "perso" })}</h2></legend>
					<div id={"gestion_option_exped"}>
						<div className={"choix_color_expe_option"}>
							<span>{t("Couleur indisponible", { ns: "perso" })}</span>
							<ColorPicker color={general.themeUser.user_perso_couleur.color_dispo1} onChangeColor={(color) => onUserPersoCouleurChange({ color_dispo1: color })} />
						</div>
						<div className={"choix_color_expe_option"}>
							<span>{t("Couleur disponible", { ns: "perso" })}</span>
							<ColorPicker color={general.themeUser.user_perso_couleur.color_dispo2} onChangeColor={(color) => onUserPersoCouleurChange({ color_dispo2: color })} />
						</div>
						<div className={"choix_color_expe_option"}>
							<span>{t("Couleur partiellement disponible", { ns: "perso" })}</span>
							<ColorPicker color={general.themeUser.user_perso_couleur.color_dispo3}
										 onChangeColor={(color) => onUserPersoCouleurChange({ color_dispo3: color })} />
						</div>
					</div>
					<fieldset>
						<legend><h2>{t("Aperçu", { ns: "perso" })}</h2></legend>
						<div id={"gestion_appercu_exped"}>
							<div className={"choix_color_expe_option"} style={{ color: "black", backgroundColor: general.themeUser.user_perso_couleur.color_dispo1 }}>0</div>
							<div className={"choix_color_expe_option"} style={{ color: "black", backgroundColor: general.themeUser.user_perso_couleur.color_dispo2 }}>1</div>
							<div className={"choix_color_expe_option"} style={{ color: "black", backgroundColor: general.themeUser.user_perso_couleur.color_dispo3 }}>Ping</div>
						</div>
					</fieldset>
				</fieldset>
				<fieldset>
					<legend><h2>{t("Gestion des disponibilités types", { ns: "perso" })}</h2></legend>
					<div id={"dispo_type_group_gestion_option"}>
						<div id={"dispo_type_list_gestion_option"}>
							<fieldset>
								<legend>{t("Liste de vos disponibilités types", { ns: "perso" })}</legend>
								{user.dispo_user_type_expeditions.length === 0 && <div>{t("Aucune disponibilité type", { ns: "perso" })}</div>}
								{user.dispo_user_type_expeditions.length > 0 && <table id={"table_dispo_type_option"}>
									<thead>
									<tr>
										<th className={"cols_name"}>{t("Nom", { ns: "perso" })}</th>
										<th className={"cols_action"}>{t("Action", { ns: "perso" })}</th>
									</tr>
									</thead>
									<tbody>
									{user.dispo_user_type_expeditions.sort((dispo_a, dispo_b) => dispo_a.priorite < dispo_b.priorite ? -1 : 1)
										.map((dispoType, index) => {
											return <tr key={dispoType.id}>
												<td className={"cols_name"}>{dispoType.nom}</td>
												<td className={"cols_action"}>
													<div>
														<button type="button" id="ajout_dispo_type" className="btn-xs btn btn-primary" onClick={() => {
															setDispoType(dispoType);
															setShowDispoForm(true);
														}}>
															<TooltipGH>
                                                                <span className={"infoBulle"}>
                                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                                </span>
																<span className={"info"}>{t("Modifier", { ns: "perso" })}</span>
															</TooltipGH>
														</button>
														<button type="button" id="ajout_dispo_type" className="btn-xs btn btn-success" onClick={() => handleDuplicateDispotype(dispoType)}>
															<TooltipGH>
                                                                <span className={"infoBulle"}>
                                                                    <i className="fa-solid fa-copy"></i>
                                                                </span>
																<span className={"info"}>{t("Dupliquer", { ns: "perso" })}</span>
															</TooltipGH>
														</button>
														<button type="button" id="ajout_dispo_type" className="btn-xs btn btn-danger" onClick={() => handleSuppDispotype(dispoType)}>
															<TooltipGH>
                                                                <span className={"infoBulle"}>
                                                                    <i className="fa-solid fa-trash"></i>
                                                                </span>
																<span className={"info"}>{t("Supprimer", { ns: "perso" })}</span>
															</TooltipGH>
														</button>
														{user.dispo_user_type_expeditions.length > 1 && (<button className={"btn btn-xs btn-secondary"} onClick={() => swapDispo(index, index - 1)} disabled={index === 0}>
															<TooltipGH>
                                                                <span className="infoBulle">
                                                                    <i className="fa-solid fa-circle-chevron-up"></i>
                                                                </span>
																<span className="info">{t("Monter", { ns: "perso" })}</span>
															</TooltipGH>
														</button>)}
														{user.dispo_user_type_expeditions.length > 1 && (<button className={"btn btn-xs btn-secondary"} onClick={() => swapDispo(index, index + 1)} disabled={index === user.dispo_user_type_expeditions.length - 1}>
															<TooltipGH>
                                                                <span className="infoBulle">
                                                                    <i className="fa-solid fa-circle-chevron-down"></i>
                                                                </span>
																<span className="info">{t("Descendre", { ns: "perso" })}</span>
															</TooltipGH>
														</button>)}
													</div>
												</td>
											</tr>;
										})}
									</tbody>
								</table>}
								<button type="button" id="ajout_dispo_type" className="btn-xs btn btn-primary" onClick={handleCreateDispotype}>{t("Créer une nouvelle disponibilité type", { ns: "perso" })}</button>
							</fieldset>
						</div>
						<div id={"dispo_type_form_gestion_option"}>
							{showDispoForm && dispoType && <fieldset>
								<div>
									<label>{t("Nom :", { ns: "perso" })}</label>
									<input type="text" value={dispoType.nom} onChange={(e) => setDispoType({ ...dispoType, nom: e.target.value })} maxLength={30} />
								</div>
								<div>
									<table>
										<thead>
										<tr>
											<th colSpan={creneau.length}>{t("Disponibilité type en expédition", { ns: "perso" })}</th>
										</tr>
										<tr className={"ligne_inscription_ouvrier"}>
											{creneau.map((creneau) => {
												return <th key={creneau.id} className={"cols_dispo"}>{creneau.libelle}</th>;
											})}
										</tr>
										</thead>
										<tbody>
										<tr>
											{
												creneau.map((creneau, indexCreneau) => {
													return <td key={creneau.id} className={"cols_dispo"}>
														<div className={"zone_td_display"}>
															<Select value={dispoType.creneau[indexCreneau].dispo ? dispo.find(dispo => dispo.id === dispoType.creneau[indexCreneau].dispo.id) : null}
																	id={"dispo_ouvrier_" + indexCreneau}
																	name={"dispo_ouvrier_" + indexCreneau}
																	options={dispo.sort((a, b) => a.nom.localeCompare(b.nom))}
																	isClearable={false}
																	components={{ SingleValue: DispoSingleValue, Option: DispoOption }}
																	getOptionLabel={(dispo) => dispo.nom}
																	getOptionValue={(dispo) => dispo.id.toString()}
																	onChange={(optionSelected: TypeDispoDTO) => {
																		handleChangeDispoToCreneau(indexCreneau, optionSelected);
																	}}
																	styles={customStyleDispo(general.themeUser,
																		styleDispoOption,
																		false,
																		calculColorDispo(dispoType.creneau[indexCreneau].dispo, general.themeUser.user_perso_couleur))} />
														</div>
													</td>;
												})
											}
										</tr>
										</tbody>
									</table>
								</div>
								<div id={"zone-button"}>
									<button type="button" id="ajout_dispo_type" className="btn-xs btn btn-success" disabled={!controlDispo()} onClick={() => handleChangeDispotype(dispoType)}>{t("Valider", { ns: "perso" })}</button>
									<button type="button" id="ajout_dispo_type" className="btn-xs btn btn-warning" onClick={() => {
										setDispoType(null);
										setShowDispoForm(false);
									}}>{t("Annuler", { ns: "perso" })}</button>
								</div>
							</fieldset>}
						</div>
					</div>
				</fieldset>
			</div>
		</div>
	</>;
}
import { createContext, useContext } from "react";
import { ExpeditionContextType }     from "./ExpeditionContext.type";

export const ExpeditionContext = createContext<ExpeditionContextType>({
	expeditions        : [],
	setExpeditions     : () => {
	},
	ouvriers           : [],
	setOuvriers        : () => {
	},
	optionsSoif        : [],
	expedition         : {},
	setExpedition      : () => {
	},
	ongletActuel       : "",
	setOngletActuel    : () => {
	},
	loadData           : false,
	setLoadData        : () => {
	},
	refreshOutils      : () => {
	},
	citoyensUser       : [],
	onDuplicate        : false,
	setOnDuplicate     : () => {
	},
	calculStockRestant : (): number[] => {
		return [];
	},
	onUpdateSeletedExpe: () => {
	},
	setVerrouExpes     : () => {
	},
	verrouExpes        : [],
});

export function useExpeditionContext() {
	return useContext(ExpeditionContext);
}
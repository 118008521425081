import React, { useState }                                   from "react";
import { EncyclopedieObjetType }                             from "../../types/components/Encyclopedie/EncyclopedieObjet.type";
import Tab                                                   from "react-bootstrap/Tab";
import Tabs                                                  from "react-bootstrap/Tabs";
import Cookies                                               from "js-cookie";
import { useTranslation }                                    from "react-i18next";
import ListObjets                                            from "./Objets/ListObjets";
import ListWeapons                                           from "./Objets/ListWeapons";
import ListVeilles                                           from "./Objets/ListVeilles";
import ListPoubelles                                         from "./Objets/ListPoubelles";
import Card                                                  from "react-bootstrap/Card";
import CardGroup                                             from "react-bootstrap/CardGroup";
import ListDecharges                                         from "./Objets/ListDecharges";
import { TypeCaracteristiqueDTO }                            from "../../types/models/typeCaracteristique.dto";
import Select, { components, OptionProps, SingleValueProps } from "react-select";
import BadgeCaracteristiqueItemFiltre                        from "../../components/encyclopedie/BadgeCaracteristiqueItemFiltre";
import { Form }                                              from "react-bootstrap";
import { useGeneralContext }                                 from "../../types/Context/GeneralContext";

const { SingleValue, Option } = components;

const CaracSingleValue = (props: SingleValueProps<TypeCaracteristiqueDTO>) => {
	return <SingleValue {...props}>
		<BadgeCaracteristiqueItemFiltre caracteristique={props.data} withSupp={false} />
	</SingleValue>;
};

const CaracOption = (props: OptionProps<TypeCaracteristiqueDTO>) => {
	return <Option {...props}>
		<BadgeCaracteristiqueItemFiltre caracteristique={props.data} withSupp={false} />
	</Option>;
};

const customStylesFiltre = {
	option           : (provided: any) => ({
		...provided,
		display        : "flex",
		flexDirection  : "row",
		alignItems     : "center",
		backgroundColor: "white",
		color          : "black",
	}),
	singleValue      : (provided: any) => ({
		...provided,
		display        : "flex",
		flexDirection  : "row",
		alignItems     : "center",
		backgroundColor: "white",
		color          : "black",
	}),
	control          : (provided: any) => ({
		...provided,
		minHeight: "30px",
		height   : "30px",
		minWidth : "200px",
		maxWidth : "200px",
	}),
	input            : (provided: any) => ({
		...provided,
		gridTemplateColumns: "none",
	}),
	dropdownIndicator: (provided: any) => ({
		...provided,
		padding: "0",
	}),
	valueContainer   : (provided: any) => ({
		...provided,
		padding: "0px 8px",
	}),
	menu             : (provided: any) => ({
		...provided,
		backgroundColor: "white",
		color          : "black",
		zIndex         : 100,
	}),
	menuList         : (provided: any) => ({
		...provided,
		backgroundColor: "white",
		color          : "black",
	}),
	menuPortal       : (provided: any) => ({
		...provided,
		backgroundColor: "white",
		color          : "black",
	}),
};


interface EncyclopedieObjetProps {
	objets: EncyclopedieObjetType;
}

export default function EncyObjet(props: EncyclopedieObjetProps) {
	const { t } = useTranslation();
	const { general } = useGeneralContext();
	const storedActiveTab = Cookies.get("activeTab_ency_objet");
	const [activeTab, setActiveTab] = useState(storedActiveTab || "objets");
	const [search_objet, setSearchObjet] = useState("");
	const [activeTrad, setActiveTrad] = useState(localStorage.getItem("activeTrad") === "true");
	const [filteredCaracteristiques, setFilteredCaracteristiques] = useState<TypeCaracteristiqueDTO[]>([]);
	
	
	const listObjetsPrototype = props.objets.listObjets;
	
	
	const handleClick = () => {
		setSearchObjet("");
	};
	
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchObjet(event.target.value);
	};
	
	
	// Fonction pour changer d'onglet et enregistrer le cookie
	const handleTabChange = (eventKey: string) => {
		setActiveTab(eventKey);
		Cookies.set("activeTab_ency_objet", eventKey, { expires: 365 }); // Le cookie expirera dans 365 jours
	};
	
	const handleFilterChange = (selected: TypeCaracteristiqueDTO | null) => {
		if (selected && !filteredCaracteristiques.includes(selected)) {
			setFilteredCaracteristiques([...filteredCaracteristiques, selected]);
		}
	};
	
	const handleRemoveFilter = (filterToRemove: TypeCaracteristiqueDTO) => {
		setFilteredCaracteristiques(filteredCaracteristiques.filter(filter => filter.id !== filterToRemove.id));
	};
	
	return <div id={"encyclopedie_objet"}>
		<div id="form_search_items">
			<CardGroup>
				<Card>
					<Card.Body className={"d-flex justify-content-start align-items-center gap-2 flex-column"}>
						<div>
							<label htmlFor="form_nom" className="required">{t("Rechercher un objet :", { ns: "ency" })} </label>
							<input
								type={"search"}
								id="form_nom"
								placeholder={t("Nom de l'objet", { ns: "ency" })}
								value={search_objet}
								onClick={handleClick}
								onChange={(event) => {
									handleChange(event);
								}}
							/>
						</div>
						<div className={"d-flex justify-content-start align-items-center"}>
							<label htmlFor="form_AfficheTrad" className="required">{t("Afficher les objets dans les 4 langues :", { ns: "ency" })} </label>
							<Form.Check
								type="switch"
								id="form_AfficheTrad"
								checked={activeTrad}
								onChange={(event) => {
									setActiveTrad(event.target.checked);
									// On stock le choix de l'utilisateur dans le storage
									localStorage.setItem("activeTrad", event.target.checked.toString());
								}}
								style={{ marginLeft: "10px" }}
							/>
						</div>
					</Card.Body>
				</Card>
				{activeTab === "objets" && <Card>
					<Card.Body className={"d-flex justify-content-start align-items-center gap-2"}>
						<div className={"d-flex justify-content-start align-items-center gap-2"}>
							<span className="itemAssemblage itemPrincipale"></span>
							<span>{t("Objets sur lequel faire l'action", { ns: "ency" })}</span>
						</div>
					</Card.Body>
				</Card>}
				{activeTab === "objets" && <Card>
					<Card.Body className={"d-flex justify-content-start align-items-center gap-2 flex-column"}>
						<div className={"d-flex justify-content-start align-items-center gap-2"}>
							<span>{t("Filtres caractéristiques :", { ns: "ency" })}</span>
							<Select
								options={Object.values(props.objets.listCaracteristiques).sort((a, b) => t(a.nom ?? "", { ns: "items" }).localeCompare(t(b.nom ?? "", { ns: "items" })))}
								components={{ SingleValue: CaracSingleValue, Option: CaracOption }}
								styles={customStylesFiltre}
								getOptionLabel={(type) => t(type.nom, { ns: "items" })}
								getOptionValue={(type) => type.id.toString()}
								onChange={(selectedOption) => {
									handleFilterChange(selectedOption as TypeCaracteristiqueDTO);
								}}
								value={null} // Reset the selected value
							/>
						</div>
						<div className={"d-flex justify-content-center align-items-center gap-2 flex-wrap"}>
							{filteredCaracteristiques.map((carac, index) => <BadgeCaracteristiqueItemFiltre caracteristique={carac} withSupp={true} onSupp={(carac) => handleRemoveFilter(carac)} key={"filtre_" + index} />)}
						</div>
					</Card.Body>
				</Card>}
			</CardGroup>
		</div>
		<Tabs
			activeKey={activeTab}
			onSelect={handleTabChange}
		>
			<Tab eventKey="objets" title={t("Objets", { ns: "ency" })}>
				<ListObjets objets={props.objets} search_objet={search_objet} listObjetsPrototype={listObjetsPrototype} filteredCarac={filteredCaracteristiques} isTrad={activeTrad} lng={general.user.lang} />
			</Tab>
			<Tab eventKey="armes" title={t("Armes", { ns: "ency" })}>
				<ListWeapons objets={props.objets} search_objet={search_objet} listObjetsPrototype={listObjetsPrototype} isTrad={activeTrad} lng={general.user.lang} />
			</Tab>
			<Tab eventKey="veilles" title={t("Armes de veille", { ns: "ency" })}>
				<ListVeilles objets={props.objets} search_objet={search_objet} listObjetsPrototype={listObjetsPrototype} isTrad={activeTrad} lng={general.user.lang} />
			</Tab>
			<Tab eventKey="poubelles" title={t("Objets poubelle", { ns: "ency" })}>
				<ListPoubelles objets={props.objets} search_objet={search_objet} listObjetsPrototype={listObjetsPrototype} isTrad={activeTrad} lng={general.user.lang} />
			</Tab>
			<Tab eventKey="decharges" title={t("Objets en décharges", { ns: "ency" })}>
				<ListDecharges objets={props.objets} search_objet={search_objet} listObjetsPrototype={listObjetsPrototype} isTrad={activeTrad} lng={general.user.lang} />
			</Tab>
		</Tabs>
	</div>;
	
}



import React, { useEffect, useRef, useState } from "react";
import SvgIcone                               from "../../../components/generality/SvgIcone";

const CheckboxList = ({ values, onChange, valueChecked }: { values: any[], onChange: (array: any[]) => void, valueChecked: any[] }) => {
	const [checkedItems, setCheckedItems] = useState(Array.isArray(valueChecked) ? valueChecked : []);
	const checkedItemsRef = useRef([]);
	
	const handleCheckboxChange = (value) => {
		const isChecked = checkedItems.includes(value);
		
		if (isChecked) {
			setCheckedItems((prevCheckedItems) => prevCheckedItems.filter(item => item !== value));
		} else {
			setCheckedItems((prevCheckedItems) => [...prevCheckedItems, value]);
		}
	};
	
	useEffect(() => {
		
		if (checkedItemsRef.current !== checkedItems) {
			checkedItemsRef.current = checkedItems;
			onChange(checkedItems);
		}
		if (!Array.isArray(valueChecked)) {
			checkedItemsRef.current = [];
			setCheckedItems([]);
			onChange([]);
		}
		
	}, [checkedItems]);
	
	return (
		<React.Fragment>
			{values.map(value => (
				<label key={value.name} className={"d-flex gap-0 align-items-center"}>
					<input
						type="checkbox"
						value={value.name}
						checked={checkedItems.includes(value.name)}
						onChange={() => handleCheckboxChange(value.name)}
					/>
					{value.icone && <SvgIcone icone={value.icone} />}
					<span>{value.label}</span>
				</label>
			))}
		</React.Fragment>
	);
};

export default CheckboxList;

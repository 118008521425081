import { Fetch }                        from "../../components/generality/Fetch";
import { CaseRuine, RuinesListingType } from "../../types/components/ville/Ruines.type";
import { GeneralType }                  from "../../types/components/Generality/General.type";
import { FetchGH }                      from "../../components/generality/FetchGH";
import { ItemPrototypeDTO }             from "../../types/models/itemPrototype.dto";
import { RuinesDTO }                    from "../../types/models/ruines.dto";

export class RuineApi {
	private fetch: Fetch;
	private fetchGH: FetchGH;
	
	constructor(mapId?: number) {
		this.fetch = new Fetch("ruine", mapId ?? 0);
		this.fetchGH = new FetchGH("ruine", mapId ?? 0);
	}
	
	public generallist(mapId?: string): Promise<AffichageListRuine> {
		return this.fetchGH.get("/" + (mapId ? mapId : ""));
	}
	
	public map(id: string, mapId: string): Promise<AffichageRuine> {
		return this.fetchGH.get("/" + id + "/" + mapId);
	}
	
	public majCaseObjet(
		id: string,
		mapId: number,
		ruinesCases: CaseRuine,
	): Promise<MajCaseObjet> {
		return this.fetchGH.post({
			path: "/maj_case", data: {
				ruineId    : id,
				mapId      : mapId,
				ruinesCases: ruinesCases,
			},
		});
	}
	
	public majPlan(data: {
		mapId: number;
		ruineId: string;
		plan: CaseRuine[];
	}): Promise<RetourRuineList> {
		return this.fetch.from("/maj_ruine").request().post(data);
	}
	
	public majAriane(data: {
		mapId: number;
		ruineId: string;
		coord: number[][][];
	}): Promise<RetourRuineBase> {
		return this.fetch.from("/maj_ariane").request().post(data);
	}
}

export interface RetourRuineList {
	codeRetour?: number;
	libRetour?: string;
	zoneRetour: {
		listRuines: RuinesDTO[];
	};
}

export interface RetourRuineBase {
	codeRetour?: number;
	libRetour?: string;
}

export interface MajCaseObjet {
	data: {
		ruineCase?: CaseRuine;
		allRuineObjets?: { item: ItemPrototypeDTO; count: number; broken: boolean }[];
		error?: string;
	};
	status?: number;
	message?: string;
}

export interface AffichageRuine {
	data: {
		ruine?: RuinesListingType;
		general?: GeneralType;
		error?: string;
	};
	status?: number;
	message?: string;
}

export interface AffichageListRuine {
	data: {
		ruine?: RuinesListingType;
		general?: GeneralType;
		error?: string;
	};
	status?: number;
	message?: string;
}

import React, { useEffect, useState } from "react";
import { useTranslation }             from "react-i18next";
import EditorComponent                from "../../../components/editor/editor";

interface PopUpCommentaireProps {
	translate: {
		valider: string;
		annuler: string;
	};
	texte: string;
	onChange: (texte: string) => void;
	onCancel: () => void;
	lang: string;
}

export default function PopUpCommentaire({ translate, texte, onChange, onCancel, lang }: PopUpCommentaireProps) {
	const { t } = useTranslation();
	const [commentaire, setCommentaire] = useState(texte);
	
	useEffect(() => {
		setCommentaire(texte);
	}, [texte]);
	
	
	return (
		<React.Fragment>
			<div id={"consigneZone"}>
				<EditorComponent width={635} data={commentaire ?? ""} lang={lang} onTextChange={(text) => {
					setCommentaire(text);
				}} />
			</div>
			<div id={"boutonMajConsigne"} style={{ marginBottom: "10px" }}>
				<button className={"btn btn-success btn-sm"} onClick={() => onChange(commentaire)}>{translate.valider}</button>
				<button className={"btn btn-warning btn-sm"} onClick={onCancel}>{translate.annuler}</button>
			</div>
		</React.Fragment>
	);
}

import * as React                                                                                                                          from "react";
import { BooleanInput, Datagrid, DateTimeInput, Edit, List, NumberField, NumberInput, SimpleForm, TextField, TextInput, useRecordContext } from "react-admin";
import { Link, useParams }                                                                                                                 from "react-router";
import { Button }                                                                                                                          from "@mui/material";
import { BiEditAlt }                                                                                                                       from "react-icons/bi";
import { ColorInput }                                                                                                                      from "../InputFieds/Inputs/Colors";


export const ExpeditionList = (props) => {
	
	const { id } = useParams();
	
	/*const postFilters = [
	 <TextInput source="q" label="Search" alwaysOn/>,
	 <ReferenceInput source="id" label="Map Id" reference="ville"/>,
	 ];*/
	
	const PostTitle = () => {
		const record = useRecordContext();
		return <span>Edition des expeditions de la ville {record ? `"${record.ville.nom}" - ${record.ville.id}` : ""}</span>;
	};
	
	
	//return <List {...props} filters={postFilters}>
	return <List {...props} title={<PostTitle />} resource="ville_expedition" filter={{ villeId: id }}>
		<Datagrid>
			<TextField source="id" />
			<TextField source="nom" />
			<TextField source={"created_by.pseudo"} label={"Pseudo créateur"} />
			<NumberField source={"jour"} />
			<EditExpeditionButton />
		</Datagrid>
	</List>;
};


const EditExpeditionButton = () => {
	const expedition = useRecordContext();
	return (
		<Button
			component={Link}
			to={`/admin/ville/${expedition?.ville.id}/expedition/${expedition?.id}`}
			color="primary"
		>
			<BiEditAlt />
			Editer
		</Button>
	);
};

export const ExpeditionEdit = (props) => {
	
	const { id, expe_id } = useParams();
	
	const PostTitle = () => {
		const record = useRecordContext();
		return <span>Edition de l&apos;expedition {record ? `"${record.nom}" - ${record.id}` : ""}</span>;
	};
	
	
	return (<Edit {...props} title={<PostTitle />} resource={"ville_expedition"} id={expe_id} redirect={`admin/ville/${id}/expedition`}>
		<SimpleForm>
			<div id={"admin_zone_ville_info"}>
				<h2>Info expédition</h2>
				<div id={"admin_ville_info_disable"}>
					<TextInput name={"created_by"} source={"created_by"} format={(value) => (value ? value.pseudo : "")} label={"Createur"} disabled={true} />
					<DateTimeInput name={"created_at"} source={"created_at"} label={"Date création"} disabled={true} />
					<TextInput name={"modify_by"} source={"modify_by"} format={(value) => (value ? value.pseudo : "")} label={"Modificateur"} disabled={true} />
					<DateTimeInput name={"modify_at"} source={"modify_at"} label={"Date modification"} disabled={true} />
					<NumberInput name={"jour"} source={"jour"} label={"Jour"} disabled={true} />
					<NumberInput name={"pa"} source={"pa"} label={"PA"} disabled={true} />
					<ColorInput name={"couleur"} source="couleur" label="Couleur" disabled={true} />
				</div>
				<div id={"admin_ville_info_edit"}>
					<BooleanInput name={"visible"} source={"visible"} label={"Visible"} />
					<BooleanInput name={"collab"} source={"collab"} label={"Collaborateur"} />
					<BooleanInput name={"personnel"} source={"personnel"} label={"Expedition privée"} />
					<BooleanInput name={"inactive"} source={"inactive"} label={"Expedition supprimée"} />
				</div>
			</div>
		
		</SimpleForm>
	</Edit>);
};
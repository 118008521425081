import React, { useEffect, useState } from "react";
import { CoalitionPropsType }         from "../../../types/components/Jump/CoalitionProps.type";
import HTMLParser                     from "html-react-parser";
import SvgIcone                       from "../../../components/generality/SvgIcone";
import { CoalitionsJumpApi }          from "../../../services/api/CoalitionsJumpApi";
import { formatInTimeZone }           from "date-fns-tz";
import { useGeneralContext }          from "../../../types/Context/GeneralContext";
import { DispoJumpDTO }               from "../../../types/models/dispoJump.dto";
import { JumpDTO }                    from "../../../types/models/jump.dto";
import { CoalitionDTO }               from "../../../types/models/coalition.dto";
import { InscriptionJumpDTO }         from "../../../types/models/inscriptionJump.dto";
import { useTranslation }             from "react-i18next";
import TooltipGH                      from "../../../components/utils/TooltipGH";
import CompetenceBadge                from "../../../components/Citoyens/CompetencesBadge";

export default function ListingCoalitions(props: {
	coalition: CoalitionPropsType,
	jump: JumpDTO,
	listInscription: InscriptionJumpDTO[],
	onRetour: (isError: boolean, showMod: boolean, messagePop: string) => void
	onJumpMaj: (jump: JumpDTO) => void
}) {
	const { t } = useTranslation();
	const { general } = useGeneralContext();
	const jump = props.jump;
	const listInscription = props.listInscription;
	const listSkill = props.coalition.options.listSkill;
	const listSkillLvl = props.coalition.options.listSkillLvl;
	const coalitionApi = new CoalitionsJumpApi();
	
	const joueursEnCoalition: number[] = [];
	Object.values(jump.coalitions).map((coalition) => {
		if (coalition.user) {
			joueursEnCoalition.push(coalition.user.id);
		}
	});
	
	const [coalitions, setCoalitions] = useState(initialisationCoalition(jump));
	const [coalitionStatut, setCoalitionStatut] = useState(null);
	const [coalitionCreateur, setCoalitionCreateur] = useState(null);
	const [coalitionDispo, setCoalitionDispo] = useState(null);
	const [coalitionDispoIndex, setCoalitionDispoIndex] = useState(null);
	const [placementJoueurValue, setPlacementJoueurValue] = useState("");
	const [coalitionArrivedValue, setCoalitionArrivedValue] = useState("");
	
	useEffect(() => {
		setCoalitions(initialisationCoalition(props.jump));
	}, [props.jump]);
	
	const handleChangeStatutCoas = (event, coalition: CoalitionDTO) => {
		const selectedValue = parseInt(event.target.value, 10); // Conversion la valeur en nombre si nécessaire
		
		coalitionApi.majStatutCoalition({ idJump: jump.id, userId: coalition.user.id, idStatut: selectedValue })
			.then((response) => {
				if (response.codeRetour === 0) {
					setCoalitionStatut(coalition);
					
					// Mise à jour de l'état des coalitions en fonction de la sélection
					const updatedCoalitions = coalitions.map((coalitionsItem) => {
						return coalitionsItem.map((ligneCoa) => {
							if (ligneCoa.num_coa === coalition.num_coa && ligneCoa.position_coa === coalition.position_coa) {
								// Mise à jour de la coalition cible ici
								const statut = selectedValue === 0 ? null : Object.values(props.coalition.options.listStatut).find((statutItem) => statutItem.id === selectedValue);
								return { ...ligneCoa, statut: statut };
							} else {
								// Aucune mise à jour nécessaire pour les autres éléments
								return ligneCoa;
							}
						});
					});
					
					setCoalitions(updatedCoalitions);
					setTimeout(() => {
						setCoalitionStatut(null);
					}, 1000);
				} else {
					props.onRetour(true, true, response.libRetour);
				}
			});
	};
	
	const handleChangeDisposCoas = (event, index_creneau, coalition: CoalitionDTO) => {
		const selectedValue = parseInt(event.target.value, 10); // Conversion la valeur en nombre si nécessaire
		
		coalitionApi.majDispoCoalition({ idJump: jump.id, userId: coalition.user.id, idDispo: selectedValue, idCreneau: index_creneau })
			.then((response) => {
				if (response.codeRetour === 0) {
					setCoalitionDispo(coalition);
					setCoalitionDispoIndex(index_creneau);
					
					// Mise à jour de l'état des coalitions en fonction de la sélection
					const updatedCoalitions = coalitions.map((coalitionsItem) => {
						return coalitionsItem.map((ligneCoa) => {
							if (ligneCoa.num_coa === coalition.num_coa && ligneCoa.position_coa === coalition.position_coa) {
								// Mise à jour de la coalition cible ici
								const newDispo = selectedValue === 0 ? null : Object.values(props.coalition.options.listTypeDispo).find((dispoItem) => dispoItem.id === selectedValue);
								
								const updatedDispo = ligneCoa.dispos.map((dispo, index) => {
									if (index === index_creneau) {
										return { ...dispo, choix_dispo: newDispo };
									} else {
										return dispo;
									}
								});
								
								
								return { ...ligneCoa, dispos: updatedDispo };
							} else {
								// Aucune mise à jour nécessaire pour les autres éléments
								return ligneCoa;
							}
						});
					});
					
					setCoalitions(updatedCoalitions);
					setTimeout(() => {
						setCoalitionDispo(null);
						setCoalitionDispoIndex(null);
					}, 1000);
				} else {
					props.onRetour(true, true, response.libRetour);
				}
			});
	};
	
	const handleChangeCreateurCoas = (event, coalition: CoalitionDTO) => {
		const selectedValue = event.target.checked; // Conversion la valeur en nombre si nécessaire
		
		coalitionApi.majCreateurCoalition(
			{ idJump: jump.id, userId: coalition.user.id, numCoa: coalition.num_coa, posCoa: coalition.position_coa, createur: selectedValue })
			.then((response) => {
				if (response.codeRetour === 0) {
					setCoalitionCreateur(coalition);
					props.onJumpMaj(response.zoneRetour.jump);
					setTimeout(() => {
						setCoalitionCreateur(null);
					}, 1000);
				} else {
					props.onRetour(true, true, response.libRetour);
				}
			});
	};
	
	const jobUser = (userId: number) => {
		// on récupère l'inscription du joueur
		const inscriptionJoueur = Object.values(listInscription).find((inscription) => inscription.user.id === userId);
		
		if (inscriptionJoueur.metier_def === null) {
			return <span>{t("Métier non défini", { ns: "jumpEvent" })}</span>;
		} else {
			return <span className={"d-flex gap-1 align-items-center"}>
                <SvgIcone icone={"h_" + inscriptionJoueur.metier_def.icon} />
                <span>{t(inscriptionJoueur.metier_def.alternatif, { ns: "jump" })}</span>
            </span>;
		}
	};
	
	const competenceUser = (userId: number) => {
		// on récupère l'inscription du joueur
		const inscriptionJoueur = Object.values(listInscription).find((inscription) => inscription.user.id === userId);
		
		if (inscriptionJoueur.skill.length === 0) {
			return <span>{t("Compétence non défini", { ns: "jumpEvent" })}</span>;
		} else {
			return <span className={"d-flex gap-1 align-items-center justify-content-center"}>{listSkill.map((type, index) => {
				const userSkill = (inscriptionJoueur.skill ?? []).find((skill) => skill.heros_skill_type.id === type.id) ?? listSkillLvl.find(skill => skill.heros_skill_type.id === type.id && skill.lvl === 1);
				return <CompetenceBadge skillLevel={userSkill} personnalisation={general.themeUser} key={index} />;
			})}</span>;
		}
	};
	
	const createurCoa = (coalition: CoalitionDTO) => {
		// on récupère les joueurs de la coalition
		const listJoueurs = coalitions[coalition.num_coa - 1];
		const auMoinsUnCreateur = Object.values(listJoueurs).some((ligneCoa) => ligneCoa.createur === true);
		
		return <input type={"checkbox"} disabled={auMoinsUnCreateur && !coalition.createur} checked={coalition.createur}
					  onChange={(event) => handleChangeCreateurCoas(event, coalition)} />;
	};
	
	const statutUser = (coalition: CoalitionDTO) => {
		return <select value={coalition.statut?.id ?? 0}
					   style={(coalitionStatut !== null && coalitionStatut.num_coa === coalition.num_coa && coalitionStatut.position_coa ===
						   coalition.position_coa) ? { border: "5px solid green" } : {}}
					   onChange={(event) => handleChangeStatutCoas(event, coalition)}
		>
			<option value={0} key={"coa_" + coalition.position_coa + "_" + coalition.num_coa + "_" + 0}>{t("Choix statut", { ns: "jump" })}</option>
			{Object.values(props.coalition.options.listStatut).map((statut) => {
				return <option value={statut.id} key={"coa_" + coalition.position_coa + "_" + coalition.num_coa + "_" + statut.id}>{t(statut.nom, { ns: "jump" })}</option>;
			})}
		</select>;
	};
	
	const dispoUser = (dispoCreneau: DispoJumpDTO, index_creneau: number, coalition: CoalitionDTO) => {
		return <select value={dispoCreneau?.choix_dispo?.id ?? 0}
					   style={(coalitionDispo !== null && coalitionDispoIndex !== null && coalitionDispoIndex === index_creneau &&
						   coalitionDispo.num_coa === coalition.num_coa && coalitionDispo.position_coa === coalition.position_coa) ?
						   { border: "5px solid green" } : {}}
					   onChange={(event) => handleChangeDisposCoas(event, index_creneau, coalition)}
		>
			<option value={0} key={"coa_" + coalition.position_coa + "_" + coalition.num_coa + "_" + index_creneau + "_" +
				0}>{t("Choix dispo", { ns: "jump" })}</option>
			{Object.values(props.coalition.options.listTypeDispo).map((dispo) => {
				return <option value={dispo.id} key={"coa_" + coalition.position_coa + "_" + coalition.num_coa + "_" + index_creneau + "_" + dispo.id}>{t(dispo.nom, { ns: "jump" })}</option>;
			})}
		</select>;
	};
	
	const handleValidation = () => {
		if ((placementJoueurValue !== "" && placementJoueurValue !== null) && (coalitionArrivedValue !== "" && coalitionArrivedValue !== null)) {
			const [numCoa, posCoa, userIdEchange] = coalitionArrivedValue.split("-");
			
			const userIdEchangeApi = (userIdEchange === "0") ? null : parseInt(userIdEchange, 10);
			
			coalitionApi.inscriptionCoalition({
				idJump       : jump.id,
				userId       : props.coalition.user.id,
				numCoa       : parseInt(numCoa, 10),
				posCoa       : parseInt(posCoa, 10),
				idUserFournis: parseInt(placementJoueurValue, 10),
				idUserEchange: userIdEchangeApi,
			})
				.then((response) => {
					if (response.codeRetour === 0) {
						props.onJumpMaj(response.zoneRetour.jump);
					} else {
						props.onRetour(true, true, response.libRetour);
					}
				});
		}
	};
	
	
	return <div id="zone_coalition">
		<div id="placementCoalition">
			<div>
				<div id="blocFormCoa">{t("Placer", { ns: "jumpEvent" })}
					<select name="placementJoueur" onChange={(event) => setPlacementJoueurValue(event.target.value)}>
						<option value={""}>{t("Choix joueur", { ns: "jump" })}</option>
						{listInscription.sort((insA: InscriptionJumpDTO, insB: InscriptionJumpDTO) => {
							const pseudoA = insA.user.pseudo.toLowerCase();
							const pseudoB = insB.user.pseudo.toLowerCase();
							
							return pseudoA.localeCompare(pseudoB);
						}).map((inscription) => {
							const joueurEnCoalition = joueursEnCoalition.includes(inscription.user.id);
							const statut = joueurEnCoalition ? t("(en coa)", { ns: "jumpEvent" }) : "";
							
							return (
								<option
									key={inscription.user.id}
									value={inscription.user.id}
									className={(joueurEnCoalition) ? "presCoa" : null}
								>
									{inscription.user.pseudo} {statut}
								</option>
							);
						})}
					</select>
					{t("à la position :", { ns: "jumpEvent" })}
					<select name="coalitionArrived" onChange={(event) => setCoalitionArrivedValue(event.target.value)}>
						<option value={""}>{t("Choix position", { ns: "jump" })}</option>
						{Object.values(coalitions).map((coalition, numCoa) => {
							return <optgroup key={"coa_num_" + numCoa} label={t("Coalition n° {i}", { ns: "jumpEvent" }).replace("{i}", (numCoa + 1).toString())}>
								{Object.values(coalition).map((ligneCoa, posCoa) => {
									return <option value={(numCoa + 1) + "-" + (posCoa + 1) + "-" + ((ligneCoa.user === null) ? 0 : ligneCoa.user.id)}
												   key={"coa_num_" + numCoa + "_" + posCoa}>{posCoa + 1} - {(ligneCoa.user === null) ? "" : ligneCoa.user.pseudo}</option>;
								})}
							</optgroup>;
						})}
					</select>
					<button id="coa-val-placement" onClick={handleValidation}>{t("Valider", { ns: "jumpEvent" })}</button>
				</div>
			</div>
			<ul>
				<li>{t("Les pseudos en rouge dans la liste déroulante sont les joueurs déjà présent en coalition", { ns: "jumpEvent" })}</li>
				<li>{t("Si vous choississez une position dans une coalition où un joueur est déjà présent, cela effectuera un échange avec la personne que vous tentez de placer", { ns: "jumpEvent" })}</li>
				<li>{t("Pour changer un joueur de place, il suffit de le sélectionner et de choisir une nouvelle place libre ou déjà prise afin de faire un échange.", { ns: "jumpEvent" })}</li>
			</ul>
		</div>
		<div id={"table_coalition"}>
			{Object.values(coalitions).map((coa: CoalitionDTO[], index) => {
				return <table className="coalition fondWhite02" key={"table_coa_" + (index + 1)}>
					<thead>
					<tr className={"title_coalition coalition_" + (index + 1)}>
						<th colSpan={7 + jump.creneau.length}>{t("Coalition n° {i}", { ns: "jumpEvent" }).replace("{i}", (index + 1).toString())}</th>
					</tr>
					<tr className={"coalition_" + (index + 1)}>
						<th className="coalition_pos">{t("Pos", { ns: "jumpEvent" })}</th>
						<th className="coalition_pseudo">{t("Pseudo", { ns: "jumpEvent" })}</th>
						<th className="coalition_moyenContact">
							<TooltipGH>
                                <span className="infoBulle">
                                    <i className="fas fa-sms"></i>
                                </span>
								<span className="info">{t("Moyen de contact", { ns: "jumpEvent" })}</span>
							</TooltipGH>
						
						</th>
						<th className="coalition_metier"> {(jump.job_fige) ? (t("Metier", { ns: "jumpEvent" })) : (HTMLParser(t("Metier <br/> Potentiel", { ns: "jumpEvent" })))}</th>
						<th className="coalition_metier"> {(jump.fige_competence) ? (t("Compétences", { ns: "jumpEvent" })) : (HTMLParser(t("Compétences <br/> Potentielles", { ns: "jumpEvent" })))}</th>
						<th className="coalition_statut">{t("Statut", { ns: "jumpEvent" })}</th>
						<th className="coalition_moyenContact">
							<TooltipGH>
                                <span className="infoBulle">
                                    <i className="fa-solid fa-crown"></i>
                                </span>
								<span className="info">{t("Créateur", { ns: "jumpEvent" })}</span>
							</TooltipGH>
						</th>
						{Object.values(jump.creneau).map((creneau, idx) => {
							return <th className="coalition_dispo" key={"coa_" + (index + 1) + "_" + idx}>
								{formatInTimeZone(new Date(creneau.date_creneau), general.fuseau, t("dd / MM", { ns: "app" }))}
								<br />
								{HTMLParser(t(creneau.creneau_horraire.libelle, { ns: "jump" }))}
							</th>;
						})}
					</tr>
					</thead>
					<tbody>
					{Object.values(coa).map((ligneCoa, position) => {
						return <tr key={"coa_" + (index + 1) + "_pos_" + (position + 1)}>
							<td className="coalition_pos">{position + 1}</td>
							<td className="coalition_pseudo">{(ligneCoa.user === null) ? "" : ligneCoa.user.pseudo}</td>
							<td className="coalition_moyenContact">{(ligneCoa.user !== null && props.coalition.listMoyenContact[ligneCoa.user.id] !== undefined) ? (
								<TooltipGH>
                                    <span className="infoBulle">
                                        <i className="fas fa-sms"></i>
                                    </span>
									<span className="info">{HTMLParser(props.coalition.listMoyenContact[ligneCoa.user.id])}</span>
								</TooltipGH>
							) : ("")
							}</td>
							<td className="coalition_metier">{(ligneCoa.user === null) ? "" : jobUser(ligneCoa.user.id)}</td>
							<td className="coalition_metier">{(ligneCoa.user === null) ? "" : competenceUser(ligneCoa.user.id)}</td>
							<td className="coalition_statut">{(ligneCoa.user === null) ? "" : statutUser(ligneCoa)}</td>
							<td className="coalition_moyenContact"
								style={(coalitionCreateur !== null && ligneCoa.user !== null && coalitionCreateur.user.id === ligneCoa.user.id) ?
									{ backgroundColor: "green" } : {}}>{(ligneCoa.user === null) ? "" : createurCoa(ligneCoa)}</td>
							{Object.values(jump.creneau).map((creneau, idx) => {
								return <td className="coalition_dispo" key={"coa_" + (index + 1) + "_pos_" + (position + 1) + "_" + idx}>
									{(ligneCoa.user === null) ? "" : dispoUser(ligneCoa.dispos[idx], idx, ligneCoa)}
								</td>;
							})}
						</tr>;
					})}
					</tbody>
				</table>;
			})}
		</div>
	
	
	</div>;
}

export function initialisationCoalition(jump: JumpDTO): CoalitionDTO[][] {
	const coalition: CoalitionDTO[][] = [];
	
	// Nombre de lignes possibles pour le premier niveau (num_coa)
	const numCoaCount = 8;
	
	// Nombre de lignes pour chaque num_coa
	const positionCount = 5;
	
	for (let num_coa = 1; num_coa <= numCoaCount; num_coa++) {
		const numCoaRow = [];
		
		for (let position_coa = 1; position_coa <= positionCount; position_coa++) {
			
			// Recherche coalition existante dans jump
			const existingCoalition = Object.values(jump.coalitions).find(
				(coalition) =>
					coalition.num_coa === num_coa && coalition.position_coa === position_coa,
			);
			
			if (existingCoalition) {
				// Si une coalition existe, on réutilise la donnée
				numCoaRow.push(existingCoalition);
			} else {
				// Sinon, utilisez les valeurs par défaut
				numCoaRow.push({
					user       : null, // Utilisateur par défaut
					num_coa,
					dispos     : [], // Tableau de dispos
					statut_user: null, // Statut par défaut
					position_coa,
					createur   : false,
				});
			}
		}
		
		coalition.push(numCoaRow);
	}
	
	return coalition;
}
import { createContext, useContext } from "react";
import { EncyChantierContextType }   from "./EncyChantierContext.type";

export const EncyChantierContext = createContext<EncyChantierContextType>({
	listChantierChoisi   : [],
	setListChantierChoisi: () => {
	},
});

export function useECContext() {
	return useContext(EncyChantierContext);
}
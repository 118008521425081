import { createContext, useContext } from "react";
import { GeneralContextType }        from "./GeneralContext.type";

export const GeneralContext = createContext<GeneralContextType>({
	general   : null,
	setGeneral: (general) => {
	},
});

export function useGeneralContext() {
	return useContext(GeneralContext);
}
import Cookies                        from "js-cookie";
import React, { useEffect, useState } from "react";
import SvgIcone                       from "../../../components/generality/SvgIcone";
import { CarteType }                  from "../../../types/components/ville/Carte.type";
import { ExpeditionType }             from "../../../types/components/ville/Expedition.type";
import ConfirmationDialog             from "../../../components/generality/ComposantGeneral/ConfirmationDialog";
import { OutilsExpeditionTypeProps }  from "../../../types/components/Outils/OutilsExpedition.type";
import { useCarteContext }            from "../../../types/Context/CarteContext";
import { ExpeHordesDTO }              from "../../../types/models/expeHordes.dto";
import { useTranslation }             from "react-i18next";
import Modal                          from "react-bootstrap/Modal";
import { ExpeditionPartDTO }          from "../../../types/models/expeditionPart.dto";
import { InscriptionExpeditionApi }   from "../../../services/api/InscriptionExpeditionApi";
import { Status_error, usePopUp }     from "../../../types/Context/PopUpContext";
import { CreneauHorraireDTO }         from "../../../types/models/creneauHorraire.dto";
import { TabExpedition }              from "../../Hotel/Inscription/TabExpedition";
import { UserDTO }                    from "../../../types/models/user.dto";
import TooltipGH                      from "../../../components/utils/TooltipGH";

interface ExpeditionModuleProps {
	carte: CarteType;
	onUpdateSeletedExpe: (id: string) => void;
	onUpdateModeExpe: (mode_expe: boolean) => void;
	onUpdateSelectedExpes: (arraySelected: string[]) => void;
	onUpdateExpedition: (expedition: ExpeditionType, retracage: boolean) => void;
	onEditExpedition: (idExpe: string) => void;
	onSuppExpedition: (idExpe: string) => void;
	onSaveExpedition: (expedition: ExpeditionType) => void;
	onRefresh: () => void;
	arraySelect: string[];
	mode_expe: boolean;
	expeManuel: ExpeditionType;
	outilsExpedition?: OutilsExpeditionTypeProps;
}


export default function ExpeditionModule(props: ExpeditionModuleProps) {
	const expeditionCookie = Cookies.get("expeditionModule");
	const { t } = useTranslation();
	const { jourActuel } = useCarteContext();
	const [modeExpe, setModeExpe] = useState<boolean>(props.mode_expe);
	const [arraySelect, setArraySelect] = useState<string[]>(props.arraySelect);
	const [expedition, setExpedition] = useState<ExpeditionType>(props.expeManuel);
	const [visionMod, setVisionMod] = useState<string>("manuel");
	const [confirmationShow, setConfirmationShow] = useState<boolean>(false);
	const [idExpe, setIdExpe] = useState<string>("");
	const [expeditionMasquer, setExpeditionMasquer] = useState<string[]>(expeditionCookie ? JSON.parse(expeditionCookie) : []);
	const [outilsExpedition, setOutilsExpedition] = useState<OutilsExpeditionTypeProps>(props.outilsExpedition);
	const [showExpedition, setShowExpedition] = useState<boolean>(false);
	const [expeditionPart, setExpeditionPart] = useState<ExpeditionPartDTO>();
	const [creneaux, setCreneaux] = useState<CreneauHorraireDTO[]>([]);
	const [userOption, setUseroption] = useState<UserDTO>();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	
	const carte = props.carte;
	const listExpe = carte.listExp.filter((expedition) => !expedition.inactive);
	const listExpeBiblio = carte.listExpBiblio;
	const listExpeBrouillon = carte.listExpBrouillon;
	const listExpeHordes = carte.listExpHordes;
	const userId = carte.user.id;
	
	useEffect(() => {
		setExpedition(prevExpedition => ({
			...prevExpedition,
			id              : props.expeManuel.id,
			coordonnee      : props.expeManuel.coordonnee,
			pa              : props.expeManuel.pa,
			couleur         : props.expeManuel.couleur,
			nom             : props.expeManuel.nom,
			created_by      : props.expeManuel.created_by,
			collab          : props.expeManuel.collab,
			personnel       : props.expeManuel.collab ? false : props.expeManuel.personnel,
			jour            : props.expeManuel.jour,
			modify_by       : props.expeManuel.modify_by,
			biblio          : props.expeManuel.biblio,
			brouillon       : props.expeManuel.brouillon,
			trace_expedition: props.expeManuel.trace_expedition,
		}));
	}, [props.expeManuel]);
	
	useEffect(() => {
		setModeExpe(props.mode_expe);
	}, [props.mode_expe]);
	useEffect(() => {
		setArraySelect(props.arraySelect);
	}, [props.arraySelect]);
	
	const handleChangeModeAffichageExpe = (event: any) => {
		setVisionMod(event.target.value);
	};
	
	const handleSuppression = (idExpe: string, withConfirm: boolean) => {
		if (withConfirm) {
			// Afficher la fenêtre de confirmation de suppression
			setConfirmationShow(true);
			setIdExpe(idExpe);
		} else {
			props.onSuppExpedition(idExpe);
		}
	};
	
	const handleConfirmSuppression = () => {
		setConfirmationShow(false);
		props.onSuppExpedition(idExpe);
	};
	
	const handleCancelSuppression = () => {
		// Cacher la fenêtre de confirmation
		setConfirmationShow(false);
		setIdExpe("");
	};
	
	const handleUpdateExpeditionMasque = (expeditionMasquerMod: string[]) => {
		setExpeditionMasquer(expeditionMasquerMod);
		const expirationDate = new Date();
		expirationDate.setDate(expirationDate.getDate() + 365);
		Cookies.set("expeditionModule", JSON.stringify(expeditionMasquerMod), { expires: expirationDate, path: "/", secure: true });
	};
	
	const handleShowExpe = (idExpe: string) => {
		const expeditionApi = new InscriptionExpeditionApi(carte.ville.map_id);
		expeditionApi.expeditionJoueur(idExpe).then((response) => {
			if (response.data.expedition) {
				setExpeditionPart(response.data.expedition);
			}
			if (response.data.creneaux) {
				setCreneaux(response.data.creneaux);
			}
			if (response.data.userOption) {
				setUseroption(response.data.userOption);
			}
			setShowExpedition(true);
		}).catch((error) => {
			setStatus(Status_error);
			setMessagePopUp(error?.data?.error ?? error.message);
			setShowPop(true);
		});
		
	};
	
	return <div id="contenuZoneInfoExpedition">
		<div id="listTrace">
			<div id={"zone_groupe_expedition"}>
				{t("Voir les tracés d'expéditions de :", { ns: "ville" })}
				<select value={visionMod} onChange={handleChangeModeAffichageExpe}>
					<option value="manuel">{"Gest'Hordes"}</option>
					<option value="myhordes">{t("MyHordes", { ns: "ville" })}</option>
					{outilsExpedition && <option value="trace_outils">{t("Outils expédition", { ns: "ville" })}</option>}
					{outilsExpedition && <option value="biblio">{t("Bibliothèque", { ns: "ville" })}</option>}
				</select>
				{visionMod === "manuel" && (<div id={"zone_groupe_bouton_expedition"}>
					<button className={"btn btn-xs btn-primary"} id="selectAllExpe" onClick={() => {
						const arrayExpe = [];
						// On parcourt toutes les expes
						listExpe.filter((expedition) => {
							return (expedition.jour === jourActuel && expedition.trace_expedition === true) || expedition.trace_expedition === false;
						}).map((expe) => {
							// on vérifie si l'expédition n'est pas déjà dans le tableau et qu'elle ne soit pas dans le tableau des expedtions masquées
							if (!arrayExpe.includes(expe.id) && !expeditionMasquer.includes(expe.id)) {
								arrayExpe.push(expe.id);
							}
						});
						setArraySelect(arrayExpe);
						props.onUpdateSelectedExpes(arrayExpe);
					}}>{t("Sélectionner toutes les expeditions", { ns: "ville" })}</button>
					<button className={"btn btn-xs btn-danger"} id="deselectAllExpe" onClick={() => {
						setArraySelect([]);
						props.onUpdateSelectedExpes([]);
					}}>{t("Désélectionner toutes les expeditions", { ns: "ville" })}</button>
					<button className={"btn btn-xs btn-warning"} id="inverseSelectExpe" onClick={() => {
						// On initialise un nouveau tableau
						const newArrayExpe = [];
						// On récupère l'ancien tableau
						const oldArrayExpe = arraySelect;
						listExpe.map((expe) => {
							if (!oldArrayExpe.includes(expe.id) && !expeditionMasquer.includes(expe.id)) {
								newArrayExpe.push(expe.id);
							}
						});
						setArraySelect(newArrayExpe);
						props.onUpdateSelectedExpes(newArrayExpe);
					}}>{t("Inverser la sélection", { ns: "ville" })}</button>
				</div>)}
				{visionMod === "trace_outils" && (<div id={"zone_groupe_bouton_expedition"}>
					<button className={"btn btn-xs btn-primary"} id="selectAllExpe" onClick={() => {
						const arrayExpe = [];
						// On parcourt toutes les expes
						listExpeBrouillon.map((expe) => {
							// on vérifie si l'expédition n'est pas déjà dans le tableau et qu'elle ne soit pas dans le tableau des expedtions masquées
							if (!arrayExpe.includes(expe.id) && !expeditionMasquer.includes(expe.id)) {
								arrayExpe.push(expe.id);
							}
						});
						setArraySelect(arrayExpe);
						props.onUpdateSelectedExpes(arrayExpe);
					}}>{t("Sélectionner toutes les expeditions", { ns: "ville" })}</button>
					<button className={"btn btn-xs btn-danger"} id="deselectAllExpe" onClick={() => {
						setArraySelect([]);
						props.onUpdateSelectedExpes([]);
					}}>{t("Désélectionner toutes les expeditions", { ns: "ville" })}</button>
					<button className={"btn btn-xs btn-warning"} id="inverseSelectExpe" onClick={() => {
						// On initialise un nouveau tableau
						const newArrayExpe = [];
						// On récupère l'ancien tableau
						const oldArrayExpe = arraySelect;
						listExpeBrouillon.map((expe) => {
							if (!oldArrayExpe.includes(expe.id) && !expeditionMasquer.includes(expe.id)) {
								newArrayExpe.push(expe.id);
							}
						});
						setArraySelect(newArrayExpe);
						props.onUpdateSelectedExpes(newArrayExpe);
					}}>{t("Inverser la sélection", { ns: "ville" })}</button>
				</div>)}
				{visionMod === "biblio" && (<div id={"zone_groupe_bouton_expedition"}>
					<button className={"btn btn-xs btn-primary"} id="selectAllExpe" onClick={() => {
						const arrayExpe = [];
						// On parcourt toutes les expes
						listExpeBiblio.map((expe) => {
							// on vérifie si l'expédition n'est pas déjà dans le tableau et qu'elle ne soit pas dans le tableau des expedtions masquées
							if (!arrayExpe.includes(expe.id) && !expeditionMasquer.includes(expe.id)) {
								arrayExpe.push(expe.id);
							}
						});
						setArraySelect(arrayExpe);
						props.onUpdateSelectedExpes(arrayExpe);
					}}>{t("Sélectionner toutes les expeditions", { ns: "ville" })}</button>
					<button className={"btn btn-xs btn-danger"} id="deselectAllExpe" onClick={() => {
						setArraySelect([]);
						props.onUpdateSelectedExpes([]);
					}}>{t("Désélectionner toutes les expeditions", { ns: "ville" })}</button>
					<button className={"btn btn-xs btn-warning"} id="inverseSelectExpe" onClick={() => {
						// On initialise un nouveau tableau
						const newArrayExpe = [];
						// On récupère l'ancien tableau
						const oldArrayExpe = arraySelect;
						listExpeBiblio.map((expe) => {
							if (!oldArrayExpe.includes(expe.id) && !expeditionMasquer.includes(expe.id)) {
								newArrayExpe.push(expe.id);
							}
						});
						setArraySelect(newArrayExpe);
						props.onUpdateSelectedExpes(newArrayExpe);
					}}>{t("Inverser la sélection", { ns: "ville" })}</button>
				</div>)}
			</div>
			<div id="table_trace">
				{visionMod === "manuel" && (
					<div id="gestHordes">
						<table id="trace_carte">
							<thead>
							<tr>
								<th className="nom_coul_tb_trace">{t("Nom expédition - Couleur", { ns: "ville" })}</th>
								<th className="jour_tb_trace">{t("J", { ns: "ville" })}</th>
								<th className="pa_tb_trace"><span className={"d-flex gap-1 align-items-center justify-content-center"}><SvgIcone icone={"h_pa"} /></span></th>
								<th className="user_tb_trace">{t("Créateur", { ns: "ville" })}</th>
								<th className="user_tb_trace">{t("Modificateur", { ns: "ville" })}</th>
								<th className="collab_tb_trace">
									<TooltipGH>
                                        <span className="infoBulle">
                                            <i className="fa-solid fa-lock"></i>
                                        </span>
										<span className="info">{t("Privée", { ns: "ville" })}</span>
									</TooltipGH>
								</th>
								<th className="collab_tb_trace">
									<TooltipGH>
                                        <span className="infoBulle">
                                            <i className="fas fa-handshake"></i>
                                        </span>
										<span className="info">{t("Collaborative", { ns: "ville" })}</span>
									</TooltipGH>
								</th>
								<th className="action_tb_trace">{t("Action", { ns: "ville" })}</th>
							</tr>
							</thead>
							<tbody>
							{listExpe.filter((expedition) => {
								return (expedition.jour === jourActuel && expedition.trace_expedition === true) || expedition.trace_expedition === false;
							}).sort((expe_a, expe_b) => {
								// Tri sur le jour puis sur le nom de l'expedition
								if (expe_a.jour === expe_b.jour) {
									return expe_a.nom.localeCompare(expe_b.nom);
								} else {
									return expe_a.jour > expe_b.jour ? -1 : 1;
								}
							}).map((expe, index) => (
								<LigneTableTrace
									key={expe.id}
									arrayCreateur={carte.arrCreateurVivant}
									arraySelect={arraySelect}
									expe={expe}
									index={index}
									isLead={carte.isLead}
									myVille={carte.myVille}
									onEditExpe={props.onEditExpedition}
									onSuppExpe={handleSuppression}
									onUpdateSeletedExpe={props.onUpdateSeletedExpe}
									userId={userId}
									expeditionMasquer={expeditionMasquer}
									onUpdateMasqueExpe={handleUpdateExpeditionMasque}
									isSpecifique={false}
									inCarte={outilsExpedition === null}
									isMyExpe={carte.listExpInscrit?.includes(expe.id) ?? false}
									onShowExpe={(idExpe) => handleShowExpe(idExpe)}
								/>
							))}
							</tbody>
						</table>
					</div>
				)}
				{visionMod === "myhordes" && (
					<div id="myHordes">
						<table id="trace_hordes">
							<thead>
							<tr>
								<th className="nom_coul_tb_trace">{t("Nom expédition - Couleur", { ns: "ville" })}</th>
								<th className="pa_tb_trace"><span className={"d-flex gap-1 align-items-center justify-content-center"}><SvgIcone icone={"h_pa"} /></span></th>
								<th className="user_tb_trace">{t("Créateur", { ns: "ville" })}</th>
							</tr>
							</thead>
							<tbody>
							{listExpeHordes.map((expe, index) => (
								<LigneTableTraceHordes
									key={expe.id}
									expe={expe}
									index={index}
									arraySelect={arraySelect}
									onUpdateSeletedExpe={props.onUpdateSeletedExpe}
									couleurExpe={carte.couleurHordes[expe.id]}
								/>
							))}
							</tbody>
						</table>
					</div>
				)}
				{outilsExpedition && visionMod === "trace_outils" && (
					<div id="gestHordes">
						<table id="trace_carte">
							<thead>
							<tr>
								<th className="nom_coul_tb_trace">{t("Nom expédition - Couleur", { ns: "ville" })}</th>
								<th className="pa_tb_trace"><span className={"d-flex gap-1 align-items-center justify-content-center"}><SvgIcone icone={"h_pa"} /></span></th>
								<th className="user_tb_trace">{t("Créateur", { ns: "ville" })}</th>
								<th className="user_tb_trace">{t("Modificateur", { ns: "ville" })}</th>
								<th className="action_tb_trace">{t("Action", { ns: "ville" })}</th>
							</tr>
							</thead>
							<tbody>
							{listExpeBrouillon.sort((expe_a, expe_b) => {
								// Tri sur le jour puis sur le nom de l'expedition
								return expe_a.nom.localeCompare(expe_b.nom);
							}).map((expe, index) => (
								<LigneTableTrace
									key={expe.id}
									arrayCreateur={carte.arrCreateurVivant}
									arraySelect={arraySelect}
									expe={expe}
									index={index}
									isLead={carte.isLead}
									myVille={carte.myVille}
									onEditExpe={props.onEditExpedition}
									onSuppExpe={handleSuppression}
									onUpdateSeletedExpe={props.onUpdateSeletedExpe}
									userId={userId}
									expeditionMasquer={expeditionMasquer}
									onUpdateMasqueExpe={handleUpdateExpeditionMasque}
									isSpecifique={true}
									inCarte={outilsExpedition === null}
									isMyExpe={false}
								/>
							))}
							</tbody>
						</table>
					</div>
				)}
				{outilsExpedition && visionMod === "biblio" && (
					<div id="gestHordes">
						<table id="trace_carte">
							<thead>
							<tr>
								<th className="nom_coul_tb_trace">{t("Nom expédition - Couleur", { ns: "ville" })}</th>
								<th className="pa_tb_trace"><span className={"d-flex gap-1 align-items-center justify-content-center"}><SvgIcone icone={"h_pa"} /></span></th>
								<th className="user_tb_trace">{t("Créateur", { ns: "ville" })}</th>
								<th className="user_tb_trace">{t("Modificateur", { ns: "ville" })}</th>
								<th className="action_tb_trace">{t("Action", { ns: "ville" })}</th>
							</tr>
							</thead>
							<tbody>
							{listExpeBiblio.sort((expe_a, expe_b) => {
								// Tri sur le jour puis sur le nom de l'expedition
								return expe_a.nom.localeCompare(expe_b.nom);
							}).map((expe, index) => (
								<LigneTableTrace
									key={expe.id}
									arrayCreateur={carte.arrCreateurVivant}
									arraySelect={arraySelect}
									expe={expe}
									index={index}
									isLead={carte.isLead}
									myVille={carte.myVille}
									onEditExpe={props.onEditExpedition}
									onSuppExpe={handleSuppression}
									onUpdateSeletedExpe={props.onUpdateSeletedExpe}
									userId={userId}
									expeditionMasquer={expeditionMasquer}
									onUpdateMasqueExpe={handleUpdateExpeditionMasque}
									isSpecifique={true}
									inCarte={outilsExpedition === null}
									isMyExpe={false}
								/>
							))}
							</tbody>
						</table>
					</div>
				)}
			</div>
		</div>
		<div id="formTrace">
			{(carte.myVille || carte.isLead) && (
				<React.Fragment>
					<div>
						<label htmlFor="activation_mode_expe">{t("Activer le mode expédition", { ns: "ville" })}</label>
						<input type="checkbox" name="activation_mode_expe" id="activation_mode_expe" checked={modeExpe} onChange={(event) => {
							const { checked } = event.target;
							setModeExpe(checked);
							props.onUpdateModeExpe(checked);
						}} />
					</div>
					{modeExpe && (
						<div id={"form_zone_expe"}>
							<div>
								<label htmlFor={"expe_form_nom"} className={"d-flex gap-1 align-items-center justify-content-center mb-1 mt-1"}>
									<span>{t("Nom de l'expédition :", { ns: "ville" })}</span>
									<span>(</span>
									<span className={"d-flex align-items-center justify-content-center"}>
                                        <span id="pa_trace_expe">{expedition.pa}</span>
                                        <SvgIcone icone={"h_pa"} />
                                    </span>
									<span>)</span>
								</label>
								<input type={"text"} id={"expe_form_nom"} name={"expeForm[nom]"} disabled={expedition.trace_expedition} value={expedition.nom}
									   onChange={(event) => {
										   const value = event.target.value;
										   const newExpedition = { ...expedition, nom: value };
										   setExpedition(newExpedition);
										   props.onUpdateExpedition(newExpedition, false);
									   }} />
							</div>
							<div className={"expe_form_zone"}>
								<label htmlFor={"expe_form_couleur"}>{t("Couleur de l'expédition :", { ns: "ville" })}</label>
								<input type={"color"} id={"expe_form_couleur"} name={"expeForm[couleur]"} value={expedition.couleur} onChange={(event) => {
									const value = event.target.value;
									const newExpedition = { ...expedition, couleur: value };
									setExpedition(newExpedition);
									props.onUpdateExpedition(newExpedition, false);
								}} />
							</div>
							{!outilsExpedition && <div className={"expe_form_zone"}>
								<label htmlFor={"expe_form_jour"}>{t("Jour", { ns: "ville" })}</label>
								<input type={"number"} id={"expe_form_jour"} name={"expeForm[jour]"} value={expedition.jour} disabled={expedition.trace_expedition}
									   onChange={(event) => {
										   const value = parseInt(event.target.value, 10);
										   const newExpedition: ExpeditionType = { ...expedition, jour: value < 0 ? 0 : value };
										   setExpedition(newExpedition);
										   props.onUpdateExpedition(newExpedition, false);
									   }} />
							</div>}
							{outilsExpedition && <div className={"expe_form_zone"}>
								<label htmlFor={"expe_form_biblio"}>{t("Tracé en bibliothèque", { ns: "ville" })}</label>
								<TooltipGH>
                                    <span className={"infoBulle"}>
                                        <i className={"fas fa-info-circle"}></i>
                                    </span>
									<span className={"info"}>
                                            <div className={"help_text"} id={"expe_form_biblio_help"}>{t("Permet de sauvegarder le tracé dans la bibliothèque et de pouvoir être réutilisable à l'infini.", { ns: "ville" })}</div>
                                    </span>
								</TooltipGH>
								<input type={"checkbox"} id={"expe_form_biblio"} name={"expeForm[biblio]"} checked={expedition.biblio}
									   disabled={expedition.trace_expedition} onChange={(event) => {
									const value = event.target.checked;
									const newExpedition = { ...expedition, biblio: value };
									setExpedition(newExpedition);
									props.onUpdateExpedition(newExpedition, false);
								}} />
							</div>}
							{(!outilsExpedition || (!expedition.biblio && !expedition.brouillon)) && <div className={"expe_form_zone"}>
								<label htmlFor={"expe_form_collab"}>{t("Tracé en mode collaboratif", { ns: "ville" })}</label>
								<TooltipGH>
                                    <span className={"infoBulle"}>
                                        <i className={"fas fa-info-circle"}></i>
                                    </span>
									<span className={"info"}>
                                            <div className={"help_text"} id={"expe_form_collab_help"}>{t("Le tracé pourra alors être modifié par n'importe qui de la ville", { ns: "ville" })}</div>
                                    </span>
								</TooltipGH>
								<input type={"checkbox"} id={"expe_form_collab"} name={"expeForm[collab]"}
									   disabled={expedition.trace_expedition || expedition.created_by?.id !== carte.user.id}
									   checked={expedition.collab} onChange={(event) => {
									const value = event.target.checked;
									let newValPerso = expedition.personnel;
									if (newValPerso) {
										newValPerso = !newValPerso;
									}
									const newExpedition = { ...expedition, collab: value, personnel: newValPerso };
									setExpedition(newExpedition);
									props.onUpdateExpedition(newExpedition, false);
									const expirationDate = new Date();
									expirationDate.setDate(expirationDate.getDate() + 365);
									Cookies.set("mode_collab", value.toString(), { expires: expirationDate, path: "/", secure: true });
									Cookies.set("mode_perso", newValPerso.toString(), { expires: expirationDate, path: "/", secure: true });
								}} />
							</div>}
							{(!outilsExpedition || (!expedition.biblio && !expedition.brouillon)) && <div className={"expe_form_zone"}>
								<label htmlFor={"expe_form_perso"}>{t("Tracé en mode privé", { ns: "ville" })}</label>
								<TooltipGH>
                                    <span className={"infoBulle"}>
                                        <i className={"fas fa-info-circle"}></i>
                                    </span>
									<span className={"info"}>
                                            <div className={"help_text"} id={"expe_form_perso_help"}>{t("Si vous cochez cette case, cette expédition ne sera visible que par vous", { ns: "ville" })}</div>
                                        </span>
								</TooltipGH>
								<input type={"checkbox"} id={"expe_form_perso"} name={"expeForm[perso]"}
									   disabled={expedition.trace_expedition || expedition.created_by?.id !== carte.user.id}
									   checked={expedition.personnel} onChange={(event) => {
									const value = event.target.checked;
									let newValCollab = expedition.collab;
									if (newValCollab) {
										newValCollab = !newValCollab;
									}
									const newExpedition = { ...expedition, personnel: value, collab: newValCollab };
									setExpedition(newExpedition);
									props.onUpdateExpedition(newExpedition, false);
									const expirationDate = new Date();
									expirationDate.setDate(expirationDate.getDate() + 365);
									Cookies.set("mode_perso", value.toString(), { expires: expirationDate, path: "/", secure: true });
									Cookies.set("mode_collab", newValCollab.toString(), { expires: expirationDate, path: "/", secure: true });
								}} />
							</div>}
							<div className={"ligneForm"} id={"form_expe_ligne_button"}>
								<div>
									<button className={"btn btn-xs btn-success"} type={"button"} id={"expe_form_save"} name={"expeForm[save]"} onClick={(event) => {
										event.preventDefault();
										props.onSaveExpedition(expedition);
									}}>{t("Enregistrer", { ns: "ville" })}</button>
								</div>
								<div>
									<button className={"btn btn-xs btn-warning"} type={"button"} id={"expe_form_cancel"} name={"expeForm[cancel]"} onClick={(event) => {
										event.preventDefault();
										const modeCollab = Cookies.get("mode_collab");
										const modeBiblio = Cookies.get("mode_biblio");
										const modePerso = Cookies.get("mode_perso");
										const newExpedition = {
											...expedition,
											id              : "",
											nom             : "",
											created_by      : props.carte.user,
											modify_by       : null,
											collab          : (carte.outilsExpe === null && modeCollab) ? JSON.parse(modeCollab) === true : false,
											personnel       : (carte.outilsExpe === null && modePerso) ? JSON.parse(modePerso) === true : false,
											jour            : props.carte.ville.jour,
											coordonnee      : [],
											couleur         : "#" + (Math.floor(Math.random() * 16777215).toString(16) + "000000").slice(0, 6),
											pa              : 0,
											biblio          : (carte.outilsExpe !== null && modeBiblio) ? JSON.parse(modeBiblio) === true : false,
											brouillon       : carte.outilsExpe !== null,
											trace_expedition: false,
										};
										setExpedition(newExpedition);
										props.onUpdateExpedition(newExpedition, true);
									}}>{t("Annuler", { ns: "ville" })}</button>
								</div>
								<div>
									<button className={"btn btn-xs btn-warning"} type={"button"} id={"expe_form_canPoint"} name={"expeForm[canPoint]"} onClick={(event) => {
										event.preventDefault();
										if (expedition.coordonnee.length > 1) {
											const newExpedition = {
												...expedition,
												coordonnee: expedition.coordonnee.slice(0, -1),
											};
											setExpedition(newExpedition);
											props.onUpdateExpedition(newExpedition, true);
										}
									}}>{t("Annule le dernier point", { ns: "ville" })}</button>
								</div>
							</div>
						</div>
					)}
				</React.Fragment>
			)}
		</div>
		<ConfirmationDialog
			message={t("Êtes-vous sûr de supprimer définitivement cette expédition dont vous n'êtes pas le créateur ?", { ns: "ville" })}
			titre={t("Confirmation de suppression", { ns: "ville" })}
			btnAnnuler={t("Annuler", { ns: "ville" })}
			btnConfirmation={t("Confirmer", { ns: "ville" })}
			show={confirmationShow}
			onConfirm={handleConfirmSuppression}
			onCancel={handleCancelSuppression}
		/>
		<Modal show={showExpedition} onHide={() => setShowExpedition(false)} centered key={"modal_camping"}>
			{expeditionPart && <div id="infoExpedition">
				<TabExpedition expedition={expeditionPart} creneaux={creneaux} userOption={userOption} />
				<button onClick={() => setShowExpedition(false)} type={"button"} className={"btn btn-xs btn-primary"}>
					{t("Fermer", { ns: "app" })}
				</button>
			</div>}
		</Modal>
	</div>;
}

function LigneTableTrace(props: {
	expe: ExpeditionType;
	index: number;
	arraySelect: string[];
	arrayCreateur: number[];
	userId: number;
	myVille: boolean;
	isLead: boolean;
	onUpdateSeletedExpe: (idExpe: string) => void;
	onEditExpe: (idExpe: string) => void;
	onSuppExpe: (id: string, withConfirm: boolean) => void;
	expeditionMasquer: string[];
	onUpdateMasqueExpe: (expeditionMasquer: string[]) => void;
	inCarte: boolean;
	isSpecifique?: boolean;
	isMyExpe: boolean;
	onShowExpe?: (idExpe: string) => void;
}) {
	const { t } = useTranslation();
	const expe = props.expe;
	const index = props.index;
	const arraySelect = props.arraySelect;
	const arrayCreateur = props.arrayCreateur;
	const userId = props.userId;
	const myVille = props.myVille;
	const isLead = props.isLead;
	const inCarte = props.inCarte;
	const isMyExpe = props.isMyExpe;
	const isSpecifique = props.isSpecifique;
	const [expeditionMasquer, setExpeditionMasquer] = useState<string[]>(props.expeditionMasquer);
	
	useEffect(() => {
		setExpeditionMasquer(props.expeditionMasquer);
	}, [props.expeditionMasquer]);
	
	return (
		<tr
			id={`trace_tab_expe_${expe.id}`}
			className={`visualiser_expe ${index % 2 === 0 ? "fondWhite02" : "fondBlack02"} ${arraySelect.includes(expe.id) ? "select_expe selectedExpe" : (isMyExpe ? "myExpe" : "")}`}
			onClick={() => {
				props.onUpdateSeletedExpe(expe.id);
			}}>
			<td className="nom_coul_tb_trace">
				<div>
					<span>{expe.nom}</span>
					<span className={"group-btn-color-expedition"}>
                        {expe.trace_expedition && <button className={"btn btn-xs btn-primary"} onClick={(event) => {
							event.stopPropagation();
							props.onShowExpe(expe.id);
						}}><i className="fa-solid fa-street-view"></i></button>}
						<span className="list_couleur_trace" style={{ backgroundColor: expe.couleur }} />
                    </span>
				</div>
			</td>
			{!isSpecifique && <td className="jour_tb_trace">{expe.jour}</td>}
			<td className="pa_tb_trace">{expe.pa}</td>
			<td className={"user_tb_trace" + ((userId === expe.created_by.id) ? " my_trace_expedition" : "")}>{expe.created_by.pseudo} {(userId ===
				expe.created_by.id) && <span className={"color-red"}>*</span>}</td>
			{expe.modify_by === null ? (<td className="user_tb_trace">/</td>) : (<td className="user_tb_trace">{expe.modify_by.pseudo}</td>)}
			{!isSpecifique && <td className="collab_tb_trace">{expe.personnel ? (<i className="fas fa-check-circle color-green" />) : null}</td>}
			{!isSpecifique && <td className="collab_tb_trace">{expe.collab ? <i className="fas fa-check-circle color-green" /> : null}</td>}
			<td className="action_tb_trace">{(myVille || isLead) ?
				(<div>{expe.collab || expe.created_by?.id === userId ? (<div>
						<button className="edition_trace_expe btn btn-success btn-xs" onClick={(event) => {
							event.stopPropagation();
							props.onEditExpe(expe.id);
						}}><i className="fas fa-edit" /></button>
					</div>) : (<div>
						<button className="edition_trace_expe btn btn-success btn-xs" disabled><i className="fas fa-edit" /></button>
					</div>)}
						{expe.trace_expedition ? (
							<div></div>
						) : (
							arrayCreateur[expe.created_by?.id] === undefined || expe.created_by?.id === userId ? (
								<div>
									<button className="supp_trace_expe btn btn-danger btn-xs" onClick={(event) => {
										event.stopPropagation();
										props.onSuppExpe(expe.id, false);
									}}><i className="fas fa-trash" /></button>
								</div>
							) : expe.collab ? (
								<div>
									<button className="supp_trace_expe btn btn-danger btn-xs" onClick={(event) => {
										event.stopPropagation();
										props.onSuppExpe(expe.id, true);
									}}><i className="fas fa-trash" /></button>
								</div>
							) : (
								<div>
									<button className={"btn btn-danger btn-xs"} disabled><i className="fas fa-trash" /></button>
								</div>
							)
						)}
						<div>
							<TooltipGH>
								<button className="edition_trace_expe btn btn-primary btn-xs" onClick={(event) => {
									event.preventDefault();
									event.stopPropagation();
									// on fait une copie d'expeditionMasquer
									const expeditionMasquerNew = [...expeditionMasquer];
									// Si l'expe est masquée, on l'enlève du tableau, sinon on l'ajoute
									if (expeditionMasquerNew.includes(expe.id)) {
										expeditionMasquerNew.splice(expeditionMasquerNew.indexOf(expe.id), 1);
									} else {
										expeditionMasquerNew.push(expe.id);
									}
									props.onUpdateMasqueExpe(expeditionMasquerNew);
									setExpeditionMasquer(expeditionMasquerNew);
								}}>
									<span className={"infoBulle"}>{expeditionMasquer.includes(expe.id) ? <i className="fa-solid fa-eye-slash"></i> : (<i className="fa-solid fa-eye"></i>)}</span>
								</button>
								<span className={"infoExpedition"}>{t("Permet de masquer une expédition en la rendant insélectionnable pour les boutons de selection rapide, l'action est réversible en recliquant sur l'oeil barré.", { ns: "ville" })}</span>
							</TooltipGH>
						</div>
					</div>
				) : null}
			</td>
		</tr>
	);
}

function LigneTableTraceHordes(props: {
	expe: ExpeHordesDTO;
	couleurExpe: string;
	index: number;
	arraySelect: any;
	onUpdateSeletedExpe: (arg0: string | number) => void;
}) {
	const expe = props.expe;
	const expeCouleur = props.couleurExpe;
	const arraySelect = props.arraySelect;
	const index = props.index;
	
	return (
		<tr
			id={`trace_tab_expe_${expe.id}`}
			data-statut={arraySelect.includes(expe.id) ? "visible" : "hidden"}
			className={`visualiser_expe ${
				index % 2 === 0 ? "fondWhite02" : "fondBlack02"
			} ${arraySelect.includes(expe.id) ? "select_expe selectedExpe" : ""}`}
			onClick={() => props.onUpdateSeletedExpe(expe.id)}
		>
			<td className="nom_coul_tb_trace">
				<div>
					{expe.nom}{" "}
					<span className="list_couleur_trace" style={{ backgroundColor: expeCouleur }} />
				</div>
			</td>
			<td className="pa_tb_trace">{expe.length}</td>
			<td className="user_tb_trace">{expe.citoyen.pseudo}</td>
		</tr>
	);
}
import * as React                                                                                                                                                from "react";
import { BooleanField, BooleanInput, Create, Datagrid, Edit, EditButton, List, NumberField, ReferenceInput, SimpleForm, TextField, TextInput, useRecordContext } from "react-admin";
import { AdminApi }                                                                                                                                              from "../../../services/api/AdminApi";
import { Status_error, Status_success, usePopUp }                                                                                                                from "../../../types/Context/PopUpContext";
import Button                                                                                                                                                    from "@mui/material/Button";
import { Link }                                                                                                                                                  from "react-router";

const BaseButton = () => {
	return (
		<Button component={Link} to={"/admin/menu/design"} color="primary">
			Design Menu Base
		</Button>
	);
};

const MajJson = () => {
	const adminApi = new AdminApi();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const handleUpdate = () => {
		adminApi.maj_menus_json().then((response) => {
			setStatus(Status_success);
			setMessagePopUp(response.data.libRetour);
			setShowPop(true);
			setTimeout(() => {
				setShowPop(false);
				setMessagePopUp("");
			}, 1000);
		}).catch((error) => {
			setStatus(Status_error);
			setMessagePopUp(error?.data?.error ?? error?.message ?? "Erreur inconnue");
			setShowPop(true);
		});
	};
	return (
		<button className={"btn btn-primary"} onClick={handleUpdate}>
			Mettre à jour le fichier JSON
		</button>
	);
};

export const MenuList = (props) => {
	const postFilters = [
		<TextInput source="q" label="Search" alwaysOn key={"search_user"} />,
		<ReferenceInput source="id" label="User" reference="user" key={"search_user_id"} />,
	];
	
	return <>
		<MajJson />
		<BaseButton />
		<List {...props} filters={postFilters}>
			<Datagrid>
				<NumberField source="id" />
				<TextField source="label" label={"Label"} />
				<TextField source="category" label={"Cat."} />
				<TextField source="icone" label={"Icone"} />
				<TextField source="route" label={"Route"} />
				<BooleanField source={"hab_user"} label={"Hab. User"} />
				<BooleanField source={"hab_beta"} label={"Hab. Beta"} />
				<BooleanField source={"hab_admin"} label={"Hab. Admin"} />
				<BooleanField source={"connected"} label={"is Co"} />
				<BooleanField source={"ville"} label={"In Ville"} />
				<BooleanField source={"my_ville"} label={"My Ville"} />
				<BooleanField source={"for_user"} label={"For User"} />
				<EditButton />
			</Datagrid>
		</List>
	</>;
};

export const MenuEdit = (props) => {
	
	const PostTitle = () => {
		const record = useRecordContext();
		return <span>Edition de {record ? `"${record.label}"` : ""}</span>;
	};
	return <Edit {...props} title={<PostTitle />}>
		<SimpleForm>
			<TextInput source="label" label={"Label"} />
			<TextInput source="category" label={"Cat."} />
			<TextInput source="icone" label={"Icone"} />
			<TextInput source="route" label={"Route"} />
			<BooleanInput source={"hab_user"} label={"Hab. User"} />
			<BooleanInput source={"hab_beta"} label={"Hab. Beta"} />
			<BooleanInput source={"hab_admin"} label={"Hab. Admin"} />
			<BooleanInput source={"connected"} label={"is Co"} />
			<BooleanInput source={"ville"} label={"In Ville"} />
			<BooleanInput source={"my_ville"} label={"My Ville"} />
			<BooleanInput source={"for_user"} label={"For User"} />
		</SimpleForm>
	</Edit>;
};

export const MenuCreate = (props) => {
	return <Create {...props}>
		<SimpleForm>
			<TextInput source="label" label={"Label"} />
			<TextInput source="category" label={"Cat."} />
			<TextInput source="icone" label={"Icone"} />
			<TextInput source="route" label={"Route"} />
			<BooleanInput source={"hab_user"} label={"Hab. User"} />
			<BooleanInput source={"hab_beta"} label={"Hab. Beta"} />
			<BooleanInput source={"hab_admin"} label={"Hab. Admin"} />
			<BooleanInput source={"connected"} label={"is Co"} />
			<BooleanInput source={"ville"} label={"In Ville"} />
			<BooleanInput source={"my_ville"} label={"My Ville"} />
			<BooleanInput source={"for_user"} label={"For User"} />
		</SimpleForm>
	</Create>;
};
import * as React                                                                                                                              from "react";
import { BooleanInput, Create, Datagrid, Edit, List, NumberField, NumberInput, SimpleForm, TextField, TextInput, useDelete, useRecordContext } from "react-admin";
import { Link, useParams }                                                                                                                     from "react-router";
import { Button }                                                                                                                              from "@mui/material";
import { BiEditAlt }                                                                                                                           from "react-icons/bi";
import { BsDatabaseAdd, BsTrash }                                                                                                              from "react-icons/bs";

const EditHerosButton = () => {
	const heros = useRecordContext();
	return (
		<Button
			component={Link}
			to={`/admin/user/heros/${heros?.id}`}
			color="primary"
		>
			<BiEditAlt />
			Editer
		</Button>
	);
};

const DeleteHerosButton = () => {
	const record = useRecordContext();
	const [deleteOne, { isPending }] = useDelete(
		"heros",
		{ id: record.id, previousData: record },
	);
	const handleClick = () => {
		deleteOne().then(r => r);
	};
	
	return (
		<Button
			onClick={handleClick}
			disabled={isPending}
			color="error"
		>
			<BsTrash />
			Supprimer
		</Button>
	);
};

const AddHerosButton = () => (
	<Button
		component={Link}
		to="/admin/user/heros/create"
		color="primary"
		startIcon={<BsDatabaseAdd />}
	>
		Ajouter un pouvoir héros
	</Button>
);


export const HerosList = (props) => {
	
	
	const PostTitle = () => {
		return <span>Liste des categories objets</span>;
	};
	
	return <List {...props} title={<PostTitle />} resource="heros">
		<Datagrid>
			<TextField source="id" />
			<TextField source={"nom"} />
			<NumberField source={"ordre_recup"} />
			<EditHerosButton />
			<DeleteHerosButton />
		</Datagrid>
		<AddHerosButton />
	</List>;
};


export const HerosEdit = (props) => {
	
	const { id } = useParams();
	
	const PostTitle = () => {
		const record = useRecordContext();
		return <span>Edition pouvoir héros {record ? `"${record.nom}"` : ""}</span>;
	};
	
	return (<Edit {...props} title={<PostTitle />} resource={"heros"} id={id} redirect={"admin/user/heros"}>
		<SimpleForm>
			<NumberField source={"id"} label={"ID"} />
			<TextInput source={"nom"} label={"Nom"} />
			<TextInput source={"icon"} label={"Icon"} />
			<TextInput source={"description"} label={"Description"} multiline={true} />
			<NumberInput source={"jour"} label={"Jour"} />
			<NumberInput source={"jour_cumul"} label={"Jour Cumulé"} />
			<BooleanInput source={"pouv_actif"} label={"Pouvoir actif"} />
			<TextInput source={"method"} label={"Methode"} />
			<NumberInput source={"jour_cumul"} label={"Jour Cumulé"} />
		</SimpleForm>
	</Edit>);
};

export const HerosCreate = (props) => (
	<Create {...props} title="Créer un nouveau pouvoir héros" resource={"heros"} redirect={"admin/user/heros"}>
		<SimpleForm>
			<NumberField source={"id"} label={"ID"} />
			<TextInput name={"nom"} source={"nom"} label={"Nom"} />
			<TextInput name={"icon"} source={"icon"} label={"Icon"} />
			<TextInput name={"description"} source={"description"} label={"Description"} multiline={true} />
			<NumberInput name={"jour"} source={"jour"} label={"Jour"} />
			<NumberInput name={"jour_cumul"} source={"jour_cumul"} label={"Jour Cumulé"} />
			<BooleanInput name={"pouv_actif"} source={"pouv_actif"} label={"Pouvoir actif"} />
			<TextInput name={"method"} source={"method"} label={"Methode"} />
			<NumberInput name={"jour_cumul"} source={"jour_cumul"} label={"Jour Cumulé"} />
		</SimpleForm>
	</Create>
);
import React, { useEffect, useState } from "react";
import { PopUpMajCoffreProps }        from "../../../types/components/Hotel/Citoyens.type";
import { AffNbrIcon }                 from "../../../components/generality/ComposantGeneral";
import { ListingItemsNews }           from "../../../components/Transverse/ListingItemsNew";
import { CitoyensDTO }                from "../../../types/models/citoyens.dto";
import { ItemPrototypeDTO }           from "../../../types/models/itemPrototype.dto";
import { CoffreCitoyenDTO }           from "../../../types/models/coffreCitoyen.dto";
import { useTranslation }             from "react-i18next";

interface PopUpMajManuelProps {
	popUpMaj: PopUpMajCoffreProps,
	citoyen: CitoyensDTO,
	retourMajPopUp: string | null,
	typeRetourMajPopup: number | null,
	onSaveCase: (citoyen: CitoyensDTO) => void,
	onCancel: () => void,
}

const PopUpMajManuel = (props: PopUpMajManuelProps) => {
	const { t } = useTranslation();
	const [stateCitoyen, setStateCitoyen] = useState(props.citoyen);
	
	useEffect(() => {
		setStateCitoyen(props.citoyen);
	}, [props.citoyen]);
	
	const handleDecrementItems = (itemMod: ItemPrototypeDTO, broken: boolean) => {
		setStateCitoyen(prevState => {
			const items = prevState.coffres.map(itemCoffre => {
				if (itemCoffre.item.id === itemMod.id && itemCoffre.broken === broken && itemCoffre.nombre > 0) {
					return { ...itemCoffre, nombre: itemCoffre.nombre - 1 };
				}
				return itemCoffre;
			}).filter(itemCoffre => itemCoffre.nombre > 0); // Retirer les éléments avec count = 0
			return {
				...prevState,
				coffres: items,
			};
		});
	};
	
	const handleIncrementItems = (itemMod: ItemPrototypeDTO, broken: boolean) => {
		const itemCoffre = [...stateCitoyen.coffres];
		const index = itemCoffre.findIndex((item) => item.item.id === itemMod.id && item.broken === broken);
		if (index === -1) {
			itemCoffre.push({ item: itemMod, broken: broken, nombre: 1 });
		} else {
			itemCoffre[index].nombre++;
		}
		
		itemCoffre.sort((a, b) => a.item.nom.localeCompare(b.item.nom)); // Tri par ordre alphabétique de la propriété "name"
		
		setStateCitoyen(prevState => {
			return {
				...prevState,
				coffres: itemCoffre,
			};
		});
	};
	
	const listCatetogies = props.popUpMaj.listCategorie;
	const listItems = props.popUpMaj.listItems;
	const citoyen = stateCitoyen;
	
	return <React.Fragment>
		<div id="zoneInfoCoffre">
			<div><h2>{t("Mise à jour du coffre de", { ns: "ville" })} <span id="coffreUser">{citoyen.citoyen.pseudo}</span></h2></div>
			<p>{t("Obiet(s) dans le coffre :", { ns: "ville" })}</p>
			<div id="objetCoffreMaj">{citoyen.coffres.sort((a: CoffreCitoyenDTO, b: CoffreCitoyenDTO) => {
				const aName = t(a.item.nom, { ns: "game" }).toLowerCase();
				const bName = t(b.item.nom, { ns: "game" }).toLowerCase();
				return aName.localeCompare(bName);
			}).map(item =>
				<AffNbrIcon item={item.item}
							broken={item.broken}
							nbr={item.nombre}
							key={"obj_" + item.item.id + "_" + (item.broken ? 1 : 0)}
							onDecrementItem={handleDecrementItems} />)}
			</div>
			<div id="boutonMajCoffre">
				{(props.typeRetourMajPopup === null || (props.typeRetourMajPopup !== 0)) && <React.Fragment>
					<button id="enrCoffre" onClick={() => props.onSaveCase(citoyen)}>{t("Enregistrer", { ns: "ville" })}</button>
					<button id="annulCoffre" onClick={props.onCancel}>{t("Annuler", { ns: "ville" })}</button>
				</React.Fragment>}
				{props.typeRetourMajPopup !== null && props.typeRetourMajPopup === 0 &&
					<button id="fermerCoffre" onClick={props.onCancel}>{t("Fermer", { ns: "app" })}</button>}
			</div>
			
			{props.retourMajPopUp !== null &&
				<div id="retourAjaxMajCoffre" className={(props.typeRetourMajPopup === 0) ? "color-green" : "color-red"}>{props.retourMajPopUp}</div>}
		</div>
		<div id="listingObjetMajCoffre"><ListingItemsNews listCategory={listCatetogies} listItems={listItems} itemSelected={handleIncrementItems} /></div>
	</React.Fragment>;
};

export default React.memo(PopUpMajManuel);
import React, { useEffect, useRef } from "react";
import { Chart, registerables }     from "chart.js";
import { UserDTO }                  from "../types/models/user.dto";
import { ThemeUserDTO }             from "../types/models/themeUser.dto";

interface ChartProps {
	data: {
		legend: string[],
		nbr: number[],
		total?: number[],
	},
	id: string,
	refChart: string,
	user?: UserDTO
	width?: number,
	height?: number
	backgroundColor: string[],
	translate?: {
		titre: string,
		table: {
			legend: string,
			nbr: string,
			pct: string,
			total?: string,
		},
	},
	displayLegend?: boolean,
	themeUser?: ThemeUserDTO
}

const ChartPieComponant = ({ data, id, refChart, user = null, width = 500, height = 500, backgroundColor, translate = { titre: "", table: { legend: "", nbr: "", pct: "" } }, displayLegend = true, themeUser = null }: ChartProps) => {
	const chartPie = useRef<HTMLCanvasElement>(null);
	
	let colorFont = themeUser?.stats_font_color ?? "#ffffff";
	let colorGrid = themeUser?.stats_border_color ?? "rgba(255,255,255,0.15)";
	
	const buildChartTheme = () => {
		Chart.register(...registerables);
		const myChartRef = chartPie.current?.getContext("2d");
		const myChart = Chart.getChart(refChart);
		if (myChart) {
			myChart.destroy();
		}
		
		new Chart(myChartRef!, {
			type   : "pie",
			data   : {
				labels  : Object.values(data.legend),
				datasets: [{
					backgroundColor: backgroundColor,
					data           : Object.values(data.nbr),
				}],
			},
			options: {
				plugins: {
					legend: {
						display : displayLegend,
						position: "top",
						labels  : {
							color: colorFont,
						},
					},
					title : {
						display: true,
						text   : translate.titre,
						color  : colorFont,
					},
				},
			},
		});
	};
	
	useEffect(() => {
		buildChartTheme();
	}, [data]);
	
	// calcul total data pour le pourcentage
	const totalData = Object.values(data.nbr).reduce((a, b) => a + b, 0);
	
	return (
		<div id={id + "-groupe-stats-graph-table"}>
			<div id={id + "-graph-theme"}>
				<canvas ref={chartPie} id={refChart} width={width} height={height}></canvas>
			</div>
			<div id={id + "-table-stats-theme"}>
				<table>
					<thead>
					<tr>
						<th>{translate.table.legend}</th>
						<th>{translate.table.nbr}</th>
						<th>{translate.table.pct}</th>
						{translate.table.total && <th>{translate.table.total}</th>}
					</tr>
					</thead>
					<tbody>
					{Object.values(data.legend).map((value, index) => {
						return (
							<tr key={value + index}>
								<td>{value}</td>
								<td>{Object.values(data.nbr)[index]}</td>
								<td>{Math.round(Object.values(data.nbr)[index] / totalData * 10000) / 100}%</td>
								{translate.table.total && <td>{Math.round(Object.values(data.total)[index] / Object.values(data.nbr)[index] * 100) / 100}</td>}
							</tr>
						);
					})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default ChartPieComponant;
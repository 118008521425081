import React                        from "react";
import { OutilsChantiersTypeProps } from "../../../../types/components/Outils/OutilsChantiers.type";
import SvgIcone                     from "../../../../components/generality/SvgIcone";
import { DefenseClass }             from "../DefenseClass";
import { useOutilsChantiers }       from "../TabsOutilsChantiers";
import { ProgrammeChantierDTO }     from "../../../../types/models/programmeChantier.dto";
import { ChantiersDTO }             from "../../../../types/models/chantiers.dto";
import { useTranslation }           from "react-i18next";

export default function EnteteOutilsChantiers(props: { outils: OutilsChantiersTypeProps }) {
	const { t } = useTranslation();
	const { outilsChantiers } = useOutilsChantiers();
	const outils = props.outils;
	const recapChantier = outilsChantiers;
	const defense = outils.defense;
	
	
	const tdgaConstruit = isChantierPlannedOrBuilt(outils.listChantiersConstruit, recapChantier.chantiers_programmes, outils.option.id_tdga);
	const cimetiere_construit = isChantierPlannedOrBuilt(outils.listChantiersConstruit, recapChantier.chantiers_programmes, outils.option.id_cimetiere);
	const cercueil_construit = isChantierPlannedOrBuilt(outils.listChantiersConstruit, recapChantier.chantiers_programmes, outils.option.id_cercueil);
	const hamame_construit = isChantierPlannedOrBuilt(outils.listChantiersConstruit, recapChantier.chantiers_programmes, outils.option.id_hamame);
	
	const newDefense = new DefenseClass({
		def_base           : defense,
		chantier           : recapChantier.def_chantier,
		objet_def          : defense.objet,
		bonus_od           : defense.bonus_od,
		upgrade            : recapChantier.def_upgrade || 0,
		maison_citoyen     : recapChantier.def_maison || 0,
		gardiens           : outils.option.nb_gardien,
		veilleurs          : outils.recapDefAutre.veille,
		ames               : outils.option.nb_ame_banque,
		morts              : outils.option.nb_mort,
		bonusSD            : defense.bonus_sd_pct,
		tdga_construit     : tdgaConstruit,
		pa_tdga            : 15,
		decharges          : outils.recapDefAutre.decharge,
		cimetiere_construit: cimetiere_construit,
		cercueil_construit : cercueil_construit,
		hamame_construit   : hamame_construit,
		def_repa           : outils.recapDefAutre.reparation,
	});
	
	
	return <div id="recap_def_chantiers">
		<div id="def_chantier_actuelle">
			<p>{t("Défense actuelle", { ns: "outils" })}</p>
			<div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Muraille :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">10</span>
			</div>
			<div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Chantiers :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">{defense.buildings + defense.upgrades} ➔ ({defense.buildings} + {defense.upgrades})</span>
			</div>
			<div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Objets de défense :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">{
					Math.floor(defense.objet * defense.bonus_od / 10)} - ({defense.objet} {(defense.objet > 1) ? t("objets", { ns: "outils" }) : t("objet", { ns: "outils" })} x {defense.bonus_od / 10})</span>
			</div>
			<div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Maisons des citoyens :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">{defense.maison_citoyen} </span>
			</div>
			<div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Gardiens :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">{defense.gardiens} </span>
			</div>
			<div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Veilleurs :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">{defense.veilleurs} </span>
			</div>
			<div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Tempos :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">{defense.tempos} </span>
			</div>
			{defense.ames > 0 && <div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Âmes :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">{defense.ames} </span>
			</div>}
			{defense.morts > 0 && <div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Morts :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">{defense.morts} </span>
			</div>}
			{defense.def_s_d > 0 && <div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Bonus SD (+{bonusSd}%) :", { ns: "outils" }).replace("{bonusSd}", defense.bonus_sd_pct.toString())}</span>
				<span className="resultZoneInfoVille">{defense.def_s_d} </span>
			</div>}
			<div className="divZoneInfoVille" id="derZoneInfoVille">
				<span className="titreZoneInfoVille"><span>{t("Total", { ns: "ville" })} </span> <SvgIcone icone={"h_guard"} /> : </span>
				<span className="resultZoneInfoVille">{defense.total} ({defense.def_h_s_d} + {defense.def_s_d})</span>
			</div>
		</div>
		<div id="def_chantier_prevu">
			<p>{t("Défense future", { ns: "outils" })}</p>
			<div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Muraille :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">10</span>
			</div>
			<div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Chantiers :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">{newDefense.getChantiers() +
					newDefense.getUpgrades()} ➔ ({newDefense.getChantiers()} + {newDefense.getUpgrades()})</span>
			</div>
			<div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Objets de défense :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">
					{newDefense.getObjets()} - ({defense.objet} {(defense.objet > 1) ? t("objets", { ns: "outils" }) : t("objet", { ns: "outils" })} x {defense.bonus_od / 10})
				</span>
			</div>
			<div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Maisons des citoyens :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">{newDefense.getMaisons()} </span>
			</div>
			<div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Gardiens :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">{newDefense.getGardiens()} </span>
			</div>
			<div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Veilleurs :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">{newDefense.getVeilleurs()} </span>
			</div>
			<div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Tempos :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">{newDefense.getTempos()} </span>
			</div>
			{newDefense.getAmes() > 0 && <div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Âmes :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">{newDefense.getAmes()} </span>
			</div>}
			{newDefense.getMorts() > 0 && <div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Morts :", { ns: "ville" })}</span>
				<span className="resultZoneInfoVille">{newDefense.getMorts()} </span>
			</div>}
			{newDefense.getDefSD() > 0 && <div className="divZoneInfoVille">
				<span className="titreZoneInfoVille">{t("Bonus SD (+{bonusSd}%) :", { ns: "outils" }).replace("{bonusSd}", newDefense.bonusSD.toString())}</span>
				<span className="resultZoneInfoVille">{newDefense.getDefSD()} </span>
			</div>}
			<div className="divZoneInfoVille" id="derZoneInfoVille">
				<span className="titreZoneInfoVille"><span>{t("Total", { ns: "ville" })} </span> <SvgIcone icone={"h_guard"} /> : </span>
				<span className="resultZoneInfoVille">{newDefense.getTotalDef() +
					newDefense.getDefSD()} ({newDefense.getTotalDef()} + {newDefense.getDefSD()})</span>
			</div>
		</div>
		<div id="recap_pa"></div>
	</div>;
}

export function isChantierPlannedOrBuilt(listChantiersConstruit: ChantiersDTO[], chantiersProgrammes: ProgrammeChantierDTO[],
	chantierAVerifier: number): boolean {
	// Vérifiez d'abord si le chantier est construit
	const isBuilt = Object.values(listChantiersConstruit).some(chantier => chantier.chantier.id === chantierAVerifier);
	
	if (isBuilt) {
		return true; // Le chantier est construit.
	} else {
		// Si le chantier n'est pas construit, vérifiez s'il est programmé pour être fini
		return chantiersProgrammes.some(
			chantierProg => chantierProg.chantier?.id === chantierAVerifier && (chantierProg.finir === true || chantierProg.pa_a_laisser === 0));
	}
}
import React, { useCallback }                                   from "react";
import { useTranslation }                                       from "react-i18next";
import { useDispatch, useSelector }                             from "react-redux";
import TooltipGH                                                from "../../../components/utils/TooltipGH";
import SvgIcone                                                 from "../../../components/generality/SvgIcone";
import { updateBuilding, updateHard, updateInfoCase, updateKm } from "../../../store/outils/campingSlice";
import { RootState }                                            from "../../../store/store";

const CampingControls = () => {
		  const { t } = useTranslation();
		  const dispatch = useDispatch();
		  const infoCase = useSelector((state: RootState) => state.camping.infoCase);
		  const listBat = useSelector((state: RootState) => state.camping.infoCase?.listBat);
		  
		  const handleBatimentChange = useCallback((value: string) => {
			  const batId = parseInt(value, 10);
			  if (batId === -2) {
				  // Cas "Aucun"
				  dispatch(updateBuilding({
					  bat  : null,
					  nbTas: 0,
				  }));
			  } else {
				  const bat = listBat.find(b => b.id === batId);
				  if (bat) {
					  dispatch(updateBuilding({
						  bat,
						  nbTas: infoCase.nbTas,
					  }));
				  }
			  }
		  }, [dispatch, listBat, infoCase.nbTas]);
		  
		  const handleNbTasChange = useCallback((value: number) => {
			  if (infoCase.bat?.id === -1) {
				  dispatch(updateBuilding({
					  bat  : infoCase.bat,
					  nbTas: value,
				  }));
			  } else {
				  dispatch(updateInfoCase({ nbTas: value }));
			  }
		  }, [dispatch, infoCase.bat]);
		  
		  const distance = Array.from({ length: 31 }, (_, index) => index);
		  
		  return (
			  <div id={`form_camp_batiment_${infoCase.mode}`}>
				  {/* Sélection du km*/}
				  {infoCase.mode === "anonyme" && <div key={"km"} id={"camping_km_select"} className={"d-flex justify-content-start align-content-center align-items-center gap-2"}>
					  <label>{t("Distance de la ville:", { ns: "ville" })}</label>
					  <select
						  value={infoCase.km}
						  onChange={(event) => dispatch(updateKm(parseInt(event.target.value, 10)))}
					  >
						  {distance.map((km) => (
							  <option key={km} value={km}>{km}</option>
						  ))}
					  </select>
				  </div>}
				  {/* Sélection du bâtiment */}
				  <div id="camping_batiment_select" className="d-flex justify-content-start align-content-center align-items-center gap-2">
					  <label>{t("Bâtiment", { ns: "ville" })}</label>
					  <select
						  value={infoCase.bat?.id ?? -2}
						  onChange={(e) => handleBatimentChange(e.target.value)}
					  >
						  <option value={-2}>{t("Aucun", { ns: "ville" })}</option>
						  {[...listBat]?.sort((a, b) => t(a.nom, { ns: "bats" }).localeCompare(t(b.nom, { ns: "bats" })))?.map((bat) => (
							  <option key={bat.id} value={bat.id}>
								  {t(bat.nom, { ns: "bats" })} ({bat.bonus_camping}%)
							  </option>
						  ))}
					  </select>
					  {/* Nombre de tas (si applicable) */}
					  {infoCase.bat?.id === -1 && (
						  <div className="d-flex justify-content-start align-content-center align-items-center gap-2">
							  <label>{t("Nombre de tas :", { ns: "ville" })}</label>
							  <input
								  type="number"
								  value={infoCase.nbTas}
								  onChange={(e) => handleNbTasChange(parseInt(e.target.value, 10))}
								  min={0}
							  />
						  </div>
					  )}
				  </div>
				  
				  {/* Nombre maximum de campeurs */}
				  <div id="camp_max_input" className="d-flex justify-content-start align-content-center align-items-center gap-2">
					  <label>{t("Nombre de campeur maximum", { ns: "ville" })}</label>
					  <input
						  type="number"
						  value={infoCase.nbrCampMax}
						  onChange={(e) => dispatch(updateInfoCase({
							  nbrCampMax: parseInt(e.target.value, 10),
						  }))}
						  min={0}
					  />
				  </div>
				  
				  {/* Phare */}
				  <div id="camping_phare_select" className="d-flex justify-content-start align-content-center align-items-center gap-2">
					  <label>{t("Phare construit", { ns: "ville" })}</label>
					  <select
						  value={infoCase.phare ? 1 : 0}
						  onChange={(e) => dispatch(updateInfoCase({
							  phare: e.target.value === "1",
						  }))}
					  >
						  <option value={0}>{t("Non", { ns: "app" })}</option>
						  <option value={1}>{t("Oui", { ns: "app" })}</option>
					  </select>
				  </div>
				  
				  {/* Nuit */}
				  <div id="camping_nuit_select" className="d-flex justify-content-start align-content-center align-items-center gap-2">
					  <label>{t("Nuit", { ns: "ville" })}</label>
					  <select
						  value={infoCase.nuit ? 1 : 0}
						  onChange={(e) => dispatch(updateInfoCase({
							  nuit: e.target.value === "1",
						  }))}
					  >
						  <option value={0}>{t("Non", { ns: "app" })}</option>
						  <option value={1}>{t("Oui", { ns: "app" })}</option>
					  </select>
				  </div>
				  
				  {/* Pandémonium */}
				  {infoCase.mode === "anonyme" && <div id="camping_hard_select" className="d-flex justify-content-start align-content-center align-items-center gap-2">
					  <label>{t("Pandemonium", { ns: "ville" })}</label>
					  <select
						  value={infoCase.hard ? 1 : 0}
						  onChange={(e) => dispatch(updateHard(e.target.value === "1"))}
					  >
						  <option value={0}>{t("Non", { ns: "app" })}</option>
						  <option value={1}>{t("Oui", { ns: "app" })}</option>
					  </select>
				  </div>}
				  
				  {/* Zombie */}
				  <div id="camping_zombie_select" className="d-flex justify-content-start align-content-center align-items-center gap-2">
					  <label>{t("Zombie", { ns: "ville" })}</label>
					  <input
						  type="number"
						  value={infoCase.zombie}
						  onChange={(e) => dispatch(updateInfoCase({
							  zombie: parseInt(e.target.value, 10),
						  }))}
						  min={0}
					  />
				  </div>
				  
				  {/* Dévasté */}
				  <div id="camping_devast_select" className="d-flex justify-content-start align-content-center align-items-center gap-2">
					  <label>{t("Dévasté", { ns: "ville" })}</label>
					  <select
						  value={infoCase.devast ? 1 : 0}
						  onChange={(e) => dispatch(updateInfoCase({
							  devast: e.target.value === "1",
						  }))}
					  >
						  <option value={0}>{t("Non", { ns: "app" })}</option>
						  <option value={1}>{t("Oui", { ns: "app" })}</option>
					  </select>
				  </div>
				  
				  {/* Améliorations et OD */}
				  <div id="camping_amelio_od_select" className="d-flex justify-content-start align-content-center align-items-center gap-2">
					  <label className="d-flex gap-1 align-items-center">
						  <span>{t("Nombre d'amélioration + OD", { ns: "ville" })}</span>
						  <TooltipGH>
 							  <span className="infoBulle">
 								  <i className="fa-solid fa-circle-info" />
 							  </span>
							  <span className="info">{t("Comptez 1,8 pour un OD, et 1 pour une amelioration, maximum 11,6", { ns: "ville" })}</span>
						  </TooltipGH>
					  </label>
					  <span className="d-flex gap-1 align-items-center">
						  <input type="number" value={infoCase.nbrAmelioOD} onChange={(e) => {
							  const value = parseFloat(e.target.value);
							  if (!isNaN(value)) {
								  dispatch(updateInfoCase({
									  nbrAmelioOD: Math.min(value, 11.6),
								  }));
							  }
						  }} min={0} max={11.6} step={0.1} />
						  <TooltipGH>
							  <span className="infoBulle">
								  <SvgIcone icone="h_warning" />
							  </span>
							  <span className="info">{t("Décomptez 3 améliorations par jour", { ns: "ville" })}</span></TooltipGH>
					  </span>
				  </div>
			  </div>
		  );
	  }
;

export default CampingControls;
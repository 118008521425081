import React, { useCallback }       from "react";
import { OutilsChantiersTypeProps } from "../../../../types/components/Outils/OutilsChantiers.type";
import Select, { components }       from "react-select";
import { useOutilsChantiers }       from "../TabsOutilsChantiers";
import SvgIcone                     from "../../../../components/generality/SvgIcone";
import { v4 as uuid }               from "uuid";
import { UpHomePrototypeDTO }       from "../../../../types/models/upHomePrototype.dto";
import { CitoyensDTO }              from "../../../../types/models/citoyens.dto";
import { useTranslation }           from "react-i18next";

const { SingleValue, Option, GroupHeading } = components;

const customStylesAmelioration = {
	option           : (provided, state) => ({
		...provided,
		display        : "flex",
		flexDirection  : "row",
		alignItems     : "center",
		backgroundColor: state.isDisabled ? "lightgrey" : "white",
		color          : state.isDisabled ? "grey" : "black",
		":active"      : {
			...provided[":active"],
			backgroundColor: !state.isDisabled && (state.isSelected ? "data.color" : "white"),
		},
	}),
	singleValue      : (provided) => ({
		...provided,
		display        : "flex",
		flexDirection  : "row",
		alignItems     : "center",
		backgroundColor: "white",
		color          : "black",
	}),
	control          : (provided) => ({
		...provided,
		minHeight: "24px",
		height   : "24px",
		width    : "155px",
	}),
	input            : (provided) => ({
		...provided,
		gridTemplateColumns: "none",
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		padding: "0",
	}),
	valueContainer   : (provided) => ({
		...provided,
		padding: "0px 8px",
	}),
	menu             : (provided) => ({
		...provided,
		backgroundColor: "white",
		color          : "black",
		width          : "200px",
	}),
	menuList         : (provided) => ({
		...provided,
		backgroundColor: "white",
		color          : "black",
	}),
	menuPortal       : (provided) => ({
		...provided,
		backgroundColor: "white",
		color          : "black",
	}),
};


const customStylesCitoyen = {
	option           : (provided, state) => ({
		...provided,
		display        : "flex",
		flexDirection  : "row",
		alignItems     : "center",
		backgroundColor: state.isDisabled ? "lightgrey" : "white",
		color          : state.isDisabled ? "grey" : "black",
		":active"      : {
			...provided[":active"],
			backgroundColor: !state.isDisabled && (state.isSelected ? "data.color" : "white"),
		},
	}),
	singleValue      : (provided) => ({
		...provided,
		display        : "flex",
		flexDirection  : "row",
		alignItems     : "center",
		backgroundColor: "white",
		color          : "black",
	}),
	control          : (provided) => ({
		...provided,
		minHeight: "24px",
		height   : "24px",
		width    : "155px",
	}),
	input            : (provided) => ({
		...provided,
		gridTemplateColumns: "none",
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		padding: "0",
	}),
	valueContainer   : (provided) => ({
		...provided,
		padding: "0px 8px",
	}),
	menu             : (provided) => ({
		...provided,
		backgroundColor: "white",
		color          : "black",
		width          : "200px",
	}),
	menuList         : (provided) => ({
		...provided,
		backgroundColor: "white",
		color          : "black",
	}),
	menuPortal       : (provided) => ({
		...provided,
		backgroundColor: "white",
		color          : "black",
	}),
};

export default function UpAmelioration(props: { outils: OutilsChantiersTypeProps }) {
	const { t } = useTranslation();
	const { outilsChantiers, setOutilsChantiers } = useOutilsChantiers();
	const outils: OutilsChantiersTypeProps = props.outils;
	
	const listCitoyens = Object.values(outils.listCitoyens).filter((citoyen: CitoyensDTO) => !citoyen.mort)
		.sort((a: CitoyensDTO, b: CitoyensDTO) => a.citoyen.pseudo.localeCompare(b.citoyen.pseudo));
	
	const listAmelios = Object.values(outils.option.list_ame);
	
	// Définissez les composants personnalisés ici en utilisant `useCallback` si nécessaire.
	const CustomOptionCitoyen = useCallback((props) => (
		<components.Option {...props}>
			<SvgIcone icone={"h_" + props.data.job.icon} />
			<span className={(props.data.ban) ? "color-red" : null}>{props.data.citoyen.pseudo}</span>
		</components.Option>
	), []);
	
	const CustomSingleValueCitoyen = useCallback((props) => (
		<components.SingleValue {...props}>
			<SvgIcone icone={"h_" + props.data.job.icon} />
			<span className={(props.data.ban) ? "color-red" : null}>{props.data.citoyen.pseudo}</span>
		</components.SingleValue>
	), []);
	
	
	// Définissez les composants personnalisés ici en utilisant `useCallback` si nécessaire.
	const CustomOptionAmelioration = useCallback((props) => (
		<components.Option {...props}>
			<SvgIcone icone={props.data.icon} />
			<span>{t(props.data.label, { ns: "game" })}</span>
		</components.Option>
	), []);
	
	const CustomSingleValueAmelioration = useCallback((props) => (
		<components.SingleValue {...props}>
			<SvgIcone icone={props.data.icon} />
			<span>{t(props.data.label, { ns: "game" })}</span>
		</components.SingleValue>
	), []);
	
	
	// Fonction pour ajouter un nouveau up Habitation
	const ajouterAmelioration = () => {
		
		// Vous devez définir la structure de l'objet chantier par défaut qui sera ajouté
		const nouvelAmelioration = {
			uuid   : uuid(),
			citoyen: null,
			amelio : null,
			lvl    : 0,
		};
		
		// Mettre à jour l'état avec le nouveau chantier
		setOutilsChantiers({
			...outilsChantiers,
			up_amelios: [...outilsChantiers.up_amelios, nouvelAmelioration],
		});
	};
	
	// Fonction pour trouver la valeur actuelle du Select basée sur id joueur
	const findCitoyenValue = (citoyenId) => {
		const foundOption = listCitoyens.find(citoyen => citoyen.citoyen.id === citoyenId);
		if (foundOption) {
			return foundOption;
		}
		return null;
	};
	
	const handleUpdateUpAmelioChange = (uuid, updatedField, updatedValue) => {
		const updatedAmeliorations = outilsChantiers.up_amelios.map(amelioration => {
			if (amelioration.uuid === uuid) {
				return {
					...amelioration,
					[updatedField]: updatedValue,
				};
			}
			return amelioration;
		});
		setOutilsChantiers({ ...outilsChantiers, up_amelios: updatedAmeliorations });
	};
	
	// Fonction pour trouver la valeur actuelle du Select basée sur le level de l'habitation
	const findAmeliorationValue = (ame_id) => {
		const foundOption = listAmelios.find(amelioration => amelioration.id === ame_id);
		if (foundOption) {
			return foundOption;
		}
		return null;
	};
	
	const deleteUpAmelioration = (uuid: string) => {
		const upAmeliorations = outilsChantiers.up_amelios.filter(amelio => amelio.uuid !== uuid);
		setOutilsChantiers({ ...outilsChantiers, up_amelios: upAmeliorations });
	};
	
	return <div id={"bloc_up_amelerioration"}>
		<table className={"tab_upAmelioration"}>
			<thead>
			<tr className={"fondBlack02"}>
				<th className={"pseudo"}>{t("Citoyen", { ns: "outils" })}</th>
				<th className={"choix"}>{t("Amelioration", { ns: "outils" })}</th>
				<th className={"lvl"}>{t("Lvl", { ns: "outils" })}</th>
				<th className={"action"}></th>
			</tr>
			</thead>
			<tbody>
			{Object.values(outilsChantiers.up_amelios).filter((amelio) => {
				if (amelio.citoyen === null || amelio.amelio === null || amelio.lvl === 0) {
					return true;
				}
				const citoyen = findCitoyenValue(amelio.citoyen.id);
				
				switch (amelio.amelio.nom) {
					case "cs":
						return citoyen?.lvl_coin_sieste !== amelio.lvl;
					case "cuisine":
						return citoyen?.lvl_cuisine !== amelio.lvl;
					case "labo":
						return citoyen?.lvl_labo !== amelio.lvl;
					case "renfort":
						return citoyen?.lvl_renfort !== amelio.lvl;
					case "rangement":
						return citoyen?.lvl_rangement !== amelio.lvl;
					case "cloture":
						return citoyen?.cloture;
					case "barricade":
						return citoyen?.nb_barricade !== amelio.lvl;
					case "carton":
						return citoyen?.nb_carton !== amelio.lvl;
				}
				return true;
			}).map((amelioration) => {
				return <tr key={amelioration.uuid}>
					<td className={"nom"}>
						<Select
							onChange={(selectedOption: CitoyensDTO) => handleUpdateUpAmelioChange(amelioration.uuid, "citoyen", selectedOption)}
							options={listCitoyens}
							components={{
								Option     : CustomOptionCitoyen,
								SingleValue: CustomSingleValueCitoyen,
							}}
							styles={customStylesCitoyen}
							value={findCitoyenValue(amelioration.citoyen?.id)}
						/>
					</td>
					<td className={"choix"}>
						<Select
							onChange={(selectedOption: UpHomePrototypeDTO) => handleUpdateUpAmelioChange(amelioration.uuid, "amelio", selectedOption)}
							options={listAmelios.filter((ameliorationProto) => {
								if (!amelioration.citoyen) {
									return true;
								}
								const citoyen = findCitoyenValue(amelioration.citoyen.id);
								switch (ameliorationProto.nom) {
									case "cs":
										return citoyen?.lvl_coin_sieste !== ameliorationProto.levels.length;
									case "cuisine":
										return citoyen?.lvl_cuisine !== ameliorationProto.levels.length;
									case "labo":
										return citoyen?.lvl_labo !== ameliorationProto.levels.length;
									case "renfort":
										return citoyen?.lvl_renfort !== ameliorationProto.levels.length;
									case "rangement":
										return citoyen?.lvl_rangement !== ameliorationProto.levels.length;
									case "cloture":
										return !citoyen?.cloture;
									case "barricade":
									case "carton":
										return true;
								}
								return true;
							})}
							components={{
								Option     : CustomOptionAmelioration,
								SingleValue: CustomSingleValueAmelioration,
							}}
							styles={customStylesAmelioration}
							value={findAmeliorationValue(amelioration?.amelio?.id)}
							isDisabled={!amelioration.citoyen}
						/>
					</td>
					<td className={"lvl"}>
						{amelioration.citoyen && amelioration.amelio && <>

						</>}
					</td>
					<td className={"action"}>
						<button onClick={() => deleteUpAmelioration(amelioration.uuid)}><i className="fa-solid fa-trash color-red"></i></button>
					</td>
				</tr>;
			})}
			</tbody>
		</table>
		<button type={"button"} className={"btn btn-primary btn-sm"} onClick={ajouterAmelioration}>{t("Ajouter une amélioration", { ns: "outils" })}</button>
	</div>;
}


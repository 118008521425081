import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JobPrototypeDTO }            from "../../types/models/jobPrototype.dto";
import { CitoyensDTO }                from "../../types/models/citoyens.dto";

interface MenuCarteState {
	compteurJobs: number[];
	selectedCitoyens: number[];
	selectedJobs: number[];
}

const initialState: MenuCarteState = {
	compteurJobs    : [],
	selectedCitoyens: [],
	selectedJobs    : [],
};

const menuCarteSlice = createSlice({
	name    : "menuCarte",
	initialState,
	reducers: {
		majSelectedCitoyens   : (state, action: PayloadAction<number[]>) => {
			state.selectedCitoyens = action.payload;
		},
		getCompteurJobs       : (state, action: PayloadAction<{ jobs: JobPrototypeDTO[], citoyens: CitoyensDTO[] }>) => {
			state.compteurJobs = action.payload.jobs.map(job => {
				return action.payload.citoyens.filter(citoyen => citoyen.job.id === job.id).length;
			});
		},
		selectedAllCitoyens   : (state, action: PayloadAction<{ citoyens: CitoyensDTO[], jobs: JobPrototypeDTO[] }>) => {
			state.selectedCitoyens = action.payload.citoyens.map(citoyen => citoyen.citoyen.id);
			state.selectedJobs = action.payload.jobs.map(job => job.id);
		},
		deselectedAllCitoyens : (state) => {
			state.selectedCitoyens = [];
			state.selectedJobs = [];
		},
		invertSelectedCitoyens: (state, action: PayloadAction<{ citoyens: CitoyensDTO[], jobs: JobPrototypeDTO[] }>) => {
			// On duplique ce qui est déjà sélectionné
			const selectedCitoyensDupl = state.selectedCitoyens;
			state.selectedCitoyens = action.payload.citoyens.map(citoyen => citoyen.citoyen.id).filter(id => !selectedCitoyensDupl.includes(id));
			const selectedJobsDupl = state.selectedJobs;
			state.selectedJobs = action.payload.jobs.map(job => job.id).filter(id => !selectedJobsDupl.includes(id));
		},
		selectCitoyen         : (state, action: PayloadAction<{ citoyenId: number }>) => {
			// On verifie si le citoyen est déjà sélectionné, si oui on le retire
			const citoyenIndex = state.selectedCitoyens.indexOf(action.payload.citoyenId);
			if (citoyenIndex !== -1) {
				state.selectedCitoyens.splice(citoyenIndex, 1);
			} else {
				state.selectedCitoyens.push(action.payload.citoyenId);
			}
		},
		selectCitoyenJob      : (state, action: PayloadAction<{ citoyens: CitoyensDTO[], jobId: number }>) => {
			const jobId = action.payload.jobId;
			const citoyensJob = action.payload.citoyens.filter((citoyen: CitoyensDTO) => citoyen.job.id === jobId).map(citoyen => citoyen.citoyen.id);
			// On verifie que le job est pas déjà sélectionné, si c'est le cas, on procède à la déselection des citoyens avec ce job
			const jobIndex = state.selectedJobs.indexOf(action.payload.jobId);
			if (jobIndex !== -1) {
				state.selectedJobs.splice(jobIndex, 1);
				state.selectedCitoyens = state.selectedCitoyens.filter(citoyenId => !citoyensJob.includes(citoyenId));
			} else {
				state.selectedJobs.push(action.payload.jobId);
				state.selectedCitoyens = state.selectedCitoyens.concat(citoyensJob);
			}
		},
	},
});

export const { majSelectedCitoyens, getCompteurJobs, selectedAllCitoyens, deselectedAllCitoyens, invertSelectedCitoyens, selectCitoyen, selectCitoyenJob } = menuCarteSlice.actions;
export default menuCarteSlice.reducer;

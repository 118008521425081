import React, { useEffect, useState } from "react";
import TriangleSort                   from "../../../components/generality/ComposantGeneral/TriangleSort";
import { ItemPrototypeDTO }           from "../../../types/models/itemPrototype.dto";
import SvgIcone                       from "../../../components/generality/SvgIcone";
import { EncyclopedieObjetType }      from "../../../types/components/Encyclopedie/EncyclopedieObjet.type";
import { useTranslation }             from "react-i18next";
import { useNormalizedSearch }        from "../../../services/hook/useNormalizedSearch";
import TooltipGH                      from "../../../components/utils/TooltipGH";
import TranslationsDisplay            from "../../../components/utils/TranslationsDisplay";

export default function ListVeilles({ objets, search_objet, listObjetsPrototype, isTrad, lng }: { objets: EncyclopedieObjetType, search_objet: string, listObjetsPrototype: ItemPrototypeDTO[], isTrad: boolean, lng: string }) {
	const { t } = useTranslation();
	const [listVeilles, setListVeilles] = useState(objets.listVeilles);
	const [sortedColumnV, setSortedColumnV] = useState("");
	const [sortOrderV, setSortOrderV] = useState("");
	const { normalizeSearch } = useNormalizedSearch();
	
	useEffect(() => {
		setListVeilles(objets.listVeilles);
	}, [objets]);
	
	const filterLists = (listObjetsPrototype: ItemPrototypeDTO[]) => {
		const filteredVeilles = Object.values(objets.listVeilles).filter(
			(veilles) => normalizeSearch(search_objet.trim(), t(listObjetsPrototype[veilles.id].nom, { ns: "items" })),
		);
		setListVeilles(filteredVeilles);
	};
	
	
	useEffect(() => {
		filterLists(listObjetsPrototype);
	}, [search_objet]);
	
	const getSortIndicatorClassVeilles = (column: string) => {
		if (column === sortedColumnV) {
			return sortOrderV === "asc" ? "asc" : "desc";
		} else {
			return "default"; // Retourne une chaîne vide si la colonne n'est pas triée
		}
	};
	const handleSortDataVeilles = (column: string) => {
		// Si la colonne cliquée est la même que la colonne triée précédemment,
		// on inverse l'ordre de tri.
		const order = column === sortedColumnV && sortOrderV === "asc" ? "desc" : (sortOrderV === "desc" ? "default" : "asc");
		
		if (order === "default") {
			column = "";
		}
		
		// On met à jour l'état avec la colonne actuellement triée et l'ordre de tri.
		setSortedColumnV(column);
		setSortOrderV(order);
	};
	const sortedListsVeilles = (listObjetsPrototype: ItemPrototypeDTO[]) => {
		const sortFunctionsVeille = {
			"def"       : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => a.def_base - b.def_base,
			"armurerie" : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => Number(a.armurerie) - Number(b.armurerie),
			"magasin"   : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => Number(a.magasin) - Number(b.magasin),
			"tourelle"  : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => Number(a.tourelle) - Number(b.tourelle),
			"bete"      : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => Number(a.lance_bete) - Number(b.lance_bete),
			"encombrant": (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => Number(a.encombrant) - Number(b.encombrant),
			"reparable" : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => Number(a.reparable) - Number(b.reparable),
			"unique"    : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => Number(a.usage_unique) - Number(b.usage_unique),
			"default"   : (a: ItemPrototypeDTO, b: ItemPrototypeDTO) => t(listObjetsPrototype[a.id]?.nom ?? "", { ns: "items" }).localeCompare(t(listObjetsPrototype[b.id]?.nom ?? "", { ns: "items" })),
		};
		const sortFunction = sortFunctionsVeille[sortedColumnV] || sortFunctionsVeille["default"];
		return Object.values(listVeilles).sort((a, b) => {
			const result = sortFunction(a, b);
			return sortOrderV === "asc" ? -result : result;
		});
	};
	const veillesTri: ItemPrototypeDTO[] = sortedListsVeilles(objets.listObjets);
	
	
	return (
		<div id="ency_veilles">
			<table>
				<thead>
				<tr>
					<th className="tab_ency_items_icone" rowSpan={2}>{t("Image", { ns: "ency" })}</th>
					<th className="tab_ency_veilles_nom" rowSpan={2}>{t("Nom", { ns: "ency" })}</th>
					<th className="tab_ency_veilles_def" rowSpan={2}>
						<div className={"entete_tri"} onClick={() => handleSortDataVeilles("def")}>
							<div id={"entete_ency_veilles_def"}>{t("Défense de base", { ns: "ency" })}</div>
							<TriangleSort direction={getSortIndicatorClassVeilles("def")} />
						</div>
					</th>
					<th colSpan={4}>{t("Affecté par", { ns: "ency" })}</th>
					<th colSpan={3}>{t("Est", { ns: "ency" })}</th>
				</tr>
				<tr>
					<th className="tab_ency_veilles_armurerie">
						<div className={"entete_tri"} onClick={() => handleSortDataVeilles("armurerie")}>
							<div id={"entete_ency_veilles_armurerie"}>
								<span>{t("Meuleuse", { ns: "ency" })}</span>
								<TooltipGH>
                                    <span className={"infoBulle"}>
                                        <i className={"fa fa-circle-info"}></i>
                                    </span>
									<span className={"info"}>+20%</span>
								</TooltipGH>
							</div>
							<TriangleSort direction={getSortIndicatorClassVeilles("armurerie")} />
						</div>
					</th>
					<th className="tab_ency_veilles_magasin">
						<div className={"entete_tri"} onClick={() => handleSortDataVeilles("magasin")}>
							<div id={"entete_ency_veilles_magasin"}>
								<span>{t("Magasin", { ns: "ency" })}</span>
								<TooltipGH>
                                    <span className={"infoBulle"}>
                                        <i className={"fa fa-circle-info"}></i>
                                    </span>
									<span className={"info"}>+30%</span>
								</TooltipGH>
							</div>
							<TriangleSort direction={getSortIndicatorClassVeilles("magasin")} />
						</div>
					</th>
					<th className="tab_ency_veilles_tourelle">
						<div className={"entete_tri"} onClick={() => handleSortDataVeilles("tourelle")}>
							<div id={"entete_ency_veilles_tourelle"}>
								<span>{t("Gouttières", { ns: "ency" })}</span>
								<TooltipGH>
                                    <span className={"infoBulle"}>
                                        <i className={"fa fa-circle-info"}></i>
                                    </span>
									<span className={"info"}>+40%</span>
								</TooltipGH>
							</div>
							<TriangleSort direction={getSortIndicatorClassVeilles("tourelle")} />
						</div>
					</th>
					<th className="tab_ency_veilles_bete">
						<div className={"entete_tri"} onClick={() => handleSortDataVeilles("bete")}>
							<div id={"entete_ency_veilles_bete"}>
								<span>{t("Animalerie", { ns: "ency" })}</span>
								<TooltipGH>
                                    <span className={"infoBulle"}>
                                        <i className={"fa fa-circle-info"}></i>
                                    </span>
									<span className={"info"}>+20%</span>
								</TooltipGH>
							</div>
							<TriangleSort direction={getSortIndicatorClassVeilles("bete")} />
						</div>
					</th>
					<th className="tab_ency_veilles_encombrant">
						<div className={"entete_tri"} onClick={() => handleSortDataVeilles("encombrant")}>
							<div id={"entete_ency_veilles_encombrant"}>{t("encombrant", { ns: "ency" })}</div>
							<TriangleSort direction={getSortIndicatorClassVeilles("encombrant")} />
						</div>
					</th>
					<th className="tab_ency_veilles_reparable">
						<div className={"entete_tri"} onClick={() => handleSortDataVeilles("reparable")}>
							<div id={"entete_ency_veilles_reparable"}>{t("reparable", { ns: "ency" })}</div>
							<TriangleSort direction={getSortIndicatorClassVeilles("reparable")} />
						</div>
					</th>
					<th className="tab_ency_veilles_unique">
						<div className={"entete_tri"} onClick={() => handleSortDataVeilles("unique")}>
							<div id={"entete_ency_veilles_unique"}>{t("à usage unique", { ns: "ency" })}</div>
							<TriangleSort direction={getSortIndicatorClassVeilles("unique")} />
						</div>
					</th>
				</tr>
				</thead>
				<tbody>
				{veillesTri.map((item) => {
					
					return <tr className={"ligne_ency_veilles"} key={"veilles_" + item.id}>
						<td className={"tab_ency_items_icone"}>
							<div><SvgIcone icone={listObjetsPrototype[item.id]?.icon ?? ""} /></div>
						</td>
						<td className="tab_ency_veilles_nom">{isTrad ? <TranslationsDisplay translationKey={listObjetsPrototype[item.id]?.nom ?? ""} namespace={"items"} userLang={lng} /> : t(listObjetsPrototype[item.id]?.nom ?? "", { ns: "items" })}</td>
						<td className="tab_ency_veilles_def">{item.def_base}</td>
						<td className="tab_ency_veilles_armurerie">{item.armurerie ? <i className="fas fa-check color-check"></i> : ""}</td>
						<td className="tab_ency_veilles_magasin">{item.magasin ? <i className="fas fa-check color-check"></i> : ""}</td>
						<td className="tab_ency_veilles_tourelle">{item.tourelle ? <i className="fas fa-check color-check"></i> : ""}</td>
						<td className="tab_ency_veilles_bete">{item.lance_bete ? <i className="fas fa-check color-check"></i> : ""}</td>
						<td className="tab_ency_veilles_encombrant">{item.encombrant ? <i className="fas fa-check color-check"></i> : ""}</td>
						<td className="tab_ency_veilles_reparable">{item.reparable ? <i className="fas fa-check color-check"></i> : ""}</td>
						<td className="tab_ency_veilles_unique">{item.usage_unique ? <i className="fas fa-check color-check"></i> : ""}</td>
					</tr>;
				})}
				</tbody>
			</table>
		</div>
	);
}





import React, { useEffect, useState } from "react";
import { CompteursCitoyens }          from "../../../types/components/Hotel/Citoyens.type";
import SvgIcone                       from "../../../components/generality/SvgIcone";
import { CitoyensDTO }                from "../../../types/models/citoyens.dto";
import { useTranslation }             from "react-i18next";

interface CompteursProps {
	citoyens: CitoyensDTO[],
	compteurs: CompteursCitoyens,
	updateCpt: boolean,
	onAfterUpdate: (update: boolean) => void
}

const CompteurCitoyens = (props: CompteursProps) => {
	const { t } = useTranslation();
	const [compteurs, setCompteurs] = useState<CompteursCitoyens>(props.compteurs);
	
	useEffect(() => {
		calculerCompteurs(); // Appel de la méthode pour le calcul initial
	}, []);
	
	useEffect(() => {
		if (props.updateCpt) {
			props.onAfterUpdate(true);
			calculerCompteurs(); // Appel de la méthode de calcul lorsque le tableau de citoyens est modifié
		}
	}, [props.updateCpt]);
	
	const calculerCompteurs = () => {
		const { citoyens } = props;
		const { job, hab, pv } = compteurs;
		
		// Réinitialiser les compteurs
		const nouveauxCompteursJob = Object.values(job).map(j => ({
			...j,
			nbr: 0,
		}));
		
		// Calculer les compteurs pour chaque citoyen
		citoyens.forEach(citoyen => {
			if (!citoyen.mort) {
				const jobIndex = nouveauxCompteursJob.findIndex(j => j.id === citoyen.job.id);
				if (jobIndex !== -1) {
					nouveauxCompteursJob[jobIndex].nbr += 1;
				}
			}
		});
		
		// Réinitialiser les compteurs habitations
		const nouveauxCompteursHab = Object.values(hab).map(h => ({
			...h,
			nbr: 0,
		}));
		
		// Calculer les compteurs pour chaque citoyen
		citoyens.forEach(citoyen => {
			if (!citoyen.mort) {
				const habIndex = nouveauxCompteursHab.findIndex(h => h.id === citoyen.lvl_maison.id);
				if (habIndex !== -1) {
					nouveauxCompteursHab[habIndex].nbr += 1;
				}
			}
		});
		
		// Réinitialiser les compteurs pouvoirs
		const nouveauxCompteursPV = Object.values(pv).map(p => ({
			...p,
			nbr: 0,
		}));
		
		// Calculer les compteurs pour chaque citoyen
		citoyens.forEach(citoyen => {
			if (!citoyen.mort) {
				nouveauxCompteursPV.map((p, index) => {
					if (citoyen[p.method]) {
						nouveauxCompteursPV[index].nbr += 1;
					}
				});
			}
		});
		
		// Mettre à jour l'état avec les nouveaux compteurs
		setCompteurs({
			job: nouveauxCompteursJob,
			hab: nouveauxCompteursHab,
			pv : nouveauxCompteursPV,
		});
	};
	
	return <React.Fragment>
		<div className="titreZoneCitoyen">{t("Compteurs", { ns: "hotel" })}</div>
		<div className="fondWhite02" id="compteurCitoyen">
			<div id="compteurJob">
				<div className="zoneTitreCompteurCitoyen">
					<div className="titreCompteurCitoyen">{t("Métiers", { ns: "hotel" })}</div>
					<div className="borderBottomCitoyen"></div>
				</div>
				<div className="conteneurCompteurCitoyen">
					{Object.values(compteurs.job).map(job => {
						return <div key={"cpt_job_" + job.id}>
                            <span className={"d-flex gap-1 align-items-center justify-content-start"}>
                                <SvgIcone icone={"h_" + job.icon} />
                                <span>{t(job.nom, { ns: "game" })}</span>
                            </span>
							<span className={"numCptCitoyen"}>{job.nbr}</span>
						</div>;
					})}
				</div>
			</div>
			<div id="compteurPouvoir">
				<div className="zoneTitreCompteurCitoyen">
					<div className="titreCompteurCitoyen">{t("Pouvoirs Héros", { ns: "hotel" })}</div>
					<div className="borderBottomCitoyen"></div>
				</div>
				<div className="conteneurCompteurCitoyen">
					{Object.values(compteurs.pv).map(pv => {
						return <div key={"cpt_pouv_" + pv.id}>
							<span>{t(pv.nom, { ns: "game" })}</span>
							<span className={"numCptCitoyen"}>{pv.nbr}</span>
						</div>;
					})}
				</div>
			</div>
			<div id="compteurMaison">
				<div className="zoneTitreCompteurCitoyen">
					<div className="titreCompteurCitoyen">{t("Habitations", { ns: "hotel" })}</div>
					<div className="borderBottomCitoyen"></div>
				</div>
				<div className="conteneurCompteurCitoyen" id="finConteneurCompteur">
					{Object.values(compteurs.hab).map(hab => {
						return <div key={"cpt_hab_" + hab.id}>
                            <span className={"d-flex gap-1 align-items-center justify-content-start"}>
                                <SvgIcone icone={"home_" + hab.icon} />
                                <span>{hab.nom}</span>
                            </span>
							<span className={"numCptCitoyen"}>{hab.nbr}</span>
						</div>;
					})}
				</div>
			</div>
		</div>
	</React.Fragment>;
	
};

export default CompteurCitoyens;
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useGeneralContext }                             from "../../../types/Context/GeneralContext";
import { useTooltipPosition }                            from "../../../services/hook/useTooltipPosition";

interface TooltipCaseCarteProps {
	children: [ReactNode, ReactNode];
	idTs: string;
	typeCarte: string;
	handleMouseClick: (idTs: string) => void;
	handleClosePop: () => void;
	currentId: string;
	onClicked: boolean;
	setCurrentId: (id: string) => void;
	isCalculPosition: boolean;
	tooltipWidth?: number;
	spacingTop?: number;
	spacingLeft?: number;
	arrowSize?: number;
}

const TooltipCaseCarte = ({
	children,
	idTs,
	typeCarte,
	isCalculPosition,
	handleMouseClick,
	handleClosePop,
	setCurrentId,
	currentId,
	onClicked,
	tooltipWidth = 550,
	spacingTop = 10,
	spacingLeft = -15,
	arrowSize = 7,
}: TooltipCaseCarteProps) => {
	const { general } = useGeneralContext();
	const [visible, setVisible] = useState(false);
	const tooltipRef = useRef<HTMLDivElement>(null);
	const triggerElementRef = useRef<HTMLElement | null>(null);
	
	const {
			  containerPosition,
			  arrowPosition,
			  placement,
			  updatePosition,
			  updatePositionAfterRender,
			  visiblePosition,
		  } = useTooltipPosition(tooltipRef, {
		width: tooltipWidth,
		spacingTop,
		spacingLeft,
		arrowSize,
	});
	
	useEffect(() => {
		if (currentId !== null && currentId === idTs) {
			if (triggerElementRef.current) {
				updatePosition(triggerElementRef.current);
				updatePositionAfterRender(triggerElementRef.current);
				setVisible(true);
			}
		} else if (currentId === null || currentId !== idTs) {
			setVisible(false);
		}
	}, [currentId, idTs, updatePosition, updatePositionAfterRender]);
	
	const handleMouseOverOrMove = (event: React.MouseEvent<HTMLTableDataCellElement>) => {
		if (onClicked) {
			triggerElementRef.current = event.currentTarget;
			setVisible(true);
			setCurrentId(idTs);
		}
	};
	const handleMouseOut = (event: React.MouseEvent<HTMLTableDataCellElement>) => {
		if (onClicked) {
			triggerElementRef.current = event.currentTarget;
			setVisible(false);
			setCurrentId(null);
		}
	};
	
	const handleMouseClickToolTip = (event: React.MouseEvent<HTMLTableDataCellElement>) => {
		if (isCalculPosition) {
			if (currentId !== null && currentId === idTs && !onClicked) {
				setVisible(false);
				handleClosePop();
				return;
			} else {
				setVisible(true);
				triggerElementRef.current = event.currentTarget;
				setCurrentId(idTs);
			}
		}
		handleMouseClick(idTs);
	};
	
	const handleTooltipClick = (event: React.MouseEvent) => {
		event.stopPropagation(); // Empêche la propagation du clic vers le td parent
	};
	
	const [triggerElement, tooltipContent] = children;
	
	return (
		<>
			<td
				id={idTs}
				className={`caseCarte ${typeCarte}`}
				onMouseOver={handleMouseOverOrMove}
				onMouseMove={handleMouseOverOrMove}
				onMouseOut={handleMouseOut}
				onClick={handleMouseClickToolTip}
			>
				{triggerElement}
				{visible && <div
					className="tooltip-container-carte"
					ref={tooltipRef}
					onClick={handleTooltipClick}
					style={{
						position    : "absolute",
						visibility  : visiblePosition ? "visible" : "hidden",
						top         : containerPosition.top,
						left        : containerPosition.left,
						width       : tooltipWidth,
						zIndex      : 600,
						borderRadius: "4px",
						boxShadow   : "0 2px 4px rgba(0,0,0,0.1)",
						border      : `1px solid ${general.themeUser.primary_border_color}`,
					}}
				>
					{tooltipContent}
					<div
						className="tooltip-arrow"
						style={{
							position                                            : "absolute",
							visibility                                          : visiblePosition ? "visible" : "hidden",
							[placement.vertical === "bottom" ? "top" : "bottom"]: "-7px",
							width                                               : 0,
							height                                              : 0,
							borderLeft                                          : "7px solid transparent",
							borderRight                                         : "7px solid transparent",
							borderBottom                                        : placement.vertical === "bottom" ? `7px solid ${general.themeUser.primary_border_color}` : "none",
							borderTop                                           : placement.vertical === "top" ? `7px solid ${general.themeUser.primary_border_color}` : "none",
							pointerEvents                                       : "none",
							...arrowPosition,
						}}
					/>
				</div>}
			</td>
		</>
	);
};

export default TooltipCaseCarte;
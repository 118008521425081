import React         from "react";
import { CaseRuine } from "../../../types/components/ville/Ruines.type";
import SvgRuine      from "../../../components/generality/SvgRuine";
import SvgIcone      from "../../../components/generality/SvgIcone";


export default function RuineTraceMap({ plan, z_aff, z, x, y, trad_porte, handleClick = null, showAriane = false, filAriane = [], selObjet = [] }: {
	plan: CaseRuine[][][], z_aff: number, z: number, x: number, y: number, trad_porte: string[], showAriane?: boolean, filAriane?: number[][][][],
	handleClick?: (xPos: number, yPos: number, zPos: number) => void
	selObjet?: number[]
}) {
	const numbers = Array.from({ length: 15 }, (_, index) => index);
	
	return <table>
		<tbody>
		<tr>
			<td className="bordCarteRuine"></td>
			{numbers.map((x_tab) => (
				<td className="bordCarteRuine" key={x_tab}>{x_tab - 7}</td>
			))}
			<td className="bordCarteRuine"></td>
		</tr>
		{Object.values(plan[z_aff]).map((ligne, y_tab) => (
			<tr key={`${z_aff}-${y_tab}`}>
				<td className="bordCarteRuine">{y_tab}</td>
				{ligne.map((caseRuine, x_tab) => (
					<td className={"caseCarteRuine"} key={`${y_tab}-${x_tab}-${z_aff}`}>
						{!caseRuine.correction && <div className={"caseCarteRuineD" + ((caseRuine.type_case <= 17 && caseRuine.type_case > 0) ? " caseRuineClique" : "")} onClick={handleClick ? () => handleClick(x_tab, y_tab, z_aff) : undefined}>
							<SvgRuine icone={"ruineCarte_" + caseRuine.type_case} classRuine={"ruineCarte"} />
							{caseRuine.type_case > 0 && caseRuine.nbr_zombie !== null &&
								<div className={`ruineCarte_zomb ruineCarte_z_${caseRuine.nbr_zombie}`}></div>}
							{caseRuine.type_porte !== null && <div className={"ruineCarte_porte"}><SvgIcone icone={trad_porte[caseRuine.type_porte]} /></div>}
							{caseRuine.type_escalier !== null && <div className={"ruineCarte_escalier"}><SvgRuine icone={caseRuine.type_escalier} classRuine={"ruineCarte " + ((caseRuine.type_escalier === "up") ? "ruine_escalier_up" : "ruine_escalier_down")} /></div>}
							{x_tab === x && y_tab === y && z_aff === z && <div className="maPositionRuine"></div>}
							{showAriane && <div className={"caseFilAriane"}>
								{filAriane[z_aff][y_tab][x_tab].map((fil) => (<SvgRuine icone={"ariane_" + fil} classRuine={"filAriane"} key={"case_ariane_" + fil} />))}
							</div>}
							{selObjet !== undefined && caseRuine.items !== undefined && caseRuine.items.length > 0 && <div className={"ruineCarte_items" +
								(caseRuine.items.some(item => selObjet.includes(item.item.id * 10 + ((item.broken) ? 1 : 0))) ? " objetRuineSelected" :
									"")}></div>}
						</div>}
						{caseRuine.correction && <div className={"caseCarteRuineD caseRuineClique " + (caseRuine.correction ? " caseCarteRuineEffacer" : "")} onClick={handleClick ? () => handleClick(x_tab, y_tab, z_aff) : undefined}></div>}
					</td>
				))}
				<td className="bordCarteRuine">{y_tab}</td>
			</tr>
		))}
		<tr>
			<td className="bordCarteRuine"></td>
			{numbers.map((x_tab) => (
				<td className="bordCarteRuine" key={x_tab}>{x_tab - 7}</td>
			))}
			<td className="bordCarteRuine"></td>
		</tr>
		</tbody>
	</table>;
	
}
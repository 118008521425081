import { Fetch }                     from "../../components/generality/Fetch";
import { InscriptionJumpPropsType }  from "../../types/components/Jump/InscriptionJump.type";
import { InscriptionEventPropsType } from "../../types/components/Jump/InscriptionEvent.type";
import { JumpListType }              from "../../types/components/Jump/JumpList.type";
import { ListeJumpEvent }            from "../../types/components/Jump/ListJumpEvent.type";
import { GeneralType }               from "../../types/components/Generality/General.type";
import { InscriptionJumpDTO }        from "../../types/models/inscriptionJump.dto";
import { FetchGH }                   from "../../components/generality/FetchGH";

export class InscriptionJumpApi {
	
	private fetch: Fetch;
	private fetchGH: FetchGH;
	
	constructor(mapId?: number) {
		this.fetch = new Fetch("inscription", mapId ?? 0);
		this.fetchGH = new FetchGH("inscription", mapId ?? 0);
	}
	
	public masque_event(data: { userId: number, idEvent: string, sens: boolean }): Promise<RetourMasquageInscription> {
		return this.fetch.from("/majMasquageEvent").request().post(data);
	}
	
	public masque_jump(data: { userId: number, idJump: string, sens: boolean }): Promise<RetourMasquageInscription> {
		return this.fetch.from("/majMasquageJump").request().post(data);
	}
	
	public majMetierInscription(data: { userId: number, idJump: string, idJob: number, userIdMaj: number }): Promise<RetourMajMetier> {
		return this.fetch.from("/majMetier").request().post(data);
	}
	
	public majStatutInscription(data: { userId: number, idJump: string, idStatut: number }): Promise<RetourMajStatut> {
		return this.fetch.from("/majStatut").request().post(data);
	}
	
	public inscription_jump(data: { userId: number, jumpId: string, inscription: InscriptionJumpDTO }): Promise<RetourInscription> {
		return this.fetch.from("/inscription_jump").request().post(data);
	}
	
	public modif_inscription_jump(data: { userId: number, jumpId: string, inscription: InscriptionJumpDTO }): Promise<RetourInscription> {
		return this.fetch.from("/mod_inscription_jump").request().post(data);
	}
	
	public desist_inscription_jump(data: { userId: number, jumpId: string, inscription: InscriptionJumpDTO }): Promise<RetourInscription> {
		return this.fetch.from("/desist_inscription_jump").request().post(data);
	}
	
	public reinscription_jump(data: { userId: number, jumpId: string, inscription: InscriptionJumpDTO }): Promise<RetourInscription> {
		return this.fetch.from("/reinscription_jump").request().post(data);
	}
	
	public inscription({ idJump, isArchMode = false }: { idJump: string, isArchMode?: boolean }): Promise<AffichageInscription> {
		return this.fetchGH.get("/jump/" + idJump + "?arch=" + isArchMode);
	}
	
	public inscriptionEvent({ idEvent, idJump, isArchMode = false }: { idJump: string, idEvent: string, isArchMode?: boolean }): Promise<AffichageInscriptionEvent> {
		return this.fetchGH.get("/event/" + idEvent + "/" + idJump + "?arch=" + isArchMode);
	}
	
	public listJumpOnEvent({ idEvent, isArchMode = false }: { idEvent: string, isArchMode?: boolean }): Promise<AffichageListEvent> {
		return this.fetchGH.get("/event/" + idEvent + "?arch=" + isArchMode);
	}
	
	
	public main(): Promise<AffichageListJumpEvent> {
		return this.fetch.from("/").request().get();
	}
	
}

export interface RetourMasquageInscription {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: object,
	
}

export interface RetourMajMetier {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: object,
	
}

export interface RetourMajStatut {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: object,
	
}

export interface RetourInscription {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		inscription?: InscriptionJumpDTO,
		libErreur?: string[],
	},
	
}

export interface AffichageInscription {
	data: {
		inscription: InscriptionJumpPropsType,
		general: GeneralType,
		error?: string,
	},
	status?: number,
	message?: string,
	
}


export interface AffichageInscriptionEvent {
	data: {
		inscription: InscriptionEventPropsType,
		general: GeneralType,
		error?: string,
	},
	status?: number,
	message?: string,
}

export interface AffichageListEvent {
	data: {
		event: JumpListType,
		general: GeneralType,
		error?: string,
	},
	status?: number,
	message?: string,
}

export interface AffichageListJumpEvent {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		listJumpEvent: ListeJumpEvent,
		general: GeneralType,
	},
	
}

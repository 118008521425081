import React                 from "react";
import { ListGestionType }   from "../../../types/components/Jump/ListGestion.type";
import HTMLParser            from "html-react-parser";
import { formatInTimeZone }  from "date-fns-tz";
import { Link }              from "react-router";
import { useGeneralContext } from "../../../types/Context/GeneralContext";
import { JumpDTO }           from "../../../types/models/jump.dto";
import { EventDTO }          from "../../../types/models/event.dto";
import { useTranslation }    from "react-i18next";
import Tabs                  from "react-bootstrap/Tabs";
import Tab                   from "react-bootstrap/Tab";

export default function GestionList({ list }: { list: ListGestionType }) {
	const { t } = useTranslation();
	const { general } = useGeneralContext();
	const [activeTab, setActiveTab] = React.useState("actif");
	const listJump = list.listJump;
	const listEvent = list.listEvent;
	const listJumpArch = list.listJumpArch;
	const listEventArch = list.listEventArch;
	
	const handleTabChange = (key: string) => {
		setActiveTab(key);
	};
	
	return <div className="jumpCorps">
		<Tabs
			activeKey={activeTab}
			onSelect={handleTabChange}
		>
			<Tab eventKey="actif" title={t("Jump/Event actif", { ns: "jumpEvent" })}>
				<div id="active_jump">
					<div className={"container-tabs"}>
						<table>
							<thead>
							<tr>
								<th className="th_nomJump">{HTMLParser(t("Nom du jump", { ns: "jumpEvent" }))}</th>
								<th className="td_dateJump">{HTMLParser(t("Date de début <br/> inscription", { ns: "jumpEvent" }))}</th>
								<th className="td_dateJump">{HTMLParser(t("Date de fin <br/> inscription", { ns: "jumpEvent" }))}</th>
								<th className="td_dateJump">{HTMLParser(t("Date de jump <br/> approximative", { ns: "jumpEvent" }))}</th>
								<th className="td_lienInscipJump">{HTMLParser(t("Lien vers <br/> la fiche", { ns: "jumpEvent" }))}</th>
							</tr>
							</thead>
							<tbody>
							{Object.values(listJump).sort((a: JumpDTO, b: JumpDTO) => (new Date(a.date_approx_jump) < new Date(b.date_approx_jump) ? -1 : 1)).map((jump) => {
								return <tr key={"jump_" + jump.id}>
									<td className="td_nomJump">{jump.nom}</td>
									<td className="td_dateJump">{formatInTimeZone(new Date(jump.date_deb_inscription), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
									<td className="td_dateJump">{formatInTimeZone(new Date(jump.date_fin_inscription), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
									<td className="td_dateJump">{formatInTimeZone(new Date(jump.date_approx_jump), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
									<td className="td_lienInscipJump">
										<Link to={"/jump/gestion/" + jump.id} style={{ textDecoration: "none", color: "inherit" }}>
											<button type={"button"}>{t("Gérer le jump", { ns: "jumpEvent" })}</button>
										</Link>
									</td>
								</tr>;
							})}
							</tbody>
						</table>
						<br />
						<table>
							<thead>
							<tr>
								<th className="th_nomJump">{t("Nom de l'event", { ns: "jumpEvent" })}</th>
								<th className="td_dateJump">{HTMLParser(t("Date de début <br/> inscription", { ns: "jumpEvent" }))}</th>
								<th className="td_dateJump">{HTMLParser(t("Date de fin <br/> inscription", { ns: "jumpEvent" }))}</th>
								<th className="td_dateJump">{HTMLParser(t("Date début <br/> Event", { ns: "jumpEvent" }))}</th>
								<th className="td_lienInscipJump">{HTMLParser(t("Lien vers <br/> la fiche", { ns: "jumpEvent" }))}</th>
							</tr>
							</thead>
							<tbody>
							{Object.values(listEvent).sort((a: EventDTO, b: EventDTO) => (new Date(a.event_begin_at) < new Date(b.event_begin_at)) ? -1 : 1).map((eventJump) => {
								return <tr key={"event_" + eventJump.id}>
									<td className="td_nomJump">{eventJump.nom}</td>
									<td className="td_dateJump">{formatInTimeZone(new Date(eventJump.deb_inscription_date_at), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
									<td className="td_dateJump">{formatInTimeZone(new Date(eventJump.fin_inscription_date_at), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
									<td className="td_dateJump">{formatInTimeZone(new Date(eventJump.event_begin_at), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
									<td className="td_lienInscipJump">
										<Link to={"/jump/gestion/event/" + eventJump.id} style={{ textDecoration: "none", color: "inherit" }}>
											<button type={"button"}>{t("Gérer l'Event", { ns: "jumpEvent" })}</button>
										</Link>
									</td>
								</tr>;
							})}
							</tbody>
						</table>
					</div>
				</div>
			</Tab>
			<Tab eventKey="archive" title={t("Archives", { ns: "jumpEvent" })}>
				<div id="archive_jump">
					<div className={"container-tabs"}>
						<table>
							<thead>
							<tr>
								<th className="th_nomJump">{HTMLParser(t("Nom du jump", { ns: "jumpEvent" }))}</th>
								<th className="td_dateJump">{HTMLParser(t("Date de début <br/> inscription", { ns: "jumpEvent" }))}</th>
								<th className="td_dateJump">{HTMLParser(t("Date de fin <br/> inscription", { ns: "jumpEvent" }))}</th>
								<th className="td_dateJump">{HTMLParser(t("Date de jump <br/> approximative", { ns: "jumpEvent" }))}</th>
								<th className="td_lienInscipJump">{HTMLParser(t("Lien vers <br/> la fiche", { ns: "jumpEvent" }))}</th>
							</tr>
							</thead>
							<tbody>
							{Object.values(listJumpArch).sort((a: JumpDTO, b: JumpDTO) => (new Date(a.date_approx_jump) < new Date(b.date_approx_jump) ? -1 : 1)).map((jump) => {
								return <tr key={"jump_" + jump.id}>
									<td className="td_nomJump">{jump.nom}</td>
									<td className="td_dateJump">{formatInTimeZone(new Date(jump.date_deb_inscription), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
									<td className="td_dateJump">{formatInTimeZone(new Date(jump.date_fin_inscription), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
									<td className="td_dateJump">{formatInTimeZone(new Date(jump.date_approx_jump), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
									<td className="td_lienInscipJump">
										<Link to={"/jump/gestion/archive/" + jump.id} style={{ textDecoration: "none", color: "inherit" }}>
											<button type={"button"}>{t("Gérer le jump", { ns: "jumpEvent" })}</button>
										</Link>
									</td>
								</tr>;
							})}
							</tbody>
						</table>
						<br />
						<table>
							<thead>
							<tr>
								<th className="th_nomJump">{t("Nom de l'event", { ns: "jumpEvent" })}</th>
								<th className="td_dateJump">{HTMLParser(t("Date de début <br/> inscription", { ns: "jumpEvent" }))}</th>
								<th className="td_dateJump">{HTMLParser(t("Date de fin <br/> inscription", { ns: "jumpEvent" }))}</th>
								<th className="td_dateJump">{HTMLParser(t("Date début <br/> Event", { ns: "jumpEvent" }))}</th>
								<th className="td_lienInscipJump">{HTMLParser(t("Lien vers <br/> la fiche", { ns: "jumpEvent" }))}</th>
							</tr>
							</thead>
							<tbody>
							{Object.values(listEventArch).sort((a: EventDTO, b: EventDTO) => (new Date(a.event_begin_at) < new Date(b.event_begin_at)) ? -1 : 1).map((eventJump) => {
								return <tr key={"event_" + eventJump.id}>
									<td className="td_nomJump">{eventJump.nom}</td>
									<td className="td_dateJump">{formatInTimeZone(new Date(eventJump.deb_inscription_date_at), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
									<td className="td_dateJump">{formatInTimeZone(new Date(eventJump.fin_inscription_date_at), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
									<td className="td_dateJump">{formatInTimeZone(new Date(eventJump.event_begin_at), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
									<td className="td_lienInscipJump">
										<Link to={"/jump/gestion/event/archive/" + eventJump.id} style={{ textDecoration: "none", color: "inherit" }}>
											<button type={"button"}>{t("Gérer l'Event", { ns: "jumpEvent" })}</button>
										</Link>
									</td>
								</tr>;
							})}
							</tbody>
						</table>
					</div>
				</div>
			</Tab>
		</Tabs>
	
	</div>;
}

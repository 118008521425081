import React, { useEffect, useState }             from "react";
import { GestionJumpType }                        from "../../../types/components/Jump/GestionJump.type";
import { GestionJumpApi }                         from "../../../services/api/GestionJumpApi";
import Modal                                      from "react-bootstrap/Modal";
import Button                                     from "react-bootstrap/Button";
import ConfirmationDialog                         from "../../../components/generality/ComposantGeneral/ConfirmationDialog";
import FormJump                                   from "../FormJump";
import SvgIcone                                   from "../../../components/generality/SvgIcone";
import GestionCandidature                         from "./GestionCandidature";
import { formatInTimeZone }                       from "date-fns-tz";
import { useNavigate }                            from "react-router";
import { useGeneralContext }                      from "../../../types/Context/GeneralContext";
import { Status_error, Status_success, usePopUp } from "../../../types/Context/PopUpContext";
import { UserDTO }                                from "../../../types/models/user.dto";
import { LeadJumpDTO }                            from "../../../types/models/leadJump.dto";
import { TypeLeadDTO }                            from "../../../types/models/typeLead.dto";
import { LogEventInscriptionDTO }                 from "../../../types/models/logEventInscription.dto";
import { InscriptionJumpDTO }                     from "../../../types/models/inscriptionJump.dto";
import { useTranslation }                         from "react-i18next";
import { LeadInscriptionDTO }                     from "../../../types/models/leadInscription.dto";

export default function GestionJump({ gestionJump, isArchMode = false }: { gestionJump: GestionJumpType, isArchMode?: boolean }) {
	const { t } = useTranslation();
	const { general } = useGeneralContext();
	const naviagate = useNavigate();
	const options = gestionJump.options;
	const gestionApi = new GestionJumpApi();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	
	
	const [jump, setJump] = useState(gestionJump.jump);
	const [listOrga, setListOrga] = useState(Object.values(jump.gestionnaires));
	const [listLead, setListLead] = useState(Object.values(jump.lead));
	
	useEffect(() => {
		setJump(gestionJump.jump);
	}, [gestionJump]);
	
	
	const [messagePopUpListErreur, setMessagePopUpListErreur] = useState(null);
	const [showModListErreur, setShowModListErreur] = useState(false);
	const [confirmShow, setConfirmShow] = useState(false);
	
	const extractionInscription = (): UserDTO[] => {
		const inscriptionUnique: UserDTO[] = [];
		Object.values(jump.inscription_jumps).map((inscription: InscriptionJumpDTO) => {
			if (inscription.statut.id === options.statutAcc) {
				inscriptionUnique[inscription.user.id] = inscription.user;
			}
		});
		return inscriptionUnique.filter((user) => user !== undefined);
	};
	
	const verification_inscription = (gestionnaire: UserDTO): boolean => {
		const listInscription = extractionInscription();
		
		return listInscription.map((user) => user.id).includes(gestionnaire.id);
	};
	
	const gestionnaire_inscrit = verification_inscription(gestionJump.user);
	
	const listInscrit = extractionInscription();
	
	const listSouhaitLead = (leadtype: TypeLeadDTO, apprenti: boolean) => {
		const inscriptionUnique: InscriptionJumpDTO[] = [];
		Object.values(jump.inscription_jumps).map((inscription: InscriptionJumpDTO) => {
			if (inscription.statut.id === options.statutAcc) {
				inscriptionUnique[inscription.user.id] = inscription;
			}
		});
		
		// Filtrer les utilisateurs en fonction du vœu de lead et/ou apprenti
		return inscriptionUnique.filter((inscription: InscriptionJumpDTO) => {
			// Vérifier si l'utilisateur a fait le vœu d'être un lead et/ou apprenti lead
			return inscription.lead_inscriptions.some((lead: LeadInscriptionDTO) => {
				return lead.lead.id === leadtype.id && lead.apprenti === apprenti;
			});
		});
		
	};
	
	const listLeadJump = (leadtype: TypeLeadDTO, apprenti: boolean) => {
		// Filtrer les utilisateurs en fonction du vœu de lead et/ou apprenti
		return listLead.filter((lead: LeadJumpDTO) => {
			// Vérifier si l'utilisateur a fait le vœu d'être un lead et/ou apprenti lead
			return lead.type_lead.id === leadtype.id && lead.apprenti === apprenti;
		});
	};
	
	const onRetour = (isErrorGestion: boolean, showModGestion: boolean, messagePopGestion: string) => {
		setStatus(isErrorGestion ? Status_error : Status_success);
		setShowPop(showModGestion);
		setMessagePopUp(messagePopGestion);
		if (!isErrorGestion) {
			setTimeout(() => {
				setShowPop(false);
				setMessagePopUp("");
			}, 1500);
		}
	};
	
	const handleClose = () => {
		setMessagePopUpListErreur(null);
		setShowModListErreur(false);
	};
	
	const onChangeOrgaJump = (e, index: number) => {
		const listInscription = extractionInscription();
		const orga = [...listOrga];
		
		orga[index] = listInscription.find((inscription) => inscription.id === parseInt(e.target.value, 10));
		setListOrga(orga);
	};
	
	const onChangeLeadJump = (e, userId: number, typeLead: TypeLeadDTO, apprenti: boolean) => {
		const selectedUserId = parseInt(e.target.value, 10);
		
		// On clone la partie des leads qu'on souhaite modifier
		const updatedLeads = [...listLead];
		
		// Recherche de l'index de l'élément à modifier
		const index = updatedLeads.findIndex(
			(leadJoueur) => leadJoueur.user.id === userId && typeLead.id === leadJoueur.type_lead.id && apprenti === leadJoueur.apprenti);
		
		if (index !== -1) {
			// Mettez à jour les propriétés du lead correspondant
			updatedLeads[index].user = listSouhaitLead(typeLead, apprenti).find((lead) => lead.user.id === selectedUserId).user;
			
			// Mettez à jour l'état avec les leads mis à jour
			setListLead(updatedLeads);
		}
		
	};
	
	const onSuppOrgaJump = (id: number) => {
		const orgas = Object.values(listOrga).filter(orga => orga.id !== id);
		setListOrga(orgas);
	};
	
	const onSuppLeadJump = (userId: number, typeLead: TypeLeadDTO, apprenti: boolean) => {
		// On clone la partie des leads qu'on souhaite modifier
		const updatedLeads = [...listLead];
		
		// Recherche de l'index de l'élément à supprimer
		const index = updatedLeads.findIndex((leadJoueur) => {
			return leadJoueur.user.id === userId && typeLead.id === leadJoueur.type_lead.id && apprenti === leadJoueur.apprenti;
		});
		
		if (index !== -1) {
			// Supprimez l'élément correspondant de la liste
			updatedLeads.splice(index, 1);
			
			// Mettez à jour l'état avec les leads mis à jour (sans l'élément supprimé)
			setListLead(updatedLeads);
		}
	};
	
	const handleCreationOrga = () => {
		const newOrga: UserDTO = {
			id    : 0,
			pseudo: "",
		};
		
		setListOrga(prevEvent => (
			[...prevEvent, newOrga]
		));
		
	};
	
	const handleCreationLead = (leadType: TypeLeadDTO, apprenti: boolean) => {
		const newLead: LeadJumpDTO = {
			id       : 0,
			user     : { id: 0, pseudo: "" },
			type_lead: leadType,
			apprenti : apprenti,
		};
		
		setListLead(prevEvent => (
			[...prevEvent, newLead]
		));
		
	};
	
	const handleSaveJump = () => {
		gestionApi.modification_jump({ jump: jump }).then((response) => {
			if (response.codeRetour === 0) {
				onRetour(false, true, response.libRetour);
				setTimeout(() => {
					onRetour(false, false, "");
				}, 2000);
				setJump(response.zoneRetour.jump);
			} else if (response.codeRetour === 2) {
				const retourErreur = <>
					<span>{response.libRetour}</span>
					<ul>{Object.values(response.zoneRetour.libErreur).map((erreur, index) => {
						return <li key={"erreur_" + index}>{erreur}</li>;
					})}</ul>
				</>;
				setShowModListErreur(true);
				setMessagePopUpListErreur(retourErreur);
			} else {
				onRetour(true, true, response.libRetour);
			}
		});
	};
	
	const handleSuppJump = () => {
		gestionApi.suppression_jump(jump.id).then((response) => {
			if (response.codeRetour === 0) {
				onRetour(false, true, response.libRetour);
				setTimeout(() => {
					onRetour(false, false, "");
					naviagate("/jump/gestion");
				}, 2000);
			} else {
				onRetour(true, true, response.libRetour);
			}
		});
	};
	
	const handleAssociateJump = () => {
		gestionApi.associate({ idJump: jump.id, userId: gestionJump.user.id }).then((response) => {
			if (response.codeRetour === 0) {
				onRetour(false, true, response.libRetour);
				setTimeout(() => {
					onRetour(false, false, "");
				}, 2000);
				setJump(response.zoneRetour.jump);
			} else {
				onRetour(true, true, response.libRetour);
			}
		});
	};
	
	useEffect(() => {
		setJump(jump => ({ ...jump, gestionnaires: listOrga }));
	}, [listOrga]);
	useEffect(() => {
		setJump(jump => ({ ...jump, lead: listLead }));
	}, [listLead]);
	
	return <div className="jumpCorps">
		<div id="formGestion" className="fondWhite02">
			<h3>{t("Gestion du jump", { ns: "jumpEvent" })}</h3>
			<fieldset>
				<legend>{t("Info principal", { ns: "jumpEvent" })}</legend>
				<FormJump formJump={{ jump: jump, options: options, user: gestionJump.user }}
						  onRetour={onRetour} onChange={setJump}
						  isArchMode={isArchMode}
				/>
			</fieldset>
			<fieldset>
				<legend>{listOrga.length > 1 ? t("Organisateurs du jump", { ns: "jumpEvent" }) : t("Organisateur du jump", { ns: "jumpEvent" })}</legend>
				<div id="form_orgas">
					{!isArchMode && jump.event === null && listOrga.length === 1 && Object.values(listOrga).map((orga) => (
						<div key={"event_orga_" + orga.id} className={"ligneGestionJump"}>
							<span>{t("Joueur :", { ns: "jumpEvent" })}</span>
							<span>{orga.pseudo}</span>
						</div>
					))}
					{!isArchMode && (jump.event !== null || listOrga.length > 1 && listInscrit.length > 1) && Object.values(listOrga).map((orga, index) => (
						<div key={"event_orga_" + orga.id} className={"ligneGestionJump"}>
							<label>{t("Joueur :", { ns: "jumpEvent" })}</label>
							<select value={orga.id} onChange={(event) => onChangeOrgaJump(event, index)}>
								<option value={0}>{t("Choix joueur.", { ns: "jumpEvent" })}</option>
								{listInscrit.map((user) => {
									return <option value={user.id} key={"orga_" + user.id}>{user.pseudo}</option>;
								})}
							</select>
							<button type={"button"} className={"color-red"} onClick={() => onSuppOrgaJump(orga.id)}><i className="fa-solid fa-trash"></i></button>
						</div>
					))}
					{isArchMode && Object.values(listOrga).map((orga, index) => (
						<div key={"event_orga_" + orga.id} className={"ligneGestionJump"}>
							<label>{t("Joueur :", { ns: "jumpEvent" })}</label>
							<span>{orga.pseudo}</span>
						</div>
					))}
				</div>
				<div>
					{isArchMode ? null :
						(gestionnaire_inscrit ? (<button type="button" className="add_jump_link btn btn-warning btn-sm" onClick={handleCreationOrga}
														 disabled={!gestionnaire_inscrit || listInscrit.length === 1}>{t("Ajouter un organisateur", { ns: "jumpEvent" })}</button>) :
							(<span className={"color-red"}>{t("Impossible d'ajouter d'autres organisateurs tant que vous ne serez pas inscrit à votre jump.", { ns: "jumpEvent" })}</span>))
					}
				</div>
			</fieldset>
			<fieldset id="gestion_leadJump">
				<legend>{t("Lead", { ns: "jumpEvent" })}</legend>
				{Object.values(options.listLead).map((lead) => {
					return <fieldset key={"lead_" + lead.id} className="leadGestionJump">
						<legend className={"d-flex gap-1 align-items-center"}><SvgIcone icone={lead.icon} /> <span>{lead.nom}</span></legend>
						<div id={"form_lead_" + lead.id} key={"form_lead_" + lead.id}>
							{listLeadJump(lead, false).map((leadUser) => {
								return <div key={"lead_" + lead.id + "_" + leadUser.id}>
									{isArchMode ? <span>{leadUser.user.pseudo}</span> : (<>
										<select value={leadUser.user.id} onChange={(event) => onChangeLeadJump(event, leadUser.user.id, lead, false)}>
											<option value={0}>{t("Choix joueur.", { ns: "jumpEvent" })}</option>
											{listSouhaitLead(lead, false).map((inscription) => {
												return <option value={inscription.user.id}
															   key={"lead_" + lead.id + "_" + inscription.user.id}>{inscription.user.pseudo}</option>;
											})}
										</select>
										<button type={"button"} className={"color-red"} onClick={() => onSuppLeadJump(leadUser.user.id, lead, false)}>
											<i className="fa-solid fa-trash"></i>
										</button>
									</>)}
								</div>;
							})}
						</div>
						{!isArchMode && <div>
							<button type="button" className={"add_lead_link"} onClick={() => handleCreationLead(lead, false)}>
								<><i className="fas fa-plus-square color-green"></i> {t("lead {lead}", { ns: "jumpEvent" }).replace("{lead}", t(lead.nom, { ns: "jump" }))}</>
							</button>
						</div>}
					</fieldset>;
				})}
			</fieldset>
			<fieldset id="gestion_apprentiLeadJump">
				<legend>{t("Apprenti Lead", { ns: "jumpEvent" })}</legend>
				{Object.values(options.listLead).map((lead) => {
					return <fieldset key={"lead_apprenti_" + lead.id} className="leadGestionJump">
						<legend className={"d-flex gap-1 align-items-center"}><SvgIcone icone={lead.icon} /> <span>{lead.nom}</span></legend>
						<div id={"form_lead_" + lead.id} key={"form_lead_" + lead.id}>
							{listLeadJump(lead, true).map((leadUser) => {
								return <div key={"app_lead_" + lead.id + "_" + leadUser.id}>
									{isArchMode ? <span>{leadUser.user.pseudo}</span> : (<>
										<select value={leadUser.user.id} onChange={(event) => onChangeLeadJump(event, leadUser.user.id, lead, true)}>
											<option value={0}>{t("Choix joueur.", { ns: "jumpEvent" })}</option>
											{listSouhaitLead(lead, true).map((inscription) => {
												return <option value={inscription.user.id}
															   key={"app_lead_" + lead.id + "_" + inscription.user.id}>{inscription.user.pseudo}</option>;
											})}
										</select>
										<button type={"button"} className={"color-red"} onClick={() => onSuppLeadJump(leadUser.user.id, lead, true)}>
											<i className="fa-solid fa-trash"></i>
										</button>
									</>)}
								</div>;
							})}
						</div>
						{!isArchMode && <div>
							<button type="button" className={"add_lead_link"} onClick={() => handleCreationLead(lead, true)}>
								<><i className="fas fa-plus-square color-green"></i> {t("apprenti {lead}", { ns: "jumpEvent" }).replace("{lead}", t(lead.nom, { ns: "jump" }))}</>
							</button>
						</div>}
					</fieldset>;
				})}
			</fieldset>
			{!isArchMode && <div className="doubleFormButton">
				<button type={"button"} className={"btn btn-primary"} onClick={handleSaveJump}>{t("Sauvegarder jump", { ns: "jumpEvent" })}</button>
				<button type={"button"} className={"btn btn-danger"} onClick={() => setConfirmShow(true)}>{t("Supprimer jump", { ns: "jumpEvent" })}</button>
			</div>}
			<fieldset>
				<legend>{t("Ville en cours", { ns: "jumpEvent" })}</legend>
				{jump.ville === null ? (<>
					{!isArchMode && <div className="warning">{t("Pensez à associer la ville après jump afin de bénéficier de tous les avantages (contact dans la page citoyen, continuer de modifier après la mort pour les leads...).", { ns: "jumpEvent" })}</div>}
					{!isArchMode && <div className="warning">{t("Attention toute association est definitive.", { ns: "jumpEvent" })}</div>}
					{!isArchMode && <div className="simpleFormButton">
						<button className={"btn btn-warning"} onClick={handleAssociateJump}>{t("Associer ma ville actuelle à ce jump", { ns: "jumpEvent" })}</button>
					</div>}
					{isArchMode && <div className="warning">{t("Le jump n'a pas été relié avec une ville.", { ns: "jumpEvent" })}</div>}
				</>) : (<>
					<div><strong>{t("Nom de la ville :", { ns: "jumpEvent" })}</strong>{jump.ville.nom}</div>
					<div><strong>{t("Jour :", { ns: "jumpEvent" })}</strong>{jump.ville.jour}</div>
				</>)}
			</fieldset>
		
		</div>
		<GestionCandidature jump={jump} options={options} onRetourPop={onRetour} isArchMode={isArchMode} />
		<div id="listLog" className="fondWhite02">
			<h3>{t("Historique des modifications", { ns: "jumpEvent" })}</h3>
			<table>
				<thead>
				<tr>
					<th className="td_log_date">{t("Date", { ns: "jumpEvent" })}</th>
					<th className="td_log_user">{t("Qui ?", { ns: "jumpEvent" })}</th>
					<th className="td_log_libelle">{t("Evénement", { ns: "jumpEvent" })}</th>
					<th className="td_log_valeur_av">{t("Valeur avant", { ns: "jumpEvent" })}</th>
					<th className="td_log_valeur_ap">{t("Valeur après", { ns: "jumpEvent" })}</th>
				</tr>
				</thead>
				<tbody>
				{Object.values(jump.log_event).sort((a: LogEventInscriptionDTO, b: LogEventInscriptionDTO) => a.id > b.id ? -1 : 1).map((log: LogEventInscriptionDTO) => {
					return <tr key={"jump_id_" + log.id}>
						<td className="td_log_date">{formatInTimeZone(new Date(log.event_at), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
						<td className="td_log_user">{log.declencheur.pseudo}</td>
						<td className="td_log_libelle">{t(log.libelle, { ns: "jumpEvent" })}</td>
						<td className="td_log_valeur_av">{log.valeur_avant}</td>
						<td className="td_log_valeur_ap">{log.valeur_apres}</td>
					</tr>;
				})}
				</tbody>
			</table>
		</div>
		<Modal show={showModListErreur} onHide={handleClose} centered={true}>
			<Modal.Header className={"modal-header-error"}>
				<Modal.Title>{t("Erreur !", { ns: "app" })}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{messagePopUpListErreur}</Modal.Body>
			{<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>{t("Fermer", { ns: "app" })}</Button>
			</Modal.Footer>}
		</Modal>
		<ConfirmationDialog
			message={t("Voulez-vous réellement supprimer le jump ? Attention, toute suppression est définitive, cela supprimera également toutes les candidatures.", { ns: "jumpEvent" })}
			titre={t("Confimation", { ns: "jumpEvent" })}
			btnAnnuler={t("Annuler", { ns: "jumpEvent" })}
			btnConfirmation={t("Oui", { ns: "jumpEvent" })}
			show={confirmShow}
			onConfirm={() => {
				handleSuppJump();
				setConfirmShow(false);
			}}
			onCancel={() => setConfirmShow(false)}
		/>
	</div>
		;
}

import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { createPortal }                                       from "react-dom";
import { useGeneralContext }                                  from "../../types/Context/GeneralContext";

interface Position {
	x: number;
	y: number;
}

const TooltipGH = ({ children }: { children: ReactNode[] }) => {
	const { general } = useGeneralContext();
	const [visible, setVisible] = useState(false);
	const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
	const [tooltipDimensions, setTooltipDimensions] = useState({ width: 0, height: 0 });
	const ARROW_SIZE = 7;
	const TOOLTIP_MARGIN = 14;
	
	const getViewportBoundaries = useCallback(() => {
		return {
			left  : window.scrollX,
			right : window.innerWidth + window.scrollX,
			top   : window.scrollY,
			bottom: window.innerHeight + window.scrollY,
		};
	}, []);
	
	const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
		const rect = event.currentTarget.getBoundingClientRect();
		setPosition({
			x: rect.left + rect.width / 2 + window.scrollX,
			y: rect.bottom + window.scrollY,
		});
		setVisible(true);
	};
	
	const handleMouseLeave = () => {
		setVisible(false);
	};
	
	useEffect(() => {
		if (visible) {
			const tooltipElement = document.querySelector(".tooltip-container");
			if (tooltipElement) {
				setTooltipDimensions({
					width : tooltipElement.clientWidth,
					height: tooltipElement.clientHeight,
				});
			}
		}
	}, [visible]);
	
	const calculateOptimalPosition = useCallback(() => {
		const viewport = getViewportBoundaries();
		const halfWidth = tooltipDimensions.width / 2;
		let left = position.x;
		
		// Position initiale centrée sur la souris
		left -= halfWidth;
		
		// Ajustement si débordement à droite
		if (left + tooltipDimensions.width > viewport.right) {
			left = viewport.right - tooltipDimensions.width - TOOLTIP_MARGIN - 5;
		}
		
		// Ajustement si débordement à gauche
		if (left < viewport.left) {
			left = viewport.left + TOOLTIP_MARGIN;
		}
		
		return {
			left,
			// La position de la flèche est calculée par rapport à la position originale
			arrowPosition: position.x - left,
		};
	}, [position.x, tooltipDimensions.width, getViewportBoundaries]);
	
	const { left, arrowPosition } = calculateOptimalPosition();
	
	return (
		<>
			<div
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{children[0]}
			</div>
			{visible &&
				createPortal(
					<div
						className="tooltip-container"
						style={{
							position : "absolute",
							top      : position.y,
							left     : left,
							transform: `translateY(${TOOLTIP_MARGIN}px)`,
							zIndex   : 9999,
						}}
					>
						{children[1]}
						<div
							style={{
								position    : "absolute",
								top         : -ARROW_SIZE,
								left        : arrowPosition,
								transform   : "translateX(-50%)",
								width       : 0,
								height      : 0,
								borderLeft  : `${ARROW_SIZE}px solid transparent`,
								borderRight : `${ARROW_SIZE}px solid transparent`,
								borderBottom: `${ARROW_SIZE}px solid ${general.themeUser.primary_border_color}`,
							}}
						/>
					</div>,
					document.body,
				)}
		</>
	);
};

export default TooltipGH;
import React, { useEffect }     from "react";
import SvgIcone                 from "../../../components/generality/SvgIcone";
import { ChantierPrototypeDTO } from "../../../types/models/chantierPrototype.dto";
import { useTranslation }       from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState }   from "../../../store/store";
import { setPlans }                 from "../../../store/hotel/plansChantierSlice";


interface ListPlansRuineProps {
	listPlans: ChantierPrototypeDTO[],
}


const ListPlansRuine = ({ listPlans }: ListPlansRuineProps) => {
	const { t } = useTranslation();
	
	const dispatch = useDispatch<AppDispatch>();
	const listePlans = useSelector((state: RootState) => state.plansChantier.listePlans);
	
	useEffect(() => {
		dispatch(setPlans({ plans: Object.values(listPlans) }));
	}, [listPlans]);
	
	const planTypes = [
		{ key: "Commun", type: "c", plans: listePlans.plansCommun },
		{ key: "Inhab", type: "u", plans: listePlans.plansInhab },
		{ key: "Rare", type: "r", plans: listePlans.plansRare },
		{ key: "TresRare", type: "e", plans: listePlans.plansEpique },
	];
	
	const planTypesRuine = [
		{ key: "Inhab", type: "u" },
		{ key: "Rare", type: "r" },
		{ key: "TresRare", type: "e" },
	];
	
	const listRuines = [
		{ key: "bunker", nom: t("Bunker abandonné", { ns: "bats" }), icon: "b" },
		{ key: "hopital", nom: t("Hôpital abandonné", { ns: "bats" }), icon: "m" },
		{ key: "hotel", nom: t("Hôtel abandonné", { ns: "bats" }), icon: "h" },
	];
	
	return <div>
		<div>
			<fieldset>
				<legend>{t("Plans de chantier", { ns: "chantiers" })}</legend>
				{planTypes.map(({ key, type, plans }) => (
					<React.Fragment key={key}>
						<div className={`ency_plan_chantier listPlans${key}`}>
							{plans.map((colChantier: ChantierPrototypeDTO[], index) => (
								<div key={`${key.toLowerCase()}_${index}`} className="d-flex flex-column">
									{colChantier.map((chantier: ChantierPrototypeDTO) => (
										<div className={"ligne_plans_ruine d-flex gap-1 align-items-center justify-content-start"} key={"plan_ruine_lvl_" + index + "_chantier_" + chantier.id}>
											<SvgIcone icone={"item_bplan_" + type} />
											<span>{t(chantier.nom, { ns: "chantiers" })}</span>
										</div>
									))}
								</div>
							))}
						</div>
						{key !== "TresRare" && <hr className="hrBlack40" />}
					</React.Fragment>
				))}
			</fieldset>
			<fieldset>
				<legend>{t("Plans en ruine", { ns: "chantiers" })}</legend>
				<div className={"d-flex align-self-center justify-content-center"}>
					{Object.values(listRuines).map(({ key, nom, icon }) => {
						const nomRuine = key.charAt(0).toUpperCase() + key.slice(1);
						return <div key={nomRuine} id={`ruine${nomRuine}`}>
							<div className="titreRuine">{nom}</div>
							{Object.values(planTypesRuine).map((planType) => {
								return <div key={planType.key} className={`listPlans${planType.key}Ruine`}>
									{listePlans.plansRuine[key][`plans${planType.key}`].map((chantier: ChantierPrototypeDTO, index) => {
										return <div className={"ligne_plans_ruine d-flex gap-1 align-items-center justify-content-start"} key={"plan_ruine_lvl_" + index + "_chantier_" + chantier.id}>
											<SvgIcone icone={`item_${icon}bplan_${planType.type}`} />
											<span>{t(chantier.nom, { ns: "chantiers" })}</span>
										</div>;
									})}
								</div>;
							})}
						</div>;
					})}
				</div>
			</fieldset>
		</div>
	
	</div>;
};

export default ListPlansRuine;
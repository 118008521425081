import React, { useEffect, useState } from "react";
import chargement                     from "../../../img/chargement.svg";
import { GestionJumpApi }             from "../../services/api/GestionJumpApi";
import GestionList                    from "../../containers/Jump/Gestion/GestionList";
import { useNavigate }                from "react-router";
import { useGeneralContext }          from "../../types/Context/GeneralContext";
import { useGHContext }               from "../../types/Context/GHContext";
import { Status_error, usePopUp }     from "../../types/Context/PopUpContext";
import { useTranslation }             from "react-i18next";

export function GestionListGeneral() {
	const { general, setGeneral } = useGeneralContext();
	const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const navigate = useNavigate();
	const { t } = useTranslation();
	
	const [gestionData, setGestionData] = useState(null);
	
	
	// Fonction pour recharger les données
	const reloadData = async () => {
		const gestionApi = new GestionJumpApi(parseInt(sessionStorage.getItem("mapId") ?? "0", 10));
		gestionApi.main_gestion().then((response) => {
			setIsOnRefresh(false);
			setIsOnUpdate(false);
			if (response.codeRetour === 0) {
				if (response.zoneRetour.general !== undefined) {
					setGeneral(response.zoneRetour.general);
					sessionStorage.setItem("mapId", response.zoneRetour.general.ville ? response.zoneRetour.general.ville.map_id.toString() : null);
				}
				if (response.zoneRetour.list === undefined) {
					console.error("Erreur de chargement des données gestion", response);
				} else {
					setGestionData(response.zoneRetour.list);
				}
			} else if (response.codeRetour === 1) {
				setStatus(Status_error);
				setShowPop(true);
				setMessagePopUp(response.libRetour);
				navigate("/news");
			}
		});
	};
	
	useEffect(() => {
		if (checkHabilitation("jump", "gestions")) {
			reloadData().then(r => r);
		} else {
			navigate("/news");
		}
	}, [refreshKey]);
	
	return (
		<>
			{gestionData === null ? (
				<div className={"chargement_page"}>
					<img src={chargement} alt="Drapeau" />
					<span>{t("Chargement...", { ns: "app" })}</span>
				</div>
			) : (
				<GestionList list={gestionData} />
			)}
		
		</>
	);
}
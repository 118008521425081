import React                                                             from "react";
import SvgCase                                                           from "./SvgCase";
import { BordureCase }                                                   from "./Bordure";
import { TraceExpedition, TraceExpeditionHordes, TraceExpeditionManuel } from "./TraceExpedition";
import { ZoneMapDTO }                                                    from "../../../types/models/zoneMap.dto";
import { ParamCarteType }                                                from "../../../types/components/ville/ParamCarte.type";
import { ConsigneExpeditionDTO }                                         from "../../../types/models/consigneExpedition.dto";
import { VilleDTO }                                                      from "../../../types/models/ville.dto";
import { CarteType }                                                     from "../../../types/components/ville/Carte.type";
import { GeneralType }                                                   from "../../../types/components/Generality/General.type";
import colormap                                                          from "colormap";
import { UserDTO }                                                       from "../../../types/models/user.dto";
import { ExpeditionType }                                                from "../../../types/components/ville/Expedition.type";

interface CaseCarteProps {
	idTs: string;
	currentId: string;
	zone: ZoneMapDTO;
	ville: VilleDTO;
	carte: CarteType;
	typeCarte: string;
	typeVille: string;
	paramCarte: ParamCarteType;
	general: GeneralType;
	selectedCarte: { obj: any[], bat: any[], cit: any[], expe: any[] },
	user: UserDTO;
	tailleCase: number;
	modeExpe: boolean;
	maxObjet: number;
	estimZombieSupp: number;
	carteTraceManuel: Array<Array<(number | null)[]>>;
	listeExpeJour: string[];
	expeManuel: ExpeditionType;
	consigneExpes: string[];
}

const CaseCarte = ({
	idTs, zone, currentId,
	ville, carte, typeCarte, typeVille, maxObjet, estimZombieSupp,
	paramCarte, tailleCase, modeExpe, carteTraceManuel,
	selectedCarte, user, general, listeExpeJour, expeManuel, consigneExpes,
}: CaseCarteProps) => {
	const caseVille = zone.vue === -2 && zone.x === ville.pos_x && zone.y === ville.pos_y;
	
	const alpha = general.themeUser.user_perso_couleur.alpha_colormap / 100;
	const colorap_carte = colormap({ colormap: general.themeUser.user_perso_couleur.colormap, nshades: 100, format: "rgbaString", alpha: alpha });
	
	const displayBlock = "block";
	const displayNone = "none";
	const display = currentId !== null && currentId === idTs ? displayBlock : displayNone;
	
	
	let valeursCommunes = [];
	if (zone.items !== null && selectedCarte.obj !== null && selectedCarte.obj.length !== 0) {
		valeursCommunes = zone.item_sol.filter((valeur) => selectedCarte.obj.includes(valeur));
	}
	let selectionBat = false;
	if (zone.bat !== null && selectedCarte.bat !== null && selectedCarte.bat.length !== 0) {
		selectionBat = selectedCarte.bat.includes(idTs);
	}
	
	let expeSelectionneGH = [];
	if (carte.carteExpe[zone.y * 100 + zone.x] !== undefined) {
		expeSelectionneGH = Object.entries(carte.carteExpe[zone.y * 100 + zone.x]).filter(([index]) => selectedCarte.expe.includes(index) && listeExpeJour.includes(index));
	}
	let expeSelectionneBiblio = [];
	if (carte.carteExpeBiblio[zone.y * 100 + zone.x] !== undefined) {
		expeSelectionneBiblio = Object.entries(carte.carteExpeBiblio[zone.y * 100 + zone.x]).filter(([index]) => selectedCarte.expe.includes(index));
	}
	let expeSelectionneBrouillon = [];
	if (carte.carteExpeBrouillon[zone.y * 100 + zone.x] !== undefined) {
		expeSelectionneBrouillon = Object.entries(carte.carteExpeBrouillon[zone.y * 100 + zone.x]).filter(([index]) => selectedCarte.expe.includes(index));
	}
	let expeSelectionneMH = [];
	if (carte.carteExpeHordes[zone.y * 100 + zone.x] !== undefined) {
		expeSelectionneMH = Object.entries(carte.carteExpeHordes[zone.y * 100 + zone.x]).filter(([index]) => {
			return selectedCarte.expe.includes(parseInt(index, 10));
		});
	}
	
	const selectionObjet = valeursCommunes.length !== 0;
	let selectionCit = false;
	if (ville.list_citoyen_vie[zone.y * 100 + zone.x] !== undefined) {
		const arrayTmp = ville.list_citoyen_vie[zone.y * 100 + zone.x].filter((valeur) => selectedCarte.cit.includes(valeur.citoyen.id));
		selectionCit = arrayTmp.length !== 0;
	}
	
	const conditions = [selectionObjet, selectionBat, selectionCit];
	const nombreDeValeursTrue = conditions.reduce((total, condition) => {
		if (condition) {
			return total + 1;
		} else {
			return total;
		}
	}, 0);
	
	const objetCarteAlter = paramCarte.arrAlter.reduce((acc: number, id: string | number) => acc + zone.carte_alter[id], 0);
	
	let consigne_case: ConsigneExpeditionDTO[] = null;
	if (zone.consigne_expeditions !== null) {
		consigne_case = zone.consigne_expeditions.filter((consigne) => carte.listExpInscrit.includes(consigne.expedition_part.trace?.id));
	}
	
	return <div className={"caseCarteInfo"}>
		{caseVille && <>
			<div className="caseVille">
				<div className={"picto_home_ville"}><i className="fa-solid fa-house-flag"></i></div>
			</div>
			{consigne_case !== null && consigne_case.map((consigne, index) => {
				if (!consigne.fait) {
					return <SvgCase classPrincipale={"consigneExpe myFlag"} typeCarte={typeCarte} svg={"divers"} idSvg={"flagConsigne"} key={"flag_" + idTs + index} />;
				}
				if (consigne.fait) {
					return <SvgCase classPrincipale={"consigneExpe myFlagFinis"} typeCarte={typeCarte} svg={"divers"} idSvg={"flagConsigne"} key={"flag_" + idTs + index} />;
				}
			})}
		</>}
		{!caseVille && (<React.Fragment key={"caseHV_" + idTs}>
				{/* Zone non explorée */}
				{zone.vue === -1 && (
					<div className={"zone-NonExplo " + typeCarte} key={"danger_" + idTs}></div>
				)}
				{/* Zone Danger */}
				{paramCarte.danger && (!paramCarte.carteAlter && !paramCarte.carteScrut) && zone.vue === 1 && zone.danger != null && (!paramCarte.ctrl || zone.pdc === null || zone.pdc === 0) && (
					<div className={"danger zone-danger" + zone.danger + " " + typeCarte} key={"danger_" + idTs}></div>
				)}
				{paramCarte.ctrl && (!paramCarte.carteAlter && !paramCarte.carteScrut) && zone.vue === 1 && zone.pdc !== 0 && zone.pdc !== null && (
					<div className={`ctrl ${(zone.pdc >= (zone?.zombie ?? 0)) ? "zone-ctrlOk" : "zone-ctrlKo"} ${typeCarte}`} key={"ctrl_" + idTs}></div>
				)}
				{/* Quadrillage */}
				<SvgCase classPrincipale={"quadrillage"} typeCarte={typeCarte} svg={"bord"} idSvg={"bordCase"} style={{ display: "block" }} key={"quadrillage_" + idTs} />
				{/* Zone épuisée */}
				{zone.vue !== -1 && zone.dried && paramCarte.epuise && (user.user_personnalisation.action_diff_carte_alter || !paramCarte.carteAlter) && (
					<SvgCase classPrincipale={"epuise zone-epuise"} typeCarte={typeCarte} svg={"divers"} idSvg={user.user_personnalisation.motif_epuisement} style={{ color: general.themeUser.user_perso_couleur.couleur_epuise }} key={"epuise_" + idTs} />
				)}
				{/* Zone campée */}
				{zone.plan !== null && !zone.camped && (
					<SvgCase classPrincipale={"camp " + zone.plan} typeCarte={typeCarte} svg={"divers"} idSvg={"plan"} key={"camp_" + idTs} />
				)}
				{/* Zone bâtiment */}
				{zone.bat !== null && !user.user_personnalisation.affichage_bat && (
					<div className={"bat_square d-flex justify-content-center align-items-center " + typeCarte}>
						<SvgCase classPrincipale={"bat"} typeCarte={typeCarte} svg={"divers"} style={{ color: general.themeUser.user_perso_couleur.couleur_bat }} idSvg={zone.status_bat} key={"bat_" + idTs} />
						{zone.dig === 0 && zone.empty && <SvgCase classPrincipale={"bat_croix"} typeCarte={typeCarte} svg={"divers"} idSvg={"croix"} key={"empty_" + idTs} />}
					</div>
				)}
				{zone.bat !== null && user.user_personnalisation.affichage_bat && (
					<div className={"bat_icone d-flex justify-content-center align-items-center " + typeCarte}>
						{zone.dig > 0 && <SvgCase classPrincipale={"bat_svg"} typeCarte={typeCarte} svg={"divers"} idSvg={"bat-s-icone"} key={"bat_" + idTs} />}
						{zone.dig === 0 && <SvgCase classPrincipale={"bat_svg"} typeCarte={typeCarte} svg={"bat"} idSvg={zone.bat.icon} key={"bat_" + idTs} />}
						{zone.dig === 0 && zone.empty && <SvgCase classPrincipale={"bat_croix"} typeCarte={typeCarte} svg={"divers"} idSvg={"croix"} key={"empty_" + idTs} />}
					</div>
				)}
				{/* Zone balisage */}
				{zone.lvl_balisage !== null && zone.vue !== -1 && paramCarte.balisage && (
					<SvgCase classPrincipale={"balisage"} typeCarte={typeCarte} svg={"divers"} idSvg={zone.lvl_balisage + "z"} style={{ color: general.themeUser.user_perso_couleur.color_zombie }} key={"balisage_" + idTs} />
				)}
				{/* Zombie */}
				{zone.zombie >= 0 && zone.vue !== -1 && paramCarte.zombie && !paramCarte.estimZombie && !paramCarte.balisage && (
					<SvgCase classPrincipale={"zombReel zombie"} typeCarte={typeCarte} svg={"divers"} idSvg={user.user_personnalisation.zombie_discret && zone.zombie === 0 ? "-z" : zone.zombie + "z"} style={{ color: general.themeUser.user_perso_couleur.color_zombie }} key={"zomReel_" + idTs} />
				)}
				{/* Zombie éclaireur */}
				{zone.zombie_min !== null && zone.zombie_min >= 0 && zone.vue === -1 && paramCarte.zombie && !paramCarte.estimZombie && (
					<div className={"zombRange"}>
						<SvgCase classPrincipale={"zombReel zombie"} typeCarte={typeCarte} svg={"divers"} idSvg={zone.zombie_min + "z"} style={{ color: general.themeUser.user_perso_couleur.color_zombie }} key={"zomFourchette_min_" + idTs + "_" + zone.zombie_min} />
						<SvgCase classPrincipale={"zombReel zombie"} typeCarte={typeCarte} svg={"divers"} idSvg={"-z"} style={{
							color: general.themeUser.user_perso_couleur.color_zombie,
							left : `${Math.round(tailleCase / 2) - (zone.zombie_min < 10 ? 9 : 6)}px`,
						}} key={"zomFourchette_" + idTs} />
						<SvgCase classPrincipale={"zombReel zombie"} typeCarte={typeCarte} svg={"divers"} idSvg={zone.zombie_max + "z"} style={{
							color: general.themeUser.user_perso_couleur.color_zombie,
							left : `${Math.round(tailleCase / 2)}px`,
						}} key={"zomFourchette_max_" + idTs + "_" + zone.zombie_max} />
					</div>
				)}
				{/* Zombie estimation */}
				{zone.zombie_estim !== null && zone.zombie_estim >= 0 && zone.vue !== -1 && paramCarte.estimZombie && (
					<SvgCase classPrincipale={"zombEstim zombie"} typeCarte={typeCarte} svg={"divers"} style={{ color: general.themeUser.user_perso_couleur.color_estimation_zombie }} idSvg={zone.zombie_estim + estimZombieSupp + "z"} key={"zomEstim_" + idTs} />
				)}
				{/* Objet aux sols (objets + marqueurs) */}
				{zone.items.length > 0 && paramCarte.objetSol && (!paramCarte.carteAlter) && !paramCarte.carteScrut && !zone.marqueur_only && (
					<div className={"objetSolDiv " + typeCarte}>
						<div className={"d-flex justify-content-center align-items-center"}>
							{user.user_personnalisation.afficher_nbr_items_sol && (<span className={"itemsSolSpan"}>{zone.items.length}</span>)}
							<SvgCase classPrincipale={"itemsSol"} typeCarte={typeCarte} svg={"icone"} idSvg={"tag_3"} key={"objetSol_" + idTs} />
						</div>
					</div>
				
				)}
				{/* Objet aux sols (marqueurs) */}
				{zone.items.length > 0 && paramCarte.objetMarq && (!paramCarte.carteAlter) && !paramCarte.carteScrut && zone.marqueur_only && (
					<SvgCase classPrincipale={"objetSol"} typeCarte={typeCarte} svg={"divers"} idSvg={"presMarqueur"} key={"objetSol_" + idTs} />
				)}
				{/* Bordure KM */}
				{zone.bord_km !== null && paramCarte.distance && paramCarte.arrKm.includes(zone.km) && (
					<SvgCase classPrincipale={"km_" + zone.km + " distance"} typeCarte={typeCarte} style={{ color: general.themeUser.user_perso_couleur.couleur_km }} svg={"bord"} idSvg={"b_" + zone.bord_km} key={"bord_km_" + idTs} />
				)}
				{/* Bordure Scrutateur */}
				{zone.bord_scrut !== null && paramCarte.scrutateur && (
					<SvgCase classPrincipale={"scrut"} typeCarte={typeCarte} svg={"bord"} style={{ color: general.themeUser.user_perso_couleur.couleur_scrut }} idSvg={"b_" + zone.bord_scrut} key={"bord_scrut_" + idTs} />
				)}
				{/* Bordure PA */}
				{zone.bord_pa !== null && paramCarte.distance_pa && paramCarte.arrPa.includes(zone.pa) && (
					<SvgCase classPrincipale={"pa_" + zone.pa + " distance_pa"} typeCarte={typeCarte} style={{ color: general.themeUser.user_perso_couleur.couleur_p_a }} svg={"bord"} idSvg={"b_" + zone.bord_pa} key={"bord_pa_" + idTs} />
				)}
				{/* Bordure Zonage */}
				{zone.bord_zonage !== null && paramCarte.zonage && paramCarte.arrZonage.includes(zone.zone) && (
					<SvgCase classPrincipale={"zonage_" + zone.zone + " zonage"} typeCarte={typeCarte} style={{ color: general.themeUser.user_perso_couleur.couleur_zone }} svg={"bord"} idSvg={"b_" + zone.bord_zonage} key={"bord_zonage_" + idTs} />
				)}
				{/* Zone carte alternative */}
				{zone.items.length > 0 && objetCarteAlter > 0 && paramCarte.carteAlter && !paramCarte.carteScrut && (
					<div className={"carteAlter " + typeCarte} style={{ backgroundColor: colorap_carte[Math.round((objetCarteAlter / maxObjet + Number.EPSILON) * 100) - 1] }}>
						<div className={"divCarteAlter"}>
							<span className={"itemDechargeable"}>{objetCarteAlter}</span>
						</div>
					</div>
				)}
				{/* Zone carte scrutateur */}
				{zone.items.length > 0 && zone.carte_scrut > 0 && !paramCarte.carteAlter && paramCarte.carteScrut && (
					<div className={"carteScrut " + typeCarte} style={{ backgroundColor: colorap_carte[Math.round((zone.carte_scrut / carte.maxScrut + Number.EPSILON) * 100) - 1] }}>
						<div className={"divCarteScrut"}>
							<span className={"itemRegenerer"}>{zone.carte_scrut}</span>
						</div>
					</div>
				)}
				{/* Ma position */}
				{ville.ma_position !== null && ville.ma_position === (zone.x + zone.y * 100) && (
					<SvgCase classPrincipale={"posJoueur"} typeCarte={typeCarte} svg={"divers"} style={{ color: general.themeUser.user_perso_couleur.couleur_pos }} idSvg={"posJoueur"} key={"maPos_" + idTs} />
				)}
				{/* Indicateur de visite */}
				{zone.indic_visite !== null && paramCarte.indicVisite && !paramCarte.carteAlter && !paramCarte.carteScrut && (
					<SvgCase classPrincipale={"indicVisit " + zone.indic_visite} typeCarte={typeCarte} svg={"divers"} idSvg={"indicateurVue"} key={"indic_" + idTs} />
				)}
				{/* Indicateur de citoyen */}
				{ville.list_citoyen_vie[zone.y * 100 + zone.x] !== undefined && paramCarte.citoyen && (
					<div className={"citoyensVilleDiv " + typeCarte}>
						{user.user_personnalisation.affichage_nb_citoyen && (<span className={"citoyensVilleSpan"}>{ville.list_citoyen_vie[zone.y * 100 + zone.x].length}</span>)}
						<SvgCase classPrincipale={"citoyensVille"} typeCarte={typeVille + "CitoyensVille"} svg={"icone"} idSvg={"h_human"} key={"citVille_" + idTs} />
					</div>
				)}
				{/* Bordure de selection 1 */}
				{nombreDeValeursTrue === 1 && (
					<div className={"selectionCase"}>
						<BordureCase typeCarte={typeCarte} idBordure={"bordSelect"} classBordure={"select_1 selected_carte"} classOption={selectionBat ? "Bat" : selectionCit ? "Cit" : "Obj"} />
					</div>
				)}
				{/* Bordure de selection 2 */}
				{nombreDeValeursTrue === 2 && (
					<div className={"selectionCase"}>
						<BordureCase typeCarte={typeCarte} idBordure={"bordSelect2-1"} classBordure={"select_2 selected_carte"} classOption={selectionBat ? "Bat" : "Cit"} />
						<BordureCase typeCarte={typeCarte} idBordure={"bordSelect2-2"} classBordure={"select_2 selected_carte"} classOption={selectionObjet ? "Obj" : "Cit"} />
					</div>
				)}
				{/* Bordure de selection 3 */}
				{nombreDeValeursTrue === 3 && (
					<div className={"selectionCase"}>
						<BordureCase typeCarte={typeCarte} idBordure={"bordSelect3-1"} classBordure={"select_3 selected_carte"} classOption={"Bat"} />
						<BordureCase typeCarte={typeCarte} idBordure={"bordSelect3-2"} classBordure={"select_3 selected_carte"} classOption={"Cit"} />
						<BordureCase typeCarte={typeCarte} idBordure={"bordSelect3-3"} classBordure={"select_3 selected_carte"} classOption={"Obj"} />
					</div>
				)}
				{/* Indicateur objet selectionné */}
				{zone.items.length > 0 && valeursCommunes.length > 0 && (
					<div className={"listingItemsSelection"} key={"objetCase_" + idTs}>
						{valeursCommunes.length === 1 && (<SvgCase classPrincipale={"miniIcone Spe selected_objet"} typeCarte={typeCarte + "Spe"} svg={"icone"} idSvg={zone.item_sol_icone[valeursCommunes[0]]} />)}
						{valeursCommunes.length > 1 && (<SvgCase classPrincipale={"miniIconeMulti selected_objet"} typeCarte={typeCarte + "Spe"} svg={"icone"} idSvg={"tag_4"} />)}
					</div>
				)}
				{/* Flag consigne */}
				{consigne_case !== null && consigne_case.map((consigne, index) => {
					if (!consigne.fait) {
						return <SvgCase classPrincipale={"consigneExpe myFlag"} typeCarte={typeCarte} svg={"divers"} idSvg={"flagConsigne"} key={"flag_" + idTs + index} />;
					}
					if (consigne.fait) {
						return <SvgCase classPrincipale={"consigneExpe myFlagFinis"} typeCarte={typeCarte} svg={"divers"} idSvg={"flagConsigne"} key={"flag_" + idTs + index} />;
					}
				})}
				{/* Flag congigne expédition */}
				{consigneExpes.length > 0 && consigneExpes.includes(idTs) && (
					<SvgCase classPrincipale={"consigneExpe myFlag"} typeCarte={typeCarte} svg={"divers"} idSvg={"flagConsigne"} key={"flag_" + idTs} />
				)}
			</React.Fragment>
		)}
		
		<BordureCase typeCarte={typeCarte} classBordure={"visuCase"} idBordure={"bordSelect"} display={display} />
		{expeSelectionneGH.length > 0 && (<div className={"expeditionVille " + typeCarte}>
				{expeSelectionneGH.length > 0 && (
					<TraceExpedition typeCarte={typeCarte} carte={carte} zone={zone} key={"expe_" + idTs} listExpe={carte.carteExpe[zone.y * 100 + zone.x]} selectedArray={selectedCarte.expe} />
				)}
			</div>
		)}
		{expeSelectionneBiblio.length > 0 && (<div className={"expeditionVille " + typeCarte}>
				{expeSelectionneBiblio.length > 0 && (
					<TraceExpedition typeCarte={typeCarte} carte={carte} zone={zone} key={"expe_" + idTs} listExpe={carte.carteExpeBiblio[zone.y * 100 + zone.x]} selectedArray={selectedCarte.expe} />
				)}
			</div>
		)}
		{expeSelectionneBrouillon.length > 0 && (<div className={"expeditionVille " + typeCarte}>
				{expeSelectionneBrouillon.length > 0 && (
					<TraceExpedition typeCarte={typeCarte} carte={carte} zone={zone} key={"expe_" + idTs} listExpe={carte.carteExpeBrouillon[zone.y * 100 + zone.x]} selectedArray={selectedCarte.expe} />
				)}
			</div>
		)}
		{expeSelectionneMH.length > 0 && (
			<div className={"expeditionVille " + typeCarte}>
				{expeSelectionneMH.length > 0 && (
					<TraceExpeditionHordes typeCarte={typeCarte} carte={carte} zone={zone} key={"expe_" + idTs} listExpe={carte.carteExpeHordes[zone.y * 100 + zone.x]} selectedArray={selectedCarte.expe} />
				)}
			</div>
		)}
		{modeExpe && carteTraceManuel.length > 0 && carteTraceManuel[zone.y][zone.x] !== null &&
			(<div className={"expeditionVille " + typeCarte}>
					<TraceExpeditionManuel typeCarte={typeCarte} carte={carte} zone={zone} key={"expe_man_" + idTs} carteExpe={carteTraceManuel[zone.y][zone.x]} couleur={expeManuel.couleur} />
				</div>
			)}
	</div>;
	
};

export default CaseCarte;
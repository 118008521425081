import React, { useEffect, useState }                                      from "react";
import Select, { components, OptionProps, SingleValueProps, StylesConfig } from "react-select";
import { HerosSkillLevelDTO }                                              from "../../types/models/herosSkillLevel.dto";
import { useGeneralContext }                                               from "../../types/Context/GeneralContext";
import CompetenceBadge                                                     from "../Citoyens/CompetencesBadge";
import chroma                                                              from "chroma-js";
import { useTranslation }                                                  from "react-i18next";
import { ThemeUserDTO }                                                    from "../../types/models/themeUser.dto";

interface SkillManagerProps {
	availableSkills: HerosSkillLevelDTO[];
	savedUserSkill: HerosSkillLevelDTO;
	onSave: (skill: HerosSkillLevelDTO) => void;
	feedback: boolean;
}

const CustomOption = (props: OptionProps<HerosSkillLevelDTO>) => {
	const { data } = props;
	const { general } = useGeneralContext();
	
	return (
		<components.Option {...props}>
			<div><CompetenceBadge skillLevel={data} personnalisation={general.themeUser} /></div>
		</components.Option>
	);
};

const CustomSingleValue = (props: SingleValueProps<HerosSkillLevelDTO>) => {
	const { general } = useGeneralContext();
	const { data } = props;
	return (
		<components.SingleValue {...props}>
			<div>
				<CompetenceBadge skillLevel={data} personnalisation={general.themeUser} />
			</div>
		</components.SingleValue>
	);
};

const customStylesSkill = (theme: ThemeUserDTO, feedBack?: boolean): StylesConfig => {
	
	if (!feedBack) {
		feedBack = false;
	}
	
	return {
		container        : (provided) => ({
			...provided,
			width       : "40px",
			height      : "20px",
			marginBottom: "4px",
		}),
		option           : (provided: any, { isSelected, isFocused }) => {
			const color = chroma("#373a40");
			return {
				...provided,
				display        : "flex",
				flexDirection  : "row",
				alignItems     : "center",
				backgroundColor: isFocused ? color.alpha(0.5).css() : "white",
				color          : isFocused ? "white" : "black",
				width          : "24px",
				height         : "20px",
				overflow       : "hidden",
				margin         : "3px",
				cursor         : "pointer",
				padding        : "0",
			};
		},
		singleValue      : (provided) => ({
			...provided,
			borderRadius   : 5,
			display        : "flex",
			flexDirection  : "row",
			alignItems     : "center",
			justifyContent : "flex-start",
			alignContent   : "center",
			backgroundColor: "white",
			background     : "none",
			color          : "black",
			width          : "40px",
			padding        : "0",
			height         : "20px",
			overflow       : "hidden",
			textOverflow   : "ellipsis",
			whiteSpace     : "nowrap",
		}),
		control          : (provided) => ({
			...provided,
			minHeight     : "20px",
			height        : "20px",
			width         : "45px",
			display       : "flex",
			alignItems    : "center",
			justifyContent: "center",
			alignContent  : "center",
			padding       : "0",
			overflow      : "hidden",
			border        : feedBack ? `3px solid ${theme.succes_color}` : "1px solid black",
		}),
		input            : (provided) => ({
			...provided,
			gridTemplateColumns: "none",
			width              : "30px",
			padding            : "0",
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			padding: "0",
			width  : "20px",
			height : "20px",
		}),
		valueContainer   : (provided) => ({
			...provided,
			padding : 0,
			width   : "50px",
			height  : "20px",
			overflow: "hidden",
		}),
		menu             : (provided) => ({
			...provided,
			backgroundColor: "white",
			color          : "black",
			width          : "30px",
			padding        : "0",
		}),
		menuList         : (provided) => ({
			...provided,
			backgroundColor: "white",
			color          : "black",
			width          : "30px", // Limite la largeur de la liste déroulante
			overflow       : "hidden",
			padding        : "0",
		}),
		menuPortal       : (provided) => ({
			...provided,
			backgroundColor: "white",
			color          : "black",
			cursor         : "pointer",
			padding        : "0",
		}),
	};
};

export default function SkillManager({ availableSkills, savedUserSkill, onSave, feedback }: SkillManagerProps) {
	const { t } = useTranslation();
	const { general } = useGeneralContext();
	const [selectedSkill, setSelectedSkill] = useState<HerosSkillLevelDTO | null>(null);
	
	useEffect(() => {
		if (savedUserSkill) {
			setSelectedSkill(savedUserSkill);
		} else {
			setSelectedSkill(availableSkills.find(skillLevel => skillLevel.lvl === 1));
		}
	}, [savedUserSkill]);
	
	const handleSkillChange = (
		option: HerosSkillLevelDTO | null,
	) => {
		setSelectedSkill(option);
		onSave(option);
	};
	
	return (
		<div className={"d-flex gap-4 justify-content-start align-items-center"}>
			<Select
				value={selectedSkill}
				options={availableSkills}
				onChange={(option: HerosSkillLevelDTO) => handleSkillChange(option)}
				placeholder="Sélectionner une compétence"
				styles={customStylesSkill(general.themeUser, feedback)}
				components={{
					Option     : CustomOption,
					SingleValue: CustomSingleValue,
				}}
			/>
		</div>
	);
}
import React, { ReactNode, useEffect, useState } from "react";
import { FontAwesomeIcon }                       from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight }         from "@fortawesome/free-solid-svg-icons";

const CollapsibleFieldset = ({ title, children, className, collapse = true, actionExterne }: { title: string, children: ReactNode, className?: string, collapse?: boolean, actionExterne?: (collapse) => void }) => {
	const [isCollapsed, setIsCollapsed] = useState(collapse);
	
	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed);
		if (actionExterne) {
			actionExterne(!isCollapsed);
		}
	};
	
	useEffect(() => {
		setIsCollapsed(collapse);
	}, [collapse]);
	
	return (
		<fieldset className={className}>
			<legend
				onClick={toggleCollapse}
				style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
			>
				<FontAwesomeIcon
					icon={isCollapsed ? faChevronRight : faChevronDown}
					style={{ marginRight: "8px" }}
				/>
				{title}
			</legend>
			<div style={{ display: isCollapsed ? "none" : "block" }}>
				{children}
			</div>
		</fieldset>
	);
};

export default CollapsibleFieldset;

import React, { useEffect, useState }             from "react";
import { OutilsDechargePropsType }                from "../../../types/components/Outils/Decharge.type";
import HTMLParser                                 from "html-react-parser";
import SvgIcone                                   from "../../../components/generality/SvgIcone";
import { DechargesApi }                           from "../../../services/api/DechargesApi";
import { formatInTimeZone }                       from "date-fns-tz";
import { useGeneralContext }                      from "../../../types/Context/GeneralContext";
import { Status_error, Status_success, usePopUp } from "../../../types/Context/PopUpContext";
import { DechargesDTO }                           from "../../../types/models/decharges.dto";
import { RegroupementItemsDechargeDTO }           from "../../../types/models/regroupementItemsDecharge.dto";
import { useTranslation }                         from "react-i18next";

export default function Decharge(props: { decharge: OutilsDechargePropsType }) {
	const { t } = useTranslation();
	const { general } = useGeneralContext();
	const regroupements = props.decharge.regroupements;
	const chantier_dh = props.decharge.chantier_dh;
	const itemBanque = props.decharge.itemBanque;
	const chantier_decharge = props.decharge.chantier_decharge;
	const mapId = props.decharge.mapId;
	const userId = props.decharge.userId;
	
	const [outilsDecharge, setOutilsDecharge] = useState(props.decharge.outils_decharge);
	
	const initialDecharges = Object.values(regroupements).map((regroupement) => {
		
		const decharge = Object.values(outilsDecharge.decharges).find((decharge) => decharge.regroup_items.id === regroupement.id);
		
		return {
			regroup_items: regroupement,
			nbr_estime   : decharge?.nbr_estime ?? 0,
			nbr_utilise  : decharge?.nbr_utilise ?? 0,
			def_by_item  : ((chantier_decharge.includes(regroupement.chantier_decharge.id)) ? regroupement.point_def_decharge :
				regroupement.point_def_base) + ((chantier_dh) ? 1 : 0),
		};
	}) as DechargesDTO[];
	
	const [dechargesData, setDechargesData] = useState(initialDecharges);
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	
	useEffect(() => {
		setOutilsDecharge(props.decharge.outils_decharge);
	}, [props.decharge]);
	
	
	const handleEstimInputChange = (event: React.ChangeEvent<HTMLInputElement>, regroupementId: number) => {
		let value = parseInt(event.target.value, 10);
		if (value < 0) {
			value = 0;
		}
		setDechargesData(
			(prevData) => prevData.map((decharge) => decharge.regroup_items.id === regroupementId ? { ...decharge, nbr_estime: Number(value) } : decharge),
		);
	};
	
	const handleUtilInputChange = (event: React.ChangeEvent<HTMLInputElement>, regroupementId: number) => {
		let value = parseInt(event.target.value, 10);
		if (value < 0) {
			value = 0;
		}
		setDechargesData(
			(prevData) => prevData.map((decharge) => decharge.regroup_items.id === regroupementId ? { ...decharge, nbr_utilise: Number(value) } : decharge),
		);
	};
	
	const handleEstimInputDoubleClick = (regroupementId: number) => {
		const updatedDechargesData = dechargesData.map(
			(decharge) => decharge.regroup_items.id === regroupementId ? { ...decharge, nbr_estime: 0 } : decharge);
		
		setDechargesData(updatedDechargesData);
	};
	
	const handleUtiliseInputDoubleClick = (regroupementId: number, maxBanque: number) => {
		
		const dechargeModifier = dechargesData.find((decharge) => decharge.regroup_items.id === regroupementId);
		const valeurActuelle = dechargeModifier.nbr_utilise;
		const estimeActuelle = dechargeModifier.nbr_estime;
		let updatedDechargesData: React.SetStateAction<DechargesDTO[]>;
		if (valeurActuelle > 0) {
			updatedDechargesData =
				dechargesData.map((decharge) => decharge.regroup_items.id === regroupementId ? { ...decharge, nbr_utilise: 0 } : decharge);
		} else {
			updatedDechargesData = dechargesData.map(
				(decharge) => decharge.regroup_items.id === regroupementId ? { ...decharge, nbr_utilise: maxBanque + estimeActuelle } : decharge);
		}
		
		setDechargesData(updatedDechargesData);
	};
	
	const handleCancel = () => {
		setDechargesData(initialDecharges);
	};
	
	const handleSauvegarder = () => {
		const dechargeApi = new DechargesApi(mapId);
		
		dechargeApi.sauvegarde({ decharge: dechargesData, mapId: mapId, userId: userId }).then(response => {
			if (response.codeRetour === 0) {
				setOutilsDecharge(() => response.zoneRetour.outilsDecharge);
				setStatus(Status_success);
				setShowPop(true);
				setMessagePopUp(response.libRetour);
				
				setTimeout(() => {
					handleClose();
				}, 1000);
			} else {
				setStatus(Status_error);
				setShowPop(true);
				setMessagePopUp(response.libRetour);
			}
		});
	};
	
	const handleClose = () => {
		setMessagePopUp("");
		setShowPop(false);
		setStatus(Status_success);
	};
	
	return <div id="dechargeCorps">
		<table className="fondWhite02">
			<thead>
			<tr>
				<th className="col_dech_itemAff">{HTMLParser(t("Items <br/> décharges", { ns: "outils" }))}</th>
				<th className="col_dech_def">{HTMLParser(t("Def / <br/> objets", { ns: "outils" }))}</th>
				<th className="col_dech_totBanque">{HTMLParser(t("Nbr <br/> banque", { ns: "outils" }))}</th>
				<th className="col_dech_estim">{HTMLParser(t("Estim. <br/> suppl.", { ns: "outils" }))}</th>
				<th className="col_dech_util">{HTMLParser(t("Util. <br/> réelle", { ns: "outils" }))}</th>
				<th className="col_dech_defApport">{HTMLParser(t("Def <br/> apportée", { ns: "outils" }))}</th>
			</tr>
			</thead>
			<tbody>
			{Object.values(regroupements).map((regroupement: RegroupementItemsDechargeDTO) => {
				
				return <tr id={"lign_" + regroupement.id} key={"lign_" + regroupement.id}>
					<td className="col_dech_itemAff">
                        <span className="d-flex gap-1 align-items-center justify-content-center">{regroupement.item_affiches.length === 0 ? " " : (
							regroupement.item_affiches.map((item, index) => (
								<span key={`item_${item.id}_${regroupement.id}`} className="d-flex align-items-center justify-content-center">
                                    <SvgIcone icone={item.icon} />
									{index < regroupement.item_affiches.length - 2 && ", "}
                                </span>
							)).reduce((acc, elem, index, array) => (
								array.length > 1 && index === array.length - 1 ? acc.concat(<span>{t("et", { ns: "app" })}</span>, elem) : acc.concat(elem)
							), [])
						)}
                        </span>
					</td>
					<td className="col_dech_def">{((chantier_decharge.includes(regroupement.chantier_decharge.id)) ? regroupement.point_def_decharge :
						regroupement.point_def_base) + ((chantier_dh) ? 1 : 0)}</td>
					<td className="col_dech_totBanque totalBanqueDecharge">{itemBanque[regroupement.id]}</td>
					<td className="col_dech_estim">
						<div>
							<input type={"number"}
								   min={0}
								   value={dechargesData.find((decharge) => decharge.regroup_items.id === regroupement.id).nbr_estime}
								   onChange={(event) => handleEstimInputChange(event, regroupement.id)}
								   onDoubleClick={() => handleEstimInputDoubleClick(regroupement.id)}
							/>
						</div>
					</td>
					<td className="col_dech_util">
						<div>
							<input type={"number"}
								   min={0}
								   value={dechargesData.find((decharge) => decharge.regroup_items.id === regroupement.id).nbr_utilise}
								   onChange={(event) => handleUtilInputChange(event, regroupement.id)}
								   onDoubleClick={() => handleUtiliseInputDoubleClick(regroupement.id, itemBanque[regroupement.id])}
							/>
						</div>
					</td>
					<td className="col_dech_defApport defApport" id={"def_" + regroupement.id}>{dechargesData.find(
							(decharge) => decharge.regroup_items.id === regroupement.id).nbr_utilise *
						(((chantier_decharge.includes(regroupement.chantier_decharge.id)) ? regroupement.point_def_decharge :
							regroupement.point_def_base) + ((chantier_dh) ? 1 : 0))}</td>
				</tr>
					;
				
			})}
			
			<tr className="ligneTotalDecharge" key={"derniere_ligne"}>
				<td colSpan={4} id="mention_sauvegarde_decharge">
					{outilsDecharge.created_by !== null &&
						<span>{HTMLParser(t("Créé par <strong>{createur}</strong> le <strong>{date}</strong>", { ns: "outils" }).replace("{createur}", outilsDecharge.created_by.pseudo).replace("{date}", formatInTimeZone(new Date(outilsDecharge.created_at), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))))}</span>}
					{outilsDecharge.modify_by !== null &&
						<span>{HTMLParser(t("Modifiée par <strong>{createur}</strong> le <strong>{date}</strong>", { ns: "outils" }).replace("{createur}", outilsDecharge.modify_by.pseudo).replace("{date}", formatInTimeZone(new Date(outilsDecharge.modify_at), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))))}</span>}
				</td>
				<th>{t("Total def", { ns: "outils" })}</th>
				<td className="col_dech_defApport">{dechargesData.reduce((somme, decharge) => somme + decharge.nbr_utilise * decharge.def_by_item, 0)}</td>
			</tr>
			</tbody>
		</table>
		
		<div id="boutonDecharge">
			<button type={"button"} className={"btn btn-primary btn-sm"} onClick={handleSauvegarder}>{t("Sauvegarder", { ns: "outils" })}</button>
			<button type={"button"} className={"btn btn-warning btn-sm"} onClick={handleCancel}>{t("Annuler", { ns: "outils" })}</button>
		</div>
	</div>;
	
}
import React, { useEffect, useState } from "react";
import chargement                     from "../../../img/chargement.svg";
import { GeneralApi }                 from "../../services/api/GeneralApi";
import { News }                       from "../../containers/General/News";
import { useGeneralContext }          from "../../types/Context/GeneralContext";
import { useGHContext }               from "../../types/Context/GHContext";
import { useTranslation }             from "react-i18next";

export function NewsGeneral() {
	const { general, setGeneral } = useGeneralContext();
	const { refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
	const [newData, setNewsData] = useState(null);
	const { t } = useTranslation();
	// Fonction pour recharger les données
	const reloadNewsData = async () => {
		const generalApi = new GeneralApi(parseInt(sessionStorage.getItem("mapId") ?? "0", 10));
		
		generalApi.index().then(response => {
			setIsOnRefresh(false);
			setIsOnUpdate(false);
			if (response.codeRetour === 0) {
				if (response.zoneRetour.general !== undefined) {
					setGeneral(response.zoneRetour.general);
					sessionStorage.setItem("mapId", response.zoneRetour.general.ville ? response.zoneRetour.general.ville.map_id.toString() : null);
				}
				if (response.zoneRetour.news === undefined) {
					console.error("Erreur de chargement des news", response);
				} else {
					setNewsData(response.zoneRetour.news);
				}
			}
		});
	};
	
	useEffect(() => {
		reloadNewsData().then(r => r);
	}, [refreshKey]);
	
	return (
		<>
			{newData === null ? (
				<div className={"chargement_page"}>
					<img src={chargement} alt="Drapeau" />
					<span>{t("Chargement...", { ns: "app" })}</span>
				</div>
			) : (<News news={newData} general={general} />)}
		</>
	);
}
import * as React          from "react";
import { components }      from "react-select";
import { BatPrototypeDTO } from "../../../types/models/batPrototype.dto";
import { JobPrototypeDTO } from "../../../types/models/jobPrototype.dto";
import Camping             from "../../Outils/Camping/Camping";
import { InfoCase }        from "../../../types/components/Outils/Camping.type";

const { SingleValue, Option } = components;

interface PropsCamping {
	listBat: BatPrototypeDTO[];
	listJob: JobPrototypeDTO[];
}

export default function CampingEncyclopedie({ listBat, listJob }: PropsCamping) {
	
	const infoCaseAnonyme: InfoCase = {
		bat          : null,
		citoyens     : [],
		listBat      : listBat,
		listJob      : listJob,
		mode         : "anonyme",
		phare        : false,
		nuit         : true,
		devast       : false,
		nbrAmelioOD  : 0,
		zombie       : 0,
		bonus_camping: -25,
		nbrCampMax   : 99,
		km           : 1,
		hard         : false,
		nbTas        : 0,
	};
	
	
	return <Camping initialInfoCase={infoCaseAnonyme} listBat={listBat} />;
}

import { Fetch }            from "../../components/generality/Fetch";
import { RetourApiGeneral } from "../../types/api/RetourApiGeneral";
import { ChangelogType }    from "../../types/components/General/Changelog.type";
import { NewsType }         from "../../types/components/General/News.type";
import { GeneralType }      from "../../types/components/Generality/General.type";
import { FetchGH }          from "../../components/generality/FetchGH";
import { StatsType }        from "../../types/components/General/Stats.type";
import { RetourContacts }   from "./ContactApi";


export class GeneralApi {
	
	private fetch: Fetch;
	private fetchGH: FetchGH;
	
	constructor(mapId?: number) {
		this.fetch = new Fetch("generality", mapId ?? 0);
		this.fetchGH = new FetchGH("generality", mapId ?? 0);
	}
	
	public refresh(): Promise<RetourApiGeneral> {
		return this.fetch.from("/refresh").request().get();
	}
	
	public update(): Promise<RetourMajSite> {
		return this.fetchGH.post({ path: "/update" });
	}
	
	public resetVille() {
		return this.fetch.from("/reset").request().post();
	}
	
	public changelog(): Promise<AffichageChangeLog> {
		return this.fetch.from("/changelog").request().get();
	}
	
	public index(): Promise<AffichageIndex> {
		return this.fetch.from("/").request().get();
	}
	
	public majLang(data: { lang: string }): Promise<RetourMajLang> {
		return this.fetch.from("/majLang").request().post(data);
	}
	
	public general(): Promise<AffichageGeneral> {
		return this.fetchGH.get("/general");
	}
	
	public statistiques(): Promise<RetourStatistiques> {
		return this.fetchGH.get("/statistiques");
	}
	
	public statistiques_saison(saison: number): Promise<RetourStatistiques> {
		return this.fetchGH.get("/stats/" + saison);
	}
	
	public contact(): Promise<RetourContacts> {
		return this.fetchGH.get("/contact");
	}
	
	public ping(data: { versionClient: string }): Promise<RetourPing> {
		return this.fetchGH.post({ path: "/ping", data: data });
	}
	
}

export interface AffichageChangeLog {
	codeRetour: number,
	libRetour: string
	zoneRetour: {
		changelog: ChangelogType
		general: GeneralType,
	},
}

export interface RetourMajSite {
	data: {
		general?: GeneralType,
		retour?: number,
		lib?: string,
		error?: string,
	},
	status?: number,
	message?: string,
}

export interface RetourPing {
	data: {
		reload?: boolean,
		error?: string,
	},
	status?: number,
	message?: string,
}

export interface AffichageGeneral {
	data: {
		general?: GeneralType,
		error?: string,
	},
	status?: number,
	message?: string,
}


export interface AffichageIndex {
	codeRetour: number,
	libRetour: string
	zoneRetour: {
		news: NewsType
		general: GeneralType,
	},
}

export interface RetourMajLang {
	ok: boolean,
}

export interface RetourStatistiques {
	data: {
		general?: GeneralType,
		stats?: StatsType,
		error?: string,
	}
	status?: number,
	message?: string,
}
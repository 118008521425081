import * as React                                                                                                                                                                              from "react";
import { BooleanInput, Datagrid, DateField, DateTimeInput, Edit, EditButton, List, NumberField, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextField, TextInput, useRecordContext } from "react-admin";
import Button                                                                                                                                                                                  from "@mui/material/Button";
import { Link }                                                                                                                                                                                from "react-router";

const HerosButton = () => {
	return (
		<Button
			component={Link}
			to={"/admin/user/heros"}
			color="primary"
		>
			Pouvoir Héros
		</Button>
	);
};

export const UserList = (props) => {
	const postFilters = [
		<TextInput source="q" label="Search" alwaysOn key={"search_user"} />,
		<ReferenceInput source="id" label="User" reference="user" key={"search_user_id"} />,
	];
	
	return <>
		<HerosButton />
		<List {...props} filters={postFilters}>
			<Datagrid>
				<NumberField source="id" />
				<TextField source="pseudo" />
				<DateField source="date_maj" showTime={true} />
				<NumberField source="map_id" />
				<EditButton />
			</Datagrid>
		</List>
	</>;
};

export const UserEdit = (props) => {
	
	const role = [{ name: "ROLE_USER" }, { name: "ROLE_BETA" }, { name: "ROLE_MODO" }, { name: "ROLE_ADMIN" }];
	const theme = [{ name: "light" }, { name: "dark" }, { name: "vintage" }, { name: "hordes" }, { name: "perso" }];
	
	const colors = [
		{ name: "jet" },
		{ name: "hsv" },
		{ name: "hot" },
		{ name: "cool" },
		{ name: "spring" },
		{ name: "summer" },
		{ name: "autumn" },
		{ name: "winter" },
		{ name: "bone" },
		{ name: "copper" },
		{ name: "greys" },
		{ name: "YIGnBu" },
		{ name: "greens" },
		{ name: "YIOrRd" },
		{ name: "bluered" },
		{ name: "RdBu" },
		{ name: "picnic" },
		{ name: "rainbow" },
		{ name: "portland" },
		{ name: "blackbody" },
		{ name: "earth" },
		{ name: "electric" },
		{ name: "viridis" },
		{ name: "inferno" },
		{ name: "magma" },
		{ name: "plasma" },
		{ name: "warm" },
		{ name: "rainbow-soft" },
		{ name: "bathymetry" },
		{ name: "cdom" },
		{ name: "chlorophyll" },
		{ name: "density" },
		{ name: "freesurface-blue" },
		{ name: "freesurface-red" },
		{ name: "oxygen" },
		{ name: "par" },
		{ name: "phase" },
		{ name: "salinity" },
		{ name: "temperature" },
		{ name: "turbidity" },
		{ name: "velocity-blue" },
		{ name: "velocity-green" },
	];
	
	
	const PostTitle = () => {
		const record = useRecordContext();
		return <span>Edition de {record ? `"${record.pseudo}"` : ""}</span>;
	};
	
	
	return (<Edit {...props} title={<PostTitle />}>
		<SimpleForm>
			<div className={"user-switch"}>
				<NumberInput source="map_id" label="Identifiant ville en cours" />
				<ReferenceInput source={"der_pouv"} reference={"heros"} label={"Dernier pouvoir héros"}>
					<SelectInput
						source="der_pouv"
						label="Dernier pouvoir héros"
						optionText="nom"
						optionValue="id"
						format={value => value ? value.id : ""}
						parse={value => ({ id: value })}
					/>
				</ReferenceInput>
				<SelectInput
					source="roles"
					label="role"
					choices={role}
					optionText="name"
					optionValue="name"
				/>
				{/*                <SelectInput
				 source="theme"
				 label="Theme"
				 choices={theme}
				 optionText="name"
				 optionValue="name"
				 />*/}
				{/*                <SelectInput
				 source="user_personnalisation.colormap"
				 label="Colormap"
				 choices={colors}
				 optionText="name"
				 optionValue="name"
				 />*/}
				<BooleanInput source="heros" label="Héros" />
				<DateTimeInput source="date_maj" label="Date de mise à jour" />
				<TextInput source="periode_rappel" label="Période de rappel" />
				<BooleanInput source="poss_apag" label="Possède Apag" />
				<BooleanInput source="tem_arma" label="Tem arma" />
				<BooleanInput source="force_maj" label="Force de mise à jour" />
				<BooleanInput source="legend" label="Légende" />
				<BooleanInput source="annonce_news" label="Annonce les news" />
				<BooleanInput source="user_personnalisation.pop_up_click" label="Afficher la pop-up uniquement au clic" />
				<BooleanInput source="user_personnalisation.fige_menu" label="Figer le menu carte" />
				<BooleanInput source="user_personnalisation.bloc_maj_citoyens" label="Bloquer la mise à jour de ses informations de la page Citoyens par les autres joueurs" />
				<BooleanInput source="user_personnalisation.citoyens_mode_compact" label="Afficher la page citoyens en mode compact" />
				{/*<BooleanInput source="user_personnalisation.carte_textured" label="Ajouter une texture à la carte" />*/}
			</div>
			{/*            <div className={"user-color"}>
			 <ColorInput source="user_personnalisation.couleur_scrut" label="Couleur Scrut" />
			 <ColorInput source="user_personnalisation.couleur_km" label="Couleur Km" />
			 <ColorInput source="user_personnalisation.couleur_pos" label="Couleur position" />
			 <ColorInput source="user_personnalisation.couleur_bat" label="Couleur du bâtiment" />
			 <ColorInput source="user_personnalisation.couleur_select_obj" label="Couleur sélection Objet" />
			 <ColorInput source="user_personnalisation.couleur_select_bat" label="Couleur sélection Batiment" />
			 <ColorInput source="user_personnalisation.couleur_select_cit" label="Couleur sélection Citoyen" />
			 <ColorInput source="user_personnalisation.couleur_vue_auj" label="Couleur vue aujourd'hui" />
			 <ColorInput source="user_personnalisation.couleur_vue24" label="Couleur vue 24h" />
			 <ColorInput source="user_personnalisation.couleur_vue48" label="Couleur vue > 48h" />
			 <ColorInput source="user_personnalisation.couleur_p_a" label="Couleur PA" />
			 <ColorInput source="user_personnalisation.couleur_zone" label="Couleur Zone PA" />
			 <ColorInput source="user_personnalisation.color_zombie" label="Couleur Zombie" />
			 <ColorInput source="user_personnalisation.couleur_danger0" label="Couleur danger 0" />
			 <ColorInput source="user_personnalisation.couleur_danger1" label="Couleur danger 1" />
			 <ColorInput source="user_personnalisation.couleur_danger2" label="Couleur danger 2" />
			 <ColorInput source="user_personnalisation.couleur_danger3" label="Couleur danger 3" />
			 <ColorInput source="user_personnalisation.couleur_epuise" label="Couleur épuisée" />
			 <ColorInput source="user_personnalisation.color_sel_exp" label="Couleur des expés sélectionnés (dans le listing)" />
			 <ColorInput source="user_personnalisation.couleur_sel_case_maj" label="Couleur de bordure pour afficher le détail de la case" />
			 <ColorInput source="user_personnalisation.couleur_carte" label="Couleur de carte" />
			 <ColorInput source="user_personnalisation.color_estimation_zombie" label="Couleur estimation zombie" />
			 <NumberInput source="user_personnalisation.alpha_colormap" label="Transparence color map" />
			 </div>*/}
			
			
			{/* Ajoutez d'autres champs à éditer ici */}
		</SimpleForm>
	</Edit>);
};
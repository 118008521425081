import moment                              from "moment-timezone";
import TimezoneSelect, { ITimezoneOption } from "react-timezone-select";
import React                               from "react";

interface SelectTimeZoneProps {
	timeZone: string,
	disabled: boolean,
	style: any,
	onUpdate: (timezoneChoice: string) => void
}

export default function SelectTimeZone({ timeZone, disabled, style, onUpdate }: SelectTimeZoneProps) {
	
	const excludePatterns = ["CET", "CST6CDT", "EET", "EST", "EST", "Etc/", "GMT", "GB", "Greenwich", "HST", "MET", "MST", "PRC", "PST8PDT", "Poland", "ROC", "ROK", "Portugal", "UCT", "UTC", "Universal", "W-SU", "WET", "Zulu"]; // Ajoutez d'autres motifs ou chaînes à exclure
	
	const getFilteredTimezoneNames = () => {
		const timezones = moment.tz.names();
		const filteredNames = {};
		
		timezones.forEach(tz => {
			const shouldExclude = excludePatterns.some(pattern => tz.includes(pattern) || tz.startsWith(pattern));
			if (!shouldExclude) {
				filteredNames[tz] = tz.split("/").pop().replace("_", " ");
			}
		});
		
		return filteredNames;
	};
	
	const timezoneOptions = getFilteredTimezoneNames();
	
	return <TimezoneSelect
		value={timeZone}
		onChange={(timezone: ITimezoneOption) => onUpdate(timezone.value)}
		labelStyle="abbrev"
		displayValue={"UTC"}
		timezones={timezoneOptions}
		styles={style}
		isDisabled={disabled}
	/>;
	
}
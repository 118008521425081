import React              from "react";
import { AmeStats }       from "../../types/components/Ame/Ame.type";
import { useTranslation } from "react-i18next";


export default function StatsAmes({ ameStats }: { ameStats: AmeStats }) {
	const { t } = useTranslation();
	
	
	return <div style={{ width: "340px", height: "100px" }} className={"d-flex flex-column justify-content-start align-items-center align-self-center"}>
		<h3 className={"m-0"}>{t("Statistiques des villes", { ns: "ame" })}</h3>
		<div className={"grille_stats_ame"}>
			<div className={"d-flex justify-content-between"}>
				<div>{t("Nombre de villes :", { ns: "ame" })}</div>
				<div>{ameStats.nbrVille}</div>
			</div>
			<div className={"d-flex justify-content-between"}>
				<div>{t("Record de survie :", { ns: "ame" })}</div>
				<div>{ameStats.record_surv}</div>
			</div>
			<div className={"d-flex justify-content-between"}>
				<div>{t("Moyenne de survie :", { ns: "ame" })}</div>
				<div>{ameStats.moyenne_surv}</div>
			</div>
			<div className={"d-flex justify-content-between"}>
				<div>{t("Jours héros (environ) :", { ns: "ame" })}</div>
				<div>{ameStats.jour_heros}</div>
			</div>
			<div className={"d-flex justify-content-between"}>
				<div>{t("Jours total :", { ns: "ame" })}</div>
				<div>{ameStats.jour_total}</div>
			</div>
		</div>
	</div>;
}

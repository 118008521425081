import { ProgrammeChantierDTO } from "../../../types/models/programmeChantier.dto";

export class RessourceClass {
	
	listChantiers: ProgrammeChantierDTO[] = [];
	
	
	constructor({ listChantiers }: {
		listChantiers: ProgrammeChantierDTO[],
	}) {
		this.listChantiers = listChantiers;
	}
	
	getRessource() {
		let arrayRessource: any[] = [];
		let arrayRessourceEco: any[] = [];
		
		this.listChantiers.forEach(chantier => {
			const ressources = chantier.chantier?.ressources || [];
			const targetArray = chantier.pa_a_laisser === 0 ? arrayRessource : arrayRessourceEco;
			
			ressources.forEach(ressource => {
				const foundRessource = targetArray.find(item => item.item.id === ressource.item.id);
				if (foundRessource) {
					foundRessource.nombre += ressource.nombre;
				} else {
					targetArray.push({
						nombre: ressource.nombre,
						item  : ressource.item,
					});
				}
			});
		});
		
		arrayRessourceEco.forEach(ressourceEco => {
			const foundRessource = arrayRessource.find(item => item.item.id === ressourceEco.item.id);
			if (foundRessource) {
				foundRessource.nombre += ressourceEco.nombre;
			} else {
				arrayRessource.push({
					nombre: ressourceEco.nombre,
					item  : ressourceEco.item,
				});
			}
		});
		
		return arrayRessource;
	}
	
}
import { FetchGH }                                                                                                                                                                              from "../../components/generality/FetchGH";
import { GeneralType }                                                                                                                                                                          from "../../types/components/Generality/General.type";
import { AppercuInscriptionExpeditionTypeProps, ArchiveInscriptionExpeditionTypeProps, InscriptionConsigneSauvegardeType, InscriptionExpeditionSauvegardeType, InscriptionExpeditionTypeProps } from "../../types/components/Hotel/InscriptionExpedition.type";
import { ExpeditionPartDTO }                                                                                                                                                                    from "../../types/models/expeditionPart.dto";
import { CreneauHorraireDTO }                                                                                                                                                                   from "../../types/models/creneauHorraire.dto";
import { UserDTO }                                                                                                                                                                              from "../../types/models/user.dto";

export class InscriptionExpeditionApi {
	
	private fetchGH: FetchGH;
	
	constructor(mapId: number = 0) {
		this.fetchGH = new FetchGH("inscription_expedition", mapId ?? 0);
	}
	
	public main(mapId: number): Promise<AffichageInscriptionExpedition> {
		return this.fetchGH.get("/" + mapId);
	}
	
	public archive(mapId: number, jour: number): Promise<AffichageArchiveInscriptionExpedition> {
		return this.fetchGH.get("/archive/" + mapId + "/" + jour);
	}
	
	public sauvegarderInscriptionExpedition(inscription: InscriptionExpeditionSauvegardeType): Promise<RetourSauvegardeInscriptionExpedition> {
		return this.fetchGH.post({ path: "/sauvegarder", data: inscription });
	}
	
	public sauvegarderConsigneExpedition(consigne: InscriptionConsigneSauvegardeType): Promise<RetourSauvegardeInscriptionExpedition> {
		return this.fetchGH.post({ path: "/validConsigne", data: consigne });
	}
	
	public appercuTrace(id_trace: string): Promise<AffichageTraceExpeInscriptionExpedition> {
		return this.fetchGH.get("/appercu/" + id_trace);
	}
	
	public expeditionJoueur(idTrace: string): Promise<AffichageInscriptionExpeditionJoueur> {
		return this.fetchGH.get("/expedition/" + idTrace);
	}
	
	public sauvegardeCommentaireExpeditionnaire(data: { expedition_part_id: number, expeditionnaire_id: string, commentaire?: string }): Promise<RetourSauvegardeInscriptionExpedition> {
		return this.fetchGH.post({ path: "/editCommentaireExpe", data: data });
	}
	
}

export interface AffichageInscriptionExpedition {
	data: {
		inscription?: InscriptionExpeditionTypeProps,
		general?: GeneralType,
		error?: string,
	},
	status?: number,
	message?: string,
}

export interface AffichageArchiveInscriptionExpedition {
	data: {
		archive?: ArchiveInscriptionExpeditionTypeProps,
		general?: GeneralType,
		error?: string,
	},
	status?: number,
	message?: string,
}

export interface AffichageTraceExpeInscriptionExpedition {
	data: {
		appercu?: AppercuInscriptionExpeditionTypeProps,
		error?: string,
	},
	status?: number,
	message?: string,
}

export interface AffichageInscriptionExpeditionJoueur {
	data: {
		expedition?: ExpeditionPartDTO,
		creneaux?: CreneauHorraireDTO[],
		userOption?: UserDTO,
		error?: string,
	},
	status?: number,
	message?: string,
}

export interface RetourSauvegardeInscriptionExpedition {
	data: {
		error?: string,
	},
	status?: number,
	message?: string,
}

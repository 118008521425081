import { useCallback } from "react";
import chroma          from "chroma-js";

// Hook personnalisé pour gérer les couleurs et les champs spécifiques
export const useColor = () => {
	const inversedColorByHex = useCallback((couleurHex: string): string => {
		// Convertit la couleur en rgba
		const rgbaColor = chroma(couleurHex).rgb();
		// Calcul le negatif de la couleur
		const r = 255 - rgbaColor[0];
		const g = 255 - rgbaColor[1];
		const b = 255 - rgbaColor[2];
		// Reconstruit la couleur
		return chroma(r, g, b).css();
	}, []);
	
	const getColorContrast = useCallback((couleur1: string, couleur2: string): string => {
		return chroma.contrast(couleur1, couleur2) > 4.5 ? couleur2 : inversedColorByHex(couleur2);
	}, []);
	
	return { inversedColorByHex, getColorContrast };
};
import React, { useEffect, useState }     from "react";
import chargement                         from "../../../img/chargement.svg";
import { GeneralApi, RetourStatistiques } from "../../services/api/GeneralApi";
import { useNavigate }                    from "react-router";
import { Stats }                          from "../../containers/General/Stats";
import { useGeneralContext }              from "../../types/Context/GeneralContext";
import { useGHContext }                   from "../../types/Context/GHContext";
import { Status_error, usePopUp }         from "../../types/Context/PopUpContext";
import { useTranslation }                 from "react-i18next";

export function StatsGeneral() {
	const { general, setGeneral } = useGeneralContext();
	const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
	const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [statsData, setStatsData] = useState(null);
	
	
	// Fonction pour recharger les données
	const reloadStatsData = async () => {
		const generalApi = new GeneralApi(parseInt(sessionStorage.getItem("mapId") ?? "0", 10));
		
		generalApi.statistiques().then((response: RetourStatistiques) => {
			setIsOnRefresh(false);
			setIsOnUpdate(false);
			if (response.data.general !== undefined) {
				setGeneral(response.data.general);
				sessionStorage.setItem("mapId", response.data.general.ville ? response.data.general.ville.map_id.toString() : null);
			}
			if (response.data.stats === undefined) {
				console.error("Erreur de chargement des données statistiques", response);
			} else {
				setStatsData(response.data.stats);
			}
		}).catch((error) => {
			setStatus(Status_error);
			setShowPop(true);
			setMessagePopUp(error.message);
			navigate("/news");
		});
	};
	
	useEffect(() => {
		if (checkHabilitation("statistiques")) {
			reloadStatsData().then(r => r);
		} else {
			navigate("/news");
		}
	}, [refreshKey]);
	
	return (
		<>
			{statsData === null ? (
				<div className={"chargement_page"}>
					<img src={chargement} alt="Drapeau" />
					<span>{t("Chargement...", { ns: "app" })}</span>
				</div>
			) : (<Stats stats={statsData} />)}
		</>
	);
}
import React, { useEffect, useState }             from "react";
import Spinner                                    from "react-bootstrap/Spinner";
import { EventEnAvantType, NiveauHabilitation }   from "../../../types/components/Generality/General.type";
import Form                                       from "react-bootstrap/Form";
import { useGHContext }                           from "../../../types/Context/GHContext";
import { Status_error, Status_success, usePopUp } from "../../../types/Context/PopUpContext";
import { AdminApi, GenerauxApp }                  from "../../../services/api/AdminApi";


export default function ParamGeneral() {
	const adminApi = new AdminApi();
	const [isLoading, setIsLoading] = useState(true);
	const [generaux, setGeneraux] = useState<GenerauxApp>(null);
	const { setStatus, setMessagePopUp, setShowPop, handleClose } = usePopUp();
	const { triggerRefresh } = useGHContext();
	
	
	const fetchGeneraux = async () => {
		setIsLoading(true);
		adminApi.generaux().then((response) => {
			setGeneraux(response.generaux);
			setIsLoading(false);
		}).catch((error) => {
			console.error("Error fetching heros:", error);
		});
	};
	
	const handlSauvegarde = () => {
		adminApi.maj_generaux(generaux).then((response) => {
			if (response.codeRetour === 0) {
				setGeneraux(response.generaux);
				setStatus(Status_success);
				setMessagePopUp(response.libRetour);
				setShowPop(true);
				setTimeout(() => {
					handleClose();
				}, 1000);
				triggerRefresh();
			} else {
				setStatus(Status_error);
				setMessagePopUp(response.libRetour);
				setShowPop(true);
			}
		}).catch((error) => {
			console.error("Error fetching heros:", error);
		});
	};
	
	
	const handleSwitchChange = (key, subKey, level, checked) => {
		// Mettre à jour l'état local
		const newHabilitations = { ...generaux.habilitation };
		if (subKey) {
			newHabilitations[key][subKey][level] = checked;
		} else {
			newHabilitations[key][level] = checked;
		}
		setGeneraux({ ...generaux, ...{ habilitation: newHabilitations } });
	};
	
	
	const isNiveauHabilitation = (item): item is NiveauHabilitation => {
		return "user" in item && "beta" in item && "admin" in item;
	};
	
	const rows = Object.entries(generaux?.habilitation ?? []).flatMap(([key, value]) => {
		const item = generaux.habilitation[key];
		
		if (isNiveauHabilitation(item)) {
			return (
				<tr key={key}>
					<td className={"clef"}>{key}</td>
					<td className={"clef"}></td>
					{["user", "beta", "admin"].map((level) => (
						<td className={"role"} key={level}>
							<Form.Check
								type="switch"
								checked={value[level]}
								onChange={(e) => handleSwitchChange(key, null, level, e.target.checked)}
							/>
						</td>
					))}
				</tr>
			);
		} else {
			return Object.entries(value).map(([subKey, niveauHabilitation]) => (
				<tr key={`${key}-${subKey}`}>
					<td className={"clef"}>{key}</td>
					<td className={"clef"}>{subKey}</td>
					{["user", "beta", "admin"].map((level) => (
						<td className={"role"} key={level}>
							<Form.Check
								type="switch"
								checked={niveauHabilitation[level]}
								onChange={(e) => handleSwitchChange(key, subKey, level, e.target.checked)}
							/>
						</td>
					))}
				</tr>
			));
		}
	});
	
	useEffect(() => {
		fetchGeneraux().then(r => r);
	}, []);
	
	const handleAddEvent = () => {
		const newGeneraux = { ...generaux };
		newGeneraux.miseEnAvant.push({ id: "", nom: "", icone: "" });
		setGeneraux(newGeneraux);
	};
	
	const handleChangeEvent = (index: number, updatedPropretie: Partial<EventEnAvantType>) => {
		const newGeneraux = { ...generaux };
		newGeneraux.miseEnAvant[index] = { ...newGeneraux.miseEnAvant[index], ...updatedPropretie };
		setGeneraux(newGeneraux);
	};
	
	const handleDeleteEvent = (index: number) => {
		const newGeneraux = { ...generaux };
		newGeneraux.miseEnAvant.splice(index, 1);
		setGeneraux(newGeneraux);
	};
	
	
	return <div className={"admin_generaux"}>
		<h2>Paramètre généraux de GH</h2>
		<div id={"admin_maj_param_generaux"}>
			{isLoading ? (
				<div id="listChargement">
					<Spinner animation="border" role="status">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				</div>
			) : (
				<>
					<div id={"param_generaux"}>
						<div>
							<label>Numéro de version du jeu : </label>
							<input
								type="text"
								value={generaux.versionJeu}
								onChange={(event) => setGeneraux(generaux => ({ ...generaux, versionJeu: event.target.value }))}
							/>
						</div>
						<div>
							<label>Lien MHE : </label>
							<input
								type="text"
								value={generaux.lienMHE}
								onChange={(event) => setGeneraux(generaux => ({ ...generaux, lienMHE: event.target.value }))}
							/>
						</div>
						<div>
							<label>Lien MHO : </label>
							<input
								type="text"
								value={generaux.lienMHO}
								onChange={(event) => setGeneraux(generaux => ({ ...generaux, lienMHO: event.target.value }))}
							/>
						</div>
						<div>
							<label>Lien discord : </label>
							<input
								type="text"
								value={generaux.lienDiscord}
								onChange={(event) => setGeneraux(generaux => ({ ...generaux, lienDiscord: event.target.value }))}
							/>
						</div>
						<div>
							<label>Lien Git : </label>
							<input
								type="text"
								value={generaux.lienGit}
								onChange={(event) => setGeneraux(generaux => ({ ...generaux, lienGit: event.target.value }))}
							/>
						</div>
						<div>
							<label>Lien Crowdin : </label>
							<input
								type="text"
								value={generaux.lienCrowdin}
								onChange={(event) => setGeneraux(generaux => ({ ...generaux, lienCrowdin: event.target.value }))}
							/>
						</div>
					</div>
					<div id={"param_habil"}>
						<h2>Habilatation de GH</h2>
						<table>
							<thead>
							<tr>
								<th className={"clef"}>Clef Principale</th>
								<th className={"clef"}>Clef Secondaire</th>
								<th className={"role"}>User</th>
								<th className={"role"}>Beta</th>
								<th className={"role"}>Admin</th>
							</tr>
							</thead>
							<tbody>
							{rows}
							</tbody>
						</table>
					</div>
					<div id={"param_habil"}>
						<h2>Mise en avant Event</h2>
						<table>
							<thead>
							<tr>
								<th className={"clef"}>Nom</th>
								<th className={"clef"}>Id</th>
								<th className={"clef"}>Icone</th>
								<th className={"role"}>Action</th>
							</tr>
							</thead>
							<tbody>
							{generaux.miseEnAvant.map((eventValue, index) => (
								<tr key={index}>
									<td className={"clef"}><input type="text" placeholder={"Nom de l'event"} value={eventValue.nom} onChange={(event) => handleChangeEvent(index, { nom: event.target.value })} /></td>
									<td className={"clef"}><input type="text" placeholder={"Id de l'event"} value={eventValue.id} onChange={(event) => handleChangeEvent(index, { id: event.target.value })} /></td>
									<td className={"clef"}><input type="text" placeholder={"Icone de l'event"} value={eventValue.icone} onChange={(event) => handleChangeEvent(index, { icone: event.target.value })} /></td>
									<td className={"role"}>
										<button className={"btn btn-danger"} onClick={() => handleDeleteEvent(index)}>Supprimer</button>
									</td>
								</tr>
							))}
							</tbody>
						</table>
						<button className={"btn btn-success"} onClick={handleAddEvent}>Ajouter un event</button>
					</div>
					<button className={"btn btn-success"} onClick={() => handlSauvegarde()}>Sauvegarder les modifications</button>
				</>
			
			)}
		</div>
	</div>;
}
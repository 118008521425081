import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListPlansObtenu }            from "../../types/components/Hotel/PlansChantier.type";
import { ChantierPrototypeDTO }       from "../../types/models/chantierPrototype.dto";
import i18next                        from "i18next";

interface plansChantierState {
	compteurPlans: { commun: number, inhab: number, rare: number, epique: number, max_commun: number, max_inhab: number, max_rare: number, max_epique: number };
	plansObtenus: ListPlansObtenu[];
	listePlans: {
		plansCommun: ChantierPrototypeDTO[][],
		plansInhab: ChantierPrototypeDTO[][],
		plansRare: ChantierPrototypeDTO[][],
		plansEpique: ChantierPrototypeDTO[][],
		plansRuine: {
			bunker: {
				plansInhab: ChantierPrototypeDTO[],
				plansRare: ChantierPrototypeDTO[],
				plansTresRare: ChantierPrototypeDTO[],
			},
			hotel: {
				plansInhab: ChantierPrototypeDTO[],
				plansRare: ChantierPrototypeDTO[],
				plansTresRare: ChantierPrototypeDTO[],
			},
			hopital: {
				plansInhab: ChantierPrototypeDTO[],
				plansRare: ChantierPrototypeDTO[],
				plansTresRare: ChantierPrototypeDTO[],
			}
		}
	};
}

const initialState: plansChantierState = {
	compteurPlans: { commun: 0, inhab: 0, rare: 0, epique: 0, max_commun: 0, max_inhab: 0, max_rare: 0, max_epique: 0 },
	plansObtenus : [],
	listePlans   : {
		plansCommun: [[], [], [], []],
		plansInhab : [[], [], [], []],
		plansRare  : [[], [], [], []],
		plansEpique: [[], [], [], []],
		plansRuine : {
			bunker : {
				plansInhab   : [],
				plansRare    : [],
				plansTresRare: [],
			},
			hotel  : {
				plansInhab   : [],
				plansRare    : [],
				plansTresRare: [],
			},
			hopital: {
				plansInhab   : [],
				plansRare    : [],
				plansTresRare: [],
			},
		},
	},
};

const plansChantierSlice = createSlice({
	name    : "plansChantier",
	initialState,
	reducers: {
		resetPlansObtenus(state, action: PayloadAction<{ listChantier: ListPlansObtenu[] }>) {
			state.plansObtenus = action.payload.listChantier;
			// On balaye les plans obtenus pour mettre à jour le compteur
			state.compteurPlans.commun = 0;
			state.compteurPlans.inhab = 0;
			state.compteurPlans.rare = 0;
			state.compteurPlans.epique = 0;
			action.payload.listChantier.forEach(plan => {
				switch (plan.chantier.plan) {
					case 1:
						state.compteurPlans.commun++;
						break;
					case 2:
						state.compteurPlans.inhab++;
						break;
					case 3:
						state.compteurPlans.rare++;
						break;
					case 4:
						state.compteurPlans.epique++;
						break;
				}
			});
		},
		changePlanObtenu(state, action: PayloadAction<{ chantier: ChantierPrototypeDTO }>) {
			const chantier = action.payload.chantier;
			const index = state.plansObtenus.findIndex(plan => plan.chantier.id === chantier.id);
			if (index === -1) {
				state.plansObtenus.push({ chantier: chantier });
				switch (chantier.plan) {
					case 1:
						state.compteurPlans.commun++;
						break;
					case 2:
						state.compteurPlans.inhab++;
						break;
					case 3:
						state.compteurPlans.rare++;
						break;
					case 4:
						state.compteurPlans.epique++;
						break;
				}
			} else {
				state.plansObtenus.splice(index, 1);
				switch (chantier.plan) {
					case 1:
						state.compteurPlans.commun--;
						break;
					case 2:
						state.compteurPlans.inhab--;
						break;
					case 3:
						state.compteurPlans.rare--;
						break;
					case 4:
						state.compteurPlans.epique--;
						break;
				}
			}
		},
		setPlans(state, action: PayloadAction<{ plans: ChantierPrototypeDTO[] }>) {
			// On tri les plans par ordre alpha dans un premier temps avant de les ranger dans les différents types
			const listPlansTri = action.payload.plans.sort((chantier_a, chantier_b) => {
				const aName = i18next.t(chantier_a.nom, { ns: "chantiers" });
				const bName = i18next.t(chantier_b.nom, { ns: "chantiers" });
				return aName.localeCompare(bName);
			});
			
			// Filtrage par type de plan
			const plansCommun = listPlansTri.filter(plan => plan.plan === 1);
			const plansInhab = listPlansTri.filter(plan => plan.plan === 2);
			const plansRare = listPlansTri.filter(plan => plan.plan === 3);
			const plansEpique = listPlansTri.filter(plan => plan.plan === 4);
			
			state.compteurPlans.max_commun = plansCommun.length;
			state.compteurPlans.max_inhab = plansInhab.length;
			state.compteurPlans.max_rare = plansRare.length;
			state.compteurPlans.max_epique = plansEpique.length;
			
			// Fonction pour répartir en colonnes avec un remplissage par colonne
			const remplirColonnesParBloc = (plans: ChantierPrototypeDTO[]) => {
				const colonnes: ChantierPrototypeDTO[][] = [[], [], [], []]; // 4 colonnes
				const nombreColonnes = colonnes.length;
				const tailleParColonne = Math.ceil(plans.length / nombreColonnes); // Taille des 3 premières colonnes
				const reste = plans.length % nombreColonnes; // Nombre de plans restants pour la dernière colonne
				
				let colonneIndex = 0;
				let compteur = 0; // Compte le nombre d'éléments dans la colonne actuelle
				
				plans.forEach(plan => {
					// Ajouter le plan à la colonne actuelle
					colonnes[colonneIndex].push(plan);
					compteur++;
					
					// Si la colonne actuelle atteint la taille maximale, passer à la colonne suivante
					if (compteur >= tailleParColonne && colonneIndex < nombreColonnes - 1) {
						colonneIndex++; // Passer à la colonne suivante
						compteur = 0; // Réinitialiser le compteur pour la nouvelle colonne
					}
				});
				
				return colonnes;
			};
			
			// Répartition en 4 colonnes
			state.listePlans.plansCommun = remplirColonnesParBloc(plansCommun);
			state.listePlans.plansInhab = remplirColonnesParBloc(plansInhab);
			state.listePlans.plansRare = remplirColonnesParBloc(plansRare);
			state.listePlans.plansEpique = remplirColonnesParBloc(plansEpique);
			state.listePlans.plansRuine.bunker.plansInhab = listPlansTri.filter(plan => plan.plan === 2 && plan.ruine_bu);
			state.listePlans.plansRuine.bunker.plansRare = listPlansTri.filter(plan => plan.plan === 3 && plan.ruine_bu);
			state.listePlans.plansRuine.bunker.plansTresRare = listPlansTri.filter(plan => plan.plan === 4 && plan.ruine_bu);
			state.listePlans.plansRuine.hotel.plansInhab = listPlansTri.filter(plan => plan.plan === 2 && plan.ruine_ho);
			state.listePlans.plansRuine.hotel.plansRare = listPlansTri.filter(plan => plan.plan === 3 && plan.ruine_ho);
			state.listePlans.plansRuine.hotel.plansTresRare = listPlansTri.filter(plan => plan.plan === 4 && plan.ruine_ho);
			state.listePlans.plansRuine.hopital.plansInhab = listPlansTri.filter(plan => plan.plan === 2 && plan.ruine_hs);
			state.listePlans.plansRuine.hopital.plansRare = listPlansTri.filter(plan => plan.plan === 3 && plan.ruine_hs);
			state.listePlans.plansRuine.hopital.plansTresRare = listPlansTri.filter(plan => plan.plan === 4 && plan.ruine_hs);
		},
	},
});

export const { resetPlansObtenus, changePlanObtenu, setPlans } = plansChantierSlice.actions;
export default plansChantierSlice.reducer;

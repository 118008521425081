import React                                                          from "react";
import { useDispatch, useSelector }                                   from "react-redux";
import { useTranslation }                                             from "react-i18next";
import { addColonneMasquer, getColonneMasquer, removeColonneMasquer } from "../../store/villes/gestionVillesSlice";
import SvgIcone                                                       from "../../components/generality/SvgIcone";
import { AppDispatch, RootState }                                     from "../../store/store";
import { faLanguage }                                                 from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon }                                            from "@fortawesome/react-fontawesome";
import { IconDefinition }                                             from "@fortawesome/fontawesome-svg-core";


const ColumnFilterManager = () => {
	const { t } = useTranslation("villes");
	const dispatch = useDispatch<AppDispatch>();
	const colonnesMasquees = useSelector((state: RootState) => state.gestionVilles.colonneMasquer);
	
	// Définition des colonnes avec t() directement
	const AVAILABLE_COLUMNS = {
		villesVisu       : {
			label: t("Voir", { ns: "villes" }),
			icon : "r_explo2",
		},
		villesNum        : {
			label: t("N° ville", { ns: "villes" }),
			icon : null,
		},
		villesNom        : {
			label: t("Nom de la ville", { ns: "villes" }),
			icon : null,
		},
		villesType       : {
			label: t("Type", { ns: "villes" }),
			icon : null,
		},
		villesEtat       : {
			label: t("État", { ns: "villes" }),
			icon : null,
		},
		villesLarg       : {
			label: t("Largeur", { ns: "villes" }),
			icon : null,
		},
		villesLang       : {
			label : t("Communauté", { ns: "villes" }),
			iconFa: faLanguage,
		},
		villesSaison     : {
			label: t("Saison", { ns: "villes" }),
			icon : "r_wintop",
		},
		villesPointSaison: {
			label: t("Points de saison", { ns: "villes" }),
			icon : "item_soul_red",
		},
		villesCit        : {
			label: t("Citoyens en vie", { ns: "villes" }),
			icon : "h_human",
		},
		villeHero        : {
			label: t("Nombre de Héros", { ns: "villes" }),
			icon : "r_heroac",
		},
		villeDate        : {
			label: t("Dernière maj", { ns: "villes" }),
			icon : null,
		},
	};
	
	React.useEffect(() => {
		dispatch(getColonneMasquer());
	}, [dispatch]);
	
	const handleColumnToggle = (columnId: string) => {
		if (colonnesMasquees.includes(columnId)) {
			dispatch(removeColonneMasquer(columnId));
		} else {
			dispatch(addColonneMasquer(columnId));
		}
	};
	
	return (
		<div className="column-filter-manager p-2 mb-2">
			<fieldset className="list-colonne-filtre-villes">
				<legend>
					{t("Gérer l'affichage des colonnes", { ns: "villes" })}
				</legend>
				{Object.entries(AVAILABLE_COLUMNS).map(([columnId, { label, icon, iconFa }]: [string, { label: string, icon?: string, iconFa?: IconDefinition }]) => (
					<div key={columnId} className="d-flex justify-content-center align-items-center gap-1">
						<input
							type="checkbox"
							id={`column-toggle-${columnId}`}
							checked={!colonnesMasquees.includes(columnId)}
							onChange={() => handleColumnToggle(columnId)}
							className="h-4 w-4"
						/>
						<label
							htmlFor={`column-toggle-${columnId}`}
							className="d-flex justify-content-center align-items-center gap-1"
						>
							{icon && <SvgIcone icone={icon} />}
							{iconFa && <FontAwesomeIcon icon={iconFa} />}
							<span>{label}</span>
						</label>
					</div>
				))}
			</fieldset>
		</div>
	);
};

export default ColumnFilterManager;
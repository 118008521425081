import React                      from "react";
import { Helmet }                 from "react-helmet-async";
import { Bordure }                from "../../Ville/Carte/Bordure";
import SvgCase                    from "../../Ville/Carte/SvgCase";
import { TraceExpeditionAppercu } from "../../Ville/Carte/TraceExpedition";
import { ConsigneExpeditionDTO }  from "../../../types/models/consigneExpedition.dto";
import { UserDTO }                from "../../../types/models/user.dto";
import { VilleDTO }               from "../../../types/models/ville.dto";
import { ZoneMapDTO }             from "../../../types/models/zoneMap.dto";
import { useGeneralContext }      from "../../../types/Context/GeneralContext";
import { UserPersoCouleurDTO }    from "../../../types/models/userPersoCouleur.dto";

const generateCSSUser = (userPerso: UserPersoCouleurDTO) => {
	return `
		.Bat{color:${userPerso.couleur_select_bat};}
		.select_bat { background-color: ${userPerso.couleur_select_bat}; }
		.Cit{color:${userPerso.couleur_select_cit};}
		.select_cit { background-color: ${userPerso.couleur_select_cit}; }
		.Obj{color:${userPerso.couleur_select_obj};}
		.select_obj { background-color: ${userPerso.couleur_select_obj}; }
		.vueAuj { color: ${userPerso.couleur_vue_auj}; }
		.vue24 { color: ${userPerso.couleur_vue24}; }
		.vue48 { color: ${userPerso.couleur_vue48}; }
		.selectedExpe { background-color: ${userPerso.color_sel_exp}; }
		.myExpe { background-color: ${userPerso.color_my_exp}; }
		.myFlag { color: ${userPerso.color_flag}; }
		.myFlagFinis { color: ${userPerso.color_flag_finish}; }
		.visuCase { color: ${userPerso.couleur_sel_case_maj}; }
		.zone-danger0 { background-color: ${userPerso.couleur_danger0}; }
		.zone-danger1 { background-color: ${userPerso.couleur_danger1}; }
		.zone-danger2 { background-color: ${userPerso.couleur_danger2}; }
		.zone-danger3 { background-color: ${userPerso.couleur_danger3}; }
		.background_carte_color { background-color: ${userPerso.couleur_carte}; }
		.caseVille { background-color: ${userPerso.color_town}; }
		.zone-NonExplo { background-color: ${userPerso.color_non_vu}; }
		.zone-ctrlOk { background-color: ${userPerso.couleur_controle_ok}; }
		.zone-ctrlKo { background-color: ${userPerso.couleur_contole_nok}; }
		.bat_croix { color: ${userPerso.color_bat_epuise}; }
		.objetSolDiv{color:${userPerso.couleur_nbr_items_sol};}
		.citoyensVilleDiv{color:${userPerso.couleur_nbr_citoyen};}
		`;
};
const generateCSSVille = (ville: VilleDTO, xlarg: number, userOption: UserPersoCouleurDTO) => {
	const tailleVille = 24;
	
	const variableTaille = Math.ceil(600 / tailleVille);
	document.documentElement.style.setProperty("--tailleGd", `${variableTaille}px`);
	const variableTailleSpe = Math.ceil((12 / tailleVille) * 15);
	document.documentElement.style.setProperty("--tailleGdSpe", `${variableTailleSpe}px`);
	// calcul de la taille du picto home
	const taillePicto = Math.ceil(variableTaille / 2);
	
	return `
   .c${ville.height} {width : ${variableTaille * xlarg + 30}px;}
   .picto_home_ville {font-size : ${taillePicto}px; color: ${userOption.color_city};}
   `;
};


export default function AppercuCarte(props: {
	zones: ZoneMapDTO[],
	trace: number[],
	x_min: number,
	x_max: number,
	y_min: number,
	y_max: number,
	ville: VilleDTO,
	user: UserDTO,
	idTrace: string,
}) {
	
	const { general } = useGeneralContext();
	const ville = props.ville;
	const zones = props.zones;
	const trace = props.trace;
	const user = props.user;
	
	const typeCarte = "gdCarte";
	
	
	const bordHaut = [];
	for (let i = props.x_min; i <= props.x_max; i++) {
		bordHaut.push(
			<Bordure classBordure={"bordHautBasCarteAppercu"} typeCarte={typeCarte} valeur={i - ville.pos_x} key={"bord_h_" + i} />,
		);
	}
	const bordBas = [];
	for (let i = props.x_min; i <= props.x_max; i++) {
		bordBas.push(<Bordure classBordure={"bordHautBasCarteAppercu"} typeCarte={typeCarte} valeur={i - ville.pos_x} key={"bord_b_" + i} />);
	}
	
	const borduresHaut = (
		<React.Fragment key={"frag_bord_haut"}>
			<tr className="ligneCarteAppercu" key={"bord_haut"}>
				<td className="fondNoir coinCarteAppercu"></td>
				{bordHaut}
				<td className="fondNoir coinCarteAppercu"></td>
			</tr>
		</React.Fragment>
	);
	const borduresBas = (
		<React.Fragment key={"frag_bord_bas"}>
			<tr className="ligneCarteAppercu" key={"bord_bas"}>
				<td className="fondNoir coinCarteAppercu"></td>
				{bordBas}
				<td className="fondNoir coinCarteAppercu"></td>
			</tr>
		</React.Fragment>
	);
	
	const lignes = [];
	for (let j = props.y_min; j <= props.y_max; j++) {
		const cases = [];
		for (let i = props.x_min; i <= props.x_max; i++) {
			
			const zone = zones[j * 100 + i];
			const idTs = zone.x + "_" + zone.y;
			
			const caseVille = zone.vue === -2 && zone.x === ville.pos_x && zone.y === ville.pos_y;
			
			let consigne_case: ConsigneExpeditionDTO[] = null;
			if (zone.consigne_expeditions !== null) {
				consigne_case = zone.consigne_expeditions.filter((consigne) => props.idTrace === consigne.expedition_part.trace?.id);
			}
			
			cases.push(
				<React.Fragment key={"casemap_" + idTs}>
					<td id={idTs} className={"caseCarte " + typeCarte} data-x={zone.x} data-y={zone.y} key={"case_" + idTs}>
						<div className={"caseCarteInfo"}>
							{caseVille && <>
								<div className="caseVille">
									<div className={"picto_home_ville"}><i className="fa-solid fa-house-flag"></i></div>
								</div>
								{consigne_case !== null && consigne_case.map((consigne, index) => {
									if (!consigne.fait) {
										return <SvgCase classPrincipale={"consigneExpe myFlag"} typeCarte={typeCarte} svg={"divers"} idSvg={"flagConsigne"} key={"flag_" + idTs + index} />;
									}
									if (consigne.fait) {
										return <SvgCase classPrincipale={"consigneExpe myFlagFinis"} typeCarte={typeCarte} svg={"divers"} idSvg={"flagConsigne"} key={"flag_" + idTs + index} />;
									}
								})}
							</>}
							{!caseVille && (<React.Fragment key={"caseHV_" + idTs}>
									{zone.vue === -1 && (<div className={"zone-NonExplo " + typeCarte} key={"danger_" + idTs}></div>)}
									<SvgCase classPrincipale={"quadrillage"} typeCarte={typeCarte} svg={"bord"} idSvg={"bordCase"} style={{ display: "block" }} key={"quadrillage_" + idTs} />
									{zone.bat !== null && zone.plan !== null && !zone.camped &&
										(<SvgCase classPrincipale={"camp " + zone.plan} typeCarte={typeCarte} svg={"divers"} idSvg={"plan"} key={"camp_" + idTs} />)}
									{zone.bat !== null && (
										<div className={"bat_square d-flex justify-content-center align-items-center " + typeCarte}>
											<SvgCase classPrincipale={"bat"} typeCarte={typeCarte} svg={"divers"} style={{ color: general.themeUser.user_perso_couleur.couleur_bat }} idSvg={zone.status_bat} key={"bat_" + idTs} />
											{zone.dig === 0 && zone.empty && <SvgCase classPrincipale={"bat_croix"} typeCarte={typeCarte} svg={"divers"} idSvg={"croix"} key={"empty_" + idTs} />}
										</div>)}
									{consigne_case !== null && consigne_case.map((consigne, index) => {
										if (!consigne.fait) {
											return <SvgCase classPrincipale={"consigneExpe myFlag"} typeCarte={typeCarte} svg={"divers"} idSvg={"flagConsigne"} key={"flag_" + idTs + index} />;
										}
										if (consigne.fait) {
											return <SvgCase classPrincipale={"consigneExpe myFlagFinis"} typeCarte={typeCarte} svg={"divers"} idSvg={"flagConsigne"} key={"flag_" + idTs + index} />;
										}
									})}
								</React.Fragment>
							)}
							
							<div className={"expeditionVille " + typeCarte}>
								<TraceExpeditionAppercu typeCarte={typeCarte} theme={user.theme} zone={zone} key={"expe_" + idTs}
														listExpe={trace[zone.y][zone.x] ?? []} />
							</div>
						</div>
					</td>
				</React.Fragment>,
			);
		}
		
		lignes.push(
			<React.Fragment key={"ligne_" + j}>
				<tr className="ligneCarteAppercu" key={"lign_" + j}>
					<Bordure classBordure={"bordGaucheDroiteCarteAppercu"} typeCarte={typeCarte} valeur={ville.pos_y - j} key={"bord_g_" + j} />
					{cases}
					<Bordure classBordure={"bordGaucheDroiteCarteAppercu"} typeCarte={typeCarte} valeur={ville.pos_y - j} key={"bord_d_" + j} />
				</tr>
			</React.Fragment>,
		);
	}
	
	return (
		<>
			<Helmet>
				<style>{generateCSSUser(general.themeUser.user_perso_couleur)}</style>
				<style>{generateCSSVille(ville, (props.x_max - props.x_min + 1), general.themeUser.user_perso_couleur)}</style>
			</Helmet>
			
			<div id={"zoneCarte"} className={"c" + ville.weight} key={"carte_div"}>
				<div className={(general.themeUser.user_perso_couleur.carte_textured || user.theme === "vintage") ? "background_carte_sable" : "background_carte_lisse"}>
					<div className={"background_carte_color"}>
						<table>
							<tbody>
							{borduresHaut}
							{lignes}
							{borduresBas}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
}

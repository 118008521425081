import { ClassementPicto }   from "../../types/components/Ame/HistoriquePictos.type";
import { Ame }               from "../../types/components/Ame/Ame.type";
import { ClassementAmeType } from "../../types/components/Ame/ClassementAme.type";
import { GeneralType }       from "../../types/components/Generality/General.type";
import { FetchGH }           from "../../components/generality/FetchGH";
import { PictoPrototypeDTO } from "../../types/models/pictoPrototype.dto";
import { PictosDTO }         from "../../types/models/pictos.dto";

export class ClassementAmeApi {
	
	private fetchGH: FetchGH;
	
	constructor(mapId: number = 0) {
		this.fetchGH = new FetchGH("ame", mapId ?? 0);
	}
	
	public recup_picto_general(picto: PictoPrototypeDTO): Promise<RetourRecupPicto> {
		return this.fetchGH.get("/classement/" + picto.id);
	}
	
	public recup_picto_general_saison(picto: PictoPrototypeDTO, saison: number): Promise<RetourRecupPicto> {
		return this.fetchGH.get("/classement/" + picto.id + "/" + saison);
	}
	
	public recup_picto_ville(picto: PictoPrototypeDTO): Promise<RetourRecupPicto> {
		return this.fetchGH.get("/classement/maville/" + picto.id);
	}
	
	public recup_picto_ville_saison(picto: PictoPrototypeDTO, saison: number): Promise<RetourRecupPicto> {
		return this.fetchGH.get("/classement/maville/" + picto.id + "/" + saison);
	}
	
	public main(idUser?: string): Promise<AffichageAme> {
		return this.fetchGH.get("/" + ((idUser) ? idUser : ""));
	}
	
	public mainClassement(): Promise<AffichageClassement> {
		return this.fetchGH.get("/classement");
	}
	
	public cumulPictoUser(saison: number, idUser?: number): Promise<RetourRecupCumulPicto> {
		return this.fetchGH.get("/cumul/" + saison + "/" + ((idUser) ? idUser : ""));
	}
	
	public classementSaison(saison: number): Promise<RetourRecupClassementSaison> {
		return this.fetchGH.get("/classement/saison/" + saison);
	}
	
	public voisinUser(saison: string, idUser?: number): Promise<RetourRecupVoisinUser> {
		return this.fetchGH.get("/voisin/" + saison + "/" + ((idUser) ? idUser : ""));
	}
	
	public updateSoul(): Promise<RetourAmeApiVide> {
		return this.fetchGH.get("/updatemysoul");
	}
	
}

export interface RetourRecupPicto {
	data: {
		classement?: ClassementPicto[],
		picto?: PictoPrototypeDTO,
		error?: string,
	},
	status?: number,
	message?: string,
}

export interface RetourRecupClassementSaison {
	data: {
		classPicto?: ClassementPicto[][],
		classPictoVille?: ClassementPicto[][],
		error?: string,
	},
	status?: number,
	message?: string,
}

export interface RetourRecupCumulPicto {
	data: {
		pictos?: PictosDTO[],
		error?: string,
	},
	status?: number,
	message?: string,
}

export interface RetourRecupVoisinUser {
	data: {
		listVoisin?: { pseudo: string, id: number, nbVille: number }[],
		nbrVille?: number,
		error?: string,
	},
	status?: number,
	message?: string,
}

export interface AffichageAme {
	data: {
		ame?: Ame,
		general?: GeneralType,
		error?: string,
	},
	status?: number,
	message?: string,
}

export interface AffichageClassement {
	data: {
		classement?: ClassementAmeType,
		general?: GeneralType,
		error?: string,
	},
	status?: number,
	message?: string,
}

export interface RetourAmeApiVide {
	data: {
		error?: string,
	},
	status?: number,
	message?: string,
}
import { Fetch }                             from "../../components/generality/Fetch";
import { CitoyensType, PopUpMajCoffreProps } from "../../types/components/Hotel/Citoyens.type";
import { ParameterizedNamedSQL }             from "react-querybuilder/dist/cjs/react-querybuilder.cjs.development";
import { GeneralType }                       from "../../types/components/Generality/General.type";
import { CitoyensDTO }                       from "../../types/models/citoyens.dto";
import { FetchGH }                           from "../../components/generality/FetchGH";


export class CitoyensApi {
	
	private fetch: Fetch;
	private fetchGH: FetchGH;
	
	constructor(mapId?: number) {
		this.fetch = new Fetch("citoyens", mapId ?? 0);
		this.fetchGH = new FetchGH("citoyens", mapId ?? 0);
	}
	
	public save_citoyen(data: {
		mapId: number,
		userId: number,
		citoyens: CitoyensDTO,
		nameForm: string,
	}): Promise<RetourSaveCitoyen> {
		return this.fetch.from("/majCitoyens").request().post(data);
	}
	
	public filtre_citoyen(data: {
		mapId: number,
		userId: number,
		filtre: ParameterizedNamedSQL
	}): Promise<RetourFiltreCitoyen> {
		return this.fetch.from("/filtre").request().post(data);
	}
	
	public main(mapId?: string): Promise<AffichageCitoyens> {
		return this.fetch.from("/" + ((mapId) ? mapId : "")).request().get();
	}
	
	public saveCompetence(data: { mapId: number, citoyenId: number, userId: number, skillId: number, level: number }): Promise<RetourSaveCompetenceCitoyens> {
		return this.fetchGH.post({ path: "/saveCompetence", data: data });
	}
	
}

export type RetourSaveCitoyen = {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		citoyen: CitoyensDTO
	},
}

export type RetourFiltreCitoyen = {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		citoyen: CitoyensDTO[]
	},
}

export type AffichageCitoyens = {
	codeRetour?: number,
	libRetour?: string,
	zoneRetour: {
		citoyens: CitoyensType,
		popUpMaj: PopUpMajCoffreProps,
		general: GeneralType,
	},
}

export type RetourSaveCompetenceCitoyens = {
	data: {
		citoyen: CitoyensDTO,
		error?: string,
	},
	status?: number,
	message?: string,
}
import React, { useEffect, useRef } from "react";
import { Chart, registerables }     from "chart.js";
import { UserDTO }                  from "../types/models/user.dto";

interface ChartProps {
	stat: {
		theme: string[],
		nbr: number[],
	},
	id: string,
	user?: UserDTO
	width?: number,
	height?: number
	translate?: {
		titre: string,
		table: {
			theme: string,
			nbr: string
		},
	}
}

const ThemeChart = ({ stat, id, user = null, width = 500, height = 500, translate = { titre: "Repartition des thèmes utilisés", table: { theme: "Theme", nbr: "Nombre" } } }: ChartProps) => {
	
	const chartTheme = useRef<HTMLCanvasElement>(null);
	
	let colorFont = "#ffffff";
	if (user !== null) {
		colorFont = (user.theme === "dark" || user.theme === "hordes") ? "#ffffff" : "#000000";
	}
	
	const buildChartTheme = () => {
		Chart.register(...registerables);
		const myChartRef = chartTheme.current?.getContext("2d");
		const myChart = Chart.getChart("statTheme");
		if (myChart) {
			myChart.destroy();
		}
		
		new Chart(myChartRef!, {
			type   : "pie",
			data   : {
				labels  : Object.values(stat.theme),
				datasets: [{
					backgroundColor: [
						"rgb(255, 99, 132)",
						"rgb(54, 162, 235)",
						"rgb(255, 205, 86)",
						"rgb(28,255,0)",
					],
					data           : Object.values(stat.nbr),
				}],
			},
			options: {
				plugins: {
					legend: {
						position: "top",
						labels  : {
							color: colorFont,
						},
					},
					title : {
						display: true,
						text   : translate.titre,
						color  : colorFont,
					},
				},
			},
		});
	};
	
	useEffect(() => {
		buildChartTheme();
	}, [stat]);
	
	return (
		<div id={id + "-groupe-stats-graph-table"}>
			<div id={id + "-graph-theme"}>
				<canvas ref={chartTheme} id={"statTheme"} width={width} height={height}></canvas>
			</div>
			<div id={id + "-table-stats-theme"}>
				<table>
					<thead>
					<tr>
						<th>{translate.table.theme}</th>
						<th>{translate.table.nbr}</th>
					</tr>
					</thead>
					<tbody>
					{Object.values(stat.theme).map((value, index) => {
						return (
							<tr key={value + index}>
								<td>{value}</td>
								<td>{Object.values(stat.nbr)[index]}</td>
							</tr>
						);
					})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default ThemeChart;
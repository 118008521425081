import React, { useEffect, useState } from "react";
import chargement                     from "../../../img/chargement.svg";
import { GeneralApi }                 from "../../services/api/GeneralApi";
import Changelog                      from "../../containers/General/Changelog";
import { useGeneralContext }          from "../../types/Context/GeneralContext";
import { useGHContext }               from "../../types/Context/GHContext";
import { useTranslation }             from "react-i18next";

export function ChangelogGeneral() {
	const { general, setGeneral } = useGeneralContext();
	const { refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
	const [changelogData, setChangelogData] = useState(null);
	const { t } = useTranslation();
	// Fonction pour recharger les données
	const reloadChangelogData = async () => {
		const generalApi = new GeneralApi(parseInt(sessionStorage.getItem("mapId") ?? "0", 10));
		
		generalApi.changelog().then(response => {
			setIsOnRefresh(false);
			setIsOnUpdate(false);
			if (response.codeRetour === 0) {
				if (response.zoneRetour.general !== undefined) {
					setGeneral(response.zoneRetour.general);
					sessionStorage.setItem("mapId", response.zoneRetour.general.ville ? response.zoneRetour.general.ville.map_id.toString() : null);
				}
				if (response.zoneRetour.changelog === undefined) {
					console.error("Erreur de chargement des données changelog", response);
				} else {
					setChangelogData(response.zoneRetour.changelog);
				}
			} else {
				return null;
			}
		});
	};
	
	useEffect(() => {
		reloadChangelogData().then(r => r);
	}, [refreshKey]);
	
	return (
		<>
			{changelogData === null ? (
				<div className={"chargement_page"}>
					<img src={chargement} alt="Drapeau" />
					<span>{t("Chargement...", { ns: "app" })}</span>
				</div>
			) : (
				<Changelog changelog={changelogData} general={general} />
			)}
		
		</>
	);
}
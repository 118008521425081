import { Fetch }                                                       from "../../components/generality/Fetch";
import { EditRetour, SauvegardeRetour, SuptraceRetour, TracingRetour } from "../../types/api/RetourApiExpedition";
import { FetchGH }                                                     from "../../components/generality/FetchGH";
import { GeneralType }                                                 from "../../types/components/Generality/General.type";
import { CarteType, PopUpMaj }                                         from "../../types/components/ville/Carte.type";
import { OutilsExpeditionTypeProps }                                   from "../../types/components/Outils/OutilsExpedition.type";
import { ExpeditionDTO }                                               from "../../types/models/expedition.dto";
import { OuvriersDTO }                                                 from "../../types/models/ouvriers.dto";


export class ExpeditionApi {
	
	private fetch: Fetch;
	private fetchGH: FetchGH;
	
	constructor(mapId?: number) {
		this.fetch = new Fetch("expe", mapId ?? 0);
		this.fetchGH = new FetchGH("expe", mapId ?? 0);
		
	}
	
	public main(mapId: number): Promise<AffichageExpeditions> {
		return this.fetchGH.get("/" + mapId);
	}
	
	public tracing(data: { mapId: number, coord: number[][] }): Promise<TracingRetour> {
		return this.fetch.from("/tracing").request().post(data);
	}
	
	public edit(data: { expe_id: string }): Promise<EditRetour> {
		return this.fetch.from("/edit").request().post(data);
	}
	
	public suptrace(data: { expe_id: string }): Promise<SuptraceRetour> {
		return this.fetch.from("/supTrace").request().post(data);
	}
	
	public sauvegarde(data: {
		mapId: number, id: string, nom: string, crea_id: number, mod_id?: number, pa_expe: number, jour_expe: number, collab: boolean, couleur: string,
		coordonnee: number[][], personnel: boolean
	}): Promise<SauvegardeRetour> {
		return this.fetch.from("/enregTrace").request().post(data);
	}
	
	public sauvegardeExpedition(data: { map_id: number, id_user: number, jour: number, expedition: ExpeditionDTO }): Promise<RetourSauvegarde> {
		return this.fetchGH.post({ path: "/sauvegardeExpedition", data: data });
	}
	
	public changeStatutExpedition(data: {
		map_id: number, id_user: number, jour: number, expedition_id: string, statut: boolean
	}): Promise<RetourSauvegarde> {
		return this.fetchGH.post({ path: "/changeStatutExpedition", data: data });
	}
	
	public changeOrderExpedition(data: { map_id: number, id_user: number, jour: number, expeditions: ExpeditionDTO[] }): Promise<RetourSauvegarde> {
		return this.fetchGH.post({ path: "/changeOrderExpedition", data: data });
	}
	
	public deleteExpedition(data: { map_id: number, id_user: number, jour: number, expedition_id: string }): Promise<RetourSauvegarde> {
		return this.fetchGH.post({ path: "/deleteExpedition", data: data });
	}
	
	public sauvegardeOuvrier(data: {
		map_id: number, id_user: number, jour: number, ouvriers: OuvriersDTO[], direction_fao: number | null
	}): Promise<RetourSauvegarde> {
		return this.fetchGH.post({ path: "/sauvegardeOuvrier", data: data });
	}
	
	public recuperationOutils(data: { map_id: number, id_user: number, jour: number }): Promise<RetourRefreshOutils> {
		return this.fetchGH.post({ path: "/refreshOutils", data: data });
	}
	
	public changeStatutOuvrier(data: { map_id: number, id_user: number, jour: number, statut: boolean }): Promise<RetourSauvegarde> {
		return this.fetchGH.post({ path: "/changeStatutOuvrier", data: data });
	}
	
	public duplicateOuvrier(data: { map_id: number, id_user: number, with_preinscrit: boolean, from_day: number, to_day: number }): Promise<RetourSauvegardeVide> {
		return this.fetchGH.post({ path: "/duplicateOuvrier", data: data });
	}
	
	public verouillageExpedition(data: { map_id: number, id_user: number, jour: number, expedition_id: string, verrouillage: boolean }): Promise<RetourSauvegardeVide> {
		return this.fetchGH.post({ path: "/verrouExpedition", data: data });
	}
	
	public getVerrouExpedition(data: { map_id: number, id_user: number, jour: number }): Promise<RetourVerrouillage> {
		return this.fetchGH.post({ path: "/getVerrouExpedition", data: data });
	}
	
	public getExpedition(data: { map_id: number, id_user: number, jour: number, expedition_id: string }): Promise<RetourExpedition> {
		return this.fetchGH.post({ path: "/getExpedition", data: data });
	}
	
	
}


export interface AffichageExpeditions {
	data: {
		outils?: {
			carte: CarteType,
			popUpMaj: PopUpMaj,
		},
		general: GeneralType,
		error?: string,
	}
	status?: number,
	message?: string,
}

export interface RetourSauvegarde {
	data: {
		general?: GeneralType,
		error?: string,
	}
	status?: number,
	message?: string,
}

export interface RetourSauvegardeVide {
	data: {
		error?: string,
	}
	status?: number,
	message?: string,
}

export interface RetourRefreshOutils {
	data: {
		outils?: OutilsExpeditionTypeProps,
		general?: GeneralType,
		error?: string,
	}
	status?: number,
	message?: string,
}

export interface RetourVerrouillage {
	data: {
		verrou?: string[],
		error?: string,
	}
	status?: number,
	message?: string,
}

export interface RetourExpedition {
	data: {
		expedition?: ExpeditionDTO,
		error?: string,
	}
	status?: number,
	message?: string,
}